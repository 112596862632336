import { RefObject, useEffect, useMemo } from 'react';

import {
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { useCustomerTags } from 'components/CustomerDrawer/hooks/useCustomerTags';
import { DrawerHeader } from 'components/CustomerDrawer/UserDrawer/DrawerHeader';
import { DrawerTabs } from 'components/CustomerDrawer/UserDrawer/DrawerTabs';
import { CompanyTagsModal } from 'components/CustomerDrawer/UserDrawer/UserTagsModal';
import {
  useCustomerUser,
  useFetchCustomerUserCompany,
  useFetchCustomerUserCompanyData,
  useFetchCustomerUserData,
} from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { hideScrollbar } from 'styles/hideScrollbar';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

type CustomerUserDrawerProps = {
  customerUserId: string;
  isOpen: boolean;
  onClose: () => void;
  finalFocusRef: RefObject<HTMLDivElement>;
};

export function CustomerUserDrawer({
  customerUserId,
  isOpen,
  onClose,
  finalFocusRef,
}: CustomerUserDrawerProps) {
  const trackEvent = useUserEvent();
  const { user } = useCustomerUser(customerUserId);
  useFetchCustomerUserCompany(customerUserId, user.customerCompanyId);
  useFetchCustomerUserData(customerUserId);
  useFetchCustomerUserCompanyData(customerUserId, user.customerCompanyId);
  const {
    isOpen: isTagsOpen,
    onOpen: onTagsOpen,
    onClose: onTagsClose,
  } = useDisclosure();
  const { tags: allTags } = useCustomerTags();

  const tags = useMemo(
    () => allTags.filter((tag) => user.tags.includes(tag.id)),
    [allTags, user.tags],
  );

  useEffect(() => {
    if (isOpen) {
      trackEvent(CustomerDrawerAnalyticsEvents.USER_OPENED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      size={'lg'}
    >
      <DrawerOverlay />
      <DrawerContent
        overflow={'scroll'}
        css={hideScrollbar}
        background={'grey.offWhite'}
      >
        <DrawerHeader
          customerUserId={user.id}
          onClick={onClose}
          tags={tags}
          isTagsOpen={isTagsOpen}
          onTagsOpen={() => {
            onTagsOpen();
            trackEvent(CustomerDrawerAnalyticsEvents.TAGS_MANAGE, {
              customerType: 'user',
            });
          }}
        />

        <Collapse in={isTagsOpen} endingHeight={'100%'}>
          <CompanyTagsModal
            customerUserId={user.id}
            onTagsClose={onTagsClose}
          />
        </Collapse>

        <Collapse in={!isTagsOpen} endingHeight={'100%'}>
          <DrawerTabs customerUserId={user.id} />
        </Collapse>
      </DrawerContent>
    </Drawer>
  );
}
