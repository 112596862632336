import { useTranslation } from 'react-i18next';

import { useSentimentOptions } from 'components/CustomerDrawer/hooks/useSentimentOptions';
import { Select } from 'components/Select/Select';
import { Box, Heading, HStack, Spinner, VStack } from 'components/uikit';
import {
  useCustomerUser,
  useCustomerUserSentimentUpdatingAtom,
} from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';
import { isFormLoading } from 'utils/atoms/dataFetchingAtom';
import { formatRelativeTime } from 'utils/datetime';
import { isLoading } from 'utils/types';

export function SentimentOverview({ userId }: { userId: string }) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.overview_tab.sentiment',
  });
  const sentimentOptions = useSentimentOptions();
  const { user, fetchStatus: companyFetchStatus } = useCustomerUser(userId);
  const [formState, updateSentiment] =
    useCustomerUserSentimentUpdatingAtom(userId);
  const loading = isLoading(companyFetchStatus) || isFormLoading(formState);

  if (!user) {
    return <></>;
  }

  const onSentimentChange = async (value: string | null) => {
    await updateSentiment({ userId: userId, sentiment: value });
    trackEvent(CustomerDrawerAnalyticsEvents.SENTIMENT_UPDATED, {
      customerType: 'user',
    });
  };

  const sentimentOption = sentimentOptions.find(
    (option) => option.value === user.sentiment,
  );

  return (
    <HStack p='24px' justify={'space-between'}>
      <VStack alignItems='left' spacing={'2px'}>
        <Heading
          as={'h5'}
          fontWeight={'semibold'}
          noOfLines={1}
          fontSize={'14px'}
          lineHeight={1.6}
          color={'grey.primaryText'}
        >
          {t('title')}
        </Heading>
        <Box
          noOfLines={1}
          fontSize={'12px'}
          lineHeight={1.5}
          color={'grey.tertiaryText'}
        >
          {loading ? (
            <Spinner boxSize={'12px'} />
          ) : (
            t('updated_date_ago', {
              date: formatRelativeTime(
                user.sentimentUpdatedAt ?? user.insertedAt,
              ),
            })
          )}
        </Box>
      </VStack>
      <Box>
        <Box paddingBottom='8px'>
          <Select<string | null>
            items={sentimentOptions}
            onChange={onSentimentChange}
            selectedItem={user.sentiment}
            noneSelectedItemText={t('options.no_priority')}
            width='200px'
            mb='4px'
            color={sentimentOption?.color}
            backgroundColor={sentimentOption?.backgroundColor}
          />
        </Box>
      </Box>
    </HStack>
  );
}
