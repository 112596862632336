import { createIcon } from '@chakra-ui/react';

export const CloudSearchRed = createIcon({
  displayName: 'CloudSearchRedIcon',
  viewBox: '0 0 160 160',
  path: (
    <>
      <circle cx='90.2809' cy='64.2688' r='64.016' fill='#E3E8EF' />
      <ellipse
        cx='24.2644'
        cy='20.2578'
        rx='6.0015'
        ry='6.0015'
        fill='#E3E8EF'
      />
      <circle cx='21.2637' cy='112.281' r='8.002' fill='#E3E8EF' />
      <circle cx='164.299' cy='36.2618' r='8.002' fill='#E3E8EF' />
      <circle cx='153.297' cy='9.25507' r='5.00125' fill='#E3E8EF' />
      <g filter='url(#filter0_dd_22813_88506)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M92.2814 16.2568C78.8121 16.2568 66.8973 22.9126 59.6466 33.1147C57.2789 32.5563 54.8097 32.2608 52.2714 32.2608C34.5939 32.2608 20.2634 46.5913 20.2634 64.2689C20.2634 81.9464 34.5939 96.2769 52.2714 96.2769H132.291C147.759 96.2769 160.298 83.7377 160.298 68.2699C160.298 52.802 147.759 40.2628 132.291 40.2628C131.193 40.2628 130.109 40.3261 129.043 40.4492C122.912 26.219 108.76 16.2568 92.2814 16.2568Z'
          fill='#F8FAFC'
        />
        <ellipse
          cx='52.2714'
          cy='64.2688'
          rx='32.008'
          ry='32.008'
          fill='url(#paint0_linear_22813_88506)'
        />
        <ellipse
          cx='92.2815'
          cy='56.2668'
          rx='40.01'
          ry='40.01'
          fill='url(#paint1_linear_22813_88506)'
        />
        <circle
          cx='132.291'
          cy='68.2699'
          r='28.007'
          fill='url(#paint2_linear_22813_88506)'
        />
      </g>
      <g filter='url(#filter1_b_22813_88506)'>
        <rect
          x='64.5'
          y='81.7539'
          width='56'
          height='56'
          rx='28'
          fill='#D92D20'
        />
        <path
          d='M104.5 121.754L98.7 115.954M101.833 108.421C101.833 114.312 97.0577 119.087 91.1667 119.087C85.2756 119.087 80.5 114.312 80.5 108.421C80.5 102.53 85.2756 97.7539 91.1667 97.7539C97.0577 97.7539 101.833 102.53 101.833 108.421Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_22813_88506'
          x='0.263428'
          y='16.2568'
          width='180.035'
          height='120.02'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_22813_88506'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='4' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_22813_88506'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_22813_88506'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_22813_88506'
            result='effect2_dropShadow_22813_88506'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_22813_88506'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_22813_88506'
          x='56.5'
          y='73.7539'
          width='72'
          height='72'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='4' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_22813_88506'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_22813_88506'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_22813_88506'
          x1='27.6939'
          y1='43.1207'
          x2='84.2795'
          y2='96.2768'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3E8EF' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_22813_88506'
          x1='61.5595'
          y1='29.8317'
          x2='132.292'
          y2='96.2768'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3E8EF' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_22813_88506'
          x1='110.786'
          y1='49.7652'
          x2='160.298'
          y2='96.2768'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3E8EF' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  ),
});
