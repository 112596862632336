import { ReactNode, useCallback, useEffect, useState } from 'react';

import { VStack } from 'components/uikit/reexport';
import { ScrollsIntoView } from 'components/uikit/ScrollsIntoView';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

interface ListProps<K extends string, T> {
  items: Record<K, T[]>;
  initialSelection?: T | null;
  renderSectionHeader: (section: K) => ReactNode;
  renderItem: (
    item: T,
    isSelected: boolean,
    selectItem: () => void,
    removeSelection: () => void,
    index: number,
  ) => ReactNode;
  onItemSelected?: (item: T | null, index: number | null) => void;
  isItemDisabled?: (item: T) => boolean;
  hotkeysActive?: boolean;
}

export function ListWithSections<K extends string, T>({
  items,
  renderSectionHeader,
  initialSelection = null,
  renderItem,
  isItemDisabled,
  onItemSelected,
  hotkeysActive = true,
}: ListProps<K, T>) {
  const [selectedItem, setSelectedItem] = useState<T | null>(initialSelection);
  const itemsList = Object.values(items).flat() as T[];
  const headers = Object.keys(items) as K[];

  useEffect(() => {
    if (selectedItem === null && initialSelection) {
      setSelectedItem(initialSelection);
    }
  }, [selectedItem, initialSelection]);

  const selectItem = useCallback(
    (item: T | null, index: number | null) => {
      setSelectedItem(item);
      onItemSelected?.(item, index);
    },
    [setSelectedItem, onItemSelected],
  );

  const selectNextAvailableItem = useCallback(
    (index: number): void => {
      if (index >= itemsList.length) return;
      const nextItem = itemsList[index];
      if (!nextItem) return;

      const isDisabled = isItemDisabled?.(nextItem) ?? false;

      if (isDisabled) return selectNextAvailableItem(index + 1);
      selectItem(nextItem, index);
    },
    [itemsList, selectItem, isItemDisabled],
  );

  const selectPrevAvailableItem = useCallback(
    (index: number): void => {
      if (index < 0) return;

      const prevItem = itemsList[index];
      if (!prevItem) return;

      const isDisabled = isItemDisabled?.(prevItem) ?? false;

      if (isDisabled) return selectPrevAvailableItem(index - 1);
      selectItem(prevItem, index);
    },
    [itemsList, selectItem, isItemDisabled],
  );

  const selectNextItem = useCallback(() => {
    const nextIndex = selectedItem ? itemsList.indexOf(selectedItem) + 1 : 0;
    selectNextAvailableItem(nextIndex);
  }, [itemsList, selectedItem, selectNextAvailableItem]);

  const selectPrevItem = useCallback(() => {
    const prevIndex = selectedItem
      ? itemsList.indexOf(selectedItem) - 1
      : itemsList.length - 1;
    selectPrevAvailableItem(prevIndex);
  }, [itemsList, selectedItem, selectPrevAvailableItem]);

  useKeyAction('UP', selectPrevItem, {
    enabled: hotkeysActive,
    preventDefault: true,
    enableOnFormTags: true,
  });
  useKeyAction('DOWN', selectNextItem, {
    enabled: hotkeysActive,
    preventDefault: true,
    enableOnFormTags: true,
  });

  let index = 1;

  return (
    <>
      {headers
        .filter((header) => items[header] && items[header].length > 0)
        .map((header) => (
          <VStack key={header} align='stretch' spacing='0px'>
            {renderSectionHeader(header)}
            {items[header].map((item, idx) => {
              return (
                <ScrollsIntoView key={idx} selected={item === selectedItem}>
                  {renderItem(
                    item,
                    item === selectedItem,
                    () => selectItem(item, index),
                    () => selectItem(null, null),
                    index++,
                  )}
                </ScrollsIntoView>
              );
            })}
          </VStack>
        ))}
    </>
  );
}
