import { Property } from 'csstype';

export const StickyColumnStyle: {
  position: Property.Position;
  height: Property.Height;
  top: Property.Top;
} = {
  position: 'sticky',
  height: '100%',
  top: '0px',
};

export function stickyColumnStyle(isSticky: boolean) {
  return isSticky ? StickyColumnStyle : {};
}

export function fixedWidthStyle(width: number) {
  return {
    minWidth: `${width}px`,
    maxWidth: `${width}px`,
  };
}
