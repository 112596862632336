import { Flex, Tag, Text } from 'components/uikit';

interface CommandBarSectionHeaderProps {
  title: string;
  isTag: boolean;
}

export function CommandBarSectionHeader({
  title,
  isTag,
}: CommandBarSectionHeaderProps) {
  return (
    <Flex padding='16px 8px 0px 34px'>
      <Tag
        backgroundColor={isTag ? 'secondary.background' : 'grey.white'}
        borderRadius='4px'
        sx={
          isTag
            ? undefined
            : {
                p: 0,
              }
        }
      >
        <Text fontSize='12px' fontWeight='500' color='secondary.button'>
          {title}
        </Text>
      </Tag>
    </Flex>
  );
}
