import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';

export function useCustomerRevenueChartBlocks() {
  const { blocks } = useCustomerBreakdownBlocks();
  const chartBlock = blocks.find(
    (block) => block.uniqueName === 'CUSTOMER_USER_FINANCE_REVENUE_CHART',
  );
  const statsBlock = blocks.find(
    (block) => block.uniqueName === 'CUSTOMER_USER_FINANCE_REVENUE_STATS',
  );
  const blocksExist = !!chartBlock && !!statsBlock;

  return { chartBlock, statsBlock, blocksExist };
}
