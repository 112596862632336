import { LinkBox } from '@chakra-ui/react';

import { CalliperLogo } from 'components/uikit';
import paths from 'utils/paths';

export function CalliperLogoLinkBox() {
  return (
    <LinkBox
      as={'a'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      w='24px'
      h='24px'
      borderRadius='100'
      href={paths.missionControl.index}
    >
      <CalliperLogo w='24px' h='24px' />
    </LinkBox>
  );
}
