import { useTranslation } from 'react-i18next';

import { CountTag } from 'components/CustomerDrawer/DrawerTabs/CountTag';
import { CustomerDrawerTab } from 'components/CustomerDrawer/DrawerTabs/CustomerDrawerTab';
import { DrillDownTabPanel } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel';
import { NotesTabPanel } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/NotesTabPanel';
import { OverviewTabPanel } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel';
import { TabList, TabPanels, Tabs, VStack } from 'components/uikit';
import { useCustomerUserNotes } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

interface DrawerTabsProps {
  customerUserId: string;
}

export function DrawerTabs({ customerUserId }: DrawerTabsProps) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs',
  });
  const { notes } = useCustomerUserNotes(customerUserId);

  const handleTabsChange = (index: number) => {
    const tabOpened = ['overview', 'drill_down', 'users', 'notes'][index];
    trackEvent(CustomerDrawerAnalyticsEvents.TABS_SWITCHED, {
      customerType: 'user',
      tabOpened,
    });
  };

  return (
    <VStack height={'100%'}>
      <Tabs
        variant='unstyled'
        width={'100%'}
        isFitted
        height={'calc(100%)'}
        overflow={'hidden'}
        onChange={handleTabsChange}
      >
        <TabList
          justifyContent={'space-between'}
          gap={'16px'}
          px={'24px'}
          borderY='solid 1px !important'
          borderColor={'grey.border !important'}
          alignItems={'center'}
          height={'48px'}
          backgroundColor={'grey.white'}
        >
          <CustomerDrawerTab>{t('overview')}</CustomerDrawerTab>

          <CustomerDrawerTab>{t('drill_down')}</CustomerDrawerTab>

          <CustomerDrawerTab>
            {t('notes')}
            <CountTag>{notes.length.toString()}</CountTag>
          </CustomerDrawerTab>
        </TabList>

        <TabPanels flexDir={'row'} height={'calc(100% - 48px)'}>
          <OverviewTabPanel userId={customerUserId} />
          <DrillDownTabPanel userId={customerUserId} />
          <NotesTabPanel userId={customerUserId} />
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
