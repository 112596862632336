import { useCallback } from 'react';

import { useSetAtom } from 'jotai';

import { sharingConfigAtom } from 'data/sharing/atoms/sharingConfig';

export function useSetSharingLegendToggle(keys: string[] | undefined) {
  const setSharingConfig = useSetAtom(sharingConfigAtom);

  return useCallback(
    () => setSharingConfig({ legendToggle: keys?.toString() ?? undefined }),
    [keys, setSharingConfig],
  );
}
