import { Getter } from 'jotai';

import { activateMainProduct } from 'api/rest/users/activateMainProduct';
import { fetchUsedProviders } from 'api/rest/users/fetchUsedProviders';
import { getCompanyOnboarding } from 'api/rest/users/getCompanyOnboarding';
import {
  selectUsedProviders,
  SelectUsedProvidersRequest,
} from 'api/rest/users/selectUsedProviders';
import { updateCompanyOnboarding } from 'api/rest/users/updateCompanyOnboarding';
import {
  CompanyOnboarding,
  UpdateCompanyOnboardingParams,
  UsedProvidersByDepartment,
} from 'data/user/models/onboarding';
import { getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataFetchingAtom,
  getDataUpdatingAtom,
} from 'utils/atoms/dataFetchingAtom';

export const companyOnboardingAtom = getDataAtom<CompanyOnboarding | null>(
  null,
);

export const fetchCompanyOnboardingAtom = getDataFetchingAtom<
  CompanyOnboarding | null,
  string
>(companyOnboardingAtom, getCompanyOnboarding);

export const updateCompanyOnboardingAtom = getDataUpdatingAtom<
  CompanyOnboarding | null,
  UpdateCompanyOnboardingParams
>(companyOnboardingAtom, updateCompanyOnboarding);

export const activateMainProductAtom = getDataUpdatingAtom<
  CompanyOnboarding | null,
  string
>(companyOnboardingAtom, activateMainProduct);

export const usedProvidersAtom = getDataAtom<UsedProvidersByDepartment | null>(
  null,
);
export const fetchUsedProvidersAtom = getDataFetchingAtom<
  UsedProvidersByDepartment,
  undefined
>(usedProvidersAtom, fetchUsedProviders);
export const selectUsedProvidersAtom = getDataUpdatingAtom<
  UsedProvidersByDepartment,
  SelectUsedProvidersRequest
>(
  usedProvidersAtom,
  async (request: SelectUsedProvidersRequest, get: Getter) => {
    await selectUsedProviders(request);

    const providersConfig = get(usedProvidersAtom);

    return {
      ...providersConfig,
      [request.department]: request.providers,
    };
  },
);
