import { FC } from 'react';

import { IconProps, LinkIcon } from '@chakra-ui/icons';

import { CopyAsImageOptionBody } from 'components/Sharing/SharingOptions/CopyAsImageOptionBody';
import { EmbedOptionBody } from 'components/Sharing/SharingOptions/EmbedOptionBody';
import { ShareWithAnyoneOptionBody } from 'components/Sharing/SharingOptions/ShareWithAnyoneOptionBody';
import { ShareWithTeamOptionBody } from 'components/Sharing/SharingOptions/ShareWithTeamOptionBody';
import { CodeIcon, GlobeIcon, FramedPictureIcon } from 'components/uikit/icons';

export interface ShareOption {
  readonly id: string;
  readonly titleTk: string;
  readonly icon: FC<IconProps>;
  readonly body: FC;
  readonly enabled: boolean;
}

export type ShareOptions = [ShareOption, ...ShareOption[]];

export const SHARING_OPTION_SHARE_WITH_TEAM: ShareOption = {
  id: 'SHARE_WITH_TEAM',
  icon: LinkIcon,
  titleTk: 'sharing_block_modal_share_with_team.option_title',
  enabled: true,
  body: ShareWithTeamOptionBody,
};

export const SHARING_OPTION_SHARE_WITH_ANYONE: ShareOption = {
  id: 'SHARE_WITH_ANYONE',
  icon: GlobeIcon,
  titleTk: 'sharing_block_modal_share_with_anyone.option_title',
  enabled: true,
  body: ShareWithAnyoneOptionBody,
};

export const SHARING_OPTION_EMBED: ShareOption = {
  id: 'EMBED',
  icon: CodeIcon,
  titleTk: 'sharing_block_modal_embed.option_title',
  enabled: true,
  body: EmbedOptionBody,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SHARING_OPTION_COPY_AS_IMAGE: ShareOption = {
  id: 'COPY_AS_IMAGE',
  icon: FramedPictureIcon,
  titleTk: 'sharing_block_modal_copy_as_image.option_title',
  enabled: true,
  body: CopyAsImageOptionBody,
};

export const SHARING_OPTIONS_DEFAULT: ShareOptions = [
  SHARING_OPTION_SHARE_WITH_TEAM,
  SHARING_OPTION_SHARE_WITH_ANYONE,
  SHARING_OPTION_EMBED,
  SHARING_OPTION_COPY_AS_IMAGE,
];
