import { forwardRef } from 'react';

import { TabProps, useTab } from '@chakra-ui/react';

import { Button } from 'components/uikit';

export const CustomerDrawerTab = forwardRef<HTMLElement, TabProps>(
  (props, ref) => {
    const tabProps = useTab({ ...props, ref });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isSelected = !!tabProps['aria-selected'];

    return (
      <Button
        width='120px'
        height='32px'
        {...tabProps}
        backgroundColor={isSelected ? 'grey.background' : undefined}
        stroke={isSelected ? 'secondary.button' : undefined}
        color={isSelected ? 'secondary.button' : undefined}
        boxShadow='none !important'
        variant={'grayGhost'}
        _hover={
          isSelected
            ? {
                backgroundColor: 'grey.background',
                stroke: 'secondary.button',
                color: 'secondary.button',
              }
            : undefined
        }
        fontSize='14px'
      >
        {tabProps.children}
      </Button>
    );
  },
);

CustomerDrawerTab.displayName = 'CustomerDrawerTab';
