import { useTranslation } from 'react-i18next';

import { Delta } from 'components/Delta/Delta';
import { MobileTableConfig } from 'data/charts/models/MobileTableConfig';
import { TableConfig } from 'data/charts/models/TableConfig';
import { NumberFormatter } from 'utils/formatting/number';

type EngagementIntensityRow = {
  signal: string;
  allTime: number;
  lastWeek: number;
  prevWeekDelta: number;
};

export function useCustomerEngagementBreakdownBySignalsConfig(
  formatter: NumberFormatter,
) {
  const { t } = useTranslation('customer-health', {});
  const tableConfig: TableConfig<EngagementIntensityRow> = [
    {
      accessor: 'signal',
      header: t('breakdown_by_signals.engagement_events'),
      style: {
        fontWeight: '500',
        textColor: 'grey.primaryText',
        width: '250px',
        align: 'left',
      },
    },
    {
      accessor: 'allTime',
      header: t('breakdown_by_signals.all_time'),
      style: {
        align: 'right',
        textColor: 'grey.tertiaryText',
      },
    },
    {
      accessor: 'lastWeek',
      header: t('breakdown_by_signals.last_30_days'),
      style: {
        align: 'right',
      },
    },
    {
      accessor: 'prevWeekDelta',
      header: t('breakdown_by_signals.change'),
      render: ({ prevWeekDelta }) => (
        <Delta
          value={Number(prevWeekDelta)}
          valueFormatter={formatter}
          py={0}
          my={0}
          maxH='15px'
        />
      ),
      style: {
        align: 'right',
      },
    },
  ];

  const mobileTableConfig: MobileTableConfig<EngagementIntensityRow> = [
    [
      {
        accessor: 'signal',
        type: 'text',
      },
      {
        accessor: 'allTime',
        type: 'value',
        render: ({ allTime }) => t('users_count', { count: allTime }),
      },
    ],
  ];

  return { tableConfig, mobileTableConfig };
}
