import { z } from 'zod';

export const SupportDictionarySummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_SUMMARY',
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_SUMMARY',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_SUMMARY',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_SUMMARY_THREE_PERIODS_AGO',
]);

export const GrowthDictionarySummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_LEADS_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_LEADS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_LEADS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_SUMMARY',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_SUMMARY_THREE_PERIODS_AGO',
]);
export const FinanceDictionarySummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_FINANCE_ARR_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_ARR_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_ARR_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_MRR_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_ARPC_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_ARPC_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_ARPC_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_CHURN_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_CHURN_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_CHURN_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_GRR_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_GRR_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_GRR_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NRR_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NRR_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NRR_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_SUMMARY',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_SUMMARY_THREE_PERIODS_AGO',
]);
export const ProductDictionarySummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_SUMMARY',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_SUMMARY_THREE_PERIODS_AGO',
]);
export const SalesDictionarySummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_SUMMARY',
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS_SUMMARY',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_SUMMARY',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_SUMMARY',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_WIN_RATE_SUMMARY',
  'METRIC_DICTIONARY_SALES_WIN_RATE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_WIN_RATE_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_SUMMARY',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_VELOCITY_SUMMARY',
  'METRIC_DICTIONARY_SALES_VELOCITY_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_VELOCITY_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_SUMMARY',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS_SUMMARY',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS_SUMMARY_ONE_PERIOD_AGO',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS_SUMMARY_THREE_PERIODS_AGO',
  'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS_SUMMARY',
  'METRIC_DICTIONARY_SALES_STALLED_DEALS_SUMMARY',
  'METRIC_DICTIONARY_SALES_NEW_DEALS_SUMMARY',
]);

export const FinanceProductDictionarySummaryUniqueBlockName = z.enum([
  'AUTO_DIGEST_FINANCE_PRODUCT_TOTAL_NEW_ACCOUNTS_SUMMARY',
  'AUTO_DIGEST_FINANCE_PRODUCT_NEW_PAID_ACCOUNTS_SUMMARY',
  'AUTO_DIGEST_FINANCE_PRODUCT_NEW_FREE_ACCOUNTS_SUMMARY',
]);

export const MetricDictionarySummaryName = z.enum([
  ...GrowthDictionarySummaryUniqueBlockName.options,
  ...FinanceDictionarySummaryUniqueBlockName.options,
  ...ProductDictionarySummaryUniqueBlockName.options,
  ...SalesDictionarySummaryUniqueBlockName.options,
  ...SupportDictionarySummaryUniqueBlockName.options,
  ...FinanceProductDictionarySummaryUniqueBlockName.options,
]);

export type MetricDictionarySummaryName = z.infer<
  typeof MetricDictionarySummaryName
>;
