import { createIcon } from '@chakra-ui/react';

export const TrophyIcon = createIcon({
  displayName: 'TrophyIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M10.0319 13.8121C7.00157 13.8121 5.2339 11.2883 4.72885 10.0264C4.67347 9.888 4.63024 9.67987 4.59716 9.41893M10.0319 13.8121C12.8984 13.669 14.8449 11.2506 15.3349 10.0264C15.4005 9.86247 15.449 9.60069 15.4839 9.2692M10.0319 13.8121L10.0316 18.3549M10.0319 13.8121C9.99385 13.814 10.0281 17.9473 10.0316 18.3549M10.0319 13.8121C10.0789 13.8121 10.0359 17.9473 10.0316 18.3549M10.0316 18.3549C10.0318 18.3763 14.1982 18.3549 14.1982 18.3549H5.86491H10.0316ZM15.4666 3.96919C15.4048 2.67582 15.3349 1.69775 15.3349 1.69775H4.72885C4.72885 1.69775 4.65895 2.67582 4.59716 3.96919M15.4666 3.96919H18.3649V7.75491C17.9594 8.38587 16.8498 9.64778 15.4839 9.2692M15.4666 3.96919C15.5493 5.70087 15.6175 7.99778 15.4839 9.2692M4.59716 3.96919H1.69824V7.75491C2.1157 8.48569 3.27993 9.84157 4.59716 9.41893M4.59716 3.96919C4.51107 5.7712 4.44074 8.18528 4.59716 9.41893'
      stroke='currentColor'
      fill='none'
      strokeWidth='1'
      strokeLinejoin='round'
    />
  ),
});
