import { ComponentProps } from 'react';

import ReactMarkdown from 'react-markdown';

export function Markdown(props: ComponentProps<typeof ReactMarkdown>) {
  return (
    <ReactMarkdown
      components={{
        ul: (props) => (
          <ul
            style={{
              transform: 'translateX(18px)',
              width: 'calc(100% - 18px)',
            }}
            {...props}
          />
        ),
      }}
      {...props}
    />
  );
}
