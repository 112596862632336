import { FC } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CommandPanelModal } from 'components/CommandPanel/Modal';
import { Button, HStack, SearchIcon, Text } from 'components/uikit';
import { useUserEvent } from 'utils/analytics';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

import { CommandPanelShortcut } from './CommandPanelShortcut';

export const SidebarCommandPanelButton: FC = () => {
  const { t } = useTranslation('command-panel');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const trackEvent = useUserEvent();
  const onClick = () => {
    trackEvent(CommandPanelAnalyticsEvents.COMMAND_PANEL_OPENED);
    onOpen();
  };
  useKeyAction('COMMAND_PANEL', onClick, { enabled: !isOpen });
  return (
    <>
      <Button
        variant='ghost'
        width='152px'
        padding='8px'
        bgColor='grey.offWhite.dark'
        borderRadius='4px'
        borderWidth='1px'
        borderColor='grey.border.dark'
        onClick={onClick}
      >
        <HStack gap='8px' justifyContent='space-between'>
          <HStack>
            <SearchIcon stroke='grey.secondaryText.dark' w='20px' h='20px' />
            <Text
              fontSize='16px'
              fontWeight='500'
              color='grey.secondaryText.dark'
            >
              {t('search')}
            </Text>
          </HStack>
          <CommandPanelShortcut />
        </HStack>
      </Button>
      <CommandPanelModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
