import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';

import { fetchDataSystemStatusAtom } from '../atoms/dataSystemStatusAtom';

/**
 * Check data system status once after user has been fetched
 */
export function useCheckDataSystemStatus() {
  const { user } = useCurrentProfile();
  const [{ dataSystemStatus }, fetchDataSystemStatus] = useAtom(
    fetchDataSystemStatusAtom,
  );
  useEffect(() => {
    if (user && !dataSystemStatus) {
      fetchDataSystemStatus({});
    }
  }, [fetchDataSystemStatus, user, dataSystemStatus]);
}
