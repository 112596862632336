import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

import { atom, useAtomValue, useSetAtom } from 'jotai';

import { INTERCOM_ID } from 'data/constants/intercom';
import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';

declare const window: Window &
  typeof globalThis & {
    Intercom: typeof Intercom;
  };

export const showIntercom = atom(true);

export function useIntercom() {
  const { user, currentProfile } = useCurrentProfile();
  const hideIntercom = useHideIntercomCallback();
  const showIntercomValue = useAtomValue(showIntercom);

  const companyName = currentProfile?.company?.name ?? '';
  const companyNamespace = currentProfile?.company?.namespace ?? '';

  useEffect(() => {
    if (!INTERCOM_ID || !window.Intercom) return;

    if (!user && window.Intercom.booted) {
      window.Intercom('shutdown');
    }

    if (user) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_ID,
        user_id: user.id,
        company: {
          name: companyName,
          id: companyNamespace,
        },
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });

      if (!showIntercomValue) {
        hideIntercom();
      }
    }
  }, [hideIntercom, showIntercomValue, user, companyName, companyNamespace]);
}

export function useHideIntercomCallback() {
  return useCallback(() => {
    if (!window.Intercom) return;

    window.Intercom('update', { hide_default_launcher: true });
    window.Intercom('hide');
  }, []);
}

export function useShowIntercomCallback() {
  return useCallback(() => {
    if (!window.Intercom) return;

    window.Intercom('update', { hide_default_launcher: false });
  }, []);
}

export function useSwitchIntercomVisibility(isVisible: boolean) {
  const router = useRouter();
  const hideIntercom = useHideIntercomCallback();
  const showIntercom = useShowIntercomCallback();

  useEffect(() => {
    router.events.on('routeChangeStart', showIntercom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible) {
      hideIntercom();
    } else {
      showIntercom();
    }
  }, [hideIntercom, isVisible, showIntercom]);
}

export function useHideIntercom() {
  const setShowIntercom = useSetAtom(showIntercom);

  return useCallback(() => {
    return setShowIntercom(false);
  }, [setShowIntercom]);
}

export function showUnauthorizedIntercom() {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: INTERCOM_ID,
  });
  window.Intercom(
    'showNewMessage',
    "Hey👋, I've forgot my password, could you please help me change it",
  );
}
