import { ColorProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface SentimentOption {
  label: string;
  color: ColorProps['color'];
  backgroundColor: ColorProps['color'];
  value: string | null;
}

export const useSentimentOptions = (): SentimentOption[] => {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs.overview_tab.sentiment.options',
  });

  return [
    {
      label: t('top_priority'),
      value: 'top-priority',
      color: 'primary.text',
      backgroundColor: 'primary.background',
    },
    {
      label: t('high_priority'),
      value: 'high-priority',
      color: 'cyan.text',
      backgroundColor: 'cyan.background',
    },
    {
      label: t('medium_priority'),
      value: 'medium-priority',
      color: 'tertiary.text',
      backgroundColor: 'tertiary.background',
    },
    {
      label: t('low_priority'),
      value: 'low-priority',
      color: 'grey.text',
      backgroundColor: 'grey.background',
    },
    {
      label: t('no_priority'),
      value: null,
      color: 'grey.tertiaryText',
      backgroundColor: 'grey.background',
    },
  ];
};
