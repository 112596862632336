import { useCallback, useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { Chat, ChatContext } from 'data/ai/models';
import { useConnectedDepartments } from 'data/dataSources/hooks/useDataSources';
import { useIsEnterprise } from 'utils/useIsEnterprise';

import {
  aiArchiveDrawerAtom,
  aiDrawerAtom,
  aiFollowUpQuestionsAtom,
  aiResponseAtom,
  fetchAiChatMessagesAtom,
  fetchInsightFollowUpQuestionsAtom,
  getInsightFetchingAtomPair,
} from './atoms';

export const useOpenAiDrawer = () => {
  const setAiDrawer = useSetAtom(aiDrawerAtom);

  return useCallback(() => {
    setAiDrawer(true);
  }, [setAiDrawer]);
};

export const useCloseAiDrawer = () => {
  const setAiDrawer = useSetAtom(aiDrawerAtom);

  return useCallback(() => {
    setAiDrawer(false);
  }, [setAiDrawer]);
};

export const useAiDrawerOpen = () => {
  return useAtomValue(aiDrawerAtom);
};

export const useResetResults = () => {
  const setResults = useSetAtom(aiResponseAtom);
  const setFollowUpQuestions = useSetAtom(aiFollowUpQuestionsAtom);

  return useCallback(
    (context: ChatContext = { type: 'CHAT' }) => {
      setResults({
        results: [],
        errorMessage: '',
        fetchStatus: 'INITIAL',
        context,
      });
      setFollowUpQuestions({
        questions: [],
        errorMessage: '',
        fetchStatus: 'INITIAL',
      });
    },
    [setResults, setFollowUpQuestions],
  );
};

export const useOpenInsightDrawer = (
  insightId: string,
  title?: string,
  description?: string,
) => {
  const resetResults = useResetResults();
  const openDrawer = useOpenAiDrawer();
  const setAiArvchiveDrawer = useSetAtom(aiArchiveDrawerAtom);
  const fetchFollowUpQuestions = useSetAtom(fetchInsightFollowUpQuestionsAtom);

  return useCallback(() => {
    resetResults({ type: 'INSIGHT', insightId, name: title, description });
    openDrawer();
    setAiArvchiveDrawer(false);
    fetchFollowUpQuestions(insightId);
  }, [
    resetResults,
    openDrawer,
    fetchFollowUpQuestions,
    insightId,
    title,
    description,
    setAiArvchiveDrawer,
  ]);
};

export const useDefaultFollowUpQuestions = () => {
  const { departments, ...remote } = useConnectedDepartments();
  const isEnterprise = useIsEnterprise();

  const questions = isEnterprise
    ? [
        'How has GMV trended over the past 6 months?',
        'What was the average checkout conversion rate in July 2024?',
        "What's the split in GMV by Buyer country?",
      ]
    : departments.FINANCE
    ? [
        'How has MRR churn trended over the past 6 months?',
        `In which week in ${new Date().getFullYear()} have we added the most new customers?`,
        'What is the trend in MRR by subscription type?',
      ]
    : departments.PRODUCT
    ? [
        'What was the trend in Daily Active Users last month?',
        'What is the ratio of WAUs/MAUs?',
        'How many power users are there?',
      ]
    : departments.GROWTH
    ? [
        'What is the trend in Sessions by Channel?',
        'What is the trend in New Visitors by Country?',
        'What is the average time on site by Device type?',
      ]
    : departments.SALES
    ? [
        'What are the largest deals in the sales pipeline?',
        'How many deals have been won in the last 3 months?',
        'What is the trend in New deals added to the pipeline?',
      ]
    : [];

  return { questions, ...remote };
};

export const useInsightActions = (insightId: string, prefetch = false) => {
  const { fetchingAtom } = getInsightFetchingAtomPair(insightId);

  const [actionsRemote, fetchActions] = useAtom(fetchingAtom);

  useEffect(() => {
    if (prefetch) {
      fetchActions(insightId);
    }
  }, [prefetch, insightId, fetchActions]);

  return { actionsRemote, fetchActions };
};

export const useSelectChat = () => {
  const resetResults = useResetResults();
  const fetchChatMessages = useSetAtom(fetchAiChatMessagesAtom);

  return useCallback(
    (chat: Chat) => {
      resetResults({
        type: chat.type,
        id: chat.id,
        name: chat.name,
        description: chat.description,
      });
      fetchChatMessages(chat);
    },
    [resetResults, fetchChatMessages],
  );
};
