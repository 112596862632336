import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { useCustomerActiveDaysBlocks } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useCustomerActiveDaysBlocks';
import { useCustomerUserAtoms } from 'data/healthReports/atoms/customerUserAtoms';
import { isInitial } from 'utils/types';

export function useActiveUsersData(userId: string) {
  const { chartBlock } = useCustomerActiveDaysBlocks();
  const { activeUsersChartAtoms } = useCustomerUserAtoms(userId);

  const [
    {
      fetchStatus: chartFetchStatus,
      aggPeriod: chartAggPeriod,
      data: chartData,
    },
    fetchChart,
  ] = useAtom(activeUsersChartAtoms.fetchingAtom);

  useEffect(() => {
    if (isInitial(chartFetchStatus) && chartBlock) {
      fetchChart(chartBlock.id);
    }
  }, [chartBlock, chartFetchStatus, fetchChart]);

  return {
    chart: {
      data: chartData,
      aggPeriod: chartAggPeriod,
      fetchStatus: chartFetchStatus,
    },
  };
}
