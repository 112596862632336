import { Box, Text } from 'components/uikit';
import { CustomerTag } from 'data/customer/CustomerTag';

export function TagCheckboxLabel({ tag }: { tag: CustomerTag }) {
  return (
    <Box>
      <Text
        noOfLines={1}
        fontSize={'14px'}
        lineHeight={1.6}
        color={'grey.primaryText'}
      >
        {tag.title}
      </Text>
      <Text
        noOfLines={1}
        fontSize={'14px'}
        lineHeight={1.5}
        color={'grey.tertiaryText'}
      >
        {tag.description}
      </Text>
    </Box>
  );
}
