import { RadarInsightDesktopDescription } from 'components/pages/Radar/RadarInsight/RadarInsightDesktopDescription';
import { Text, VStack } from 'components/uikit';

interface AiAnswerTextProps {
  answer: string;
  description?: string;
  showSeparator?: boolean;
}

export function AiAnswerHeader({
  answer,
  description,
  showSeparator = false,
}: AiAnswerTextProps) {
  return (
    <VStack
      px='24px'
      py='16px'
      borderBottom={showSeparator ? '1px solid' : 'none'}
      borderColor='grey.border'
      spacing='12px'
      align='stretch'
    >
      <Text fontWeight='medium' fontSize='lg'>
        {answer}
      </Text>
      {description ? (
        <RadarInsightDesktopDescription
          description={description}
          type='INSIGHT'
        />
      ) : null}
    </VStack>
  );
}
