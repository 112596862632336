import { z } from 'zod';

import { SalesStageMappingType } from 'data/settings/models/SalesStages';

export const PipelineMapping = z.object({
  id: z.string(),
  name: z.string(),
  enabled: z.boolean(),
});

export type PipelineMapping = z.infer<typeof PipelineMapping>;

export const StageMapping = z.object({
  pipelineId: z.string(),
  stage: z.string(),
  type: SalesStageMappingType,
});

export type StageMapping = z.infer<typeof StageMapping>;

export const SalesSettings = z.object({
  emptyValueColumn: z.boolean().optional(),
  pipelines: z.array(PipelineMapping).default([]),
  stagesMappings: z.array(StageMapping).default([]),
  updating: z.boolean().default(false),
});

export type SalesSettings = z.infer<typeof SalesSettings>;
