import { localStorageManager } from '@chakra-ui/color-mode';
import { z } from 'zod';

import { theme } from 'components/theme';
import { useParsedQueryParam } from 'utils/router/useQueryParam';

export const ColorMode = z.enum(['light', 'dark', 'system']);

export const useGetInitialColorMode = () => {
  theme.config.initialColorMode =
    useParsedQueryParam(ColorMode, 'colorMode') ??
    localStorageManager.get() ??
    theme.config.initialColorMode;
  localStorageManager.set(theme.config.initialColorMode);
};
