import { ReactNode, useCallback, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ChevronDownIcon,
  Flex,
  List,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  ButtonProps,
} from 'components/uikit';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

import { SelectRow } from './SelectRow';

export interface SelectItem<T> {
  label: ReactNode;
  description?: ReactNode;
  value: T;
}

export interface SelectProps<T> extends Omit<ButtonProps, 'onChange'> {
  label?: ReactNode;
  items: SelectItem<T>[];
  onChange: (value: T) => void;
  selectedItem?: T;
  noneSelectedItemText?: string;
  height?: ButtonProps['height'];
  width?: ButtonProps['width'];
  placement?: 'bottom-start' | 'bottom-end';
}

export function Select<T>({
  label,
  items,
  onChange,
  selectedItem,
  noneSelectedItemText,
  height = '38px',
  placement = 'bottom-start',
  width = '100%',
  ...rest
}: SelectProps<T>) {
  const [hoveredItem, setHoveredItem] = useState<T | null>(null);
  const [isActive, setIsActive] = useState(false);
  const { onClose } = useDisclosure();
  const { t } = useTranslation('common');

  const onDropdownClose = useCallback(() => {
    onClose();
    setIsActive(false);
  }, [onClose]);

  const handleSelection = useCallback(
    (value: T) => {
      onChange(value);
      onDropdownClose();
      return value;
    },
    [onChange, onDropdownClose],
  );

  const selectHoveredItem = useCallback(() => {
    if (hoveredItem === null) return;
    handleSelection(hoveredItem);
  }, [hoveredItem, handleSelection]);

  useKeyAction('CLOSE', onClose, {
    preventDefault: true,
    enabled: isActive,
  });

  useKeyAction('CONFIRM', selectHoveredItem, {
    preventDefault: true,
    enabled: isActive,
  });

  return (
    <Popover
      placement={placement}
      onClose={onDropdownClose}
      onOpen={() => {
        setIsActive(true);
      }}
      isOpen={isActive}
    >
      <PopoverTrigger>
        <Button
          p={0}
          height={'calc(' + height + ' + 2px)'}
          width={width}
          variant='grayOutline'
          _hover={{
            'div:last-child': {
              backgroundColor: rest?.backgroundColor ?? 'grey.offWhite',
            },
          }}
          {...rest}
        >
          {label ? (
            <Flex
              p='8px'
              h={height}
              color='grey.tertiaryText'
              alignItems='center'
              borderLeftRadius='3px'
              fontSize='sm'
              backgroundColor='grey.offWhite'
              borderRight='1px solid'
              borderColor='grey.border'
            >
              {label}
            </Flex>
          ) : null}
          <Flex
            width='100%'
            backgroundColor={rest?.backgroundColor ?? 'grey.white'}
            p='8px'
            h={height}
            alignItems='center'
            borderRightRadius='3px'
            color={rest?.color ?? 'secondary.button'}
            fontSize='sm'
            justifyContent='space-between'
            minW='76px'
          >
            {selectedItem
              ? items.find((item) => item.value === selectedItem)?.label
              : noneSelectedItemText ?? t('none')}
            <ChevronDownIcon w='20px' h='20px' stroke='currentColor' />
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='4px'
        borderColor='grey.border'
        border='1px'
        mt='4px'
        overflow='hidden'
      >
        <PopoverBody p={0} borderRadius='4px'>
          <List
            items={items}
            hotkeysActive={isActive}
            onItemSelected={(item) => {
              if (item) setHoveredItem(item.value);
            }}
            renderItem={(
              { label, value, description },
              isHovered,
              hoverItem,
              removeHover,
              index,
            ) => (
              <SelectRow
                label={label}
                description={description}
                index={index}
                isActive={isActive}
                isHovered={isHovered}
                onSelect={() => handleSelection(value)}
                onHover={hoverItem}
                onBlur={removeHover}
              />
            )}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
