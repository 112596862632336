import { AnalyticsEventType, AnalyticsEventGroup } from 'utils/analytics';

export class DictionaryAnalyticsEvents extends AnalyticsEventType {
  static DEPARTMENT_SWITCHED = new DictionaryAnalyticsEvents(
    'dictionary-department-switched',
  );
  static METRIC_SWITCHED = new DictionaryAnalyticsEvents(
    'dictionary-metric-switched',
  );
  static METRIC_SPLIT_TOGGLED = new DictionaryAnalyticsEvents(
    'dictionary-metric-split-toggled',
  );
  static DICTIONARY_OPENED = new DictionaryAnalyticsEvents('dictionary-opened');
  static REPORT_DATA_ERROR = new DictionaryAnalyticsEvents(
    'dictionary-report-data-error',
  );
  static CHART_ERROR = new AnalyticsEventType(
    'chart-error',
    AnalyticsEventGroup.DICTIONARY,
  );

  private constructor(readonly action: string) {
    super(action, AnalyticsEventGroup.DICTIONARY);
  }
}
