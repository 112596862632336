import { useCallback, useEffect } from 'react';

import { Profile, User } from 'api/graphql/backendAPI/generatedTypes';
import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';
import {
  AnalyticsEventType,
  trackEvent,
  unidentifiedEvents,
  trackUserEvent,
} from 'utils/analytics';

export function useUserEvent() {
  const { user, currentProfile } = useCurrentProfile();

  return useCallback(
    async (event: AnalyticsEventType, payload: Record<string, unknown> = {}) =>
      await trackUserEvent(user, currentProfile, event, payload),
    [user, currentProfile],
  );
}

export async function replayUnidentifiedEvents(
  user: User,
  currentProfile: Profile,
) {
  await Promise.all(
    unidentifiedEvents.map(async ({ type, payload }) => {
      await trackEvent(type, payload, user, currentProfile);
    }),
  );
}

export function useTrackUserEvent(
  event: AnalyticsEventType,
  payload: Record<string, unknown> = {},
) {
  const trackEvent = useUserEvent();

  useEffect(() => {
    trackEvent(event, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
