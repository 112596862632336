import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { DateRangeControlOptions } from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownTable } from 'components/charts/FullBreakdownTable/FullBreakdownTable';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';
import { useCustomerFinanceRevenueBreakdownConfig } from 'data/blocks/hooks/tableConfigs/breakdowns/product/useCustomerFinanceRevenueBreakdownConfig';
import { useCustomerUserAtoms } from 'data/healthReports/atoms/customerUserAtoms';
import { isInitial } from 'utils/types';

export function FinanceRevenueBreakdown({
  customerId,
}: {
  customerId: string;
}) {
  const { showFinanceBlocks } = useShowDepartmentBlocksForUser(customerId);
  const { blocks } = useCustomerBreakdownBlocks();
  const breakdownBlock = blocks.find(
    (block) => block.uniqueName == 'CUSTOMER_USER_FINANCE_REVENUE_BREAKDOWN',
  );

  if (!showFinanceBlocks || !breakdownBlock) return null;

  return (
    <EngagementBreakdownWithBlockId
      customerId={customerId}
      blockId={breakdownBlock.id}
    />
  );
}

function EngagementBreakdownWithBlockId({
  customerId,
  blockId,
}: {
  customerId: string;
  blockId: string;
}) {
  const { revenueBreakdownAtoms } = useCustomerUserAtoms(customerId);
  const { fetchingAtom } = revenueBreakdownAtoms;
  const [{ data, fetchStatus }, fetchData] = useAtom(fetchingAtom);

  useEffect(() => {
    if (isInitial(fetchStatus)) {
      fetchData(blockId);
    }
  }, [blockId, fetchData, fetchStatus]);

  const config = useCustomerFinanceRevenueBreakdownConfig();

  return (
    <FullBreakdownTable
      blockId={blockId}
      width='100%'
      trackingLabel='revenueBreakdown'
      fetchStatus={fetchStatus}
      config={config.tableConfig}
      borderRadius={'0px'}
      borderWidth={'0px'}
      numRows={5}
      data={data}
      dateRangeControl={DateRangeControlOptions.DATE_PICKER_FIXED_END_DATE}
    />
  );
}
