import { ReactNode, useCallback, useEffect, useState } from 'react';

import { ScrollsIntoView } from 'components/uikit/ScrollsIntoView';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

interface ListProps<T> {
  items: T[];
  initialSelection?: T | null;
  renderItem: (
    item: T,
    isSelected: boolean,
    selectItem: () => void,
    removeSelection: () => void,
    index: number,
  ) => ReactNode;
  onItemSelected?: (item: T | null, index: number | null) => void;
  isItemDisabled?: (item: T) => boolean;
  hotkeysActive?: boolean;
}

export function List<T>({
  items,
  initialSelection = null,
  renderItem,
  isItemDisabled,
  onItemSelected,
  hotkeysActive = true,
}: ListProps<T>) {
  const [selectedItem, setSelectedItem] = useState<T | null>(initialSelection);

  useEffect(() => {
    if (selectedItem === null && initialSelection) {
      setSelectedItem(initialSelection);
    }
  }, [selectedItem, initialSelection]);

  const selectItem = useCallback(
    (item: T | null, index: number | null) => {
      setSelectedItem(item);
      onItemSelected?.(item, index);
    },
    [setSelectedItem, onItemSelected],
  );

  const selectNextAvailableItem = useCallback(
    (index: number): void => {
      if (index >= items.length) return;
      const nextItem = items[index];
      if (!nextItem) return;

      const isDisabled = isItemDisabled?.(nextItem) ?? false;

      if (isDisabled) return selectNextAvailableItem(index + 1);
      selectItem(nextItem, index);
    },
    [items, selectItem, isItemDisabled],
  );

  const selectPrevAvailableItem = useCallback(
    (index: number): void => {
      if (index < 0) return;

      const prevItem = items[index];
      if (!prevItem) return;

      const isDisabled = isItemDisabled?.(prevItem) ?? false;

      if (isDisabled) return selectPrevAvailableItem(index - 1);
      selectItem(prevItem, index);
    },
    [items, selectItem, isItemDisabled],
  );

  const selectNextItem = useCallback(() => {
    const nextIndex = selectedItem ? items.indexOf(selectedItem) + 1 : 0;
    selectNextAvailableItem(nextIndex);
  }, [items, selectedItem, selectNextAvailableItem]);

  const selectPrevItem = useCallback(() => {
    const prevIndex = selectedItem
      ? items.indexOf(selectedItem) - 1
      : items.length - 1;
    selectPrevAvailableItem(prevIndex);
  }, [items, selectedItem, selectPrevAvailableItem]);

  useKeyAction('UP', selectPrevItem, { enabled: hotkeysActive });
  useKeyAction('DOWN', selectNextItem, { enabled: hotkeysActive });

  return (
    <>
      {items.map((item, index) => {
        return (
          <ScrollsIntoView key={index} selected={item === selectedItem}>
            {renderItem(
              item,
              item === selectedItem,
              () => selectItem(item, index),
              () => selectItem(null, null),
              index,
            )}
          </ScrollsIntoView>
        );
      })}
    </>
  );
}
