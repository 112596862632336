import { useMemo } from 'react';

import { BarChart } from 'components/charts/BarChart/BarChart';
import { FinanceTooltipContent } from 'components/charts/FinanceTooltipContent';
import { LineChart } from 'components/charts/LineChart/LineChart';
import { AiTimeseriesChartData } from 'data/ai/models';
import { useFormatTooltipTitle } from 'data/charts/hooks/useFormatTooltipTitle';
import { formatNumericDatum, getChartColors } from 'utils/chartsUtils';
import { useColorPalette } from 'utils/hooks/useColorPalette';

type AiLineChartProps = AiTimeseriesChartData;

export function AiAnswerLineChart({ data, config }: AiLineChartProps) {
  const formatter = useFormatTooltipTitle(config.timeRange.granularity);
  const colors = useColorPalette();
  const hasNegativeValues = useMemo(
    () =>
      Object.values(data)
        .flatMap(({ values }) => values)
        .some((item) => {
          return item !== null && Number(item) < 0;
        }),
    [data],
  );
  // TODO: refactor this to have rules
  if (
    ([
      'dictionaryMrrLostChart',
      'dictionaryContractionChart',
      'dictionaryChurnChart',
      'dictionaryNetMrrChangeChart',
      'netCustomersChangeChart',
      'churnChart',
      'mrrMovements',
    ].includes(config.chartName) ||
      config.split) &&
    ![
      'checkoutConversionRateChart',
      'paymentAcceptanceRate',
      'paymentInitiatedRate',
    ].includes(config.chartName)
  ) {
    const isChurn = config.chartName.includes('churn') && !config.split;

    return (
      <BarChart
        id={config.chartName}
        data={data}
        tooltipContentFormat={FinanceTooltipContent}
        fetchStatus='SUCCESS'
        colors={
          isChurn ? [colors.red] : getChartColors(colors, Object.keys(data))
        }
        aggPeriod={config.timeRange.granularity}
        height={300}
        align={isChurn ? 'top' : hasNegativeValues ? 'center' : 'bottom'}
      />
    );
  }
  return (
    <LineChart
      data={data}
      fetchStatus='SUCCESS'
      legend='none'
      granularity={config.timeRange.granularity}
      height={300}
      colors={getChartColors(colors, Object.keys(data))}
      tooltipDataFormatter={formatNumericDatum}
      tooltipTitleFormatter={formatter}
      tooltipAnchor='left'
    />
  );
}
