import { StackProps } from '@chakra-ui/layout';

import { HStack, Stack } from 'components/uikit';

export function SharingBlockModalBodyDefaultContentContainer(
  props: StackProps,
) {
  return (
    <Stack
      paddingX='24px'
      paddingY='16px'
      justify='top'
      align='left'
      spacing='16px'
      width='100%'
      height='100%'
      fontWeight='medium'
      background={'grey.offWhite'}
      {...props}
    />
  );
}

export function SharingBlockModalBodyDefaultActionsContainer(
  props: StackProps,
) {
  return (
    <HStack
      justify='right'
      align='center'
      spacing='16px'
      bottom='0px'
      minHeight='72px'
      width='100%'
      borderTop='0px solid'
      borderTopColor={'grey.border'}
      paddingX='24px'
      background={'grey.offWhite'}
      {...props}
    />
  );
}

export function SharingBlockModalBodyDefaultContainer(props: StackProps) {
  return <Stack spacing='0px' alignSelf='stretch' width='100%' {...props} />;
}
