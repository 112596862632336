import { FC, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';

import { SidebarCard } from 'components/SidebarMenu/SidebarCards/SidebarCard';
import { ArrowUpRightIcon, CopyIcon, VStack } from 'components/uikit';
import { useCopyInviteLink } from 'data/common/useCopyInviteLink';
import { useUpdateProductTourConfig } from 'data/user/hooks/useUpdateProductTourConfig';
import { useUserProductTourConfig } from 'data/user/hooks/useUserProductTourConfig';
import paths from 'utils/paths';
import { isLoading, isSuccess } from 'utils/types';

export const SidebarCards: FC = () => {
  return (
    <VStack marginTop='auto'>
      <InviteTeamCard />
      <GetBetterInsightsCard />
    </VStack>
  );
};

const InviteTeamCard = () => {
  const { t } = useTranslation('sidebar');
  const { config, fetchStatus } = useUserProductTourConfig();
  const { updateTourConfig } = useUpdateProductTourConfig();
  const { hidInviteTeamSidebarCard } = config;
  const [visible, setVisible] = useState(!hidInviteTeamSidebarCard);

  useEffect(() => {
    if (isSuccess(fetchStatus)) {
      setVisible(!hidInviteTeamSidebarCard);
    }
  }, [setVisible, fetchStatus]);

  const onCopy = useCopyInviteLink();
  const onClose = async () => {
    setVisible(false);
    try {
      await updateTourConfig('hidInviteTeamSidebarCard');
    } catch (e) {
      setVisible(true);
    }
  };
  if (!visible || isLoading(fetchStatus)) {
    return null;
  }
  return (
    <SidebarCard
      title={t('invite_team_member_card.title')}
      description={t('invite_team_member_card.description')}
      cta={t('invite_team_member_card.cta')}
      ctaIcon={<CopyIcon stroke='primary.button.dark' />}
      onClose={onClose}
      onCtaClick={onCopy}
    />
  );
};

const GetBetterInsightsCard = () => {
  const { t } = useTranslation('sidebar');
  const { config, fetchStatus } = useUserProductTourConfig();
  const { updateTourConfig } = useUpdateProductTourConfig();
  const router = useRouter();
  const { hidGetBetterInsightsSidebarCard } = config;
  const [visible, setVisible] = useState(!hidGetBetterInsightsSidebarCard);
  useEffect(() => {
    if (isSuccess(fetchStatus)) {
      setVisible(!hidGetBetterInsightsSidebarCard);
    }
  }, [setVisible, fetchStatus]);
  const onClose = async () => {
    setVisible(false);
    try {
      await updateTourConfig('hidGetBetterInsightsSidebarCard');
    } catch (e) {
      setVisible(true);
    }
  };
  if (!visible || isLoading(fetchStatus)) {
    return null;
  }
  return (
    <SidebarCard
      title={t('get_better_insights_card.title')}
      description={t('get_better_insights_card.description')}
      cta={t('get_better_insights_card.cta')}
      ctaIcon={<ArrowUpRightIcon stroke='primary.button.dark' />}
      onClose={onClose}
      onCtaClick={() => router.push(paths.dataSources.connect)}
    />
  );
};
