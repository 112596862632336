import { createIcon } from '@chakra-ui/react';

export const CopyAsImagePlaceholder = createIcon({
  displayName: 'CopyAsImagePlaceholder',
  viewBox: '0 0 544 328',
  path: (
    <svg
      width='544'
      height='328'
      viewBox='0 0 544 328'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_19522_74740)'>
        <rect
          x='6'
          y='2.00098'
          width='532'
          height='316'
          rx='12'
          fill='white'
          shapeRendering='crispEdges'
        />
        <rect
          x='6.5'
          y='2.50098'
          width='531'
          height='315'
          rx='11.5'
          stroke='#E3E8EF'
          shapeRendering='crispEdges'
        />
        <g filter='url(#filter1_dd_19522_74740)'>
          <line
            x1='71'
            y1='76.4639'
            x2='521.992'
            y2='76.4639'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='115.063'
            x2='521.992'
            y2='115.063'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='153.663'
            x2='521.992'
            y2='153.663'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='190.265'
            x2='521.992'
            y2='190.265'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='229.864'
            x2='521.992'
            y2='229.864'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='274.464'
            x2='521.992'
            y2='274.464'
            stroke='#E3E8EF'
          />
          <line
            x1='71'
            y1='76.4639'
            x2='521.992'
            y2='76.4639'
            stroke='#E3E8EF'
          />
          <path
            d='M30 28.001C30 26.8964 30.8271 26.001 31.8473 26.001H249.832C250.852 26.001 251.679 26.8964 251.679 28.001V48.001C251.679 49.1055 250.852 50.001 249.832 50.001H31.8473C30.8271 50.001 30 49.1055 30 48.001V28.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 73.001C30 71.8964 30.8271 71.001 31.8473 71.001H57.7099C58.7302 71.001 59.5573 71.8964 59.5573 73.001V81.001C59.5573 82.1055 58.7302 83.001 57.7099 83.001H31.8473C30.8271 83.001 30 82.1055 30 81.001V73.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 111.001C30 109.896 30.8271 109.001 31.8473 109.001H57.7099C58.7302 109.001 59.5573 109.896 59.5573 111.001V119.001C59.5573 120.106 58.7302 121.001 57.7099 121.001H31.8473C30.8271 121.001 30 120.106 30 119.001V111.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 149.001C30 147.896 30.8271 147.001 31.8473 147.001H57.7099C58.7302 147.001 59.5573 147.896 59.5573 149.001V157.001C59.5573 158.106 58.7302 159.001 57.7099 159.001H31.8473C30.8271 159.001 30 158.106 30 157.001V149.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 187.001C30 185.896 30.8271 185.001 31.8473 185.001H57.7099C58.7302 185.001 59.5573 185.896 59.5573 187.001V195.001C59.5573 196.106 58.7302 197.001 57.7099 197.001H31.8473C30.8271 197.001 30 196.106 30 195.001V187.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 225.001C30 223.896 30.8271 223.001 31.8473 223.001H57.7099C58.7302 223.001 59.5573 223.896 59.5573 225.001V233.001C59.5573 234.106 58.7302 235.001 57.7099 235.001H31.8473C30.8271 235.001 30 234.106 30 233.001V225.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M30 268.001C30 266.896 30.8271 266.001 31.8473 266.001H57.7099C58.7302 266.001 59.5573 266.896 59.5573 268.001V276.001C59.5573 277.106 58.7302 278.001 57.7099 278.001H31.8473C30.8271 278.001 30 277.106 30 276.001V268.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M92.8092 284.001C92.8092 282.896 93.6362 282.001 94.6565 282.001H120.519C121.539 282.001 122.366 282.896 122.366 284.001V292.001C122.366 293.106 121.539 294.001 120.519 294.001H94.6565C93.6362 294.001 92.8092 293.106 92.8092 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M166.702 284.001C166.702 282.896 167.529 282.001 168.55 282.001H194.412C195.432 282.001 196.26 282.896 196.26 284.001V292.001C196.26 293.106 195.432 294.001 194.412 294.001H168.55C167.529 294.001 166.702 293.106 166.702 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M240.595 284.001C240.595 282.896 241.422 282.001 242.443 282.001H268.305C269.326 282.001 270.153 282.896 270.153 284.001V292.001C270.153 293.106 269.326 294.001 268.305 294.001H242.443C241.422 294.001 240.595 293.106 240.595 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M314.489 284.001C314.489 282.896 315.316 282.001 316.336 282.001H342.198C343.219 282.001 344.046 282.896 344.046 284.001V292.001C344.046 293.106 343.219 294.001 342.198 294.001H316.336C315.316 294.001 314.489 293.106 314.489 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M388.382 284.001C388.382 282.896 389.209 282.001 390.229 282.001H416.092C417.112 282.001 417.939 282.896 417.939 284.001V292.001C417.939 293.106 417.112 294.001 416.092 294.001H390.229C389.209 294.001 388.382 293.106 388.382 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M462.275 284.001C462.275 282.896 463.102 282.001 464.122 282.001H489.985C491.005 282.001 491.832 282.896 491.832 284.001V292.001C491.832 293.106 491.005 294.001 489.985 294.001H464.122C463.102 294.001 462.275 293.106 462.275 292.001V284.001Z'
            fill='#E3E8EF'
          />
          <path
            d='M85.4198 205.594H129.756V273.703H85.4198V205.594Z'
            fill='#0BA5EC'
          />
          <path
            d='M159.313 182.449H203.649V273.703H159.313V182.449Z'
            fill='#0BA5EC'
          />
          <path
            d='M233.206 137.213H277.542V273.703H233.206V137.213Z'
            fill='#0BA5EC'
          />
          <path
            d='M307.099 110.001H351.435V273.703H307.099V110.001Z'
            fill='#0BA5EC'
          />
          <path
            d='M380.992 167.778H425.328V273.703H380.992V167.778Z'
            fill='#0BA5EC'
          />
          <path
            d='M454.885 114.816H499.221V273.703H454.885V114.816Z'
            fill='#0BA5EC'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_19522_74740'
          x='0'
          y='0.000976562'
          width='544'
          height='328'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_19522_74740'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_19522_74740'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_19522_74740'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_19522_74740'
            result='effect2_dropShadow_19522_74740'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_19522_74740'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_dd_19522_74740'
          x='8'
          y='8.00098'
          width='528'
          height='312'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_19522_74740'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_19522_74740'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_19522_74740'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_19522_74740'
            result='effect2_dropShadow_19522_74740'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_19522_74740'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  ),
});
