export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN;
export const AUTH_DOMAIN = `auth.${DOMAIN}`;
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const PROTECTED_API_URL = process.env.NEXT_PUBLIC_PROTECTED_API_URL;
export const BLOCKS_API_URL =
  process.env.NEXT_PUBLIC_BLOCKS_API_URL ??
  process.env.NEXT_PUBLIC_PROTECTED_API_URL;
export const INSIGHTS_API_URL =
  process.env.NEXT_PUBLIC_INSIGHTS_API_URL ??
  process.env.NEXT_PUBLIC_PROTECTED_API_URL;
export const CHARTS_API_URL =
  process.env.NEXT_PUBLIC_CHARTS_API_URL ?? `${PROTECTED_API_URL}/charts`;

export const USERS_API_URL =
  process.env.NEXT_PUBLIC_USERS_API_URL ?? `${PROTECTED_API_URL}/companies-new`;

export const DATA_API_URL = process.env.NEXT_PUBLIC_DATA_API_URL;
export const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_API_URL ?? '';
export const DATA_GRAPHQL_URL =
  process.env.NEXT_PUBLIC_DATA_GRAPHQL_API_URL ?? '';
