import { TFunction } from 'react-i18next';

export const getPercentileCopy = (
  quartile: number,
  isTopCustomer: boolean,
  topCustomersCount: number,
  t: TFunction,
): string => {
  if (isTopCustomer) {
    return t('top_customers', {
      count: topCustomersCount,
    });
  }

  switch (quartile) {
    case 1:
      return t('bottom_25_pct_customers');
    case 2:
      return t('bottom_50_pct_customers');
    case 3:
      return t('top_50_pct_customers');
    case 4:
      return t('top_25_pct_customers');
    default:
      throw Error(`Invalid quartile value: ${quartile}`);
  }
};
