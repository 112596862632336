import { createIcon } from '@chakra-ui/react';

export const GlossaryIcon = createIcon({
  displayName: 'GlossaryIcon',
  viewBox: '0 0 24 24',
  path: (
    <g opacity='0.75'>
      <path
        d='M6 3V21M6 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H6M6 3C7.46992 3 16.8959 3 20.006 3C20.5583 3 21 3.44772 21 4V20C21 20.5523 20.5576 21 20.0053 21C17.1031 21 8.68226 21 6 21'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 16L11 13L14 15L18 11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 7L18 7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1 2'
      />
    </g>
  ),
});
