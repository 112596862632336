import { useAnimatedPath } from '@nivo/core';
import { CustomLayerProps } from '@nivo/line';
import { animated } from '@react-spring/web';

export type AreaGenerator = CustomLayerProps['lineGenerator'];
type GeneratedArea = ReturnType<AreaGenerator>;

export function AreaSegment(props: {
  generatedArea: GeneratedArea;
  color: string;
  areaSegmentId: string;
}) {
  const { generatedArea, color, areaSegmentId } = props;
  const animatedArea = useAnimatedPath(generatedArea);

  return (
    <>
      <defs>
        <linearGradient
          id={`areaGradient-${areaSegmentId}`}
          x1='0'
          y1='0'
          x2='0'
          y2='1'
        >
          <stop offset='0%' stopColor={color} stopOpacity={1} />
          <stop offset='100%' stopColor='transparent' stopOpacity={0} />
        </linearGradient>
      </defs>
      <animated.path
        d={animatedArea}
        fill={`url(#areaGradient-${areaSegmentId})`}
        fillOpacity={0.2}
      />
    </>
  );
}
