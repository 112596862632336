import { format } from 'date-fns';

import { BarData } from 'data/charts/models/BarData';
import { TimeSeries } from 'data/responseModels/TimeSeries';
import { groupBy, mapValues } from 'utils/helpers';

export function formatBarData(
  getKeyLabel: (name: string) => string,
  timeSeries: {
    [k: string]: {
      labels: TimeSeries['labels'];
      values: TimeSeries['values'];
    };
  },
): BarData[] {
  const flatData = Object.entries(timeSeries).flatMap(
    ([seriesName, seriesResult]) =>
      seriesResult.values.map((value, index) => ({
        value,
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        period: seriesResult.labels[index]!,
        label: getKeyLabel(seriesName),
      })),
  );
  const groupedByPeriod = groupBy(({ period }) => period, flatData);
  const combinedIntoRecords = mapValues(
    (period, itemsInPeriod) =>
      itemsInPeriod.reduce(
        (acc, item) => ({
          ...acc,
          [item.label]: item.value,
        }),
        { id: format(new Date(period), 'yyyy-MM-dd') },
      ),
    groupedByPeriod,
  );

  return Object.values(combinedIntoRecords);
}
