import { atomWithStorage } from 'jotai/utils';

enum HINT_STORAGE_KEYS {
  DOUBLE_CLICK_LEGEND = '@calliper/doubleClickLegendHint',
}

export const doubleClickHintAtom = atomWithStorage<boolean>(
  HINT_STORAGE_KEYS.DOUBLE_CLICK_LEGEND,
  false,
);
