import React from 'react';

import {
  fixedWidthStyle,
  stickyColumnStyle,
} from 'components/uikit/grids/gridCommons';

import { Box, Flex } from '../reexport';

export function WideLeftColGrid({
  children: [col1, col2],
  stickyCols: [stickyCol1, stickyCol2] = [false, false],
}: {
  children: [React.ReactNode, React.ReactNode];
  stickyCols?: [boolean, boolean];
}) {
  const leftColSize = 984;
  const rightColSize = 306;
  const gap = 32;
  const gridSize = gap + leftColSize + rightColSize;

  return (
    <Flex
      dir='row'
      width='100%'
      // This one is important for the scroll bar to appear on the page
      // instead of on this container
      height='auto'
      gap={`${gap}px`}
      justifyContent='center'
      //
      // the following is necessary for the user-friendly
      // horizontal scroll when the screen is too small
      {...fixedWidthStyle(gridSize)}
      margin='auto'
      //
    >
      <Box {...fixedWidthStyle(leftColSize)} {...stickyColumnStyle(stickyCol1)}>
        {col1}
      </Box>
      <Box
        {...fixedWidthStyle(rightColSize)}
        {...stickyColumnStyle(stickyCol2)}
      >
        {col2}
      </Box>
    </Flex>
  );
}
