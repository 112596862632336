import { z } from 'zod';

import { Department } from 'data/blocks/models/Block';
import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByAllCustomersRow = z.object({
  customerId: z.string(),
  customerIdDepartment: Department,
  customerName: z.string().nullable(),
  accountType: z.string().nullable(),
  activeUsers: z.coerce.number(),
  mrr: z.coerce.number(),
  renewalDate: z.coerce.date(),
  largestSubscription: z.string().nullable(),
  subscriptionStatus: z.string().nullable(),
  joinDate: z.coerce.date(),
  joinMonth: z.string(),
  daysSinceJoin: z.coerce.number(),
  lastActive: z.coerce.date(),
  lastEngaged: z.coerce.date(),
  totalEventsl30: z.coerce.number(),
  engagedEventsl30: z.coerce.number(),
  activeSessionsL30: z.coerce.number(),
  activeSessionsDelta: z.coerce.number(),
  engagedSessionsL30: z.coerce.number(),
  engagedRateL30: z.coerce.number(),
});

export type FullBreakdownByAllCustomersRow = z.infer<
  typeof FullBreakdownByAllCustomersRow
>;

export const ActiveUserColumn = z.object({ activeUsers: z.coerce.number() });

export type ActiveUserColumn = z.infer<typeof ActiveUserColumn>;

export const breakdownByAllCustomersAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByAllCustomersRow,
  [{ column: 'mrr', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
);

export const breakdownByTopNewTrialsAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByAllCustomersRow,
  [{ column: 'activeSessionsL30', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  [{ field: 'subscriptionStatus', operator: '=', value: 'Trial' }],
);

export const breakdownByUpsellOpportunitiesAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByAllCustomersRow,
    [{ column: 'mrr', direction: 'ASC' }],
    fixedEndDateFormattedDateRangeAtom,
    [{ field: 'activeSessionsL30', operator: '>', value: 15 }],
  );

export const breakdownByChurnRisksAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByAllCustomersRow,
  [{ column: 'mrr', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  [
    { field: 'mrr', operator: '>', value: 0 },
    { field: 'daysSinceJoin', operator: '>', value: 30 },
    { field: 'activeSessionsL30', operator: '<', value: 7 },
  ],
);
