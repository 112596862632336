import { Key } from 'ts-key-enum';

type ActionMapping = { onMac: string; default: string } | string;

function makeDefaultMapping<T>(
  mapping: Readonly<Record<keyof T, ActionMapping>>,
): Readonly<Record<keyof T, ActionMapping>> {
  return mapping;
}

export const DEFAULT_MAPPINGS = makeDefaultMapping({
  SELECT_1: '1',
  SELECT_2: '2',
  SELECT_3: '3',
  SELECT_4: '4',
  SELECT_5: '5',
  SELECT_6: '6',
  SELECT_7: '7',
  SELECT_8: '8',
  SELECT_9: '9',
  SELECT_0: '0',
  CLOSE: Key.Escape,
  LEFT: Key.ArrowLeft,
  RIGHT: Key.ArrowRight,
  UP: Key.ArrowUp,
  DOWN: Key.ArrowDown,
  CONFIRM: Key.Enter,
  TOGGLE: ' ',
  BACK: Key.Backspace,
  CLEAR: 'C',
  DATE_PICKER: {
    onMac: 'Meta+D',
    default: 'Ctrl+D',
  },
  COMMAND_PANEL: {
    onMac: 'Meta+K',
    default: 'Ctrl+K',
  },
  COPY_SHARE_LINK: {
    onMac: 'Meta+L',
    default: 'Ctrl+L',
  },
} as const);
