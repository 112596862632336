import { StarIcon } from '@chakra-ui/icons';

import { Box, HStack } from 'components/uikit';

export function HorizontalQuartileChart({
  value,
  star,
}: {
  value: number;
  star: boolean;
}) {
  return (
    <HStack spacing={'4px'}>
      <Box
        width={'52px'}
        height={'16px'}
        backgroundColor={value >= 1 ? 'dataViz.11' : 'grey.background'}
        borderRadius={'2px'}
      />
      <Box
        width={'52px'}
        height={'16px'}
        backgroundColor={value >= 2 ? 'dataViz.12' : 'grey.background'}
        borderRadius={'2px'}
      />
      <Box
        width={'52px'}
        height={'16px'}
        backgroundColor={value >= 3 ? 'dataViz.13' : 'grey.background'}
        borderRadius={'2px'}
      />
      <Box
        width={'52px'}
        height={'16px'}
        backgroundColor={value >= 4 ? 'dataViz.14' : 'grey.background'}
        borderRadius={'2px'}
      />
      <StarIcon
        boxSize={'24px'}
        p={'2px'}
        color={star ? 'dataViz.5' : 'grey.background'}
      />
    </HStack>
  );
}
