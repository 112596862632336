import { ReactElement, ReactNode } from 'react';

import { NextPage } from 'next';
import { AppProps } from 'next/app';

export enum EntryPoint {
  PUBLIC = 'PUBLIC',
  PROTECTED = 'PROTECTED',
  AUTH = 'AUTH',
}

export type NextPageWithEntryType = {
  entryPointType: EntryPoint;
};

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
} & NextPageWithEntryType;

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
