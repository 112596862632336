import { useEffect, useRef, useState } from 'react';

import { Textarea } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { CompanyNoteCard } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/NotesTabPanel/CompanyNoteCard';
import {
  Box,
  Button,
  HStack,
  Spinner,
  TabPanel,
  VStack,
  WriteIcon,
} from 'components/uikit';
import {
  useCustomerUser,
  useCustomerUserNote,
  useCustomerUserNoteCreatingAtom,
  useCustomerUserNotesFetchingAtom,
} from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { autoresizeTextarea } from 'styles/autoresizeTextarea';
import { hideScrollbar } from 'styles/hideScrollbar';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';
import { isLoading } from 'utils/types';

export function NotesTabPanel({ userId }: { userId: string }) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.notes_tab',
  });
  const { user } = useCustomerUser(userId);
  const createNoteForCompany = useCustomerUserNoteCreatingAtom(userId);
  const { notes, fetchStatus: notesFetchStatus } = useCustomerUserNote(userId);
  const fetchNotes = useSetAtom(useCustomerUserNotesFetchingAtom(userId));
  const [newNote, setNewNote] = useState('');
  const textBoxRef = useRef<HTMLDivElement>(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    fetchNotes(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setButtonsDisabled(newNote === '');
  }, [newNote, setNewNote]);

  if (!user) {
    return <></>;
  }

  const onCreateNote = async () => {
    setButtonsDisabled(true);
    await createNoteForCompany({
      userId,
      // Replace new lines with two spaces and a new line to maintain line breaks in Markdown
      content: newNote.replaceAll('\n', '  \n'),
    });
    trackEvent(CustomerDrawerAnalyticsEvents.NOTES_ADDED, {
      customerType: 'user',
    });
    await fetchNotes(userId);
    setNewNote('');
    setButtonsDisabled(false);
  };

  const noteCards = notes
    .sort(
      (noteA, noteB) => noteB.updatedAt.valueOf() - noteA.updatedAt.valueOf(),
    )
    .map((note, index) => (
      <CompanyNoteCard
        companyId={userId}
        key={`${userId}-${index}`}
        note={note}
      />
    ));

  const minTextBoxSize = 72;
  const textBoxSize = textBoxRef.current?.clientHeight
    ? textBoxRef.current.clientHeight
    : minTextBoxSize;

  return (
    <TabPanel height={'100%'}>
      <VStack height={'100%'} spacing={0}>
        <VStack
          background={'grey.offWhite'}
          height={`calc(100% - ${textBoxSize}px)`}
          overflowY={'hidden'}
          width={'100%'}
        >
          <VStack
            p='16px'
            overflowY={'scroll'}
            width={'100%'}
            css={hideScrollbar}
          >
            {isLoading(notesFetchStatus) && (
              <VStack p='16px'>
                <Spinner />
              </VStack>
            )}
            {!isLoading(notesFetchStatus) && notes.length === 0 ? (
              <VStack p='16px'>
                <Box>{t('no_notes')}</Box>
              </VStack>
            ) : (
              noteCards
            )}
          </VStack>
        </VStack>

        <HStack
          background={'grey.white'}
          minHeight={`${minTextBoxSize}px`}
          width={'100%'}
          ref={textBoxRef}
          justifyContent={'center'}
          p={'16px 24px'}
          shadow={'0px -16px 16px rgba(0, 0, 0, 0.05)'}
        >
          <WriteIcon boxSize='16px' stroke='grey.secondaryText' />
          <Textarea
            rows={1}
            border={'0px'}
            placeholder={t('add_note_input_placeholder')}
            maxHeight={'200px'}
            minHeight={'32px'}
            p={'4px'}
            resize={'none'}
            value={newNote}
            onChange={(event) => {
              setNewNote(event.currentTarget.value);
              autoresizeTextarea(event.currentTarget);
            }}
          />
          <Button
            variant={'secondary'}
            onClick={onCreateNote}
            disabled={buttonsDisabled}
          >
            {t('add_note')}
          </Button>
        </HStack>
      </VStack>
    </TabPanel>
  );
}
