import { useTranslation } from 'react-i18next';

import { Box, Heading, HStack, Text, VStack } from 'components/uikit';
import { TagIcon } from 'components/uikit/icons/Tag';

export function UserTagsModalHeader() {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.user_tags_modal.header',
  });

  return (
    <HStack p={'16px'} spacing={'16px'} background={'grey.offWhite'}>
      <Box p={'8px'} border={'1px solid'} borderColor={'grey.border'}>
        <TagIcon boxSize='24px' stroke={'grey.secondaryText'} />
      </Box>
      <VStack alignItems='left' spacing={0}>
        <Heading as={'h5'} noOfLines={1} fontSize={'16px'} lineHeight={1.5}>
          {t('heading')}
        </Heading>
        <Text
          noOfLines={1}
          fontSize={'14px'}
          lineHeight={1.5}
          color={'grey.tertiaryText'}
        >
          {t('subheading')}
        </Text>
      </VStack>
    </HStack>
  );
}
