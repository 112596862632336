import { useTranslation } from 'react-i18next';

import { Button, Flex, Link, VerticalBarChartIcon } from 'components/uikit';
import { TrophyIcon } from 'components/uikit/icons/TrophyIcon';
import { AiChartDataConfig } from 'data/ai/models';

interface AiAnswerFooterProps {
  config: AiChartDataConfig;
}

interface ChartLink {
  link: string;
  title: string;
}

const financeNameMapping: Record<string, ChartLink> = {
  mrr: {
    link: `/mission-control/finance?panel=value&subpanel=mrr`,
    title: 'mrr',
  },
  mrrMovements: {
    link: `/mission-control/finance?panel=value&subpanel=net_mrr_change`,
    title: 'net_mrr_change',
  },
  churnChart: {
    link: `/mission-control/finance?panel=value&subpanel=churn`,
    title: 'lost_mrr',
  },
  expansionChart: {
    link: `/mission-control/finance?panel=value&subpanel=expansion`,
    title: 'customer_expansion_tooltip_title',
  },
  newCustomersMrrChart: {
    link: `/mission-control/finance?panel=value&subpanel=new_customers_mrr`,
    title: 'new_customers',
  },
  newTrialsChart: {
    link: `/mission-control/finance?panel=customers&subpanel=new_trials`,
    title: 'new_trials',
  },
  newCustomersChart: {
    link: `/mission-control/finance?panel=customers&subpanel=new_customers`,
    title: 'new_customers',
  },
  expansionCustomersChart: {
    link: `/mission-control/finance?panel=customers&subpanel=expansion_customers`,
    title: 'expansion_customers',
  },
  churnCustomersChart: {
    link: `/mission-control/finance?panel=customers&subpanel=churn_customers`,
    title: 'churn_customers',
  },
  recurringCustomersChart: {
    link: `/mission-control/finance?panel=customers&subpanel=recurring_customers`,
    title: 'total_customers',
  },
};

const salesNameMapping: Record<string, ChartLink> = {
  monthlySales: {
    link: `/mission-control/sales?panel=value&subpanel=sales`,
    title: 'closed_deals',
  },
  pipelineOverview: {
    link: `/mission-control/sales?panel=value&subpanel=pipeline_value`,
    title: 'pipeline_value',
  },
  avgDealValueChart: {
    link: `/mission-control/sales?panel=value&subpanel=avg_deal_value`,
    title: 'avg_deal_value',
  },
  winRateChart: {
    link: `/mission-control/sales?panel=value&subpanel=win_rate`,
    title: 'win_rate',
  },
  avgSalesCycleRate: {
    link: `/mission-control/sales?panel=value&subpanel=avg_sales_cycle`,
    title: 'avg_sales_cycle',
  },
  wonDealsChart: {
    link: `/mission-control/sales?panel=value&subpanel=won_deals`,
    title: 'won_deals',
  },
};

const productNameMapping: Record<string, ChartLink> = {
  monthlySales: {
    link: `/mission-control/product?panel=users&subpanel=new_sign_ups`,
    title: 'new_sign_ups',
  },
  activeUsers: {
    link: `/mission-control/product?panel=users&subpanel=active_users`,
    title: 'active_users',
  },
  engagedUsers: {
    link: `/mission-control/product?panel=users&subpanel=engaged_users`,
    title: 'active_users',
  },
  powerUsers: {
    link: `/mission-control/product?panel=users&subpanel=power_users`,
    title: 'product_power_users',
  },
  weeklyToMonthlyActiveUsersChart: {
    link: `/mission-control/product?panel=users&subpanel=wau_mau`,
    title: 'product_retention',
  },
  signalOneCountChart: {
    link: `/mission-control/product?panel=engagement&subpanel=signal_one`,
    title: 'Signal 1',
  },
  signalTwoCountChart: {
    link: `/mission-control/product?panel=engagement&subpanel=signal_two`,
    title: 'Signal 2',
  },
  signalThreeCountChart: {
    link: `/mission-control/product?panel=engagement&subpanel=signal_three`,
    title: 'Signal 3',
  },
  signalFourCountChart: {
    link: `/mission-control/product?panel=engagement&subpanel=signal_four`,
    title: 'Signal 4',
  },
  signalFiveCountChart: {
    link: `/mission-control/product?panel=engagement&subpanel=signal_five`,
    title: 'Signal 5',
  },
  companiesSignUpsChart: {
    link: `/mission-control/product?panel=companies&subpanel=companies_new_sign_ups`,
    title: 'recently_signed_up_companies',
  },
  activeCompaniesChart: {
    link: `/mission-control/product?panel=companies&subpanel=active_companies`,
    title: 'active_companies',
  },
  companiesEngagedUsersChart: {
    link: `/mission-control/product?panel=companies&subpanel=engaged_companies`,
    title: 'product_engaged_companies',
  },
  companiesPowerUsersChart: {
    link: `/mission-control/product?panel=companies&subpanel=power_companies`,
    title: 'product_power_companies',
  },
  companiesRetentionCohorts: {
    link: `/mission-control/product?panel=companies&subpanel=wac_mac`,
    title: 'product_wac_mac',
  },
};

const growthNameMapping: Record<string, ChartLink> = {
  visitors: {
    link: `/mission-control/marketing?panel=events&subpanel=new_visitors`,
    title: 'new_visitors',
  },
  sessions: {
    link: `/mission-control/marketing?panel=events&subpanel=sessions`,
    title: 'sessions',
  },
  engagement: {
    link: `/mission-control/marketing?panel=events&subpanel=engagement_rate`,
    title: 'engagement_rate',
  },
  avgTimeOnSite: {
    link: `/mission-control/marketing?panel=events&subpanel=time_on_site`,
    title: 'avg_time_on_site',
  },
  leads: {
    link: `/mission-control/marketing?panel=events&subpanel=leads`,
    title: 'leads',
  },
};

const chartNameMapping: Record<string, ChartLink> = {
  ...financeNameMapping,
  ...salesNameMapping,
  ...productNameMapping,
  ...growthNameMapping,
};

export const financeLeaderboardNameMapping: Record<string, ChartLink> = {
  mrrByClient: {
    link: '/mission-control/finance?panel=value&subpanel=mrr',
    title: 'largest_accounts',
  },
  mrrByProduct: {
    link: '/mission-control/finance?panel=value&subpanel=mrr',
    title: 'revenue_by_product',
  },
  latestTransactions: {
    link: '/mission-control/finance?panel=value&subpanel=net_mrr_change',
    title: 'latest_transactions',
  },
  movementsBreakdown: {
    link: '/mission-control/finance?panel=value&subpanel=net_mrr_change',
    title: 'mrr_movements_breakdown',
  },
  newestCustomers: {
    link: '/mission-control/finance?panel=value&subpanel=new_customers_mrr',
    title: 'newest_customers',
  },
  customersByProduct: {
    link: '/mission-control/finance?panel=value&subpanel=new_customers_mrr',
    title: 'customers_by_product',
  },
  latestUpgrades: {
    link: '/mission-control/finance?panel=value&subpanel=expansion',
    title: 'latest_upgrades',
  },
  longestCustomers: {
    link: '/mission-control/finance?panel=value&subpanel=expansion',
    title: 'largest_lifetime',
  },
  latestChurns: {
    link: '/mission-control/finance?panel=value&subpanel=churn',
    title: 'latest_churned_customers',
  },
  largestChurns: {
    link: '/mission-control/finance?panel=value&subpanel=churn',
    title: 'largest_mrr_lost',
  },
  endingSoonTrials: {
    link: '/mission-control/finance?panel=customers&subpanel=new_trials',
    title: 'ending_soon_trials',
  },
  latestTrials: {
    link: '/mission-control/finance?panel=customers&subpanel=new_trials',
    title: 'latest_trials',
  },
  fullBreakdownByProductsByCustomersCount: {
    link: '/breakdowns/finance?breakdown=customers',
    title: 'products_by_customers',
  },
  fullBreakdownByProductsByMRR: {
    link: '/breakdowns/finance?breakdown=revenue',
    title: 'products_by_revenue',
  },
  fullBreakdownByCustomersByHighestLTV: {
    link: '/breakdowns/finance?breakdown=highestLtv',
    title: 'customers_by_highest_ltv',
  },
  fullBreakdownByCustomersByLargestMRR: {
    link: '/breakdowns/finance?breakdown=largestCustomers',
    title: 'customers_by_largest_mrr',
  },
  fullBreakdownByCustomersByJoinDate: {
    link: '/breakdowns/finance?breakdown=newCustomers',
    title: 'new_customers',
  },
  fullBreakdownLatestTransactions: {
    link: '/breakdowns/finance?breakdown=latestTransactions',
    title: 'latest_transactions',
  },
  fullBreakdownChurnTransactionsByDate: {
    link: '/breakdowns/finance?breakdown=latestChurnTransactions',
    title: 'latest_churn_transactions',
  },
  fullBreakdownChurnTransactionsByMRR: {
    link: '/breakdowns/finance?breakdown=largestChurnTransactions',
    title: 'largest_churn_transactions',
  },
  fullBreakdownExpansionTransactionsByMRR: {
    link: '/breakdowns/finance?breakdown=expansionTransactions',
    title: 'expansion_transactions',
  },
};

const salesLeaderboardNameMapping: Record<string, ChartLink> = {
  recentlyClosed: {
    link: '/mission-control/sales?panel=value&subpanel=sales',
    title: 'recently_closed_sales',
  },
  largestActive: {
    link: '/mission-control/sales?panel=value&subpanel=sales',
    title: 'largest_closed_deals',
  },
  closingSoon: {
    link: '/mission-control/sales?panel=value&subpanel=avg_deal_value',
    title: 'deals_closing_soon_breakdown_title',
  },
  stalled: {
    link: '/mission-control/sales?panel=value&subpanel=avg_deal_value',
    title: 'stalled_deals_breakdown_title',
  },
  pipelineOverview: {
    link: '/mission-control/sales?panel=value&subpanel=pipeline_value',
    title: 'pipeline_overview',
  },
  pipelineDealCountOverview: {
    link: '/mission-control/sales?panel=companies&subpanel=pipeline_deals_count',
    title: 'pipeline_overview',
  },
  latestAdded: {
    link: '/mission-control/sales?panel=value&subpanel=pipeline_value',
    title: 'recently_added_deals',
  },
  pipelineSummary: {
    link: '/mission-control/sales?panel=value&subpanel=pipeline_value',
    title: 'largest_deals_in_pipeline',
  },
  winRateByDealSize: {
    link: '/mission-control/sales?panel=value&subpanel=win_rate',
    title: 'win_rate_by_owner',
  },
  recentlyLost: {
    link: '/mission-control/sales?panel=value&subpanel=win_rate',
    title: 'recently_lost_deals',
  },
  fastestMoving: {
    link: '/mission-control/sales?panel=value&subpanel=avg_sales_cycle',
    title: 'stage_speed',
  },
  cycleTimeByOwner: {
    link: '/mission-control/sales?panel=value&subpanel=avg_sales_cycle',
    title: 'sales_cycle_time_by_owner',
  },
  fullBreakdownBySalesByLargestValue: {
    link: '/breakdowns/sales?breakdown=byLargestSales',
    title: 'sales_breakdown_by_largest_sales',
  },
  fullBreakdownBySalesByMostRecent: {
    link: '/breakdowns/sales?breakdown=byMostRecentSales',
    title: 'sales_breakdown_by_most_recent_sales',
  },
  fullBreakdownBySalesByTimeToClose: {
    link: '/breakdowns/sales?breakdown=byFastestDealsToClose',
    title: 'sales_breakdown_by_fastest_deals_to_close',
  },
  fullBreakdownByLostDealsByCloseDate: {
    link: '/breakdowns/sales?breakdown=byRecentLostDeals',
    title: 'sales_breakdown_by_recent_lost_deals',
  },
  fullBreakdownByLostDealsByLargestValue: {
    link: '/breakdowns/sales?breakdown=byLargestLostDeals',
    title: 'sales_breakdown_by_largest_lost_deals',
  },
  fullBreakdownByPipelineDealsByLargestDeals: {
    link: '/breakdowns/sales?breakdown=byLargestDealsInPipeline',
    title: 'sales_breakdown_by_largest_deals_in_pipeline',
  },
  fullBreakdownByPipelineDealsByRecentDeals: {
    link: '/breakdowns/sales?breakdown=byRecentlyAddedPipeline',
    title: 'sales_breakdown_by_recently_added_pipeline',
  },
  fullBreakdownByPipelineDealsByStalledDeals: {
    link: '/breakdowns/sales?breakdown=byStalledDeals',
    title: 'sales_breakdown_by_stalled_deals',
  },
  fullBreakdownByPipelineDealsByClosingSoon: {
    link: '/breakdowns/sales?breakdown=byClosingSoon',
    title: 'sales_breakdown_by_closing_soon',
  },
  fullBreakdownBySalesOwnersByValue: {
    link: '/breakdowns/sales?breakdown=byOwnerSales',
    title: 'sales_breakdown_by_owner_sales',
  },
  fullBreakdownBySalesOwnersByWinRate: {
    link: '/breakdowns/sales?breakdown=byWinRate',
    title: 'sales_breakdown_by_win_rate',
  },
  fullBreakdownBySalesOwnersByAvgSalesCycle: {
    link: '/breakdowns/sales?breakdown=byTimeToClose',
    title: 'sales_breakdown_by_time_to_close',
  },
};

const growthLeaderboardNameMapping: Record<string, ChartLink> = {
  visitorsByMedium: {
    link: '/mission-control/marketing?panel=events&subpanel=new_visitors',
    title: 'new_visitors_by_source_medium',
  },
  visitorsByCountry: {
    link: '/mission-control/marketing?panel=events&subpanel=new_visitors',
    title: 'visitors_by_country',
  },
  sessionsByOS: {
    link: '/mission-control/marketing?panel=events&subpanel=sessions',
    title: 'sessions_by_operating_system',
  },
  sessionsByLanding: {
    link: '/mission-control/marketing?panel=events&subpanel=sessions',
    title: 'sessions_by_landing_page',
  },
  engagementByMedium: {
    link: '/mission-control/marketing?panel=events&subpanel=engagement_rate',
    title: 'engagement_by_source_medium',
  },
  engagementByCampaign: {
    link: '/mission-control/marketing?panel=events&subpanel=engagement_rate',
    title: 'engagement_by_campaign',
  },
  timeByPage: {
    link: '/mission-control/marketing?panel=events&subpanel=time_on_site',
    title: 'time_by_page',
  },
  visitorsByPage: {
    link: '/mission-control/marketing?panel=events&subpanel=time_on_site',
    title: 'visitors_by_page',
  },
  leadsByMedium: {
    link: '/mission-control/marketing?panel=events&subpanel=leads',
    title: 'leads_by_source_medium',
  },
  leadsByCampaign: {
    link: '/mission-control/marketing?panel=events&subpanel=leads',
    title: 'leads_by_campaign',
  },
  fullBreakdownByCampaignByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=campaignByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_campaign',
  },
  fullBreakdownByCampaignBySessions: {
    link: '/breakdowns/marketing?breakdown=campaignBySessions&split=sessions',
    title: 'growth_breakdown_by_campaign',
  },
  fullBreakdownByCampaignByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=campaignByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_campaign',
  },
  fullBreakdownByCampaignByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=campaignByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_campaign',
  },
  fullBreakdownByChannelByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=channelByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_channel',
  },
  fullBreakdownByChannelBySessions: {
    link: '/breakdowns/marketing?breakdown=channelBySessions&split=sessions',
    title: 'growth_breakdown_by_channel',
  },
  fullBreakdownByChannelByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=channelByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_channel',
  },
  fullBreakdownByChannelByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=channelByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_channel',
  },
  fullBreakdownByCountryByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=countryByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_country',
  },
  fullBreakdownByCountryBySessions: {
    link: '/breakdowns/marketing?breakdown=countryBySessions&split=sessions',
    title: 'growth_breakdown_by_country',
  },
  fullBreakdownByCountryByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=countryByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_country',
  },
  fullBreakdownByCountryByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=countryByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_country',
  },
  fullBreakdownByDeviceByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=deviceByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_device',
  },
  fullBreakdownByDeviceBySessions: {
    link: '/breakdowns/marketing?breakdown=deviceBySessions&split=sessions',
    title: 'growth_breakdown_by_device',
  },
  fullBreakdownByDeviceByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=deviceByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_device',
  },
  fullBreakdownByDeviceByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=deviceByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_device',
  },
  fullBreakdownByOperatingSystemByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=osByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_os',
  },
  fullBreakdownByOperatingSystemBySessions: {
    link: '/breakdowns/marketing?breakdown=osBySessions&split=sessions',
    title: 'growth_breakdown_by_os',
  },
  fullBreakdownByOperatingSystemByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=osByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_os',
  },
  fullBreakdownByOperatingSystemByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=osByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_os',
  },
  fullBreakdownByPageByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=pageByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_page',
  },
  fullBreakdownByPageBySessions: {
    link: '/breakdowns/marketing?breakdown=pageBySessions&split=sessions',
    title: 'growth_breakdown_by_page',
  },
  fullBreakdownByPageByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=pageByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_page',
  },
  fullBreakdownByPageByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=pageByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_page',
  },
  fullBreakdownBySourceByNewVisitors: {
    link: '/breakdowns/marketing?breakdown=sourceByNewVisitors&split=newVisitors',
    title: 'growth_breakdown_by_origin',
  },
  fullBreakdownBySourceBySessions: {
    link: '/breakdowns/marketing?breakdown=sourceBySessions&split=sessions',
    title: 'growth_breakdown_by_origin',
  },
  fullBreakdownBySourceByEngagementRate: {
    link: '/breakdowns/marketing?breakdown=sourceByEngagementRate&split=engagementRate',
    title: 'growth_breakdown_by_origin',
  },
  fullBreakdownBySourceByAvgTimePerSession: {
    link: '/breakdowns/marketing?breakdown=sourceByAvgTimePerSession&split=avgTimePerSession',
    title: 'growth_breakdown_by_origin',
  },
};

const productLeaderboardNameMapping: Record<string, ChartLink> = {
  recentlySignedUp: {
    link: '/mission-control/product?panel=users&subpanel=new_sign_ups',
    title: 'recently_signed_up_users',
  },
  bestRetentionCohorts: {
    link: '/mission-control/product?panel=users&subpanel=new_sign_ups',
    title: 'best_1_month_retention_cohorts',
  },
  mostActive: {
    link: '/mission-control/product?panel=users&subpanel=active_users',
    title: 'most_active',
  },
  usersWithLargestIncreaseInActivity: {
    link: '/mission-control/product?panel=users&subpanel=active_users',
    title: 'users_with_largest_increase_in_activity',
  },
  mostEngagedUsers: {
    link: '/mission-control/product?panel=users&subpanel=engaged_users',
    title: 'most_engaged_users',
  },
  recentlyEngagedUsers: {
    link: '/mission-control/product?panel=users&subpanel=engaged_users',
    title: 'recently_engaged_users',
  },
  topPowerUsers: {
    link: '/mission-control/product?panel=users&subpanel=power_users',
    title: 'top_power_users',
  },
  newPowerUsers: {
    link: '/mission-control/product?panel=users&subpanel=power_users',
    title: 'new_power_users',
  },
  usersWithDecreasedUsage: {
    link: '/mission-control/product?panel=users&subpanel=wau_mau',
    title: 'users_with_decreased_usage',
  },
  recentlySignedUpCompanies: {
    link: '/mission-control/product?panel=companies&subpanel=companies_new_sign_ups',
    title: 'recently_signed_up_companies',
  },
  companiesBestRetentionCohorts: {
    link: '/mission-control/product?panel=companies&subpanel=companies_new_sign_ups',
    title: 'best_1_month_retention_cohorts',
  },
  mostActiveCompanies: {
    link: '/mission-control/product?panel=companies&subpanel=active_companies',
    title: 'most_active_companies',
  },
  companiesWithLargestIncreaseInActivity: {
    link: '/mission-control/product?panel=companies&subpanel=active_companies',
    title: 'companies_with_largest_increase_in_activity',
  },
  mostEngagedCompanies: {
    link: '/mission-control/product?panel=companies&subpanel=engaged_companies',
    title: 'most_engaged_companies',
  },
  recentlyEngagedCompanies: {
    link: '/mission-control/product?panel=companies&subpanel=engaged_companies',
    title: 'recently_engaged_companies',
  },
  topCompaniesPowerUsers: {
    link: '/mission-control/product?panel=companies&subpanel=power_companies',
    title: 'top_power_companies',
  },
  newCompaniesPowerUsers: {
    link: '/mission-control/product?panel=companies&subpanel=power_companies',
    title: 'new_power_companies',
  },
  companiesWithDecreasedUsage: {
    link: '/mission-control/product?panel=companies&subpanel=wac_mac',
    title: 'companies_with_decreased_usage',
  },
  fullBreakdownByUsersBySignUpDate: {
    link: '/breakdowns/product?breakdown=byNewUsers',
    title: 'product_breakdown_by_new_users',
  },
  fullBreakdownByUsersByActiveDays: {
    link: '/breakdowns/product?breakdown=byMostActive',
    title: 'product_breakdown_by_users_most_active',
  },
  fullBreakdownByUsersByIncreaseInActivity: {
    link: '/breakdowns/product?breakdown=byLargestIncreaseInActivity',
    title: 'product_breakdown_by_largest_increase_in_activity',
  },
  fullBreakdownByUsersByDecreaseInActivity: {
    link: '/breakdowns/product?breakdown=byLargestDecreaseInActivity',
    title: 'product_breakdown_by_largest_decrease_in_activity',
  },
  fullBreakdownByUsersByEngagedDays: {
    link: '/breakdowns/product?breakdown=byMostEngaged',
    title: 'product_breakdown_by_users_most_engaged',
  },
  fullBreakdownByPowerUsersByJoinDate: {
    link: '/breakdowns/product?breakdown=byNewestPowerUsers',
    title: 'product_breakdown_by_power_users_newest',
  },
  fullBreakdownByPowerUsersByPowerUserEventCount: {
    link: '/breakdowns/product?breakdown=byTopPowerUsers',
    title: 'product_breakdown_by_power_users_top',
  },
  fullBreakdownByUsersEngagement: {
    link: '/breakdowns/product?breakdown=byUserEngagement',
    title: 'product_breakdown_by_user_engagement',
  },
  fullBreakdownByCompaniesByJoinDate: {
    link: '/breakdowns/product?breakdown=byNewCompanies',
    title: 'product_breakdown_by_new_companies',
  },
  fullBreakdownByCompaniesByActiveDays: {
    link: '/breakdowns/product?breakdown=byMostActiveCompanies',
    title: 'product_breakdown_by_most_active_companies',
  },
  fullBreakdownByCompaniesByIncreaseInActivity: {
    link: '/breakdowns/product?breakdown=byCompaniesLargestIncreaseInActivity',
    title: 'product_breakdown_by_largest_increase_in_activity',
  },
  fullBreakdownByCompaniesByDecreaseInActivity: {
    link: '/breakdowns/product?breakdown=byCompaniesLargestDecreaseInActivity',
    title: 'product_breakdown_by_largest_decrease_in_activity',
  },
  fullBreakdownByCompaniesByEngagedDays: {
    link: '/breakdowns/product?breakdown=byMostEngagedCompanies',
    title: 'product_breakdown_by_most_engaged_companies',
  },
};

const leaderboardNameMapping: Record<string, ChartLink> = {
  ...financeLeaderboardNameMapping,
  ...salesLeaderboardNameMapping,
  ...growthLeaderboardNameMapping,
  ...productLeaderboardNameMapping,
};

function getRelatedChartLink(chartName: string): ChartLink | null {
  return chartNameMapping[chartName] ?? null;
}

function getRelatedBreakdownLink(chartName: string): ChartLink | null {
  return leaderboardNameMapping[chartName] ?? null;
}

export function AiAnswerFooter({
  config: {
    chartName,
    timeRange: { granularity, end },
  },
}: AiAnswerFooterProps) {
  const { t } = useTranslation(['mission-control', 'breakdowns']);
  const chartLink = getRelatedChartLink(chartName);
  const breakdownLink = getRelatedBreakdownLink(chartName);

  if (!chartLink && !breakdownLink) return null;

  const chartParams = `&date=${encodeURIComponent(
    end.toISOString(),
  )}&granularity=${granularity}`;

  return (
    <Flex justify='stretch' borderTop='1px solid' borderColor='grey.border'>
      {chartLink ? (
        <Link href={chartLink.link + chartParams} flex={1}>
          <Button
            variant='ghost'
            w='100%'
            color='secondary.text'
            leftIcon={<VerticalBarChartIcon />}
          >
            {t(chartLink.title)}
          </Button>
        </Link>
      ) : null}
      {breakdownLink ? (
        <Link href={breakdownLink.link} flex={1}>
          <Button
            variant='ghost'
            w='100%'
            color='secondary.text'
            leftIcon={<TrophyIcon />}
          >
            {t(breakdownLink.title)}
          </Button>
        </Link>
      ) : null}
    </Flex>
  );
}
