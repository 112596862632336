import { useCallback } from 'react';

import { CommandPanelSearchFooter } from 'components/CommandPanel/Modal/Search/CommandPanelSearchFooter';
import { Modal } from 'components/uikit';
import { useOpenAiDrawer } from 'data/ai/hooks';

import { CommandPanelSearchBody } from './Search/CommandPanelSearchBody';

export interface CommandPanelModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CommandPanelModal({ isOpen, onClose }: CommandPanelModalProps) {
  const openAiDrawer = useOpenAiDrawer();

  const onAiOpen = useCallback(() => {
    onClose();
    openAiDrawer();
  }, [onClose, openAiDrawer]);

  return (
    <Modal
      isCentered
      body={
        <CommandPanelSearchBody onClose={onClose} onSelectAiMode={onAiOpen} />
      }
      footer={<CommandPanelSearchFooter />}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth='3xl'
      footerStyle={{
        justifyContent: 'space-between',
        minH: '40px',
        p: 0,
      }}
    />
  );
}
