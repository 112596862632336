import { useAtomValue } from 'jotai';

import {
  DataSource,
  DataSourceStatus,
  GoogleProvider,
} from 'api/graphql/backendAPI/generatedTypes';

import { dataSourcesAtom } from '../atoms/dataSourcesAtom';

export function isDataSourceActive(status: DataSourceStatus | undefined) {
  return !['NOT_CREATED', 'INITIAL'].includes(status ?? '');
}

export function useActiveGAProvider(): GoogleProvider | null {
  const { dataSources } = useAtomValue(dataSourcesAtom);

  if (
    dataSources?.GOOGLE_ANALYTICS_4 &&
    isDataSourceActive(dataSources?.GOOGLE_ANALYTICS_4?.status)
  ) {
    return 'GOOGLE_ANALYTICS_4';
  }

  return null;
}

export function useActiveGADataSource(): DataSource | null {
  const { dataSources } = useAtomValue(dataSourcesAtom);
  const activeGAProvider = useActiveGAProvider() ?? 'GOOGLE_ANALYTICS_4';
  return dataSources?.[activeGAProvider] ?? null;
}
