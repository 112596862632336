import { ReactNode, useEffect, useRef } from 'react';

interface ScrollsIntoViewProps {
  children: ReactNode;
  selected: boolean;
}

export const ScrollsIntoView = ({
  children,
  selected,
}: ScrollsIntoViewProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (selected)
      ref.current?.scrollIntoView?.({
        block: 'nearest',
      });
  }, [selected]);
  return <span ref={ref}>{children}</span>;
};
