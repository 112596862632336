import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export type FetchCustomerCompanyByIdParams = {
  companyId: string;
};

export async function fetchCustomerCompanyById({
  companyId,
}: FetchCustomerCompanyByIdParams): Promise<CustomerCompanyResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/${companyId}`,
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer company: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompanyResponse, data);
}
