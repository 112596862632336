import { Box, Flex, Text } from 'components/uikit';

interface TooltipContentDataEntryProps {
  boxColor?: string | null;
  label: string | number | undefined;
  value: string | number;
  valueColor?: string;
  opacity?: number;
}

export function TooltipContentDataEntry({
  boxColor,
  label,
  value,
  opacity,
  valueColor,
}: TooltipContentDataEntryProps) {
  const labelFontWeight = boxColor ? 400 : 500;
  return (
    <Flex flexDirection='row' justify='space-between' gap='16px'>
      <Flex flexDirection='row' alignItems='center'>
        {boxColor && (
          <Box
            w='8px'
            h='8px'
            bg={boxColor}
            mr='4px'
            borderRadius='2px'
            opacity={opacity}
          />
        )}

        <Text
          textTransform='capitalize'
          fontSize='14px'
          lineHeight='18px'
          color={'grey.secondaryText'}
          fontWeight={labelFontWeight}
          maxW='210px'
          overflowX='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {label}
        </Text>
      </Flex>

      <Text
        color={valueColor}
        variant='numeric'
        fontSize='14px'
        fontWeight='medium'
      >
        {value}
      </Text>
    </Flex>
  );
}
