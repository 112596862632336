import { useMemo } from 'react';

import { nonNullable } from 'next/dist/lib/non-nullable';
import { useRouter } from 'next/router';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { Direction } from 'data/charts/models/ChartsApiRequest';
import { zipArrays } from 'utils/arrayUtils';
import { encodeUrlParam } from 'utils/url/urlQueryUtils';

type ParamValue = string | string[] | undefined | null;
type DestinationQuery = Record<string, string | string[] | undefined | null>;

class Destination {
  public readonly path: string[];
  public readonly query: DestinationQuery;

  public constructor(path: string | string[], query: DestinationQuery = {}) {
    this.path = Array.isArray(path) ? path : [path];
    this.query = query;
  }

  public withParam(name: string, value: ParamValue) {
    return new Destination(this.path, {
      ...this.query,
      [name]: value,
    });
  }

  public buildUrl(): string {
    const query = Object.entries(this.query)
      .map(([key, value]) =>
        value !== null && value !== undefined
          ? `${key}=${encodeURI(String(value))}`
          : null,
      )
      .filter(nonNullable)
      .join('&');

    const path = this.path.join('/');
    return `/${path}${query ? `?${query}` : ''}`;
  }
}

type DestinationBuilder<T extends unknown[]> = (
  currentPath: string,
  query: DestinationQuery,
) => (...params: T) => Destination;

type DestinationModifier<T extends unknown[]> = (
  _: Destination,
  ...params: T
) => Destination;

export function withOptionalNumberParam(name: string) {
  return (destination: Destination, value?: number) => {
    return destination.withParam(name, String(value));
  };
}

export function withStringParam(name: string) {
  return (destination: Destination, value: string) => {
    return destination.withParam(name, value);
  };
}

export function withParams<T>(param: string): DestinationModifier<[T]>;
export function withParams<T, U>(
  param1: string,
  param2: string,
): DestinationModifier<[T, U]>;
export function withParams<T, U, V>(
  param1: string,
  param2: string,
  param3: string,
): DestinationModifier<[T, U, V]>;
export function withParams<T extends ParamValue[]>(...names: string[]) {
  return (destination: Destination, ...values: T) => {
    return zipArrays(names, values).reduce(
      (modDestination, [name, value]) => modDestination.withParam(name, value),
      destination,
    );
  };
}

export function ofCurrentUrl(currentPath: string, query: DestinationQuery) {
  return () =>
    new Destination(
      currentPath.split('/').filter((s) => !!s),
      query,
    );
}

export function ofBreakdownChart() {
  return (params: {
    department: 'finance' | 'marketing' | 'product' | 'sales' | 'customers';
    breakdown: string;
    date: Date;
    granularity: AggregationPeriod;
    split?: string;
    col?: string;
    order?: Direction;
  }) => {
    const { department, ...remainingParams } = params;
    const partialDestination = new Destination(['breakdowns', department]);

    return Object.entries(remainingParams).reduce(
      (dest, [name, value]) => dest.withParam(name, encodeUrlParam(value)),
      partialDestination,
    );
  };
}

type ConnectedDestination<T extends unknown[]> = { go: (...args: T) => void };

export function useDestination<T extends unknown[]>(
  builder: DestinationBuilder<T>,
): ConnectedDestination<T>;
export function useDestination<T extends unknown[]>(
  builder: DestinationBuilder<[]>,
  m1: DestinationModifier<T>,
): ConnectedDestination<T>;
export function useDestination<T extends unknown[]>(
  builder: DestinationBuilder<[]>,
  m1?: DestinationModifier<T>,
): ConnectedDestination<T> {
  const { push, pathname, query } = useRouter();

  return useMemo(() => {
    const destination = m1
      ? (...args: T) => m1(builder(pathname, query)(), ...args)
      : builder(pathname, query);

    return {
      go: (...args: T) => {
        const nextUrl = destination(...args).buildUrl();
        push(nextUrl, undefined, { shallow: true });
      },
    };
  }, [push, pathname, query, m1, builder]);
}
