import { ReactNode } from 'react';

import { iconsByType } from 'components/CommandPanel/Modal/ListItems/UserMenuListItem';
import { Department, MetricBlockName } from 'data/blocks/models/Block';
import { BlockCommandPanelOption } from 'data/commandPanel/models';
import paths from 'utils/paths';

export const redirectMappings: Partial<
  Record<MetricBlockName, Record<string, string>>
> = {
  METRICS_FINANCE_MRR_CHART: { panel: 'value', subpanel: 'mrr' },
  METRICS_FINANCE_MRR_BREAKDOWN_BY_PRODUCT: { panel: 'value', subpanel: 'mrr' },
  METRICS_FINANCE_MRR_BREAKDOWN_BY_CLIENT: { panel: 'value', subpanel: 'mrr' },

  METRICS_FINANCE_MRR_MOVEMENTS: { panel: 'value', subpanel: 'net_mrr_change' },
  METRICS_FINANCE_MRR_CHANGE_BREAKDOWN_LATEST_TRANSACTIONS: {
    panel: 'value',
    subpanel: 'net_mrr_change',
  },
  METRICS_FINANCE_MRR_CHANGE_BREAKDOWN_MOVEMENTS: {
    panel: 'value',
    subpanel: 'net_mrr_change',
  },

  METRICS_FINANCE_NEW_CUSTOMERS_MRR: {
    panel: 'value',
    subpanel: 'new_customers_mrr',
  },
  METRICS_FINANCE_NEW_CUSTOMERS_MRR_BREAKDOWN_NEWEST_CUSTOMERS: {
    panel: 'value',
    subpanel: 'new_customers_mrr',
  },
  METRICS_FINANCE_NEW_CUSTOMERS_MRR_BREAKDOWN_BY_PRODUCT: {
    panel: 'value',
    subpanel: 'new_customers_mrr',
  },

  METRICS_FINANCE_EXPANSION: { panel: 'value', subpanel: 'expansion' },
  METRICS_FINANCE_EXPANSION_BREAKDOWN_LATEST_UPGRADES: {
    panel: 'value',
    subpanel: 'expansion',
  },
  METRICS_FINANCE_EXPANSION_BREAKDOWN_LONGEST_CUSTOMERS: {
    panel: 'value',
    subpanel: 'expansion',
  },

  METRICS_FINANCE_CHURN: { panel: 'value', subpanel: 'churn' },
  METRICS_FINANCE_CHURN_BREAKDOWN_LATEST_CHURNS: {
    panel: 'value',
    subpanel: 'churn',
  },
  METRICS_FINANCE_CHURN_BREAKDOWN_LARGEST_CHURNS: {
    panel: 'value',
    subpanel: 'churn',
  },

  METRICS_FINANCE_RECURRING_CUSTOMERS_CHART: {
    panel: 'volume',
    subpanel: 'recurring_customers',
  },
  METRICS_FINANCE_RECURRING_CUSTOMERS_BREAKDOWN_BY_PRODUCT: {
    panel: 'volume',
    subpanel: 'recurring_customers',
  },
  METRICS_FINANCE_RECURRING_CUSTOMERS_BREAKDOWN_BY_CLIENT: {
    panel: 'volume',
    subpanel: 'recurring_customers',
  },

  METRICS_FINANCE_NEW_TRIALS: { panel: 'volume', subpanel: 'new_trials' },
  METRICS_FINANCE_NEW_TRIALS_BREAKDOWN_LATEST_TRIALS: {
    panel: 'volume',
    subpanel: 'new_trials',
  },
  METRICS_FINANCE_NEW_TRIALS_BREAKDOWN_ENDING_SOON_TRIALS: {
    panel: 'volume',
    subpanel: 'new_trials',
  },

  METRICS_FINANCE_NEW_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'new_customers',
  },
  METRICS_FINANCE_NEW_CUSTOMERS_BREAKDOWN_NEWEST_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'new_customers',
  },
  METRICS_FINANCE_NEW_CUSTOMERS_BREAKDOWN_BY_PRODUCT: {
    panel: 'volume',
    subpanel: 'new_customers',
  },

  METRICS_FINANCE_EXPANSION_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'expansion_customers',
  },
  METRICS_FINANCE_EXPANSION_CUSTOMERS_BREAKDOWN_LATEST_UPGRADES: {
    panel: 'volume',
    subpanel: 'expansion_customers',
  },
  METRICS_FINANCE_EXPANSION_CUSTOMERS_BREAKDOWN_LONGEST_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'expansion_customers',
  },

  METRICS_FINANCE_CHURN_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'churn_customers',
  },
  METRICS_FINANCE_CHURN_BREAKDOWN_LATEST_CHURNS_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'churn_customers',
  },
  METRICS_FINANCE_CHURN_BREAKDOWN_LARGEST_CHURNS_CUSTOMERS: {
    panel: 'volume',
    subpanel: 'churn_customers',
  },

  METRIC_DICTIONARY_FINANCE_ARR_CHART: { menu: 'arr', switch: 'arr' },
  METRIC_DICTIONARY_FINANCE_MRR_CHART: { menu: 'mrr', switch: 'mrr' },
  METRIC_DICTIONARY_FINANCE_CUSTOMERS_CHART: {
    menu: 'customers',
    switch: 'customers',
  },
  METRIC_DICTIONARY_FINANCE_ARPC_CHART: { menu: 'arpc', switch: 'arpc' },
  METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_CHART: {
    menu: 'net_mrr_change',
    switch: 'net_mrr_change',
  },
  METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CHART: {
    menu: 'new_customers_mrr',
    switch: 'new_customers_mrr',
  },
  METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_CHART: {
    menu: 'new_customers_mrr',
    switch: 'new_customers',
  },
  METRIC_DICTIONARY_FINANCE_CHURN_CHART: { menu: 'churn', switch: 'churn' },
  METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_CHART: {
    menu: 'churn',
    switch: 'churn_customers_count',
  },
  METRIC_DICTIONARY_FINANCE_EXPANSION_CHART: {
    menu: 'expansion',
    switch: 'expansion',
  },
  METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_CHART: {
    menu: 'expansion',
    switch: 'expansion_customers_count',
  },
  METRIC_DICTIONARY_FINANCE_CONTRACTION_CHART: {
    menu: 'contraction',
    switch: 'contraction',
  },
  METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_CHART: {
    menu: 'contraction',
    switch: 'contraction_customers_count',
  },
  METRIC_DICTIONARY_FINANCE_REACTIVATION_CHART: {
    menu: 'reactivation',
    switch: 'reactivation',
  },
  METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_CHART: {
    menu: 'reactivation',
    switch: 'reactivation_customers_count',
  },
  METRIC_DICTIONARY_FINANCE_MRR_WON_CHART: {
    menu: 'mrr_won',
    switch: 'mrr_won',
  },
  METRIC_DICTIONARY_FINANCE_MRR_LOST_CHART: {
    menu: 'mrr_lost',
    switch: 'mrr_lost',
  },
  METRIC_DICTIONARY_FINANCE_QUICK_RATIO_CHART: {
    menu: 'quick_ratio',
    switch: 'quick_ratio',
  },
  METRIC_DICTIONARY_FINANCE_GRR_CHART: { menu: 'grr', switch: 'grr' },
  METRIC_DICTIONARY_FINANCE_NRR_CHART: { menu: 'nrr', switch: 'nrr' },
  METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_CHART: {
    menu: 'average_ltv',
    switch: 'average_ltv',
  },
  METRIC_DICTIONARY_FINANCE_NEW_TRIALS_CHART: {
    menu: 'new_trials',
    switch: 'new_trials',
  },
  METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_CHART: {
    menu: 'new_accounts',
    switch: 'new_accounts',
  },
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_CHART: {
    menu: 'trials_conversion_rate',
    switch: 'trials_conversion_rate',
  },
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_CHART: {
    menu: 'trials_converted',
    switch: 'trials_converted',
  },
  METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_CHART: {
    menu: 'trials_cancelled',
    switch: 'trials_cancelled',
  },
  METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_CHART: {
    menu: 'total_accounts',
    switch: 'total_accounts',
  },

  METRICS_GROWTH_VISITORS: { panel: 'events', subpanel: 'new_visitors' },
  METRICS_GROWTH_VISITORS_BREAKDOWN_BY_MEDIUM: {
    panel: 'events',
    subpanel: 'new_visitors',
  },
  METRICS_GROWTH_VISITORS_BREAKDOWN_BY_COUNTRY: {
    panel: 'events',
    subpanel: 'new_visitors',
  },

  METRICS_GROWTH_SESSIONS: { panel: 'events', subpanel: 'sessions' },
  METRICS_GROWTH_SESSIONS_BREAKDOWN_BY_LANDING: {
    panel: 'events',
    subpanel: 'sessions',
  },
  METRICS_GROWTH_SESSIONS_BREAKDOWN_BY_OS: {
    panel: 'events',
    subpanel: 'sessions',
  },

  METRICS_GROWTH_ENGAGEMENT: { panel: 'events', subpanel: 'engagement_rate' },
  METRICS_GROWTH_ENGAGEMENT_BREAKDOWN_BY_MEDIUM: {
    panel: 'events',
    subpanel: 'engagement_rate',
  },
  METRICS_GROWTH_ENGAGEMENT_BREAKDOWN_BY_CAMPAIGN: {
    panel: 'events',
    subpanel: 'engagement_rate',
  },

  METRICS_GROWTH_AVERAGE_TIME_ON_SITE: {
    panel: 'events',
    subpanel: 'time_on_site',
  },
  METRICS_GROWTH_TIME_BREAKDOWN_BY_PAGE: {
    panel: 'events',
    subpanel: 'time_on_site',
  },
  METRICS_GROWTH_VISITORS_BREAKDOWN_BY_PAGE: {
    panel: 'events',
    subpanel: 'time_on_site',
  },

  METRICS_GROWTH_LEADS: { panel: 'events', subpanel: 'leads' },
  METRICS_GROWTH_LEADS_BREAKDOWN_BY_MEDIUM: {
    panel: 'events',
    subpanel: 'leads',
  },
  METRICS_GROWTH_LEADS_BREAKDOWN_BY_CAMPAIGN: {
    panel: 'events',
    subpanel: 'leads',
  },

  METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CHART: {
    menu: 'new_visitors',
    switch: 'new_visitors',
  },
  METRIC_DICTIONARY_GROWTH_SESSIONS_CHART: {
    menu: 'sessions',
    switch: 'sessions',
  },
  METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_CHART: {
    menu: 'engaged_sessions',
    switch: 'engaged_sessions',
  },
  METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_CHART: {
    menu: 'engagement_rate',
    switch: 'engagement_rate',
  },
  METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_CHART: {
    menu: 'page_views',
    switch: 'page_views',
  },
  METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_CHART: {
    menu: 'pages_per_session',
    switch: 'pages_per_session',
  },
  METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_CHART: {
    menu: 'average_session_duration',
    switch: 'average_session_duration',
  },
  METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_CHART: {
    menu: 'average_page_view_time',
    switch: 'average_page_view_time',
  },
  METRIC_DICTIONARY_GROWTH_LEADS_CHART: { menu: 'leads', switch: 'leads' },
  METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_CHART: {
    menu: 'lead_conversion_rate',
    switch: 'lead_conversion_rate',
  },

  METRICS_SALES_WON_DEALS_COMPANIES: {
    panel: 'companies',
    subpanel: 'won_deals',
  },
  METRICS_SALES_DEAL_BREAKDOWN_RECENTLY_CLOSED_COMPANIES: {
    panel: 'companies',
    subpanel: 'won_deals',
  },
  METRICS_SALES_DEAL_BREAKDOWN_LARGEST_ACTIVE_ACCOUNTS_COMPANIES: {
    panel: 'companies',
    subpanel: 'won_deals',
  },

  METRICS_SALES_AVG_DEAL_VALUE_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_deal_value_companies',
  },
  METRICS_SALES_AVG_DEAL_VALUE_CLOSING_SOON_BREAKDOWN_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_deal_value_companies',
  },
  METRICS_SALES_AVG_DEAL_VALUE_STALLED_BREAKDOWN_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_deal_value_companies',
  },

  METRICS_SALES_PIPELINE_OVERVIEW_COMPANIES: {
    panel: 'companies',
    subpanel: 'pipeline_deals_count',
  },
  METRICS_SALES_PIPELINE_BREAKDOWN_LATEST_ADDED_COMPANIES: {
    panel: 'companies',
    subpanel: 'pipeline_deals_count',
  },
  METRICS_SALES_PIPELINE_BREAKDOWN_SUMMARY_COMPANIES: {
    panel: 'companies',
    subpanel: 'pipeline_deals_count',
  },

  METRICS_SALES_WIN_RATE_COMPANIES: {
    panel: 'companies',
    subpanel: 'win_rate_companies',
  },
  METRICS_SALES_WIN_RATE_BREAKDOWN_BY_DEAL_SIZE_COMPANIES: {
    panel: 'companies',
    subpanel: 'win_rate_companies',
  },
  METRICS_SALES_WIN_RATE_BREAKDOWN_RECENTLY_LOST_COMPANIES: {
    panel: 'companies',
    subpanel: 'win_rate_companies',
  },

  METRICS_SALES_AVG_SALES_CYCLE_RATE_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_sales_cycle_companies',
  },
  METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_FASTEST_MOVING_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_sales_cycle_companies',
  },
  METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_BY_OWNER_COMPANIES: {
    panel: 'companies',
    subpanel: 'avg_sales_cycle_companies',
  },

  METRICS_SALES_MONTHLY_SALES: { panel: 'value', subpanel: 'sales' },
  METRICS_SALES_DEAL_BREAKDOWN_RECENTLY_CLOSED: {
    panel: 'value',
    subpanel: 'sales',
  },
  METRICS_SALES_DEAL_BREAKDOWN_LARGEST_ACTIVE_ACCOUNTS: {
    panel: 'value',
    subpanel: 'sales',
  },

  METRICS_SALES_AVG_DEAL_VALUE: { panel: 'value', subpanel: 'avg_deal_value' },
  METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN_CLOSING_SOON: {
    panel: 'value',
    subpanel: 'avg_deal_value',
  },
  METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN_STALLED: {
    panel: 'value',
    subpanel: 'avg_deal_value',
  },

  METRICS_SALES_PIPELINE_OVERVIEW: {
    panel: 'value',
    subpanel: 'pipeline_value',
  },
  METRICS_SALES_PIPELINE_BREAKDOWN_LATEST_ADDED: {
    panel: 'value',
    subpanel: 'pipeline_value',
  },
  METRICS_SALES_PIPELINE_BREAKDOWN_SUMMARY: {
    panel: 'value',
    subpanel: 'pipeline_value',
  },

  METRICS_SALES_WIN_RATE: { panel: 'value', subpanel: 'win_rate' },
  METRICS_SALES_WIN_RATE_BREAKDOWN_BY_DEAL_SIZE: {
    panel: 'value',
    subpanel: 'win_rate',
  },
  METRICS_SALES_WIN_RATE_BREAKDOWN_RECENTLY_LOST: {
    panel: 'value',
    subpanel: 'win_rate',
  },

  METRICS_SALES_AVG_SALES_CYCLE_RATE: {
    panel: 'value',
    subpanel: 'avg_sales_cycle',
  },
  METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_FASTEST_MOVING: {
    panel: 'value',
    subpanel: 'avg_sales_cycle',
  },
  METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_BY_OWNER: {
    panel: 'value',
    subpanel: 'avg_sales_cycle',
  },

  METRIC_DICTIONARY_SALES_TOTAL_SALES_CHART: {
    menu: 'total_sales',
    switch: 'total_sales',
  },
  METRIC_DICTIONARY_SALES_TOTAL_DEALS_CHART: {
    menu: 'total_sales',
    switch: 'total_deals',
  },
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_CHART: {
    menu: 'average_sales_amount',
    switch: 'average_sales_amount',
  },
  METRIC_DICTIONARY_SALES_LOST_DEALS_CHART: {
    menu: 'lost_deals',
    switch: 'lost_deals',
  },
  METRIC_DICTIONARY_SALES_WIN_RATE_CHART: {
    menu: 'win_rate',
    switch: 'win_rate',
  },
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_CHART: {
    menu: 'average_sales_cycle',
    switch: 'average_sales_cycle',
  },
  METRIC_DICTIONARY_SALES_VELOCITY_CHART: {
    menu: 'velocity',
    switch: 'velocity',
  },
  METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CHART: {
    menu: 'pipeline_value',
    switch: 'pipeline_value',
  },
  METRIC_DICTIONARY_SALES_PIPELINE_DEALS_CHART: {
    menu: 'pipeline_value',
    switch: 'pipeline_deals',
  },

  METRICS_PRODUCT_SIGN_UPS: { panel: 'users', subpanel: 'new_sign_ups' },
  METRICS_PRODUCT_RECENTLY_SIGNED_UP: {
    panel: 'users',
    subpanel: 'new_sign_ups',
  },
  METRICS_PRODUCT_BEST_RETENTION_COHORTS: {
    panel: 'users',
    subpanel: 'new_sign_ups',
  },

  METRICS_PRODUCT_ACTIVE_USERS: { panel: 'users', subpanel: 'active_users' },
  METRICS_PRODUCT_MOST_ACTIVE: { panel: 'users', subpanel: 'active_users' },
  METRICS_PRODUCT_USERS_WITH_LARGEST_INCREASE_IN_ACTIVITY: {
    panel: 'users',
    subpanel: 'active_users',
  },

  METRICS_PRODUCT_ENGAGED_USERS: { panel: 'users', subpanel: 'engaged_users' },
  METRICS_PRODUCT_MOST_ENGAGED: { panel: 'users', subpanel: 'engaged_users' },
  METRICS_PRODUCT_RECENTLY_ENGAGED: {
    panel: 'users',
    subpanel: 'engaged_users',
  },

  METRICS_PRODUCT_POWER_USERS: { panel: 'users', subpanel: 'power_users' },
  METRICS_PRODUCT_TOP_POWER_USERS: { panel: 'users', subpanel: 'power_users' },
  METRICS_PRODUCT_NEW_POWER_USERS: { panel: 'users', subpanel: 'power_users' },

  METRICS_PRODUCT_WAU_MAU: { panel: 'users', subpanel: 'wau_mau' },
  METRICS_PRODUCT_RETENTION_COHORTS: { panel: 'users', subpanel: 'wau_mau' },
  METRICS_PRODUCT_USERS_WITH_DECREASED_USAGE: {
    panel: 'users',
    subpanel: 'wau_mau',
  },

  METRICS_PRODUCT_COMPANIES_SIGN_UPS: {
    panel: 'companies',
    subpanel: 'companies_new_sign_ups',
  },
  METRICS_PRODUCT_RECENTLY_SIGNED_UP_COMPANIES: {
    panel: 'companies',
    subpanel: 'companies_new_sign_ups',
  },
  METRICS_PRODUCT_COMPANIES_BEST_RETENTION_COHORTS: {
    panel: 'companies',
    subpanel: 'companies_new_sign_ups',
  },

  METRICS_PRODUCT_ACTIVE_COMPANIES: {
    panel: 'companies',
    subpanel: 'active_companies',
  },
  METRICS_PRODUCT_MOST_ACTIVE_COMPANIES: {
    panel: 'companies',
    subpanel: 'active_companies',
  },
  METRICS_PRODUCT_COMPANIES_WITH_LARGEST_INCREASE_IN_ACTIVITY: {
    panel: 'companies',
    subpanel: 'active_companies',
  },

  METRICS_PRODUCT_ENGAGED_COMPANIES: {
    panel: 'companies',
    subpanel: 'engaged_companies',
  },
  METRICS_PRODUCT_MOST_ENGAGED_COMPANIES: {
    panel: 'companies',
    subpanel: 'engaged_companies',
  },
  METRICS_PRODUCT_RECENTLY_ENGAGED_COMPANIES: {
    panel: 'companies',
    subpanel: 'engaged_companies',
  },

  METRICS_PRODUCT_COMPANIES_POWER_USERS: {
    panel: 'companies',
    subpanel: 'power_companies',
  },
  METRICS_PRODUCT_COMPANIES_TOP_POWER_USERS: {
    panel: 'companies',
    subpanel: 'power_companies',
  },
  METRICS_PRODUCT_COMPANIES_NEW_POWER_USERS: {
    panel: 'companies',
    subpanel: 'power_companies',
  },

  METRICS_PRODUCT_WAC_MAC: { panel: 'companies', subpanel: 'wac_mac' },
  METRICS_PRODUCT_COMPANY_RETENTION_COHORTS: {
    panel: 'companies',
    subpanel: 'wac_mac',
  },
  METRICS_PRODUCT_COMPANIES_WITH_DECREASED_USAGE: {
    panel: 'companies',
    subpanel: 'wac_mac',
  },

  METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CHART: { menu: 'new_sign_ups' },
  METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_CHART: { menu: 'active_users' },
  METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_CHART: { menu: 'engaged_users' },
  METRIC_DICTIONARY_PRODUCT_POWER_USERS_CHART: { menu: 'power_users' },
  METRIC_DICTIONARY_PRODUCT_WAU_MAU_CHART: { menu: 'wau_mau' },
};

function getBlockSection(name: MetricBlockName) {
  if (name.startsWith('METRIC_DICTIONARY')) {
    return 'DICTIONARY';
  }

  return 'METRICS';
}

const getDepartmentUrl = (
  baseUrl: typeof paths.dictionary | typeof paths.missionControl,
  department: Department,
): string => {
  switch (department) {
    case 'GROWTH':
      return baseUrl.marketing;
    case 'PRODUCT':
      return baseUrl.product;
    case 'FINANCE':
      return baseUrl.finance;
    case 'SALES':
      return baseUrl.sales;
    default:
      return baseUrl.marketing;
  }
};

export function getUrl(item: BlockCommandPanelOption): string {
  if (item.uniqueName.startsWith('MISSION_CONTROL')) {
    return paths.missionControl.index;
  }

  const section = getBlockSection(item.uniqueName);
  const baseUrl =
    section === 'DICTIONARY' ? paths.dictionary : paths.missionControl;
  const departmentUrl = getDepartmentUrl(baseUrl, item.department);
  const queryParams = redirectMappings[item.uniqueName] || {};
  const queryParamsAsString = Object.entries(queryParams).reduce(
    (acc, [key, value]) => `${acc}${key}=${value}&`,
    '?',
  );
  return `${departmentUrl}${queryParamsAsString}`;
}

export function getIconByUniqueName(name: MetricBlockName): ReactNode {
  if (name.includes('BREAKDOWN') || name.includes('BY')) {
    return iconsByType.BREAKDOWN;
  }
  const section = getBlockSection(name);
  if (section == 'DICTIONARY') {
    return iconsByType.METRICS_DICTIONARY;
  }

  return iconsByType.METRIC;
}

export function getCommandPanelSubtitle(uniqueName: MetricBlockName) {
  switch (getBlockSection(uniqueName)) {
    case 'DICTIONARY':
      return 'dictionary';
    case 'METRICS':
      return 'metrics';
    default:
      return '';
  }
}
