import { z } from 'zod';

import { formattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByPageRow = z.object({
  resource: z.string(),
  totalTime: z.coerce.number(),
  pageViews: z.coerce.number(),
  conversions: z.coerce.number(),
  avgTimePerPage: z.coerce.number(),
});

export type FullBreakdownByPageRow = z.infer<typeof FullBreakdownByPageRow>;

export const breakdownByPageViewsAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByPageRow,
  [
    {
      column: 'pageViews',
      direction: 'DESC',
    },
  ],
  formattedDatePickerAtom,
);
