import { atom } from 'jotai';

import { Filter } from 'data/charts/models/ChartsApiRequest';

interface SharingConfig {
  split?: string;
  legendToggle?: string;
  filters: Filter[];
}

export const sharingConfigAtom = atom<SharingConfig, Partial<SharingConfig>>(
  {
    filters: [],
  },
  (get, set, update) => {
    const currentValue = get(sharingConfigAtom);
    set(sharingConfigAtom, {
      ...currentValue,
      ...update,
    });
  },
);

export const importedSharingConfig = atom<
  SharingConfig,
  Partial<SharingConfig>
>(
  {
    filters: [],
  },
  (get, set, update) => {
    const currentValue = get(importedSharingConfig);
    set(importedSharingConfig, {
      ...currentValue,
      ...update,
    });
  },
);
