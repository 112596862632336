import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react';

import { AiContent } from 'components/AiDrawer/AiContent';
import { VStack } from 'components/uikit';
import { useAiDrawerOpen, useCloseAiDrawer } from 'data/ai/hooks';
import { hideScrollbar } from 'styles/hideScrollbar';

export function AiDrawer() {
  const closeAiDrawer = useCloseAiDrawer();
  const aiDrawerOpen = useAiDrawerOpen();

  return (
    <Drawer
      isOpen={aiDrawerOpen}
      placement='right'
      onClose={closeAiDrawer}
      size='lg'
    >
      <DrawerOverlay />
      <DrawerContent
        overflow='scroll'
        css={hideScrollbar}
        backgroundColor='grey.offWhite'
      >
        <VStack align='stretch' spacing='0' maxH='100%' h='100%'>
          <AiContent />
        </VStack>
      </DrawerContent>
    </Drawer>
  );
}
