import { Fragment, useEffect } from 'react';

import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { AiChatArchiveFooter } from 'components/AiDrawer/AiChatArchive/AiChatArchiveFooter';
import { AiChatArchiveHeader } from 'components/AiDrawer/AiChatArchive/AiChatArchiveHeader';
import { AiChatArchiveItem } from 'components/AiDrawer/AiChatArchive/AiChatArchiveItem';
import { Button, Skeleton, Text, VStack } from 'components/uikit';
import { aiResponseAtom, fetchAiChatsAtom } from 'data/ai/atoms';
import { Chat } from 'data/ai/models';
import { range } from 'utils/helpers';
import { resolveState } from 'utils/types';

interface AiChatProps {
  onClose?: () => void;
  onNewChat?: () => void;
  onSelectChat: (chatId: Chat) => void;
  fullScreen?: boolean;
}

function getLoadingSkeleton(fullScreen = false) {
  return fullScreen ? (
    <Skeleton w='100%' h='64px' />
  ) : (
    <Skeleton w='100%' h='144px' />
  );
}

export function AiChatArchive({
  onClose,
  onNewChat,
  onSelectChat,
  fullScreen = false,
}: AiChatProps) {
  const { t } = useTranslation('ai');
  const [resultRemote, fetchChats] = useAtom(fetchAiChatsAtom);
  const {
    context: { id: selectedChatId },
  } = useAtomValue(aiResponseAtom);

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  return (
    <>
      {onClose ? <AiChatArchiveHeader onClose={onClose} /> : null}
      {fullScreen ? (
        <Text pt='24px' pr='24px' pb='8px' fontSize='lg' fontWeight='semibold'>
          Past Questions
        </Text>
      ) : null}
      <VStack
        align='stretch'
        spacing='16px'
        p='24px'
        pl={fullScreen ? '0' : undefined}
        pt='0'
        backgroundColor={fullScreen ? 'grey.background' : 'grey.offWhite'}
        overflowY='auto'
        h={fullScreen ? 'calc(100% - 59px)' : undefined}
        flex='1'
      >
        {resolveState(resultRemote, {
          LOADING: () => (
            <>
              {range(fullScreen ? 8 : 3).map((index) => (
                <Fragment key={index}>
                  {getLoadingSkeleton(fullScreen)}
                </Fragment>
              ))}
            </>
          ),
          SUCCESS: ({ results }) => (
            <>
              {results.map((chat) => (
                <AiChatArchiveItem
                  key={chat.id}
                  {...chat}
                  fullScreen={fullScreen}
                  isSelected={selectedChatId === chat.id && fullScreen}
                  onSelectChat={onSelectChat}
                />
              ))}
            </>
          ),
          ERROR: () => (
            <VStack align='start' justify='start'>
              <Text color='error.text'>{t('questions_fetch_error')}</Text>
              <Button variant='secondary' onClick={fetchChats}>
                {t('common:retry')}
              </Button>
            </VStack>
          ),
        })}
      </VStack>
      {onNewChat ? <AiChatArchiveFooter onNewChat={onNewChat} /> : null}
    </>
  );
}
