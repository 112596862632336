import { useCallback, useState } from 'react';

import { createSharingLinkRequest } from 'api/rest/sharing/createSharingLinkRequest';
import { SharingLink } from 'data/sharingLink/models/sharingLink';
import { RequestStatus } from 'utils/requests/types';

export function useCreateSharingLink() {
  const [requestStatus, setRequestStatus] = useState<
    RequestStatus<SharingLink>
  >({
    fetchStatus: 'INITIAL',
  });

  const createSharingLink = useCallback(async (blockId: string) => {
    setRequestStatus({ fetchStatus: 'INITIAL' });
    const response = await createSharingLinkRequest(blockId);
    setRequestStatus(response);
    return response.fetchStatus === 'SUCCESS' ? response.response : null;
  }, []);

  return {
    requestStatus,
    createSharingLink,
  };
}
