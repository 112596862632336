import { ReactElement, useMemo } from 'react';

import { AxisTickProps } from '@nivo/axes/dist/types/types';
import { Margin } from '@nivo/core';
import { Line, Serie } from '@nivo/line';
import { TooltipAnchor } from '@nivo/tooltip/dist/types/types';
import { atom, useAtomValue, WritableAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';

import { ChartLegend, JustifyLegend } from 'components/charts/ChartLegend';
import { useAreasLayer } from 'components/charts/Layers/AreasLayer';
import { makeLinesLayer } from 'components/charts/Layers/LinesLayer';
import {
  TooltipDataFormatter,
  TooltipLabelFormatter,
  TooltipTitleFormatter,
} from 'components/charts/LineChart/LineChartTooltip';
import { useEffectiveChartHeight } from 'components/charts/useEffectiveChartHeight';
import useColorModeChecker from 'components/theme/isLightTheme';
import { Box } from 'components/uikit';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { formatPropelLineData } from 'data/charts/formatters/finance/formatLineData';
import {
  getAxisLeftFormatter,
  renderLeftTick,
} from 'data/charts/formatters/formatLeftTick';
import {
  formatTimeAxisValues,
  SpecificGranularity,
} from 'data/charts/formatters/formatTimeAxisValues';
import { TimeSeries } from 'data/responseModels/TimeSeries';
import {
  getChartDefaultChartMargins,
  getLegendKeys,
  getNiceScale,
} from 'utils/chartsUtils';
import {
  formatAggPeriodLabels,
  getEndOfPeriodOrToday,
} from 'utils/datetime/dateUtil';
import { last } from 'utils/helpers';
import { useIsMobile } from 'utils/hooks/screenBreakpoints';
import {
  getLineChartDefsAndFills,
  getLineChartMaxValue,
  GetSeriesItemColorFn,
} from 'utils/lineChartUtils';
import { FetchStatus } from 'utils/requests/types';
import { isFetchError, isLoading } from 'utils/types';

import { ChartSkeleton, ChartSkeletonType } from '../ChartSkeleton';

type TimeTickFrequency =
  | 'every second'
  | 'every minute'
  | 'every hour'
  | 'every day'
  | 'every week'
  | 'every month'
  | 'every year';

interface ChartProps {
  data: Serie[];
  width: number;
  height: number;
  timeTickFormat: (tick: AxisTickProps<string>) => ReactElement;
  timeTickFrequency: TimeTickFrequency;
  tooltipDataFormatter: TooltipDataFormatter;
  tooltipTitleFormatter: TooltipTitleFormatter;
  tooltipLabelFormatter?: TooltipLabelFormatter;
  leftTickFormat?: (value: number) => string;
  colors: string[];
  hasGradient?: boolean;
  getSeriesItemColor?: GetSeriesItemColorFn;
  margin?: Partial<Margin>;
  lastLineDotted: boolean;
  alwaysShowSeriesColorOnTooltip?: boolean;
  tooltipAnchor?: TooltipAnchor;
}

function Chart({
  data,
  width,
  height,
  timeTickFormat,
  timeTickFrequency,
  tooltipDataFormatter,
  tooltipTitleFormatter,
  tooltipLabelFormatter,
  colors,
  leftTickFormat,
  margin,
  lastLineDotted,
  getSeriesItemColor,
  hasGradient = true,
  alwaysShowSeriesColorOnTooltip = false,
  tooltipAnchor,
}: ChartProps) {
  const isLight = useColorModeChecker();
  const isMobile = useIsMobile();

  const { defs, fills } = hasGradient
    ? getLineChartDefsAndFills(data, isLight)
    : { defs: [], fills: [] };

  const linesLayer = useMemo(
    () =>
      makeLinesLayer(
        {
          alwaysShowSeriesColor: alwaysShowSeriesColorOnTooltip,
          tooltipDataFormatter,
          tooltipTitleFormatter,
          tooltipLabelFormatter,
          tooltipAnchor,
        },
        lastLineDotted,
        getSeriesItemColor,
      ),
    [
      lastLineDotted,
      tooltipDataFormatter,
      tooltipTitleFormatter,
      tooltipLabelFormatter,
      alwaysShowSeriesColorOnTooltip,
      getSeriesItemColor,
    ],
  );

  const areasLayer = useAreasLayer(getSeriesItemColor, hasGradient);

  const { maxValue, allZeroValues } = useMemo(() => {
    const maxValue = getLineChartMaxValue(data);
    return {
      maxValue: maxValue === 0 ? 1 : maxValue,
      allZeroValues: maxValue === 0,
    };
  }, [data]);

  const scaleMax = getNiceScale(maxValue);

  return (
    <Line
      data={data}
      width={width}
      height={height}
      enableArea={hasGradient}
      defs={defs}
      fill={fills}
      margin={{
        ...getChartDefaultChartMargins(isMobile),
        ...margin,
      }}
      colors={colors}
      pointBorderColor={{ from: 'serieColor' }}
      pointColor='grey.white'
      pointSize={1}
      pointBorderWidth={1}
      animate
      enableSlices='x'
      theme={{
        crosshair: {
          line: {
            strokeOpacity: 1,
            stroke: 'var(--chakra-colors-gray-400)',
            strokeWidth: 1,
            strokeDasharray: '10px',
          },
        },
        grid: {
          line: {
            stroke: isLight
              ? 'var(--chakra-colors-gray-200)'
              : 'var(--chakra-colors-grey-border)',
          },
        },
        fontFamily: 'var(--font-numeric)',
      }}
      enableGridX={false}
      yScale={{
        type: 'linear',
        nice: true,
        clamp: true,
        stacked: false,
        max: scaleMax,
      }}
      axisLeft={{
        renderTick: renderLeftTick,
        format: leftTickFormat || getAxisLeftFormatter('TIME_SERIES', null),
        tickSize: 0,
        tickPadding: 12,
        tickValues: allZeroValues ? [0] : 5, // Only show the tick value at the 0 line
      }}
      gridYValues={5}
      axisBottom={{
        renderTick: timeTickFormat,
        tickSize: 0,
        tickPadding: 0,
        tickValues: timeTickFrequency,
        legendOffset: -12,
      }}
      layers={['grid', 'markers', areasLayer, 'axes', linesLayer, 'crosshair']}
    />
  );
}

interface LineChartProps {
  data: { [k: string]: TimeSeries };
  fetchStatus: FetchStatus;
  granularity: AggregationPeriod;
  specificGranularity?: SpecificGranularity;
  testId?: string;
  colors: string[];
  getSeriesItemColor?: GetSeriesItemColorFn;
  legend: 'visible' | 'invisible' | 'none';
  leftTickFormat?: (value: number) => string;
  tooltipTitleFormatter: (value: Date) => string;
  tooltipDataFormatter: TooltipDataFormatter;
  tooltipLabelFormatter?: TooltipLabelFormatter;
  formatLabel?: (key: string) => string;
  hasGradient?: boolean;
  height?: number;
  margin?: Partial<Margin>;
  legendAtom?: WritableAtom<string[], string[]>;
  justifyLegend?: JustifyLegend;
  staticLegend?: boolean;
  alwaysShowSeriesColorOnTooltip?: boolean;
  tooltipAnchor?: TooltipAnchor;
}

const emptyHiddenKeysAtom = atom(() => []);

export function LineChart({
  data,
  fetchStatus,
  testId,
  granularity,
  specificGranularity,
  formatLabel,
  legend = 'visible',
  height = 500,
  legendAtom,
  colors,
  justifyLegend,
  staticLegend,
  ...rest
}: LineChartProps) {
  const isMobile = useIsMobile();
  const effectiveHeight = useEffectiveChartHeight(height, legend === 'visible');
  const hiddenKeys = useAtomValue(legendAtom ?? emptyHiddenKeysAtom);
  const { t } = useTranslation('mission-control');
  const formattedData = useMemo(() => {
    return formatPropelLineData(
      formatLabel ?? t,
      formatAggPeriodLabels(data, granularity),
      granularity,
      colors,
    );
  }, [formatLabel, t, data, granularity, colors]);

  const keys = useMemo(
    () => getLegendKeys(formattedData, colors),
    [formattedData, colors],
  );

  const normalizedData = useMemo(() => {
    return formattedData.flatMap((serie) =>
      hiddenKeys.includes(serie.id.toString()) ? [] : [serie],
    );
  }, [formattedData, hiddenKeys]);

  const dataIsLoading = isLoading(fetchStatus);
  const hasError = isFetchError(fetchStatus);
  if (dataIsLoading || hasError) {
    return (
      <Box h={`${effectiveHeight - 50}px`} mb='70px'>
        <ChartSkeleton
          isLoading={dataIsLoading}
          chartType={ChartSkeletonType.LINE}
        />
      </Box>
    );
  }

  const lastPeriod = last(normalizedData[0]?.data ?? [])?.x;
  const lastPeriodIsCurrent = lastPeriod
    ? getEndOfPeriodOrToday(new Date(lastPeriod), granularity).dateIsInFuture
    : false;

  return (
    <Box w='100%' h='100%' data-testid={testId}>
      <ReactVirtualizedAutoSizer disableHeight>
        {({ width }) => (
          <Box width={width}>
            <Chart
              data={normalizedData}
              width={width}
              height={effectiveHeight}
              timeTickFormat={formatTimeAxisValues(
                granularity,
                specificGranularity,
                isMobile,
              )}
              timeTickFrequency={
                isMobile
                  ? 'every week'
                  : (`every ${granularity.toLowerCase()}` as TimeTickFrequency)
              }
              colors={colors}
              lastLineDotted={lastPeriodIsCurrent}
              {...rest}
            />
            {legend === 'visible' && (legendAtom || staticLegend) ? (
              <ChartLegend
                keys={keys}
                legendAtom={legendAtom}
                justifyLegend={justifyLegend}
              />
            ) : legend === 'invisible' ? (
              <Box height='20px' />
            ) : null}
          </Box>
        )}
      </ReactVirtualizedAutoSizer>
    </Box>
  );
}
