import { useMemo } from 'react';

import { NextRouter, useRouter } from 'next/router';

export interface RelativeNavigateOptions {
  replace?: boolean;
  retainSearch?: boolean;
  retainHash?: boolean;
  shallow?: boolean;
}

// E.g. goUp('/initial/path/a/b/c', '../../../remainder/path') => '/initial/path/remainder/path'
function goUp(currentPath: string, path: string) {
  const splitPath = path.split('/');
  const levelsUp = splitPath.lastIndexOf('..') + 1;
  // E.g. ../../remainder/path => remainder/path
  const pathRemainder = '/' + splitPath.slice(levelsUp).join('/');
  // E.g. /initial/path/a/b/c, go up 3 levels => /initial/path
  const updatedInitialPath = currentPath
    .split('/')
    .slice(0, -levelsUp)
    .join('/');
  return (
    // replace any trailing slashes
    (updatedInitialPath + pathRemainder).replace(/\/+$/, '')
  );
}

export function calculateDestinationPath(path: string, currentPath: string) {
  // replace trailing slash
  if (path.startsWith('./')) return currentPath + path.replace('.', '');
  if (path === '.') return currentPath;
  if (path.startsWith('..')) return goUp(currentPath, path);
  return path;
}

/**
 * Relative routing works similar to cd in unix
 * /a/b/c navigate('./d') => /a/b/c/d
 * /a/b/c navigate('../d') => /a/b/d
 */
export function navigate(
  push: NextRouter['push'],
  replace: NextRouter['replace'],
) {
  return function (
    path: string,
    { shallow, ...options }: RelativeNavigateOptions = {},
  ) {
    let destination = calculateDestinationPath(
      path,
      window.location.pathname.replace(/\/$/, ''),
    );
    if (options.retainSearch) destination += window.location.search;
    if (options.retainHash) destination += window.location.hash;

    if (shallow) {
      if (options.replace) return replace(destination, undefined, { shallow });
      else return push(destination, undefined, { shallow });
    } else {
      if (options.replace) return replace(destination);
      else return push(destination);
    }
  };
}

export function useNavigate() {
  const { push, replace } = useRouter();
  return useMemo(() => {
    return navigate(push, replace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useLink(path: string) {
  const { asPath } = useRouter();
  if (path.startsWith('./') || path.startsWith('..')) {
    const calculatedPath = calculateDestinationPath(path, asPath);
    return { path: calculatedPath };
  }
  return { path };
}

export function reloadPage() {
  window.location.reload();
}
