import { useCallback, useEffect, useMemo } from 'react';

import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';

import { growthBreakdownSplitAtom } from 'components/pages/Metrics/FullBreakdowns/growth/useGrowthBreakdownsSplit';
import { MetricBlockName } from 'data/blocks/models/Block';
import {
  CUSTOMERS_BREAKDOWNS_CONFIG,
  CustomersBreakdownName,
} from 'data/charts/configs/breakdowns/customers';
import {
  FINANCE_BREAKDOWNS_CONFIG,
  FinanceBreakdownName,
} from 'data/charts/configs/breakdowns/finance';
import {
  GROWTH_BREAKDOWNS_CONFIG,
  GrowthBreakdownName,
} from 'data/charts/configs/breakdowns/growth';
import {
  PRODUCT_BREAKDOWN_CONFIG,
  SortableProductBreakdownNames,
} from 'data/charts/configs/breakdowns/product';
import {
  SALES_BREAKDOWN_CONFIG,
  SalesBreakdownName,
} from 'data/charts/configs/breakdowns/sales';
import { BreakdownConfigWithSorting } from 'data/charts/configs/breakdowns/types';
import { Direction, SortArray } from 'data/charts/models/ChartsApiRequest';
import {
  ofCurrentUrl,
  useDestination,
  withParams,
} from 'utils/destination/destination';
import { parseEnumOptional } from 'utils/parser/parseResponse';
import {
  getBooleanValue,
  getOptionalStringValue,
} from 'utils/url/urlQueryUtils';

function buildCustomSortArray(
  column: string | null,
  order: Direction | null,
): SortArray {
  if (!column || !order) {
    return [];
  }
  return [{ column, direction: order }];
}

export function useBreakdownSortingAtom(
  config: BreakdownConfigWithSorting<MetricBlockName>,
  defaultValue: SortArray | typeof RESET = RESET,
) {
  const [sortAtomValue, setSortAtom] = useAtom(config.atoms.sortingAtom);
  const parser = config.columnParser;
  const isCustomSort = getBooleanValue('custom', false);
  const columnParamValue = parseEnumOptional(
    parser,
    getOptionalStringValue('col'),
  );
  const orderParamValue = parseEnumOptional(
    Direction,
    getOptionalStringValue('order'),
  );

  const sortDestination = useDestination(
    ofCurrentUrl,
    withParams<string | undefined, Direction | undefined, boolean>(
      'col',
      'order',
      'custom',
    ),
  );
  const newSortValue = useMemo(
    () =>
      isCustomSort
        ? buildCustomSortArray(columnParamValue, orderParamValue)
        : defaultValue,
    [columnParamValue, orderParamValue, isCustomSort, defaultValue],
  );
  useEffect(() => {
    setSortAtom(newSortValue);
  }, [newSortValue, setSortAtom]);

  const setQueryParam = useCallback(
    (sorts: SortArray) => {
      const { column, direction } = sorts[0] ?? {};
      sortDestination.go(column, direction, true);
    },
    [sortDestination],
  );

  return [sortAtomValue, setQueryParam, isCustomSort] as const;
}

export function useCustomersSortingAtom(breakdownName: CustomersBreakdownName) {
  return useBreakdownSortingAtom(CUSTOMERS_BREAKDOWNS_CONFIG[breakdownName]);
}

export function useFinanceSortingAtom(breakdownName: FinanceBreakdownName) {
  return useBreakdownSortingAtom(FINANCE_BREAKDOWNS_CONFIG[breakdownName]);
}

export function useMarketingSortingAtom(
  breakdownName: GrowthBreakdownName,
  defaultValue: SortArray | typeof RESET = RESET,
) {
  return useBreakdownSortingAtom(
    GROWTH_BREAKDOWNS_CONFIG[breakdownName],
    defaultValue,
  );
}

export function useMarketingSortingAtomWithSplit(
  breakdownName: Exclude<GrowthBreakdownName, 'byPage'>,
) {
  const splitValue = useAtomValue(growthBreakdownSplitAtom);
  const defaultSort = useMemo(
    () => [<const>{ column: splitValue, direction: 'DESC' }],
    [splitValue],
  );
  const [sortValue, setSortValue, isCustomSort] = useMarketingSortingAtom(
    breakdownName,
    defaultSort,
  );

  return [sortValue, setSortValue, isCustomSort] as const;
}

export function useProductSortingAtom(
  breakdownName: SortableProductBreakdownNames,
) {
  return useBreakdownSortingAtom(PRODUCT_BREAKDOWN_CONFIG[breakdownName]);
}

export function useSalesSortingAtom(breakdownName: SalesBreakdownName) {
  return useBreakdownSortingAtom(SALES_BREAKDOWN_CONFIG[breakdownName]);
}
