import { useCallback, useEffect } from 'react';

import { WritableAtom, useAtom } from 'jotai';

import { useImportedSharingLegendToggle } from 'data/sharing/hooks/useImportedSharingLegendToggle';
import { useSetSharingLegendToggle } from 'data/sharing/hooks/useSetSharingLegendToggle';

export function useToggleChartLegend(
  keys: string[] = [],
  legendAtom: WritableAtom<string[], string[]>,
) {
  const [hiddenKeys, setHiddenKeys] = useAtom(legendAtom);

  const setSharingLegendToggle = useSetSharingLegendToggle(hiddenKeys);
  useEffect(setSharingLegendToggle, [setSharingLegendToggle]);

  const importedChartLegend = useImportedSharingLegendToggle(keys);
  useEffect(() => {
    if (importedChartLegend.length > 0) {
      setHiddenKeys(importedChartLegend);
    }
  }, [importedChartLegend, keys, setHiddenKeys]);

  const toggleKey = (toggledKey: string) => {
    const newHiddenKeys = hiddenKeys.filter(
      (hiddenKey) => hiddenKey !== toggledKey,
    );
    if (newHiddenKeys.length === hiddenKeys.length)
      newHiddenKeys.push(toggledKey);

    // No point showing an empty chart
    if (newHiddenKeys.length === keys.length) return setHiddenKeys([]);

    setHiddenKeys(newHiddenKeys);
  };

  const focusOnKey = useCallback(
    (focusedKey: string) => {
      setHiddenKeys(keys.filter((key) => key !== focusedKey));
    },
    [keys, setHiddenKeys],
  );

  return {
    hiddenKeys,
    toggleKey,
    focusOnKey,
  };
}
