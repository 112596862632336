import { useMemo } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import { List } from './List';
import { MenuListItem, MenuListItemBaseProps } from './MenuListItem';

interface MenuListProps<T> extends FlexProps {
  items: T[];
  initialSelection?: T | null;
  onItemSelected: (item: T | null) => void;
  formatItem: (item: T) => MenuListItemBaseProps;
}

export function MenuList<T>({
  items,
  initialSelection = null,
  onItemSelected,
  formatItem,
  ...props
}: MenuListProps<T>) {
  const formatted = useMemo(
    () => new Map(items.map((item) => [item, formatItem(item)])),
    [items, formatItem],
  );
  return (
    <Flex flexDirection='column' overflow='auto' {...props}>
      <List
        items={items}
        initialSelection={initialSelection}
        onItemSelected={onItemSelected}
        isItemDisabled={(item) => formatted.get(item)?.disabled ?? false}
        renderItem={(item, isSelected, selectItem) => {
          const formattedItem = formatted.get(item);
          if (!formattedItem) return null;
          const { image, title, subTitle, disabled, error, key } =
            formattedItem;
          return (
            <MenuListItem
              image={image}
              key={key}
              title={title}
              subTitle={subTitle}
              onSelect={selectItem}
              selected={isSelected}
              error={error}
              disabled={disabled}
            />
          );
        }}
      />
    </Flex>
  );
}
