import { PropsWithChildren } from 'react';

import { FlexProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { ShareBlockIconButton } from 'components/Sharing/ShareBlockIconButton';
import { ShareOptions } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import { Flex, IconButton, MoreVerticalIcon } from 'components/uikit';
import { useIsSharedContent } from 'utils/hooks/useIsSharedContent';
import { useOpenIntercom } from 'utils/intercom/useOpenIntercom';

export function TableDefaultControls({
  blockId,
  trackDataErrorReport,
  sharingOptions,
  children: header,
  ...flexProps
}: {
  blockId: string | undefined;
  trackDataErrorReport: () => void;
  sharingOptions: ShareOptions;
} & FlexProps &
  PropsWithChildren) {
  const { t } = useTranslation('breakdowns');
  const openIntercom = useOpenIntercom();
  const isSharedContent = useIsSharedContent();

  return (
    <Flex {...flexProps}>
      {!isSharedContent && blockId && (
        <ShareBlockIconButton
          blockId={blockId}
          iconSize='16px'
          sharingOptions={sharingOptions}
        />
      )}
      <DropdownMenu
        header={header}
        items={[
          {
            label: t('others_report_data_error'),
            onClick: () => {
              openIntercom();
              trackDataErrorReport();
            },
          },
        ]}
      >
        <IconButton
          aria-label={t('previous')}
          variant='grayGhost'
          height='34px'
          width='32px'
          icon={
            <MoreVerticalIcon
              stroke='grey.tertiaryText'
              width='16px'
              height='16px'
            />
          }
          size='xs'
        />
      </DropdownMenu>
    </Flex>
  );
}
