import { z } from 'zod';

export const BlockSection = z.enum([
  'ALL',
  'MISSION_CONTROL',
  'METRIC_DICTIONARY',
  'DEPARTMENT_TAB_AND_BREAKDOWNS',
  'CUSTOMER',
  'CUSTOMER_LISTS',
]);

export type BlockSection = z.infer<typeof BlockSection>;
