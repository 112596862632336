import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { range } from 'utils/helpers';

export function getDateOptions(
  granularity: AggregationPeriod,
  date: Date,
): Date[] {
  switch (granularity) {
    case 'DAY':
      return getDayOptions(date);
    case 'WEEK':
      return getWeekOptions(date);
    case 'MONTH':
      return getMonthOptions(date);
    default:
      throw new Error('Invalid granularity');
  }
}

export function getDayOptions(date: Date): Date[] {
  const dayOptions: Date[] = [];

  dayOptions.push(endOfDay(date));

  range(4, 1).forEach((index) => {
    const previousDay = endOfDay(subDays(date, index));
    dayOptions.push(previousDay);
  });

  return dayOptions;
}

export function getWeekOptions(date: Date): Date[] {
  const weeks = [] as Date[];
  weeks.push(date);
  range(4, 1).forEach((index) => {
    const previousWeek = endOfWeek(subWeeks(date, index));
    weeks.push(previousWeek);
  });
  return weeks;
}

export function getMonthOptions(date: Date): Date[] {
  const months = [] as Date[];
  months.push(date);
  range(4, 1).forEach((index) => {
    const previousMonth = endOfMonth(subMonths(date, index));
    months.push(previousMonth);
  });
  return months;
}
