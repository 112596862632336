import { FC } from 'react';

import { CommandPanelOption } from 'data/commandPanel/models';

import {
  ActionMenuListItem,
  BlockMenuListItem,
  CompanyMenuListItem,
  InsightMenuListItem,
  LeaderboardMenuListItem,
  UserMenuListItem,
} from '../ListItems';

type ListItemProps = {
  option: CommandPanelOption;
  isSelected: boolean;
  selectItem: () => void;
  index: number;
  onClose: () => void;
};

export const CommandBarListItem: FC<ListItemProps> = ({
  option,
  selectItem,
  isSelected,
  index,
  onClose,
}) => {
  switch (option.type) {
    case 'USER':
      return (
        <UserMenuListItem
          item={option}
          onSelect={selectItem}
          isSelected={isSelected}
          index={index}
          onClose={onClose}
        />
      );
    case 'COMPANY':
      return (
        <CompanyMenuListItem
          item={option}
          onSelect={selectItem}
          isSelected={isSelected}
          index={index}
          onClose={onClose}
        />
      );
    case 'ACTION':
      return (
        <ActionMenuListItem
          action={option.action}
          onSelect={selectItem}
          isSelected={isSelected}
          index={index}
          onClose={onClose}
        />
      );
    case 'METRIC':
      return (
        <BlockMenuListItem
          item={option}
          index={index}
          isSelected={isSelected}
          onSelect={selectItem}
          onClose={onClose}
        />
      );
    case 'INSIGHT':
      return (
        <InsightMenuListItem
          item={option}
          index={index}
          isSelected={isSelected}
          onSelect={selectItem}
          onClose={onClose}
        />
      );
    case 'LEADERBOARD':
      return (
        <LeaderboardMenuListItem
          item={option}
          index={index}
          isSelected={isSelected}
          onSelect={selectItem}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};
