import { z } from 'zod';

import { DisplayType } from 'data/blocks/models/DisplayType';

export const AggregationPeriod = z.enum([
  'DAY',
  'FIFTEEN_MINUTES',
  'FIVE_MINUTES',
  'HOUR',
  'MINUTE',
  'MONTH',
  'TEN_MINUTES',
  'WEEK',
  'YEAR',
  'SINCE_THE_BEGINNING_OF_TIME',
]);
export type AggregationPeriod = z.infer<typeof AggregationPeriod>;

export const AggregationFunction = z.enum([
  'SUM',
  'COUNT',
  'AVG',
  'MAX',
  'MIN',
]);
export type AggregationFunction = z.infer<typeof AggregationFunction>;

export const QueryType = z.enum([
  'TIME_SERIES',
  'USER_INTERACTION_SET',
  'FINANCIAL_TRANSACTION_SET',
]);
export type QueryType = z.infer<typeof QueryType>;

export const Sentiment = z.enum(['POSITIVE', 'NEGATIVE', 'NEUTRAL']);
export type Sentiment = z.infer<typeof Sentiment>;

export const RangeInsightDriver = z.object({
  startIndex: z.number().nullish(),
  endIndex: z.number().nullish(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  sentiment: Sentiment,
});

export const GroupingOptions = z.object({
  aggPeriod: AggregationPeriod.nullish(),
  aggFunc: AggregationFunction.nullish(),
  groupBy: z.string().nullish(),
});

export const InsightDriver = RangeInsightDriver;
export type InsightDriver = z.infer<typeof InsightDriver>;

// The following list is sorted alphabetically. Please keep it sorted
// for convenience. Thanks!
export const MetricName = z.enum([
  'active_sessions',
  'active_users',
  'active_window',
  'arr',
  'arr_approximation',
  'average_page_view_time',
  'average_sales_cycle',
  'average_session_duration',
  'average_time_in_stage',
  'avg_deal_speed',
  'begin_checkout',
  'burn',
  'closed',
  'closed_deals',
  'closing_soon_deals',
  'conversion',
  'conversion_rate',
  'conversions',
  'customers',
  'customers_approximation',
  'daily_pipeline',
  'daily_pipeline_count',
  'decreasing_usage_users',
  'engaged_session',
  'engaged_sessions',
  'engaged_users',
  'engagement_rate',
  'first_visit',
  'increasing_usage_users',
  'lead_conversion',
  'login',
  'logins',
  'lost_deal',
  'lost_deals',
  'marketing_burn',
  'marketing_sign_up',
  'mrr',
  'mrr_approximation',
  'mrr_change_customer_count',
  'mrr_churn',
  'mrr_contraction',
  'mrr_event',
  'mrr_expansion',
  'mrr_expansion_rate',
  'mrr_expansion_rate_3',
  'mrr_gross_retention_rate',
  'mrr_lost',
  'mrr_net_retention_rate',
  'mrr_new',
  'mrr_re-activation',
  'mrr_renewal_rate',
  'mrr_retention_rate_3',
  'mrr_won',
  'net_mrr_change',
  'net_retained_mrr',
  'new_deal',
  'new_deals',
  'new_visitors',
  'other_burn',
  'page_view',
  'page_views',
  'pipeline',
  'pipeline_time_in_stage',
  'power_users',
  'product_active_session',
  'product_engaged_session',
  'product_session_engagement_rate',
  'product_sign_up',
  'product_sign_ups',
  'projected',
  'projected_pipeline_value',
  'pulled_deal',
  'pulled_in_deals',
  'purchase',
  'purchases',
  'retained_users',
  'retained_window',
  'wau_mau',
  'retention_rate',
  'retention_rate_month_2',
  'retention_rate_week_2',
  'retention_rate_week_3',
  'sales_cycle',
  'sales_velocity',
  'sales_velocity_volume',
  'session_duration',
  'session_start',
  'sessions',
  'sign_up',
  'sign_ups',
  'signal_1',
  'signal_1_adoption',
  'signal_1_completions',
  'signal_1_per_user',
  'signal_1_users',
  'signal_2',
  'signal_2_adoption',
  'signal_2_completions',
  'signal_2_per_user',
  'signal_2_users',
  'signal_3',
  'signal_3_adoption',
  'signal_3_completions',
  'signal_3_per_user',
  'signal_3_users',
  'signal_4',
  'signal_4_adoption',
  'signal_4_completions',
  'signal_4_per_user',
  'signal_4_users',
  'signal_5',
  'signal_5_adoption',
  'signal_5_completions',
  'signal_5_per_user',
  'signal_5_users',
  'signal_user_count',
  'signup_rate',
  'slipped_deal',
  'slipped_deals',
  'software_and_subs_burn',
  'spend',
  'stalled_deals',
  'team_burn',
  'total_sales_cycle',
  'visits',
  'win_rate',
  'won_deal',
  'won_deals',
  'won_sales',
  'gmv',
  'new_subscriptions_gmv',
  'new_subscriptions_gmv_rate',
  'b2b_gmv',
  'b2c_gmv',
  'b2c_b2b_gmv_rate',
  'self_serve_gmv',
  'sales_led_gmv',
  'self_serve_gmv_rate',
  'self_serve_sales_led_rate',
  'new_sellers_gmv',
  'growing_sellers_gmv',
  'declining_sellers_gmv',
  'expanding_currency_sellers_gmv',
  'expanding_currency_sellers',
  'expanding_country_sellers_gmv',
  'expanding_country_sellers',
  'added_payment_method_sellers_gmv',
  'added_payment_method_sellers',
  'new_sellers_checkouts',
  'checkouts',
  'payments_started',
  'payments_succeeded',
  'payment_acceptance_rate',
  'payment_initiated_rate',
  'new_sellers_payments_started',
  'new_sellers_payments_succeeded',
  'new_sellers_payment_acceptance_rate',
  'm1_shifted_to_m3_gmv',
  'm2_shifted_to_m3_gmv',
  'm2_m1_gmv_rate',
  'm1_shifted_to_m3_retained',
  'm2_shifted_to_m3_retained',
  'm2_m1_retention_rate',
  'seller_revenue',
  'seller_new_subscriptions_revenue',
  'seller_one_time_revenue',
  'new_buyer_revenue',
  'usd_payments_started',
  'usd_payments_succeeded',
  'usd_payment_acceptance_rate',
  'card_payments_started',
  'card_payments_succeeded',
  'card_payment_acceptance_rate',
  'retainable_subscriptions',
  'retained_subscriptions',
  'subscription_renewal_rate',
]);
export type MetricName = z.infer<typeof MetricName>;

export const Metric = z.object({
  queryType: QueryType,
  metricNames: z.array(MetricName),
  dataSetNames: z.array(z.string()),
  groupingOptions: GroupingOptions,
  displayType: DisplayType,
  segmentName: z.string().nullish(),
  segmentType: z.string().nullish(),
  insightDrivers: z.array(InsightDriver).transform((value) => value || []),
});
export type Metric = z.infer<typeof Metric>;

export const Chart = z.object({
  metrics: z.array(Metric),
  start: z.string(),
  end: z.string().nullish(),
});

export const StaticChartSeries = z.object({
  labels: z.string().array(),
  values: z.string().nullable().array(),
  isPrimary: z.boolean().optional(),
  labelMetadata: z.object({
    segmentName: z.string().optional(),
    segmentValue: z.string().optional(),
  }),
  metricName: MetricName.optional(),
  insightDrivers: RangeInsightDriver.array(),
  granularity: AggregationPeriod.optional(),
  isReference: z.boolean().optional(),
});

export type StaticChartSeries = z.infer<typeof StaticChartSeries>;

export const NonEmptyStaticChartSeries = StaticChartSeries.array().nonempty();

export type NonEmptyStaticChartSeries = z.infer<
  typeof NonEmptyStaticChartSeries
>;

export const StaticChartData = z.object({
  series: NonEmptyStaticChartSeries,
  displayType: DisplayType,
});

export type StaticChartData = z.infer<typeof StaticChartData>;

export const ChartConfig = z.object({
  name: z.string(),
  title: z.string().optional(),
  chart: Chart.nullish(),
  data: StaticChartData.optional(),
});
export type ChartConfig = z.infer<typeof ChartConfig>;
