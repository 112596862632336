import { AnalyticsEventType, AnalyticsEventGroup } from 'utils/analytics';

export class CustomerDrawerAnalyticsEvents extends AnalyticsEventType {
  static COMPANY_OPENED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-company-opened',
  );
  static USER_OPENED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-user-opened',
  );
  static TABS_SWITCHED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-tabs-switched',
  );
  static TAGS_MANAGE = new CustomerDrawerAnalyticsEvents(
    'health-drawer-tags-manage',
  );
  static TAGS_CREATED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-tags-created',
  );
  static TAGS_UPDATED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-tags-updated',
  );
  static TAGS_REMOVED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-tags-removed',
  );
  static NOTES_ADDED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-notes-added',
  );
  static NOTES_DELETED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-notes-deleted',
  );
  static SENTIMENT_UPDATED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-sentiment-updated',
  );
  static BLOCK_TABS_SWITCHED = new CustomerDrawerAnalyticsEvents(
    'health-drawer-block-tabs-switched',
  );

  private constructor(readonly action: string) {
    super(action, AnalyticsEventGroup.CUSTOMER_DRAWER);
  }
}
