import { useCallback } from 'react';

import { Link } from '@chakra-ui/react';

import { useStyledToast } from 'components/Toast';
import { reloadPage } from 'utils/navigation';

export function useReloadToast(title: string, description: string) {
  const toast = useStyledToast();

  return useCallback(
    () =>
      toast({
        title,
        description: <Link onClick={reloadPage}>{description}</Link>,
        variant: 'ERROR',
        duration: 9000,
      }),
    [title, description, toast],
  );
}
