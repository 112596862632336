import {
  fetchRecordChart,
  RecordsChartConfig,
} from 'api/rest/charts/fetchRecordChart';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';
import { Parser } from 'utils/parser/parseResponse';

export type RecordBlockResult<T> = {
  data?: BlockResponse<T>['data'];
};

export type RecordBlockAtomConfig<T> = {
  parser: Parser<T>;
  config?: RecordsChartConfig;
};

export function getRecordChartAtoms<T>({
  parser,
  config = {},
}: RecordBlockAtomConfig<T>) {
  async function fetcher(blockId: string): Promise<RecordBlockResult<T>> {
    const { data } = await fetchRecordChart<T>(blockId, config, parser);

    return { data };
  }

  const dataAtom = getDataAtom<RecordBlockResult<T>>({
    data: undefined,
  });

  const fetchingAtom = getDataRefreshingAtom<RecordBlockResult<T>, string>(
    dataAtom,
    fetcher,
  );

  return {
    dataAtom,
    fetchingAtom,
  };
}
