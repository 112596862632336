import { useEffect, useState } from 'react';

import { Button, HStack, Text } from 'components/uikit';
import {
  PageRequest,
  PaginationPageSize,
} from 'data/charts/models/ChartsApiRequest';

export type PaginationProps = {
  page: number;
  pageSize: PaginationPageSize;
  total: number;
  onPaginationChange?: (pageRequest: PageRequest) => void;
};

export function PagesElement({
  page,
  pageSize,
  total,
  onPaginationChange,
}: PaginationProps) {
  const [segments, setSegments] = useState<number[][]>([]);

  useEffect(() => {
    const firstThree = [1, 2, 3];
    const lastThree = [total - 2, total - 1, total];
    if (total < 7) {
      setSegments([Array.from({ length: total }, (_, i) => i + 1)]);
    } else if (firstThree.includes(page) || lastThree.includes(page)) {
      setSegments([firstThree, lastThree]);
    } else {
      setSegments([[1], [page - 1, page, page + 1], [total]]);
    }
  }, [page, total, pageSize]);

  return (
    <HStack>
      {segments.map((segment, idx) => {
        return (
          <HStack key={idx}>
            <HStack>
              {segment.map((pageNumber, idx) => {
                const isActive = pageNumber === page;
                const onClick = () => {
                  onPaginationChange?.({ page: pageNumber, size: pageSize });
                };
                return (
                  <Button
                    key={idx}
                    height={7}
                    width={7}
                    minW={7}
                    px={0}
                    fontSize='14px'
                    borderRadius='2px'
                    color='secondary.button'
                    borderColor={isActive ? 'secondary.button' : 'transparent'}
                    borderWidth='1px'
                    bgColor={isActive ? 'grey.white' : 'transparent'}
                    onClick={onClick}
                    variant='ghost'
                  >
                    {pageNumber}
                  </Button>
                );
              })}
            </HStack>
            {idx != segments.length - 1 ? (
              <Text fontSize='14px'>...</Text>
            ) : null}
          </HStack>
        );
      })}
    </HStack>
  );
}
