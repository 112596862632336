import { endOfHour } from 'date-fns';
import { atom } from 'jotai';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { getFormattedDateRangeAtom } from 'utils/atoms/dateRangeAtom';

export const customerDrawerDateRangeAtom = atom({
  date: endOfHour(new Date()),
  granularity: AggregationPeriod.enum.WEEK,
});

export const customerDrawerMonthlyDateRangeAtom = atom({
  date: endOfHour(new Date()),
  granularity: AggregationPeriod.enum.MONTH,
});

export const customerDrawerFormattedDateRangeAtom = getFormattedDateRangeAtom(
  customerDrawerDateRangeAtom,
);

export const customerDrawerMonthlyFormattedDateRangeAtom =
  getFormattedDateRangeAtom(customerDrawerMonthlyDateRangeAtom);
