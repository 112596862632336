import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { FinanceRevenueBreakdown } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/FinanceRevenueBreakdown';
import { FinanceRevenueChartSection } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/FinanceRevenueChartSection';
import { useFinanceRevenueSection } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/useFinanceRevenueSection';
import { SelectToggle } from 'components/SelectToggle';
import { Card, Flex, HStack, Text } from 'components/uikit';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

export function FinanceRevenueCard({ customerId }: { customerId: string }) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.drill_down_tab.finance_revenue',
  });
  const { sections, section, setSection } = useFinanceRevenueSection();

  useEffect(() => {
    trackEvent(CustomerDrawerAnalyticsEvents.BLOCK_TABS_SWITCHED, {
      customerType: 'user',
      block: 'finance_revenue',
      tab: section,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, customerId, setSection]);

  return (
    <Card minHeight='375px' maxHeight='400px' overflow={'hidden'}>
      <HStack
        p='16px 24px'
        borderBottom='1px solid'
        borderBottomColor='grey.border'
        justifyContent='space-between'
      >
        <Text fontSize='18px' fontWeight='600' color='grey.primaryText'>
          {t('title')}
        </Text>
        <SelectToggle
          items={sections.map((section) => ({
            value: section,
            label: t(`switch.${section.toLowerCase()}`),
          }))}
          selectedItem={section}
          onChange={setSection}
          style={{
            p: '5x 8px',
            gap: '4px',
          }}
        />
      </HStack>
      <Flex>
        {section === 'MRR' ? (
          <FinanceRevenueChartSection customerId={customerId} />
        ) : (
          <FinanceRevenueBreakdown customerId={customerId} />
        )}
      </Flex>
    </Card>
  );
}
