import { useCallback } from 'react';

import { atom, useAtomValue, useSetAtom } from 'jotai';

import { ShareOptions } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import { ShareBlockConfig } from 'data/sharingLink/atoms/globalShareBlock';

interface SharingModalContext {
  activeSharingOptionId?: string;
  shareBlockConfig?: ShareBlockConfig;
  sharingUrl?: string;
  closeSharingModal?: () => void;
  publicSharingLink?: string;
  publicSharingLinkId?: string;
  sharingOptions?: ShareOptions;
}

export const sharingBlockContextAtom = atom<SharingModalContext>({
  activeSharingOptionId: undefined,
  shareBlockConfig: undefined,
  sharingUrl: undefined,
  closeSharingModal: undefined,
  publicSharingLink: undefined,
  publicSharingLinkId: undefined,
  sharingOptions: undefined,
});

export const useSharingBlockContext = () => {
  return useAtomValue(sharingBlockContextAtom);
};

export const useSetActiveSharingOptionId = () => {
  const sharingBlockContext = useSharingBlockContext();
  const setSharingBlockContext = useSetAtom(sharingBlockContextAtom);

  return useCallback(
    (newActiveSharingOptionId: string) => {
      setSharingBlockContext({
        ...sharingBlockContext,
        activeSharingOptionId: newActiveSharingOptionId,
      });
    },
    [setSharingBlockContext, sharingBlockContext],
  );
};
