import { z } from 'zod';

import { GrowthUniqueBlockName } from 'data/blocks/models/Block';
import {
  breakdownByCampaignByAvgTimePerSessionAtoms,
  breakdownByCampaignByEngagementRateAtoms,
  breakdownByCampaignByNewVisitorsAtoms,
  breakdownByCampaignBySessionsAtoms,
  breakdownByChannelByAvgTimePerSessionAtoms,
  breakdownByChannelByEngagementRateAtoms,
  breakdownByChannelByNewVisitorsAtoms,
  breakdownByChannelBySessionsAtoms,
  breakdownByCountryByAvgTimePerSessionAtoms,
  breakdownByCountryByEngagementRateAtoms,
  breakdownByCountryByNewVisitorsAtoms,
  breakdownByCountryBySessionsAtoms,
  breakdownByDeviceByAvgTimePerSessionAtoms,
  breakdownByDeviceByEngagementRateAtoms,
  breakdownByDeviceByNewVisitorsAtoms,
  breakdownByDeviceBySessionsAtoms,
  breakdownByOSByAvgTimePerSessionAtoms,
  breakdownByOSByEngagementRateAtoms,
  breakdownByOSByNewVisitorsAtoms,
  breakdownByOSBySessionsAtoms,
  breakdownByPageByAvgTimePerSessionAtoms,
  breakdownByPageByEngagementRateAtoms,
  breakdownByPageByNewVisitorsAtoms,
  breakdownByPageBySessionsAtoms,
  breakdownBySourceByAvgTimePerSessionAtoms,
  breakdownBySourceByEngagementRateAtoms,
  breakdownBySourceByNewVisitorsAtoms,
  breakdownBySourceBySessionsAtoms,
  FullBreakdownGrowthRow,
} from 'data/charts/atoms/fullBreakdowns/growth/BreakdownByDimension';
import {
  breakdownByPageViewsAtoms,
  FullBreakdownByPageRow,
} from 'data/charts/atoms/fullBreakdowns/growth/FullBreakdownByPage';
import { BreakdownConfig } from 'data/charts/configs/breakdowns/types';

export const GrowthBreakdownName = z.enum([
  'campaignByNewVisitors',
  'channelByNewVisitors',
  'countryByNewVisitors',
  'deviceByNewVisitors',
  'osByNewVisitors',
  'sourceByNewVisitors',
  'pageByNewVisitors',
  'campaignBySessions',
  'channelBySessions',
  'countryBySessions',
  'deviceBySessions',
  'osBySessions',
  'sourceBySessions',
  'pageBySessions',
  'campaignByEngagementRate',
  'channelByEngagementRate',
  'countryByEngagementRate',
  'deviceByEngagementRate',
  'osByEngagementRate',
  'sourceByEngagementRate',
  'pageByEngagementRate',
  'campaignByAvgTimePerSession',
  'channelByAvgTimePerSession',
  'countryByAvgTimePerSession',
  'deviceByAvgTimePerSession',
  'osByAvgTimePerSession',
  'sourceByAvgTimePerSession',
  'pageByAvgTimePerSession',
  'byPageViews',
]);
export type GrowthBreakdownName = z.infer<typeof GrowthBreakdownName>;
export type GrowthSplitBreakdownName = Exclude<
  GrowthBreakdownName,
  'byPageViews'
>;

export const GROWTH_BREAKDOWNS_CONFIG: Record<
  GrowthBreakdownName,
  BreakdownConfig<GrowthUniqueBlockName>
> = {
  campaignByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCampaignByNewVisitorsAtoms,
  },
  campaignBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCampaignBySessionsAtoms,
  },
  campaignByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCampaignByEngagementRateAtoms,
  },
  campaignByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCampaignByAvgTimePerSessionAtoms,
  },

  channelByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByChannelByNewVisitorsAtoms,
  },
  channelBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByChannelBySessionsAtoms,
  },
  channelByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByChannelByEngagementRateAtoms,
  },
  channelByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByChannelByAvgTimePerSessionAtoms,
  },

  countryByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCountryByNewVisitorsAtoms,
  },
  countryBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCountryBySessionsAtoms,
  },
  countryByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCountryByEngagementRateAtoms,
  },
  countryByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByCountryByAvgTimePerSessionAtoms,
  },

  deviceByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByDeviceByNewVisitorsAtoms,
  },
  deviceBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByDeviceBySessionsAtoms,
  },
  deviceByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByDeviceByEngagementRateAtoms,
  },
  deviceByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByDeviceByAvgTimePerSessionAtoms,
  },

  osByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByOSByNewVisitorsAtoms,
  },
  osBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByOSBySessionsAtoms,
  },
  osByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByOSByEngagementRateAtoms,
  },
  osByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByOSByAvgTimePerSessionAtoms,
  },

  pageByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByPageByNewVisitorsAtoms,
  },
  pageBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByPageBySessionsAtoms,
  },
  pageByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByPageByEngagementRateAtoms,
  },
  pageByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownByPageByAvgTimePerSessionAtoms,
  },

  sourceByNewVisitors: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_NEW_VISITORS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownBySourceByNewVisitorsAtoms,
  },
  sourceBySessions: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_SESSIONS',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownBySourceBySessionsAtoms,
  },
  sourceByEngagementRate: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_ENGAGEMENT_RATE',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownBySourceByEngagementRateAtoms,
  },
  sourceByAvgTimePerSession: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_AVG_TIME_PER_SESSION',
    columnParser: FullBreakdownGrowthRow.keyof(),
    atoms: breakdownBySourceByAvgTimePerSessionAtoms,
  },

  byPageViews: {
    blockName: 'FULL_BREAKDOWNS_GROWTH_BY_PAGE_VIEWS',
    columnParser: FullBreakdownByPageRow.keyof(),
    atoms: breakdownByPageViewsAtoms,
  },
};
