import { ComputedDatum } from '@nivo/pie/dist/types/types';

interface CenteredMetricProps<RawDatum> {
  dataWithArc: ComputedDatum<RawDatum>[];
  centerX: number;
  centerY: number;
}

export function CenteredMetric<RawDatum>({
  dataWithArc,
  centerX,
  centerY,
}: CenteredMetricProps<RawDatum>) {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <>
      <text
        x={centerX}
        y={centerY - 12}
        textAnchor='middle'
        dominantBaseline='central'
        fill='var(--chakra-colors-grey-primaryText)'
        fontSize='24px'
        fontWeight='bold'
      >
        {total}
      </text>
      <text
        x={centerX}
        y={centerY + 12}
        textAnchor='middle'
        dominantBaseline='central'
        fill='var(--chakra-colors-grey-tertiaryText)'
        fontSize='12px'
        fontWeight='medium'
      >
        Total Users
      </text>
    </>
  );
}
