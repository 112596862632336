import { z } from 'zod';

import {
  CustomerBlockResponse,
  fetchCustomersChart,
} from 'api/rest/customer/fetchCustomersChart';
import { CustomerBlock } from 'data/blocks/models/Block';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomerDataBlock = z.ZodObject<any>;

export interface CustomersChartResult<T extends CustomerDataBlock> {
  data?: CustomerBlockResponse<T>['data'];
}

interface CustomersChartAtomConfig<T extends CustomerDataBlock> {
  blockResponseParser: T;
  filters: Filter[];
}

export interface FetchCustomersChartProps {
  block: CustomerBlock;
}

export const RevenueStatsData = z.object({
  product: z.string(),
  mrr: z.number(),
  renewalDate: z.coerce.date().nullable(),
  ltv: z.number(),
});

export type RevenueStatsData = z.infer<typeof RevenueStatsData>;

export const CompanyData = z.object({
  name: z.string().nullable(),
});

export type CompanyData = z.infer<typeof CompanyData>;

export const UserData = z.object({
  name: z.string().nullable(),
  title: z.string().nullable(),
  location: z.string().nullable(),
  joinDate: z.coerce.date().nullable(),
});

export type UserData = z.infer<typeof UserData>;

export function getCustomersChartAtoms<T extends CustomerDataBlock>({
  blockResponseParser,
  filters,
}: CustomersChartAtomConfig<T>) {
  async function fetcher({
    block,
  }: FetchCustomersChartProps): Promise<CustomersChartResult<T>> {
    const { data } = await fetchCustomersChart(
      block.id,
      { filters },
      blockResponseParser,
    );

    return { data };
  }

  const dataAtom = getDataAtom<CustomersChartResult<T>>({
    data: undefined,
  });

  const fetchingAtom = getDataRefreshingAtom<
    CustomersChartResult<T>,
    FetchCustomersChartProps
  >(dataAtom, fetcher);

  return {
    dataAtom,
    fetchingAtom,
  };
}
