import { useIsMobile } from 'utils/hooks/screenBreakpoints';
import { useIsEmbed } from 'utils/hooks/useIsEmbed';

export function useEffectiveChartHeight(height: number, hasLegend: boolean) {
  const isMobile = useIsMobile();
  const isEmbed = useIsEmbed();

  if (isMobile) {
    return 200;
  }

  if (isEmbed) {
    return 320;
  }

  const legendHeight = hasLegend ? 15.5 : 0;

  return height - legendHeight;
}
