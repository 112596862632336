import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByUsersEngagementRow = z.object({
  userId: z.string(),
  customerId: z.string(),
  userName: z.string(),
  company: z.string(),
  accountType: z.string().nullable(),
  accountState: z.string().nullable(),
  companyName: z.string().nullable(),
  lastEngaged: z.string(),
  daysEngagedL30: z.number(),
  signal1: z.number(),
  signal2: z.number(),
  signal3: z.number(),
  signal4: z.number(),
  signal5: z.number(),
  joinMonth: z.string(),
  country: z.string(),
});

export type FullBreakdownByUsersEngagementRow = z.infer<
  typeof FullBreakdownByUsersEngagementRow
>;

export const breakdownByUsersEngagementAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersEngagementRow,
    [{ column: 'daysEngagedL30', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );
