import { ReactNode } from 'react';

import { Portal } from '@chakra-ui/portal';

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from 'components/uikit';

export function SidebarMenuTooltip({
  trigger,
  body,
  offset = [0, 16],
}: {
  trigger: ReactNode;
  body: ReactNode;
  offset?: [number, number];
}) {
  return (
    <Popover
      placement={'right-end'}
      trigger='hover'
      offset={offset}
      arrowShadowColor={'none'}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent
          margin='none'
          display={{
            sm: 'none',
            lg: 'block',
          }}
          border='none'
          width='auto'
          outline='none'
          backgroundColor={'grey.primaryText.dark'}
          zIndex='higher'
        >
          <PopoverArrow backgroundColor={'grey.primaryText.dark'} />
          <PopoverBody
            padding='0'
            color='grey.border.dark'
            fontWeight='medium'
            outline='none'
          >
            {body}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
