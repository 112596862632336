import { BlockType, Department } from 'data/blocks/models/Block';
import { BlockSection } from 'data/blocks/models/BlockSection';
import { BLOCKS_API_URL } from 'data/constants';

export const getBlocksByDepartmentUrl = (
  company: string,
  department: Department,
) =>
  `${BLOCKS_API_URL}/blocks-new/company/${company}/blocks/department/${department}`;

export const getBlocksByTypeUrl = (company: string, blockTypes: BlockType[]) =>
  `${BLOCKS_API_URL}/blocks-new/company/${company}/blocks/types/${blockTypes.join(
    ',',
  )}`;

export const getBlocksBySectionUrl = (company: string, section: BlockSection) =>
  `${BLOCKS_API_URL}/blocks-new/company/${company}/blocks/sections/${section}`;

export const getBlocksByIdUrl = (blockId: string) =>
  `${BLOCKS_API_URL}/blocks-new/blocks/${blockId}`;
