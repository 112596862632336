import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface UpdateCustomerCompanySentimentParams {
  customerCompanyId: string;
  sentiment: string | null;
}

export async function updateCustomerCompanySentiment({
  customerCompanyId,
  sentiment,
}: UpdateCustomerCompanySentimentParams): Promise<CustomerCompanyResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/${customerCompanyId}/sentiment/${sentiment}`,
    { method: 'PUT' },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to update customer company tags: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompanyResponse, data);
}
