import { createIcon } from '@chakra-ui/react';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 14 16',
  path: (
    <>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        fill='transparent'
        d='M3.667 6.667v-2a3.333 3.333 0 0 1 6.666 0v2m-8 0h9.334C12.403 6.667 13 7.264 13 8v5.333c0 .737-.597 1.334-1.333 1.334H2.333A1.333 1.333 0 0 1 1 13.333V8c0-.736.597-1.333 1.333-1.333Z'
      />
    </>
  ),
});
