import { z } from 'zod';

import { Profile } from 'api/graphql/backendAPI/generatedTypes';
import { TourStep } from 'data/user/models/onboarding';
import { State } from 'utils/atoms/dataFetchingAtom';

export const ProductTourConfig = z.object({
  viewedRadar: z.boolean().default(false),
  ratedInsight: z.boolean().default(false),
  createdAlerts: z.boolean().default(false),
  viewedAlerts: z.boolean().default(false),
  viewedMissionControl: z.boolean().default(false),
  productTourCompleted: z.boolean().default(false),
  viewedBreakdowns: z.boolean().default(false),
  viewedMetricDictionary: z.boolean().default(false),
  viewedHighlightNotice: z.boolean().default(false),
  viewedInsightNotice: z.boolean().default(false),
  viewedDigestNotice: z.boolean().default(false),
  hidInviteTeamSidebarCard: z.boolean().default(false),
  hidGetBetterInsightsSidebarCard: z.boolean().default(false),
});

export type ProductTourConfig = z.infer<typeof ProductTourConfig>;

export const User = z.object({
  avatar: z.string().nullish(),
  email: z.string(),
  emailVerified: z.boolean(),
  firstName: z.string(),
  hasOnboarded: z.boolean(),
  id: z.string(),
  lastName: z.string(),
  tourStep: TourStep,
});
export type User = z.infer<typeof User>;

export const CompanyUser = User.extend({
  roleInCompany: z.string().nullable(),
});

export type CompanyUser = z.infer<typeof CompanyUser>;

export type UserProfilesPayload = {
  user: User | null;
  profiles: Array<Profile> | null;
  currentProfile: Profile | null;
  maintenance: boolean;
};

export type UserProfilesState = State<UserProfilesPayload>;
