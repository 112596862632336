import { SignalsByEngagementRow } from 'data/charts/atoms/product/users/breakdowns';
import { useProductSettings } from 'data/settings/hooks/product/useProductSettings';

export function useFormatEngagementBreakdownBySignals(
  firstRow: SignalsByEngagementRow | undefined,
) {
  const { productSignals } = useProductSettings();
  return [
    {
      signal: productSignals.signal1 ?? 'signal_1',
      allTime: firstRow?.signal1Total ?? 0,
      lastWeek: firstRow?.signal1 ?? 0,
      prevWeekDelta: firstRow?.signal1Delta ?? 0,
      show: productSignals.signal1 != null,
    },
    {
      signal: productSignals.signal2 ?? 'signal_2',
      allTime: firstRow?.signal2Total ?? 0,
      lastWeek: firstRow?.signal2 ?? 0,
      prevWeekDelta: firstRow?.signal2Delta ?? 0,
      show: productSignals.signal2 != null,
    },
    {
      signal: productSignals.signal3 ?? 'signal_3',
      allTime: firstRow?.signal3Total ?? 0,
      lastWeek: firstRow?.signal3 ?? 0,
      prevWeekDelta: firstRow?.signal3Delta ?? 0,
      show: productSignals.signal3 != null,
    },
    {
      signal: productSignals.signal4 ?? 'signal_4',
      allTime: firstRow?.signal4Total ?? 0,
      lastWeek: firstRow?.signal4 ?? 0,
      prevWeekDelta: firstRow?.signal4Delta ?? 0,
      show: productSignals.signal4 != null,
    },
    {
      signal: productSignals.signal5 ?? 'signal_5',
      allTime: firstRow?.signal5Total ?? 0,
      lastWeek: firstRow?.signal5 ?? 0,
      prevWeekDelta: firstRow?.signal5Delta ?? 0,
      show: productSignals.signal5 != null,
    },
  ].filter(({ show }) => show);
}
