import { MouseEvent } from 'react';

import { IconProps } from '@chakra-ui/icons';
import { ButtonProps, createIcon } from '@chakra-ui/react';

import { Button, CloseIcon, Text } from 'components/uikit';

interface Props {
  content: string;
  hasValue: boolean;
  canBeReset: boolean;
  onClick: (() => void) | undefined;
  onReset?: () => void;
  ControlIcon: ReturnType<typeof createIcon>;
  isDisabled?: boolean;
  iconSize?: IconProps['boxSize'];
  fontSize?: ButtonProps['fontSize'];
}

export function ControlButton({
  content,
  hasValue,
  canBeReset,
  onClick,
  onReset,
  ControlIcon,
  isDisabled,
  iconSize = '16px',
  fontSize = 'sm',
}: Props) {
  const contentColor = hasValue ? 'secondary.text' : 'grey.tertiaryText';
  const backgroundColor = hasValue ? 'secondary.background' : 'none';

  function onResetClick(e: MouseEvent) {
    e.stopPropagation();
    onReset?.();
  }

  return (
    <Button
      isDisabled={isDisabled}
      leftIcon={<ControlIcon stroke={contentColor} boxSize={iconSize} />}
      rightIcon={
        canBeReset ? (
          <CloseIcon
            onClick={onResetClick}
            stroke={contentColor}
            strokeWidth='2px'
            boxSize='16px'
            borderRadius='2px'
            cursor='pointer'
            _hover={{
              backgroundColor: 'secondary.button',
              stroke: 'grey.white',
            }}
          />
        ) : undefined
      }
      variant='ghost'
      onClick={onClick}
      bgColor={backgroundColor}
      boxShadow='none'
      fontSize={fontSize}
      lineHeight='140%'
      fontWeight='medium'
      color='grey.tertiaryText'
      size='xs'
      alignItems='center'
      px='8px'
      py='16px'
      _hover={{
        backgroundColor: 'grey.offWhite',
      }}
      cursor={onClick ? 'pointer' : 'default'}
    >
      <Text color={contentColor}>{content}</Text>
    </Button>
  );
}
