import { useRef } from 'react';

import { useUserDrawerState } from 'components/CustomerDrawer/hooks/useUserDrawerState';
import { CustomerUserDrawer } from 'components/CustomerDrawer/UserDrawer';
import { useSwitchIntercomVisibility } from 'utils/intercom/useIntercom';

export function StandaloneCustomerUserDrawer() {
  const btnRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, user } = useUserDrawerState();
  useSwitchIntercomVisibility(isOpen);

  if (!user) {
    return null;
  }

  return (
    <CustomerUserDrawer
      customerUserId={user.id}
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={btnRef}
    />
  );
}
