import { z } from 'zod';

import { StaticChartData } from 'data/blocks/models/ChartConfig';
import { GoalMetric } from 'data/goals/models/GoalMetric';
import { GoalProgressStatus } from 'data/goals/models/GoalProgressStatus';
import { CurrencyParser } from 'utils/formatting/number';

export const BlockDataGoal = z.object({
  metric: GoalMetric,
  startDate: z.coerce.date(),
  currentDate: z.coerce.date(),
  endDate: z.coerce.date(),
  initialValue: z.number(),
  targetValue: z.number(),
  currentValue: z.number(),
  status: GoalProgressStatus,
  chart: StaticChartData,
  progressPct: z.number(),
  progressValue: z.number(),
  currency: CurrencyParser.optional(),
});

export type BlockDataGoal = z.infer<typeof BlockDataGoal>;

export const BlockData = z.object({
  goal: BlockDataGoal.optional(),
});

export type BlockData = z.infer<typeof BlockData>;
