import { createIcon } from '@chakra-ui/react';

export const DashboardIcon = createIcon({
  displayName: 'DashboardIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <path
        d='M13.6667 27.25H5C4.86193 27.25 4.75 27.1381 4.75 27V5C4.75 4.86193 4.86193 4.75 5 4.75H13.6667C13.8047 4.75 13.9167 4.86193 13.9167 5V27C13.9167 27.1381 13.8047 27.25 13.6667 27.25ZM27 27.25H18.3333C18.1953 27.25 18.0833 27.1381 18.0833 27V15.6667C18.0833 15.5286 18.1953 15.4167 18.3333 15.4167H27C27.1381 15.4167 27.25 15.5286 27.25 15.6667V27C27.25 27.1381 27.1381 27.25 27 27.25ZM27.25 5V11C27.25 11.1381 27.1381 11.25 27 11.25H18.3333C18.1953 11.25 18.0833 11.1381 18.0833 11V5C18.0833 4.86193 18.1953 4.75 18.3333 4.75H27C27.1381 4.75 27.25 4.86193 27.25 5Z'
        fill='none'
        stroke='CurrentColor'
        strokeWidth='1.5'
      />
    </>
  ),
});
