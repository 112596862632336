import { useEffect } from 'react';

import { BoxProps } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';

import { ErrorIndicator } from 'components/ErrorIndicator';
import { Box } from 'components/uikit';
import { updateRenderedErrorsCountAtom } from 'data/loadErrors/atoms/renderedErrors';
import { useUserEvent } from 'utils/analytics';
import { getEventByLabel, TrackingLabel } from 'utils/analytics/trackingLabels';

const POSITIONING = {
  statCard: {
    top: '8px',
    right: '8px',
  },
  summaryCard: {
    top: '8px',
    right: '8px',
  },
  mobileBreakdown: {
    top: '8px',
    right: '8px',
  },
  chart: {
    top: '12px',
    right: '32px',
  },
  table: {
    top: '12px',
    right: '16px',
  },
  dictionaryChart: {
    top: '17px',
    right: '24px',
  },
  fullBreakdownTable: {
    top: '8px',
    right: '16px',
  },
  right: {
    right: '0px',
  },
  hide: undefined,
} as const;

export interface LoadableComponentProps extends BoxProps {
  hasError: boolean;
  trackingLabel?: TrackingLabel;
  positioning: keyof typeof POSITIONING;
}

export function LoadableComponent({
  hasError,
  trackingLabel,
  positioning,
  children,
  ...otherProps
}: LoadableComponentProps) {
  const hideErrorIndicator = positioning === 'hide';
  const updateCount = useSetAtom(updateRenderedErrorsCountAtom);
  const trackEvent = useUserEvent();

  useEffect(() => {
    // Here we need to increment the count of rendered errors and decrement it accordingly or on unmount
    // This count is then used to display the general LoadingErrorBanner
    if (hasError) {
      if (trackingLabel) {
        trackEvent(getEventByLabel(trackingLabel), {
          trackingLabel,
        });
      }
      updateCount('increment');
      return () => updateCount('decrement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  const showErrorIndicator = hasError && !hideErrorIndicator;

  return (
    <Box {...otherProps} position='relative'>
      {showErrorIndicator ? (
        <Box position='absolute' zIndex={1} {...POSITIONING[positioning]}>
          <ErrorIndicator />
        </Box>
      ) : null}
      {children}
    </Box>
  );
}
