import { ReactNode } from 'react';

import { BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TableDefaultControls } from 'components/charts/FullBreakdownTable/TableDefaultControls';
import { DemoDataTag } from 'components/DemoDataTag';
import { ControlButton } from 'components/MetricCards/MetricCharts/ControlButton';
import { SHARING_OPTION_SHARE_WITH_TEAM } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import {
  AlertTriangleIcon,
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
} from 'components/uikit';
import { FilterIcon } from 'components/uikit/icons/Filter';
import { SortIcon } from 'components/uikit/icons/SortIcon';
import { Tag } from 'components/uikit/reexport';
import {
  Direction,
  Filter,
  FilterOperator,
  SortArray,
} from 'data/charts/models/ChartsApiRequest';
import { ColumnConfig, TableConfig } from 'data/charts/models/TableConfig';
import { AnalyticsEventType, useUserEvent } from 'utils/analytics';
import { formatDate } from 'utils/datetime';

type Props<T> = {
  headingPadding?: BoxProps['padding'];
  title?: string;
  isDemo: boolean;
  subTitle?: string;
  onResetTable?: () => void;
  controls?: ReactNode;
  blockId?: string;
  hasError: boolean;
  config?: TableConfig<T>;
  sorts?: SortArray;
  filters?: Filter[];
  resetFilters?: Record<keyof T, () => void>;
};

export function DefaultFullBreakdownHeaderControls({
  blockId,
  title,
  subTitle,
  isDemo,
}: {
  blockId: string | undefined;
  title: string | undefined;
  subTitle?: string;
  isDemo: boolean;
}) {
  const { t } = useTranslation('mission-control');
  const trackEvent = useUserEvent();

  return (
    <TableDefaultControls
      blockId={blockId}
      trackDataErrorReport={() =>
        trackEvent(AnalyticsEventType.BREAKDOWNS_REPORT_DATA_ERROR, {
          breakdown: title,
        })
      }
      sharingOptions={[SHARING_OPTION_SHARE_WITH_TEAM]}
    >
      <HStack>
        <Text color='grey.primaryText' fontWeight='semibold' fontSize='sm'>
          {title}
        </Text>
        {subTitle ? (
          <Text fontSize='sm' fontWeight='semibold' color='grey.tertiaryText'>
            {subTitle}
          </Text>
        ) : null}
        <Spacer />
        {isDemo ? (
          <Tag
            variant='square'
            bgColor='warning.text'
            color='warning.background'
            borderRadius='4px'
            py='4px'
            gap='4px'
            px='12px'
          >
            <AlertTriangleIcon
              height='20px'
              width='20px'
              stroke='warning.background'
            />
            {t('demo_data_tag')}
          </Tag>
        ) : null}
      </HStack>
    </TableDefaultControls>
  );
}

function getColumn<T>(column: keyof T, config: TableConfig<T>) {
  const c = config.find((c) => c.accessor === column);

  if (!c) {
    throw new Error(`Invalid column ${column.toString()} in table config`);
  }

  return c;
}

function getColumnHeader<T>(column: keyof T, config: TableConfig<T>) {
  return getColumn(column, config)?.header;
}

function getSortsCopy<T>(sorts: SortArray, config: TableConfig<T>) {
  function getSortIndicator(direction: Direction) {
    return direction === 'ASC' ? '↑' : '↓';
  }

  return sorts
    .map(
      ({ column, direction }) =>
        `${getColumnHeader(column as keyof T, config)} ${getSortIndicator(
          direction,
        )}`,
    )
    .join(', ');
}

function filterFieldToColumn<T>(
  field: string,
  config: TableConfig<T>,
): ColumnConfig<T, keyof T> {
  return getColumn(field as keyof T, config);
}

function formatArrayFilterCopy(value: string[]) {
  return value.length <= 1 ? value : `${value.length} values`;
}

function getFiltersCopy<T>(
  field: string,
  operator: FilterOperator,
  value: string | number | string[] | Date,
  config: TableConfig<T>,
) {
  const formattedField = filterFieldToColumn(field, config)?.header;

  const formattedValue = (value as Date).getTime
    ? formatDate(value as Date, 'DAY', true, false, true)
    : Array.isArray(value)
    ? field === 'tags' && value.length === 1
      ? `1 value`
      : formatArrayFilterCopy(value)
    : value;

  const formattedOperator =
    operator === '<='
      ? ' ≤ '
      : operator === '>='
      ? ' ≥ '
      : operator === 'in' || operator === 'hasAny'
      ? ': '
      : ` ${operator} `;

  return `${formattedField}${formattedOperator}${formattedValue}`;
}

export function FullBreakdownHeader<T>({
  headingPadding,
  title,
  subTitle,
  onResetTable,
  controls,
  blockId,
  hasError,
  isDemo = false,
  sorts,
  config,
  filters,
  resetFilters,
}: Props<T>) {
  const { t } = useTranslation('breakdowns');
  const defaultTableControls = !controls && !hasError && (
    <DefaultFullBreakdownHeaderControls
      blockId={blockId}
      title={title}
      isDemo={isDemo}
    />
  );

  const configuredFilters =
    resetFilters && filters && config
      ? filters.filter(
          ({ field }) =>
            filterFieldToColumn(field, config).accessor in resetFilters,
        )
      : [];

  return (
    <Box borderTopRadius='inherit'>
      <Flex
        alignItems='center'
        gap='8px'
        minHeight='34px'
        textAlign='left'
        textTransform='none'
        backgroundColor='grey.white'
        padding={headingPadding ?? '16px 24px'}
        borderTopRadius='inherit'
      >
        <Flex direction='column' gap='4px'>
          <Text fontSize='md' fontWeight='semibold' color='grey.primaryText'>
            {title}
          </Text>
          {subTitle ? (
            <Text fontSize='sm' fontWeight='semibold' color='grey.tertiaryText'>
              {subTitle}
            </Text>
          ) : null}
        </Flex>
        <Spacer />
        {isDemo ? <DemoDataTag /> : null}
        {controls ?? defaultTableControls}
      </Flex>
      {config?.length &&
      ((onResetTable && sorts?.length) ||
        (resetFilters && configuredFilters.length)) ? (
        <Flex
          borderTop='solid 1px'
          borderColor='grey.border'
          px='24px'
          py='8px'
          flexWrap='wrap'
          gap='8px'
        >
          {onResetTable && sorts?.length ? (
            <ControlButton
              content={`${t('sort_by')} ${getSortsCopy(sorts, config)}`}
              hasValue={true}
              canBeReset={true}
              onClick={undefined}
              onReset={onResetTable}
              ControlIcon={SortIcon}
            />
          ) : null}
          {resetFilters && configuredFilters.length
            ? configuredFilters.map(({ value, field, operator }) => {
                const column = filterFieldToColumn(field, config);

                return (
                  <ControlButton
                    key={field}
                    content={getFiltersCopy(field, operator, value, config)}
                    hasValue={column.accessor in resetFilters}
                    canBeReset={column.accessor in resetFilters}
                    onClick={undefined}
                    onReset={resetFilters[column.accessor]}
                    ControlIcon={FilterIcon}
                  />
                );
              })
            : null}
        </Flex>
      ) : null}
    </Box>
  );
}
