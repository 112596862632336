import { useRef } from 'react';

import { ButtonProps } from '@chakra-ui/react';

import { Action } from 'utils/hotkeys/types';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

import { Button } from '../reexport';

interface SubmitButtonProps extends ButtonProps {
  keyAction?: Action;
}

export function SubmitButton({
  keyAction = 'CONFIRM',
  ...props
}: SubmitButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);

  useKeyAction(keyAction, () => {
    ref.current?.click();
  });

  return <Button type='submit' {...props} ref={ref} />;
}
