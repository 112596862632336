import { ReactNode } from 'react';

import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

import { KeyBinding } from 'components/SelectionModal/SelectionModalOptionRow';
import { Radio } from 'components/uikit/form/Radio';
import { Key } from 'components/uikit/Key';
import { lastChar } from 'utils/helpers';
import { Action } from 'utils/hotkeys/types';

export interface MenuListItemBaseProps {
  key: string;
  image?: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  selected?: boolean;
  onSelect?: () => void;
  subTitleDirection?: 'row' | 'column';
  error?: boolean;
  disabled?: boolean;
  radio?: boolean;
  action?: Action;
  hotkeysActive?: boolean;
}

interface MenuListItemProps
  extends Omit<FlexProps, 'title' | 'key' | 'onSelect'>,
    MenuListItemBaseProps {}

export function MenuListItem({
  image,
  title,
  subTitle,
  selected = false,
  error = false,
  disabled = false,
  subTitleDirection = 'column',
  onSelect,
  radio,
  action,
  hotkeysActive = false,
  ...props
}: MenuListItemProps) {
  return (
    <Flex
      py='16px'
      pr='24px'
      pl={selected ? '20px' : '24px'}
      gap='8px'
      justifyContent='flex-start'
      alignItems='center'
      borderColor={'secondary.button'}
      borderLeftWidth={selected ? '4px' : '0px'}
      backgroundColor={
        disabled ? 'grey.offWhite' : !selected ? 'none' : 'grey.offWhite'
      }
      _hover={
        !selected && !disabled && !error
          ? {
              backgroundColor: 'grey.offWhite',
            }
          : undefined
      }
      cursor={disabled ? undefined : 'pointer'}
      transitionDuration='normal'
      transitionProperty='common'
      onClick={disabled ? undefined : onSelect}
      {...props}
    >
      {image ? (
        <Box
          display='flex'
          h='40px'
          w='40px'
          borderRadius='40px'
          alignItems='center'
          justifyContent='center'
        >
          {image}
        </Box>
      ) : null}
      {radio ? (
        <Radio
          isChecked={selected}
          label={null}
          description={null}
          isDisabled={disabled}
        />
      ) : null}
      <Flex flexDirection={subTitleDirection}>
        <Text
          fontSize='sm'
          fontWeight='medium'
          lineHeight='20px'
          display='inline-flex'
          alignItems='center'
        >
          {title}
        </Text>
        <Text fontSize='sm' color='grey.tertiaryText' lineHeight='20px'>
          {subTitle}
        </Text>
      </Flex>

      {action && hotkeysActive && onSelect ? (
        <>
          <KeyBinding
            onSelect={onSelect}
            action={action}
            enabled={hotkeysActive}
          />
          <Box flex={1} />
          <Key isOutlined size='24px' element={lastChar(action) as string} />
        </>
      ) : null}
    </Flex>
  );
}
