import { atom, PrimitiveAtom } from 'jotai';

import { FetchStatus } from 'utils/requests/types';

export type State<Payload> = Payload & {
  fetchStatus: FetchStatus;
  errorMessage: string;
};

export type DataAtom<T> = PrimitiveAtom<State<T>>;

/**
 * Payload: e.g. { idToken: string | null }
 * @param initialPayload - e.g. { idToken: null }
 * @param initialFetchStatus
 * @returns an atom containing the payload with fetchStatus and errorMessage attributes.
 */

export function getDataAtom<Payload>(
  initialPayload: Payload,
  initialFetchStatus: FetchStatus = 'INITIAL',
): DataAtom<Payload> {
  return atom<State<Payload>>({
    ...initialPayload,
    fetchStatus: initialFetchStatus,
    errorMessage: '',
  });
}
