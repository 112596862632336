import { atom } from 'jotai';

export const renderedErrorsCountAtom = atom(0);

export const updateRenderedErrorsCountAtom = atom(
  null,
  (get, set, params: 'increment' | 'decrement') => {
    const currentCount = get(renderedErrorsCountAtom);
    if (params === 'increment') {
      set(renderedErrorsCountAtom, currentCount + 1);
    } else {
      set(renderedErrorsCountAtom, currentCount - 1);
    }
  },
);
