import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

import { getIdToken, refreshAndGetNewIdToken } from '../amplify';

type IdTokenPayload = {
  idToken: string | null;
  expiry: number | null;
};

const idTokenAtom = getDataAtom<IdTokenPayload>(
  {
    idToken: null,
    expiry: null,
  },
  'LOADING', // initialise as loading state - as when we first login page will assume we're logged out and redirect otherwise
);

const fetchIdTokenAtom = getDataFetchingAtom<IdTokenPayload, undefined>(
  idTokenAtom,
  getIdToken,
);

const refreshIdTokenAtom = getDataFetchingAtom<IdTokenPayload, undefined>(
  idTokenAtom,
  refreshAndGetNewIdToken,
);

export { idTokenAtom, fetchIdTokenAtom, refreshIdTokenAtom };
