import { Getter, Setter } from 'jotai';

import {
  GetOrCreateUserProfilesMutation,
  GetOrCreateUserProfilesDocument,
  GetOrCreateUserProfilesMutationVariables,
} from 'api/graphql/backendAPI/queries/user/getOrCreateUserProfiles/getOrCreateUserProfiles.generated';
import { getClient } from 'api/graphql/client';
import { refreshIdTokenAtom } from 'data/auth/atoms/idTokenAtom';
import { AnalyticsEventType } from 'utils/analytics';
import { trackUserEvent } from 'utils/analytics/trackUserEvent';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

import { UserProfilesPayload } from '../models';

async function fetchUserProfiles(
  variables: GetOrCreateUserProfilesMutationVariables = {},
  _get: Getter,
  set: Setter,
): Promise<UserProfilesPayload> {
  const clientQuery = getClient().mutation<
    GetOrCreateUserProfilesMutation,
    GetOrCreateUserProfilesMutationVariables
  >(GetOrCreateUserProfilesDocument, variables);

  try {
    const response = await clientQuery.toPromise();
    if (response.error) {
      throw new Error(JSON.stringify(response.error));
    }
    if (!response.data) {
      throw new Error('could not fetch userProfiles');
    }

    // TODO: update current profile logic
    const user = response.data.getOrCreateUserProfiles.user ?? null;
    const profiles = response.data.getOrCreateUserProfiles.profiles ?? null;
    const currentProfile = profiles?.[0] ?? null;

    // if we have a new user, refresh the id token.
    if (response.data.getOrCreateUserProfiles.userCreated) {
      trackUserEvent(user, currentProfile, AnalyticsEventType.SIGN_UP);
      await set(refreshIdTokenAtom);
    }
    return {
      user,
      profiles,
      currentProfile,
      maintenance: response.data.getOrCreateUserProfiles.maintenance,
    };
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    return {
      user: null,
      profiles: null,
      currentProfile: null,
      maintenance: false,
    };
  }
}

export const userProfilesAtom = getDataAtom<UserProfilesPayload>({
  user: null,
  profiles: null,
  currentProfile: null,
  maintenance: false,
});

export const fetchUserProfilesAtom = getDataFetchingAtom<
  UserProfilesPayload,
  GetOrCreateUserProfilesMutationVariables
>(userProfilesAtom, fetchUserProfiles);
