import { createIcon } from '@chakra-ui/react';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <rect width='16' height='16' rx='2' fill='#EEF2F6' />
      <path
        d='M7.99369 5.06037C7.66998 5.06037 7.41627 4.9685 7.23254 4.78478C7.05756 4.60105 6.97007 4.36045 6.97007 4.06299V3.99737C6.97007 3.69991 7.05756 3.45932 7.23254 3.27559C7.41627 3.09186 7.66998 3 7.99369 3C8.3174 3 8.56675 3.09186 8.74173 3.27559C8.92545 3.45932 9.01732 3.69991 9.01732 3.99737V4.06299C9.01732 4.36045 8.92545 4.60105 8.74173 4.78478C8.56675 4.9685 8.3174 5.06037 7.99369 5.06037ZM6.30078 12.2126H7.14068V7.34383L6.30078 7.17323V6.51706L8.85984 5.99213V12.2126H9.69973V13H6.30078V12.2126Z'
        fill='#364152'
      />
    </>
  ),
});
