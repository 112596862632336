import { createIcon } from '@chakra-ui/react';

export const GlobeIcon = createIcon({
  displayName: 'GlobeIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M1.8016 6.4C1.66732 6.92272 1.59959 7.46031 1.6 8C1.6 8.552 1.6704
         9.088 1.8016 9.6H4.08C3.97214 8.53606 3.97214 7.46394 4.08
         6.4H1.8016ZM2.456 4.8H4.3328C4.5456 3.8264 4.8528 2.9544 5.2296
         2.2288C4.06814 2.78834 3.10177 3.68419 2.456 4.8ZM14.1984
         6.4H11.92C12.0279 7.46394 12.0279 8.53606 11.92 9.6H14.1984C14.4677
         8.55033 14.4677 7.44967 14.1984 6.4ZM13.544 4.8C12.8982 3.68419 11.9319
         2.78834 10.7704 2.2288C11.148 2.9544 11.4544 3.8264 11.6672
         4.8H13.544ZM5.6896 6.4C5.62966 6.93122 5.59975 7.46541 5.6 8C5.6 8.548
         5.6304 9.084 5.6896 9.6H10.3104C10.4309 8.53674 10.4309 7.46326 10.3104
         6.4H5.6896ZM5.976 4.8H10.024C9.87841 4.19864 9.6684 3.61473 9.3976
         3.0584C8.8952 2.0544 8.3576 1.6 8 1.6C7.6416 1.6 7.1048 2.0544 6.6024
         3.0584C6.3504 3.564 6.1384 4.152 5.976 4.8ZM2.456 11.2C3.10177 12.3158
         4.06814 13.2117 5.2296 13.7712C4.852 13.0456 4.5456 12.1736 4.3328
         11.2H2.456ZM13.544 11.2H11.6672C11.4544 12.1736 11.1472 13.0456 10.7704
         13.7712C11.9319 13.2117 12.8982 12.3158 13.544 11.2ZM5.976 11.2C6.1384
         11.848 6.3504 12.436 6.6024 12.9416C7.1048 13.9456 7.6424 14.4 8
         14.4C8.3584 14.4 8.8952 13.9456 9.3976 12.9416C9.6496 12.436 9.8616
         11.848 10.024 11.2H5.976ZM8 16C3.5816 16 0 12.4184 0 8C0 3.5816
         3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16Z'
      fill='currentColor'
      stroke='none'
    />
  ),
});
