import { Serie } from '@nivo/line';

import { isNotEmpty } from 'utils/helpers';

export type GetSeriesItemColorFn = (item: {
  id: string | number;
  index: number;
}) => string;

export const getLineChartMaxValue = (data: Serie[]) => {
  if (data.length === 0) {
    return 0;
  }
  const values = data.map((serie) => serie.data).flat();
  return Math.max(...values.map(({ y }) => Number(y)));
};

function mapGradientDefs(data: Serie[], isLight: boolean) {
  const lightColors = (serie: Serie) => [
    {
      offset: 0,
      color: serie['isReference'] ? '#fff' : serie['color'],
    },
    { offset: 100, color: '#fff' },
  ];

  const darkColors = (serie: Serie) => [
    {
      offset: 0,
      color: serie['isReference'] ? 'transparent' : serie['color'],
    },
    { offset: 100, color: 'transparent' },
  ];

  return data.map((serie, idx) => {
    return {
      id: `gradient${idx}-${serie['serieId']}-fill`,
      type: 'linearGradient',
      colors: isLight ? lightColors(serie) : darkColors(serie),
    };
  });
}

function mapGradientIds(data: Serie[]) {
  return data.map((serie, idx) => {
    return {
      match: {
        id: serie.id,
      },
      id: `gradient${idx}-${serie['serieId']}-fill`,
    };
  });
}

export function getLineChartDefsAndFills(data: Serie[], isLight: boolean) {
  return {
    defs: mapGradientDefs(data, isLight),
    fills: mapGradientIds(data),
  };
}

export type LineColorSegment = {
  start: number;
  end: number;
  color: string;
};

export function splitColorSegments(colorSeries: string[]) {
  const colorSegments: LineColorSegment[] = [];

  if (isNotEmpty(colorSeries)) {
    let startIdx = 0;
    let lastColor = colorSeries[0];

    colorSeries.forEach((currentColor, idx) => {
      if (lastColor !== currentColor) {
        colorSegments.push({ start: startIdx, end: idx - 1, color: lastColor });
        startIdx = idx;
      }

      lastColor = currentColor;
    });

    colorSegments.push({
      start: startIdx,
      end: colorSeries.length - 1,
      color: lastColor,
    });
  }

  return colorSegments;
}

export function splitSegmentLastItem(segment?: LineColorSegment) {
  if (!segment) return [];
  if (segment.start === segment.end) return [segment];
  return [
    {
      color: segment.color,
      start: segment.start,
      end: segment.end - 1,
    },
    {
      color: segment.color,
      start: segment.end,
      end: segment.end,
    },
  ];
}
