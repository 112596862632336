export const DATA_VIZ_NEUTRAL = 'var(--chakra-colors-dataViz-1)';
export const DATA_VIZ_NEUTRAL_DRIVER =
  'var(--chakra-colors-dataViz-NeutralDriver)';
export const DATA_VIZ_POSITIVE = 'var(--chakra-colors-dataViz-Positive)';
export const DATA_VIZ_POSITIVE_DRIVER =
  'var(--chakra-colors-dataViz-PositiveDriver)';
export const DATA_VIZ_NEGATIVE = 'var(--chakra-colors-dataViz-Negative)';
export const DATA_VIZ_NEGATIVE_DRIVER =
  'var(--chakra-colors-dataViz-NegativeDriver)';
export const MOBILE_METRIC_CHART_MAX_DATA_POINTS = 6;
