import { Center } from '@chakra-ui/react';

import { AlertTriangleIcon } from 'components/uikit';

export function ErrorIndicator() {
  return (
    <Center
      height='32px'
      width='32px'
      background={'warning.background'}
      borderRadius='4px'
    >
      <AlertTriangleIcon height='20px' width='20px' stroke='warning.text' />
    </Center>
  );
}
