import { useTranslation } from 'react-i18next';

import { GoToPageElement } from 'components/charts/FullBreakdownTable/paginated/GoToPageElement';
import { PagesElement } from 'components/charts/FullBreakdownTable/paginated/PagesElement';
import { PageSizeSelector } from 'components/charts/FullBreakdownTable/paginated/PageSizeSelector';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Flex,
  HStack,
  IconButton,
} from 'components/uikit';
import {
  PageRequest,
  PaginationPageSize,
} from 'data/charts/models/ChartsApiRequest';

type Props = {
  page: number;
  size: PaginationPageSize;
  total: number;
  onPaginationChange?: (pageRequest: PageRequest) => void;
};

export function FullBreakdownTablePaginationRow({
  page,
  size,
  total,
  onPaginationChange,
}: Props) {
  const { t } = useTranslation('mission-control');

  return (
    <Flex
      borderTop='1px solid'
      borderColor='grey.border'
      bgColor='grey.offWhite'
      justifyContent='space-between'
      padding='12px 24px'
      borderBottomRadius='inherit'
    >
      <GoToPageElement
        total={total}
        onPaginationChange={(page) => {
          onPaginationChange?.({ page, size });
        }}
      />
      <HStack>
        <IconButton
          aria-label='Previous page'
          isDisabled={page === 1}
          size='sm'
          margin='0px'
          padding='0px'
          color='secondary.button'
          onClick={() => {
            onPaginationChange?.({
              page: page - 1 > 1 ? page - 1 : 1,
              size,
            });
          }}
          icon={<ArrowLeftIcon stroke='secondary.button' boxSize='20px' />}
          variant='ghost'
          _hover={{ backgroundColor: 'grey.border' }}
        >
          {t('')}
        </IconButton>
        <PagesElement
          page={page}
          pageSize={size}
          total={total}
          onPaginationChange={onPaginationChange}
        />
        <IconButton
          aria-label='Next page'
          isDisabled={page === total || total === 0}
          size='sm'
          margin='0px'
          padding='0px'
          color='secondary.button'
          onClick={() =>
            onPaginationChange?.({
              page: page + 1 < total ? page + 1 : total,
              size,
            })
          }
          icon={<ArrowRightIcon stroke='secondary.button' boxSize='20px' />}
          variant='ghost'
          _hover={{ backgroundColor: 'grey.border' }}
        >
          {t('')}
        </IconButton>
      </HStack>
      <PageSizeSelector
        selectedPageSize={size}
        onPageSizeChange={(size) => onPaginationChange?.({ page, size })}
      />
    </Flex>
  );
}
