import { z } from 'zod';

export const AnalyticsEventType = z.enum(['CONVERSION']);
export type AnalyticsEventType = z.infer<typeof AnalyticsEventType>;

export const GrowthSettings = z.object({
  eventMappings: z.array(
    z.object({
      name: z.string(),
      label: z.string(),
      type: AnalyticsEventType,
    }),
  ),
  updating: z.boolean().default(false),
});
