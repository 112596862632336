import { createIcon } from '@chakra-ui/react';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M19 12H5M5 12L12 19M5 12L12 5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  ),
});
