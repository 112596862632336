import { ReactNode } from 'react';

import { ButtonProps } from '@chakra-ui/react';

import { Button, HStack } from 'components/uikit';

interface SelectToggleProps<T> {
  items: {
    label: ReactNode;
    value: T;
    disabled?: boolean;
    icon?: JSX.Element;
  }[];
  selectedItem: T;
  onChange: (item: T) => void;
  style?: ButtonProps;
}

export function SelectToggle<T>({
  items,
  selectedItem,
  onChange,
  style,
}: SelectToggleProps<T>) {
  return (
    <HStack
      spacing='4px'
      p='4px'
      border='1px solid'
      borderColor='grey.border'
      borderRadius='4px'
      background={'grey.white'}
    >
      {items.map(({ label, value, disabled = false, icon }) => {
        const isSelected = value === selectedItem;
        return (
          <Button
            key={String(value)}
            leftIcon={icon}
            backgroundColor={isSelected ? 'secondary.background' : undefined}
            stroke={isSelected ? 'secondary.text' : undefined}
            color={isSelected ? 'secondary.text' : undefined}
            boxShadow='none !important'
            variant='grayGhost'
            size='sm'
            disabled={disabled}
            onClick={() => onChange(value)}
            {...style}
          >
            {label}
          </Button>
        );
      })}
    </HStack>
  );
}
