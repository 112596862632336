import { TableRowProps as ChakraTableRowProps } from '@chakra-ui/react';

import { ProgressBar } from 'components/Progress/ProgressBar';
import { Td, Tr, TruncatedText } from 'components/uikit';
import {
  ColumnConfig,
  RowConfig,
  TableConfig,
} from 'data/charts/models/TableConfig';

interface TableRowProps<T> {
  row: T;
  config: TableConfig<T>;
  rowConfig?: RowConfig<T>;
}

interface BreakdownCellProps<T> {
  cell: ColumnConfig<T>;
  cellIndex: number;
  row: T;
}

export interface BreakdownTrProps<T> extends ChakraTableRowProps {
  row: T;
}

export function BreakdownTr<T>(props: BreakdownTrProps<T>) {
  return <Tr h='32px' minH='32px' textTransform='none' {...props} />;
}

function BreakdownCell<T>({ cell, cellIndex, row }: BreakdownCellProps<T>) {
  const cellValue = row[cell.accessor];
  if (cellValue === undefined) {
    console.debug('Cell value is undefined', cell.accessor, row);
  }
  const value = cell.render ? cell.render(row) : String(cellValue);
  const textColor =
    typeof cell.style?.textColor === 'function'
      ? cell.style.textColor(row)
      : cell.style?.textColor;
  const align =
    typeof cell.style?.align === 'function'
      ? cell.style.align(row)
      : cell.style?.align;
  const fontWeight =
    typeof cell.style?.fontWeight === 'function'
      ? cell.style.fontWeight(row)
      : cell.style?.fontWeight;
  return (
    <Td
      h='32px'
      minH='32px'
      key={cellIndex}
      textAlign={align}
      width={cell.style?.width}
      minWidth={cell.style?.width}
      maxWidth={cell.style?.width}
      textOverflow='ellipsis'
      overflow='hidden'
      bg={cell.style?.backgroundColor}
      paddingX='16px'
      py={cell.style?.progressBar ? '8px' : undefined}
    >
      {typeof value === 'string' ? (
        <TruncatedText
          as='div'
          color={textColor ?? 'grey.secondaryText'}
          fontSize='14px'
          fontWeight={fontWeight ?? '500'}
        >
          {value}
        </TruncatedText>
      ) : (
        value
      )}
      {cell.style?.progressBar ? (
        <ProgressBar
          progress={cell.style.progressBar.valueGetter(row)}
          height='4px'
          borderRadius='0'
          width={cell.style.progressBar.width}
        />
      ) : null}
    </Td>
  );
}

export function FullBreakdownTableRow<T>({
  row,
  config,
  rowConfig,
}: TableRowProps<T>) {
  const RowComponent = rowConfig?.RowComponent ?? BreakdownTr;

  return (
    <RowComponent h='32px' minH='32px' textTransform='none' row={row}>
      {config.map((cell, cellIndex) => (
        <BreakdownCell
          key={`${String(cell.accessor)}-${cellIndex}`}
          cell={cell}
          cellIndex={cellIndex}
          row={row}
        />
      ))}
    </RowComponent>
  );
}
