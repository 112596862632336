import { useCallback, useEffect } from 'react';

import { useAtom } from 'jotai';

import {
  CUSTOMERS_BREAKDOWNS_CONFIG,
  CustomersBreakdownName,
} from 'data/charts/configs/breakdowns/customers';
import {
  FINANCE_BREAKDOWNS_CONFIG,
  FinanceBreakdownWithPaginationName,
} from 'data/charts/configs/breakdowns/finance';
import {
  GROWTH_BREAKDOWNS_CONFIG,
  GrowthBreakdownName,
} from 'data/charts/configs/breakdowns/growth';
import {
  PRODUCT_BREAKDOWN_CONFIG,
  ProductBreakdownWithPaginationName,
} from 'data/charts/configs/breakdowns/product';
import {
  SALES_BREAKDOWN_CONFIG,
  SalesBreakdownName,
} from 'data/charts/configs/breakdowns/sales';
import {
  PageRequest,
  PaginationPageSize,
} from 'data/charts/models/ChartsApiRequest';
import {
  BreakdownPaginationAtom,
  DEFAULT_PAGINATION,
} from 'utils/atoms/paginationAtom';
import {
  ofCurrentUrl,
  useDestination,
  withParams,
} from 'utils/destination/destination';
import { getNumberValue } from 'utils/url/urlQueryUtils';

function ensurePageSizeValue(pageSize: number): PaginationPageSize {
  const parsedPageSize = PaginationPageSize.safeParse(pageSize);
  if (parsedPageSize.success) {
    return parsedPageSize.data;
  }
  return DEFAULT_PAGINATION.size;
}

export function useBreakdownPaginationAtom(
  paginationAtom: BreakdownPaginationAtom,
) {
  const [paginationAtomValue, setPaginationAtom] = useAtom(paginationAtom);
  const pageNumberParamValue = getNumberValue('page', DEFAULT_PAGINATION.page);
  const pageSizeParamValue = ensurePageSizeValue(
    getNumberValue('size', DEFAULT_PAGINATION.size),
  );

  const pageDestination = useDestination(
    ofCurrentUrl,
    withParams<number, PaginationPageSize>('page', 'size'),
  );

  useEffect(() => {
    if (
      paginationAtomValue.size !== pageSizeParamValue ||
      paginationAtomValue.page !== pageNumberParamValue
    ) {
      setPaginationAtom({
        page: pageNumberParamValue,
        size: pageSizeParamValue,
      });
    }
  }, [
    paginationAtomValue,
    pageNumberParamValue,
    pageSizeParamValue,
    setPaginationAtom,
  ]);

  const setQueryParams = useCallback(
    ({ page, size }: PageRequest) => pageDestination.go(page, size),
    [pageDestination],
  );

  return [paginationAtomValue, setQueryParams] as const;
}

export function useCustomersPaginationAtom(
  breakdownName: CustomersBreakdownName,
) {
  const config = CUSTOMERS_BREAKDOWNS_CONFIG[breakdownName];
  return useBreakdownPaginationAtom(config.atoms.paginationAtom);
}

export function useFinancePaginationAtom(
  breakdownName: FinanceBreakdownWithPaginationName,
) {
  const config = FINANCE_BREAKDOWNS_CONFIG[breakdownName];
  return useBreakdownPaginationAtom(config.atoms.paginationAtom);
}

export function useMarketingPaginationAtom(breakdownName: GrowthBreakdownName) {
  const config = GROWTH_BREAKDOWNS_CONFIG[breakdownName];
  return useBreakdownPaginationAtom(config.atoms.paginationAtom);
}

export function useProductPaginationAtom(
  breakdownName: ProductBreakdownWithPaginationName,
) {
  const config = PRODUCT_BREAKDOWN_CONFIG[breakdownName];
  return useBreakdownPaginationAtom(config.atoms.paginationAtom);
}

export function useSalesPaginationAtom(breakdownName: SalesBreakdownName) {
  const config = SALES_BREAKDOWN_CONFIG[breakdownName];
  return useBreakdownPaginationAtom(config.atoms.paginationAtom);
}

export function useBreakdownPaginationAtomWithoutUrlNavigation(
  paginationAtom: BreakdownPaginationAtom,
) {
  const [paginationAtomValue, setPaginationAtom] = useAtom(paginationAtom);

  const setQueryParams = useCallback(
    (pageRequest: PageRequest) => setPaginationAtom(pageRequest),
    [setPaginationAtom],
  );

  return [paginationAtomValue, setQueryParams] as const;
}
