import { BarChart } from 'components/charts/BarChart/BarChart';
import { BarChartTooltip } from 'components/charts/BarChartTooltip';
import { ProductUsageStat } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/ProductUsageStat';
import { useActiveUsersData } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useActiveUsersData';
import { useCustomerActiveUsersBlocks } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useCustomerActiveUsersBlocks';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { Box, Flex, HStack } from 'components/uikit';
import { getChartColors } from 'utils/chartsUtils';
import {
  formatIntegerNumber,
  getNumberGroupFormatter,
} from 'utils/formatting/number';
import { useColorPalette } from 'utils/hooks/useColorPalette';

export function ActiveUsersChartSection({
  customerId,
}: {
  customerId: string;
}) {
  const { showProductBlocks } = useShowDepartmentBlocksForCompany(customerId);
  const { chartBlock, weekSummaryBlock, monthSummaryBlock } =
    useCustomerActiveUsersBlocks();

  if (
    !showProductBlocks ||
    !chartBlock ||
    !weekSummaryBlock ||
    !monthSummaryBlock
  ) {
    return null;
  }

  return (
    <ActiveUsersChartSectionWithBlocks
      chartBlockId={chartBlock.id}
      customerId={customerId}
    />
  );
}

function ActiveUsersChartSectionWithBlocks({
  customerId,
  chartBlockId,
}: {
  customerId: string;
  chartBlockId: string;
}) {
  const { chart, thisWeekSummary, thisMonthSummary } =
    useActiveUsersData(customerId);
  const colors = useColorPalette();
  const {
    fetchStatus: chartFetchStatus,
    data: chartData,
    aggPeriod: chartAggPeriod,
  } = chart;
  const { fetchStatus: thisWeekSummaryFetchStatus, data: thisWeekSummaryData } =
    thisWeekSummary;
  const {
    fetchStatus: thisMonthSummaryFetchStatus,
    data: thisMonthSummaryData,
  } = thisMonthSummary;

  const formatter = getNumberGroupFormatter(
    [thisWeekSummaryData.value ?? 0, thisMonthSummaryData.value ?? 0],
    { style: 'integer' },
  );

  return (
    <Flex flexDirection='column' width='100%'>
      <HStack justifyContent='space-between' px='24px' pt='16px'>
        <ProductUsageStat
          value={formatter(thisMonthSummaryData.value ?? 0)}
          align='left'
          fetchStatus={thisMonthSummaryFetchStatus}
          period={'this_month'}
        />
        <ProductUsageStat
          value={formatter(thisWeekSummaryData.value ?? 0)}
          align='right'
          fetchStatus={thisWeekSummaryFetchStatus}
          period={'this_week'}
        />
      </HStack>
      <Box width='100%'>
        <BarChart
          id={chartBlockId}
          data={chartData}
          aggPeriod={chartAggPeriod}
          fetchStatus={chartFetchStatus}
          colors={getChartColors(colors, Object.keys(chartData))}
          tooltipContentFormat={(data) =>
            BarChartTooltip({
              ...data,
              calculateNet: false,
            })
          }
          leftTickFormat={formatIntegerNumber}
          lastBarDotted
          align='bottom'
          height={250}
        />
      </Box>
    </Flex>
  );
}
