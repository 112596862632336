export enum AnalyticsEventGroup {
  USER = 'user',
  ONBOARDING = 'onboarding',
  PAGE_VIEW = 'page-view',
  DATA_SOURCES = 'data-sources',
  MISSION_CONTROL_CARD = 'mission-control-card',
  CHART_LEGEND = 'chart-legend',
  CHART_SPLIT_OR_FILTER = 'chart-split-or-filter',
  CHART_SPLIT = 'chart-split',
  CHART_FILTER = 'chart-filter',
  MISSION_CONTROL = 'mission-control',
  CLICKABLE_CARD = 'clickable-card',
  SHARING = 'sharing',
  DATE_PICKER = 'date-picker',
  BREAKDOWNS = 'breakdowns',
  DICTIONARY = 'dictionary',
  RADAR = 'radar',
  ALERTS = 'alerts',
  INSIGHTS = 'insights',
  REPORTS = 'reports',
  GOALS = 'goals',
  SIGNAL_SETTINGS = 'signal-settings',
  CUSTOMER_DRAWER = 'customer-drawer',
  COMMAND_PANEL = 'command-panel',
}
