import { z } from 'zod';

export const SalesStageMappingType = z.enum([
  'won',
  'lost',
  'pipeline',
  'unassigned',
]);

export type SalesStageMappingType = z.infer<typeof SalesStageMappingType>;

export const SalesStageMapping = z.object({
  stage: z.string(),
  type: SalesStageMappingType,
});

export const SalesStageMappings = z.object({
  stages: z.array(SalesStageMapping),
});

export type SalesStageMappings = z.infer<typeof SalesStageMappings>;
