import { createIcon } from '@chakra-ui/react';

export const BookmarkIcon = createIcon({
  displayName: 'BookmarkIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M12.6667 14L8.00004 10.6667L3.33337 14V3.33333C3.33337 2.97971 3.47385 2.64057 3.7239 2.39052C3.97395 2.14048 4.31309 2 4.66671 2H11.3334C11.687 2 12.0261 2.14048 12.2762 2.39052C12.5262 2.64057 12.6667 2.97971 12.6667 3.33333V14Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
