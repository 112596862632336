import { AnalyticsEventGroup, AnalyticsEventType } from 'utils/analytics';

export class CommandPanelAnalyticsEvents extends AnalyticsEventType {
  static COMMAND_PANEL_OPENED = new CommandPanelAnalyticsEvents(
    'command-panel-opened',
  );

  static METRIC_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-metric-selected',
  );

  static ACTION_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-action-selected',
  );

  static INSIGHT_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-insight-selected',
  );

  static COMPANY_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-company-selected',
  );

  static USER_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-company-selected',
  );

  static LEADERBOARD_SELECTED = new CommandPanelAnalyticsEvents(
    'command-panel-leaderboard-selected',
  );

  private constructor(readonly action: string) {
    super(action, AnalyticsEventGroup.COMMAND_PANEL);
  }
}
