import { createIcon } from '@chakra-ui/react';

export const CheckboxIcon = createIcon({
  displayName: 'CheckboxIcon',
  viewBox: '0 0 10 8',
  path: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.6002 0.900195C9.5002 0.800195 9.3002 0.700195 9.1002 0.700195C8.9002 0.700195 8.8002 0.800195 8.6002 0.900195L3.9002 5.7002L1.7002 3.5002C1.6002 3.4002 1.4002 3.3002 1.2002 3.3002C1.0002 3.3002 0.900195 3.4002 0.800195 3.5002C0.500195 3.8002 0.500195 4.2002 0.800195 4.5002L3.4002 7.1002C3.5002 7.2002 3.7002 7.3002 3.9002 7.3002C4.1002 7.3002 4.2002 7.2002 4.4002 7.1002L9.7002 1.8002C9.9002 1.6002 9.9002 1.2002 9.6002 0.900195Z'
      fill='currentColor'
    />
  ),
});
