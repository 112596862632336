import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGTextElement> {
  x: number;
  y: number;
  verticalText?: boolean;
}

export function ChartAxisLabel({
  children,
  x,
  y,
  verticalText = false,
  ...props
}: Props) {
  const transform = verticalText
    ? `translate(${x - 15}, ${y + 15}) rotate(-90)`
    : `translate(${x}, ${y})`;

  return (
    <text
      dominantBaseline='text-before-edge'
      textAnchor='middle'
      dy='8px'
      {...props}
      style={{
        fontFamily: 'var(--font-numeric)',
        fontSize: '12px',
        fill: 'var(--chakra-colors-grey-secondaryText)',
        ...(props.style || {}),
      }}
      transform={transform}
    >
      {children}
    </text>
  );
}
