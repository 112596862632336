import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useCustomerTags } from 'components/CustomerDrawer/hooks/useCustomerTags';
import { Box, Button, HStack } from 'components/uikit';
import { searchTagsBoxValueAtom } from 'data/healthReports/atoms/searchTagsBoxValueAtom';
import { tagsToUpdateAtom } from 'data/healthReports/atoms/tagsToUpdateAtom';
import {
  useCustomerCompany,
  useCustomerCompanyTagsUpdatingAtom,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';
import { isFormLoading } from 'utils/atoms/dataFetchingAtom';
import { combineStatuses, isLoading } from 'utils/types';

interface CompanyTagsModalFooterProps {
  companyId: string;
  onTagsClose: () => void;
  onCreateTag: () => void;
}

export function CompanyTagsModalFooter({
  companyId,
  onTagsClose,
  onCreateTag,
}: CompanyTagsModalFooterProps) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.company_tags_modal.footer',
  });
  const { company, fetchStatus: companyFetchStatus } =
    useCustomerCompany(companyId);
  const [formState, saveCompanyTags] =
    useCustomerCompanyTagsUpdatingAtom(companyId);
  const [companyTagsToUpdate, setCompanyTagsToUpdate] =
    useAtom(tagsToUpdateAtom);
  const setSearchBoxValue = useSetAtom(searchTagsBoxValueAtom);

  const { fetchStatus: tagsFetchStatus } = useCustomerTags();
  const fetchStatus = combineStatuses(companyFetchStatus, tagsFetchStatus);
  const buttonsDisabled = isFormLoading(formState) || isLoading(fetchStatus);

  const onCancel = async () => {
    setCompanyTagsToUpdate(company.tags);
    setSearchBoxValue('');
    onTagsClose();
  };

  const onSave = async () => {
    await saveCompanyTags({ companyId, tags: companyTagsToUpdate });
    trackEvent(CustomerDrawerAnalyticsEvents.TAGS_UPDATED, {
      customerType: 'company',
    });
    setCompanyTagsToUpdate([]);
    onTagsClose();
    setSearchBoxValue('');
  };

  return (
    <HStack
      p={'16px'}
      spacing={'16px'}
      justify={'space-between'}
      borderTop={'1px solid'}
      borderColor={'grey.border'}
      shadow={'0px -16px 16px rgba(0, 0, 0, 0.05)'}
    >
      <Box>
        <Button variant={'primaryGhost'} onClick={onCreateTag}>
          {t('create_tag_button_caption')}
        </Button>
      </Box>
      <HStack alignItems='right' spacing={'16px'}>
        <Button
          variant={'grayOutline'}
          onClick={onCancel}
          disabled={buttonsDisabled}
        >
          {t('cancel_button_caption')}
        </Button>
        <Button variant={'primary'} onClick={onSave} disabled={buttonsDisabled}>
          {t('save_button_caption')}
        </Button>
      </HStack>
    </HStack>
  );
}
