import { format, startOfMonth, subMonths } from 'date-fns';

import { fetchCohortsBreakdown } from 'api/rest/charts/fetchCohortsBreakdown';
import { CohortTableResponse } from 'data/charts/models/ChartsApiResponse';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { Parser } from 'utils/parser/parseResponse';

export type CohortTablePayload<CohortRow> = {
  data: CohortTableResponse<CohortRow> | null;
};

const asDateString = (date: Date) => format(date, 'yyyy-MM-dd');

export function augmentCohortData<CohortRow extends { cohort: Date }>(
  result: CohortTableResponse<CohortRow>,
  numCohorts: number,
  defaultRowFactory: (_: Date) => CohortRow,
) {
  const currentDate = new Date();
  const expectedPeriods = [...Array(numCohorts).keys()].map((i) =>
    startOfMonth(subMonths(currentDate, numCohorts - i)),
  );

  const rowsByCohortDate = Object.fromEntries(
    result.rows.map((row) => [asDateString(row.cohort), row]),
  );

  const augmentedResult = expectedPeriods.map((period) => {
    const rowFromResult = rowsByCohortDate[asDateString(period)];
    return rowFromResult ?? defaultRowFactory(period);
  });

  return {
    rows: augmentedResult,
    numCohorts: result.numCohorts,
  };
}

export function getCohortBreakdownAtoms<
  CohortRow extends { cohort: Date },
  Input = CohortRow,
>(
  rowParser: Parser<CohortRow, Input>,
  defaultRowFactory: (_: Date) => CohortRow,
  numCohorts: number,
) {
  const dataAtom = getDataAtom<{ data: CohortTableResponse<CohortRow> | null }>(
    { data: null },
  );
  const fetchingAtom = getDataFetchingAtom<
    CohortTablePayload<CohortRow>,
    string
  >(dataAtom, async (blockId) => {
    const numOfRows = numCohorts;
    const currentDate = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const startPeriod = asDateString(
      startOfMonth(subMonths(currentDate, numOfRows)),
    );

    const result = await fetchCohortsBreakdown(
      blockId,
      {
        numCohorts: 8, // This parameter is currently ignored, the API always returns 12 cohorts
        startPeriod,
        granularity: 'MONTH',
      },
      rowParser,
    );

    return { data: augmentCohortData(result, numCohorts, defaultRowFactory) };
  });

  return {
    dataAtom,
    fetchingAtom,
  };
}
