import { UserBasicInfoAndCloseButton } from 'components/CustomerDrawer/UserDrawer/DrawerHeader/UserBasicInfoAndCloseButton';
import { UserTags } from 'components/CustomerDrawer/UserDrawer/UserTags';
import { VStack } from 'components/uikit';
import { CustomerTag } from 'data/customer/CustomerTag';

type DrawerHeaderProps = {
  customerUserId: string;
  onClick: () => void;
  tags: CustomerTag[];
  isTagsOpen: boolean;
  onTagsOpen: () => void;
};

export function DrawerHeader({
  customerUserId,
  onClick,
  tags,
  isTagsOpen,
  onTagsOpen,
}: DrawerHeaderProps) {
  return (
    <VStack p='24px' spacing='16px' backgroundColor='grey.white'>
      <UserBasicInfoAndCloseButton
        customerUserId={customerUserId}
        onDrawerClose={onClick}
      />
      {/*<UserDescription description={undefined} />*/}
      {/*<UserAdditionalData customerUserId={customerUserId} />*/}
      {/*<UserAutoCohorts autoCohorts={autoCohorts} />*/}
      <UserTags
        userId={customerUserId}
        userTags={tags}
        isTagsOpen={isTagsOpen}
        onTagsOpen={onTagsOpen}
      />
    </VStack>
  );
}
