import { AxisTickProps } from '@nivo/axes/dist/types/types';
import { animated } from '@react-spring/web';

import { QueryType } from 'data/blocks/models/ChartConfig';
import { getDefaultCurrency } from 'data/settings/hooks/finance/useDefaultCurrency';
import {
  Currency,
  formatCompactNumber,
  getCurrencyFormatter,
} from 'utils/formatting/number';

export function renderLeftTick({
  value,
  tickIndex,
  format,
  animatedProps,
}: AxisTickProps<string>) {
  return (
    <animated.text
      key={tickIndex}
      dominantBaseline='central'
      textAnchor='end'
      transform={animatedProps.transform}
      opacity={animatedProps.opacity}
      dx='-12px'
      style={{
        fontFamily: 'var(--font-numeric)',
        fontSize: '12px',
        fill: 'var(--chakra-colors-grey-secondaryText)',
      }}
    >
      {format ? format(value) : value}
    </animated.text>
  );
}

export function getAxisLeftFormatter(
  queryType: QueryType,
  currency: Currency | null,
) {
  if (queryType === 'FINANCIAL_TRANSACTION_SET') {
    return getCurrencyFormatter(currency ?? getDefaultCurrency());
  }

  return formatCompactNumber;
}
