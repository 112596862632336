import { useAtomValue, useSetAtom } from 'jotai';

import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';
import { updateUserProductConfigAtom } from 'data/user/atoms/updateProductTourConfigAtom';
import { fetchUserProductConfigAtom } from 'data/user/atoms/userProductTourConfigAtom';
import { ProductTourConfig } from 'data/user/models';

export type CompletedStep = keyof ProductTourConfig;

export function useUpdateProductTourConfig() {
  const { idToken } = useAtomValue(idTokenAtom);
  const updateConfig = useSetAtom(updateUserProductConfigAtom);
  const { config } = useAtomValue(fetchUserProductConfigAtom);

  const updateTourConfig = async (step: CompletedStep) => {
    if (!idToken) {
      return;
    }
    if (config[step]) {
      return;
    }
    await updateConfig({
      ...config,
      [step]: true,
    });
  };

  return { updateTourConfig };
}
