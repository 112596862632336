/**
 * Mask for showing 'No data for this range' or a range that we want to hide
 */
import { BarCustomLayer } from '@nivo/bar';
import { CustomLayer, CustomLayerProps, Serie } from '@nivo/line';
import { useTranslation } from 'react-i18next';

import useColorModeChecker from 'components/theme/isLightTheme';
import { chakra, Tooltip } from 'components/uikit';
import { BarData } from 'data/charts/models/BarData';
import { calculateLeadingMissingDataRange } from 'utils/barChartUtils';

// return a starting range for which there are only null data points
function getMissingDataPointCountAtStart(data: Serie[]): number {
  // get number of consecutive nulls for each array
  const nullCounts = data.map((serie) => {
    let count = 0;
    serie.data.forEach((dataPoint) => {
      if (dataPoint.y === undefined) {
        count++;
      }
    });

    return count;
  });

  // return the lowest number - the range for which all series have missing data.
  return Math.min(...nullCounts);
}

function MaskLayer({
  chartHeight,
  rangeEndXValue,
}: {
  chartHeight: number;
  rangeEndXValue: number;
}) {
  const { t } = useTranslation('mission-control');
  const isLight = useColorModeChecker();
  const isSmall = rangeEndXValue < 142;

  const background = (
    <chakra.rect
      x={0}
      y={0}
      height={chartHeight}
      width={`${rangeEndXValue}px`}
      fill={'grey.background'}
      opacity={0.32}
    />
  );

  const boxSides = (
    <>
      <chakra.line stroke='gray.200' x1={0} y1={0} x2={0} y2={chartHeight} />
      <chakra.line
        stroke='gray.200'
        x1={rangeEndXValue}
        y1={0}
        x2={rangeEndXValue}
        y2={chartHeight}
      />
    </>
  );

  const boxWidth = isSmall ? 35 : 138;
  const boxHeight = 26;
  const boxStartX = (rangeEndXValue - boxWidth) / 2;
  const boxStartY = (chartHeight - boxHeight) / 2;
  const boxMidY = boxStartY + boxHeight / 2;

  const centerLine = (
    <>
      <chakra.line
        stroke='gray.400'
        x1={0}
        y1={boxMidY}
        x2={rangeEndXValue}
        y2={boxMidY}
      />
      <chakra.circle cx={0} cy={boxMidY} r={2.75} fill='gray.400' />
      <chakra.circle
        cx={rangeEndXValue}
        cy={boxMidY}
        r={2.75}
        fill='gray.400'
      />
    </>
  );

  const iconStartX = boxStartX + 10;
  const iconStartY = boxStartY + 6.5;

  const contentBox = (
    <g>
      <chakra.rect
        width={boxWidth}
        height={boxHeight}
        x={boxStartX}
        y={boxStartY}
        fill='grey.white'
        stroke='gray.500'
        strokeWidth='1px'
        rx='4px'
        ry='4px'
      />

      {/* icon */}
      <path
        transform={`translate(${iconStartX},${iconStartY})`}
        fill='none'
        stroke={
          isLight
            ? 'var(--chakra-colors-gray-600)'
            : 'var(--chakra-colors-gray-200)'
        }
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M14.666 6.5H12l-2 6-4-12-2 6H1.333'
      />

      {isSmall ? null : (
        <chakra.text
          fontSize='xxs'
          x={boxStartX + 33}
          y={boxStartY + 16.5}
          fill={isLight ? 'gray.600' : 'gray.200'}
        >
          {t('no_data_available')}
        </chakra.text>
      )}
    </g>
  );

  return (
    <g>
      {background}
      {boxSides}
      {centerLine}
      <Tooltip
        label={t('no_data_available')}
        fontSize={'xxs'}
        isDisabled={!isSmall}
      >
        {contentBox}
      </Tooltip>
    </g>
  );
}

export const LineMaskLayer: CustomLayer = function (props: CustomLayerProps) {
  const missingDataRange = getMissingDataPointCountAtStart(props.data);

  if (missingDataRange <= 0) {
    return null;
  }

  const rangeEndXValue =
    props.series?.[0]?.data?.[missingDataRange]?.position?.x ??
    props.innerWidth;

  return (
    <MaskLayer
      chartHeight={props.innerHeight}
      rangeEndXValue={rangeEndXValue}
    />
  );
};

export const BarMaskLayer: BarCustomLayer<BarData> = (props) => {
  const missingDataRange = calculateLeadingMissingDataRange(props);
  if (missingDataRange <= 0) {
    return null;
  }

  const lastEmptyBar = props.bars.find(
    (barData) => barData.index === missingDataRange - 1,
  );
  const rangeEndXValue = lastEmptyBar ? lastEmptyBar.x + lastEmptyBar.width : 0;

  return (
    <MaskLayer
      chartHeight={props.innerHeight}
      rangeEndXValue={rangeEndXValue}
    />
  );
};
