import { createIcon } from '@chakra-ui/react';

export const SortIcon = createIcon({
  displayName: 'SortIcon',
  viewBox: '0 0 10 14',
  path: (
    <>
      <path
        d='M8.33366 5.00008L5.00033 1.66675L1.66699 5.00008M8.33366 9.00008L5.00033 12.3334L1.66699 9.00008'
        fill='none'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
