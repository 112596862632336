import * as Sentry from '@sentry/nextjs';
import camelcaseKeys from 'camelcase-keys';
import { z, ZodError, ZodFormattedError, ZodIssue } from 'zod';

import { isNotNull } from 'utils/helpers';

// TODO: extract into a common library

type Issue = { code: string; message: string };

function harvestZodErrors<T>(
  { _errors, ...otherFields }: ZodFormattedError<T, Issue>,
  allErrors: Issue[] = [],
) {
  allErrors.push(..._errors);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(otherFields).forEach((field: any) => {
    if ('_errors' in field) {
      harvestZodErrors(field, allErrors);
    }
  });

  return allErrors;
}

function formatZodIssue(issue: ZodIssue): Issue {
  if ('received' in issue) {
    return {
      code: issue.code,
      message: `${issue.code}, received: ${
        issue.received
      }. Path: ${issue.path.join('->')}`,
    };
  }
  return {
    code: issue.code,
    message: issue.message,
  };
}

class ParseError<T> extends Error {
  private _issues: Issue[];
  private _formattedError: ZodFormattedError<T, Issue>;

  constructor(
    item: unknown,
    error: ZodError<T>,
    logLevel: 'warn' | 'error' = 'error',
  ) {
    const formattedError = error.format(formatZodIssue);
    super(`Failed to parse item: ${item}`);

    this._issues = harvestZodErrors(formattedError);
    this._formattedError = formattedError;

    console[logLevel](JSON.stringify(formattedError));
    console[logLevel](JSON.stringify(item));
  }

  public get formattedError() {
    return this._formattedError;
  }

  public get issues() {
    return this._issues;
  }
}

export type Parser<Output, Input = Output> = z.ZodType<
  Output,
  z.ZodTypeDef,
  Input
>;

export function parseItems<Output, Input = Output>(
  parser: Parser<Output, Input>,
  items?: Record<string, unknown>[] | null,
): Output[] {
  return (items || []).map((item) => parseItem(parser, item)).filter(isNotNull);
}

// Non-strict parser e.g. for ignoring corrupt cached values
export function parseItem<Output, Input = Output>(
  parser: Parser<Output, Input>,
  item?: Record<string, unknown> | null,
  camelCase = true,
): Output | null {
  const result = parser.safeParse(
    item && camelCase ? camelcaseKeys(item, { deep: true }) : item,
  );

  if (!result.success) {
    const parseError = new ParseError(item, result.error, 'warn');

    Sentry.captureException(parseError, {
      extra: {
        item,
        formattedError: parseError.formattedError,
      },
      fingerprint: [
        ...new Set([
          'parseItem',
          ...parseError.issues.map((issue) => issue.message),
        ]),
      ],
    });
    return null;
  }
  return result.data;
}

export function parseEnumStrict<Output, Input = Output>(
  parser: z.ZodType<Output, z.ZodTypeDef, Input>,
  item?: string | null,
) {
  const result = parser.safeParse(item);

  if (!result.success) {
    const parseError = new ParseError(item, result.error);

    Sentry.captureException(parseError, {
      extra: {
        item,
        formattedError: parseError.formattedError,
      },
      fingerprint: [
        ...new Set([
          'parseEnumStrict',
          ...parseError.issues.map((issue) => issue.message),
        ]),
      ],
    });

    throw parseError;
  }

  return result.data;
}

export function parseEnumOptional<T extends [string, ...string[]]>(
  parser: z.ZodEnum<T>,
  item?: string | null,
) {
  const result = parser.safeParse(item);

  if (!result.success) return null;
  return result.data;
}

export function parseStringOptional<Output, Input = Output>(
  parser: Parser<Output, Input>,
  item?: string | null,
) {
  const result = parser.safeParse(item);

  if (!result.success) return null;
  return result.data;
}

export function parseItemStrict<Output, Input = Output>(
  parser: Parser<Output, Input>,
  item?: Record<string, unknown> | null,
  camelCase = true,
): Output {
  const result = parser.safeParse(
    item && camelCase ? camelcaseKeys(item, { deep: true }) : item,
  );

  if (!result.success) {
    const parseError = new ParseError(item, result.error);

    Sentry.captureException(parseError, {
      extra: {
        item,
        formattedError: parseError.formattedError,
      },
      fingerprint: [
        ...new Set([
          'parseItemStrict',
          ...parseError.issues.map((issue) => issue.message),
        ]),
      ],
    });

    throw parseError;
  }

  return result.data;
}

export function parseItemsStrict<Output, Input = Output>(
  parser: Parser<Output, Input>,
  items?: Record<string, unknown>[] | null,
): Output[] {
  return (items || []).map((item) => parseItemStrict(parser, item));
}

export function parseEnumsStrict<Output, Input = Output>(
  parser: z.ZodType<Output, z.ZodTypeDef, Input>,
  items?: string[] | null,
): Output[] {
  return (items || [])
    .map((item) => parseEnumStrict(parser, item))
    .filter(isNotNull);
}
