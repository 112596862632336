import { Select } from 'components/Select/Select';
import { PaginationPageSize } from 'data/charts/models/ChartsApiRequest';

type PageSizeSelectorProps = {
  selectedPageSize: PaginationPageSize;
  onPageSizeChange?: (pageSize: PaginationPageSize) => void;
};

const options: { value: PaginationPageSize; label: string }[] = [
  {
    value: 10,
    label: '10 / page',
  },
  {
    value: 20,
    label: '20 / page',
  },
  {
    value: 50,
    label: '50 / page',
  },
];

export function PageSizeSelector({
  selectedPageSize,
  onPageSizeChange,
}: PageSizeSelectorProps) {
  return (
    <Select
      width='110px'
      height='25px'
      placement='bottom-end'
      label=''
      selectedItem={selectedPageSize}
      items={options}
      onChange={(value) => onPageSizeChange?.(value)}
    />
  );
}
