import { useRouter } from 'next/router';

import { TFuncKey } from 'i18next';
import { useTranslation } from 'react-i18next';

import { DesktopSidebarNavItem } from 'components/SidebarMenu/DesktopSidebarNavItem';
import { SidebarMenuTooltip } from 'components/SidebarMenu/SidebarMenuTooltip';
import { Box, chakra, Link, RadarIcon } from 'components/uikit';
import { useComponentResponsiveVariants } from 'utils/hooks/screenBreakpoints';

export interface SidebarNavItemProps {
  path: string;
  Icon: typeof RadarIcon;
  title: TFuncKey<'sidebar'>;
  hideOnMobile?: boolean;
}

export function SidebarNavItem({ path, Icon, title }: SidebarNavItemProps) {
  const { t } = useTranslation('sidebar');
  const router = useRouter();
  const currentPath = router?.asPath;
  const isSelected = currentPath?.includes(path);

  const mobileIconButton = (
    <SidebarMenuTooltip
      key={path}
      trigger={
        <Link width='100%' href={path}>
          <Box
            borderWidth={isSelected ? '2px' : '1px'}
            borderColor={
              isSelected ? 'secondary.button.dark' : 'grey.border.dark'
            }
            borderRadius='8px'
            background={isSelected ? 'grey.white.dark' : 'transparent'}
            display='flex'
            flexDirection='row'
            alignItems='center'
            p='12px'
            gap='8px'
          >
            <Icon
              borderRadius='4px'
              padding='6px'
              background={
                isSelected ? 'secondary.background.dark' : 'transparent'
              }
              color={
                isSelected ? 'secondary.text.dark' : 'grey.secondaryText.dark'
              }
              h='42px'
              width='42px'
              _hover={{
                background: isSelected
                  ? 'secondary.button.light'
                  : 'grey.offWhite.dark',
                color: 'secondary.text.dark',
              }}
            />
            <chakra.p
              color={
                isSelected
                  ? 'secondary.background.light'
                  : 'grey.secondaryText.dark'
              }
              fontSize='xl'
              fontWeight='medium'
              display='block'
            >
              {t(title)}
            </chakra.p>
          </Box>
        </Link>
      }
      body={
        <chakra.p whiteSpace='nowrap' p='8px'>
          {t(title)}
        </chakra.p>
      }
    />
  );

  const desktopItem = (
    <DesktopSidebarNavItem
      href={path}
      selected={isSelected}
      title={t(title)}
      icon={<Icon h='20px' width='20px' />}
    />
  );
  return useComponentResponsiveVariants('SidebarNavItem', {
    sm: mobileIconButton,
    lg: desktopItem,
  });
}
