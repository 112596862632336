import { useMemo } from 'react';

import { useAtomValue } from 'jotai';

import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';

export function useAuthState() {
  const { idToken, fetchStatus } = useAtomValue(idTokenAtom);
  return useMemo(
    () => ({
      isAuthenticated: fetchStatus === 'SUCCESS' && !!idToken,
      fetchStatus,
    }),
    [fetchStatus, idToken],
  );
}
