import { fetchBlocksByCompanySection } from 'data/blocks/api/fetchBlocksByCompanySection';
import { Block } from 'data/blocks/models/Block';
import { BlockSection } from 'data/blocks/models/BlockSection';
import { DEMO_COMPANY_NAME } from 'data/constants/demo';

type Result<T extends Block> = {
  blocks: T[];
};

export async function fetchBlocksBySection<T extends Block>(
  companyNamespace: string,
  section: BlockSection,
): Promise<Result<T>> {
  const data = await fetchBlocksByCompanySection<T>(companyNamespace, section);

  if (data.length === 0) {
    const demoData = await fetchBlocksByCompanySection<T>(
      DEMO_COMPANY_NAME,
      section,
    );

    return { blocks: demoData };
  }

  return { blocks: data };
}
