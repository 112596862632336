import { z } from 'zod';

import { SalesUniqueBlockName } from 'data/blocks/models/Block';
import {
  breakdownByLargestLostDealsAtoms,
  breakdownByRecentLostDealsAtoms,
  FullBreakdownByLostDealsRow,
} from 'data/charts/atoms/fullBreakdowns/sales/breakdownsByLostDeals';
import {
  breakdownByClosingSoonAtoms,
  breakdownByLargestDealsInPipelineAtoms,
  breakdownByRecentlyAddedPipelineAtoms,
  breakdownByStalledDealsAtoms,
  FullBreakdownsByPipelineDealsRow,
} from 'data/charts/atoms/fullBreakdowns/sales/breakdownsByPipelineDeals';
import {
  breakdownByFastestDealsToCloseAtoms,
  breakdownByLargeSalesAtoms,
  breakdownByMostRecentSalesAtoms,
  FullBreakdownsBySalesRow,
} from 'data/charts/atoms/fullBreakdowns/sales/breakdownsBySales';
import {
  breakdownByOwnerSalesAtoms,
  breakdownByTimeToCloseAtoms,
  breakdownByWinRateAtoms,
  FullBreakdownsBySalesOwnersRow,
} from 'data/charts/atoms/fullBreakdowns/sales/breakdownsBySalesOwners';
import { BreakdownConfig } from 'data/charts/configs/breakdowns/types';

export const SalesBreakdownName = z.enum([
  'byLargestSales',
  'byMostRecentSales',
  'byFastestDealsToClose',
  'byRecentLostDeals',
  'byLargestLostDeals',
  'byLargestDealsInPipeline',
  'byRecentlyAddedPipeline',
  'byStalledDeals',
  'byClosingSoon',
  'byOwnerSales',
  'byWinRate',
  'byTimeToClose',
  // 'byBestStageProgression',
  // 'byLongestTimeInStage',
  // 'byMostInPipeline',
]);

export type SalesBreakdownName = z.infer<typeof SalesBreakdownName>;

export const SALES_BREAKDOWN_CONFIG: Record<
  SalesBreakdownName,
  BreakdownConfig<SalesUniqueBlockName>
> = {
  byLargestSales: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_BY_LARGEST_VALUE',
    columnParser: FullBreakdownsBySalesRow.keyof(),
    atoms: breakdownByLargeSalesAtoms,
  },
  byMostRecentSales: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_BY_MOST_RECENT',
    columnParser: FullBreakdownsBySalesRow.keyof(),
    atoms: breakdownByMostRecentSalesAtoms,
  },
  byFastestDealsToClose: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_BY_TIME_TO_CLOSE',
    columnParser: FullBreakdownsBySalesRow.keyof(),
    atoms: breakdownByFastestDealsToCloseAtoms,
  },
  byRecentLostDeals: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_LOST_DEALS_BY_CLOSE_DATE',
    columnParser: FullBreakdownByLostDealsRow.keyof(),
    atoms: breakdownByRecentLostDealsAtoms,
  },
  byLargestLostDeals: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_LOST_DEALS_BY_LARGEST_VALUE',
    columnParser: FullBreakdownByLostDealsRow.keyof(),
    atoms: breakdownByLargestLostDealsAtoms,
  },
  byLargestDealsInPipeline: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_LARGEST_DEALS',
    columnParser: FullBreakdownsByPipelineDealsRow.keyof(),
    atoms: breakdownByLargestDealsInPipelineAtoms,
  },
  byRecentlyAddedPipeline: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_RECENT_DEALS',
    columnParser: FullBreakdownsByPipelineDealsRow.keyof(),
    atoms: breakdownByRecentlyAddedPipelineAtoms,
  },
  byStalledDeals: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_STALLED_DEALS',
    columnParser: FullBreakdownsByPipelineDealsRow.keyof(),
    atoms: breakdownByStalledDealsAtoms,
  },
  byClosingSoon: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_CLOSING_SOON',
    columnParser: FullBreakdownsByPipelineDealsRow.keyof(),
    atoms: breakdownByClosingSoonAtoms,
  },
  byOwnerSales: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_VALUE',
    columnParser: FullBreakdownsBySalesOwnersRow.keyof(),
    atoms: breakdownByOwnerSalesAtoms,
  },
  byWinRate: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_WIN_RATE',
    columnParser: FullBreakdownsBySalesOwnersRow.keyof(),
    atoms: breakdownByWinRateAtoms,
  },
  byTimeToClose: {
    blockName: 'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_AVG_SALES_CYCLE',
    columnParser: FullBreakdownsBySalesOwnersRow.keyof(),
    atoms: breakdownByTimeToCloseAtoms,
  },
  // TODO: Add these breakdowns once query is ready
  // byBestStageProgression: {
  //   blockName: 'METRICS_SALES_FULL_BREAKDOWN_BY_PIPELINE_STAGE',
  //   columnParser: BreakdownByPipelineStagesColumns,
  //   atoms: breakdownByBestStageProgressionAtoms,
  // },
  // byLongestTimeInStage: {
  //   blockName: 'METRICS_SALES_FULL_BREAKDOWN_BY_PIPELINE_STAGE',
  //   columnParser: BreakdownByPipelineStagesColumns,
  //   atoms: breakdownByLongestTimeInStageAtoms,
  // },
  // byMostInPipeline: {
  //   blockName: 'METRICS_SALES_FULL_BREAKDOWN_BY_PIPELINE_STAGE',
  //   columnParser: BreakdownByPipelineStagesColumns,
  //   atoms: breakdownByMostInPipelineAtoms,
  // },
};
