import { getIdToken } from 'data/auth/amplify';

export async function authorisedFetch(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> {
  const { idToken } = await getIdToken();
  return fetch(input, {
    ...(init ?? {}),
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      ...(init?.headers ?? {}),
    },
  });
}
