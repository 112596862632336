import { Profile, User } from 'api/graphql/backendAPI/generatedTypes';
import { IS_TEST } from 'data/constants';
import { AnalyticsEventType } from 'utils/analytics';
import { ADMIN_LOGIN_STORAGE_KEY } from 'utils/analytics/constants';
import { initSentryProvider } from 'utils/analytics/sentry';
import { AnalyticsEvent } from 'utils/analytics/types/AnalyticsEvent';
import { AnalyticsIdentifier } from 'utils/analytics/types/AnalyticsIdentifier';
import { AnalyticsUserEvent } from 'utils/analytics/types/AnalyticsUserEvent';
import { isNotNull } from 'utils/helpers';

import { initHotjarProvider } from './hotjar';
import { initSegmentProvider } from './segment';

export const unidentifiedEvents: AnalyticsEvent[] = [];

interface AnalyticsProvider {
  track(event: AnalyticsUserEvent): Promise<void>;

  identify(identifier: AnalyticsIdentifier): Promise<void>;

  reset(): Promise<void>;
}

function shouldSkipAnalytics(): boolean {
  return IS_TEST || localStorage.getItem(ADMIN_LOGIN_STORAGE_KEY) === 'true';
}

function getAnalyticsProviders(): AnalyticsProvider[] {
  return [
    initSegmentProvider(),
    initHotjarProvider(),
    initSentryProvider(),
  ].filter(isNotNull);
}

const providers = getAnalyticsProviders();

export async function trackEvent(
  type: AnalyticsEventType,
  payload: Record<string, unknown> = {},
  user: User | null = null,
  profile: Profile | null = null,
) {
  if (shouldSkipAnalytics()) return;

  providers.forEach((provider) =>
    provider.track({
      type,
      payload,
      company: profile?.company.namespace ?? '',
      user,
    }),
  );
}

export async function identify(identifier: AnalyticsIdentifier) {
  if (shouldSkipAnalytics()) return;

  await Promise.all(providers.map((provider) => provider.identify(identifier)));
}

export async function reset() {
  if (shouldSkipAnalytics()) return;

  await Promise.all(providers.map((provider) => provider.reset()));
}
