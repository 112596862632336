import { ReactNode, useCallback } from 'react';

import { Box, Flex, Key, Text } from 'components/uikit';
import { Action } from 'utils/hotkeys/types';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

interface SelectRowProps {
  label: ReactNode;
  description?: ReactNode;
  hideHotkeys?: boolean;
  index: number;
  isActive: boolean;
  isHovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onBlur: () => void;
}

export function SelectRow({
  label,
  description,
  hideHotkeys = false,
  index,
  isActive,
  isHovered,
  onSelect,
  onHover,
  onBlur,
}: SelectRowProps) {
  const onClick = useCallback(() => {
    onHover();
    onSelect();
  }, [onHover, onSelect]);
  useKeyAction(`SELECT_${index + 1}` as Action, onClick, {
    preventDefault: true,
    enabled: isActive,
  });

  return (
    <Box
      backgroundColor={isHovered ? 'grey.offWhite' : 'grey.white'}
      p='12px 16px'
      cursor='pointer'
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      onClick={onClick}
    >
      <Flex justifyContent='space-between' whiteSpace='break-spaces'>
        {label}
        {!hideHotkeys && index < 8 ? (
          <Key isOutlined size='24px' element={String(index + 1)} />
        ) : null}
      </Flex>
      <Text color='grey.tertiaryText' fontSize='sm'>
        {description}
      </Text>
    </Box>
  );
}
