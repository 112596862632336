import { atom } from 'jotai';

import { CustomerCompany } from 'data/customer/CustomerCompany';

export const standaloneDrawerCustomerCompany = atom<{
  company?: CustomerCompany;
}>({});

export const standaloneDrawerCustomerCompanyActions = atom<{
  onOpen?: () => void;
}>({});
