import { useEffect } from 'react';

import { atom, useAtom } from 'jotai';

const isEmbedAtom = atom(false);

export function useIsEmbed() {
  const [isEmbed, setIsEmbed] = useAtom(isEmbedAtom);

  useEffect(() => {
    setIsEmbed(window && window !== window.parent);
  }, [setIsEmbed]);

  return isEmbed;
}
