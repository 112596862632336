import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface UpdateCustomerUserTagsParams {
  userId: string;
  tags: string[];
}

export async function updateCustomerUserTags({
  userId,
  tags,
}: UpdateCustomerUserTagsParams): Promise<CustomerUserResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/${userId}/tags`,
    {
      method: 'PUT',
      body: JSON.stringify({ tags }),
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to update customer user tags: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserResponse, data);
}
