import { authorisedFetch } from 'api/rest/authorisedFetch';
import { USERS_API_URL } from 'data/constants';
import { CompanyUser } from 'data/user/models';
import { parseItems } from 'utils/parser/parseResponse';
import { buildUrlSearchParams } from 'utils/url/urlQueryUtils';

export interface FetchCompanyUsersParams {
  companyId: string;
}

export async function fetchCompanyUsers({
  companyId,
}: FetchCompanyUsersParams) {
  const queryParams = buildUrlSearchParams({ company: companyId });

  const response = await authorisedFetch(
    `${USERS_API_URL}/company/users?${queryParams}`,
  );

  if (response.status > 399) {
    const responseBody = await response.text();
    throw new Error(`Failed to fetch company users : ${responseBody}`);
  }

  const payload = await response.json();
  const users = parseItems(CompanyUser, payload.users);

  return { users };
}
