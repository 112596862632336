import { createIcon } from '@chakra-ui/react';

export const CalliperLogoWide = createIcon({
  displayName: 'CalliperLogo',
  viewBox: '0 0 140 32',
  path: (
    <>
      <g clipPath='url(#clip0_5953_86289)'>
        <path
          d='M57.6346 13.8028H53.4978C53.4364 13.3018 53.2833 12.8163 53.0461 12.3707C52.8186 11.9623 52.5146 11.6015 52.1507 11.3079C51.774 11.0077 51.3466 10.7774 50.8887 10.6277C50.3892 10.4668 49.8671 10.387 49.3424 10.3913C48.415 10.3696 47.5027 10.629 46.7253 11.1352C45.9518 11.6685 45.3514 12.4167 44.9983 13.2874C44.5566 14.3702 44.3423 15.5324 44.3686 16.7016C44.3335 17.8911 44.5442 19.0751 44.9877 20.1795C45.3249 21.0412 45.9209 21.7772 46.6936 22.2863C47.4662 22.7954 48.3777 23.0526 49.3025 23.0225C49.8187 23.0268 50.3327 22.9543 50.8276 22.8073C51.2792 22.6705 51.7029 22.4546 52.079 22.1696C52.4446 21.8882 52.7538 21.5402 52.9903 21.144C53.2417 20.7189 53.4137 20.2517 53.4978 19.765L57.6346 19.7836C57.5258 20.6794 57.2558 21.5481 56.8375 22.3476C56.4053 23.1794 55.8255 23.9257 55.1265 24.5502C54.3794 25.2154 53.5143 25.7345 52.5758 26.0806C51.506 26.4693 50.3742 26.6593 49.2361 26.6413C47.6175 26.6688 46.0207 26.2644 44.6104 25.4695C43.2356 24.6723 42.1202 23.4951 41.3982 22.0792C40.6153 20.6002 40.223 18.8076 40.2212 16.7016C40.2194 14.5955 40.6162 12.8003 41.4115 11.3159C42.1429 9.90187 43.2653 8.72783 44.645 7.93359C46.0449 7.14441 47.6292 6.74099 49.2361 6.76452C50.2952 6.75581 51.349 6.91545 52.358 7.23746C53.284 7.53599 54.1476 8.00155 54.9059 8.61111C55.6538 9.2173 56.2726 9.96714 56.7259 10.8164C57.2148 11.7455 57.5231 12.7589 57.6346 13.8028Z'
          fill='#0B141F'
        />
        <path
          d='M64.6358 26.6519C63.7853 26.6669 62.9413 26.5011 62.1595 26.1657C61.4602 25.8604 60.8647 25.3582 60.4458 24.7203C60.006 24.0023 59.7879 23.1705 59.8188 22.3291C59.7947 21.626 59.9473 20.9281 60.2625 20.2992C60.546 19.7608 60.9621 19.3035 61.4714 18.9707C62.0072 18.6249 62.5959 18.3691 63.2143 18.2134C63.8883 18.0394 64.5751 17.9195 65.2681 17.8547C66.113 17.7662 66.7932 17.6829 67.3086 17.605C67.7045 17.566 68.088 17.4457 68.4352 17.2516C68.5482 17.1748 68.6396 17.0701 68.7006 16.9478C68.7615 16.8254 68.79 16.6894 68.7832 16.5528V16.4944C68.798 16.2361 68.7554 15.9778 68.6585 15.738C68.5615 15.4982 68.4127 15.2829 68.2226 15.1074C67.7689 14.755 67.2017 14.5811 66.6285 14.6186C66.0165 14.5857 65.4104 14.7526 64.9015 15.0942C64.4914 15.3855 64.1918 15.8068 64.0513 16.2898L60.3316 15.9869C60.503 15.1416 60.886 14.3536 61.4448 13.6966C62.0292 13.0314 62.767 12.5188 63.5943 12.2034C64.5706 11.8335 65.6085 11.6531 66.6524 11.672C67.4342 11.6687 68.2135 11.7633 68.9718 11.9536C69.6767 12.1278 70.345 12.4256 70.9459 12.8331C71.5171 13.2243 71.9856 13.7474 72.3116 14.3582C72.6599 15.0475 72.8315 15.8126 72.8111 16.5847V26.373H68.9878V24.359H68.8735C68.6403 24.8132 68.323 25.2189 67.9383 25.5546C67.524 25.9076 67.046 26.1781 66.5301 26.3517C65.9199 26.557 65.2796 26.6585 64.6358 26.6519ZM65.7995 23.8754C66.343 23.8872 66.8812 23.7659 67.3671 23.522C67.8003 23.3025 68.1672 22.9714 68.4298 22.5629C68.6896 22.1543 68.8244 21.6787 68.8177 21.1945V19.6535C68.6573 19.7511 68.4837 19.8254 68.3023 19.874C68.0844 19.9405 67.84 20.0016 67.5717 20.0547C67.3033 20.1078 67.0403 20.1557 66.7586 20.1955L66.02 20.3045C65.5948 20.3615 65.1786 20.4731 64.7819 20.6366C64.4605 20.7655 64.1778 20.9751 63.9609 21.245C63.7599 21.5205 63.657 21.8554 63.6687 22.1962C63.6554 22.4385 63.7035 22.6802 63.8085 22.8989C63.9135 23.1176 64.0719 23.3064 64.2691 23.4476C64.7206 23.7497 65.2568 23.8996 65.7995 23.8754Z'
          fill='#0B141F'
        />
        <path
          d='M79.9608 7.02759V26.3782H75.9355V7.02759H79.9608Z'
          fill='#0B141F'
        />
        <path
          d='M87.1981 7.02759V26.3782H83.1729V7.02759H87.1981Z'
          fill='#0B141F'
        />
        <path
          d='M92.4399 9.99543C91.8691 10.0055 91.317 9.79188 90.9015 9.40027C90.6993 9.2191 90.5375 8.99733 90.4268 8.74943C90.316 8.50152 90.2588 8.23305 90.2588 7.96153C90.2588 7.69001 90.316 7.42154 90.4268 7.17363C90.5375 6.92573 90.6993 6.70395 90.9015 6.52278C91.3126 6.12874 91.8626 5.91293 92.4319 5.92231C93.0046 5.91087 93.5588 6.12457 93.9756 6.51747C94.1785 6.69692 94.3405 6.91791 94.4505 7.16546C94.5605 7.41302 94.616 7.68134 94.6132 7.95223C94.6155 8.2242 94.5597 8.49351 94.4498 8.74227C94.3398 8.99103 94.1782 9.21353 93.9756 9.39496C93.5629 9.78982 93.0109 10.0056 92.4399 9.99543ZM90.41 26.3783V11.8659H94.4352V26.3783H90.41Z'
          fill='#0B141F'
        />
        <path
          d='M97.6475 31.8197V11.8659H101.614V14.3103H101.795C101.997 13.8789 102.256 13.4768 102.565 13.1147C102.932 12.685 103.384 12.3373 103.894 12.0944C104.527 11.8007 105.22 11.6579 105.918 11.6773C106.951 11.6707 107.965 11.9573 108.841 12.5036C109.782 13.1058 110.527 13.969 110.985 14.9878C111.517 16.0931 111.782 17.4774 111.782 19.1407C111.782 20.7579 111.522 22.1227 111.001 23.235C110.551 24.2639 109.813 25.1405 108.876 25.7592C107.992 26.3225 106.966 26.6195 105.918 26.6147C105.235 26.634 104.557 26.5015 103.931 26.2268C103.427 25.9942 102.975 25.6615 102.603 25.249C102.278 24.8927 102.01 24.4896 101.806 24.0534H101.667V31.8197H97.6475ZM101.585 19.1221C101.569 19.8905 101.691 20.6555 101.946 21.3805C102.146 21.9662 102.506 22.4844 102.985 22.8764C103.467 23.2259 104.048 23.4142 104.643 23.4142C105.239 23.4142 105.819 23.2259 106.301 22.8764C106.779 22.478 107.137 21.9546 107.335 21.3645C107.583 20.6463 107.704 19.89 107.691 19.13C107.705 18.3783 107.586 17.6299 107.34 16.9195C107.143 16.3355 106.787 15.8183 106.312 15.4262C105.827 15.0786 105.245 14.8902 104.649 14.8868C104.052 14.8835 103.469 15.0655 102.98 15.4076C102.504 15.7911 102.145 16.3013 101.946 16.8796C101.692 17.5992 101.569 18.3589 101.585 19.1221Z'
          fill='#0B141F'
        />
        <path
          d='M121.087 26.6625C119.746 26.6991 118.419 26.384 117.237 25.7486C116.172 25.1512 115.309 24.2503 114.758 23.1607C114.149 21.9226 113.85 20.5548 113.887 19.1752C113.857 17.8134 114.156 16.4645 114.758 15.2429C115.307 14.1486 116.157 13.234 117.208 12.6072C118.338 11.9583 119.625 11.6321 120.928 11.664C121.84 11.656 122.747 11.8108 123.606 12.121C124.422 12.4241 125.163 12.8989 125.779 13.5132C126.424 14.1656 126.919 14.9509 127.23 15.8142C127.599 16.8525 127.779 17.9486 127.761 19.0504V20.1557H115.484V17.6581H123.959C123.97 17.1203 123.84 16.5889 123.582 16.1171C123.338 15.674 122.976 15.3064 122.538 15.0543C122.066 14.7922 121.533 14.6602 120.994 14.6717C120.425 14.6603 119.864 14.8065 119.373 15.0941C118.912 15.364 118.532 15.752 118.271 16.218C118.005 16.6958 117.866 17.2336 117.867 17.7803V20.1557C117.85 20.7988 117.987 21.4366 118.268 22.0155C118.519 22.5229 118.915 22.9445 119.405 23.2271C119.94 23.5208 120.543 23.6674 121.154 23.6522C121.57 23.6564 121.985 23.5927 122.381 23.4636C122.737 23.3458 123.064 23.1518 123.338 22.895C123.605 22.6359 123.811 22.3199 123.941 21.9704L127.66 22.2148C127.486 23.0816 127.087 23.8877 126.505 24.5529C125.888 25.2429 125.115 25.7758 124.251 26.1072C123.242 26.4935 122.168 26.6821 121.087 26.6625Z'
          fill='#0B141F'
        />
        <path
          d='M130.375 26.3782V11.8659H134.278V14.398H134.427C134.631 13.5877 135.099 12.8682 135.756 12.3521C136.379 11.8874 137.138 11.6417 137.916 11.6533C138.133 11.6544 138.351 11.6677 138.567 11.6932C138.774 11.7125 138.979 11.7471 139.18 11.7968V15.3731C138.919 15.303 138.652 15.2523 138.383 15.2216C138.08 15.1804 137.773 15.1583 137.467 15.1552C136.911 15.1437 136.362 15.2755 135.873 15.5378C135.418 15.7865 135.04 16.1538 134.778 16.6006C134.504 17.0779 134.365 17.6207 134.377 18.1709V26.3809L130.375 26.3782Z'
          fill='#0B141F'
        />
        <g clipPath='url(#clip1_5953_86289)'>
          <path
            opacity='0.8'
            d='M16.6716 0.45435C16.2844 -0.147219 15.3737 -0.147218 14.9866 0.45435L5.90934 14.5595C5.80542 14.721 5.75877 14.8944 5.75978 15.0632C5.75877 15.2332 5.80542 15.408 5.90934 15.5706L14.9866 29.7793C15.3737 30.3853 16.2844 30.3853 16.6716 29.7793L25.7488 15.5706C25.7651 15.5451 25.78 15.5192 25.7936 15.4931C25.8662 15.3531 25.8992 15.2064 25.8983 15.063C25.8993 14.8942 25.8527 14.7209 25.7488 14.5595L16.6716 0.45435Z'
            fill='#12B76A'
          />
          <path
            opacity='0.32'
            d='M16.6716 0.45435C16.2844 -0.147219 15.3737 -0.147218 14.9866 0.45435L5.90934 14.5595C5.80542 14.721 5.75877 14.8944 5.75978 15.0632C5.75877 15.2332 5.80542 15.408 5.90934 15.5706L14.9866 29.7793C15.3737 30.3853 16.2844 30.3853 16.6716 29.7793L25.7488 15.5706C25.7651 15.5451 25.78 15.5192 25.7936 15.4931C25.8662 15.3531 25.8992 15.2064 25.8983 15.063C25.8993 14.8942 25.8527 14.7209 25.7488 14.5595L16.6716 0.45435Z'
            fill='#12B76A'
          />
          <path
            opacity='0.7'
            d='M27.6097 2.96474C27.4822 2.26077 26.6408 1.91212 26.053 2.3197L12.2708 11.8763C12.113 11.9857 12.0035 12.1281 11.9399 12.2844C11.8739 12.4411 11.8502 12.6204 11.8839 12.8104L14.8346 29.4124C14.9605 30.1205 15.8019 30.4691 16.3914 30.0574L30.2132 20.4052C30.2381 20.3879 30.2617 20.3697 30.2842 20.3508C30.4049 20.2492 30.4915 20.1263 30.5456 19.9935C30.611 19.838 30.6342 19.66 30.6 19.4711L27.6097 2.96474Z'
            fill='#F04438'
          />
          <path
            opacity='0.32'
            d='M27.6097 2.96474C27.4822 2.26077 26.6408 1.91212 26.053 2.3197L12.2708 11.8763C12.113 11.9857 12.0035 12.1281 11.9399 12.2844C11.8739 12.4411 11.8502 12.6204 11.8839 12.8104L14.8346 29.4124C14.9605 30.1205 15.8019 30.4691 16.3914 30.0574L30.2132 20.4052C30.2381 20.3879 30.2617 20.3697 30.2842 20.3508C30.4049 20.2492 30.4915 20.1263 30.5456 19.9935C30.611 19.838 30.6342 19.66 30.6 19.4711L27.6097 2.96474Z'
            fill='#F04438'
          />
          <path
            opacity='0.75'
            d='M5.59984 2.32214C5.01204 1.91457 4.17063 2.26321 4.0431 2.96718L1.05283 19.4735C1.01859 19.6625 1.04184 19.8406 1.10736 19.9961C1.17148 20.1536 1.2814 20.2972 1.43964 20.4077L15.2615 30.0599C15.851 30.4715 16.6924 30.1229 16.8182 29.4148L19.7689 12.8129C19.7742 12.783 19.7781 12.7534 19.7806 12.7241C19.7942 12.567 19.7686 12.4188 19.7129 12.2866C19.6492 12.1304 19.5398 11.9881 19.3821 11.8787L5.59984 2.32214Z'
            fill='#2E90FA'
          />
          <path
            opacity='0.32'
            d='M5.59984 2.32214C5.01204 1.91457 4.17063 2.26321 4.0431 2.96718L1.05283 19.4735C1.01859 19.6625 1.04184 19.8406 1.10736 19.9961C1.17148 20.1536 1.2814 20.2972 1.43964 20.4077L15.2615 30.0599C15.851 30.4715 16.6924 30.1229 16.8182 29.4148L19.7689 12.8129C19.7742 12.783 19.7781 12.7534 19.7806 12.7241C19.7942 12.567 19.7686 12.4188 19.7129 12.2866C19.6492 12.1304 19.5398 11.9881 19.3821 11.8787L5.59984 2.32214Z'
            fill='#2E90FA'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_5953_86289'>
          <rect
            width='138.58'
            height='32'
            fill='white'
            transform='translate(0.709961)'
          />
        </clipPath>
        <clipPath id='clip1_5953_86289'>
          <rect
            width='30.2356'
            height='30.2362'
            fill='white'
            transform='translate(0.709961)'
          />
        </clipPath>
      </defs>
    </>
  ),
});
