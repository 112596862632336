import { createIcon } from '@chakra-ui/react';

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M7 19H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4m7 14 5-5m0 0-5-5m5 5H7'
    />
  ),
});
