import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Goal } from 'data/goals/models/Goal';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { GOALS_API_URL } from './goalsUrls';

const GoalsResponse = z.object({
  goals: Goal.array(),
});

export async function fetchGoals() {
  const result = await authorisedFetch(GOALS_API_URL, {
    method: 'GET',
  });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch review configs: ${result.status}`);
  }

  return parseItemStrict(GoalsResponse, data);
}
