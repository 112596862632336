const BlankChars = /\s*|&nbsp;|\r|\n/g;

export const wordContainsAnother = (wordA: string, wordB: string) => {
  return wordA.toLowerCase().includes(wordB.toLowerCase());
};

/**
 * Breaks stringA into words, break stringB into words,
 * checks if all words in stringB are in stringA with stringContainsAnother
 *
 * @param stringA string
 * @param stringB string
 */
export const stringContainsAnother = (stringA: string, stringB: string) => {
  const wordsFromStringA = stringA.split(BlankChars);

  return stringB
    .split(BlankChars)
    .every((wordB) =>
      wordsFromStringA.some((wordA) => wordContainsAnother(wordA, wordB)),
    );
};
