import { Divider } from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { HorizontalQuartileChart } from 'components/charts/HorizontalQuartileChart';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { Box, Heading, HStack, Skeleton, VStack } from 'components/uikit';
import { useCustomerBreakdownBlock } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlock';
import { useFetchCustomersQuartileCustomerLifespanChart } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { getPercentileCopy } from 'data/healthReports/utils/getPercentileCopy';
import { formatDate } from 'utils/datetime';
import { isLoading } from 'utils/types';

type CustomerLifespanOverviewProps = {
  userId: string;
};

export function CustomerLifespanOverview({
  userId,
}: CustomerLifespanOverviewProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.overview_tab',
  });

  const { showProductBlocks } = useShowDepartmentBlocksForUser(userId);
  const { block } = useCustomerBreakdownBlock(
    'CUSTOMER_USER_PRODUCT_LIFESPAN_QUARTILE',
  );
  const { data, fetchStatus } = useFetchCustomersQuartileCustomerLifespanChart(
    userId,
    block,
  );

  const showSkeletons = isLoading(fetchStatus) || !data;

  if (!block || !showProductBlocks || (!isLoading(fetchStatus) && !data)) {
    return null;
  }

  const today = new Date();
  const joinedDate = new Date(today.getTime() - (data?.value ?? 0) * 1000);

  return (
    <>
      <Divider border={'0 solid'} borderColor={'grey.border'} opacity={1} />
      <VStack p={'24px'} alignItems='left' spacing={0}>
        <HStack justify={'space-between'}>
          <Heading
            as={'h5'}
            fontWeight={'semibold'}
            noOfLines={1}
            fontSize={'14px'}
            lineHeight={1.6}
            color={'grey.primaryText'}
          >
            {t('customer_lifespan.title')}
          </Heading>
          {showSkeletons ? (
            <Skeleton h='18px' w='100px' />
          ) : (
            <Heading
              as={'h5'}
              fontWeight={'semibold'}
              noOfLines={1}
              fontSize={'18px'}
              lineHeight={1.4}
              color={'grey.primaryText'}
            >
              {formatDistance(joinedDate, today)}
            </Heading>
          )}
        </HStack>
        <HStack justify={'space-between'}>
          {showSkeletons ? (
            <Skeleton h='18px' w='250px' />
          ) : (
            <HorizontalQuartileChart
              value={data.quartile}
              star={data.isTopItem}
            />
          )}
          <HStack spacing={'4px'}>
            {showSkeletons ? (
              <Skeleton h='18px' w='120px' />
            ) : (
              <Box
                noOfLines={1}
                fontSize={'12px'}
                lineHeight={1.5}
                color={'grey.tertiaryText'}
              >
                {t('customer_lifespan.joined_date', {
                  date: formatDate(joinedDate, 'DAY', true, false, true),
                })}
              </Box>
            )}
          </HStack>
        </HStack>
        <HStack justify={'space-between'}>
          {showSkeletons ? (
            <Skeleton h='16px' w='150px' mt={'2px'} />
          ) : (
            <Box
              noOfLines={1}
              fontSize={'12px'}
              lineHeight={1.5}
              color={'grey.tertiaryText'}
            >
              {getPercentileCopy(
                data.quartile,
                data.isTopItem,
                data.topItemsCount,
                t,
              )}
            </Box>
          )}
        </HStack>
      </VStack>
    </>
  );
}
