import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import aiEN from './en/ai.json';
import alertTypesEN from './en/alert-types.json';
import alertsEN from './en/alerts.json';
import authEN from './en/auth.json';
import breakdownsEN from './en/breakdowns.json';
import chartEN from './en/chart.json';
import commandPanelEN from './en/command-panel.json';
import commonEN from './en/common.json';
import companyNameEN from './en/company-name.json';
import controlPanelEN from './en/control-panel.json';
import customerHealthEN from './en/customer-health.json';
import dataSourcesEN from './en/data-sources.json';
import dictionaryEN from './en/dictionary.json';
import goalsEN from './en/goals.json';
import integrationsEN from './en/integrations.json';
import missionControlEN from './en/mission-control.json';
import onboardingEN from './en/onboarding.json';
import radarEN from './en/radar.json';
import reviewsEN from './en/reviews.json';
import settingsEN from './en/settings.json';
import sidebarEN from './en/sidebar.json';
import slackEN from './en/slack.json';
import userDetailsEN from './en/user-details.json';
import viewportModalEN from './en/viewport-modal.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    ai: aiEN,
    'alert-types': alertTypesEN,
    alerts: alertsEN,
    auth: authEN,
    breakdowns: breakdownsEN,
    chart: chartEN,
    common: commonEN,
    'company-name': companyNameEN,
    'control-panel': controlPanelEN,
    'customer-health': customerHealthEN,
    'data-sources': dataSourcesEN,
    dictionary: dictionaryEN,
    goals: goalsEN,
    integrations: integrationsEN,
    'mission-control': missionControlEN,
    onboarding: onboardingEN,
    radar: radarEN,
    reviews: reviewsEN,
    settings: settingsEN,
    sidebar: sidebarEN,
    slack: slackEN,
    'command-panel': commandPanelEN,
    'user-details': userDetailsEN,
    'viewport-modal': viewportModalEN,
  },
} as const;

export const i18n = i18next.use(initReactI18next).init({
  resources,
  lng: 'en',
  ns: Object.keys(resources.en),
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
});
