import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface UpdateCustomerCompanyTagsParams {
  companyId: string;
  tags: string[];
}

export async function updateCustomerCompanyTags({
  companyId,
  tags,
}: UpdateCustomerCompanyTagsParams): Promise<CustomerCompanyResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/${companyId}/tags`,
    {
      method: 'PUT',
      body: JSON.stringify({ tags }),
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to update customer company tags: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompanyResponse, data);
}
