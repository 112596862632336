import { z } from 'zod';

import { FinanceUniqueBlockName } from 'data/blocks/models/Block';
import {
  breakdownByHighestLtvCustomerAtoms,
  breakdownByLargestCustomersAtoms,
  breakdownByNewCustomersAtoms,
  FullBreakdownByCustomerRow,
} from 'data/charts/atoms/fullBreakdowns/finance/BreakdownByCustomer';
import {
  breakdownByProductCustomersAtoms,
  breakdownByProductRevenueAtoms,
  FullBreakdownByProductRow,
} from 'data/charts/atoms/fullBreakdowns/finance/BreakdownByProduct';
import {
  breakdownByExpansionTransactionsAtoms,
  breakdownByLargestChurnAtoms,
  breakdownByLatestChurnAtoms,
  breakdownByLatestTransactionsAtoms,
  FullBreakdownByTransactionsRow,
} from 'data/charts/atoms/fullBreakdowns/finance/BreakdownByTransactions';
import {
  BreakdownConfig,
  BreakdownConfigWithoutPagination,
} from 'data/charts/configs/breakdowns/types';

export const FinanceBreakdownName = z.enum([
  'highestLtv',
  'largestCustomers',
  'newCustomers',
  'customers',
  'revenue',
  'latestTransactions',
  'latestChurnTransactions',
  'expansionTransactions',
  'largestChurnTransactions',
]);
export type FinanceBreakdownName = z.infer<typeof FinanceBreakdownName>;
export type FinanceBreakdownWithPaginationName = Exclude<
  FinanceBreakdownName,
  'customers' | 'revenue'
>;

export const FINANCE_BREAKDOWNS_CONFIG: Record<
  FinanceBreakdownWithPaginationName,
  BreakdownConfig<FinanceUniqueBlockName>
> &
  Record<
    'customers' | 'revenue',
    BreakdownConfigWithoutPagination<FinanceUniqueBlockName>
  > = {
  highestLtv: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_HIGHEST_LTV',
    columnParser: FullBreakdownByCustomerRow.keyof(),
    atoms: breakdownByHighestLtvCustomerAtoms,
  },
  largestCustomers: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_LARGEST_MRR',
    columnParser: FullBreakdownByCustomerRow.keyof(),
    atoms: breakdownByLargestCustomersAtoms,
  },
  newCustomers: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_JOIN_DATE',
    columnParser: FullBreakdownByCustomerRow.keyof(),
    atoms: breakdownByNewCustomersAtoms,
  },
  customers: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_BY_PRODUCTS_BY_CUSTOMERS_COUNT',
    columnParser: FullBreakdownByProductRow.keyof(),
    atoms: breakdownByProductCustomersAtoms,
  },
  revenue: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_BY_PRODUCTS_BY_MRR',
    columnParser: FullBreakdownByProductRow.keyof(),
    atoms: breakdownByProductRevenueAtoms,
  },
  latestTransactions: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_LATEST_TRANSACTIONS',
    columnParser: FullBreakdownByTransactionsRow.keyof(),
    atoms: breakdownByLatestTransactionsAtoms,
  },
  latestChurnTransactions: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_CHURN_TRANSACTIONS_BY_DATE',
    columnParser: FullBreakdownByTransactionsRow.keyof(),
    atoms: breakdownByLatestChurnAtoms,
  },
  expansionTransactions: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_EXPANSION_TRANSACTIONS_BY_MRR',
    columnParser: FullBreakdownByTransactionsRow.keyof(),
    atoms: breakdownByExpansionTransactionsAtoms,
  },
  largestChurnTransactions: {
    blockName: 'FULL_BREAKDOWNS_FINANCE_CHURN_TRANSACTIONS_BY_MRR',
    columnParser: FullBreakdownByTransactionsRow.keyof(),
    atoms: breakdownByLargestChurnAtoms,
  },
} as const;
