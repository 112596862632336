import { createIcon } from '@chakra-ui/react';

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fill='none'
        d='M7.05033 7.05025L16.9498 16.9497M7.05033 16.9497L16.9498 7.05025'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
