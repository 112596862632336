import React, { useCallback, useEffect, useState } from 'react';

import { TFunction } from 'react-i18next';

import { InputStatus, ValidateFunction } from './InputStatus';
import { useValidationOnBlur } from './useValidationOnBlur';

interface TextFieldConfig {
  name: string;
  initialValue?: string;
  validate?: ValidateFunction<string>;
  t?: TFunction;
  lazyInitialValue?: boolean;
}

export interface TextFieldConnector {
  name: string;
  value?: string;
  reset: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  inputStatus: InputStatus;
  message?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export function useTextField({
  name,
  initialValue = '',
  validate,
  t,
  lazyInitialValue = false,
}: TextFieldConfig): TextFieldConnector {
  const [value, setValue] = useState<string | undefined>(initialValue);

  const {
    onBlur,
    onFocus,
    inputStatus,
    message,
    reset: resetValidation,
  } = useValidationOnBlur({
    value,
    validate,
    t,
  });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  useEffect(() => {
    if (!lazyInitialValue) return;
    if (!value && initialValue) setValue(initialValue);
  }, [lazyInitialValue, initialValue, value]);

  return {
    name,
    value,
    reset: () => {
      resetValidation();
      setValue(initialValue);
    },
    inputStatus,
    message,
    onChange,
    onBlur,
    onFocus,
  };
}
