import { z, ZodTypeDef } from 'zod';

import { TimeSeries } from 'data/responseModels/TimeSeries';

export const BlockResponse = <Output, Input>(
  data: z.ZodType<Output, z.ZodTypeDef, Input>,
) => {
  return z.object({
    id: z.string(),
    blockUniqueName: z.string(),
    children: z.array(z.string()),
    chartName: z.string(),
    data: data,
  });
};
export type BlockResponse<T, Input = T> = z.output<
  ReturnType<typeof BlockResponse<T, Input>>
>;

export const BlockResponseShort = <Output, Input = Output>(
  data: z.ZodType<Output, z.ZodTypeDef, Input>,
) => {
  return z.object({
    chartName: z.string(),
    data,
  });
};
export type BlockResponseShort<T> = z.infer<
  ReturnType<typeof BlockResponseShort<T>>
>;

export const BreakdownResponse = <Output, Input>(
  row: z.ZodType<Output, ZodTypeDef, Input>,
) => {
  return z.object({
    rows: z.array(row),
    totalCount: z.number(),
  });
};

export type BreakdownResponse<Output, Input> = z.infer<
  ReturnType<typeof BreakdownResponse<Output, Input>>
>;

export const BreakdownWithSummaryRowResponse = <Output, Input, OutputS, InputS>(
  row: z.ZodType<Output, ZodTypeDef, Input>,
  summaryRow?: z.ZodType<OutputS, ZodTypeDef, InputS>,
) => {
  return BreakdownResponse(row).merge(
    z.object({
      summaryRow: summaryRow ? summaryRow.nullable() : row.nullable(),
    }),
  );
};

export type BreakdownWithSummaryRowResponse<
  Output,
  Input,
  OutputS = Output,
  InputS = Input,
> = z.infer<
  ReturnType<
    typeof BreakdownWithSummaryRowResponse<Output, Input, OutputS, InputS>
  >
>;

export const PaginatedBreakdownResponse = <Output, Input>(
  row: z.ZodType<Output, ZodTypeDef, Input>,
) => {
  return BreakdownResponse(row).merge(
    z.object({
      page: z.number(),
      pageSize: z.number(),
      totalPages: z.number(),
    }),
  );
};

export type PaginatedBreakdownResponse<Output, Input> = z.infer<
  ReturnType<typeof PaginatedBreakdownResponse<Output, Input>>
>;

export const CohortTableResponse = <Output, Input = Output>(
  row: z.ZodType<Output, z.ZodTypeDef, Input>,
) => {
  return z.object({
    rows: z.array(row),
    numCohorts: z.number().optional(),
  });
};

export type CohortTableResponse<Output, Input = Output> = z.infer<
  ReturnType<typeof CohortTableResponse<Output, Input>>
>;

export const TimeSeriesResponse = z.record(TimeSeries);

export type TimeSeriesResponse = z.infer<typeof TimeSeriesResponse>;

export const EventCountBreakdownRow = z.object({
  source: z.string(),
  value: z.number(),
  percentageOfTotal: z
    .number()
    .nullable()
    .transform((val) => val ?? NaN),
});
export type EventCountBreakdownRow = z.infer<typeof EventCountBreakdownRow>;

export const TimeByPageBreakdownRow = z.object({
  source: z.string(),
  avgTime: z
    .number()
    .nullable()
    .transform((val) => val ?? NaN),
});

export type TimeByPageBreakdownRow = z.infer<typeof TimeByPageBreakdownRow>;

export const EngagementRateBreakdownRow = z.object({
  source: z.string(),
  engagementRate: z
    .number()
    .nullable()
    .transform((val) => val ?? NaN),
});

export type EngagementRateBreakdownRow = z.infer<
  typeof EngagementRateBreakdownRow
>;

export const ClickableCardBreakdownResponse = <Output, Input>(
  row: z.ZodType<Output, z.ZodTypeDef, Input>,
) => {
  return z.object({
    rows: row.array(),
    totalCount: z.number(),
  });
};

export type ClickableCardBreakdownResponse<Output, Input> = z.infer<
  ReturnType<typeof ClickableCardBreakdownResponse<Output, Input>>
>;
