import { Pie, PieCustomLayer } from '@nivo/pie';
import { PieTooltipProps } from '@nivo/pie/dist/types/types';
import { BasicTooltip } from '@nivo/tooltip';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';

import { Box, Flex } from 'components/uikit';

const PieTooltip = <RawDatum,>({ datum }: PieTooltipProps<RawDatum>) => (
  <BasicTooltip
    id={datum.label}
    value={datum.formattedValue}
    enableChip={true}
    color={datum.color}
  />
);

interface PieChartProps<RawDatum> {
  data: RawDatum[];
  additionalLayers?: PieCustomLayer<RawDatum>[];
  padAngle?: number;
}

export function PieChart<RawDatum>({
  data,
  additionalLayers = [],
}: PieChartProps<RawDatum>) {
  return (
    <Flex width={'100%'}>
      <ReactVirtualizedAutoSizer disableHeight>
        {({ width }) => (
          <Box width={width}>
            <Pie
              data={data}
              fit={true}
              layers={['arcs', ...additionalLayers]}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.6}
              tooltip={PieTooltip}
              cornerRadius={0}
              borderWidth={4}
              borderColor={'var(--chakra-colors-grey-white)'}
              activeOuterRadiusOffset={8}
              colors={{ datum: 'data.chunkColor' }}
              width={width}
              height={250}
              theme={{
                tooltip: {
                  container: {
                    background: 'var(--chakra-colors-grey-white)',
                    color: 'var(--chakra-colors-grey-text)',
                    fontSize: 14,
                  },
                },
              }}
            />
          </Box>
        )}
      </ReactVirtualizedAutoSizer>
    </Flex>
  );
}
