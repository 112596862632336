import { createIcon } from '@chakra-ui/react';

export const WarningIcon = createIcon({
  displayName: 'WarningIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M11.9988 7.99999V12M11.9988 16H12.0088M10.2888 2.85999L1.8188 17C1.64417 17.3024 1.55177 17.6453 1.55079 17.9945C1.54981 18.3437 1.64029 18.6871 1.81323 18.9905C1.98616 19.2939 2.23553 19.5467 2.53651 19.7238C2.83749 19.9009 3.1796 19.9962 3.5288 20H20.4688C20.818 19.9962 21.1601 19.9009 21.4611 19.7238C21.7621 19.5467 22.0114 19.2939 22.1844 18.9905C22.3573 18.6871 22.4478 18.3437 22.4468 17.9945C22.4458 17.6453 22.3534 17.3024 22.1788 17L13.7088 2.85999C13.5305 2.5661 13.2795 2.32311 12.98 2.15447C12.6805 1.98584 12.3425 1.89725 11.9988 1.89725C11.6551 1.89725 11.3171 1.98584 11.0176 2.15447C10.7181 2.32311 10.4671 2.5661 10.2888 2.85999Z'
      stroke='currentColor'
      fill='none'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
