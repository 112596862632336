import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CUSTOMERS_API_URL } from 'api/rest/customer/resourcesUrls';
import { CustomersAndBlocksSearchResult } from 'data/commandPanel/models';
import { parseItemStrict } from 'utils/parser/parseResponse';

export type fetchCustomersByNameParams = {
  query: string;
};

export async function fetchCustomersByName({
  query,
}: fetchCustomersByNameParams): Promise<CustomersAndBlocksSearchResult> {
  const result = await authorisedFetch(`${CUSTOMERS_API_URL}/search/${query}`);

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer company: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomersAndBlocksSearchResult, data, false);
}
