import { MetricSummaryBlockName } from 'data/blocks/models/MetricSummaryBlockName';
import { NumberFormatter } from 'utils/formatting/number';

export const DELTA_UNAVAILABLE = Symbol('DELTA_UNAVAILABLE');

export interface SummaryCardData {
  title: string;
  isLoading?: boolean;
  hasError?: boolean;
  value: number | undefined | null;
  delta: number | undefined | null | typeof DELTA_UNAVAILABLE;
  deltaFormatter: NumberFormatter;
  valueFormatter: NumberFormatter;
  unit?: string | null;
  deltaVs?: string | null;
  isInverseMetric?: boolean;
  isActive?: boolean;
  tooltipTitle: string;
  tooltipDescription: string;
}

export interface SummaryCard<T extends MetricSummaryBlockName>
  extends SummaryCardData {
  uniqueName: T;
}
