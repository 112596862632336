import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownsBySalesRow = z.object({
  dealName: z.string(),
  company: z.string(),
  ownerName: z.string(),
  saleDate: z.coerce.date(),
  timeToCloseInDays: z.coerce.number(),
  dealValue: z.coerce.number(),
});

export type FullBreakdownsBySalesRow = z.infer<typeof FullBreakdownsBySalesRow>;

export const breakdownByLargeSalesAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsBySalesRow,
  [
    {
      column: 'dealValue',
      direction: 'DESC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);

export const breakdownByMostRecentSalesAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownsBySalesRow,
    [{ column: 'saleDate', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByFastestDealsToCloseAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownsBySalesRow,
    [{ column: 'timeToCloseInDays', direction: 'ASC' }],
    fixedEndDateFormattedDateRangeAtom,
  );
