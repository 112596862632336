import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Hub } from 'aws-amplify';

import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';
import {
  AnalyticsEventType,
  replayUnidentifiedEvents,
  useUserEvent,
} from 'utils/analytics';
import { identify, reset } from 'utils/analytics/analytics';

function useTrackPageVisits() {
  const { events } = useRouter();
  const trackEvent = useUserEvent();

  useEffect(() => {
    trackEvent(AnalyticsEventType.PAGE_VISIT, {
      page: window.location.pathname,
    });
    // Adding trackEvent to the dependencies will cause the visit event to be tracked again when the user is identified.
    // We don't need to care about that because the event will be replayed anyway.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sendRouteChangeEvent = (newPath: string) => {
      const path = newPath.split('?')[0];
      if (window.location.pathname === path) return;
      trackEvent(AnalyticsEventType.PAGE_VISIT, {
        page: path,
      });
    };

    events.on('beforeHistoryChange', sendRouteChangeEvent);

    return () => {
      events.off('beforeHistoryChange', sendRouteChangeEvent);
    };
  }, [events, trackEvent]);
}

function useTrackAuthEvents() {
  const trackEvent = useUserEvent();

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;
      switch (event) {
        case 'signIn':
          trackEvent(AnalyticsEventType.SIGN_IN);
          break;
        case 'signUp':
          // signUp event is triggered when a user is created in the backend. See userProfilesAtom.ts
          break;
        case 'signOut':
        case 'oAuthSignOut':
          trackEvent(AnalyticsEventType.SIGN_OUT);
      }
    });
  }, [trackEvent]);
}

function useIdentifyUser() {
  const { user, currentProfile, fetchStatus } = useCurrentProfile();
  useEffect(() => {
    if (fetchStatus != 'SUCCESS' || !user || !currentProfile) return;
    (async () => {
      await identify({
        user,
        company: currentProfile.company.namespace,
      });
      await replayUnidentifiedEvents(user, currentProfile);
    })();
  }, [user, currentProfile, fetchStatus]);
}

export function useSetupAnalytics() {
  useIdentifyUser();
  useTrackAuthEvents();
  useTrackPageVisits();
}

export function useResetAnalytics() {
  return reset;
}
