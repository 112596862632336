import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { useAuthState } from 'data/auth/hooks/useAuthState';
import {
  fetchSalesStageMappingsAtom,
  salesStageMappingsAtom,
} from 'data/charts/atoms/sales/salesStageMappingsAtom';
import { useCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';

export function useFetchSalesStageMappings() {
  const fetchSalesStageMappings = useSetAtom(fetchSalesStageMappingsAtom);
  const { isAuthenticated } = useAuthState();
  const { mainProductActive } = useCompanyOnboarding();

  useEffect(() => {
    if (isAuthenticated && mainProductActive) {
      fetchSalesStageMappings();
    }
  }, [fetchSalesStageMappings, isAuthenticated, mainProductActive]);
}

export function useSalesStageMappings() {
  const { stages, fetchStatus } = useAtomValue(salesStageMappingsAtom);

  return { stages, fetchStatus };
}
