import { useRef } from 'react';

import { CustomerCompanyDrawer } from 'components/CustomerDrawer/CompanyDrawer';
import { useCompanyDrawerState } from 'components/CustomerDrawer/hooks/useCompanyDrawerState';
import { useSwitchIntercomVisibility } from 'utils/intercom/useIntercom';

export function StandaloneCustomerCompanyDrawer() {
  const btnRef = useRef<HTMLDivElement>(null);
  const { company, onClose, isOpen } = useCompanyDrawerState();
  useSwitchIntercomVisibility(isOpen);

  if (!company) {
    return null;
  }

  return (
    <CustomerCompanyDrawer
      customerCompanyId={company.id}
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={btnRef}
    />
  );
}
