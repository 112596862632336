import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { CurrencyType } from 'data/common/currencyType';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByCustomerRow = z.object({
  customerId: z.string(),
  customer: z.string(),
  customerName: z.string(),
  joinDate: z.coerce.date().nullish(),
  mrr: z.coerce.number(),
  ltv: z.coerce.number(),
  currency: CurrencyType,
  joinMonth: z.string(),
  product: z.string(),
});

export type FullBreakdownByCustomerRow = z.infer<
  typeof FullBreakdownByCustomerRow
>;

export const breakdownByHighestLtvCustomerAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCustomerRow,
    [{ column: 'ltv', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByLargestCustomersAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCustomerRow,
    [{ column: 'mrr', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByNewCustomersAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByCustomerRow,
  [
    {
      column: 'joinDate',
      direction: 'DESC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);
