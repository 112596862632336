import { FocusEvent, ReactNode, useCallback } from 'react';

import { Checkbox } from 'components/uikit/form/Checkbox';
import { Flex } from 'components/uikit/reexport';

interface PlainMultiSelectRowProps<T> {
  value: T;
  label: ReactNode;
  index: number;
  isActive: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
  isHovered: boolean;
  onSelect: () => void;
  onHover: () => void;
  onBlur: () => void;
}

function preventFocus(event: FocusEvent<HTMLInputElement>) {
  event.target.blur();
}

function preventDefault(event: FocusEvent<HTMLInputElement>) {
  event.preventDefault();
}

export function SimpleMultiSelectRow<T>({
  value,
  label,
  isSelected,
  onSelect,
  isHovered,
  onHover,
  onBlur,
}: PlainMultiSelectRowProps<T>) {
  const onUpdate = useCallback(() => {
    onHover();
    onSelect();
  }, [onHover, onSelect]);

  return (
    <Flex
      backgroundColor={isHovered ? 'grey.offWhite' : 'grey.white'}
      key={String(value)}
      p='12px 16px 12px 24px'
      cursor='pointer'
      justifyContent='space-between'
      alignItems='center'
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      onClick={onUpdate}
    >
      <Checkbox
        label={label}
        isChecked={isSelected}
        onFocus={preventFocus}
        onBlur={preventDefault}
        pointerEvents='none'
        variant='noShadow'
        sx={{
          span: {
            mt: 0,
            alignSelf: 'center',
          },
        }}
      />
    </Flex>
  );
}
