import { createIcon } from '@chakra-ui/react';

export const SortArrowDown = createIcon({
  displayName: 'SortArrowDown',
  viewBox: '0 0 8 10',
  path: (
    <path
      d='M5 1.5V8.5M5 8.5L8.5 5M5 8.5L1.5 5'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
