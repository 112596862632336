import { useMemo } from 'react';

import { useAtomValue } from 'jotai';

import { customerTagsAtom } from 'data/healthReports/atoms/customerTagsAtoms';

export const useCustomerTags = () => {
  const { tags, fetchStatus } = useAtomValue(customerTagsAtom);

  return { tags, fetchStatus };
};

export const useCustomerTagLabels = () => {
  const { tags } = useCustomerTags();

  return useMemo(
    () => tags.reduce((acc, { id, title }) => ({ ...acc, [id]: title }), {}),
    [tags],
  );
};
