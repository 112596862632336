import { LinkBox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useOnCustomerCompanyDrawerOpen } from 'components/CustomerDrawer/hooks/useOnCustomerCompanyDrawerOpen';
import { Text } from 'components/uikit';
import { Department } from 'data/blocks/models/Block';
import { useCustomerCompany } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { getCustomerName } from 'data/healthReports/utils/getCustomerAnyDepartmentId';

interface UserOfCompanyProps {
  customerUserId: string;
  department: Department;
  departmentId: string;
  onDrawerClose: () => void;
}

export function UserOfCompany({
  customerUserId,
  department,
  departmentId,
  onDrawerClose,
}: UserOfCompanyProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.header',
  });
  const { company } = useCustomerCompany(customerUserId);

  const onCompanyDrawerOpen = useOnCustomerCompanyDrawerOpen({
    department,
    departmentId,
    onDataLoaded: () => void 0,
  });

  if (!company) {
    return null;
  }

  return (
    <Text
      noOfLines={1}
      fontSize={'14px'}
      lineHeight={1.6}
      color={'grey.tertiaryText'}
    >
      {t('user_of_company')}
      <LinkBox
        as={'button'}
        display={'inline'}
        color={'secondary.button'}
        _hover={{ textDecoration: 'underline' }}
        onClick={() => {
          onDrawerClose();
          onCompanyDrawerOpen();
        }}
      >
        {getCustomerName(company)}
      </LinkBox>
    </Text>
  );
}
