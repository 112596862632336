import React, { useCallback, useEffect } from 'react';

import { useAtom } from 'jotai';

import { AiAnswer } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswer';
import { AIErrorCard } from 'components/AiDrawer/AiChat/AiAnswer/AIErrorCard';
import { AiChatFooter } from 'components/AiDrawer/AiChat/AiChatFooter';
import { AiChatHeader } from 'components/AiDrawer/AiChat/AiChatHeader';
import { AiInputCard } from 'components/AiDrawer/AiChat/AiInputCard';
import { AiLoadingCard } from 'components/AiDrawer/AiChat/AiLoadingCard';
import { AiQuestion } from 'components/AiDrawer/AiChat/AiQuestion';
import { VStack } from 'components/uikit';
import { fetchAiResponseAtom } from 'data/ai/atoms';

interface AiChatProps {
  onClose?: () => void;
  onChatArchiveOpen?: () => void;
  fullScreen?: boolean;
}

export function AiChat({
  onClose,
  onChatArchiveOpen,
  fullScreen,
}: AiChatProps) {
  const [resultRemote, fetchAnswer] = useAtom(fetchAiResponseAtom);
  const scrollingContainerRef = React.useRef<HTMLDivElement>(null);

  const askAi = useCallback(
    (prompt: string, actionsRequested?: boolean) => {
      fetchAnswer({ prompt, actionsRequested });
    },
    [fetchAnswer],
  );

  useEffect(() => {
    setTimeout(() => {
      if (!scrollingContainerRef.current) return;
      scrollingContainerRef.current.scrollTop =
        scrollingContainerRef.current.scrollHeight;
    }, 0);
  }, [resultRemote.fetchStatus]);

  return (
    <>
      {fullScreen ? null : <AiChatHeader onClose={onClose} />}
      <VStack
        align='stretch'
        spacing='0'
        px={fullScreen ? ['8px', '8px', '8px', '0'] : '24px'}
        pb={['8px', '8px', '8px', '24px']}
        pt='0'
        backgroundColor={fullScreen ? 'grey.background' : 'grey.offWhite'}
        overflowY={fullScreen ? undefined : 'auto'}
        flex='1'
      >
        <VStack
          align='stretch'
          pt='24px'
          pb={
            resultRemote.results.length > 0 || resultRemote.context.description
              ? '16px'
              : '0'
          }
          spacing='16px'
          scrollBehavior='smooth'
          overflowY={fullScreen ? 'auto' : undefined}
          flex={fullScreen ? 1 : undefined}
          ref={scrollingContainerRef}
        >
          {resultRemote.context.description ? (
            <AiAnswer
              answer={{
                answer: 'Context',
                description: resultRemote.context.description,
              }}
            />
          ) : null}
          {resultRemote.results.map(({ prompt, answer, error }, index) => (
            <React.Fragment key={prompt + index}>
              <AiQuestion question={prompt} flex={fullScreen ? 0 : 1} />
              {answer ? (
                <AiAnswer answer={answer} flex={fullScreen ? 0 : 1} />
              ) : null}
              {error ? (
                <AIErrorCard
                  onRetry={() => askAi(prompt)}
                  flex={fullScreen ? 0 : 1}
                />
              ) : null}
            </React.Fragment>
          ))}
          {resultRemote.fetchStatus === 'LOADING' ? (
            <AiLoadingCard flex={fullScreen ? 0 : 1} />
          ) : null}
        </VStack>
        {resultRemote.fetchStatus === 'LOADING' ? null : (
          <AiInputCard
            onAsk={askAi}
            errorMessage={resultRemote.errorMessage}
            actionsAvailable={
              !!resultRemote.context.insightId && !!resultRemote.context.id
            }
          />
        )}
      </VStack>
      {fullScreen ? null : (
        <AiChatFooter
          onChatArchiveOpen={onChatArchiveOpen}
          chatId={resultRemote.context.id}
          type={resultRemote.context.type}
        />
      )}
    </>
  );
}
