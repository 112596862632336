import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';
import { z } from 'zod';

import { parseEnumOptional } from 'utils/parser/parseResponse';
import { getOptionalStringValue } from 'utils/url/urlQueryUtils';

const GrowthBreakdownSplit = z.enum([
  'sessions',
  'newVisitors',
  'engagementRate',
  'avgTimePerSession',
]);

export type GrowthBreakdownSplit = z.infer<typeof GrowthBreakdownSplit>;

export const growthBreakdownSplitAtom =
  atomWithReset<GrowthBreakdownSplit>('newVisitors');

export function useGrowthBreakdownsSplitValue() {
  const [split, setSplit] = useAtom(growthBreakdownSplitAtom);
  const splitParamValue = getOptionalStringValue('split');
  const parsedSplitValue = parseEnumOptional(
    GrowthBreakdownSplit,
    splitParamValue,
  );

  useEffect(() => {
    if (parsedSplitValue) {
      setSplit(parsedSplitValue);
    } else {
      setSplit(RESET);
    }
  }, [parsedSplitValue, setSplit]);

  return split;
}
