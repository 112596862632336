import { useEffect } from 'react';

export const usePieChartPatch = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    HTMLCanvasElement.prototype.getBBox = function () {
      return { width: this.offsetWidth, height: this.offsetHeight };
    };
  }, []);
};
