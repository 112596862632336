import { DEFAULT_MAPPINGS } from 'utils/hotkeys/mappings';

export type Action = keyof Readonly<typeof DEFAULT_MAPPINGS>;
export type WithNullableAction<T> = T & {
  action: Action | null;
};
export type WithAction<T> = T & { action: Action };

export function hasAction<T>(
  value: WithNullableAction<T> | WithAction<T>,
): value is WithAction<T> {
  return value.action !== null;
}
