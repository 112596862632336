import { createIcon } from '@chakra-ui/react';

export const XeroLogo = createIcon({
  displayName: 'XeroLogo',
  viewBox: '0 0 64 64',
  path: (
    <>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M32 64c17.697 0 32-14.303 32-32S49.697 0 32 0 0 14.303 0 32s14.303 32 32 32z'
        fill='#00b2d5'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M15.304 31.9l5.454-5.454a1.03 1.03 0 0 0 .278-.668c0-.557-.445-1.002-1.002-1.002-.278 0-.5.1-.723.278l-5.454 5.454-5.454-5.454a1.03 1.03 0 0 0-.668-.278c-.557 0-1.002.445-1.002 1.002 0 .278.1.5.278.723l5.454 5.454L7 37.4c-.223.167-.278.445-.278.723 0 .557.445 1.002 1.002 1.002a.92.92 0 0 0 .668-.278l5.454-5.454 5.454 5.454a.92.92 0 0 0 .723.278c.557 0 1.002-.445 1.002-1.002a.92.92 0 0 0-.278-.668zm32.446 0c0 1.002.78 1.78 1.78 1.78s1.78-.78 1.78-1.78-.78-1.78-1.78-1.78c-.946 0-1.78.835-1.78 1.78zm-5.4 0a7.2 7.2 0 0 0 7.179 7.179 7.2 7.2 0 0 0 7.179-7.179 7.2 7.2 0 0 0-7.179-7.179A7.2 7.2 0 0 0 42.35 31.9zm2.06 0c0-2.838 2.282-5.176 5.176-5.176 2.838 0 5.176 2.282 5.176 5.176a5.13 5.13 0 0 1-5.176 5.176c-2.894 0-5.176-2.337-5.176-5.176zm-2.56-7.013h-.278c-.9 0-1.78.278-2.504.835-.1-.445-.5-.78-.946-.78-.557 0-.946.445-.946.946v12.077c0 .557.445 1.002 1.002 1.002s1.002-.445 1.002-1.002v-7.402c0-2.45.223-3.45 2.337-3.73h.4c.557 0 1.002-.4 1.002-.946-.056-.612-.5-1.002-1.057-1.002zm-6.733 5.666c-.445-2.003-1.503-3.617-3.172-4.675-2.45-1.558-5.677-1.447-8.014.223-1.892 1.336-3.005 3.617-3.005 5.9 0 .557.056 1.17.223 1.725.723 2.838 3.172 5 6.066 5.343a6.58 6.58 0 0 0 2.56-.167c.723-.167 1.503-.5 2.17-.9.723-.445 1.28-1.057 1.837-1.725l.056-.056c.4-.5.334-1.17-.1-1.503-.334-.278-.946-.4-1.447.223-.1.167-.223.278-.334.445-.4.445-.835.835-1.4 1.17-.723.4-1.558.612-2.393.612-2.838-.056-4.34-2.003-4.897-3.45-.1-.278-.167-.557-.223-.835v-.167h10.24c1.336-.056 2.06-1.057 1.837-2.17zm-12.02.167v-.1c.557-2.226 2.616-3.896 5-3.896 2.45 0 4.508 1.725 5.064 4.007z'
        fill='#fff'
      />
    </>
  ),
});
