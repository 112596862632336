import { BarChart } from 'components/charts/BarChart/BarChart';
import { useGetRedBlueGreenBarColor } from 'components/charts/common/useGetRedBlueGreenBarColor';
import { FinanceTooltipContent } from 'components/charts/FinanceTooltipContent';
import { FinanceRevenueStat } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/FinanceRevenueStat';
import { useCustomerRevenueChartBlocks } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/useCustomerRevenueChartBlocks';
import { useRevenueChartData } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/useRevenueChartData';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { Box, Flex, HStack } from 'components/uikit';
import { useDefaultCurrency } from 'data/settings/hooks/finance/useDefaultCurrency';
import { getChartColors } from 'utils/chartsUtils';
import { formatDate } from 'utils/datetime';
import { formatCurrency, getCurrencyFormatter } from 'utils/formatting/number';
import { useColorPalette } from 'utils/hooks/useColorPalette';

export function FinanceRevenueChartSection({
  customerId,
}: {
  customerId: string;
}) {
  const { showFinanceBlocks } = useShowDepartmentBlocksForCompany(customerId);
  const { chartBlock, statsBlock } = useCustomerRevenueChartBlocks();

  if (!showFinanceBlocks || !chartBlock || !statsBlock) {
    return null;
  }

  return (
    <FinanceRevenueSectionWithBlocks
      chartBlockId={chartBlock.id}
      customerId={customerId}
    />
  );
}

function FinanceRevenueSectionWithBlocks({
  customerId,
  chartBlockId,
}: {
  customerId: string;
  chartBlockId: string;
}) {
  const currency = useDefaultCurrency();
  const { chart, stats } = useRevenueChartData(customerId);
  const colors = useColorPalette();
  const {
    fetchStatus: chartFetchStatus,
    data: chartData,
    aggPeriod: chartAggPeriod,
  } = chart;
  const { fetchStatus: statsFetchStatus, data: statsData } = stats;
  const getBarColorFn = useGetRedBlueGreenBarColor(chartData?.mrr?.values);

  return (
    <Flex flexDirection='column' width='100%'>
      <HStack justifyContent='space-between' px='24px' pt='16px'>
        <FinanceRevenueStat
          value={statsData?.product}
          align='left'
          fetchStatus={statsFetchStatus}
          statName={'product'}
        />
        <FinanceRevenueStat
          value={
            statsData?.mrr
              ? formatCurrency(statsData?.mrr, currency)
              : undefined
          }
          align='left'
          fetchStatus={statsFetchStatus}
          statName={'mrr'}
        />
        <FinanceRevenueStat
          value={
            statsData?.renewalDate
              ? formatDate(statsData?.renewalDate, 'DAY', true, false, true)
              : undefined
          }
          align='left'
          fetchStatus={statsFetchStatus}
          statName={'renewalDate'}
        />
        <FinanceRevenueStat
          value={
            statsData?.ltv
              ? formatCurrency(statsData?.ltv, currency)
              : undefined
          }
          align='left'
          fetchStatus={statsFetchStatus}
          statName={'ltv'}
        />
      </HStack>
      <Box width='100%'>
        <BarChart
          id={chartBlockId}
          data={chartData}
          aggPeriod={chartAggPeriod}
          fetchStatus={chartFetchStatus}
          colors={getChartColors(colors, Object.keys(chartData))}
          getBarColor={getBarColorFn}
          tooltipContentFormat={FinanceTooltipContent}
          leftTickFormat={getCurrencyFormatter(currency)}
          lastBarDotted
          align='bottom'
          height={250}
        />
      </Box>
    </Flex>
  );
}
