import { z } from 'zod';

import { MissionControlChartConfigKey } from 'data/charts/configs/missionControl';
import { AnalyticsEventType, DictionaryAnalyticsEvents } from 'utils/analytics';

const TrackingLabelValues = z.enum([
  'allTimeChurned',
  'latestChurned',
  'newCustomersByProduct',
  'newCustomers',
  'largestLifetimeCustomers',
  'latestUpgrades',
  'latestTransactions',
  'netMrrMovements',
  'mrrByClient',
  'mrrByProduct',
  'lost_mrr',
  'expansion',
  'totalMrr',
  'netMrrChange',
  'financeStatCards',
  'monthlyAvgTime',
  'weeklySessions',
  'newVisitors',
  'visitorsByMedium',
  'visitorsByCountry',
  'sessionsByLanding',
  'sessionsByOS',
  'engagementRate',
  'engagementByCampaign',
  'engagementByMedium',
  'timeByPage',
  'viewsByPage',
  'monthlySales',
  'winRate',
  'salesStatCards',
  'pipelineOverview',
  'salesWeeklyPipeine',
  'recentlyClosedDeals',
  'largestClosedDeals',
  'recentlyLostDeals',
  'winRateByDealSize',
  'recentlyAddedDeals',
  'monthlySalesCycle',
  'salesCycleTimeByOwner',
  'salesVelocity',
  'largestDealsInPipeline',
  'closingSoon',
  'stageSpeedDataAtom',
  'stalledDeals',
  'productStatCards',
  'newSignups',
  'companiesSignUpsChart',
  'activeUsers',
  'activeCompaniesChart',
  'engagedUsers',
  'companiesEngagedUsersChart',
  'powerUsers',
  'companiesPowerUsersChart',
  'weeklyToMonthlyActiveUsers',
  'weeklyToMonthlyActiveCompaniesChart',
  'recentlySignedUp',
  'companiesRecentlySignedUp',
  'recentlyActive',
  'recentlyActiveCompanies',
  'mostActive',
  'mostActiveCompanies',
  'highestLtv',
  'largestCustomers',
  'newCustomersFullBreakdown',
  'allTransactions',
  'expansionTransactions',
  'latestChurnTransactions',
  'largestChurnTransactions',
  'customersFullBreakdownByProduct',
  'revenueFullBreakdownByProduct',
  'fullBreakdownByCampaign',
  'fullBreakdownByChannel',
  'fullBreakdownByCountry',
  'fullBreakdownByDevice',
  'fullBreakdownByOrigin',
  'fullBreakdownByOS',
  'fullBreakdownByPage',
  'fullBreakdownByResource',
  'recentlySignedUpFullBreakdownByUsers',
  'recentlyActiveFullBreakdownByUsers',
  'mostEngagedFullBreakdownByUsers',
  'mostConsistentFullBreakdownByUsers',
  'fullBreakdownByTopPowerUsers',
  'mostActiveFullBreakdownByUsers',
  'usersByCohortFullBreakdown',
  'newPowerUsersBreakdown',
  'newPowerCompaniesBreakdown',
  'userEngagementStreak',
  'companiesEngagementStreak',
  'engagementIntensity',
  'companiesEngagementIntensity',
  'mostEngaged',
  'mostEngagedCompanies',
  'signUpsCohortBreakdown',
  'signUpsCompaniesCohortBreakdown',
  'topPowerUsersBreakdown',
  'best1MonthRetentionCohortsBreakdown',
  'companiesBestRetentionBreakdown',
  'metric_arr',
  'metric_mrr',
  'metric_customers',
  'metric_arpc',
  'metric_net_mrr_change',
  'metric_new_customer_mrr',
  'metric_churn',
  'metric_expansion',
  'metric_contraction',
  'metric_reactivation',
  'metric_mrr_won',
  'metric_mrr_lost',
  'metric_quick_ratio',
  'metric_grr',
  'metric_nrr',
  'metric_avg_ltv',
  'metric_new_visitors',
  'metric_sessions',
  'metric_engaged_sessions',
  'metric_engagement_rate',
  'metric_page_views',
  'metric_pages_per_session',
  'metric_avg_session_duration',
  'metric_avg_page_view_time',
  'metric_leads',
  'metric_lead_conversion_rate',
  'metric_new_sign_ups',
  'metric_active_users',
  'metric_engaged_users',
  'metric_power_users',
  'metric_retained_users',
  'metric_retention_rate',
  'metric_wau_mau',
  'metric_sales',
  'metric_avg_sales_amount',
  'metric_lost_deals',
  'metric_sales_win_rate',
  'metric_avg_sales_cycle',
  'metric_sales_velocity',
  'metric_sales_pipeline_value',
  'leadsByMedium',
  'leadsByCampaign',
  'leads',
  'activeDataSources',
  'digestBreakdownTable',
  'growthStatCards',
  'insightBreakdownTable',
  'missionControlMrrChart',
  'missionControlTotalCustomersChart',
  'missionControlNetMrrChangeCard',
  'missionControlMrrCard',
  'missionControlNewVisitorsChart',
  'missionControlLeadsChart',
  'missionControlNewVisitorsCard',
  'missionControlLeadsCard',
  'missionControlSignUpsChart',
  'missionControlWauMauChart',
  'missionControlClosedDealsChart',
  'missionControlTotalPipelineChart',
  'mrrTest',
  'productNewSignUpsSummary',
  'productPowerUsersSummary',
  'productSummaryCards',
  'missionControlSalesPipelineBreakdown',
  'totalSalesStatCard',
  'newDealsStatCard',
  'stageSpeed',
  'largestSalesFullBreakdownBySales',
  'mostRecentSalesFullBreakdownBySales',
  'fastestDealsToCloseFullBreakdownBySales',
  'recentLostDealsFullBreakdownBySales',
  'largestLostDealsFullBreakdownBySales',
  'largestDealsInPipelineFullBreakdownBySales',
  'recentlyAddedDealsFullBreakdownBySales',
  'stalledDealsFullBreakdownBySales',
  'closingSoonFullBreakdownBySales',
  'ownerSalesFullBreakdownBySales',
  'ownerWinRateFullBreakdownBySales',
  'ownerTimeToCloseFullBreakdownBySales',
  'productRetentionSummary',
  'closedDealsStatCard',
  'totalPipelineStatCard',
  'missionControlTotalCustomersCard',
  'wonDealsChart',
  'recentlyActiveCompanies',
  'recurringCustomers',
  'expansionCustomers',
  'churnCustomers',
  'netCustomersChange',
  'newCustomersMrr',
  'avgDealValue',
  'metric_new_customers',
  'metric_new_customers_mrr',
  'newTrials',
  'endingSoonTrials',
  'latestTrials',
  'metric_new_accounts',
  'metric_new_trials',
  'metric_total_accounts',
  'metric_trials_converted',
  'metric_trials_cancelled',
  'metric_trials_conversion_rate',
  'metric_sales_new_deals',
  'metric_sales_stalled_deals',
  'metric_sales_closing_soon_deals',
  'userSegmentsFullBreakdown',
  'usersListFullBreakdown',
  'revenueBreakdown',
  'outbound_conversations',
  'inbound_conversations',
  'average_response_time',
  // TODO: remove this once review tracking labels fixed
  'unknown',
]);

export type TrackingLabel =
  | z.infer<typeof TrackingLabelValues>
  | MissionControlChartConfigKey;

export const trackingLabelToGroupMapping: Record<
  TrackingLabel,
  AnalyticsEventType
> = {
  unknown: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mrrNetChange: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  weeklyToMonthlyActiveUsersChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signalOneCountChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signalTwoCountChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signalThreeCountChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signalFourCountChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signalFiveCountChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  outbound_conversations: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  average_response_time: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  inbound_conversations: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  wonDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  metric_wau_mau: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  allTimeChurned: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  avgDealValue: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newCustomersMrr: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  netCustomersChange: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recurringCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  expansionCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  churnCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlyActiveCompanies: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  wonDealsChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  latestChurned: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newCustomersByProduct: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  largestLifetimeCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  latestUpgrades: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  latestTransactions: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  netMrrMovements: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mrrByClient: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mrrByProduct: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  lost_mrr: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  expansion: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  totalMrr: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  netMrrChange: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  financeStatCards: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  monthlyAvgTime: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  weeklySessions: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newVisitors: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  visitorsByMedium: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  visitorsByCountry: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  sessionsByLanding: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  sessionsByOS: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  engagementRate: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  engagementByCampaign: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  engagementByMedium: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  timeByPage: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  viewsByPage: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  monthlySales: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  winRate: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  salesStatCards: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  pipelineOverview: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  salesWeeklyPipeine: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlyClosedDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  largestClosedDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlyLostDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  winRateByDealSize: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlyAddedDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  monthlySalesCycle: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  salesCycleTimeByOwner: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  salesVelocity: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  largestDealsInPipeline: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  closingSoon: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  stageSpeedDataAtom: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  stalledDeals: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  productStatCards: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newSignups: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesSignUpsChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  activeUsers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  activeCompaniesChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  engagedUsers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesEngagedUsersChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  powerUsers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesPowerUsersChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  weeklyToMonthlyActiveUsers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  weeklyToMonthlyActiveCompaniesChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlySignedUp: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesRecentlySignedUp: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  recentlyActive: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mostActive: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mostActiveCompanies: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  highestLtv: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  largestCustomers: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newCustomersFullBreakdown: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  allTransactions: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  expansionTransactions: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  latestChurnTransactions: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  largestChurnTransactions: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  customersFullBreakdownByProduct: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  revenueFullBreakdownByProduct: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByCampaign: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByChannel: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByCountry: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByDevice: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByOrigin: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByOS: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByPage: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByResource: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  recentlySignedUpFullBreakdownByUsers:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  recentlyActiveFullBreakdownByUsers: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  mostEngagedFullBreakdownByUsers: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  mostConsistentFullBreakdownByUsers: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fullBreakdownByTopPowerUsers: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  mostActiveFullBreakdownByUsers: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  usersByCohortFullBreakdown: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  largestSalesFullBreakdownBySales: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  mostRecentSalesFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  fastestDealsToCloseFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  recentLostDealsFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  largestLostDealsFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  largestDealsInPipelineFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  recentlyAddedDealsFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  stalledDealsFullBreakdownBySales: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  closingSoonFullBreakdownBySales: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  ownerSalesFullBreakdownBySales: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  ownerWinRateFullBreakdownBySales: AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  ownerTimeToCloseFullBreakdownBySales:
    AnalyticsEventType.BREAKDOWNS_CHART_ERROR,
  newPowerUsersBreakdown: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newPowerCompaniesBreakdown: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  userEngagementStreak: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesEngagementStreak: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  engagementIntensity: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesEngagementIntensity: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mostEngaged: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mostEngagedCompanies: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signUpsCohortBreakdown: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  signUpsCompaniesCohortBreakdown:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  topPowerUsersBreakdown: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  best1MonthRetentionCohortsBreakdown:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  companiesBestRetentionBreakdown:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  metric_arr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_mrr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_customers: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_arpc: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_net_mrr_change: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_customer_mrr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_churn: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_expansion: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_contraction: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_reactivation: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_mrr_won: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_mrr_lost: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_quick_ratio: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_grr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_nrr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_avg_ltv: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_visitors: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sessions: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_engaged_sessions: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_engagement_rate: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_page_views: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_pages_per_session: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_avg_session_duration: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_avg_page_view_time: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_leads: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_lead_conversion_rate: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_sign_ups: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_active_users: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_customers: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_customers_mrr: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_engaged_users: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_power_users: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_retained_users: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_retention_rate: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_avg_sales_amount: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_lost_deals: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_win_rate: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_avg_sales_cycle: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_velocity: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_pipeline_value: DictionaryAnalyticsEvents.CHART_ERROR,
  leadsByMedium: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  leadsByCampaign: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  leads: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  activeDataSources: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  digestBreakdownTable: AnalyticsEventType.INSIGHT_CHART_ERROR,
  growthStatCards: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  insightBreakdownTable: AnalyticsEventType.INSIGHT_CHART_ERROR,
  missionControlNewVisitorsChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlLeadsChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlMrrChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlTotalCustomersChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlMrrCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlSignUpsChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlWauMauChart: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlNetMrrChangeCard:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlLeadsCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlClosedDealsChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlTotalPipelineChart:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlNewVisitorsCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  mrrTest: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlSalesPipelineBreakdown:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newDealsStatCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  productNewSignUpsSummary: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  productPowerUsersSummary: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  productSummaryCards: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  stageSpeed: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  totalSalesStatCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  closedDealsStatCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  missionControlTotalCustomersCard:
    AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  productRetentionSummary: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  totalPipelineStatCard: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  newTrials: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  latestTrials: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  endingSoonTrials: AnalyticsEventType.MISSION_CONTROL_CHART_ERROR,
  metric_new_trials: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_new_accounts: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_total_accounts: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_trials_converted: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_trials_cancelled: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_trials_conversion_rate: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_closing_soon_deals: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_new_deals: DictionaryAnalyticsEvents.CHART_ERROR,
  metric_sales_stalled_deals: DictionaryAnalyticsEvents.CHART_ERROR,
  userSegmentsFullBreakdown: DictionaryAnalyticsEvents.CHART_ERROR,
  usersListFullBreakdown: DictionaryAnalyticsEvents.BREAKDOWNS_CHART_ERROR,
  revenueBreakdown: DictionaryAnalyticsEvents.BREAKDOWNS_CHART_ERROR,
};

export function getEventByLabel(
  trackingLabel: TrackingLabel,
): AnalyticsEventType {
  return trackingLabelToGroupMapping[trackingLabel];
}
