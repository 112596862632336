import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerTagResponse } from 'api/rest/customer/models';
import { CUSTOMERS_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function createCustomerTag(body: {
  title: string;
  description: string;
}): Promise<CustomerTagResponse> {
  const result = await authorisedFetch(`${CUSTOMERS_API_URL}/tags`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer company tags: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerTagResponse, data);
}
