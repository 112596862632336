import { ActiveDaysOverview } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel/ActiveDaysOverview';
import { CustomerLifespanOverview } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel/CustomerLifespanOverview';
import { ProductEngagementOverview } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel/ProductEngagementOverview';
import { RevenueOverview } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel/RevenueOverview';
import { SentimentOverview } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/OverviewTabPanel/SentimentOverview';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { Card, TabPanel, VStack } from 'components/uikit';
import { hideScrollbar } from 'styles/hideScrollbar';

interface OverviewTabPanelProps {
  userId: string;
}

export function OverviewTabPanel({ userId }: OverviewTabPanelProps) {
  const { showProductBlocks, showFinanceBlocks } =
    useShowDepartmentBlocksForUser(userId);

  return (
    <TabPanel height={'100%'}>
      <VStack
        background={'grey.offWhite'}
        alignItems='stretch'
        height={'100%'}
        overflowY={'hidden'}
      >
        <VStack
          p='16px'
          overflowY={'scroll'}
          width={'100%'}
          css={hideScrollbar}
        >
          <Card>
            <SentimentOverview userId={userId} />{' '}
            {showFinanceBlocks ? <RevenueOverview userId={userId} /> : null}
            {showProductBlocks ? <ActiveDaysOverview userId={userId} /> : null}
            {showProductBlocks ? (
              <ProductEngagementOverview userId={userId} />
            ) : null}
            {showProductBlocks ? (
              <CustomerLifespanOverview userId={userId} />
            ) : null}
          </Card>
        </VStack>
      </VStack>
    </TabPanel>
  );
}
