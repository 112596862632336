import { FormEvent, HTMLProps, useCallback, useRef } from 'react';

import { Action } from 'utils/hotkeys/types';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

interface FormProps extends HTMLProps<HTMLFormElement> {
  keyAction?: Action;
}

export function Form({ keyAction = 'CONFIRM', onSubmit, ...props }: FormProps) {
  const ref = useRef<HTMLFormElement>(null);

  useKeyAction(keyAction, () => {
    ref.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  });

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit?.(event);
    },
    [onSubmit],
  );

  return <form type='submit' onSubmit={handleSubmit} {...props} ref={ref} />;
}
