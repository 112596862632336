import { useCallback } from 'react';

import { GetBarColorFn } from 'components/charts/BarChart/BarChart';
import { useColorPalette } from 'utils/hooks/useColorPalette';

export const useGetRedBlueGreenBarColor = (
  chartValues?: (string | null)[],
): GetBarColorFn => {
  const colors = useColorPalette();

  return useCallback(
    (bar) => {
      const prevValueString = chartValues?.[bar.index - 1];
      const currValue = bar.value;

      if (bar.index === 0 || !prevValueString || !currValue) {
        return colors.blue;
      }

      const prevValue = parseFloat(prevValueString);
      return prevValue === currValue
        ? colors.blue
        : prevValue < currValue
        ? colors.positive
        : colors.red;
    },
    [chartValues, colors.blue, colors.positive, colors.red],
  );
};
