import { useSetAtom } from 'jotai';

import {
  CUSTOMERS_BREAKDOWNS_CONFIG,
  CustomersBreakdownName,
} from 'data/charts/configs/breakdowns/customers';
import {
  FINANCE_BREAKDOWNS_CONFIG,
  FinanceBreakdownName,
} from 'data/charts/configs/breakdowns/finance';
import {
  GROWTH_BREAKDOWNS_CONFIG,
  GrowthBreakdownName,
} from 'data/charts/configs/breakdowns/growth';
import {
  PRODUCT_BREAKDOWN_CONFIG,
  ProductBreakdownName,
} from 'data/charts/configs/breakdowns/product';
import {
  SALES_BREAKDOWN_CONFIG,
  SalesBreakdownName,
} from 'data/charts/configs/breakdowns/sales';
import { useDidUpdateEffectWithDeepEqual } from 'data/charts/hooks/useDidUpdateEffect';
import { WritableDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { BreakdownPaginationAtom } from 'utils/atoms/paginationAtom';
import { BreakdownSortAtom } from 'utils/atoms/sortingAtom';
import { useNullableAtomValue } from 'utils/atoms/useNullableAtom';

export function useBreakdownDataReFetch(
  fetchingAtom: WritableDataFetchingAtom<unknown, string>,
  sortingAtom: BreakdownSortAtom | null,
  paginationAtom: BreakdownPaginationAtom | null,
  blockId: string,
) {
  const sorts = useNullableAtomValue(sortingAtom);
  const pagination = useNullableAtomValue(paginationAtom);
  const fetchChart = useSetAtom(fetchingAtom);

  useDidUpdateEffectWithDeepEqual(() => {
    fetchChart(blockId);
  }, [sorts, pagination]);
}

/*
 Refetches breakdown data when sorting or pagination changes
 The first fetch is handled by fetch*Breakdowns.ts

 This hook receives a breakdownName and a blockId to ensure the blockId
 exists already, before rendering.
 */
export function useCustomersBreakdownDataReFetch(
  breakdownName: CustomersBreakdownName,
  blockId: string,
) {
  const { atoms } = CUSTOMERS_BREAKDOWNS_CONFIG[breakdownName];
  const paginationAtom =
    'paginationAtom' in atoms ? atoms.paginationAtom : null;

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    atoms.sortingAtom,
    paginationAtom,
    blockId,
  );
}

export function useFinanceBreakdownDataReFetch(
  breakdownName: FinanceBreakdownName,
  blockId: string,
) {
  const { atoms } = FINANCE_BREAKDOWNS_CONFIG[breakdownName];
  const paginationAtom =
    'paginationAtom' in atoms ? atoms.paginationAtom : null;

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    atoms.sortingAtom,
    paginationAtom,
    blockId,
  );
}

export function useGrowthBreakdownDataReFetch(
  breakdownName: GrowthBreakdownName,
  blockId: string,
) {
  const { atoms } = GROWTH_BREAKDOWNS_CONFIG[breakdownName];

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    atoms.sortingAtom,
    atoms.paginationAtom,
    blockId,
  );
}

export function useProductBreakdownDataReFetch(
  breakdownName: ProductBreakdownName,
  blockId: string,
) {
  const { atoms } = PRODUCT_BREAKDOWN_CONFIG[breakdownName];
  const sortingAtom = 'sortingAtom' in atoms ? atoms.sortingAtom : null;
  const paginationAtom =
    'paginationAtom' in atoms ? atoms.paginationAtom : null;

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    sortingAtom,
    paginationAtom,
    blockId,
  );
}

export function useSalesBreakdownDataReFetch(
  breakdownName: SalesBreakdownName,
  blockId: string,
) {
  const { atoms } = SALES_BREAKDOWN_CONFIG[breakdownName];

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    atoms.sortingAtom,
    atoms.paginationAtom,
    blockId,
  );
}
