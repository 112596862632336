import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useReloadToast } from 'components/ReloadToast';
import { Hub } from 'data/auth/amplify';
import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';
import {
  removeInitialRoute,
  useStoreInitialRoute,
} from 'data/auth/hooks/initialRoute';
import { useNextPathRedirect } from 'data/auth/hooks/useNextPathRedirect';
import { companyOnboardingAtom } from 'data/user/atoms/companyOnboardingAtom';
import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';
import { hasLoaded } from 'utils/helpers';
import { useGetLandingPageRoute } from 'utils/hooks/useNavigateToLandingPage';
import { useNavigate } from 'utils/navigation';
import { EntryPoint } from 'utils/nextTypes';
import paths from 'utils/paths';

let customOAuthState: string | undefined;

Hub.listen('auth', async ({ payload }) => {
  const { event, data } = payload;

  switch (event) {
    case 'customOAuthState':
      customOAuthState = data;
      break;
  }
});

function useProtectedPagesGuard() {
  const { currentProfile, fetchStatus } = useCurrentProfile();
  const navigate = useNavigate();
  const { idToken, fetchStatus: idTokenFetchStatus } =
    useAtomValue(idTokenAtom);
  const { mainProductActive, fetchStatus: onboardingFetchStatus } =
    useAtomValue(companyOnboardingAtom);
  const { pathname } = useRouter();
  const storeInitialRoute = useStoreInitialRoute();
  const redirectToNextPath = useNextPathRedirect();

  const { t } = useTranslation('auth');
  const reloadToast = useReloadToast(
    t('authentication_error_toast_title'),
    t('authentication_error_toast_description'),
  );

  const isOnboardingDataLoaded = hasLoaded(onboardingFetchStatus);
  const isUserLoaded = hasLoaded(fetchStatus);
  const roleInCompanyNotSelected = !currentProfile?.roleInCompany;
  return useCallback(() => {
    if (idTokenFetchStatus === 'ERROR') {
      reloadToast();
      return;
    }

    if (idTokenFetchStatus === 'SUCCESS') {
      if (!idToken) {
        storeInitialRoute();
        navigate(paths.login.index);
        return;
      }

      if (customOAuthState) {
        const data = customOAuthState;
        customOAuthState = undefined;
        removeInitialRoute();
        redirectToNextPath(data);
        return;
      }

      const inOnboardingPage = pathname.startsWith(paths.onboarding.index);
      if (isOnboardingDataLoaded && !mainProductActive && !inOnboardingPage) {
        navigate(paths.onboarding.index);
        return;
      }

      if (
        isUserLoaded &&
        isOnboardingDataLoaded &&
        mainProductActive &&
        roleInCompanyNotSelected
      ) {
        navigate(paths.profile.index);
        return;
      }
    }
  }, [
    navigate,
    idToken,
    idTokenFetchStatus,
    mainProductActive,
    isOnboardingDataLoaded,
    pathname,
    reloadToast,
    redirectToNextPath,
    storeInitialRoute,
    isUserLoaded,
    roleInCompanyNotSelected,
  ]);
}

function useAuthPagesGuard() {
  const navigate = useNavigate();
  const { idToken, fetchStatus } = useAtomValue(idTokenAtom);
  const getLandingPageRoute = useGetLandingPageRoute();

  return useCallback(() => {
    const { route } = getLandingPageRoute();
    if (fetchStatus === 'SUCCESS' && !!idToken && route) {
      navigate(route);
    }
  }, [fetchStatus, idToken, getLandingPageRoute, navigate]);
}

export function useAccessGuard(entryPointType: EntryPoint): void {
  const protectedPagesGuard = useProtectedPagesGuard();
  const authPagesGuard = useAuthPagesGuard();

  useEffect(() => {
    switch (entryPointType) {
      case EntryPoint.PROTECTED:
        protectedPagesGuard();
        return;
      case EntryPoint.AUTH:
        authPagesGuard();
        return;
    }
  }, [entryPointType, authPagesGuard, protectedPagesGuard]);
}
