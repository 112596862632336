import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { importedSharingConfig } from 'data/sharing/atoms/sharingConfig';
import { getIntersection } from 'utils/arrayUtils';

export function useImportedSharingLegendToggle(chartKeys: string[]) {
  const [importedLegendtoggle, setImportedLegendtoggle] = useAtom(
    importedSharingConfig,
  );
  const { legendToggle } = importedLegendtoggle;

  useEffect(() => {
    if (!!legendToggle && chartKeys.length)
      setImportedLegendtoggle({ legendToggle: undefined });
  }, [setImportedLegendtoggle, legendToggle, chartKeys]);

  // filters out any non-matching chart keys so it doesn't break the chart legend reset
  return legendToggle
    ? getIntersection(legendToggle.split(','), chartKeys)
    : [];
}
