import { ReactElement, useEffect, useState } from 'react';

import Head from 'next/head';

import { ColorModeScript } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { setDefaultOptions } from 'date-fns';
import { Provider as GraphQLProvider } from 'urql';

import { getClient } from 'api/graphql/client';
import { AtomReader } from 'components/AtomReader';
import { usePieChartPatch } from 'components/charts/PieChart/usePieChartPatch';
import { ControlPanel } from 'components/ControlPanel/ControlPanel';
import { StandaloneCustomerCompanyDrawer } from 'components/CustomerDrawer/StandaloneCustomerCompanyDrawer';
import { StandaloneCustomerUserDrawer } from 'components/CustomerDrawer/StandaloneCustomerUserDrawer';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AppLayout } from 'components/layouts';
import { Maintenance } from 'components/pages/Maintenance/Maintenance';
import { ProductTourCompletedModal } from 'components/pages/Onboarding/ProductTour/ProductTourCompletedModal';
import { theme, ThemeProvider } from 'components/theme';
import { Loader } from 'components/uikit';
import { useAccessGuard } from 'data/auth/hooks/useAccessGuard';
import { useAuth } from 'data/auth/hooks/useAuth';
import { useFetchSalesStageMappings } from 'data/charts/hooks/sales/useSalesStageMappings';
import { useFetchCustomerProducts } from 'data/charts/hooks/useCustomerProducts';
import { useFetchSettings } from 'data/charts/hooks/useFetchSettings';
import { GOOGLE_OAUTH_CLIENT_ID } from 'data/constants';
import { dateFnsDefaultConfig } from 'data/constants/dateFns';
import { useCheckDataSystemStatus } from 'data/dataSystem/hooks/useCheckDataSystemStatus';
import { useFetchGoals } from 'data/goals/hooks/useFetchGoals';
import { useFetchCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';
import { useFetchUserProfiles } from 'data/user/hooks/useFetchUserProfiles';
import { useFetchUserProductTourConfig } from 'data/user/hooks/useUserProductTourConfig';
import 'locales/i18n';
import { useSetupAnalytics } from 'utils/analytics';
import { useGetInitialColorMode } from 'utils/hooks/useGetInitialColorMode';
import { IntercomScript } from 'utils/intercom/IntercomScript';
import { useIntercom } from 'utils/intercom/useIntercom';
import { AppPropsWithLayout, EntryPoint } from 'utils/nextTypes';

import { AiDrawer } from '../components/AiDrawer/AiDrawer';

setDefaultOptions(dateFnsDefaultConfig);

function AppHead() {
  return (
    <Head>
      <title>Calliper</title>
      {/* <!-- Primary Meta Tags --> */}
      <title>Calliper | Business Intelligence for SaaS Operators</title>
      <meta
        name='title'
        content='Calliper | Business Intelligence for SaaS Operators'
      />
      <meta
        name='description'
        content='Calliper is a modern business intelligence platform designed for SaaS Operators. Get automatic customer insights and alerts, and make data-informed decisions with ease. Integrate in minutes - no coding required.'
      />

      {/* <!-- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color --> */}
      <meta
        name='theme-color'
        media='(prefers-color-scheme: light)'
        content='#EEF2F6'
      />
      <meta
        name='theme-color'
        media='(prefers-color-scheme: dark)'
        content='#090D13'
      />

      {/*{ <!-- Open Graph / Facebook -->}*/}
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.calliper.app/' />
      <meta
        property='og:title'
        content='Calliper | Business Intelligence for SaaS Operators'
      />
      <meta
        property='og:description'
        content='Calliper is a modern business intelligence platform designed for SaaS Operators. Get automatic customer insights and alerts, and make data-informed decisions with ease. Integrate in minutes - no coding required.'
      />
      <meta
        property='og:image'
        content='https://uploads-ssl.webflow.com/5ef484b37d050d19a1fa66bf/65c5198ef9fc0a2b2436d4cc_Meta Image.png](https://uploads-ssl.webflow.com/5ef484b37d050d19a1fa66bf/65c5198ef9fc0a2b2436d4cc_Meta%20Image.png'
      />

      {/*{!--Twitter--}*/}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='https://www.calliper.app/' />
      <meta
        property='twitter:title'
        content='Calliper |  Business Intelligence for SaaS Operator'
      />
      <meta
        property='twitter:description'
        content='Calliper is a modern business intelligence platform designed for SaaS Operators. Get automatic customer insights and alerts, and make data-informed decisions with ease. Integrate in minutes - no coding required.'
      />
      <meta
        property='twitter:image'
        content='https://uploads-ssl.webflow.com/5ef484b37d050d19a1fa66bf/65c5198ef9fc0a2b2436d4cc_Meta Image.png](https://uploads-ssl.webflow.com/5ef484b37d050d19a1fa66bf/65c5198ef9fc0a2b2436d4cc_Meta%20Image.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/static/favicon/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/static/favicon/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/static/favicon/favicon-16x16.png'
      />
      <link rel='manifest' href='/static/favicon/site.webmanifest' />
    </Head>
  );
}

const defaultPageRenderer = (page: ReactElement) => page;
const loaderRenderer = () => <Loader />;
const maintenanceRenderer = () => <Maintenance />;

function App({ Component, pageProps }: AppPropsWithLayout) {
  usePieChartPatch();
  const [isMounted, setIsMounted] = useState(false);
  useGetInitialColorMode();
  useSetupAnalytics();
  useAuth();
  const { userLoaded, maintenance } = useFetchUserProfiles();
  useFetchCompanyOnboarding();
  useFetchUserProductTourConfig();
  useCheckDataSystemStatus();
  useIntercom();
  useFetchSettings();
  useFetchCustomerProducts();
  useFetchSalesStageMappings();
  useFetchGoals();

  useAccessGuard(Component.entryPointType);

  const getLayout =
    !userLoaded && Component.entryPointType === EntryPoint.PROTECTED
      ? loaderRenderer
      : maintenance
      ? maintenanceRenderer
      : Component.getLayout ?? defaultPageRenderer;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return loaderRenderer();
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <AtomReader />
      <GraphQLProvider value={getClient()}>
        <ThemeProvider>
          <AppHead />
          <AppLayout>
            <ErrorBoundary>
              <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
              />
              <IntercomScript />
              {getLayout(<Component {...pageProps} />)}
              <ControlPanel />
              <ProductTourCompletedModal />
              <StandaloneCustomerCompanyDrawer />
              <StandaloneCustomerUserDrawer />
              <AiDrawer />
            </ErrorBoundary>
          </AppLayout>
        </ThemeProvider>
      </GraphQLProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
