import { BorderProps, ColorProps } from '@chakra-ui/react';

import { HeaderCell } from 'components/charts/FullBreakdownTable/HeaderCell';
import { Thead, Tr } from 'components/uikit';
import {
  Direction,
  Sort,
  SortArray,
} from 'data/charts/models/ChartsApiRequest';
import { TableConfig } from 'data/charts/models/TableConfig';
import { AnalyticsEventType, useUserEvent } from 'utils/analytics';

type FullBreakdownTableHeaderProps<T> = {
  title?: string;
  config: TableConfig<T>;
  sorts?: SortArray;
  onSortChange?: (sorts: SortArray) => void;
  bgColor?: ColorProps['color'];
  borderBottomWidth?: BorderProps['borderBottomWidth'];
  borderBottomColor?: ColorProps['color'];
};

export function FullBreakdownTableHeader<T>({
  title,
  config,
  sorts = [],
  onSortChange,
  bgColor = 'grey.offWhite',
  borderBottomWidth = '1px',
  borderBottomColor = 'grey.border',
}: FullBreakdownTableHeaderProps<T>) {
  const trackEvent = useUserEvent();

  const handleSort = async (accessor: keyof T, direction: Direction) => {
    const newSortOption: Sort = { column: accessor as string, direction };
    onSortChange?.([newSortOption]);
    await trackEvent(AnalyticsEventType.BREAKDOWNS_SORT_CHANGED, {
      title: title,
      sort: newSortOption,
    });
  };

  async function removeSort(accessor: keyof T) {
    const filteredOptions = sorts.filter(
      (option) => option.column !== accessor,
    );
    onSortChange?.(filteredOptions);
    await trackEvent(AnalyticsEventType.BREAKDOWNS_SORT_CHANGED, {
      title: title,
    });
  }

  return (
    <Thead>
      <Tr
        h='40px'
        bgColor={bgColor}
        borderBottomWidth={borderBottomWidth}
        borderBottomColor={borderBottomColor}
      >
        {config
          .filter((cellConfig) => !cellConfig.hidden)
          .map((cellConfig, index) => {
            return (
              <HeaderCell
                key={index}
                cell={cellConfig}
                options={sorts}
                handleSort={handleSort}
                removeSort={removeSort}
              />
            );
          })}
      </Tr>
    </Thead>
  );
}
