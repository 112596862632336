import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const createdAccountAtom = atomWithStorage<boolean>(
  '@calliper/createdAccount',
  false,
);

export function useCreatedAccount() {
  return useAtomValue(createdAccountAtom);
}

export function useSetCreatedAccount() {
  return useSetAtom(createdAccountAtom);
}
