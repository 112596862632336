import { useCallback, useRef } from 'react';

import { FlexProps } from '@chakra-ui/react';
import { useAtom, WritableAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useStyledToast } from 'components/Toast';
import { Box, Flex, HintIcon, HStack, Text } from 'components/uikit';
import { ReferenceLineIcon } from 'components/uikit/icons/ReferenceLine';
import { useToggleChartLegend } from 'data/charts/hooks/useToggleChartLegend';
import { doubleClickHintAtom } from 'data/user/atoms/userHintAtoms';
import { AnalyticsEventType, useUserEvent } from 'utils/analytics';
import useDoubleClick from 'utils/hooks/useDoubleClick';
import { responsive } from 'utils/responsiveUtils';

export interface LegendKey {
  key: string;
  color: string;
  isReference?: boolean;
}
export type JustifyLegend = FlexProps['justifyContent'];

type LegendAtom = WritableAtom<string[], string[]>;

export interface ChartLegendProps {
  keys: LegendKey[];
  getKeyColor?: (key: string) => string; // Overrides colors
  onHighlight?: (key: string) => void;
  legendAtom?: LegendAtom;
  preventToggle?: boolean;
  justifyLegend?: JustifyLegend;
  marginTop?: FlexProps['marginTop'];
}

interface ToggeableChartLegendProps extends ChartLegendProps {
  legendAtom: LegendAtom;
}

function isToggeableChartLegendProps(
  props: ChartLegendProps,
): props is ToggeableChartLegendProps {
  return 'legendAtom' in props && props.legendAtom !== undefined;
}

function ToggeableLegendKey(props: {
  legendKey: string;
  isHidden: boolean;
  onSingleClick: (key: string) => void;
  onDoubleClick: (key: string) => void;
  handleHover: (key: string) => void;
  isReference: boolean | undefined;
  color: string;
}) {
  const {
    legendKey,
    isHidden,
    onSingleClick,
    onDoubleClick,
    handleHover,
    isReference,
    color,
  } = props;
  return (
    <HStack
      _hover={{ backgroundColor: 'grey.background' }}
      px='8px'
      borderRadius='2'
      cursor='pointer'
      opacity={isHidden ? '0.5' : '1'}
      onClick={() => onSingleClick(legendKey)}
      onDoubleClick={() => onDoubleClick(legendKey)}
      onMouseEnter={() => handleHover(legendKey)}
      onMouseLeave={() => handleHover('')}
    >
      {isReference ? (
        <ReferenceLineIcon w='12px' h='4px' fill={color} />
      ) : (
        <Box width='12px' height='12px' borderRadius='2' bg={color} />
      )}
      <Text
        fontSize={responsive('xxs', 'xs')}
        whiteSpace='nowrap'
        fontWeight='medium'
        overflowX='hidden'
        textOverflow='ellipsis'
        color='tertiaryText'
        userSelect='none'
      >
        {legendKey}
      </Text>
    </HStack>
  );
}

function ToggeableChartLegend({
  keys,
  legendAtom,
  getKeyColor,
  justifyLegend,
  onHighlight = () => {
    return;
  },
}: ToggeableChartLegendProps) {
  const trackEvent = useUserEvent();
  const analyticsEventQueue = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [doubleClickHint, setDoubleClickHint] = useAtom(doubleClickHintAtom);
  const { hiddenKeys, toggleKey, focusOnKey } = useToggleChartLegend(
    keys.map(({ key }) => key),
    legendAtom,
  );

  const { t } = useTranslation('mission-control');
  const toast = useStyledToast();

  const { handleClick, handleDoubleClick } = useDoubleClick({
    onClick: toggleKey,
    onDoubleClick: focusOnKey,
  });

  const onDoubleClick = (key: string) => {
    handleDoubleClick(key);
    trackEvent(AnalyticsEventType.CHART_LEGEND_FOCUSED);
    analyticsEventQueue.current.forEach((timeout) => clearTimeout(timeout));
    analyticsEventQueue.current = [];
  };

  const onSingleClick = useCallback(
    (key: string) => {
      handleClick(key);
      analyticsEventQueue.current.push(
        setTimeout(
          () => trackEvent(AnalyticsEventType.CHART_LEGEND_TOGGLED),
          1000,
        ),
      );

      if (!doubleClickHint) {
        toast({
          Icon: HintIcon,
          title: t('double_click_toast_title'),
          position: 'bottom-right',
          description: t('double_click_toast_description'),
          variant: 'SUCCESS',
          duration: 9000,
        });
        setDoubleClickHint(true);
      }
    },
    [t, handleClick, doubleClickHint, toast, setDoubleClickHint, trackEvent],
  );

  const handleHover = (key: string): void => {
    if (!hiddenKeys.includes(key)) onHighlight(key);
  };

  return (
    <Flex
      paddingX='24px'
      justifyContent={justifyLegend ?? 'flex-start'}
      direction='row'
      flexWrap='wrap'
      marginTop={responsive('0', '16px')}
      gap='4px'
    >
      {keys.map(({ key: legendKey, color, isReference }) => (
        <ToggeableLegendKey
          key={legendKey}
          legendKey={legendKey}
          isHidden={hiddenKeys && hiddenKeys.includes(legendKey)}
          onSingleClick={onSingleClick}
          onDoubleClick={onDoubleClick}
          handleHover={handleHover}
          isReference={isReference}
          color={getKeyColor ? getKeyColor(legendKey) : color}
        />
      ))}
    </Flex>
  );
}

export function ChartLegend(props: ChartLegendProps) {
  if (isToggeableChartLegendProps(props)) {
    return <ToggeableChartLegend {...props} />;
  } else {
    const { keys, getKeyColor, justifyLegend, marginTop } = props;
    return (
      <Flex
        paddingX={responsive('0', '24px')}
        justifyContent={justifyLegend ?? 'flex-start'}
        direction='row'
        flexWrap='wrap'
        marginTop={marginTop ?? '0'}
        gap='4px'
      >
        {keys.map(({ key, color, isReference }) => (
          <HStack
            key={key}
            _hover={{ backgroundColor: 'grey.background' }}
            px='8px'
            borderRadius='2'
            cursor='pointer'
          >
            {isReference ? (
              <ReferenceLineIcon
                w='12px'
                h='4px'
                fill={getKeyColor ? getKeyColor(key) : color}
              />
            ) : (
              <Box
                width='12px'
                height='12px'
                borderRadius='2'
                bg={getKeyColor ? getKeyColor(key) : color}
              />
            )}
            <Text
              fontSize='xs'
              whiteSpace='nowrap'
              fontWeight='medium'
              overflowX='hidden'
              textOverflow='ellipsis'
              color='grey.tertiaryText'
              userSelect='none'
            >
              {key}
            </Text>
          </HStack>
        ))}
      </Flex>
    );
  }
}
