import { useCallback } from 'react';

import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SidebarMenuTooltip } from 'components/SidebarMenu/SidebarMenuTooltip';
import { useStyledToast } from 'components/Toast';
import {
  Button,
  CopyIcon,
  EnvelopeIcon,
  Flex,
  HStack,
  SettingsIcon,
  Spacer,
  Text,
  UserIcon,
  UserPlusIcon,
} from 'components/uikit';
import { CompanyIcon } from 'components/uikit/icons/Company';
import { APP_URL } from 'data/constants';
import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';
import paths from 'utils/paths';

export function SidebarMenuUserAndSettingsSection() {
  const { t } = useTranslation('sidebar');
  const { user, currentProfile } = useCurrentProfile();
  const avatarUrl = user?.avatar ?? undefined;
  const avatarName = `${user?.firstName} ${user?.lastName}` ?? undefined;
  const toast = useStyledToast();
  const onCopy = useCallback(async () => {
    await navigator.clipboard.writeText(APP_URL || '');
    toast({
      id: 'invite-toast',
      title: t('toast_invite_copied_title'),
      description: (
        <Text
          fontSize='sm'
          color='grey.tertiaryText'
          lineHeight='short'
          mt='2px'
        >
          {t('toast_invite_copied_description')}
        </Text>
      ),
      variant: 'SUCCESS',
      Icon: UserPlusIcon,
      duration: 3000,
      position: 'bottom-right',
    });
  }, [t, toast]);

  return (
    <Flex
      display='block'
      bgColor='grey.white.dark'
      padding='8px'
      maxW='100%'
      width='168px'
    >
      <HStack height='48px' px='8px'>
        <HStack>
          <Flex
            borderRadius='100'
            h='24px'
            w='24px'
            borderWidth='2px'
            borderStyle='solid'
            borderColor='grey.tertiaryText'
            role='button'
            alignItems='center'
            justifyContent='center'
            color='grey.white'
          >
            <Avatar name={avatarName} src={avatarUrl} size='sm' />
          </Flex>
          <Text
            width='70px'
            fontSize='14px'
            color='grey.secondaryText.dark'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
          >
            {user?.firstName ?? ''}
          </Text>
        </HStack>
        <Spacer />
        <SidebarMenuTooltip
          offset={[0, 20]}
          trigger={
            <SettingsIcon
              w='16px'
              h='16px'
              stroke='grey.secondaryText.dark'
              fill='none'
            />
          }
          body={
            <Flex
              alignItems='flex-start'
              gap='8px'
              flexDirection='column'
              borderRadius='4px'
              overflow='hidden'
            >
              <Flex
                px='16px'
                py='12px'
                alignItems='center'
                columnGap='8px'
                background={'grey.offWhite.light'}
                width='100%'
                borderBottom='1px solid'
                borderBottomColor={'grey.border.light'}
              >
                <Button
                  color='secondary.button.light'
                  fontSize='sm'
                  variant='link'
                  onClick={onCopy}
                  _focus={{
                    ring: 0,
                    outline: 'none',
                  }}
                >
                  <Flex alignItems='center' columnGap='8px' fontSize='sm'>
                    <CopyIcon stroke='currentColor' boxSize='16px' />
                    {t('integrations:toast_invite_link')}
                  </Flex>
                </Button>
              </Flex>
              <Flex
                mx='16px'
                alignItems='flex-start'
                gap='8px'
                flexDirection='column'
                color='grey.border.dark'
              >
                <Flex alignItems='center' columnGap='8px' fontSize='sm'>
                  <UserIcon boxSize='16px' />
                  {`${user?.firstName} ${user?.lastName}`}
                </Flex>
                <Flex alignItems='center' columnGap='8px' fontSize='sm'>
                  <EnvelopeIcon boxSize='16px' />
                  {user?.email}
                </Flex>
                <Flex alignItems='center' columnGap='8px' fontSize='sm'>
                  <CompanyIcon boxSize='16px' />
                  {currentProfile?.company.name}
                </Flex>
              </Flex>
              <Flex
                px='16px'
                py='8px'
                alignItems='flex-start'
                gap='8px'
                flexDirection='column'
                align-items='stretch'
                width='100%'
              >
                <Button
                  as='a'
                  backgroundColor={'secondary.button.light'}
                  color={'grey.white.light'}
                  _hover={{
                    backgroundColor: 'secondary.text.light',
                  }}
                  _focus={{
                    ring: 0,
                    outline: 'none',
                  }}
                  href={paths.logout}
                  width='100%'
                >
                  {t('log_out')}
                </Button>
              </Flex>
            </Flex>
          }
        />
      </HStack>
    </Flex>
  );
}
