import { createIcon } from '@chakra-ui/react';

export const LinkedinMenuIcon = createIcon({
  displayName: 'LinkedinMenuIcon',
  viewBox: '0 0 40 40',
  path: (
    <>
      <circle cx='20' cy='20.25' r='20' fill='#0277B5' />
      <g clipPath='url(#clip0_19114_2042)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.2553 28.1824H15.5676V17.5332H12.2553V28.1824ZM13.9146 12.25C14.2933 12.25 14.6635 12.3621 14.9784 12.5722C15.2932 12.7823 15.5386 13.0809 15.6835 13.4303C15.8284 13.7796 15.8664 14.164 15.7925 14.5349C15.7186 14.9058 15.5363 15.2464 15.2685 15.5138C15.0007 15.7812 14.6596 15.9633 14.2882 16.037C13.9168 16.1108 13.5318 16.073 13.1819 15.9283C12.8321 15.7836 12.5331 15.5385 12.3227 15.2241C12.1123 14.9097 12 14.54 12 14.1619C12 13.6548 12.2017 13.1686 12.5608 12.81C12.9199 12.4515 13.4068 12.25 13.9146 12.25Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.6484 17.5332H20.8395V18.9862H20.8842C21.2026 18.4425 21.6628 17.9952 22.2158 17.6922C22.7688 17.3892 23.3938 17.2417 24.0242 17.2655C27.3812 17.2655 28.0002 19.4705 28.0002 22.3639V28.2079H24.6816V23.0266C24.6816 21.7903 24.6816 20.2035 22.9583 20.2035C21.2352 20.2035 20.9735 21.5481 20.9735 22.9375V28.2079H17.6484V17.5587'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_19114_2042'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(12 12.25)'
          />
        </clipPath>
      </defs>
    </>
  ),
});
