import { fetchCustomersByName } from 'api/rest/customer/fetchCustomersByName';
import { CustomersAndBlocksSearchResult } from 'data/commandPanel/models';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const commandPanelOptionsAtom =
  getDataAtom<CustomersAndBlocksSearchResult>({
    COMPANY: [],
    USER: [],
    INSIGHT: [],
    METRIC: [],
    LEADERBOARD: [],
  });

export const fetchCommandPanelOptionsAtom = getDataFetchingAtom(
  commandPanelOptionsAtom,
  fetchCustomersByName,
);
