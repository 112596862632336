import { ReactNode } from 'react';

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';

import { CheckboxIcon, CheckboxIndeterminateIcon } from '../icons';
import { Text } from '../reexport';

export { useRadio, useRadioGroup } from '@chakra-ui/react';

type CheckboxProps = Omit<ChakraCheckboxProps, 'children'> & {
  label?: ReactNode;
  description?: ReactNode;
};

function CustomCheckBoxIcon(props: CheckboxProps) {
  const { isChecked, isIndeterminate } = props;

  return isChecked ? (
    <CheckboxIcon height='9px' width='12px' />
  ) : isIndeterminate ? (
    <CheckboxIndeterminateIcon height='14px' width='14px' />
  ) : null;
}

export function Checkbox(props: CheckboxProps) {
  return (
    <ChakraCheckbox
      icon={<CustomCheckBoxIcon {...props} />}
      {...props}
      background={props?.isIndeterminate ? 'grey.white' : props?.background}
    >
      {props.label}
      {props.description ? (
        <Text color='grey.tertiaryText' fontWeight='regular'>
          {props.description}
        </Text>
      ) : null}
    </ChakraCheckbox>
  );
}
