import { CloseIcon, IconButton } from 'components/uikit';
import { useCloseSideMenu } from 'data/layout/hooks/useNavHeight';

export function SidebarMenuMobileCloseButton() {
  const closeSideMenu = useCloseSideMenu();

  return (
    <IconButton
      aria-label='CloseMenu'
      paddingInline='0'
      variant='none'
      minWidth='32px'
      width='32px'
      height='32px'
      background={'grey.offWhite.dark'}
      borderColor={'grey.border.dark'}
      borderStartWidth='1px'
      borderEndWidth='1px'
      borderTopWidth='1px'
      borderBottomWidth='1px'
      onClick={closeSideMenu}
      icon={
        <CloseIcon
          width='24px'
          height='24px'
          color={'#848A93'}
          stroke={'#848A93'}
          fill={'#848A93'}
        />
      }
    />
  );
}
