import { useCallback } from 'react';

import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { AlertTriangleIcon, Button, Flex, Text } from 'components/uikit';
import { ReloadIcon } from 'components/uikit/icons/Reload';
import { pageReloadAttemptsAtom } from 'data/loadErrors/atoms/pageReloadAttempts';
import { useIsMobile } from 'utils/hooks/screenBreakpoints';
import { reloadPage } from 'utils/navigation';

interface Props {
  isInsightError?: boolean;
}

export function LoadingErrorBanner({ isInsightError }: Props) {
  const { t } = useTranslation('common');
  const registerReload = useSetAtom(pageReloadAttemptsAtom);
  const isMobile = useIsMobile();

  const registerAndReloadPage = useCallback(() => {
    registerReload(new Date());
    reloadPage();
  }, [registerReload]);

  return (
    <Flex
      height={isMobile ? 'auto' : '48px'}
      background={'warning.background'}
      direction='row'
      align='center'
      padding={isInsightError ? '0 24px 0 20px' : '0 32px'}
      borderLeftWidth='4px'
      borderLeftColor={'warning.button'}
      justifyContent='space-between'
    >
      <Flex align='center' gap='8px' p={isMobile ? '20px 0' : 'auto'}>
        <AlertTriangleIcon height='16px' width='16px' stroke={'warning.text'} />
        <Text
          color={'warning.text'}
          fontSize={isInsightError ? 'sm' : 'md'}
          fontWeight='medium'
        >
          {t(
            isInsightError
              ? 'loading_error_banner_insight_message'
              : 'loading_error_banner_message',
          )}
        </Text>
      </Flex>
      <Button
        aria-label='Reload'
        position='static'
        display='flex'
        variant='unstyled'
        height='32px'
        backgroundColor={'warning.text'}
        padding='6px 12px'
        gap='8px'
        borderRadius='2px'
        _hover={{
          backgroundColor: 'warning.button',
        }}
        onClick={registerAndReloadPage}
      >
        {!isMobile && (
          <Text fontWeight='medium' fontSize='sm' color={'warning.background'}>
            {t('loading_error_banner_reload')}
          </Text>
        )}
        <ReloadIcon stroke={'warning.background'} height='20px' width='20px' />
      </Button>
    </Flex>
  );
}
