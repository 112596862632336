import { createIcon } from '@chakra-ui/react';

export const ReloadIcon = createIcon({
  displayName: 'ReloadIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M7.12221 16.4728C9.12149 17.3598 11.5027 17.3116 13.5419 16.1343C16.9298 14.1783 18.0906 9.84618 16.1345 6.45828L15.9262 6.09743M3.86546 13.5417C1.90945 10.1538 3.07024 5.8217 6.45814 3.8657C8.4973 2.68839 10.8785 2.64022 12.8778 3.52719M2.07788 13.6114L4.35459 14.2215L4.96463 11.9448M15.0356 8.05479L15.6456 5.77808L17.9223 6.38813'
      fill='none'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
