import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { FullBreakdownByUsersRow } from 'data/charts/atoms/product/users/breakdowns';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const breakdownByUsersLargestDecreaseInActivityAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersRow,
    [{ column: 'activeSessionsDelta', direction: 'ASC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByUsersLargestIncreaseInActivityAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersRow,
    [{ column: 'activeSessionsDelta', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByMostEngagedUsersAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersRow,
    [{ column: 'engagedSessionsL30', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByMostActiveUsersAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersRow,
    [{ column: 'activeSessionsL30', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByNewUsersAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByUsersRow,
  [{ column: 'joinDate', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
);
