import React, { useEffect } from 'react';

import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';

import { useIsEnterprise } from 'utils/useIsEnterprise';

import { theme } from './theme';

interface ThemeProviderProps extends Omit<ChakraProviderProps, 'theme'> {
  children: React.ReactNode;
}

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  const isEnterprise = useIsEnterprise();
  useEffect(() => {
    if (isEnterprise) {
      document.documentElement.style.setProperty(
        '--chakra-colors-primary-button',
        '#FDDD35',
      );
      document.documentElement.style.setProperty(
        '--chakra-colors-primary-text',
        '#fee772',
      );
      document.documentElement.style.setProperty(
        '--chakra-colors-grey-primaryButtonText',
        '#121926',
      );
    }
  }, [isEnterprise]);
  return (
    <ChakraProvider theme={theme} {...props}>
      {children}
    </ChakraProvider>
  );
}
