import { PropsWithChildren } from 'react';

import { Switch } from '@chakra-ui/react';

import { Flex } from 'components/uikit/reexport';

interface CardContentSwitchProps {
  switchOn: boolean;
  toggleSwitch: () => void;
}

export function CardContentSwitch({
  switchOn,
  toggleSwitch,
  children,
}: PropsWithChildren<CardContentSwitchProps>) {
  return (
    <Flex
      backgroundColor='grey.offWhite'
      borderY='1px solid'
      borderColor='grey.border'
      alignItems='center'
      padding='12px 24px'
      gap='16px'
    >
      <Switch
        size='md'
        isChecked={switchOn}
        onChange={toggleSwitch}
        variant={'insightFocus'}
      />
      {children}
    </Flex>
  );
}
