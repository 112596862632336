import { z } from 'zod';

import { AggregationPeriod } from '../blocks/models/ChartConfig';
import { TimeSeries } from '../responseModels/TimeSeries';

export const InsightActionType = z.enum(['QUICK_WIN', 'STRATEGIC_BET']);
export const InsightAction = z.object({
  actionType: InsightActionType,
  description: z.string(),
});
export type InsightAction = z.infer<typeof InsightAction>;

export const AiChartDataConfig = z.object({
  chartName: z.string(),
  timeRange: z.object({
    start: z.coerce.date(),
    end: z.coerce.date(),
    granularity: AggregationPeriod,
  }),
  filters: z
    .array(
      z.object({
        field: z.string(),
        operator: z.string(),
        value: z.string(),
      }),
    )
    .default([]),
  split: z
    .object({
      top: z.number(),
      column: z.string(),
    })
    .optional(),
});
export type AiChartDataConfig = z.infer<typeof AiChartDataConfig>;

export const AiTimeseriesChartData = z.object({
  data: z.record(TimeSeries),
  config: AiChartDataConfig,
  type: z.literal('TIMESERIES'),
});
export type AiTimeseriesChartData = z.infer<typeof AiTimeseriesChartData>;

export const AiBreakdownRow = z.record(
  z.union([z.string(), z.number(), z.boolean(), z.unknown().array()]),
);

export const AiBreakdownData = z.object({
  data: z.object({
    rows: AiBreakdownRow.array(),
    totalCount: z.number(),
  }),
  config: AiChartDataConfig,
  type: z.literal('BREAKDOWN'),
});
export type AiBreakdownData = z.infer<typeof AiBreakdownData>;

const AiChartData = z.discriminatedUnion('type', [
  AiTimeseriesChartData,
  AiBreakdownData,
]);
export type AiChartData = z.infer<typeof AiChartData>;

export function isTimeSeries(data: AiChartData): data is AiTimeseriesChartData {
  return data.type === 'TIMESERIES';
}

export function isBreakdown(data: AiChartData): data is AiBreakdownData {
  return data.type === 'BREAKDOWN';
}

export const Answer = z.object({
  answer: z.string(),
  description: z.string().optional(),
  actions: InsightAction.array().optional(),
  chartData: AiChartData.optional(),
});
export type Answer = z.infer<typeof Answer>;

export const AiContent = z.object({
  text: z.string(),
  description: z.string().optional(),
  actions: InsightAction.array().optional(),
  chartData: AiChartData.optional(),
});
export type AiContent = z.infer<typeof AiContent>;

export const ChatAnswer = z.object({
  content: AiContent,
  chatId: z.string(),
});
export type ChatAnswer = z.infer<typeof ChatAnswer>;

export const FollowUpQuestionsResponse = z.object({
  followUpQuestions: z.string().array(),
});
export type FollowUpQuestionsResponse = z.infer<
  typeof FollowUpQuestionsResponse
>;

export const QuestionWithAnswer = z.object({
  prompt: z.string(),
  answer: Answer.optional(),
  error: z.string().optional(),
});
export type QuestionWithAnswer = z.infer<typeof QuestionWithAnswer>;

export const PublicChatRole = z.enum(['user', 'system']);
export const PublicChatMessage = z.object({
  id: z.string(),
  chatId: z.string(),
  role: PublicChatRole,
  content: AiContent.optional(),
  insertedAt: z.coerce.date(),
});
export type PublicChatMessage = z.infer<typeof PublicChatMessage>;

export const ChatType = z.enum(['CHAT', 'INSIGHT']);
export type ChatType = z.infer<typeof ChatType>;

export const Chat = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  insightId: z.string().optional(),
  insertedAt: z.coerce.date(),
  latestMessages: PublicChatMessage.array(),
  totalMessages: z.number(),
  type: ChatType.default('CHAT'),
});
export type Chat = z.infer<typeof Chat>;

export const ChatContext = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  description: z.string().nullish(),
  insightId: z.string().optional(),
  type: ChatType.default('CHAT'),
});
export type ChatContext = z.infer<typeof ChatContext>;
