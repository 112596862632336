import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Goal, CreateGoalRequest } from 'data/goals/models/Goal';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { GOALS_API_URL } from './goalsUrls';

export async function createGoal(data: CreateGoalRequest) {
  const result = await authorisedFetch(GOALS_API_URL, {
    method: 'POST',
    body: JSON.stringify(data),
  });

  const body = await result.json();
  if (result.status > 399) {
    throw new Error(`Failed to fetch review configs: ${result.status}`);
  }

  return parseItemStrict(Goal, body);
}
