import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';

export function useCustomerActiveDaysBlocks() {
  const { blocks } = useCustomerBreakdownBlocks();
  const chartBlock = blocks.find(
    (block) => block.uniqueName === 'CUSTOMER_USER_PRODUCT_ACTIVE_DAYS_CHART',
  );

  return { chartBlock, blocksExist: !!chartBlock };
}
