import { Getter } from 'jotai';
import { ZodType, ZodTypeDef } from 'zod';

import { fetchClickableCardBreakdown } from 'api/rest/charts/fetchClickableCardBreakdown';
import { FormattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { ClickableCardBreakdownResponse } from 'data/charts/models/ChartsApiResponse';
import { DataAtom, getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataRefreshingAtom,
  WritableDataFetchingAtom,
} from 'utils/atoms/dataFetchingAtom';
import { getTimeRangeFromDatePickerAtom } from 'utils/atoms/getTimeRangeFromDatePickerAtom';

export type ClickableCardBreakdownPayload<T> = {
  data: ClickableCardBreakdownResponse<T, unknown>;
};

export type ClickableCardBreakdownAtom<T> = WritableDataFetchingAtom<
  ClickableCardBreakdownPayload<T>,
  string
>;

export type BreakdownAtoms<Output> = {
  dataAtom: DataAtom<ClickableCardBreakdownPayload<Output>>;
  fetchingAtom: ClickableCardBreakdownAtom<Output>;
};

export function buildClickableCardBreakdownAtom<
  Input,
  Output,
  T extends ZodType<Output, ZodTypeDef, Input>,
>(
  breakdownRow: T,
  datePickerAtom: FormattedDatePickerAtom,
  filters: Filter[] = [],
): BreakdownAtoms<Output> {
  const dataAtom = getDataAtom<ClickableCardBreakdownPayload<Output>>({
    data: {
      rows: [],
      totalCount: 0,
    },
  });

  const fetchBreakdown = async (
    blockId: string,
    get: Getter,
  ): Promise<ClickableCardBreakdownPayload<Output>> => {
    const timeRange = getTimeRangeFromDatePickerAtom(get, datePickerAtom);

    const chartConfig = {
      filters,
      timeRange,
    };

    const response = await fetchClickableCardBreakdown(
      blockId,
      chartConfig,
      breakdownRow,
    );

    return { data: response.data };
  };

  const fetchingAtom = getDataRefreshingAtom<
    ClickableCardBreakdownPayload<Output>,
    string
  >(dataAtom, fetchBreakdown);

  return { dataAtom, fetchingAtom };
}
