import { atom, WritableAtom } from 'jotai';

import {
  CategoryValuesRequest,
  fetchFilters,
  fetchFilterValues,
} from 'api/rest/charts/fetchFilters';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { Category, CategoryValues } from 'data/charts/models/filters';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export type FilterUpdate = { filters: Filter[]; blockId: string };

export function getFilterAtoms(
  initialFilters: Filter[],
  fetchData: WritableAtom<unknown, string>,
) {
  const appliedFiltersAtom = atom<Filter[]>(initialFilters);
  const filtersAtom = getDataAtom<{ categories: Category[] }>({
    categories: [],
  });
  const filterValuesAtom = getDataAtom<CategoryValues>({
    blockId: '',
    category: '',
    values: [],
    page: 0,
    pageSize: 10,
    totalPages: 0,
  });
  const applyFiltersAtom = atom<Filter[], FilterUpdate, void>(
    (get) => get(appliedFiltersAtom),
    (get, set, { filters, blockId }) => {
      set(appliedFiltersAtom, filters);
      set(fetchData, blockId);
    },
  );
  const fetchFiltersAtom = getDataFetchingAtom(filtersAtom, fetchFilters);
  const fetchFilterValuesAtom = getDataFetchingAtom(
    filterValuesAtom,
    async ({
      blockId,
      chartConfig,
    }: {
      blockId: string;
      chartConfig: CategoryValuesRequest;
    }) => {
      const result = await fetchFilterValues({ blockId, chartConfig });

      return {
        blockId,
        category: chartConfig.category,
        ...result,
      };
    },
  );

  const fetchNextFilterValuesAtom = getDataFetchingAtom<
    CategoryValues | null,
    undefined
  >(filterValuesAtom, async (_, get) => {
    const originalConfig = get(filterValuesAtom);
    if (!originalConfig) return originalConfig;
    const { blockId, category } = originalConfig;
    if (originalConfig.page > originalConfig.totalPages) return originalConfig;

    const { values, page, pageSize, totalPages } = await fetchFilterValues({
      blockId,
      chartConfig: {
        category,
        pageRequest: {
          page: originalConfig.page + 1,
          size: originalConfig.pageSize,
        },
      },
    });

    return {
      ...originalConfig,
      values: originalConfig.values.concat(values),
      page,
      pageSize,
      totalPages,
    };
  });

  return {
    fetchFiltersAtom,
    fetchFilterValuesAtom,
    fetchNextFilterValuesAtom,
    appliedFiltersAtom,
    applyFiltersAtom,
  };
}
