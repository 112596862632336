export function getIntersection(array1: string[], array2: string[]): string[] {
  const set1 = new Set(array1);
  const set2 = new Set(array2);
  const intersection: string[] = [];

  for (const value of set1) {
    if (set2.has(value)) {
      intersection.push(value);
    }
  }

  return intersection;
}

export function zipArrays<T, U>(array1: T[], array2: U[]): [T, U][] {
  const result: [T, U][] = [];

  if (array1.length !== array2.length) {
    console.error('Arrays must be of equal length');
  }
  const maxItems = Math.min(array1.length, array2.length);

  for (let i = 0; i < maxItems; i++) {
    // Casting the type here is safe because we only iterate up to the length of the shorter array
    result.push([array1[i] as T, array2[i] as U]);
  }

  return result;
}
