import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { CommandBarListItem } from 'components/CommandPanel/Modal/Search/CommandBarListItem';
import { CommandBarSectionHeader } from 'components/CommandPanel/Modal/Search/CommandBarSectionHeader';
import { Box, Button, Skeleton, Text, VStack } from 'components/uikit';
import { CloudSearchRed } from 'components/uikit/icons/CloudSearchRed';
import { ListWithSections } from 'components/uikit/List/ListWithSections';
import {
  CommandPanelOption,
  CommandPanelOptionType,
  CommandPanelSearchResult,
} from 'data/commandPanel/models';
import { useOpenIntercom } from 'utils/intercom/useOpenIntercom';
import { FetchStatus } from 'utils/requests/types';
import { isLoaded } from 'utils/types';

type CommandPanelOptionsProps = {
  options: CommandPanelSearchResult;
  fetchStatus: FetchStatus;
  onClose: () => void;
  onSelectAiMode: () => void;
};

export const CommandPanelOptions: FC<CommandPanelOptionsProps> = ({
  options,
  fetchStatus,
  onClose,
  onSelectAiMode,
}) => {
  const { t } = useTranslation('command-panel');
  const openIntercom = useOpenIntercom();
  if (fetchStatus == 'LOADING') {
    return (
      <VStack h='480px' align='stretch' alignItems='center' p='12px'>
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
        <Skeleton height='40px' width='100%' />
      </VStack>
    );
  }
  const noOptionsFound = Object.entries(options).every(
    ([, option]) => option.length == 0,
  );
  if (isLoaded(fetchStatus) && noOptionsFound) {
    return (
      <VStack
        h='480px'
        align='stretch'
        justifyContent='center'
        alignItems='center'
      >
        <CloudSearchRed width='160px' height='140px' fill='red' />
        <Text fontSize='16px' color='grey.primaryText' fontWeight='600'>
          {t('no_results')}
        </Text>
        <Text fontSize='14px' fontWeight='400' color='grey.secondaryText'>
          {t('no_results_caption')}
        </Text>
        <Button
          alignSelf='center'
          variant='primaryGhost'
          fontSize='12px'
          fontWeight='600'
          onClick={openIntercom}
        >
          {t('contact_support')}
        </Button>
      </VStack>
    );
  }
  return (
    <Box height='480px' overflowY='scroll'>
      <ListWithSections<CommandPanelOptionType, CommandPanelOption>
        items={options}
        renderSectionHeader={(header) => (
          <CommandBarSectionHeader
            title={t(`groups.${header.toLowerCase()}`)}
            isTag={header === 'BETA'}
          />
        )}
        hotkeysActive
        renderItem={(item, isSelected, selectItem, removeSelection, index) => (
          <CommandBarListItem
            option={item}
            isSelected={isSelected}
            selectItem={() => {
              if (item.type === 'ACTION' && item.action === 'ASK_AI') {
                onSelectAiMode();
              }
              selectItem();
            }}
            index={index}
            onClose={onClose}
          />
        )}
      />
    </Box>
  );
};
