import { ReactElement } from 'react';

import { BoxProps } from '@chakra-ui/react';

import { Box, Text } from './reexport';

const KEY_STYLE = {
  normal: {
    bgColor: 'grey.background',
    color: 'grey.tertiaryText',
  },
  hover: {
    bgColor: 'grey.offWhite',
    color: 'grey.secondaryText',
  },
  disabled: {
    bgColor: 'grey.offWhite',
    color: 'grey.tertiaryText',
  },
  selected: {
    bgColor: 'grey.white',
    color: 'secondary.button',
  },
} as const;

const KEY_STYLE_DARK = {
  normal: {
    bgColor: 'grey.background.dark',
    color: 'grey.tertiaryText.dark',
  },
  hover: {
    bgColor: 'grey.offWhite.dark',
    color: 'grey.secondaryText.dark',
  },
  disabled: {
    bgColor: 'grey.offWhite.dark',
    color: 'grey.tertiaryText.dark',
  },
  selected: {
    bgColor: 'grey.white.dark',
    color: 'secondary.button.dark',
  },
} as const;

export type KeyStyle = keyof typeof KEY_STYLE;

type KeyProps = {
  element: ReactElement | string;
  // TODO: remove this prop and fix styles
  enforceDark?: boolean;
  isOutlined?: boolean;
  style?: KeyStyle;
  size?: `${number}px`;
  allowGrowWidth?: boolean;
  borderRadius?: string;
} & Pick<BoxProps, 'opacity'>;

export function Key({
  element,
  allowGrowWidth = false,
  enforceDark = false,
  style = 'normal',
  size = '18px',
  opacity,
  borderRadius = '4px',
}: KeyProps) {
  const { bgColor, color } = enforceDark
    ? KEY_STYLE_DARK[style]
    : KEY_STYLE[style];

  const getElement = () => {
    if (typeof element === 'string') {
      return (
        <Text fontSize='xs' color={color} fontWeight='medium'>
          {element}
        </Text>
      );
    } else {
      return element;
    }
  };

  return (
    <Box
      bgColor={bgColor}
      width={allowGrowWidth ? undefined : size}
      minWidth={size}
      height={size}
      borderRadius={borderRadius}
      justifyContent='center'
      alignItems='center'
      display='flex'
      padding={allowGrowWidth ? '0 8px' : undefined}
      whiteSpace='nowrap'
      opacity={opacity}
    >
      {getElement()}
    </Box>
  );
}
