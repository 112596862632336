import { createIcon } from '@chakra-ui/react';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M6 9L12 15L18 9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  ),
});
