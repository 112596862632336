import { ReactNode, useCallback } from 'react';

import { DateRangeControlOptions } from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownTable } from 'components/charts/FullBreakdownTable/FullBreakdownTable';
import { FullBreakdownTablePaginationRow } from 'components/charts/FullBreakdownTable/paginated/FullBreakdownTablePaginationRow';
import {
  Filter,
  PageRequest,
  SortArray,
} from 'data/charts/models/ChartsApiRequest';
import { PaginatedBreakdownResponse } from 'data/charts/models/ChartsApiResponse';
import { RowConfig, TableConfig } from 'data/charts/models/TableConfig';
import { AnalyticsEventType, useUserEvent } from 'utils/analytics';
import { TrackingLabel } from 'utils/analytics/trackingLabels';
import { FetchStatus } from 'utils/requests/types';

type Props<T> = {
  title: string;
  subTitle?: string;
  trackingLabel: TrackingLabel;
  fetchStatus: FetchStatus;
  config: TableConfig<T>;
  rowConfig?: RowConfig<T>;
  isDemo: boolean;
  data: PaginatedBreakdownResponse<T, unknown>;
  sorts?: SortArray;
  filters?: Filter[];
  resetFilters?: Record<keyof T, () => void>;
  onSortChange?: (sorts: SortArray) => void;
  onResetTable: (() => void) | undefined;
  pagination: PageRequest;
  onPaginationChange: (pageRequest: PageRequest) => void;
  width?: string;
  controls?: ReactNode;
};

export function FullBreakdownTablePaginated<T>({
  config,
  trackingLabel,
  data,
  fetchStatus,
  isDemo,
  title,
  subTitle,
  sorts,
  filters,
  resetFilters,
  onSortChange,
  pagination,
  onPaginationChange,
  onResetTable,
  width = 'auto',
  rowConfig,
  controls,
}: Props<T>) {
  const trackEvent = useUserEvent();
  const onPaginationChangeCallback = useCallback(
    async (pageRequest: PageRequest) => {
      onPaginationChange?.(pageRequest);
      await trackEvent(AnalyticsEventType.BREAKDOWNS_PAGINATION_CHANGED, {
        pageRequest: pageRequest,
        breakdown: title,
      });
    },
    [onPaginationChange, title, trackEvent],
  );

  return (
    <FullBreakdownTable
      trackingLabel={trackingLabel}
      fetchStatus={fetchStatus}
      title={title}
      subTitle={subTitle}
      isDemo={isDemo}
      sorts={sorts}
      filters={filters}
      resetFilters={resetFilters}
      controls={controls}
      onSortChange={onSortChange}
      numRows={pagination.size}
      config={config}
      rowConfig={rowConfig}
      data={data}
      onResetTable={onResetTable}
      width={width}
      footer={
        <FullBreakdownTablePaginationRow
          page={pagination.page}
          size={pagination.size}
          onPaginationChange={onPaginationChangeCallback}
          total={data.totalPages}
        />
      }
      dateRangeControl={DateRangeControlOptions.DATE_PICKER_FULL}
    />
  );
}
