import { createIcon } from '@chakra-ui/react';

export const HintIcon = createIcon({
  displayName: 'HintIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <rect width='32' height='32' rx='16' fill='#D1FADF' />
      <path
        d='M16.6667 9.33337L10 17.3334H16L15.3333 22.6667L22 14.6667H16L16.6667 9.33337Z'
        fill='transparent'
        stroke='#027A48'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
