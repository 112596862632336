import { createIcon } from '@chakra-ui/react';

export const ActivityIcon = createIcon({
  displayName: 'ActivityIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M22 12H18L15 21L9 3L6 12H2'
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke={'currentColor'}
      />
    </>
  ),
});
