export const shadows = {
  '-1': '0px 1px 2px rgba(16, 24, 40, 0.1), inset 0px 2px 4px rgba(16, 24, 40, 0.1)',
  '1': '0px 1px 2px rgba(16, 24, 40, 0.1), 0px 2px 4px rgba(16, 24, 40, 0.05)',
  '2': '0px 4px 8px rgba(16, 24, 40, 0.1), 0px 1px 3px rgba(16, 24, 40, 0.1)',
  '3': '0px 1px 2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.05)',
  '4': '0px 2px 4px rgba(16, 24, 40, 0.12), 0px 1px 2px rgba(16, 24, 40, 0.1), 0px 10px 15px -5px rgba(16, 24, 40, 0.1), 0px 20px 25px -5px rgba(16, 24, 40, 0.1)',
  bottom: '0px 2px 4px rgba(16, 24, 40, 0.12)',
  focus:
    '0px 1px 2px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF, inset 0px 2px 4px rgba(16, 24, 40, 0.1)',
  tertiary:
    '0px 1px 2px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(105, 56, 239, 0.25), inset 0px 2px 4px rgba(16, 24, 40, 0.1)',
  success:
    '0px 0px 0px 2px #D1FADF, 0px 1px 2px rgba(16, 24, 40, 0.1), inset 0px 2px 4px rgba(16, 24, 40, 0.1)',
  error:
    '0px 0px 0px 2px #FEE4E2, 0px 1px 2px rgba(16, 24, 40, 0.1), inset 0px 2px 4px rgba(16, 24, 40, 0.1)',
  enterprise: '0px 0px 0px 4px #FDDD3540, 0px 1px 2px 0px #FDDD350D',
};
