import { useTranslation } from 'react-i18next';

import { useSharingBlockContext } from 'components/Sharing/sharingBlockContextAtom';
import {
  SharingBlockModalBodyDefaultActionsContainer,
  SharingBlockModalBodyDefaultContainer,
  SharingBlockModalBodyDefaultContentContainer,
} from 'components/Sharing/SharingBlockModalBodyDefaultContainers';
import { useCopyInternalLink } from 'components/Sharing/SharingOptions/useCopyInternalLink';
import { Button, CopyIcon, Heading, Text } from 'components/uikit';
import { AnalyticsEventType, useUserEvent } from 'utils/analytics';

export function ShareWithTeamOptionBody() {
  const { t } = useTranslation('common', {
    keyPrefix: 'sharing_block_modal_share_with_team',
  });
  const copyInternalLink = useCopyInternalLink();
  const { closeSharingModal, shareBlockConfig } = useSharingBlockContext();
  const trackEvent = useUserEvent();

  const onCopyLinkClick = async () => {
    await copyInternalLink();
    trackEvent(AnalyticsEventType.PAGE_SHARE_LINK_COPIED, {
      sharingOption: 'SHARE_WITH_TEAM',
      blockUniqueName: shareBlockConfig?.blockName,
    });
    closeSharingModal?.();
  };

  return (
    <SharingBlockModalBodyDefaultContainer>
      <SharingBlockModalBodyDefaultContentContainer>
        <Heading as='h1' fontSize='xl'>
          {t('body_title')}
        </Heading>
        <Text color='grey.secondaryText'>{t('body_paragraph_1')}</Text>
        <Text color='grey.secondaryText'>{t('body_paragraph_2')}</Text>
      </SharingBlockModalBodyDefaultContentContainer>
      <SharingBlockModalBodyDefaultActionsContainer>
        <Button variant='primary' height='40px' onClick={onCopyLinkClick}>
          <CopyIcon boxSize='16px' stroke='grey.white' mr='9px' />
          {t('copy_link_button_caption')}
        </Button>
      </SharingBlockModalBodyDefaultActionsContainer>
    </SharingBlockModalBodyDefaultContainer>
  );
}
