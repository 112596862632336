import { fetchCustomerProducts } from 'api/rest/charts/fetchCustomerProducts';
import { CustomerProducts } from 'data/charts/models/filters';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const customerProductsAtom = getDataAtom<CustomerProducts>({
  products: [],
});

export const fetchCustomerProductsAtom = getDataFetchingAtom<
  CustomerProducts,
  undefined
>(customerProductsAtom, fetchCustomerProducts);
