import { createIcon } from '@chakra-ui/react';

export const ArrowUpRightIcon = createIcon({
  displayName: 'ArrowUpRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M7 17L17 7M17 7H7M17 7V17'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke={'currentColor'}
        fill={'none'}
      />
    </>
  ),
});
