import { useAtom, useAtomValue } from 'jotai';

import { controlPanelAtom } from './controlPanelAtom';

export const useControlPanel = () => {
  return useAtom(controlPanelAtom);
};

const SplitContent = () => {
  return null;
};

export const useControlPanelContent = () => {
  const controlPanelScreen = useAtomValue(controlPanelAtom);

  if (controlPanelScreen === 'SPLIT') return <SplitContent />;

  return null;
};
