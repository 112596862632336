import { Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { HorizontalQuartileChart } from 'components/charts/HorizontalQuartileChart';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { Delta } from 'components/Delta/Delta';
import { Box, Heading, HStack, Skeleton, VStack } from 'components/uikit';
import { useCustomerBreakdownBlock } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlock';
import { DELTA_UNAVAILABLE } from 'data/charts/models/SummaryCard';
import { useFetchCustomersQuartileRevenueChart } from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { getPercentileCopy } from 'data/healthReports/utils/getPercentileCopy';
import { useDefaultCurrency } from 'data/settings/hooks/finance/useDefaultCurrency';
import { formatCurrency, formatPercentage } from 'utils/formatting/number';
import { isLoading } from 'utils/types';

type RevenueOverviewProps = {
  customerCompanyId: string;
};

export function RevenueOverview({ customerCompanyId }: RevenueOverviewProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs.overview_tab',
  });

  const currency = useDefaultCurrency();
  const { showFinanceBlocks } =
    useShowDepartmentBlocksForCompany(customerCompanyId);
  const { block } = useCustomerBreakdownBlock(
    'CUSTOMER_COMPANY_FINANCE_REVENUE_QUARTILE',
  );
  const { data, fetchStatus } = useFetchCustomersQuartileRevenueChart(
    customerCompanyId,
    block,
  );

  const showSkeletons = isLoading(fetchStatus) || !data;

  if (
    !block ||
    !showFinanceBlocks ||
    (!isLoading(fetchStatus) && (!data || !data.value))
  ) {
    return null;
  }

  return (
    <>
      <Divider border='0 solid' borderColor='grey.border' opacity={1} />
      <VStack p='24px' alignItems='left' spacing={0}>
        <HStack justify='space-between'>
          <Heading
            as='h5'
            fontWeight='semibold'
            noOfLines={1}
            fontSize='14px'
            lineHeight={1.6}
            color='grey.primaryText'
          >
            {t('revenue.title')}
          </Heading>
          {showSkeletons ? (
            <Skeleton h='18px' w='100px' />
          ) : (
            <Heading
              as='h5'
              fontWeight='semibold'
              noOfLines={1}
              fontSize='18px'
              lineHeight={1.4}
              color='grey.primaryText'
            >
              {t('revenue.amount', {
                amount: formatCurrency(data.value ?? 0, currency),
              })}
            </Heading>
          )}
        </HStack>
        <HStack justify='space-between'>
          {showSkeletons ? (
            <Skeleton h='18px' w='250px' />
          ) : (
            <HorizontalQuartileChart
              value={data.quartile}
              star={data.isTopItem}
            />
          )}
          <HStack spacing='4px'>
            {showSkeletons ? (
              <Skeleton h='18px' w='40px' />
            ) : data.pctDelta !== null ? (
              <>
                <Delta
                  value={data.pctDelta ?? DELTA_UNAVAILABLE}
                  valueFormatter={formatPercentage}
                />
                <Box
                  noOfLines={1}
                  fontSize='12px'
                  lineHeight={1.5}
                  color='grey.tertiaryText'
                >
                  {t('vs_last_month')}
                </Box>
              </>
            ) : null}
          </HStack>
        </HStack>
        <HStack justify='space-between'>
          {showSkeletons ? (
            <Skeleton h='16px' w='150px' mt={'2px'} />
          ) : (
            <Box
              noOfLines={1}
              fontSize='12px'
              lineHeight={1.5}
              color='grey.tertiaryText'
            >
              {getPercentileCopy(
                data.quartile,
                data.isTopItem,
                data.topItemsCount,
                t,
              )}
            </Box>
          )}
        </HStack>
      </VStack>
    </>
  );
}
