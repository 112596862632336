import { PropsWithChildren, useEffect, useState } from 'react';

import { Box, BoxProps, Center } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import loader from 'components/uikit/animations/loader.json';

type BaseLoaderProps = Omit<BoxProps, 'children'> & {
  fullScreen?: boolean;
};

type LoaderWithChildrenProps = BaseLoaderProps &
  PropsWithChildren<{ isLoading: boolean; loadingTimeoutInMs?: number }>;

type LoaderProps = BaseLoaderProps | LoaderWithChildrenProps;

const FULL_SCREEN_PROPS: BoxProps = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 'highest',
};

export function Loader({ fullScreen = true, ...loaderProps }: LoaderProps) {
  const { isLoading, children, loadingTimeoutInMs, ...props } =
    'isLoading' in loaderProps
      ? loaderProps
      : {
          isLoading: undefined,
          children: null,
          loadingTimeoutInMs: null,
          ...loaderProps,
        };

  const fullScreenProps = fullScreen ? FULL_SCREEN_PROPS : {};

  const [renderLoader, setRenderLoader] = useState(isLoading ?? true);

  useEffect(() => {
    // Schedule a timeout to hide the loader after 5 seconds only if isLoading was not passed as prop
    if (isLoading === undefined) return;
    if (!isLoading) setRenderLoader(false);
    if (!loadingTimeoutInMs) return;

    const timeout = setTimeout(() => {
      setRenderLoader(false);
    }, loadingTimeoutInMs);

    return () => clearTimeout(timeout);
  }, [isLoading, loadingTimeoutInMs]);

  if (renderLoader) {
    return (
      <Center
        width='100%'
        height='100%'
        backgroundColor='grey.background'
        {...props}
        {...fullScreenProps}
      >
        <Center
          backgroundColor='grey.white'
          width='192px'
          height='192px'
          borderRadius='96px'
          borderWidth='1px'
          borderColor='grey.border'
        >
          <Box width='120px' height='100px' data-testid='loader'>
            <Lottie animationData={loader} loop />
          </Box>
        </Center>
      </Center>
    );
  }
  return <>{children}</>;
}
