import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { PaginatedBreakdownConfig } from 'data/charts/models/ChartsApiRequest';
import {
  BlockResponse,
  PaginatedBreakdownResponse,
} from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict, Parser } from 'utils/parser/parseResponse';

interface Params<
  BreakdownRow,
  Output extends PaginatedBreakdownResponse<BreakdownRow, unknown>,
> {
  blockId: string;
  chartConfig: PaginatedBreakdownConfig;
  parser: Parser<Output, unknown>;
}

export async function fetchPaginatedBreakdown<
  BreakdownRow,
  Output extends PaginatedBreakdownResponse<
    BreakdownRow,
    unknown
  > = PaginatedBreakdownResponse<BreakdownRow, unknown>,
>({
  blockId,
  chartConfig,
  parser,
}: Params<BreakdownRow, Output>): Promise<BlockResponse<Output>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({ blocks: [{ blockId, chartConfig }] }),
  });

  if (!result.ok) {
    throw new Error(
      `Failed to fetch new paginated breakdown: ${result.status}`,
    );
  }

  return parseItemStrict(
    z.array(BlockResponse(parser)).length(1),
    await result.json(),
  )[0] as BlockResponse<Output>;
}
