import { z } from 'zod';

import { CurrencyType } from 'data/common/currencyType';

export const TimeSeries = z.object({
  labels: z.array(z.coerce.string()),
  values: z.array(z.string().nullable()),
  currencies: CurrencyType.nullable().array().optional(),
  isReference: z.boolean().optional(),
  serieId: z.string().optional(),
});

export type TimeSeries = z.infer<typeof TimeSeries>;

export type MappedTimeSeriesQueryResult<K extends Record<string, unknown>> = {
  [k in keyof K]: TimeSeries;
};
