import { InputStatus } from './InputStatus';

export const getInputVariant = (status?: InputStatus) => {
  switch (status) {
    case 'ERROR':
      return 'error';
    case 'SUCCESS':
      return 'success';
    case 'DISABLED':
      return 'disabled';
    default:
      return 'outline';
  }
};
