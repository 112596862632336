import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface DeleteCustomerUserNoteParams {
  userId: string;
  noteId: string;
}

export async function deleteCustomerUserNote({
  userId,
  noteId,
}: DeleteCustomerUserNoteParams): Promise<CustomerUserResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/${userId}/notes/${noteId}`,
    { method: 'DELETE' },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to create customer company note: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserResponse, data);
}
