import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { useAtomValue } from 'jotai';

import { useShareLinkCopiedToast } from 'components/Sharing/ShareLinkCopiedToast';
import { useSharingBlockContext } from 'components/Sharing/sharingBlockContextAtom';
import { APP_URL } from 'data/constants';
import { sharingConfigAtom } from 'data/sharing/atoms/sharingConfig';
import { useCurrentCompanyNamespace } from 'data/user/hooks/useCurrentCompany';
import { isNotNull } from 'utils/helpers';

export function useCopyInternalLink() {
  const { renderSuccessToast } = useShareLinkCopiedToast();
  const { sharingUrl } = useSharingBlockContext();
  const { asPath: currentPath } = useRouter();
  const currentCompany = useCurrentCompanyNamespace();
  const otherSharingConfig = useAtomValue(sharingConfigAtom);

  return useCallback(async () => {
    const allSharedConfig = {
      path: currentPath,
      company: currentCompany,
      ...otherSharingConfig,
    };

    const encodedSharingConfig = Object.entries(allSharedConfig)
      .map(([key, value]) =>
        value
          ? `${key}=${encodeURIComponent(
              encodeURIComponent(
                typeof value === 'string' ? value : JSON.stringify(value),
              ),
            )}`
          : null,
      )
      .filter(isNotNull)
      .join('&');

    await navigator.clipboard.writeText(
      sharingUrl ? sharingUrl : `${APP_URL}/shared?${encodedSharingConfig}`,
    );

    renderSuccessToast(true, 'organization');
  }, [
    currentPath,
    currentCompany,
    otherSharingConfig,
    sharingUrl,
    renderSuccessToast,
  ]);
}
