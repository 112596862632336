import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Department } from 'data/blocks/models/Block';
import { USERS_API_URL } from 'data/constants';
import { UsedProvider } from 'data/user/models/onboarding';

export type SelectUsedProvidersRequest = {
  department: Department;
  providers: UsedProvider[];
};

export async function selectUsedProviders(request: SelectUsedProvidersRequest) {
  const result = await authorisedFetch(`${USERS_API_URL}/providers`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });

  if (result.status > 399) {
    throw new Error(`Failed to fetch used providers: ${result.status}`);
  }
}
