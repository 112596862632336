import { authorisedFetch } from 'api/rest/authorisedFetch';
import { ChatAnswer } from 'data/ai/models';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { PARROT_API_URL } from './resourcesUrls';

export async function getChatActions(chatId: string) {
  const result = await authorisedFetch(
    `${PARROT_API_URL}/chat/${chatId}/actions`,
    {
      method: 'GET',
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(
      `Failed to get parrot chat insight actions: ${responseBody}`,
    );
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(ChatAnswer, data);
}
