import { z } from 'zod';

import { CustomerCompany } from 'data/customer/CustomerCompany';
import { CustomerNote } from 'data/customer/CustomerNote';
import { CustomerTag } from 'data/customer/CustomerTag';
import { CustomerUser } from 'data/customer/CustomerUser';

export const CustomerCompanyResponse = z.object({ company: CustomerCompany });
export type CustomerCompanyResponse = z.infer<typeof CustomerCompanyResponse>;

export const CustomerCompaniesResponse = z.object({
  companies: CustomerCompany.array(),
});
export type CustomerCompaniesResponse = z.infer<
  typeof CustomerCompaniesResponse
>;

export const CustomerCompanyNotesResponse = z.object({
  companyId: z.string(),
  notes: CustomerNote.array(),
});
export type CustomerCompanyNotesResponse = z.infer<
  typeof CustomerCompanyNotesResponse
>;

export const CustomerUserResponse = z.object({ user: CustomerUser });
export type CustomerUserResponse = z.infer<typeof CustomerUserResponse>;

export const CustomerUsersResponse = z.object({
  companies: CustomerUser.array(),
});
export type CustomerUsersResponse = z.infer<typeof CustomerUsersResponse>;

export const CustomerUserNotesResponse = z.object({
  userId: z.string(),
  notes: CustomerNote.array(),
});
export type CustomerUserNotesResponse = z.infer<
  typeof CustomerUserNotesResponse
>;

export const CustomerTagsResponse = z.object({ tags: CustomerTag.array() });
export type CustomerTagsResponse = z.infer<typeof CustomerTagsResponse>;

export const CustomerTagResponse = z.object({ tag: CustomerTag });
export type CustomerTagResponse = z.infer<typeof CustomerTagResponse>;
