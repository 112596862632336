import { ActiveUsersOverview } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel/ActiveUsersOverview';
import { CustomerLifespanOverview } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel/CustomerLifespanOverview';
import { ProductEngagementOverview } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel/ProductEngagementOverview';
import { RevenueOverview } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel/RevenueOverview';
import { SentimentOverview } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel/SentimentOverview';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { Card, TabPanel, VStack } from 'components/uikit';
import { hideScrollbar } from 'styles/hideScrollbar';

interface OverviewTabPanelProps {
  customerCompanyId: string;
}

export function OverviewTabPanel({ customerCompanyId }: OverviewTabPanelProps) {
  const { showProductBlocks, showFinanceBlocks } =
    useShowDepartmentBlocksForCompany(customerCompanyId);

  return (
    <TabPanel height={'100%'}>
      <VStack
        background={'grey.offWhite'}
        alignItems='stretch'
        height={'100%'}
        overflowY={'hidden'}
      >
        <VStack
          p='16px'
          overflowY={'scroll'}
          width={'100%'}
          css={hideScrollbar}
        >
          <Card>
            <SentimentOverview customerCompanyId={customerCompanyId} />
            {showFinanceBlocks ? (
              <RevenueOverview customerCompanyId={customerCompanyId} />
            ) : null}
            {showProductBlocks ? (
              <ActiveUsersOverview customerCompanyId={customerCompanyId} />
            ) : null}
            {showProductBlocks ? (
              <ProductEngagementOverview
                customerCompanyId={customerCompanyId}
              />
            ) : null}
            {showProductBlocks ? (
              <CustomerLifespanOverview customerCompanyId={customerCompanyId} />
            ) : null}
          </Card>
        </VStack>
      </VStack>
    </TabPanel>
  );
}
