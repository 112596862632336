import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const CONSECUTIVE_RELOADS_TIMEFRAME_IN_MS = 1000 * 60;
export const CONSECUTIVE_RELOADS_LIMIT = 3;

const internalPageReloadAttemptsInMsAtom = atomWithStorage<number[]>(
  'pageReloadAttemptsInMs',
  [],
);
/*
 * This atom is used to track the number of page reloads in a timeframe of 1 minute.
 */
export const pageReloadAttemptsAtom = atom<number, Date, void>(
  (get) => {
    const currentTime = new Date().getTime();
    return get(internalPageReloadAttemptsInMsAtom).filter(
      (dateInMs) =>
        currentTime - dateInMs <= CONSECUTIVE_RELOADS_TIMEFRAME_IN_MS,
    ).length;
  },
  (get, set, newDate) => {
    const currentDates = get(internalPageReloadAttemptsInMsAtom);
    const newDates = [...currentDates, newDate.getTime()].filter(
      (dateInMs) =>
        newDate.getTime() - dateInMs <= CONSECUTIVE_RELOADS_TIMEFRAME_IN_MS,
    );
    set(internalPageReloadAttemptsInMsAtom, newDates);
  },
);
