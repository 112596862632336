import { BarChart } from 'components/charts/BarChart/BarChart';
import { BarChartTooltip } from 'components/charts/BarChartTooltip';
import { useActiveUsersData } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useActiveUsersData';
import { useCustomerActiveDaysBlocks } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useCustomerActiveDaysBlocks';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { Box, Flex } from 'components/uikit';
import { getChartColors } from 'utils/chartsUtils';
import { formatIntegerNumber } from 'utils/formatting/number';
import { useColorPalette } from 'utils/hooks/useColorPalette';

export function ActiveDaysChartSection({ userId }: { userId: string }) {
  const { showProductBlocks } = useShowDepartmentBlocksForUser(userId);
  const { chartBlock } = useCustomerActiveDaysBlocks();

  if (!showProductBlocks || !chartBlock) return null;

  return (
    <ActiveDaysChartSectionWithBlocks
      chartBlockId={chartBlock.id}
      userId={userId}
    />
  );
}

function ActiveDaysChartSectionWithBlocks({
  userId,
  chartBlockId,
}: {
  userId: string;
  chartBlockId: string;
}) {
  const {
    chart: {
      fetchStatus: chartFetchStatus,
      data: chartData,
      aggPeriod: chartAggPeriod,
    },
  } = useActiveUsersData(userId);
  const colors = useColorPalette();

  return (
    <Flex flexDirection='column' width='100%'>
      <Box width='100%'>
        <BarChart
          id={chartBlockId}
          data={chartData}
          aggPeriod={chartAggPeriod}
          fetchStatus={chartFetchStatus}
          colors={getChartColors(colors, Object.keys(chartData))}
          tooltipContentFormat={(data) =>
            BarChartTooltip({
              ...data,
              calculateNet: false,
            })
          }
          leftTickFormat={formatIntegerNumber}
          lastBarDotted
          align='bottom'
          height={250}
        />
      </Box>
    </Flex>
  );
}
