import { z } from 'zod';

export const BaseQuartileDataBlock = z.object({
  value: z.number().nullable(),
  delta: z.number().nullable(),
  pctDelta: z.number().nullable(),
  quartile: z.number(),
  topItemsCount: z.number(),
  isTopItem: z.boolean(),
});
