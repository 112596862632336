import { useTranslation } from 'react-i18next';

import { Button, HStack, MagicWandIcon } from 'components/uikit';

interface AiChatFooterProps {
  onNewChat: () => void;
}

export function AiChatArchiveFooter({ onNewChat }: AiChatFooterProps) {
  const { t } = useTranslation('ai');
  return (
    <HStack
      justify='flex-end'
      px='24px'
      py='8px'
      backgroundColor='grey.white'
      borderTop='1px solid'
      borderColor='grey.border'
    >
      <Button
        variant='tertiary'
        size='sm'
        onClick={onNewChat}
        leftIcon={<MagicWandIcon boxSize='20px' />}
      >
        {t('ask_question')}
      </Button>
    </HStack>
  );
}
