import React from 'react';

import { Grid, GridItem } from '../reexport';

export function ThreeColGrid({
  col1,
  col2,
  col3,
}: {
  col1: React.ReactNode;
  col2: React.ReactNode;
  col3: React.ReactNode;
}) {
  return (
    <Grid
      px='8'
      templateColumns='repeat(12, 1fr)'
      gridColumnGap='8'
      width='100%'
      height='100%'
    >
      <GridItem colStart={1} colSpan={2}>
        {col1}
      </GridItem>
      <GridItem colStart={3} colSpan={7}>
        {col2}
      </GridItem>
      <GridItem colStart={10} colSpan={3}>
        {col3}
      </GridItem>
    </Grid>
  );
}
