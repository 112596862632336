import { useCallback } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { isSideMenuOpenAtom, navRefAtom } from 'data/layout/atoms/layoutAtoms';

export function useNavHeight() {
  const navRef = useAtomValue(navRefAtom);

  return `${navRef.current ? navRef.current.clientHeight : 48}px`;
}

export function useOpenSideMenu() {
  const setSideMenuOpen = useSetAtom(isSideMenuOpenAtom);

  return useCallback(() => {
    setSideMenuOpen(true);
  }, [setSideMenuOpen]);
}

export function useCloseSideMenu() {
  const setSideMenuOpen = useSetAtom(isSideMenuOpenAtom);

  return useCallback(() => {
    setSideMenuOpen(false);
  }, [setSideMenuOpen]);
}

export function useToggleSideMenu() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useAtom(isSideMenuOpenAtom);

  return useCallback(() => {
    setIsSideMenuOpen(!isSideMenuOpen);
  }, [isSideMenuOpen, setIsSideMenuOpen]);
}
