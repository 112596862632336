import { PropsWithChildren, ReactNode } from 'react';

import { useAtomValue } from 'jotai';

import { LoadingErrorBanner } from 'components/LoadingErrorBanner';
import { useMultipleReloadsToast } from 'components/MultipleReloadsToast';
import { SidebarMenu } from 'components/SidebarMenu/SidebarMenu';
import { Box, chakra, Flex } from 'components/uikit';
import { renderedErrorsCountAtom } from 'data/loadErrors/atoms/renderedErrors';

interface Props extends PropsWithChildren {
  header: ReactNode;
  withErrorBanner?: boolean;
}

export function MainLayout({
  children,
  header,
  withErrorBanner = true,
}: Props) {
  const renderedErrorsCount = useAtomValue(renderedErrorsCountAtom);
  const showLoadingErrorBanner = withErrorBanner && renderedErrorsCount > 0;
  const errorBannerSpace = showLoadingErrorBanner ? 48 : 0;
  const contentPaddingTop = `${52 + errorBannerSpace}px`;
  const sidebarMenuWidth = '168px';

  useMultipleReloadsToast();

  return (
    <Flex w='100%' h='100%'>
      <chakra.nav flexBasis={sidebarMenuWidth} h='100%' zIndex='highest'>
        <SidebarMenu />
      </chakra.nav>
      <chakra.main flex={1} overflowY='auto' bg='grey.background'>
        <Flex
          position='fixed'
          zIndex='high'
          left='168px'
          width={`calc(100% - ${sidebarMenuWidth})`}
          direction='column'
        >
          {header}
          {showLoadingErrorBanner ? <LoadingErrorBanner /> : null}
        </Flex>
        <Box pt={contentPaddingTop} h='100%' w='100%' overflow='auto'>
          {children}
        </Box>
      </chakra.main>
    </Flex>
  );
}
