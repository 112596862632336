import { createIcon } from '@chakra-ui/react';

export const CodeIcon = createIcon({
  displayName: 'CodeIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M11.1654 12L15.1654 8L11.1654 4M5.83203 4L1.83203 8L5.83203 12'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      stroke='currentColor'
      fill='none'
    />
  ),
});
