import { authorisedFetch } from 'api/rest/authorisedFetch';
import { PROTECTED_API_URL } from 'data/constants';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { ProductTourConfig } from '../models';

type ProductTourConfigPayload = {
  config: ProductTourConfig;
};

async function fetchUserProductTourConfig(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variables: object = {},
): Promise<ProductTourConfigPayload> {
  const response = await authorisedFetch(
    `${PROTECTED_API_URL}/companies-new/profile/tour`,
  );

  if (response.status > 399) {
    const responseBody = await response.text();
    throw new Error(`Failed to get product config : ${responseBody}`);
  }

  const config = parseItemStrict(ProductTourConfig, await response.json());
  return { config };
}

export const userProductTourConfigAtom = getDataAtom<ProductTourConfigPayload>({
  config: {
    productTourCompleted: true,
    createdAlerts: true,
    ratedInsight: true,
    viewedAlerts: true,
    viewedBreakdowns: true,
    viewedMetricDictionary: true,
    viewedMissionControl: true,
    viewedRadar: true,
    viewedDigestNotice: true,
    viewedHighlightNotice: true,
    viewedInsightNotice: true,
    hidInviteTeamSidebarCard: true,
    hidGetBetterInsightsSidebarCard: true,
  },
});

export const fetchUserProductConfigAtom = getDataFetchingAtom<
  ProductTourConfigPayload,
  object
>(userProductTourConfigAtom, fetchUserProductTourConfig);
