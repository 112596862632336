import { z } from 'zod';

import { DepartmentIdsArrays } from 'data/customer/DepartmentIdsArrays';

export const CustomerCompany = DepartmentIdsArrays.extend({
  id: z.string().uuid(),
  name: z.string(),
  companyId: z.string(),
  insertedAt: z.coerce.date(),
  tags: z.string().uuid().array(),
  notes: z.string().uuid().array(),
  sentiment: z.string().nullable(),
  sentimentUpdatedAt: z.coerce.date().nullable(),
  updatedAt: z.coerce.date(),
});

export type CustomerCompany = z.infer<typeof CustomerCompany>;
