import { createIcon } from '@chakra-ui/react';

export const TickIcon = createIcon({
  displayName: 'TickIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7498 0.890037L3.58982 7.80004L1.68982 5.77004C1.33982 5.44004 0.789824 5.42004 0.389824 5.70004C-0.000176162 5.99004 -0.110176 6.50004 0.129824 6.91004L2.37982 10.57C2.59982 10.91 2.97982 11.12 3.40982 11.12C3.81982 11.12 4.20982 10.91 4.42982 10.57C4.78982 10.1 11.6598 1.91004 11.6598 1.91004C12.5598 0.990037 11.4698 0.180037 10.7498 0.880037V0.890037Z'
      />
    </>
  ),
});
