import { createIcon } from '@chakra-ui/react';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M16.6654 5L7.4987 14.1667L3.33203 10'
      fill='none'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
