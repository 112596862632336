import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';

import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import { BlockCommandPanelOption } from 'data/commandPanel/models';
import {
  getCommandPanelSubtitle,
  getIconByUniqueName,
  getUrl,
  redirectMappings,
} from 'data/commandPanel/redirectMappings';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';

type BlockMenuListItemProps = {
  item: BlockCommandPanelOption;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
};

export function BlockMenuListItem({
  item,
  index,
  isSelected,
  onSelect,
  onClose,
}: BlockMenuListItemProps) {
  const { t } = useTranslation('command-panel');
  const router = useRouter();
  const onClick = async () => {
    if (redirectMappings[item.uniqueName]) {
      await router.push(getUrl(item));
    }
  };
  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.METRIC_SELECTED}
      icon={getIconByUniqueName(item.uniqueName)}
      id={item.id}
      name={item.title}
      subTitle={t(`options.${getCommandPanelSubtitle(item.uniqueName)}`)}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={() => {
        onClick();
        onClose();
      }}
    />
  );
}
