import { authExchange } from '@urql/exchange-auth';
import {
  cacheExchange,
  Client,
  createClient,
  dedupExchange,
  fetchExchange,
} from 'urql';

import { readAtom } from 'components/AtomReader';
import { getIdToken } from 'data/auth/amplify';
import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';
// DATA_GRAPHQL_URL and GRAPHQL_URL seem to have the same value.
import { GRAPHQL_URL, IS_TEST } from 'data/constants';

let client: Client;

function makeClient() {
  return createClient({
    url: GRAPHQL_URL,
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange<{ idToken: string | null; expiry: number | null }>({
        getAuth: async () => {
          if (IS_TEST) {
            return { idToken: null, expiry: null };
          }
          try {
            return await getIdToken();
          } catch (error) {
            if (error instanceof Error) {
              console.error(
                `Could not get auth token for GraphQL client: ${error.message}`,
              );
            }
            return { idToken: null, expiry: null };
          }
        },
        willAuthError: ({ authState }) => {
          const { idToken } = readAtom?.(idTokenAtom) ?? {};
          const isExpired = (authState?.expiry ?? 0) * 1000 < Date.now();
          const hasRefreshed = authState?.idToken !== idToken;
          return isExpired || hasRefreshed;
        },
        didAuthError: ({ error }) =>
          error.graphQLErrors.some((e) => e.message === 'Unauthorized'),
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState || !authState.idToken) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return {
            ...operation,
            context: {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  Authorization: `Bearer ${authState.idToken}`,
                },
              },
            },
          };
        },
      }),
      fetchExchange,
    ],
  });
}

function getClient() {
  if (client) return client;
  else {
    client = makeClient();
    return client;
  }
}

/**
 * re-instantiate to clear cache
 */
function resetClient() {
  client = makeClient();
}

export { client, getClient, resetClient };
