import { fetchSalesStageMappings } from 'api/rest/charts/fetchChartsSalesSettings';
import { SalesStageMappings } from 'data/settings/models/SalesStages';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const salesStageMappingsAtom = getDataAtom<SalesStageMappings | null>(
  null,
);

export const fetchSalesStageMappingsAtom = getDataFetchingAtom<
  SalesStageMappings | null,
  undefined
>(salesStageMappingsAtom, fetchSalesStageMappings);
