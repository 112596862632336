import { DateRangeControlOptions } from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownTableBody } from 'components/charts/FullBreakdownTable/FullBreakdownTableBody';
import { FullBreakdownTableHeader } from 'components/charts/FullBreakdownTable/FullBreakdownTableHeader';
import { useUserSegmentsTableConfig } from 'components/CustomerDrawer/CompanyDrawer/hooks/useUserSegmentsTableConfig';
import { LoadableComponent } from 'components/LoadableComponent';
import { Table } from 'components/uikit';
import { UserSegmentsBreakdownRow } from 'data/charts/atoms/product/users/breakdowns';
import { BreakdownResponse } from 'data/charts/models/ChartsApiResponse';

type UserSegmentsTableProps = {
  blockId: string;
  data: BreakdownResponse<UserSegmentsBreakdownRow, unknown>;
};

export function UserSegmentsTable({ blockId, data }: UserSegmentsTableProps) {
  const config = useUserSegmentsTableConfig();

  return (
    <LoadableComponent
      id={`mission-control-breakdown-loadable-${blockId}`}
      trackingLabel='userSegmentsFullBreakdown'
      positioning='fullBreakdownTable'
      flexDirection='column'
      hasError={false}
      borderWidth={0}
    >
      <Table
        variant='plain'
        width='100%'
        borderWidth={0}
        boxShadow='none'
        margin='10px'
      >
        <FullBreakdownTableHeader
          config={config}
          bgColor='none'
          borderBottomWidth={0}
        />
        <FullBreakdownTableBody
          fetchStatus='SUCCESS'
          config={config}
          dateRangeControl={DateRangeControlOptions.DATE_PICKER_FIXED_END_DATE}
          rows={data.rows}
          maxWidth='100%'
        />
      </Table>
    </LoadableComponent>
  );
}
