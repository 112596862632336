import { useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { CreateTagForm } from 'components/CustomerDrawer/Tags/CreateTagForm';
import { CreateTagModalFooter } from 'components/CustomerDrawer/Tags/CreateTagModalFooter';
import { UserTagsList } from 'components/CustomerDrawer/UserDrawer/UserTags/UserTagsList';
import { UserTagsModalFooter } from 'components/CustomerDrawer/UserDrawer/UserTagsModal/UserTagsModalFooter';
import { UserTagsModalHeader } from 'components/CustomerDrawer/UserDrawer/UserTagsModal/UserTagsModalHeader';
import { Card, VStack } from 'components/uikit';
import { draftNewTagAtom } from 'data/healthReports/atoms/draftNewTagAtom';
import { searchTagsBoxValueAtom } from 'data/healthReports/atoms/searchTagsBoxValueAtom';

interface CompanyTagsModalProps {
  customerUserId: string;
  onTagsClose: () => void;
}

export function CompanyTagsModal({
  customerUserId,
  onTagsClose,
}: CompanyTagsModalProps) {
  const [variant, setVariant] = useState<'list' | 'create'>('list');
  const searchBoxValue = useAtomValue(searchTagsBoxValueAtom);
  const setNewTag = useSetAtom(draftNewTagAtom);

  const onCreateTagOpen = () => {
    setVariant('create');
    setNewTag({ title: searchBoxValue, description: '' });
  };

  const onCreateTagClose = () => {
    setVariant('list');
  };

  return (
    <VStack
      background={'grey.background'}
      width={'100%'}
      p={'24px'}
      height={'100%'}
      spacing={'0'}
    >
      <Card
        overflow={'hidden'}
        border={'0px solid'}
        height={'100%'}
        hidden={variant !== 'list'}
      >
        <UserTagsModalHeader />
        <UserTagsList userId={customerUserId} />
        <UserTagsModalFooter
          userId={customerUserId}
          onTagsClose={onTagsClose}
          onCreateTag={onCreateTagOpen}
        />
      </Card>

      <Card
        overflow={'hidden'}
        border={'0px solid'}
        height={'100%'}
        hidden={variant !== 'create'}
      >
        <UserTagsModalHeader />
        <CreateTagForm tKeyPrefix={'user_drawer.user_tags_modal.body'} />
        <CreateTagModalFooter
          tKeyPrefix={'user_drawer.user_tags_modal.footer'}
          onClose={onCreateTagClose}
          customerType={'user'}
        />
      </Card>
    </VStack>
  );
}
