import {
  extendTheme,
  type ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import { SidebarMenuDesktop } from 'components/theme/components/SidebarMenu.theme';
import { Switch } from 'components/theme/components/Switch.theme';
import { breakpoints } from 'utils/hooks/screenBreakpoints';

import { borderRadius } from './borderRadius';
import { colors, semanticColors } from './colors';
import { Badge } from './components/Badge.theme';
import { Button } from './components/Button.theme';
import { Checkbox } from './components/Checkbox.theme';
import { Input } from './components/Input.theme';
import { Modal } from './components/Modal.theme';
import { Radio } from './components/Radio.theme';
import { Table } from './components/Table.theme';
import { Tabs } from './components/Tabs.theme';
import { Tag } from './components/Tag.theme';
import { Text } from './components/Text.theme';
import { Textarea } from './components/Textarea.theme';
import { font } from './font';
import { shadows } from './shadows';
import { space } from './space';
import { zIndices } from './zIndices';

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
};

export const theme = extendTheme(
  withDefaultColorScheme({ colorScheme: config.initialColorMode }),
  {
    config,
    styles: {
      global: {
        body: {
          bg: 'transparent',
        },
      },
    },
  },
  {
    borderRadius,
    breakpoints,
    colors,
    components: {
      Badge,
      Tabs,
      Button,
      Input,
      Text,
      Textarea,
      Table,
      Tag,
      Modal,
      Radio,
      Checkbox,
      Switch,
      SidebarMenuDesktop,
    },
    ...font,
    shadows,
    space,
    zIndices,
  },
  {
    semanticTokens: {
      colors: semanticColors,
    },
  },
);
