import { createIcon } from '@chakra-ui/react';

export const BellIcon = createIcon({
  displayName: 'BellIcon',
  viewBox: '0 0 16 19',
  path: (
    <path
      d='M10.6253 15.3337C10.6253 16.7144 9.50597 17.8337 8.12526 17.8337C6.74454 17.8337 5.62526 16.7144 5.62526 15.3337M9.62234 4.69912C9.98525 4.32419 10.2086 3.81334 10.2086 3.25033C10.2086 2.09973 9.27585 1.16699 8.12526 1.16699C6.97466 1.16699 6.04192 2.09973 6.04192 3.25033C6.04192 3.81334 6.26526 4.32419 6.62817 4.69912M13.1253 8.83366C13.1253 7.68439 12.5985 6.58219 11.6608 5.76953C10.7231 4.95687 9.45134 4.50033 8.12526 4.50033C6.79917 4.50033 5.5274 4.95687 4.58972 5.76953C3.65204 6.58219 3.12526 7.68439 3.12526 8.83366C3.12526 10.7352 2.6537 12.1258 2.06531 13.1209C1.3947 14.255 1.0594 14.8221 1.07264 14.9575C1.08779 15.1125 1.11569 15.1614 1.24137 15.2533C1.35123 15.3337 1.90318 15.3337 3.00708 15.3337H13.2434C14.3473 15.3337 14.8993 15.3337 15.0091 15.2533C15.1348 15.1614 15.1627 15.1125 15.1779 14.9575C15.1911 14.8221 14.8558 14.255 14.1852 13.1209C13.5968 12.1258 13.1253 10.7352 13.1253 8.83366Z'
      fill='none'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
