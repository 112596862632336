import { useTranslation } from 'react-i18next';

import { Button, HStack, MagicWandIcon } from 'components/uikit';
import { ArchiveIcon } from 'components/uikit/icons/Archive';
import { useResetResults } from 'data/ai/hooks';
import { ChatType } from 'data/ai/models';

interface AiChatFooterProps {
  onChatArchiveOpen?: () => void;
  chatId?: string;
  type?: ChatType;
}

export function AiChatFooter({
  onChatArchiveOpen,
  chatId,
  type,
}: AiChatFooterProps) {
  const { t } = useTranslation('ai');
  const reset = useResetResults();

  return (
    <HStack
      justify='space-between'
      px='24px'
      py='8px'
      backgroundColor='grey.white'
      borderTop='1px solid'
      borderColor='grey.border'
    >
      {onChatArchiveOpen ? (
        <Button
          variant='primaryGhost'
          size='sm'
          onClick={onChatArchiveOpen}
          leftIcon={<ArchiveIcon boxSize='20px' />}
        >
          {t('question_archive')}
        </Button>
      ) : null}
      {chatId && type === 'CHAT' ? (
        <Button
          variant='tertiary'
          size='sm'
          onClick={() => reset()}
          leftIcon={<MagicWandIcon boxSize='20px' />}
        >
          {t('ask_new_question')}
        </Button>
      ) : null}
    </HStack>
  );
}
