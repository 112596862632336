import { Component, ErrorInfo, ReactNode } from 'react';

import { ActionLayout } from 'components/layouts/ActionLayout';
import { Box } from 'components/uikit';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ActionLayout>
          <ActionLayout.Action>
            <Box
              display='flex'
              w='100%'
              h='100%'
              alignItems='center'
              justifyContent='center'
            >
              <h1>Sorry, something went wrong</h1>;
            </Box>
          </ActionLayout.Action>
        </ActionLayout>
      );
    }

    return this.props.children;
  }
}
