import { Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { HorizontalQuartileChart } from 'components/charts/HorizontalQuartileChart';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { Delta } from 'components/Delta/Delta';
import { Box, Heading, HStack, Skeleton, VStack } from 'components/uikit';
import { useCustomerBreakdownBlock } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlock';
import { DELTA_UNAVAILABLE } from 'data/charts/models/SummaryCard';
import { useFetchCustomersQuartileActiveDaysChart } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { getPercentileCopy } from 'data/healthReports/utils/getPercentileCopy';
import { formatPercentage } from 'utils/formatting/number';
import { isLoading } from 'utils/types';

type ActiveDaysOverviewProps = {
  userId: string;
};

export function ActiveDaysOverview({ userId }: ActiveDaysOverviewProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.overview_tab',
  });

  const { showProductBlocks } = useShowDepartmentBlocksForUser(userId);
  const { block } = useCustomerBreakdownBlock(
    'CUSTOMER_USER_PRODUCT_ACTIVE_DAYS_QUARTILE',
  );
  const { data, fetchStatus } = useFetchCustomersQuartileActiveDaysChart(
    userId,
    block,
  );

  const showSkeletons = isLoading(fetchStatus) || !data;

  if (!block || !showProductBlocks || (!isLoading(fetchStatus) && !data)) {
    return null;
  }

  return (
    <>
      <Divider border={'0 solid'} borderColor={'grey.border'} opacity={1} />
      <VStack p={'24px'} alignItems='left' spacing={0}>
        <HStack justify={'space-between'}>
          <Heading
            as={'h5'}
            fontWeight={'semibold'}
            noOfLines={1}
            fontSize={'14px'}
            lineHeight={1.6}
            color={'grey.primaryText'}
          >
            {t('active_days.title')}
          </Heading>
          {showSkeletons ? (
            <Skeleton h='18px' w='100px' />
          ) : (
            <Heading
              as={'h5'}
              fontWeight={'semibold'}
              noOfLines={1}
              fontSize={'18px'}
              lineHeight={1.4}
              color={'grey.primaryText'}
            >
              {t('active_days.days', {
                count: data.value ?? 0,
              })}
            </Heading>
          )}
        </HStack>
        <HStack justify={'space-between'}>
          {showSkeletons ? (
            <Skeleton h='18px' w='250px' />
          ) : (
            <HorizontalQuartileChart
              value={data.quartile}
              star={data.isTopItem}
            />
          )}
          <HStack spacing={'4px'}>
            {showSkeletons ? (
              <Skeleton h='18px' w='40px' />
            ) : data.pctDelta !== null ? (
              <>
                <Delta
                  value={data.pctDelta ?? DELTA_UNAVAILABLE}
                  valueFormatter={formatPercentage}
                />
                <Box
                  noOfLines={1}
                  fontSize={'12px'}
                  lineHeight={1.5}
                  color={'grey.tertiaryText'}
                >
                  {t('vs_last_month')}
                </Box>
              </>
            ) : null}
          </HStack>
        </HStack>
        <HStack justify={'space-between'}>
          {showSkeletons ? (
            <Skeleton h='16px' w='150px' mt={'2px'} />
          ) : (
            <Box
              noOfLines={1}
              fontSize={'12px'}
              lineHeight={1.5}
              color={'grey.tertiaryText'}
            >
              {getPercentileCopy(
                data.quartile,
                data.isTopItem,
                data.topItemsCount,
                t,
              )}
            </Box>
          )}
        </HStack>
      </VStack>
    </>
  );
}
