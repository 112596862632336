import { z } from 'zod';

import { formattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownGrowthRow = z.object({
  split: z.string(),
  visitors: z.coerce.number(),
  newVisitors: z.coerce.number(),
  signUps: z.coerce.number(),
  sessions: z.coerce.number(),
  conversions: z.coerce.number(),
  engagedSessions: z.coerce.number(),
  totalTime: z.coerce.number(),
  engagementRate: z.coerce.number(),
  avgTimePerSession: z.coerce.number(),
  cvr: z.coerce.number(),
});

export type FullBreakdownGrowthRow = z.infer<typeof FullBreakdownGrowthRow>;

function buildGrowthBreakdownBaseAtoms() {
  return getFullBreakdownWithPaginationAtom(
    FullBreakdownGrowthRow,
    [{ column: 'newVisitors', direction: 'DESC' }],
    formattedDatePickerAtom,
  );
}

export const breakdownByCampaignByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCampaignBySessionsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCampaignByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCampaignByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByChannelByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByChannelBySessionsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByChannelByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByChannelByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCountryByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCountryBySessionsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCountryByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByCountryByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByDeviceByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByDeviceBySessionsAtoms = buildGrowthBreakdownBaseAtoms();
export const breakdownByDeviceByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByDeviceByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByOSByNewVisitorsAtoms = buildGrowthBreakdownBaseAtoms();
export const breakdownByOSBySessionsAtoms = buildGrowthBreakdownBaseAtoms();
export const breakdownByOSByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByOSByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByPageByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByPageBySessionsAtoms = buildGrowthBreakdownBaseAtoms();
export const breakdownByPageByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownByPageByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownBySourceByNewVisitorsAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownBySourceBySessionsAtoms = buildGrowthBreakdownBaseAtoms();
export const breakdownBySourceByEngagementRateAtoms =
  buildGrowthBreakdownBaseAtoms();
export const breakdownBySourceByAvgTimePerSessionAtoms =
  buildGrowthBreakdownBaseAtoms();

export const breakdownsDataAtoms = {
  campaignByNewVisitors: breakdownByCampaignByNewVisitorsAtoms.dataAtom,
  campaignBySessions: breakdownByCampaignBySessionsAtoms.dataAtom,
  campaignByEngagementRate: breakdownByCampaignByEngagementRateAtoms.dataAtom,
  campaignByAvgTimePerSession:
    breakdownByCampaignByAvgTimePerSessionAtoms.dataAtom,
  channelByNewVisitors: breakdownByChannelByNewVisitorsAtoms.dataAtom,
  channelBySessions: breakdownByChannelBySessionsAtoms.dataAtom,
  channelByEngagementRate: breakdownByChannelByEngagementRateAtoms.dataAtom,
  channelByAvgTimePerSession:
    breakdownByChannelByAvgTimePerSessionAtoms.dataAtom,
  countryByNewVisitors: breakdownByCountryByNewVisitorsAtoms.dataAtom,
  countryBySessions: breakdownByCountryBySessionsAtoms.dataAtom,
  countryByEngagementRate: breakdownByCountryByEngagementRateAtoms.dataAtom,
  countryByAvgTimePerSession:
    breakdownByCountryByAvgTimePerSessionAtoms.dataAtom,
  deviceByNewVisitors: breakdownByDeviceByNewVisitorsAtoms.dataAtom,
  deviceBySessions: breakdownByDeviceBySessionsAtoms.dataAtom,
  deviceByEngagementRate: breakdownByDeviceByEngagementRateAtoms.dataAtom,
  deviceByAvgTimePerSession: breakdownByDeviceByAvgTimePerSessionAtoms.dataAtom,
  osByNewVisitors: breakdownByOSByNewVisitorsAtoms.dataAtom,
  osBySessions: breakdownByOSBySessionsAtoms.dataAtom,
  osByEngagementRate: breakdownByOSByEngagementRateAtoms.dataAtom,
  osByAvgTimePerSession: breakdownByOSByAvgTimePerSessionAtoms.dataAtom,
  pageByNewVisitors: breakdownByPageByNewVisitorsAtoms.dataAtom,
  pageBySessions: breakdownByPageBySessionsAtoms.dataAtom,
  pageByEngagementRate: breakdownByPageByEngagementRateAtoms.dataAtom,
  pageByAvgTimePerSession: breakdownByPageByAvgTimePerSessionAtoms.dataAtom,
  sourceByNewVisitors: breakdownBySourceByNewVisitorsAtoms.dataAtom,
  sourceBySessions: breakdownBySourceBySessionsAtoms.dataAtom,
  sourceByEngagementRate: breakdownBySourceByEngagementRateAtoms.dataAtom,
  sourceByAvgTimePerSession: breakdownBySourceByAvgTimePerSessionAtoms.dataAtom,
} as const;
