import { atom, WritableAtom } from 'jotai';

import { Filter } from 'data/charts/models/ChartsApiRequest';

export type BreakdownFilters = {
  filters: Filter[];
};

type BreakdownFiltersAtom = WritableAtom<
  BreakdownFilters,
  BreakdownFilters,
  void
>;

export function getFiltersAtom(defaultFilters: Filter[]): BreakdownFiltersAtom {
  return atom<BreakdownFilters>({ filters: defaultFilters });
}
