import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Button, CloudSearch, Td, Text, Tr, VStack } from 'components/uikit';
import { RemoteRight } from 'components/uikit/icons/RemoteRight';
import {
  departmentMissionControlDatePickerAtom,
  datePickerInitialState,
} from 'data/charts/atoms/departmentMissionControlDatePickerAtom';

export enum DateRangeControlOptions {
  DATE_PICKER_FULL = 'FULL_DATE_PICKER',
  DATE_PICKER_FIXED_END_DATE = 'DATE_PICKER_FIXED_END_DATE',
}

interface BreakdownTableStubProps {
  dateRangeControl: DateRangeControlOptions;
  height?: string;
  colSpan?: number;
}

const EmptyStateMessage: { [key in DateRangeControlOptions]: string } = {
  [DateRangeControlOptions.DATE_PICKER_FULL]:
    'breakdown_table_empty_state_data_not_available_for_selected_time_period',
  [DateRangeControlOptions.DATE_PICKER_FIXED_END_DATE]:
    'breakdown_table_empty_state_data_not_available',
};

export function BreakdownTableStub({
  dateRangeControl,
  colSpan = 3,
  height = '264px',
}: BreakdownTableStubProps) {
  const { t } = useTranslation('mission-control');

  const setAtom = useSetAtom(departmentMissionControlDatePickerAtom);

  async function resetPeriod() {
    await setAtom(datePickerInitialState);
  }

  return (
    <Tr p={0} h={height}>
      <Td p={0} colSpan={colSpan}>
        <VStack
          w='100%'
          h={height}
          bgColor='grey.offWhite'
          justifyContent='center'
        >
          <CloudSearch w='150px' h='150px' fill='transparent' />
          <Text
            fontWeight='medium'
            color='grey.secondaryText'
            fontSize='16px'
            textAlign='center'
            pb='16px'
          >
            {t(EmptyStateMessage[dateRangeControl])}
          </Text>
          {dateRangeControl === DateRangeControlOptions.DATE_PICKER_FULL && (
            <Button
              rightIcon={
                <RemoteRight fill='transparent' stroke='secondary.button' />
              }
              size='xs'
              variant='ghost'
              color='secondary.button'
              onClick={resetPeriod}
              pb='20px'
            >
              {t('reset_time_period')}
            </Button>
          )}
        </VStack>
      </Td>
    </Tr>
  );
}
