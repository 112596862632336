import { ReactNode } from 'react';

import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from '@chakra-ui/react';

import { Text } from '../reexport';

export { useRadio, useRadioGroup } from '@chakra-ui/react';

type RadioProps = Omit<ChakraRadioProps, 'children'> & {
  label: ReactNode;
  description?: ReactNode;
};

export function Radio({ label, description, ...props }: RadioProps) {
  return (
    <ChakraRadio {...props}>
      {label}
      {description ? (
        <Text color='grey.tertiaryText' fontWeight='regular'>
          {description}
        </Text>
      ) : null}
    </ChakraRadio>
  );
}
