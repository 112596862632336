import { ChevronRightIcon } from '@chakra-ui/icons';
import { LinkBox } from '@chakra-ui/react';

import { useOnCustomerUserDrawerOpen } from 'components/CustomerDrawer/hooks/useOnCustomerUserDrawerOpen';
import { Flex, TruncatedText } from 'components/uikit';
import { Department } from 'data/blocks/models/Block';

export function OpenUserDrawerLinkBox({
  department,
  departmentId,
  userName,
  onDrawerClose,
}: {
  department: Department;
  departmentId: string;
  userName: string;
  onDrawerClose: () => void;
}) {
  const onUserDrawerOpen = useOnCustomerUserDrawerOpen({
    department,
    departmentId,
    onDataLoaded: () => void 0,
  });

  return (
    <LinkBox
      as={'button'}
      display={'inline'}
      onClick={() => {
        onDrawerClose();
        onUserDrawerOpen();
      }}
      width='100%'
    >
      <Flex gap='4px' justifyContent='space-between' width='100%'>
        <TruncatedText width='100%' fontSize='14px' textAlign='left'>
          {userName /* user name */}
        </TruncatedText>
        <ChevronRightIcon />
      </Flex>
    </LinkBox>
  );
}
