import { createIcon } from '@chakra-ui/react';

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16
         12.4179 16 8C16 3.58214 12.4179 0 8 0ZM11.8446 6.03036C11.85 6.11429
         11.85 6.20179 11.85 6.2875C11.85 8.90893 9.85357 11.9286 6.20536
         11.9286C5.08036 11.9286 4.0375 11.6018 3.15893 11.0393C3.31964
         11.0571 3.47321 11.0643 3.6375 11.0643C4.56607 11.0643 5.41964 10.75
         6.1 10.2179C5.22857 10.2 4.49643 9.62857 4.24643 8.84286C4.55179
         8.8875 4.82679 8.8875 5.14107 8.80714C4.69237 8.71598 4.28906 8.47229
         3.99967 8.11747C3.71028 7.76265 3.55265 7.31858 3.55357
         6.86071V6.83571C3.81607 6.98393 4.125 7.075 4.44821 7.0875C4.1765
         6.90642 3.95368 6.66109 3.79948 6.37327C3.64529 6.08544 3.56451
         5.76402 3.56429 5.4375C3.56429 5.06786 3.66071 4.73036 3.83393
         4.4375C4.33197 5.0506 4.95345 5.55204 5.65797 5.90922C6.3625 6.26641
         7.1343 6.47135 7.92321 6.51071C7.64286 5.1625 8.65 4.07143 9.86071
         4.07143C10.4321 4.07143 10.9464 4.31071 11.3089 4.69643C11.7571
         4.6125 12.1857 4.44464 12.5679 4.21964C12.4196 4.67857 12.1089
         5.06607 11.6964 5.31071C12.0964 5.26786 12.4821 5.15714 12.8393
         5.00179C12.5696 5.39821 12.2321 5.75 11.8446 6.03036Z'
      fill='currentColor'
      stroke='none'
    />
  ),
});
