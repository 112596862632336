import { useFetchedData } from 'data/charts/hooks/useFetchedData';
import { useCustomerCompanyUserSegmentsBreakdownAtoms } from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';

export const useCustomerCompanyUserSegmentsFetchedData = (
  companyId: string,
) => {
  return useFetchedData(
    useCustomerCompanyUserSegmentsBreakdownAtoms(companyId).dataAtom,
  );
};
