import { authorisedFetch } from 'api/rest/authorisedFetch';
import { getBlocksBySectionUrl } from 'data/blocks/api/resourceUrls';
import { Block } from 'data/blocks/models/Block';
import { BlockSection } from 'data/blocks/models/BlockSection';
import { parseItems } from 'utils/parser/parseResponse';

export async function fetchBlocksByCompanySection<T extends Block>(
  company: string,
  section: BlockSection,
): Promise<T[]> {
  const endpointUrl = getBlocksBySectionUrl(company, section);
  const result = await authorisedFetch(endpointUrl, { method: 'GET' });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch blocks by section: ${result.status}`);
  }

  return parseItems(Block, data) as T[];
}
