import { z } from 'zod';

import { Department } from 'data/blocks/models/Block';

import { GoalMetric } from './GoalMetric';

export const GoalStatus = z.enum([
  'IN_PROGRESS',
  'COMPLETED',
  'FAILED',
  'ARCHIVED_COMPLETED',
  'ARCHIVED_FAILED',
  'ARCHIVED',
]);

export type GoalStatus = z.infer<typeof GoalStatus>;

// TODO: redo into union when more metrics added
export const GoalModifiableConfig = z.object({
  metric: GoalMetric,
  targetValue: z.number(),
  endDate: z.coerce.date(),
});
export const GoalConfig = GoalModifiableConfig.extend({
  department: Department,
  initialValue: z.number(),
  startDate: z.coerce.date(),
});

export type GoalConfig = z.infer<typeof GoalConfig>;

export const Goal = GoalConfig.extend({
  id: z.string().uuid(),
  blockId: z.string(),
  companyId: z.string(),
  status: GoalStatus,
  insertedAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type Goal = z.infer<typeof Goal>;

export const CreateGoalRequest = GoalConfig;
export type CreateGoalRequest = z.infer<typeof CreateGoalRequest>;

export const UpdateGoalRequest = GoalModifiableConfig.extend({
  id: z.string().uuid(),
});
export type UpdateGoalRequest = z.infer<typeof UpdateGoalRequest>;
