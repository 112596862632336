import { Box, BoxProps, ColorProps, Flex, Text } from '@chakra-ui/react';

interface ProgressBarProps extends BoxProps {
  progress: number;
  showProgressNumber?: boolean;
  progressColor?: string;
  fillColor?: ColorProps['color'];
}

export function ProgressBar({
  progress,
  showProgressNumber = false,
  borderRadius = '8px',
  fillColor = 'dataViz.1',
  ...props
}: ProgressBarProps) {
  return (
    <Flex gap='12px' alignItems='center'>
      <Box
        w='320px'
        h='8px'
        borderRadius={borderRadius}
        backgroundColor='grey.border'
        position='relative'
        mt='6px'
        {...props}
      >
        <Box
          w={progress === 0 ? '8px' : `${progress}%`}
          h='100%'
          borderRadius={borderRadius}
          backgroundColor={fillColor}
          position='absolute'
          top='0'
          left='0'
        />
      </Box>
      {showProgressNumber ? (
        <Text variant='numeric' fontSize='sm' color='grey.tertiaryText'>
          {progress}%
        </Text>
      ) : null}
    </Flex>
  );
}

export function OnboardingProgressBar({
  progress,
  ...props
}: ProgressBarProps) {
  return (
    <Flex gap='12px' alignItems='center'>
      <Box
        w='320px'
        h='8px'
        borderRadius='8px'
        backgroundColor='grey.border'
        position='relative'
        mt='6px'
        {...props}
      >
        <Box
          w={progress === 0 ? '8px' : `${progress}%`}
          h='100%'
          borderRadius='8px'
          backgroundColor='primary.button'
          position='absolute'
          top='0'
          left='0'
        />
      </Box>
    </Flex>
  );
}
