import { useTranslation } from 'react-i18next';

import { DrillDownTabPanel } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel';
import { NotesTabPanel } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/NotesTabPanel';
import { OverviewTabPanel } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/OverviewTabPanel';
import { UsersTabPanel } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/UsersTabPanel';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { CountTag } from 'components/CustomerDrawer/DrawerTabs/CountTag';
import { CustomerDrawerTab } from 'components/CustomerDrawer/DrawerTabs/CustomerDrawerTab';
import { TabList, TabPanels, Tabs, VStack } from 'components/uikit';
import {
  useCustomerCompanyNotes,
  useCustomerCompanyUsers,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';
import { isLoading } from 'utils/types';

interface DrawerTabsProps {
  customerCompanyId: string;
  onDrawerClose: () => void;
}

export function DrawerTabs({
  customerCompanyId,
  onDrawerClose,
}: DrawerTabsProps) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs',
  });
  const { notes, fetchStatus: notesFetchStatus } =
    useCustomerCompanyNotes(customerCompanyId);
  const { usersCount, fetchStatus: usersFetchStatus } =
    useCustomerCompanyUsers(customerCompanyId);

  const handleTabsChange = (index: number) => {
    const tabOpened = ['overview', 'drill_down', 'users', 'notes'][index];
    trackEvent(CustomerDrawerAnalyticsEvents.TABS_SWITCHED, {
      customerType: 'company',
      tabOpened,
    });
  };

  const { showProductBlocks: showUsersTab } =
    useShowDepartmentBlocksForCompany(customerCompanyId);

  return (
    <VStack height={'100%'}>
      <Tabs
        variant='unstyled'
        width={'100%'}
        isFitted
        height={'calc(100%)'}
        overflow={'hidden'}
        onChange={handleTabsChange}
      >
        <TabList
          justifyContent={'space-between'}
          gap={'16px'}
          px={'24px'}
          borderY='solid 1px !important'
          borderColor={'grey.border !important'}
          alignItems={'center'}
          height={'48px'}
          backgroundColor={'grey.white'}
        >
          <CustomerDrawerTab>{t('overview')}</CustomerDrawerTab>

          <CustomerDrawerTab>{t('drill_down')}</CustomerDrawerTab>

          {showUsersTab ? (
            <CustomerDrawerTab>
              {t('users')}
              {isLoading(usersFetchStatus) ? null : (
                <CountTag>{usersCount.toString()}</CountTag>
              )}
            </CustomerDrawerTab>
          ) : null}

          <CustomerDrawerTab>
            {t('notes')}
            {isLoading(notesFetchStatus) ? null : (
              <CountTag>{notes.length.toString()}</CountTag>
            )}
          </CustomerDrawerTab>
        </TabList>

        <TabPanels flexDir={'row'} height={'calc(100% - 48px)'}>
          <OverviewTabPanel customerCompanyId={customerCompanyId} />
          <DrillDownTabPanel customerCompanyId={customerCompanyId} />
          {showUsersTab ? (
            <UsersTabPanel
              customerCompanyId={customerCompanyId}
              onDrawerClose={onDrawerClose}
            />
          ) : null}
          <NotesTabPanel customerCompanyId={customerCompanyId} />
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
