import { ColorMode } from '@chakra-ui/color-mode';

import { HorizontalThemeSwitchStack } from 'components/theme/HorizontalThemeSwitchStack';
import { ThemeSwitchButton } from 'components/theme/ThemeSwitchButton';

interface CopyAsImageThemeSelectorProps {
  setColorMode: (mode: ColorMode) => void;
  isLight: boolean;
  disabled?: boolean;
}

export function CopyAsImageThemeSelector({
  setColorMode,
  isLight,
  disabled,
}: CopyAsImageThemeSelectorProps) {
  return (
    <HorizontalThemeSwitchStack>
      <ThemeSwitchButton
        mode={'light'}
        isLight={isLight}
        setColorMode={setColorMode}
        disabled={disabled}
      />
      <ThemeSwitchButton
        mode={'dark'}
        isLight={isLight}
        setColorMode={setColorMode}
        disabled={disabled}
      />
    </HorizontalThemeSwitchStack>
  );
}
