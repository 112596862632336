import { createIcon } from '@chakra-ui/react';

export const RobotIcon = createIcon({
  displayName: 'RobotIcon',
  viewBox: '0 0 64 79',
  path: (
    <>
      <g clipPath='url(#clip0_24639_10666)'>
        <path
          opacity='0.2'
          d='M28.7865 74.9161C44.6849 74.9161 57.5731 61.4529 57.5731 44.8453C57.5731 28.2376 44.6849 14.7744 28.7865 14.7744C12.8882 14.7744 0 28.2376 0 44.8453C0 61.4529 12.8882 74.9161 28.7865 74.9161Z'
          fill='#1570EF'
        />
        <path
          d='M2.24273 70.0659C1.01133 67.2934 0.372817 64.2741 0.371584 61.2177C0.37035 58.1614 1.00643 55.1415 2.23559 52.368C3.46475 49.5944 5.25749 47.1338 7.48915 45.1573C9.72081 43.1808 12.3378 41.7359 15.1582 40.9229L16.8711 47.3915C14.9498 47.9447 13.167 48.9285 11.6465 50.2744C10.1261 51.6203 8.90465 53.2961 8.06706 55.1852C7.22948 57.0742 6.7959 59.1312 6.79648 61.213C6.79706 63.2949 7.23178 65.3516 8.07042 67.2402L2.24273 70.0659Z'
          fill='#1570EF'
        />
        <path
          d='M2.57882 78.9338C2.22297 78.9341 1.87399 78.8315 1.57055 78.6373C1.2671 78.4431 1.02105 78.165 0.859645 77.8337C0.408675 76.9085 0.136556 75.8997 0.0588272 74.8647C-0.018902 73.8297 0.09928 72.7888 0.406626 71.8016C1.02734 69.8077 2.38087 68.153 4.16947 67.2016C5.05509 66.7305 6.02087 66.4462 7.01166 66.365C8.00245 66.2838 8.99884 66.4073 9.94396 66.7284C11.8527 67.3768 13.4367 68.7907 14.3475 70.6591C14.4646 70.8949 14.5358 71.1527 14.5572 71.4174C14.5785 71.6822 14.5494 71.9488 14.4717 72.2017C14.3939 72.4547 14.269 72.6891 14.1041 72.8914C13.9392 73.0938 13.7377 73.26 13.511 73.3805C13.2843 73.5011 13.0371 73.5736 12.7835 73.5939C12.5299 73.6142 12.2749 73.5818 12.0333 73.4987C11.7917 73.4156 11.5683 73.2833 11.3759 73.1096C11.1834 72.9359 11.0258 72.724 10.9121 72.4864C10.6915 72.0313 10.3872 71.6262 10.0165 71.2942C9.64584 70.9622 9.21612 70.7098 8.75195 70.5515C8.28779 70.3933 7.79829 70.3322 7.31149 70.3718C6.8247 70.4114 6.35016 70.5509 5.91505 70.7823C5.47994 71.0138 5.0928 71.3326 4.77581 71.7206C4.45882 72.1085 4.21819 72.558 4.06771 73.0432C3.91723 73.5284 3.85984 74.0399 3.89885 74.5483C3.93785 75.0567 4.07248 75.5521 4.29501 76.0061C4.44446 76.313 4.51612 76.6548 4.50312 76.9989C4.49013 77.3429 4.39293 77.6777 4.22079 77.9714C4.04866 78.265 3.80735 78.5076 3.51989 78.6761C3.23243 78.8446 2.90843 78.9333 2.57882 78.9337V78.9338Z'
          fill='#101828'
        />
        <path
          d='M52.3983 44.643C49.6907 47.0585 46.4148 48.6748 42.909 49.3247L41.7778 42.7175C44.8949 42.1307 47.7216 40.4355 49.7784 37.9196C51.8353 35.4036 52.9957 32.2219 53.0627 28.9141L59.4862 29.054C59.3866 34.4445 57.3017 39.5874 53.6652 43.4133C53.2574 43.8438 52.8351 44.2537 52.3983 44.643Z'
          fill='#1570EF'
        />
        <path
          d='M61.3326 28.8624C60.2394 29.8326 58.9046 30.4584 57.4839 30.6666C56.0632 30.8748 54.6152 30.657 53.3085 30.0384C52.0018 29.4199 50.8903 28.4261 50.1035 27.173C49.3168 25.9198 48.8872 24.4589 48.8648 22.9605C48.8599 22.4285 49.0566 21.9161 49.4122 21.5352C49.7677 21.1543 50.2531 20.9358 50.7623 20.9275C51.2715 20.9193 51.7632 21.1218 52.1299 21.491C52.4966 21.8601 52.7086 22.3659 52.7195 22.8978C52.7348 23.9263 53.1406 24.9063 53.8476 25.6223C54.5546 26.3382 55.505 26.7315 56.4896 26.7155C57.4741 26.6995 58.4123 26.2756 59.0977 25.537C59.7831 24.7985 60.1595 23.8057 60.1442 22.7772C60.1363 22.2433 60.3317 21.7279 60.6875 21.3445C61.0433 20.961 61.5304 20.741 62.0415 20.7327C62.5527 20.7244 63.046 20.9285 63.4131 21.3002C63.7801 21.6719 63.9908 22.1807 63.9988 22.7146C64.0169 23.753 63.8377 24.7849 63.4716 25.7505C63.1054 26.7161 62.5595 27.5963 61.8655 28.34C61.6949 28.5239 61.5173 28.698 61.3326 28.8624Z'
          fill='#101828'
        />
        <path
          d='M47.5844 0.153656L45.836 5.45037C45.8249 5.48412 45.8215 5.52013 45.8262 5.55551C45.8308 5.59088 45.8433 5.62461 45.8628 5.65399C45.8822 5.68336 45.908 5.70756 45.938 5.72462C45.968 5.74168 46.0014 5.75113 46.0356 5.7522L47.2152 5.78897C47.2498 5.79005 47.2836 5.79973 47.314 5.81719C47.3443 5.83466 47.3702 5.85942 47.3895 5.88942C47.4088 5.91942 47.4211 5.95379 47.4252 5.98971C47.4292 6.02562 47.4251 6.06203 47.413 6.09594L45.4672 11.5681C45.449 11.6193 45.4491 11.6756 45.4673 11.7268C45.4856 11.778 45.5208 11.8206 45.5667 11.8469C45.6125 11.8732 45.6658 11.8814 45.7169 11.87C45.7681 11.8587 45.8136 11.8285 45.8452 11.7851L50.4267 5.47733C50.447 5.44938 50.4608 5.41691 50.4671 5.38246C50.4734 5.348 50.472 5.31249 50.463 5.27869C50.454 5.24489 50.4377 5.21371 50.4153 5.1876C50.3928 5.16149 50.3649 5.14114 50.3338 5.12814L48.9615 4.55622C48.9347 4.54503 48.9103 4.52839 48.8897 4.50725C48.869 4.48611 48.8526 4.46089 48.8414 4.43307C48.8302 4.40526 48.8244 4.37539 48.8244 4.34521C48.8243 4.31503 48.83 4.28514 48.8411 4.25727L50.3798 0.395996C50.3934 0.361965 50.3988 0.325007 50.3957 0.288295C50.3926 0.251583 50.3809 0.216214 50.3618 0.185225C50.3427 0.154236 50.3167 0.128553 50.286 0.110381C50.2553 0.0922096 50.2209 0.0820924 50.1856 0.0808984L47.7975 0.000118484C47.751 -0.00145132 47.7052 0.0126123 47.6668 0.0402455C47.6284 0.0678788 47.5996 0.107627 47.5844 0.153656Z'
          fill='#FDB022'
        />
        <path
          d='M2.92697 24.4627L4.96421 28.6016C4.97715 28.6279 4.98397 28.6571 4.98414 28.6868C4.98431 28.7164 4.97781 28.7457 4.96517 28.7722C4.95253 28.7988 4.93411 28.8219 4.91138 28.8396C4.88865 28.8573 4.86225 28.8693 4.8343 28.8744L3.86869 29.0504C3.84037 29.0556 3.81364 29.0677 3.79071 29.0858C3.76778 29.1039 3.7493 29.1275 3.73679 29.1545C3.72429 29.1816 3.71812 29.2114 3.7188 29.2414C3.71948 29.2714 3.72698 29.3008 3.7407 29.3272L5.96044 33.5858C5.98079 33.6257 5.98684 33.6718 5.9775 33.7159C5.96816 33.76 5.94404 33.7992 5.90949 33.8263C5.87495 33.8535 5.83225 33.8669 5.78909 33.864C5.74594 33.8612 5.70518 33.8424 5.67416 33.8109L1.19308 29.191C1.17328 29.1705 1.15827 29.1455 1.14921 29.118C1.14016 29.0904 1.1373 29.0611 1.14086 29.0322C1.14442 29.0033 1.1543 28.9757 1.16974 28.9515C1.18518 28.9272 1.20575 28.9071 1.22988 28.8925L2.29329 28.2527C2.31407 28.2402 2.33225 28.2234 2.34679 28.2035C2.36134 28.1836 2.37195 28.1608 2.37801 28.1366C2.38407 28.1123 2.38546 28.087 2.38211 28.0622C2.37875 28.0374 2.37072 28.0135 2.35846 27.992L0.656141 25.0075C0.641237 24.9812 0.632649 24.9515 0.63111 24.9209C0.629572 24.8904 0.635129 24.86 0.647306 24.8322C0.659484 24.8044 0.677921 24.7801 0.701042 24.7613C0.724163 24.7426 0.751282 24.73 0.780082 24.7246L2.73445 24.3632C2.7725 24.3562 2.8117 24.3621 2.84633 24.38C2.88096 24.3979 2.90922 24.4269 2.92697 24.4627Z'
          fill='#FDB022'
        />
        <path
          d='M10.8438 41.2748L15.1031 66.4385C15.6537 69.6914 17.2834 72.6379 19.7064 74.7607C22.1293 76.8835 25.1905 78.047 28.3528 78.0471C31.4359 78.0472 34.4255 76.9412 36.8214 74.9144C39.2173 72.8875 40.8742 70.0626 41.5149 66.9123L46.729 41.2744L10.8438 41.2748Z'
          fill='#1570EF'
        />
        <path
          d='M28.7863 46.8164C38.6957 46.8164 46.7289 44.3354 46.7289 41.2749C46.7289 38.2144 38.6957 35.7334 28.7863 35.7334C18.8769 35.7334 10.8438 38.2144 10.8438 41.2749C10.8438 44.3354 18.8769 46.8164 28.7863 46.8164Z'
          fill='#101828'
        />
        <path
          d='M35.1707 55.4941H22.612C21.4224 55.4941 20.458 56.5016 20.458 57.7443V64.0908C20.458 65.3335 21.4224 66.3409 22.612 66.3409H35.1707C36.3604 66.3409 37.3248 65.3335 37.3248 64.0908V57.7443C37.3248 56.5016 36.3604 55.4941 35.1707 55.4941Z'
          fill='#101828'
        />
        <path
          d='M27.5303 61.1197H26.7837C26.7837 60.0922 27.2293 58.6761 29.3524 57.7651C30.5961 57.2316 30.1844 55.2597 30.1799 55.2399L30.9077 55.0654C30.9313 55.1727 31.4665 57.7013 29.6362 58.486C28.2388 59.0856 27.5303 59.9721 27.5303 61.1197Z'
          fill='#1570EF'
        />
        <path
          d='M34.2293 66.7976C33.5032 65.3754 33.2002 65.4036 31.9225 65.5221C31.5065 65.5608 30.989 65.6089 30.3571 65.6089C27.529 65.6089 27.2347 63.4585 27.2319 63.4369L27.9725 63.3386L27.6022 63.3878L27.9721 63.335C27.9806 63.396 28.2067 64.829 30.3569 64.829C30.9556 64.829 31.4342 64.7844 31.8562 64.7453C33.2936 64.6116 33.9681 64.6286 34.8878 66.4306L34.2293 66.7976Z'
          fill='#1570EF'
        />
        <path
          d='M25.5982 61.5199C25.5727 61.5199 25.5476 61.5135 25.5249 61.5012L24.7179 61.0667C24.6802 61.0463 24.6518 61.0112 24.6389 60.9689C24.626 60.9267 24.6297 60.8808 24.6492 60.8414C24.6687 60.802 24.7023 60.7723 24.7428 60.7589C24.7832 60.7454 24.8271 60.7493 24.8648 60.7696L25.6718 61.2042C25.7038 61.2213 25.7293 61.2492 25.7442 61.2833C25.7591 61.3175 25.7626 61.3559 25.7541 61.3923C25.7456 61.4288 25.7256 61.4612 25.6973 61.4844C25.6691 61.5075 25.6341 61.52 25.5982 61.5199Z'
          fill='white'
        />
        <path
          d='M24.6234 63.1739C24.5861 63.1738 24.5499 63.16 24.5213 63.135C24.4927 63.11 24.4733 63.0754 24.4665 63.037C24.4598 62.9987 24.466 62.9591 24.4843 62.925C24.5025 62.891 24.5316 62.8648 24.5664 62.8508L25.4151 62.5133C25.4542 62.4998 25.4968 62.5026 25.5339 62.5211C25.5711 62.5396 25.6 62.5723 25.6146 62.6125C25.6293 62.6526 25.6286 62.6971 25.6127 62.7368C25.5967 62.7764 25.5668 62.8081 25.529 62.8252L24.6803 63.1628C24.6622 63.1701 24.6428 63.1738 24.6234 63.1739Z'
          fill='white'
        />
        <path
          d='M29.7276 62.4873C29.6875 62.4873 29.6489 62.4715 29.6194 62.4431C29.59 62.4147 29.5717 62.3758 29.5684 62.3341C29.5651 62.2923 29.5769 62.2508 29.6015 62.2177C29.6261 62.1846 29.6617 62.1624 29.7012 62.1554L30.5971 61.9973C30.6389 61.9902 30.6816 62.0006 30.716 62.0263C30.7504 62.052 30.7737 62.0908 30.7808 62.1344C30.7878 62.1779 30.7781 62.2227 30.7536 62.2587C30.7292 62.2948 30.6921 62.3193 30.6505 62.3269L29.7545 62.485C29.7456 62.4865 29.7366 62.4873 29.7276 62.4873Z'
          fill='white'
        />
        <path
          d='M29.0725 61.3551C29.0439 61.3551 29.0158 61.3471 28.9912 61.3319C28.9666 61.3167 28.9463 61.2949 28.9325 61.2687C28.9187 61.2425 28.9118 61.213 28.9127 61.1831C28.9135 61.1532 28.922 61.1242 28.9373 61.0989L29.422 60.2967C29.433 60.2776 29.4475 60.2611 29.4647 60.248C29.4819 60.2348 29.5014 60.2255 29.5221 60.2203C29.5428 60.2152 29.5643 60.2144 29.5853 60.2181C29.6063 60.2217 29.6264 60.2297 29.6444 60.2416C29.6624 60.2534 29.6779 60.2689 29.6901 60.2872C29.7023 60.3054 29.7109 60.3259 29.7154 60.3477C29.7199 60.3694 29.7202 60.3918 29.7163 60.4137C29.7124 60.4355 29.7043 60.4564 29.6926 60.4749L29.2079 61.2772C29.1934 61.301 29.1735 61.3207 29.1498 61.3344C29.1261 61.348 29.0995 61.3552 29.0725 61.3551Z'
          fill='white'
        />
        <path
          d='M38.205 60.5368C36.6518 60.5368 35.356 60.0936 34.3431 59.2138C32.4717 57.5882 32.2711 55.0791 32.2632 54.9731L33.0077 54.9146L32.6354 54.9439L33.0076 54.9141C33.0094 54.9363 33.1979 57.2093 34.8259 58.6191C35.8926 59.5422 37.3467 59.9064 39.1507 59.7011L39.2315 60.4765C38.8906 60.516 38.548 60.5361 38.205 60.5368Z'
          fill='#1570EF'
        />
        <path
          d='M18.7395 65.384L18.6016 64.6174C21.464 64.0552 21.4619 62.8112 21.4595 61.3709C21.4579 60.4009 21.4559 59.3015 22.5499 58.6586C24.5844 57.463 24.4215 54.7529 24.4196 54.7255L25.1641 54.667C25.1736 54.7981 25.3701 57.896 22.9159 59.3382C22.2379 59.7367 22.2044 60.3696 22.2061 61.3693C22.2087 62.8542 22.2117 64.7019 18.7395 65.384Z'
          fill='#1570EF'
        />
        <path
          d='M40.8044 43.0043C40.7901 43.0043 40.7758 43.0028 40.7617 42.9998C39.9536 42.83 38.4288 42.1084 38.4425 40.5913C38.4414 40.294 38.509 40.0007 38.6397 39.7365C38.7704 39.4723 38.9603 39.2451 39.1932 39.074C38.9549 38.6126 38.8844 38.0768 38.9947 37.5654C39.2995 35.9062 40.8986 35.6155 41.702 35.6942C41.7581 35.6999 41.8098 35.7285 41.8458 35.7739C41.8818 35.8194 41.899 35.8778 41.8938 35.9365C41.8887 35.9952 41.8614 36.0493 41.8181 36.0871C41.7748 36.1248 41.7189 36.1431 41.6627 36.138C41.5839 36.1304 39.7215 35.9748 39.4136 37.6494C39.3228 38.0535 39.3744 38.4783 39.5591 38.8459C40.351 38.4468 41.4356 38.4448 41.9335 38.8425C42.0333 38.9138 42.1109 39.014 42.157 39.1308C42.203 39.2476 42.2154 39.3758 42.1926 39.4999C42.093 40.0425 41.4833 40.3132 40.7113 40.1584C40.2253 40.0657 39.7806 39.8123 39.443 39.4356C39.2642 39.5657 39.1186 39.7393 39.0186 39.9414C38.9186 40.1436 38.8673 40.3681 38.8691 40.5957C38.8554 42.1221 40.8263 42.5588 40.8461 42.563C40.898 42.5739 40.9442 42.6045 40.9757 42.649C41.0072 42.6935 41.0217 42.7486 41.0165 42.8036C41.0112 42.8586 40.9866 42.9096 40.9473 42.9468C40.9081 42.9839 40.857 43.0044 40.8041 43.0044L40.8044 43.0043ZM39.8276 39.2079C40.0967 39.4701 40.431 39.6477 40.7923 39.7205C41.3012 39.8222 41.7236 39.6914 41.7743 39.4156C41.7819 39.3728 41.7764 39.3285 41.7586 39.2892C41.7407 39.2498 41.7114 39.2173 41.6749 39.1964C41.3528 38.939 40.5134 38.8971 39.8276 39.2079Z'
          fill='#F97066'
        />
        <path
          d='M45.9301 22.7766C47.0222 30.8434 39.7757 35.8736 30.1884 37.2898C20.6011 38.7059 12.3039 35.9722 11.2119 27.9048C10.1199 19.8374 16.6466 9.49135 26.2338 8.0752C35.821 6.65904 44.8381 14.7092 45.9301 22.7766Z'
          fill='#1570EF'
        />
        <path
          d='M36.7332 14.1503L26.859 15.6088C23.764 16.0659 21.6097 19.0575 22.0473 22.2907C22.4849 25.5239 25.3488 27.7743 28.4438 27.3171L38.318 25.8587C41.4131 25.4015 43.5674 22.4099 43.1297 19.1768C42.6921 15.9436 39.8283 13.6932 36.7332 14.1503Z'
          fill='white'
        />
        <path
          d='M25.3848 1.83206L24.6191 1.98535L26.0979 10.0447L26.8635 9.89137L25.3848 1.83206Z'
          fill='#1570EF'
        />
        <path
          d='M25.1113 3.99331C25.8982 3.99331 26.5361 3.32695 26.5361 2.50496C26.5361 1.68296 25.8982 1.0166 25.1113 1.0166C24.3244 1.0166 23.6865 1.68296 23.6865 2.50496C23.6865 3.32695 24.3244 3.99331 25.1113 3.99331Z'
          fill='#101828'
        />
        <path
          d='M30.4594 23.6405C30.3209 23.6406 30.186 23.5934 30.0754 23.5062L25.8825 20.2118C25.8141 20.1595 25.7564 20.0935 25.7126 20.0177C25.6688 19.942 25.6399 19.8578 25.6275 19.7703C25.6151 19.6827 25.6195 19.5935 25.6403 19.5077C25.6612 19.4219 25.6982 19.3413 25.7491 19.2706C25.8001 19.1998 25.864 19.1404 25.9371 19.0956C26.0102 19.0509 26.0911 19.0217 26.175 19.0099C26.259 18.9981 26.3444 19.0038 26.4262 19.0267C26.5081 19.0496 26.5848 19.0893 26.6518 19.1434L30.8446 22.438C30.9521 22.5222 31.0315 22.6397 31.0716 22.7738C31.1116 22.9078 31.1102 23.0516 31.0675 23.1848C31.0249 23.318 30.9432 23.4337 30.8341 23.5157C30.7249 23.5976 30.5939 23.6416 30.4594 23.6413V23.6405Z'
          fill='#101828'
        />
        <path
          d='M26.7859 24.1834C26.6671 24.1833 26.5506 24.1486 26.4495 24.0832C26.3484 24.0178 26.2668 23.9243 26.2137 23.8132C26.1607 23.702 26.1383 23.5777 26.1491 23.454C26.16 23.3303 26.2036 23.2122 26.275 23.113L29.4291 18.733C29.5317 18.593 29.6831 18.5009 29.8504 18.4769C30.0177 18.4529 30.1873 18.499 30.3222 18.605C30.4571 18.711 30.5464 18.8685 30.5707 19.043C30.595 19.2175 30.5523 19.395 30.4519 19.5368L27.2979 23.9168C27.2383 23.9997 27.161 24.067 27.0721 24.1133C26.9832 24.1596 26.8853 24.1836 26.7859 24.1834Z'
          fill='#101828'
        />
        <path
          d='M39.5644 22.2952C39.4258 22.2953 39.291 22.2481 39.1804 22.1609L34.9877 18.8662C34.92 18.8136 34.8629 18.7476 34.8197 18.6719C34.7765 18.5962 34.7481 18.5124 34.7361 18.4252C34.724 18.3379 34.7286 18.2491 34.7495 18.1638C34.7704 18.0784 34.8073 17.9982 34.858 17.9278C34.9087 17.8574 34.9722 17.7981 35.0449 17.7535C35.1176 17.7088 35.1981 17.6796 35.2816 17.6675C35.3651 17.6554 35.4502 17.6606 35.5317 17.683C35.6133 17.7053 35.6899 17.7443 35.757 17.7976L39.9498 21.0924C40.0573 21.1766 40.1367 21.2941 40.1767 21.4281C40.2168 21.5622 40.2154 21.706 40.1727 21.8392C40.1301 21.9723 40.0484 22.0881 39.9393 22.17C39.8301 22.252 39.699 22.296 39.5646 22.2957L39.5644 22.2952Z'
          fill='#101828'
        />
        <path
          d='M35.891 22.8381C35.7721 22.838 35.6556 22.8034 35.5545 22.738C35.4534 22.6726 35.3718 22.5791 35.3187 22.4679C35.2657 22.3568 35.2433 22.2324 35.2541 22.1087C35.2649 21.985 35.3085 21.8669 35.38 21.7677L38.534 17.3877C38.636 17.2461 38.7878 17.1525 38.9559 17.1277C39.1239 17.1029 39.2945 17.1488 39.4302 17.2554C39.5658 17.362 39.6554 17.5205 39.6791 17.696C39.7029 17.8716 39.6589 18.0498 39.5569 18.1915L36.4029 22.5715C36.3433 22.6544 36.266 22.7217 36.1771 22.768C36.0883 22.8143 35.9903 22.8383 35.891 22.8381Z'
          fill='#101828'
        />
        <path
          d='M33.4747 29.1451C33.6673 28.8787 33.9285 28.675 34.2277 28.5577C34.5268 28.4403 34.8518 28.4143 35.1646 28.4825C35.4775 28.5506 35.7653 28.7103 35.9946 28.9428C36.2239 29.1753 36.3852 29.4711 36.4598 29.7958C36.4733 29.8532 36.4759 29.9129 36.4673 29.9713C36.4587 30.0298 36.4391 30.0859 36.4096 30.1364C36.3802 30.1869 36.3415 30.2309 36.2957 30.2657C36.2499 30.3005 36.198 30.3256 36.1429 30.3394C36.0879 30.3531 36.0307 30.3554 35.9748 30.3461C35.9189 30.3367 35.8653 30.3159 35.8172 30.2848C35.769 30.2537 35.7272 30.213 35.6941 30.1649C35.6611 30.1169 35.6375 30.0625 35.6246 30.0049C35.5993 29.8946 35.5534 29.7905 35.4896 29.6987C35.4258 29.6069 35.3453 29.5291 35.2527 29.4698C35.1601 29.4105 35.0572 29.3708 34.95 29.3531C34.8427 29.3353 34.7331 29.3397 34.6275 29.3662C34.5219 29.3926 34.4223 29.4406 34.3344 29.5072C34.2465 29.5739 34.172 29.658 34.1153 29.7547C34.0585 29.8514 34.0205 29.9589 34.0035 30.0709C33.9865 30.183 33.9907 30.2975 34.016 30.4078C34.0418 30.5232 34.0228 30.6445 33.9632 30.7453C33.9036 30.8461 33.8082 30.9182 33.6979 30.9458C33.5876 30.9734 33.4714 30.9543 33.3745 30.8927C33.2776 30.8311 33.208 30.732 33.1809 30.6169C33.1287 30.3921 33.1198 30.1586 33.1546 29.9301C33.1894 29.7016 33.2672 29.4826 33.3835 29.2857C33.4118 29.2371 33.4422 29.1902 33.4747 29.1451Z'
          fill='#101828'
        />
        <path
          d='M22.5705 41.3309C22.5257 41.3309 22.4821 41.3161 22.4457 41.2888C22.4094 41.2615 22.3823 41.2229 22.3681 41.1785C22.3539 41.1341 22.3535 41.0862 22.3669 41.0416C22.3802 40.9969 22.4067 40.9578 22.4425 40.9298C22.4885 40.8935 23.5624 40.0286 22.9518 38.9791C22.8209 38.7342 22.6154 38.5421 22.3679 38.4332C22.1013 39.0683 21.4983 39.6254 21.0247 39.6669C20.9249 39.6808 20.8234 39.6639 20.7327 39.6184C20.6419 39.5729 20.5659 39.5007 20.5138 39.4106C20.4554 39.3046 20.4258 39.1839 20.4282 39.0615C20.4305 38.9392 20.4648 38.8198 20.5273 38.7163C20.765 38.2627 21.3563 37.9236 21.9331 37.91C21.9811 37.9091 22.0291 37.91 22.076 37.9133C22.1028 37.7618 22.0954 37.606 22.0545 37.458C22.0136 37.3101 21.9402 37.1741 21.8403 37.0609C21.1387 36.2356 19.8717 37.0091 19.8591 37.017C19.8351 37.032 19.8084 37.0418 19.7807 37.0461C19.753 37.0503 19.7247 37.0487 19.6975 37.0416C19.6704 37.0344 19.6449 37.0217 19.6224 37.0042C19.5999 36.9867 19.581 36.9647 19.5667 36.9396C19.5523 36.9144 19.5429 36.8866 19.5388 36.8576C19.5348 36.8286 19.5363 36.7991 19.5432 36.7708C19.55 36.7424 19.5622 36.7157 19.579 36.6923C19.5957 36.6688 19.6167 36.649 19.6408 36.634C19.7048 36.594 21.2262 35.6672 22.1592 36.7649C22.3039 36.9301 22.4095 37.1283 22.4677 37.3438C22.5258 37.5593 22.5349 37.786 22.4941 38.0059C22.8411 38.1477 23.1312 38.4093 23.3164 38.747C23.967 39.8643 23.1965 40.8961 22.6985 41.2856C22.6617 41.3149 22.6167 41.3308 22.5705 41.3309ZM21.9305 38.3567C21.4662 38.3723 21.0435 38.6596 20.9011 38.9311C20.8761 38.9666 20.8608 39.0084 20.8568 39.0522C20.8527 39.0961 20.8601 39.1402 20.8782 39.18C20.8888 39.1988 20.9076 39.2295 20.9893 39.2232C21.2567 39.2001 21.6959 38.8288 21.9303 38.3567H21.9305Z'
          fill='#F97066'
        />
      </g>
      <defs>
        <clipPath id='clip0_24639_10666'>
          <rect width='64' height='78.9333' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
});
