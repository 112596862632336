import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';
import { fetchUserProductConfigAtom } from 'data/user/atoms/userProductTourConfigAtom';

export function useFetchUserProductTourConfig() {
  const { idToken } = useAtomValue(idTokenAtom);
  const fetchConfig = useSetAtom(fetchUserProductConfigAtom);

  useEffect(() => {
    if (!idToken) {
      return;
    }
    fetchConfig({});
  }, [idToken, fetchConfig]);
}

export function useUserProductTourConfig() {
  const { config, fetchStatus } = useAtomValue(fetchUserProductConfigAtom);

  return { config, fetchStatus };
}
