import { useEffect } from 'react';

import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { ContactSupport } from 'components/ContactSupport/ContactSupport';
import { useStyledToast } from 'components/Toast';
import { Flex, Text } from 'components/uikit';
import {
  CONSECUTIVE_RELOADS_LIMIT,
  pageReloadAttemptsAtom,
} from 'data/loadErrors/atoms/pageReloadAttempts';

export function useMultipleReloadsToast() {
  const { t } = useTranslation('common');
  const pageReloadAttempts = useAtomValue(pageReloadAttemptsAtom);
  const multipleReloadsToast = useStyledToast();

  useEffect(() => {
    if (pageReloadAttempts >= CONSECUTIVE_RELOADS_LIMIT) {
      multipleReloadsToast({
        title: t('multiple_reloads_toast_title'),
        description: (
          <Flex gap='12px' direction='column' alignItems='flex-start'>
            <Text color='grey.tertiaryText' fontSize='sm'>
              {t('multiple_reloads_toast_description')}
            </Text>
            <ContactSupport />
          </Flex>
        ),
        variant: 'ERROR',
        duration: 10000, // 10s
      });
    }
  }, [pageReloadAttempts, multipleReloadsToast, t]);
}
