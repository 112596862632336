import { fetchCustomerCompanyByDepartmentIds } from 'api/rest/customer/companies/fetchCustomerCompanyByDepartmentIds';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { Department } from 'data/blocks/models/Block';
import { parseItemStrict } from 'utils/parser/parseResponse';

export type FetchCustomerCompanyByDepartmentIdParams = {
  department: Department;
  departmentId: string;
};

export async function fetchCustomerCompanyByDepartmentId({
  department,
  departmentId,
}: FetchCustomerCompanyByDepartmentIdParams): Promise<CustomerCompanyResponse> {
  const result = await fetchCustomerCompanyByDepartmentIds({
    department,
    departmentIds: [departmentId],
  });

  if (!result.companies?.[0]) {
    throw new Error('Failed to fetch customer company');
  }

  return parseItemStrict(CustomerCompanyResponse, {
    company: result.companies?.[0],
  });
}
