import { authorisedFetch } from 'api/rest/authorisedFetch';
import { PROTECTED_API_URL } from 'data/constants';
import { userProductTourConfigAtom } from 'data/user/atoms/userProductTourConfigAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { ProductTourConfig } from '../models';

type ProductTourConfigPayload = {
  config: ProductTourConfig;
};

async function updateUserProductTourConfig(
  variables: ProductTourConfig,
): Promise<ProductTourConfigPayload> {
  const response = await authorisedFetch(
    `${PROTECTED_API_URL}/companies-new/profile/tour`,
    {
      method: 'PUT',
      body: JSON.stringify(variables),
    },
  );

  if (response.status > 399) {
    const responseBody = await response.text();
    throw new Error(`Failed to get product config : ${responseBody}`);
  }

  const config = parseItemStrict(ProductTourConfig, await response.json());
  return { config };
}

export const updateUserProductConfigAtom = getDataFetchingAtom<
  ProductTourConfigPayload,
  ProductTourConfig
>(userProductTourConfigAtom, updateUserProductTourConfig);
