import { useRouter } from 'next/router';

import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import { iconsByType } from 'components/CommandPanel/Modal/ListItems/UserMenuListItem';
import { InsightCommandPanelOption } from 'data/commandPanel/models';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';
import { formatDate } from 'utils/datetime';

type InsightMenuListItemProps = {
  item: InsightCommandPanelOption;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
};

export function InsightMenuListItem({
  item,
  index,
  isSelected,
  onSelect,
  onClose,
}: InsightMenuListItemProps) {
  const router = useRouter();
  const onClick = async () => {
    await router.push(`/radar/${item.id}`);
  };
  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.INSIGHT_SELECTED}
      icon={iconsByType.INSIGHT}
      id={item.id}
      name={item.title}
      subTitle={formatDate(item.insertedAt, 'DAY')}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={() => {
        onClick();
        onClose();
      }}
    />
  );
}
