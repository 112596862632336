import { DepartmentIdsArrays } from 'data/customer/DepartmentIdsArrays';

export function getCustomerName(
  customer?: DepartmentIdsArrays & { name: string },
  fallback = 'Loading...',
) {
  return customer?.name
    ? customer.name
    : customer?.financeIds[0] ?? customer?.productIds[0] ?? fallback;
}
