import { ReactNode } from 'react';

import { BoxProps } from '@chakra-ui/react';
import { BorderProps } from '@chakra-ui/styled-system';

import { DateRangeControlOptions } from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownHeader } from 'components/charts/FullBreakdownTable/FullBreakdownHeader';
import { FullBreakdownTableBody } from 'components/charts/FullBreakdownTable/FullBreakdownTableBody';
import { FullBreakdownTableHeader } from 'components/charts/FullBreakdownTable/FullBreakdownTableHeader';
import { LoadableComponent } from 'components/LoadableComponent';
import { Table, TableContainer } from 'components/uikit';
import { Filter, SortArray } from 'data/charts/models/ChartsApiRequest';
import { BreakdownResponse } from 'data/charts/models/ChartsApiResponse';
import { RowConfig, TableConfig } from 'data/charts/models/TableConfig';
import { TrackingLabel } from 'utils/analytics/trackingLabels';
import { FetchStatus } from 'utils/requests/types';
import { isFetchError } from 'utils/types';

type Props<T> = {
  headingPadding?: BoxProps['padding'];
  title?: string;
  subTitle?: string;
  isDemo?: boolean;
  controls?: ReactNode;
  blockId?: string;
  trackingLabel: TrackingLabel;
  fetchStatus: FetchStatus;
  config: TableConfig<T>;
  rowConfig?: RowConfig<T>;
  data: BreakdownResponse<T, unknown>;
  numRows?: number;
  lastRow?: ReactNode;
  footer?: ReactNode;
  width?: string;
  sorts?: SortArray;
  filters?: Filter[];
  resetFilters?: Record<keyof T, () => void>;
  onSortChange?: (sorts: SortArray) => void;
  onResetTable?: () => void;
  dateRangeControl: DateRangeControlOptions;
  borderRadius?: string;
  borderWidth?: BorderProps['borderWidth'];
};

export function FullBreakdownTable<T>({
  config,
  rowConfig,
  trackingLabel,
  headingPadding,
  data,
  isDemo = false,
  fetchStatus,
  lastRow,
  title,
  subTitle,
  controls,
  width,
  numRows,
  sorts,
  filters,
  resetFilters,
  onSortChange,
  onResetTable,
  blockId,
  footer,
  dateRangeControl,
  borderRadius = '8px',
  borderWidth = '1px',
}: Props<T>) {
  const hasError = isFetchError(fetchStatus);
  const hasHeader = title || controls;

  const tableContainerProps = hasHeader
    ? {
        borderTopWidth: '1px',
        borderTopColor: 'grey.border',
      }
    : {
        borderTopRadius: 'inherit',
      };

  return (
    <LoadableComponent
      id={`mission-control-breakdown-loadable-${width}`}
      trackingLabel={trackingLabel}
      hasError={hasError}
      positioning='fullBreakdownTable'
      width={width}
      bgColor='grey.white'
      borderWidth={borderWidth}
      borderColor='grey.border'
      borderRadius={borderRadius}
      display='flex'
      flexDirection='column'
    >
      {hasHeader ? (
        <FullBreakdownHeader
          isDemo={isDemo}
          headingPadding={headingPadding}
          title={title}
          subTitle={subTitle}
          onResetTable={onResetTable}
          resetFilters={resetFilters}
          controls={controls}
          blockId={blockId}
          hasError={hasError}
          config={config}
          sorts={sorts}
          filters={filters}
        />
      ) : null}
      <TableContainer
        {...tableContainerProps}
        borderBottomRadius={footer ? '0' : 'inherit'}
      >
        <Table variant='unstyled' width='100%' borderRadius='0'>
          <FullBreakdownTableHeader
            title={title}
            config={config}
            sorts={sorts}
            onSortChange={onSortChange}
          />
          <FullBreakdownTableBody
            numRows={numRows}
            fetchStatus={fetchStatus}
            config={config}
            rowConfig={rowConfig}
            dateRangeControl={dateRangeControl}
            rows={data.rows}
            maxWidth='100%'
          />
          {lastRow}
        </Table>
      </TableContainer>
      {footer}
    </LoadableComponent>
  );
}
