import { createIcon } from '@chakra-ui/react';

export const CloudSearch = createIcon({
  displayName: 'CloudSearchIcon',
  viewBox: '0 0 130 104',
  path: (
    <>
      <circle cx='64.9999' cy='41.4735' r='41.0526' fill='#F2F4F7' />
      <g filter='url(#filter0_dd_9008_193891)'>
        <path
          d='M66.2632 13.0522C57.7584 13.0522 50.2352 17.2548 45.6569 23.6966C44.1619 23.3441 42.6028 23.1575 41.0001 23.1575C29.8381 23.1575 20.7896 32.2061 20.7896 43.368C20.7896 54.53 29.8381 63.5786 41.0001 63.5786L91.5264 63.5786C101.293 63.5786 109.211 55.6611 109.211 45.8943C109.211 36.1276 101.293 28.2101 91.5264 28.2101C90.8326 28.2101 90.1482 28.2501 89.4753 28.3278C85.604 19.3425 76.6682 13.0522 66.2632 13.0522Z'
          fill='#F9FAFB'
        />
        <circle
          cx='41.0001'
          cy='43.3682'
          r='20.2105'
          fill='url(#paint0_linear_9008_193891)'
        />
        <ellipse
          cx='66.2632'
          cy='38.3154'
          rx='25.2632'
          ry='25.2632'
          fill='url(#paint1_linear_9008_193891)'
        />
        <ellipse
          cx='91.5265'
          cy='45.8942'
          rx='17.6842'
          ry='17.6842'
          fill='url(#paint2_linear_9008_193891)'
        />
      </g>
      <ellipse
        cx='21.579'
        cy='15.421'
        rx='3.94737'
        ry='3.94737'
        fill='#F2F4F7'
      />
      <ellipse
        cx='19.2106'
        cy='86.4736'
        rx='5.52632'
        ry='5.52632'
        fill='#F2F4F7'
      />
      <ellipse
        cx='119.474'
        cy='28.0527'
        rx='5.52632'
        ry='5.52632'
        fill='#F2F4F7'
      />
      <circle cx='110.789' cy='6.73651' r='3.15789' fill='#F2F4F7' />
      <g filter='url(#filter1_b_9008_193891)'>
        <rect
          x='46.0527'
          y='49.3682'
          width='37.8947'
          height='37.8947'
          rx='18.9474'
          fill='#364152'
          fillOpacity='0.5'
        />
        <path
          d='M72.5 75.8154L69.5834 72.8988M71.6667 67.8988C71.6667 71.8108 68.4954 74.9821 64.5833 74.9821C60.6713 74.9821 57.5 71.8108 57.5 67.8988C57.5 63.9867 60.6713 60.8154 64.5833 60.8154C68.4954 60.8154 71.6667 63.9867 71.6667 67.8988Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_9008_193891'
          x='0.789551'
          y='13.0522'
          width='128.421'
          height='90.5264'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_9008_193891'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='4' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_9008_193891'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_9008_193891'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_9008_193891'
            result='effect2_dropShadow_9008_193891'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_9008_193891'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_9008_193891'
          x='38.0527'
          y='41.3682'
          width='53.8948'
          height='53.8945'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='4' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_9008_193891'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_9008_193891'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_9008_193891'
          x1='25.4813'
          y1='30.0149'
          x2='61.2106'
          y2='63.5787'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D0D5DD' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_9008_193891'
          x1='46.8647'
          y1='21.6237'
          x2='91.5263'
          y2='63.5785'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D0D5DD' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_9008_193891'
          x1='77.9475'
          y1='34.21'
          x2='109.211'
          y2='63.5783'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D0D5DD' />
          <stop offset='0.350715' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  ),
});
