import { useCallback, useEffect } from 'react';

import { useCompanyDrawerState } from 'components/CustomerDrawer/hooks/useCompanyDrawerState';
import { Department } from 'data/blocks/models/Block';
import { useFetchCustomerCompany } from 'data/healthReports/hooks/company/useFetchCustomerCompany';
import { isSuccess } from 'utils/types';

interface OnCustomerCompanyDrawerOpenProps {
  department: Department;
  departmentId: string;
  onDataLoaded: () => void;
}

export function useOnCustomerCompanyDrawerOpen({
  department,
  departmentId,
  onDataLoaded,
}: OnCustomerCompanyDrawerOpenProps) {
  const { company, fetchStatus } = useFetchCustomerCompany(
    department,
    departmentId,
  );
  const { onOpen: openDrawer } = useCompanyDrawerState();

  useEffect(() => {
    if (isSuccess(fetchStatus)) {
      onDataLoaded();
    }
  }, [fetchStatus, onDataLoaded]);

  return useCallback(() => {
    if (!isSuccess(fetchStatus) || !company) {
      return;
    }

    openDrawer(company);
  }, [company, fetchStatus, openDrawer]);
}
