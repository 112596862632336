import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CHARTS_API_URL } from 'data/constants';
import { ChartSettings } from 'data/settings/models/ChartSettings';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function fetchChartSettings(): Promise<ChartSettings> {
  const result = await authorisedFetch(`${CHARTS_API_URL}/settings`, {
    method: 'GET',
  });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch chart settings: ${result.status}`);
  }

  return parseItemStrict(ChartSettings, data, false);
}
