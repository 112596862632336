import { TableBodyProps } from '@chakra-ui/react';

import { BreakdownTableBodySkeleton } from 'components/charts/BreakdownTable/BreakdownTableBodySkeleton';
import {
  BreakdownTableStub,
  DateRangeControlOptions,
} from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownTableRow } from 'components/charts/FullBreakdownTable/FullBreakdownTableRow';
import { Box, Tbody, Td, Tr } from 'components/uikit';
import { RowConfig, TableConfig } from 'data/charts/models/TableConfig';
import { FetchStatus } from 'utils/requests/types';
import { isFetchError, isLoading } from 'utils/types';

type Props<T> = {
  config: TableConfig<T>;
  rowConfig?: RowConfig<T>;
  rows: T[];
  numRows?: number;
  fetchStatus: FetchStatus;
  dateRangeControl: DateRangeControlOptions;
} & TableBodyProps;

export function FullBreakdownTableBody<T>({
  config,
  rowConfig,
  rows,
  fetchStatus,
  numRows = rows.length,
  dateRangeControl,
  ...tableBodyProps
}: Props<T>) {
  const hasError = isFetchError(fetchStatus);
  const filteredConfig = config.filter((cellConfig) => !cellConfig.hidden);
  if (isLoading(fetchStatus) || hasError) {
    return (
      <Tbody {...tableBodyProps}>
        <BreakdownTableBodySkeleton
          cellCount={filteredConfig.length}
          cellHeight={'20px'}
          height={`${numRows * 52.1}px`}
          hasError={hasError}
        />
      </Tbody>
    );
  }
  if (fetchStatus === 'SUCCESS') {
    if (rows.length > 0) {
      return (
        <Tbody {...tableBodyProps}>
          {rows.map((row, rowIndex) => (
            <FullBreakdownTableRow
              key={rowIndex}
              row={row}
              config={filteredConfig}
              rowConfig={rowConfig}
            />
          ))}
          {rows.length < numRows &&
            Array.from({ length: numRows - rows.length }).map(
              (row, rowIndex) => (
                <Tr key={rowIndex} h='32px' minH='32px' textTransform='none'>
                  <Td h='32px' minH='32px' colSpan={filteredConfig.length}>
                    <Box height='20px'></Box>
                  </Td>
                </Tr>
              ),
            )}
        </Tbody>
      );
    } else {
      return (
        <Tbody {...tableBodyProps}>
          <BreakdownTableStub
            dateRangeControl={dateRangeControl}
            colSpan={filteredConfig.length}
            height={`${52 * numRows}px`}
          />
        </Tbody>
      );
    }
  }

  return (
    <Tbody {...tableBodyProps}>
      {Array.from({ length: numRows }).map((row, rowIndex) => (
        <Tr key={rowIndex} h='32px' minH='32px' textTransform='none'>
          <Td h='32px' minH='32px' colSpan={filteredConfig.length}>
            <Box height='20px'></Box>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
}
