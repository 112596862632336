import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { CustomerDataBlock } from 'data/healthReports/atoms/customerChartAtom';
import { parseItemStrict } from 'utils/parser/parseResponse';

export const CustomersChartConfig = z.object({});

export type CustomersChartConfig = z.infer<typeof CustomersChartConfig>;

export type CustomerBlockResponse<T extends CustomerDataBlock> = BlockResponse<
  z.infer<T>
>;

export async function fetchCustomersChart<T extends CustomerDataBlock>(
  blockId: string,
  chartConfig: CustomersChartConfig,
  blockResponseParser: T,
): Promise<CustomerBlockResponse<T>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      blocks: [{ blockId: blockId, chartConfig }],
    }),
  });

  const json = await result.json();
  const [data] = parseItemStrict(
    z.tuple([BlockResponse(blockResponseParser)]),
    json,
  );

  return data;
}
