import { useEffect, useRef } from 'react';

import { deepEqual } from 'utils/helpers';

// runs on change of dependencies, but not on mount
export function useDidUpdateEffect(fn: () => void, dependencies: unknown[]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);
}

// TODO: remove usages of this one and then remove the hook
export function useDidUpdateEffectWithDeepEqual(
  fn: () => void,
  dependencies: unknown[],
) {
  const didMountRef = useRef(false);
  const prevDepsRef = useRef(dependencies);
  const dependenciesUpdated = !deepEqual(prevDepsRef.current, dependencies);

  useEffect(() => {
    if (didMountRef.current) {
      if (dependenciesUpdated) {
        fn();
        prevDepsRef.current = dependencies;
      }
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesUpdated, ...dependencies]);
}
