export type Sentiment = 'GOOD' | 'BAD' | 'NEUTRAL';

export function getSentiment(
  value: number,
  isInverseMetric = false,
): Sentiment {
  if (value > 0) return isInverseMetric ? 'BAD' : 'GOOD';
  if (value < 0) return isInverseMetric ? 'GOOD' : 'BAD';
  return 'NEUTRAL';
}
