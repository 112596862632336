import { createIcon } from '@chakra-ui/react';

export const ArrowsUpDownIcon = createIcon({
  displayName: 'ArrowsUpDownIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        d='M3.05338 10.5V1.5M3.05338 1.5L0.75 3.80238M3.05338 1.5L5.35688 3.80238'
        fill='none'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.94662 1.5L8.94662 10.5M8.94662 10.5L11.25 8.19762M8.94662 10.5L6.64312 8.19762'
        fill='none'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
