import { createIcon } from '@chakra-ui/react';

export const WriteIcon = createIcon({
  displayName: 'WriteIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M10 16.6662H17.5M13.75 2.9162C14.0815 2.58468 14.5312 2.39844 15
         2.39844C15.2321 2.39844 15.462 2.44416 15.6765 2.533C15.891 2.62184
         16.0858 2.75205 16.25 2.9162C16.4142 3.08036 16.5444 3.27523 16.6332
         3.48971C16.722 3.70418 16.7678 3.93406 16.7678 4.1662C16.7678 4.39835
         16.722 4.62822 16.6332 4.8427C16.5444 5.05718 16.4142 5.25205 16.25
         5.4162L5.83333 15.8329L2.5 16.6662L3.33333 13.3329L13.75 2.9162Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  ),
});
