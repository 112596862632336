import { useCallback, useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useStyledToast } from 'components/Toast';
import { Department } from 'data/blocks/models/Block';
import {
  UpdateCompanyOnboardingParams,
  UsedProvider,
} from 'data/user/models/onboarding';

import {
  activateMainProductAtom,
  companyOnboardingAtom,
  fetchCompanyOnboardingAtom,
  fetchUsedProvidersAtom,
  selectUsedProvidersAtom,
  updateCompanyOnboardingAtom,
} from '../atoms/companyOnboardingAtom';
import { useCurrentCompanyNamespace } from './useCurrentCompany';

export const useCompanyOnboarding = () => {
  return useAtomValue(companyOnboardingAtom);
};

export const useFetchCompanyOnboarding = () => {
  const company = useCurrentCompanyNamespace();
  const fetchCompanyOnboarding = useSetAtom(fetchCompanyOnboardingAtom);

  useEffect(() => {
    if (company) fetchCompanyOnboarding(company);
  }, [company, fetchCompanyOnboarding]);
};

export const useUpdateCompanyStep = () => {
  const companyNamespace = useCurrentCompanyNamespace();
  const [{ progress: currentProgress }, update] = useAtom(
    updateCompanyOnboardingAtom,
  );

  return useCallback(
    async (params: Omit<UpdateCompanyOnboardingParams, 'companyNamespace'>) => {
      if (companyNamespace) {
        const progress = currentProgress == 100 ? 100 : params.progress;
        await update({ ...params, progress, companyNamespace });
      }
    },
    [companyNamespace, currentProgress, update],
  );
};

export function useActivateMainProduct() {
  const companyNamespace = useCurrentCompanyNamespace();
  const activateMainProduct = useSetAtom(activateMainProductAtom);

  return useCallback(async () => {
    if (companyNamespace) {
      await activateMainProduct(companyNamespace);
    }
  }, [companyNamespace, activateMainProduct]);
}

export function useFetchUsedProviders() {
  const [{ fetchStatus, errorMessage, ...providers }, fetchProviders] = useAtom(
    fetchUsedProvidersAtom,
  );

  useEffect(() => {
    if (fetchStatus === 'INITIAL') {
      fetchProviders();
    }
  }, [fetchStatus, fetchProviders]);

  return { providers, fetchStatus, errorMessage, fetchProviders };
}

export function useSelectUsedProviders() {
  const selectProviders = useSetAtom(selectUsedProvidersAtom);
  const toast = useStyledToast();
  const { t } = useTranslation('onboarding');

  return useCallback(
    async (department: Department, providers: UsedProvider[]) => {
      try {
        await selectProviders({ department, providers });
      } catch (e) {
        toast({
          title: t('select_used_provider_save_error'),
          description: `${t('common:something_went_wrong')}\n ${e}`,
          variant: 'ERROR',
          duration: 9000,
        });
      }
    },
    [selectProviders, t, toast],
  );
}
