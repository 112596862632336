import { ReactNode } from 'react';

import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import { useOnCustomerUserDrawerOpen } from 'components/CustomerDrawer/hooks/useOnCustomerUserDrawerOpen';
import {
  AlertIcon,
  BookIcon,
  DatabaseIcon,
  HorizontalBarChartIcon,
  MagicWandIcon,
  StarryStarryNightIcon,
  UserIcon,
  UserPlusIcon,
  VerticalBarChartIcon,
} from 'components/uikit';
import { CompanyIcon } from 'components/uikit/icons/Company';
import { TrophyIcon } from 'components/uikit/icons/TrophyIcon';
import {
  ActionCommandPanelOptionType,
  CustomerCommandPanelOption,
  CustomerCommandPanelOptionType,
} from 'data/commandPanel/models';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';
import { noop } from 'utils/helpers';

export type CustomerItemProps = {
  item: CustomerCommandPanelOption;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
};

export const iconsByType: Record<
  | ActionCommandPanelOptionType
  | CustomerCommandPanelOptionType
  | 'METRIC'
  | 'INSIGHT'
  | 'LEADERBOARD'
  | 'BREAKDOWN',
  ReactNode
> = {
  USER: <UserIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  COMPANY: <CompanyIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  ASK_AI: <MagicWandIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  INVITE_TEAM_MEMBER: (
    <UserPlusIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
  ADD_DATA_SOURCE: (
    <DatabaseIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
  VIEW_INSIGHTS: (
    <StarryStarryNightIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
  METRICS_DICTIONARY: <BookIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  CREATE_REPORT: (
    <HorizontalBarChartIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
  CREATE_ALERT: <AlertIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  INSIGHT: (
    <StarryStarryNightIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
  LEADERBOARD: <TrophyIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  METRIC: <VerticalBarChartIcon stroke='grey.tertiaryText' h='20px' w='20px' />,
  BREAKDOWN: (
    <HorizontalBarChartIcon stroke='grey.tertiaryText' h='20px' w='20px' />
  ),
};

export function UserMenuListItem({
  item,
  index,
  isSelected,
  onSelect,
  onClose,
}: CustomerItemProps) {
  const onOpen = useOnCustomerUserDrawerOpen({
    department: item.department,
    departmentId: item.departmentId,
    onDataLoaded: noop,
  });

  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.USER_SELECTED}
      icon={iconsByType.USER}
      id={item.id}
      name={item.name}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={() => {
        onOpen();
        onClose();
      }}
    />
  );
}
