import { Getter } from 'jotai';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { FormattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';

export function getTimeRangeFromDatePickerAtom(
  get: Getter,
  datePickerAtom: FormattedDatePickerAtom,
) {
  const {
    end,
    startOfCurrentPeriod: start,
    granularity: atomGranularity,
  } = get(datePickerAtom);

  const granularity =
    atomGranularity === AggregationPeriod.enum.SINCE_THE_BEGINNING_OF_TIME ||
    AggregationPeriod.enum.YEAR
      ? AggregationPeriod.enum.DAY
      : atomGranularity;

  return { start, end, granularity };
}
