import { PropsWithChildren } from 'react';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

import { useLink } from 'utils/navigation';

export type LinkProps = PropsWithChildren<
  NextLinkProps & Omit<ChakraLinkProps, 'as'>
>;

export const Link = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  children,
  ...chakraProps
}: LinkProps) => {
  const { path } = useLink(href);
  return (
    <ChakraLink
      as={NextLink}
      passHref
      href={path}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      _hover={{
        textTransform: 'none',
      }}
      {...chakraProps}
    >
      {children}
    </ChakraLink>
  );
};
