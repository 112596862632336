import { useCallback } from 'react';

import { useAtom } from 'jotai';

import { AiChat } from 'components/AiDrawer/AiChat/AiChat';
import { AiChatArchive } from 'components/AiDrawer/AiChatArchive/AiChatArchive';
import { aiArchiveDrawerAtom } from 'data/ai/atoms';
import {
  useCloseAiDrawer,
  useResetResults,
  useSelectChat,
} from 'data/ai/hooks';
import { Chat } from 'data/ai/models';

export function AiContent() {
  const closeAiDrawer = useCloseAiDrawer();
  const [chatArchiveOpen, setChatArchiveOpen] = useAtom(aiArchiveDrawerAtom);
  const resetResults = useResetResults();
  const selectChat = useSelectChat();

  const openChatArchive = useCallback(() => {
    setChatArchiveOpen(true);
  }, [setChatArchiveOpen]);
  const openNewChat = useCallback(() => {
    resetResults();
    setChatArchiveOpen(false);
  }, [resetResults, setChatArchiveOpen]);

  const onSelectChat = useCallback(
    (chat: Chat) => {
      selectChat(chat);
      setChatArchiveOpen(false);
    },
    [selectChat, setChatArchiveOpen],
  );
  return chatArchiveOpen ? (
    <AiChatArchive
      onClose={closeAiDrawer}
      onNewChat={openNewChat}
      onSelectChat={onSelectChat}
    />
  ) : (
    <AiChat onClose={closeAiDrawer} onChatArchiveOpen={openChatArchive} />
  );
}
