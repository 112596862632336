import { startOfMonth } from 'date-fns';
import { z } from 'zod';

import { CohortTableResponse } from 'data/charts/models/ChartsApiResponse';
import { getCohortBreakdownAtoms } from 'utils/atoms/cohortBreakdownAtoms';

export const FullRetentionCohortRow = z.object({
  cohort: z.coerce.date().transform((date) => startOfMonth(date)),
  initial: z.coerce.number().optional(),
  m1: z.number().optional(),
  m2: z.number().optional(),
  m3: z.number().optional(),
  m4: z.number().optional(),
  m5: z.number().optional(),
  m6: z.number().optional(),
  m7: z.number().optional(),
  m8: z.number().optional(),
});

export type FullRetentionCohortRow = z.infer<typeof FullRetentionCohortRow>;
export type FullBreakdownByNewSignUpsCohorts =
  CohortTableResponse<FullRetentionCohortRow>;

export const breakdownByNewSignUpCohortsAtoms = getCohortBreakdownAtoms(
  FullRetentionCohortRow,
  (date: Date) => ({
    cohort: date,
    initial: undefined,
  }),
  8,
);

export const SignUpCohortRow = z.object({
  cohort: z.coerce.date().transform((date) => startOfMonth(date)),
  initial: z.coerce.number().optional(),
  m1: z.coerce.number().optional(),
  m2: z.coerce.number().optional(),
  m3: z.coerce.number().optional(),
  m4: z.coerce.number().optional(),
});

export const {
  dataAtom: retentionCohortsDataAtom,
  fetchingAtom: retentionCohortsFetchingAtom,
} = getCohortBreakdownAtoms(
  SignUpCohortRow,
  (date: Date) => ({
    cohort: date,
    initial: undefined,
  }),
  6,
);
