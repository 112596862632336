import { createIcon } from '@chakra-ui/react';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M5 12H19M19 12L12 5M19 12L12 19'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  ),
});
