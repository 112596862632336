import { Getter } from 'jotai';

import { createGoal } from 'api/rest/goals/createGoal';
import { fetchGoals } from 'api/rest/goals/fetchGoals';
import { updateGoal } from 'api/rest/goals/updateGoal';
import {
  Goal,
  CreateGoalRequest,
  UpdateGoalRequest,
} from 'data/goals/models/Goal';
import { getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataFetchingAtom,
  getDataUpdatingAtom,
} from 'utils/atoms/dataFetchingAtom';

export const goalsAtom = getDataAtom<{ goals: Goal[] }>({ goals: [] });

export const fetchGoalsAtom = getDataFetchingAtom(goalsAtom, fetchGoals);

export const createGoalAtom = getDataUpdatingAtom<
  {
    goals: Goal[];
  },
  CreateGoalRequest
>(goalsAtom, async (request: CreateGoalRequest, get: Getter) => {
  const goal = await createGoal(request);
  const data = get(goalsAtom);

  return {
    ...data,
    goals: data.goals.concat(goal),
  };
});

export const updateGoalAtom = getDataUpdatingAtom<
  {
    goals: Goal[];
  },
  UpdateGoalRequest
>(goalsAtom, async (request: UpdateGoalRequest, get: Getter) => {
  await updateGoal(request);
  const data = get(goalsAtom);

  return {
    ...data,
    goals: data.goals.map((goal) =>
      goal.id === request.id
        ? {
            ...goal,
            ...request,
          }
        : goal,
    ),
  };
});
