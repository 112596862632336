import { atom, useAtomValue } from 'jotai';
import { ZodType, ZodTypeDef } from 'zod';

import { fetchCustomerCompanyByDepartmentId } from 'api/rest/customer/companies/fetchCustomerCompanyByDepartmentId';
import { removeCustomerTagFromCompany } from 'api/rest/customer/companies/removeCustomerTagFromCompany';
import { updateCustomerCompanySentiment } from 'api/rest/customer/companies/updateCustomerCompanySentiment';
import { updateCustomerCompanyTags } from 'api/rest/customer/companies/updateCustomerCompanyTags';
import { createCustomerCompanyNote } from 'api/rest/customer/companyNotes/createCustomerCompanyNote';
import { deleteCustomerCompanyNote } from 'api/rest/customer/companyNotes/deleteCustomerCompanyNote';
import { fetchCustomerCompanyNotes } from 'api/rest/customer/companyNotes/fetchCustomerCompanyNotes';
import { Department } from 'data/blocks/models/Block';
import {
  BreakdownBySegmentsRow,
  FullBreakdownByUsersRow,
  RevenueBreakdownRow,
  SignalsByEngagementRow,
} from 'data/charts/atoms/product/users/breakdowns';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { CustomerCompany } from 'data/customer/CustomerCompany';
import {
  CompanyData,
  getCustomersChartAtoms,
  RevenueStatsData,
} from 'data/healthReports/atoms/customerChartAtom';
import {
  customerDrawerFormattedDateRangeAtom,
  customerDrawerMonthlyFormattedDateRangeAtom,
} from 'data/healthReports/atoms/customerDrawerFormattedDateRangeAtom';
import {
  CustomerCompaniesAtomsType,
  CustomerCompanyAtomsType,
} from 'data/healthReports/models/company/CustomerCompanyAtomsType';
import { CustomerCompanyAtomType } from 'data/healthReports/models/company/CustomerCompanyAtomType';
import { CustomerCompanyNotesAtomType } from 'data/healthReports/models/company/CustomerCompanyNotesAtomType';
import {
  DepartmentCustomerCompaniesAtomsType,
  DepartmentCustomerCompanyAtomsType,
  DepartmentCustomerCompanyAtomType,
} from 'data/healthReports/models/company/DepartmentCustomerCompanyAtomType';
import {
  QuartileActiveUsersDataBlock,
  QuartileCustomerLifespanDataBlock,
  QuartileProductEngagementDataBlock,
  QuartileRevenueDataBlock,
} from 'data/healthReports/models/company/QuartileDataBlocks';
import { buildClickableCardBreakdownAtom } from 'utils/atoms/buildClickableCardBreakdownAtom';
import { getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataFetchingAtom,
  getFormUpdatingAtom,
} from 'utils/atoms/dataFetchingAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';
import { getRecordChartAtoms } from 'utils/atoms/recordBlockAtoms';
import { getSummaryAtom } from 'utils/atoms/summaryCardAtom';
import { getTimeSeriesAtoms } from 'utils/atoms/timeSeriesAtom';

const getProductFilterConfig = (departmentIds: string[]): Filter[] => {
  return [{ field: 'group_id', operator: 'in', value: departmentIds }];
};

const getFinanceFilterConfig = (departmentIds: string[]): Filter[] => {
  return [{ field: 'customer', operator: 'in', value: departmentIds }];
};

const departmentCustomerCompanyAtoms: DepartmentCustomerCompaniesAtomsType = {};

const customerCompanyAtoms = atom<CustomerCompaniesAtomsType>({});

export const createDepartmentCustomerCompanyAtoms = (
  department: Department,
  departmentId: string,
) => {
  if (departmentCustomerCompanyAtoms[departmentId]) {
    return departmentCustomerCompanyAtoms[
      departmentId
    ] as DepartmentCustomerCompanyAtomsType;
  }

  const dataAtom = getDataAtom<DepartmentCustomerCompanyAtomType>({});

  const fetchingAtom = getDataFetchingAtom(dataAtom, async (_, get, set) => {
    const companyRemote = await fetchCustomerCompanyByDepartmentId({
      department,
      departmentId,
    });

    if (!get(customerCompanyAtoms)[companyRemote.company.id]) {
      const atoms = createCustomerCompanyAtoms(companyRemote.company);

      set(customerCompanyAtoms, (prev) => ({
        ...prev,
        [companyRemote.company.id]: atoms,
      }));
    }

    return companyRemote;
  });

  return (departmentCustomerCompanyAtoms[departmentId] = {
    dataAtom,
    fetchingAtom,
  });
};

export const createCustomerCompanyAtoms = (
  company: CustomerCompany,
): CustomerCompanyAtomsType => {
  const dataAtom = getDataAtom<CustomerCompanyAtomType>({ company }, 'SUCCESS');

  const notesDataAtom = getDataAtom<CustomerCompanyNotesAtomType>({
    companyId: company.id,
    notes: [],
  });

  const fetchingAtom = getDataFetchingAtom(
    dataAtom,
    fetchCustomerCompanyByDepartmentId,
  );

  const sentimentUpdatingAtom = getFormUpdatingAtom(
    updateCustomerCompanySentiment,
    dataAtom,
  );

  const tagsUpdatingAtom = getFormUpdatingAtom(
    updateCustomerCompanyTags,
    dataAtom,
  );

  const tagRemovingAtom = getFormUpdatingAtom(
    removeCustomerTagFromCompany,
    dataAtom,
  );

  const notesFetchingAtom = getDataFetchingAtom(
    notesDataAtom,
    fetchCustomerCompanyNotes,
  );

  const noteCreatingAtom = getFormUpdatingAtom(
    createCustomerCompanyNote,
    dataAtom,
  );

  const noteDeletingAtom = getFormUpdatingAtom(
    deleteCustomerCompanyNote,
    dataAtom,
  );

  const companyDataAtoms = getRecordChartAtoms({
    parser: CompanyData,
    config: { customerCompanyId: company.id },
  });

  const {
    dataAtom: quartileRevenueAtom,
    fetchingAtom: quartileRevenueFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileRevenueDataBlock,
    filters: getFinanceFilterConfig(company.financeIds),
  });

  const {
    dataAtom: quartileActiveUsersAtom,
    fetchingAtom: quartileActiveUsersFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileActiveUsersDataBlock,
    filters: getProductFilterConfig(company.productIds),
  });

  const {
    dataAtom: quartileProductEngagementAtom,
    fetchingAtom: quartileProductEngagementFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileProductEngagementDataBlock,
    filters: getProductFilterConfig(company.productIds),
  });

  const {
    dataAtom: quartileCustomerLifespanAtom,
    fetchingAtom: quartileCustomerLifespanFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileCustomerLifespanDataBlock,
    filters: getProductFilterConfig(company.productIds),
  });

  const {
    dataAtom: userSegmentDataAtom,
    fetchingAtom: userSegmentFetchingAtom,
  } = buildClickableCardBreakdownAtom<
    BreakdownBySegmentsRow,
    BreakdownBySegmentsRow,
    ZodType<BreakdownBySegmentsRow, ZodTypeDef, BreakdownBySegmentsRow>
  >(
    BreakdownBySegmentsRow,
    customerDrawerFormattedDateRangeAtom,
    getProductFilterConfig(company.productIds),
  );

  const {
    dataAtom: userActivityDataAtom,
    fetchingAtom: userActivityFetchingAtom,
    sortingAtom: userActivitySortingAtom,
    paginationAtom: userActivityPaginationAtom,
  } = getFullBreakdownWithPaginationAtom(
    FullBreakdownByUsersRow,
    [{ column: 'activeSessionsL30', direction: 'DESC' }],
    customerDrawerFormattedDateRangeAtom,
    getProductFilterConfig(company.productIds),
  );

  const {
    dataAtom: totalUsersSummaryDataAtom,
    fetchingAtom: totalUsersSummaryFetchingAtom,
  } = getSummaryAtom({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    filters: getProductFilterConfig(company.productIds),
  });

  const {
    dataAtom: lastWeekEngagementSummaryDataAtom,
    fetchingAtom: lastWeekEngagementSummaryFetchingAtom,
  } = getSummaryAtom({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    filters: getProductFilterConfig(company.productIds),
  });

  const revenueChartAtoms = getTimeSeriesAtoms({
    timeRangeAtom: customerDrawerMonthlyFormattedDateRangeAtom,
    initialFilters: getFinanceFilterConfig(company.financeIds),
  });

  const revenueStatsAtoms = getRecordChartAtoms({
    parser: RevenueStatsData,
    config: { filters: getFinanceFilterConfig(company.financeIds) },
  });

  const revenueBreakdownAtoms = buildClickableCardBreakdownAtom<
    RevenueBreakdownRow,
    RevenueBreakdownRow,
    ZodType<RevenueBreakdownRow, ZodTypeDef, RevenueBreakdownRow>
  >(
    RevenueBreakdownRow,
    customerDrawerFormattedDateRangeAtom,
    getFinanceFilterConfig(company.financeIds),
  );

  const activeUsersChartAtoms = getTimeSeriesAtoms({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    initialFilters: getProductFilterConfig(company.productIds),
  });

  const thisWeekActiveUsersSummaryAtoms = getSummaryAtom({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    filters: getProductFilterConfig(company.productIds),
  });

  const thisMonthActiveUsersSummaryAtoms = getSummaryAtom({
    timeRangeAtom: customerDrawerMonthlyFormattedDateRangeAtom,
    filters: getProductFilterConfig(company.productIds),
  });

  const signalsByEngagementAtoms = buildClickableCardBreakdownAtom<
    SignalsByEngagementRow,
    SignalsByEngagementRow,
    ZodType<SignalsByEngagementRow, ZodTypeDef, SignalsByEngagementRow>
  >(
    SignalsByEngagementRow,
    customerDrawerFormattedDateRangeAtom,
    getProductFilterConfig(company.productIds),
  );

  return {
    dataAtom,
    notesDataAtom,
    fetchingAtom,
    sentimentUpdatingAtom,
    tagsUpdatingAtom,
    tagRemovingAtom,
    notesFetchingAtom,
    noteCreatingAtom,
    noteDeletingAtom,

    companyDataAtoms,

    quartileRevenueAtom,
    quartileRevenueFetchingAtom,
    quartileActiveUsersAtom,
    quartileActiveUsersFetchingAtom,
    quartileProductEngagementAtom,
    quartileProductEngagementFetchingAtom,
    quartileCustomerLifespanAtom,
    quartileCustomerLifespanFetchingAtom,

    userSegmentDataAtom,
    userSegmentFetchingAtom,

    userActivityDataAtom,
    userActivityFetchingAtom,
    userActivitySortingAtom,
    userActivityPaginationAtom,

    totalUsersSummaryDataAtom,
    totalUsersSummaryFetchingAtom,

    revenueChartAtoms,
    revenueStatsAtoms,

    revenueBreakdownAtoms,

    activeUsersChartAtoms,
    thisWeekActiveUsersSummaryAtoms,
    thisMonthActiveUsersSummaryAtoms,

    signalsByEngagementAtoms,

    lastWeekEngagementSummaryDataAtom,
    lastWeekEngagementSummaryFetchingAtom,
  };
};

export const useCustomerCompanyAtoms = (companyId: string) => {
  const atoms = useAtomValue(customerCompanyAtoms)[companyId];

  if (!atoms) {
    throw new Error('Customer company atoms not found');
  }

  return atoms;
};
