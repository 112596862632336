import { createIcon } from '@chakra-ui/react';

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M4 8H12M2 4H14M6 12H10'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
