import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useStyledToast } from 'components/Toast';
import { Text, UserPlusIcon } from 'components/uikit';
import { APP_URL } from 'data/constants';

export function useCopyInviteLink() {
  const toast = useStyledToast();
  const { t } = useTranslation('sidebar');

  return useCallback(async () => {
    await navigator.clipboard.writeText(APP_URL || '');
    toast({
      id: 'invite-toast',
      title: t('toast_invite_copied_title'),
      description: (
        <Text
          fontSize='sm'
          color='grey.tertiaryText'
          lineHeight='short'
          mt='2px'
        >
          {t('toast_invite_copied_description')}
        </Text>
      ),
      variant: 'SUCCESS',
      Icon: UserPlusIcon,
      duration: 3000,
      position: 'bottom-right',
    });
  }, [t, toast]);
}
