import { Profile, User } from 'api/graphql/backendAPI/generatedTypes';
import {
  AnalyticsEventType,
  trackEvent,
  unidentifiedEvents,
} from 'utils/analytics';

export async function trackUserEvent(
  user: User | null,
  profile: Profile | null,
  event: AnalyticsEventType,
  payload: Record<string, unknown> = {},
) {
  if (!user) {
    unidentifiedEvents.push({ type: event, payload });
  } else {
    await trackEvent(event, payload, user, profile);
  }
}
