import React, { useCallback, useEffect, useState } from 'react';

import { Divider, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  ArrowRightIcon,
  Button,
  Card,
  Form,
  Skeleton,
  Text,
  Textarea,
  VStack,
  LightningIcon,
  HStack,
} from 'components/uikit';
import { aiFollowUpQuestionsAtom } from 'data/ai/atoms';
import { useDefaultFollowUpQuestions } from 'data/ai/hooks';
import { isEmpty } from 'utils/helpers';
import { resolveState } from 'utils/types';
import { useIsEnterprise } from 'utils/useIsEnterprise';

interface AiInputCardProps {
  onAsk: (prompt: string, actionsRequested?: boolean) => void;
  errorMessage?: string;
  actionsAvailable?: boolean;
}

export function AiInputCard({
  onAsk,
  actionsAvailable = false,
}: AiInputCardProps) {
  const { t } = useTranslation('ai');
  const [aiInput, setAiInput] = useState('');
  const isEnterprise = useIsEnterprise();
  const followUpResultRemote = useAtomValue(aiFollowUpQuestionsAtom);
  const defaultFollowUpQuestionsRemote = useDefaultFollowUpQuestions();

  const ref = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      ref.current?.focus();
    });
  }, []);

  const onSubmit = useCallback(() => {
    onAsk(aiInput);
  }, [onAsk, aiInput]);

  const followUp = useCallback(
    (followUp: string) => {
      onAsk(followUp);
    },
    [onAsk],
  );

  const requestActions = useCallback(() => {
    onAsk('Suggested Actions', true);
  }, [onAsk]);

  const onRequestActions = actionsAvailable ? requestActions : undefined;

  return (
    <Form onSubmit={onSubmit}>
      <Card boxShadow='none' p='16px' borderRadius='4px'>
        <VStack align='start' spacing='16px'>
          <InputGroup>
            <Textarea
              ref={ref}
              placeholder={t('search_placeholder')}
              _placeholder={{
                fontStyle: 'italic',
                fontWeight: 'normal',
              }}
              _focus={{
                borderColor: isEnterprise
                  ? 'primary.button'
                  : 'tertiary.button',
                boxShadow: isEnterprise ? 'enterprise' : 'tertiary',
              }}
              rows={1}
              resize='vertical'
              autoFocus
              autoSize
              fontWeight='medium'
              rowGap='0'
              onChange={(e) => setAiInput(e.target.value)}
              submitOnEnter
            />
            <InputRightElement bottom='8px' top='none' h='32px'>
              <Button
                size='sm'
                variant={isEnterprise ? 'primary' : 'tertiary'}
                onClick={onSubmit}
                w='32px'
              >
                <ArrowRightIcon
                  stroke={
                    isEnterprise ? 'grey.primaryButtonText' : 'grey.white'
                  }
                  boxSize='16px'
                />
              </Button>
            </InputRightElement>
          </InputGroup>

          {resolveState(followUpResultRemote, {
            INITIAL: () =>
              resolveState(defaultFollowUpQuestionsRemote, {
                INITIAL: () => (
                  <FollowUpQuestions
                    questions={[]}
                    onFollowUp={followUp}
                    onRequestActions={onRequestActions}
                    isLoading
                  />
                ),
                LOADING: () => (
                  <FollowUpQuestions
                    questions={[]}
                    onFollowUp={followUp}
                    onRequestActions={onRequestActions}
                    isLoading
                  />
                ),
                ERROR: () => (
                  <FollowUpQuestions
                    questions={[]}
                    onFollowUp={followUp}
                    onRequestActions={onRequestActions}
                  />
                ),
                SUCCESS: ({ questions }) => (
                  <FollowUpQuestions
                    questions={questions}
                    onFollowUp={followUp}
                    onRequestActions={onRequestActions}
                  />
                ),
              }),
            LOADING: () => (
              <FollowUpQuestions
                questions={[]}
                onFollowUp={followUp}
                onRequestActions={onRequestActions}
                isLoading
              />
            ),
            ERROR: () => (
              <FollowUpQuestions
                questions={[]}
                onFollowUp={followUp}
                onRequestActions={onRequestActions}
              />
            ),
            SUCCESS: ({ questions }) => (
              <FollowUpQuestions
                questions={questions}
                onFollowUp={followUp}
                onRequestActions={onRequestActions}
              />
            ),
          })}
        </VStack>
      </Card>
    </Form>
  );
}

interface FollowUpQuestionsProps {
  questions: string[];
  onFollowUp: (question: string) => void;
  onRequestActions?: () => void;
  isLoading?: boolean;
}

function FollowUpQuestions({
  questions,
  onFollowUp,
  onRequestActions,
  isLoading = false,
}: FollowUpQuestionsProps) {
  if (isEmpty(questions) && !onRequestActions && !isLoading) return null;

  return (
    <>
      <Divider border='0 solid' borderColor='grey.border' opacity={1} />
      <VStack align='start' spacing='8px' w='100%'>
        {isLoading ? (
          <>
            <Skeleton h='42px' w='100%' borderRadius='4px' />
            <Skeleton h='42px' w='100%' borderRadius='4px' />
            <Skeleton h='42px' w='100%' borderRadius='4px' />
          </>
        ) : (
          questions.map((question) => (
            <Card
              background='grey.offWhite'
              boxShadow='none'
              cursor='pointer'
              borderRadius='4px'
              px='12px'
              py='8px'
              onClick={() => onFollowUp(question)}
              key={question}
            >
              <Text fontWeight='medium'>{question}</Text>
            </Card>
          ))
        )}
        {onRequestActions ? (
          <Card
            background='grey.offWhite'
            boxShadow='none'
            cursor='pointer'
            borderRadius='4px'
            px='12px'
            py='8px'
            onClick={onRequestActions}
            key='action'
          >
            <HStack>
              <LightningIcon boxSize='20px' />
              <Text fontWeight='medium'>Suggested Actions</Text>
            </HStack>
          </Card>
        ) : null}
      </VStack>
    </>
  );
}
