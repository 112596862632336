import { useAtomValue } from 'jotai';

import { DataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

type DataPropType<T> = T extends { data: infer R } ? R : never;

export function useFetchedData<T extends { data: DataPropType<T> }>(
  atom: DataFetchingAtom<T>,
) {
  const { fetchStatus, errorMessage, data, ...extra } = useAtomValue(atom);

  return {
    fetchStatus,
    error: errorMessage,
    data,
    ...extra,
  };
}
