import { EMPTY_FILTER } from 'data/constants/filters';
import { State } from 'utils/atoms/dataAtom';
import { FetchStatus } from 'utils/requests/types';

export type ChartSplitOption = {
  id: string;
  nameKey: string;
};

type UnionToIntersection<U> = (
  U extends unknown ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

type UnionToOvlds<U> = UnionToIntersection<
  U extends unknown ? (f: U) => void : never
>;

type PopUnion<U> = UnionToOvlds<U> extends (a: infer A) => void ? A : never;

type IsUnion<T> = [T] extends [UnionToIntersection<T>] ? false : true;

export type UnionToArray<T, A extends unknown[] = []> = IsUnion<T> extends true
  ? UnionToArray<Exclude<T, PopUnion<T>>, [PopUnion<T>, ...A]>
  : [T, ...A];

export function combineStatuses(...statuses: FetchStatus[]): FetchStatus {
  if (statuses.includes('ERROR')) {
    return 'ERROR';
  }
  if (statuses.includes('LOADING')) {
    return 'LOADING';
  }
  if (statuses.includes('INITIAL')) {
    return 'INITIAL';
  }
  if (statuses.includes('REFRESHING')) {
    return 'REFRESHING';
  }
  return 'SUCCESS';
}

export function resolveState<T, R>(
  remote: State<T>,
  resolvers: {
    ERROR: (errorMessage: string) => R;
    LOADING: (data: T) => R;
    SUCCESS: (data: T) => R;
    INITIAL?: () => R;
    // REFRESHING?: (data: T) => R;
  },
): R {
  if (remote.fetchStatus === 'ERROR') {
    return resolvers.ERROR(remote.errorMessage);
  }
  if (remote.fetchStatus === 'LOADING') {
    return resolvers.LOADING(remote);
  }
  if (remote.fetchStatus === 'INITIAL') {
    return resolvers.INITIAL ? resolvers.INITIAL() : resolvers.LOADING(remote);
  }
  // if (remote.fetchStatus === 'REFRESHING') {
  //   return resolvers.REFRESHING
  //     ? resolvers.REFRESHING(remote)
  //     : resolvers.SUCCESS(remote);
  // }
  return resolvers.SUCCESS(remote);
}

export function isInitial(status: FetchStatus) {
  return status === 'INITIAL';
}

export function isLoading(status: FetchStatus) {
  return status === 'INITIAL' || status === 'LOADING';
}

export function isLoaded(status: FetchStatus) {
  return !isLoading(status);
}

export function isSuccess(status: FetchStatus) {
  return status === 'SUCCESS';
}

export function isFetchError(status: FetchStatus): status is 'ERROR' {
  return status === 'ERROR';
}

export interface Segment {
  id: string | null;
  value: string;
}

export interface NonEmptyFilter {
  category: string;
  segments: Segment[];
  lockCategory: boolean;
}

export type FilterConfig = NonEmptyFilter | typeof EMPTY_FILTER;

export interface EnableableOption {
  enabled: boolean;
}

export interface ViewportSize {
  width: number;
  height: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type LooseAutocomplete<T> = T | (string & {});

export type NonEmptyList<T> = [T, ...T[]];

export function isNonEmptyList<T>(list: T[]): list is NonEmptyList<T> {
  return list.length > 0;
}
export function isSingleItem<T>(list: T[]): list is [T] {
  return list.length === 1;
}

export function hasId<T extends { id: string | null }>(
  item: T,
): item is T & { id: string } {
  return item.id !== null;
}

export type SectionNavigationConfig = {
  label: string;
  sectionId: string;
  onNavigation?: () => void;
  onVisible?: () => void;
  topOffsetPx?: number;
};

export type TabNavigationConfig = {
  tabId: string;
  label: string;
  url: string;
  isActive: boolean;
  showAsDemo: boolean;
};
