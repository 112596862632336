import { createIcon } from '@chakra-ui/react';

export const LinkedinIcon = createIcon({
  displayName: 'LinkedinIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M8 0C3.58167 0 0 3.58167 0 8C0 12.4183 3.58167 16 8 16C12.4183 16 16
         12.4183 16 8C16 3.58167 12.4183 0 8 0ZM6.04167
         11.3158H4.42167V6.1025H6.04167V11.3158ZM5.22167 5.4625C4.71 5.4625
         4.37917 5.1 4.37917 4.65167C4.37917 4.19417 4.72 3.8425 5.2425
         3.8425C5.765 3.8425 6.085 4.19417 6.095 4.65167C6.095 5.1 5.765 5.4625
         5.22167 5.4625ZM11.9583 11.3158H10.3383V8.42667C10.3383 7.75417 10.1033
         7.2975 9.5175 7.2975C9.07 7.2975 8.80417 7.60667 8.68667
         7.90417C8.64333 8.01 8.6325 8.16 8.6325
         8.30917V11.315H7.01167V7.765C7.01167 7.11417 6.99083 6.57 6.96917
         6.10167H8.37667L8.45083 6.82583H8.48333C8.69667 6.48583 9.21917
         5.98417 10.0933 5.98417C11.1592 5.98417 11.9583 6.69833 11.9583
         8.23333V11.3158Z'
      fill='currentColor'
      stroke='none'
    />
  ),
});
