import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { z } from 'zod';

import {
  Department,
  FinanceDictionarySwitchNames,
  GrowthDictionarySwitchNames,
  MetricDictionaryContentName,
  MetricDictionarySwitchName,
  ProductDictionarySwitchNames,
  SalesDictionarySwitchNames,
  SupportDictionarySwitchNames,
} from 'data/blocks/models/Block';
import {
  FinanceDictionarySummaryUniqueBlockName,
  GrowthDictionarySummaryUniqueBlockName,
  MetricDictionarySummaryName,
  ProductDictionarySummaryUniqueBlockName,
  SalesDictionarySummaryUniqueBlockName,
  SupportDictionarySummaryUniqueBlockName,
} from 'data/blocks/models/MetricDictionarySummaryName';
import { DEPARTMENTS } from 'data/constants/departments';
import {
  parseEnumOptional,
  parseStringOptional,
} from 'utils/parser/parseResponse';

function getEffectiveDictionaryMenuItem(
  menuItem: unknown,
  defaultValue: MetricDictionaryContentName,
  prefix: string,
  formatter: typeof MetricDictionaryContentName,
): MetricDictionaryContentName {
  if (typeof menuItem !== 'string') return defaultValue;

  const formatted = `${prefix + menuItem.toUpperCase()}_CONTENT`;
  return parseStringOptional(formatter, formatted) ?? defaultValue;
}

export function getEffectiveDictionarySwitch<D extends Department>(
  dictionarySwitch: unknown,
  defaultValue: DepartmentDictionarySwitch<D>,
  prefix: string,
  formatter: typeof DICTIONARY_SWITCH_PARSER[D],
): DepartmentDictionarySwitch<D> {
  if (typeof dictionarySwitch !== 'string') return defaultValue;

  const formatted = prefix + dictionarySwitch.toUpperCase();
  return parseStringOptional(formatter, formatted) ?? defaultValue;
}

export const DICTIONARY_SWITCH_PARSER = {
  GROWTH: GrowthDictionarySwitchNames,
  PRODUCT: ProductDictionarySwitchNames,
  SALES: SalesDictionarySwitchNames,
  FINANCE: FinanceDictionarySwitchNames,
  SUPPORT: SupportDictionarySwitchNames,
} as const;

export type DepartmentDictionarySwitch<D extends Department> = z.infer<
  typeof DICTIONARY_SWITCH_PARSER[D]
>;

export const DEPARTMENT_PREFIXES: {
  [D in Department]: string;
} = {
  GROWTH: 'METRIC_DICTIONARY_GROWTH_',
  PRODUCT: 'METRIC_DICTIONARY_PRODUCT_',
  SALES: 'METRIC_DICTIONARY_SALES_',
  FINANCE: 'METRIC_DICTIONARY_FINANCE_',
  SUPPORT: 'METRIC_DICTIONARY_SUPPORT_',
};

const DEFAULT_SUBPANELS: {
  [key in MetricDictionaryContentName]: MetricDictionarySwitchName;
} = {
  METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CONTENT:
    'METRIC_DICTIONARY_GROWTH_NEW_VISITORS',
  METRIC_DICTIONARY_GROWTH_SESSIONS_CONTENT:
    'METRIC_DICTIONARY_GROWTH_SESSIONS',
  METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_CONTENT:
    'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS',
  METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_CONTENT:
    'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE',
  METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_CONTENT:
    'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION',
  METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_CONTENT:
    'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME',
  METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_CONTENT:
    'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS',
  METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_CONTENT:
    'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION',
  METRIC_DICTIONARY_GROWTH_LEADS_CONTENT: 'METRIC_DICTIONARY_GROWTH_LEADS',
  METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_CONTENT:
    'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE',

  METRIC_DICTIONARY_FINANCE_MRR_CONTENT: 'METRIC_DICTIONARY_FINANCE_MRR',
  METRIC_DICTIONARY_FINANCE_ARR_CONTENT: 'METRIC_DICTIONARY_FINANCE_ARR',
  METRIC_DICTIONARY_FINANCE_CUSTOMERS_CONTENT:
    'METRIC_DICTIONARY_FINANCE_CUSTOMERS',
  METRIC_DICTIONARY_FINANCE_ARPC_CONTENT: 'METRIC_DICTIONARY_FINANCE_ARPC',
  METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_CONTENT:
    'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE',
  METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CONTENT:
    'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR',
  METRIC_DICTIONARY_FINANCE_CHURN_CONTENT: 'METRIC_DICTIONARY_FINANCE_CHURN',
  METRIC_DICTIONARY_FINANCE_EXPANSION_CONTENT:
    'METRIC_DICTIONARY_FINANCE_EXPANSION',
  METRIC_DICTIONARY_FINANCE_CONTRACTION_CONTENT:
    'METRIC_DICTIONARY_FINANCE_CONTRACTION',
  METRIC_DICTIONARY_FINANCE_REACTIVATION_CONTENT:
    'METRIC_DICTIONARY_FINANCE_REACTIVATION',
  METRIC_DICTIONARY_FINANCE_MRR_WON_CONTENT:
    'METRIC_DICTIONARY_FINANCE_MRR_WON',
  METRIC_DICTIONARY_FINANCE_MRR_LOST_CONTENT:
    'METRIC_DICTIONARY_FINANCE_MRR_LOST',
  METRIC_DICTIONARY_FINANCE_QUICK_RATIO_CONTENT:
    'METRIC_DICTIONARY_FINANCE_QUICK_RATIO',
  METRIC_DICTIONARY_FINANCE_GRR_CONTENT: 'METRIC_DICTIONARY_FINANCE_GRR',
  METRIC_DICTIONARY_FINANCE_NRR_CONTENT: 'METRIC_DICTIONARY_FINANCE_NRR',
  METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_CONTENT:
    'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV',
  METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_CONTENT:
    'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS',
  METRIC_DICTIONARY_FINANCE_NEW_TRIALS_CONTENT:
    'METRIC_DICTIONARY_FINANCE_NEW_TRIALS',
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_CONTENT:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE',

  METRIC_DICTIONARY_SALES_TOTAL_SALES_CONTENT:
    'METRIC_DICTIONARY_SALES_TOTAL_SALES',
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_CONTENT:
    'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT',
  METRIC_DICTIONARY_SALES_LOST_DEALS_CONTENT:
    'METRIC_DICTIONARY_SALES_LOST_DEALS',
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_CONTENT:
    'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE',
  METRIC_DICTIONARY_SALES_VELOCITY_CONTENT: 'METRIC_DICTIONARY_SALES_VELOCITY',
  METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CONTENT:
    'METRIC_DICTIONARY_SALES_PIPELINE_VALUE',
  METRIC_DICTIONARY_SALES_WIN_RATE_CONTENT: 'METRIC_DICTIONARY_SALES_WIN_RATE',
  METRIC_DICTIONARY_SALES_STALLED_DEALS_CONTENT:
    'METRIC_DICTIONARY_SALES_STALLED_DEALS',
  METRIC_DICTIONARY_SALES_NEW_DEALS_CONTENT:
    'METRIC_DICTIONARY_SALES_NEW_DEALS',
  METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS_CONTENT:
    'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS',

  METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CONTENT:
    'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS',
  METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_CONTENT:
    'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS',
  METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_CONTENT:
    'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS',
  METRIC_DICTIONARY_PRODUCT_POWER_USERS_CONTENT:
    'METRIC_DICTIONARY_PRODUCT_POWER_USERS',
  METRIC_DICTIONARY_PRODUCT_WAU_MAU_CONTENT:
    'METRIC_DICTIONARY_PRODUCT_WAU_MAU',
  METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_CONTENT:
    'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS',
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_CONTENT:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED',
  METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_CONTENT:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED',

  METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_CONTENT:
    'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS',
  METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_CONTENT:
    'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME',
  METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_CONTENT:
    'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS',
};

export const DEFAULT_PANELS: {
  [key in Department]: MetricDictionaryContentName;
} = {
  GROWTH: 'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CONTENT',
  PRODUCT: 'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CONTENT',
  SALES: 'METRIC_DICTIONARY_SALES_TOTAL_SALES_CONTENT',
  FINANCE: 'METRIC_DICTIONARY_FINANCE_ARR_CONTENT',
  SUPPORT: 'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_CONTENT',
};

export function useReadDictionaryMenu<D extends Department>(
  department: D,
  defaultMenuItem?: string | null,
) {
  const { query } = useRouter();
  const defaultItem = parseEnumOptional(
    MetricDictionaryContentName,
    defaultMenuItem,
  );

  const metric = query.menu;
  const metricSwitchName = query.switch;
  const menuItem = getEffectiveDictionaryMenuItem(
    metric,
    DEFAULT_PANELS[department],
    DEPARTMENT_PREFIXES[department],
    MetricDictionaryContentName,
  );

  const dictionarySwitch = getEffectiveDictionarySwitch(
    metricSwitchName,
    DEFAULT_SUBPANELS[defaultItem ?? menuItem],
    DEPARTMENT_PREFIXES[department],
    DICTIONARY_SWITCH_PARSER[department],
  );

  return {
    menuItem,
    dictionarySwitch,
  };
}

export function useGetTransaltionMetricName<D extends Department>(
  department: D,
) {
  const { menuItem, dictionarySwitch } = useReadDictionaryMenu(department);
  const metricName = getMenuItemQueryValue(department, menuItem);
  const switchName = getSwitchQueryValue(department, dictionarySwitch);
  return {
    metricName,
    switchName,
  };
}

export function getMenuItemQueryValue<D extends Department>(
  department: D,
  menuItem: MetricDictionaryContentName,
) {
  const prefix = DEPARTMENT_PREFIXES[department];
  const postfix = '_CONTENT';

  return menuItem.replace(prefix, '').replace(postfix, '').toLowerCase();
}

export function formatSummaryNameKey(
  name?: MetricDictionarySummaryName | null,
) {
  if (!name) return null;

  const department = getDepartmentBySummaryName(name) ?? null;
  return getSummaryMetricName(department, name);
}

export function getDepartmentBySummaryName(
  name: MetricDictionarySummaryName | null,
): Department | undefined {
  if (
    parseEnumOptional(FinanceDictionarySummaryUniqueBlockName, name) != null
  ) {
    return 'FINANCE';
  }
  if (parseEnumOptional(GrowthDictionarySummaryUniqueBlockName, name) != null) {
    return 'GROWTH';
  }
  if (
    parseEnumOptional(ProductDictionarySummaryUniqueBlockName, name) != null
  ) {
    return 'PRODUCT';
  }
  if (parseEnumOptional(SalesDictionarySummaryUniqueBlockName, name) != null) {
    return 'SALES';
  }
  if (
    parseEnumOptional(SupportDictionarySummaryUniqueBlockName, name) != null
  ) {
    return 'SUPPORT';
  }
  return undefined;
}

export function getSummaryMetricName<D extends Department>(
  department: D | null,
  menuItem: MetricDictionarySummaryName,
) {
  const prefix = department ? DEPARTMENT_PREFIXES[department] : '';
  const postfix = '_SUMMARY';

  return menuItem.replace(prefix, '').replace(postfix, '').toLowerCase();
}

export function getSwitchQueryValue<D extends Department>(
  department: D,
  blockName: DepartmentDictionarySwitch<D>,
) {
  const prefix = DEPARTMENT_PREFIXES[department];

  return blockName.replace(prefix, '').toLowerCase();
}

export function getDepartmentFromBlockName(blockName: string) {
  return DEPARTMENTS.find((department) =>
    blockName.startsWith(DEPARTMENT_PREFIXES[department]),
  );
}

export const menuItemBySwitch: {
  [key in MetricDictionarySwitchName]: MetricDictionaryContentName;
} = {
  METRIC_DICTIONARY_GROWTH_NEW_VISITORS:
    'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CONTENT',
  METRIC_DICTIONARY_GROWTH_SESSIONS:
    'METRIC_DICTIONARY_GROWTH_SESSIONS_CONTENT',
  METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS:
    'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_CONTENT',
  METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE:
    'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_CONTENT',
  METRIC_DICTIONARY_GROWTH_PAGE_VIEWS:
    'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_CONTENT',
  METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION:
    'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_CONTENT',
  METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION:
    'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_CONTENT',
  METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME:
    'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_CONTENT',
  METRIC_DICTIONARY_GROWTH_LEADS: 'METRIC_DICTIONARY_GROWTH_LEADS_CONTENT',
  METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE:
    'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_CONTENT',

  METRIC_DICTIONARY_FINANCE_ARR: 'METRIC_DICTIONARY_FINANCE_ARR_CONTENT',
  METRIC_DICTIONARY_FINANCE_MRR: 'METRIC_DICTIONARY_FINANCE_MRR_CONTENT',
  METRIC_DICTIONARY_FINANCE_CUSTOMERS:
    'METRIC_DICTIONARY_FINANCE_CUSTOMERS_CONTENT',
  METRIC_DICTIONARY_FINANCE_ARPC: 'METRIC_DICTIONARY_FINANCE_ARPC_CONTENT',
  METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE:
    'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_CONTENT',
  METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR:
    'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CONTENT',
  METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS:
    'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CONTENT',
  METRIC_DICTIONARY_FINANCE_CHURN: 'METRIC_DICTIONARY_FINANCE_CHURN_CONTENT',
  METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT:
    'METRIC_DICTIONARY_FINANCE_CHURN_CONTENT',
  METRIC_DICTIONARY_FINANCE_EXPANSION:
    'METRIC_DICTIONARY_FINANCE_EXPANSION_CONTENT',
  METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT:
    'METRIC_DICTIONARY_FINANCE_EXPANSION_CONTENT',
  METRIC_DICTIONARY_FINANCE_CONTRACTION:
    'METRIC_DICTIONARY_FINANCE_CONTRACTION_CONTENT',
  METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT:
    'METRIC_DICTIONARY_FINANCE_CONTRACTION_CONTENT',
  METRIC_DICTIONARY_FINANCE_REACTIVATION:
    'METRIC_DICTIONARY_FINANCE_REACTIVATION_CONTENT',
  METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT:
    'METRIC_DICTIONARY_FINANCE_REACTIVATION_CONTENT',
  METRIC_DICTIONARY_FINANCE_MRR_WON:
    'METRIC_DICTIONARY_FINANCE_MRR_WON_CONTENT',
  METRIC_DICTIONARY_FINANCE_MRR_LOST:
    'METRIC_DICTIONARY_FINANCE_MRR_LOST_CONTENT',
  METRIC_DICTIONARY_FINANCE_QUICK_RATIO:
    'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_CONTENT',
  METRIC_DICTIONARY_FINANCE_GRR: 'METRIC_DICTIONARY_FINANCE_GRR_CONTENT',
  METRIC_DICTIONARY_FINANCE_NRR: 'METRIC_DICTIONARY_FINANCE_NRR_CONTENT',
  METRIC_DICTIONARY_FINANCE_AVERAGE_LTV:
    'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_CONTENT',
  METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS:
    'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_CONTENT',
  METRIC_DICTIONARY_FINANCE_NEW_TRIALS:
    'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_CONTENT',
  METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS:
    'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_CONTENT',
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_CONTENT',
  METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_CONTENT',
  METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE:
    'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_CONTENT',

  METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS:
    'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CONTENT',
  METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS:
    'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_CONTENT',
  METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS:
    'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_CONTENT',
  METRIC_DICTIONARY_PRODUCT_POWER_USERS:
    'METRIC_DICTIONARY_PRODUCT_POWER_USERS_CONTENT',
  METRIC_DICTIONARY_PRODUCT_WAU_MAU:
    'METRIC_DICTIONARY_PRODUCT_WAU_MAU_CONTENT',

  METRIC_DICTIONARY_SALES_TOTAL_SALES:
    'METRIC_DICTIONARY_SALES_TOTAL_SALES_CONTENT',
  METRIC_DICTIONARY_SALES_TOTAL_DEALS:
    'METRIC_DICTIONARY_SALES_TOTAL_SALES_CONTENT',
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT:
    'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_CONTENT',
  METRIC_DICTIONARY_SALES_LOST_DEALS:
    'METRIC_DICTIONARY_SALES_LOST_DEALS_CONTENT',
  METRIC_DICTIONARY_SALES_WIN_RATE: 'METRIC_DICTIONARY_SALES_WIN_RATE_CONTENT',
  METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE:
    'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_CONTENT',
  METRIC_DICTIONARY_SALES_VELOCITY: 'METRIC_DICTIONARY_SALES_VELOCITY_CONTENT',
  METRIC_DICTIONARY_SALES_PIPELINE_VALUE:
    'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CONTENT',
  METRIC_DICTIONARY_SALES_PIPELINE_DEALS:
    'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CONTENT',
  METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS:
    'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS_CONTENT',
  METRIC_DICTIONARY_SALES_NEW_DEALS:
    'METRIC_DICTIONARY_SALES_NEW_DEALS_CONTENT',
  METRIC_DICTIONARY_SALES_STALLED_DEALS:
    'METRIC_DICTIONARY_SALES_STALLED_DEALS_CONTENT',

  METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME:
    'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_CONTENT',
  METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS:
    'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_CONTENT',
  METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS:
    'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_CONTENT',
};

export function useSetDictionarySwitch<D extends Department>(department: D) {
  const { push, query } = useRouter();

  return useCallback(
    async (dictionarySwitch: DepartmentDictionarySwitch<D>) =>
      await push(
        {
          query: {
            ...query,
            menu: getMenuItemQueryValue(
              department,
              menuItemBySwitch[dictionarySwitch],
            ),
            switch: getSwitchQueryValue(department, dictionarySwitch),
          },
        },
        undefined,
        { shallow: true },
      ),
    [department, query, push],
  );
}

export function useSetDictionaryMenuItem<D extends Department>(department: D) {
  const { push, query } = useRouter();

  return useCallback(
    async (menuItem: MetricDictionaryContentName) =>
      await push(
        {
          query: {
            ...query,
            menu: getMenuItemQueryValue(department, menuItem),
            switch: getSwitchQueryValue(
              department,
              DEFAULT_SUBPANELS[menuItem],
            ),
          },
        },
        undefined,
        { shallow: true },
      ),
    [department, query, push],
  );
}
