import { ReactNode } from 'react';

import NextLink from 'next/link';

import { Box, Text } from 'components/uikit';

type Props = {
  href: string;
  selected: boolean;
  icon: ReactNode;
  title: string;
};

export function DesktopSidebarNavItem({ icon, selected, href, title }: Props) {
  return (
    <NextLink href={href}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        w='152px'
        h='40px'
        p='6px 8px'
        gap='8px'
        borderRadius='4px'
        background={selected ? 'secondary.background.dark' : 'transparent'}
        color={selected ? 'secondary.text.dark' : 'grey.secondaryText.dark'}
        _hover={{
          background: selected
            ? 'secondary.button.light'
            : 'grey.offWhite.dark',
          color: 'secondary.text.dark',
        }}
      >
        {icon}
        <Text
          color={selected ? 'secondary.text.dark' : 'grey.secondaryText.dark'}
          fontSize='16px'
          fontWeight='500'
          lineHeight='150%'
        >
          {title}
        </Text>
      </Box>
    </NextLink>
  );
}
