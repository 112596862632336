import { z } from 'zod';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';

export const MissionControlChartConfig = z.object({
  granularity: z.enum(['WEEK', 'MONTH']),
});
export type MissionControlChartConfig = z.infer<
  typeof MissionControlChartConfig
>;

export const PaginationPageSize = z.union([
  z.literal(10),
  z.literal(20),
  z.literal(50),
]);
export type PaginationPageSize = z.infer<typeof PaginationPageSize>;

export const PageRequest = z.object({
  page: z.number().int().nonnegative(),
  size: PaginationPageSize,
});

export type PageRequest = z.infer<typeof PageRequest>;

export const TimeRangeParser = z.object({
  start: z.string(),
  end: z.string(),
  granularity: AggregationPeriod,
});

export type TimeRangeParser = z.infer<typeof TimeRangeParser>;

export const Direction = z.enum(['ASC', 'DESC']);
export type Direction = z.infer<typeof Direction>;

export const getSortParser = <T extends [string, ...string[]]>(
  columns: z.ZodEnum<T>,
) =>
  z.object({
    column: columns,
    direction: Direction,
  });

export type Sort = z.infer<ReturnType<typeof getSortParser>>;

export const getSortArrayParser = <T extends [string, ...string[]]>(
  columns: z.ZodEnum<T>,
) => z.array(getSortParser<T>(columns));

export type SortArray = z.infer<ReturnType<typeof getSortArrayParser>>;

export type BreakdownsSortArray<T extends string> = {
  column: T;
  direction: Direction;
}[];

export const getBreakdownConfigParser = <
  F extends z.ZodType<Filter>,
  R extends z.ZodType<Sort>,
>(
  filters: z.ZodArray<F>,
  sorts: z.ZodArray<R>,
) => {
  return z.object({
    filters: filters.optional().default([]),
    sorts: sorts.optional().default([]),
    timeRange: TimeRangeParser,
  });
};

export type BreakdownConfig<
  F extends z.ZodType<Filter> = z.ZodType<Filter>,
  R extends z.ZodType<Sort> = z.ZodType<Sort>,
> = z.infer<ReturnType<typeof getBreakdownConfigParser<F, R>>>;

export const getPaginatedBreakdownConfigParser = <
  F extends z.ZodType<Filter>,
  R extends z.ZodType<Sort>,
>(
  filters: z.ZodArray<F>,
  sorts: z.ZodArray<R>,
) => {
  return z.object({
    filters: filters.optional().default([]),
    sorts: sorts.optional().default([]),
    timeRange: TimeRangeParser,
    pagination: PageRequest,
  });
};

export type PaginatedBreakdownConfig<
  F extends z.ZodType<Filter> = z.ZodType<Filter>,
  R extends z.ZodType<Sort> = z.ZodType<Sort>,
> = z.infer<ReturnType<typeof getPaginatedBreakdownConfigParser<F, R>>>;

export const CohortBreakdownConfigParser = z.object({
  numCohorts: z.number().int().positive(),
  startPeriod: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  granularity: z.enum(['DAY', 'WEEK', 'MONTH']),
});

export type CohortBreakdownConfig = z.infer<typeof CohortBreakdownConfigParser>;

export const IntOperator = z.enum(['=', '!=', '>', '>=', '<', '<=']);
export const StringOperator = z.enum(['=', '!=', 'like', 'not like']);
export const ArrayOperator = z.enum(['in', 'not in', 'hasAny']);
export type ArrayOperator = z.infer<typeof ArrayOperator>;

export const FilterOperator = z.union([
  IntOperator,
  StringOperator,
  ArrayOperator,
]);
export type FilterOperator = z.infer<typeof FilterOperator>;

export const getIntFilterParser = <T extends string>(field: z.ZodType<T>) => {
  return z
    .object({
      field,
      operator: IntOperator,
      value: z.number(),
    })
    .or(
      z.object({
        field,
        operator: ArrayOperator,
        value: z.array(z.string()),
      }),
    );
};

export const getStringFilterParser = <T extends string>(
  field: z.ZodType<T>,
) => {
  return z
    .object({
      field,
      operator: StringOperator,
      value: z.string(),
    })
    .or(
      z.object({
        field,
        operator: ArrayOperator,
        value: z.array(z.string()),
      }),
    );
};

export const getDateFilterParser = <T extends string>(field: z.ZodType<T>) => {
  return z
    .object({
      field,
      operator: IntOperator,
      value: z.coerce.date(),
    })
    .or(
      z.object({
        field,
        operator: ArrayOperator,
        value: z.array(z.string()),
      }),
    );
};

export const getEnumFilterParser = <
  T extends string,
  R extends [string, ...string[]],
>(
  field: z.ZodType<T>,
  values: z.ZodEnum<R>,
) => {
  return z.object({
    field,
    operator: IntOperator,
    value: values,
  });
};

export const getFilterParser = <T extends string>(field: z.ZodType<T>) => {
  return z.union([
    getIntFilterParser(field),
    getStringFilterParser(field),
    getDateFilterParser(field),
  ]);
};

export const getSplitConfigParser = <T>(dimension: z.ZodType<T>) => {
  return z
    .object({
      column: dimension.optional(),
      top: z.number(),
    })
    .optional();
};

export type Filter = z.infer<ReturnType<typeof getFilterParser>>;
export const getChartConfigParser = <E, F extends z.ZodType<Filter>>(
  dimensions: z.ZodType<E>,
  filters: z.ZodArray<F>,
) => {
  return z.object({
    split: getSplitConfigParser(dimensions),
    filters: filters.default([]),
    timeRange: TimeRangeParser,
  });
};

export type ChartConfig<
  E,
  F extends z.ZodType<Filter> = z.ZodType<Filter>,
> = z.infer<ReturnType<typeof getChartConfigParser<E, F>>>;

export const ClickableCardBreakdownConfig = z.object({
  timeRange: TimeRangeParser,
});
export type ClickableCardBreakdownConfig = z.infer<
  typeof ClickableCardBreakdownConfig
>;
