import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByCompaniesRow = z.object({
  company: z.string(),
  accountType: z.string().nullable(),
  accountState: z.string().nullable(),
  companyName: z.string().nullable(),
  activeUsers: z.coerce.number(),
  joinDate: z.coerce.date(),
  lastActive: z.coerce.date(),
  lastEngaged: z.coerce.date().nullish(),
  totalEventsl30: z.coerce.number(),
  activeSessionsL30: z.coerce.number(),
  activeSessionsDelta: z.coerce.number(),
  engagedSessionsL30: z.coerce.number(),
  engagedRateL30: z.coerce.number(),
  joinMonth: z.string(),
});

export type FullBreakdownByCompaniesRow = z.infer<
  typeof FullBreakdownByCompaniesRow
>;

export const breakdownByNewCompaniesAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByCompaniesRow,
  [{ column: 'joinDate', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
);

export const breakdownByCompaniesLargestIncreaseInActivityAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCompaniesRow,
    [{ column: 'activeSessionsDelta', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByMostEngagedCompaniesAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCompaniesRow,
    [{ column: 'engagedSessionsL30', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByMostActiveCompaniesAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCompaniesRow,
    [{ column: 'activeSessionsL30', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByCompaniesLargestDecreaseInActivityAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByCompaniesRow,
    [{ column: 'activeSessionsDelta', direction: 'ASC' }],
    fixedEndDateFormattedDateRangeAtom,
  );
