import { useTranslation } from 'react-i18next';

import { Button, Text } from 'components/uikit';
import { ArrowUpRightIcon } from 'components/uikit/icons/ArrowUpRight';
import { useOpenIntercom } from 'utils/intercom/useOpenIntercom';

export function ContactSupport() {
  const { t } = useTranslation('common');
  const openIntercom = useOpenIntercom();

  return (
    <Button
      variant='unstyled'
      height='18px'
      display='flex'
      gap='4px'
      alignItems='center'
      onClick={openIntercom}
    >
      <Text color='secondary.button' fontSize='xxs' fontWeight='semibold'>
        {t('contact_support')}
      </Text>
      <ArrowUpRightIcon height='16px' width='16px' stroke='secondary.button' />
    </Button>
  );
}
