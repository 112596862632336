import { authorisedFetch } from 'api/rest/authorisedFetch';
import { ChatAnswer } from 'data/ai/models';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { PARROT_API_URL } from './resourcesUrls';

export async function chatWithParrot(body: {
  prompt: string;
  chatId?: string;
}): Promise<ChatAnswer> {
  const result = await authorisedFetch(`${PARROT_API_URL}/chat`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to chat with parrot: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(ChatAnswer, data);
}
