import { z } from 'zod';

export const CustomerNote = z.object({
  id: z.string().uuid(),
  creatorId: z.string().uuid(),
  content: z.string(),
  insertedAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type CustomerNote = z.infer<typeof CustomerNote>;
