import { createIcon } from '@chakra-ui/react';

export const SegmentMenuIcon = createIcon({
  displayName: 'SegmentMenuIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <rect x='0' width='32' height='32' fill='#52BD94' />
      <g clipPath='url(#clip0_47_35893)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.271 19.145H7.53489C7.2972 19.145 7.06924 19.0505 6.90117 18.8825C6.73309 18.7144 6.63867 18.4864 6.63867 18.2487C6.63867 18.011 6.73309 17.7832 6.90117 17.6151C7.06924 17.447 7.2972 17.3525 7.53489 17.3525H18.271C18.5086 17.3525 18.7366 17.447 18.9047 17.6151C19.0728 17.7832 19.1672 18.011 19.1672 18.2487C19.1672 18.4864 19.0728 18.7144 18.9047 18.8825C18.7366 19.0505 18.5086 19.145 18.271 19.145Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.9693 24.6385C15.099 24.6391 14.2335 24.5084 13.402 24.2511C13.2895 24.2164 13.185 24.16 13.0943 24.0849C13.0036 24.0099 12.9287 23.9177 12.8736 23.8137C12.8186 23.7096 12.7845 23.5958 12.7735 23.4786C12.7624 23.3615 12.7746 23.2433 12.8092 23.1308C12.8438 23.0183 12.9003 22.9137 12.9753 22.823C13.0504 22.7324 13.1425 22.6574 13.2466 22.6024C13.3506 22.5473 13.4645 22.5132 13.5816 22.5022C13.6988 22.4911 13.817 22.5034 13.9295 22.538C14.5907 22.7393 15.2781 22.8414 15.9693 22.8413C17.4424 22.8485 18.8783 22.3793 20.0627 21.5036C21.2472 20.6278 22.1168 19.3926 22.5416 17.9822C22.5763 17.8697 22.6327 17.7652 22.7078 17.6745C22.7828 17.5838 22.875 17.5088 22.979 17.4538C23.083 17.3986 23.1969 17.3646 23.314 17.3535C23.4313 17.3425 23.5495 17.3547 23.6619 17.3893C23.7749 17.4234 23.8799 17.4795 23.9711 17.5544C24.0623 17.6292 24.1378 17.7214 24.1933 17.8254C24.2488 17.9296 24.2832 18.0435 24.2945 18.161C24.3059 18.2784 24.2939 18.3968 24.2594 18.5096C23.7217 20.2876 22.6244 21.8445 21.1307 22.9488C19.6371 24.0531 17.8268 24.6457 15.9693 24.6385Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.4086 14.5698H13.6726C13.4349 14.5698 13.2069 14.4755 13.0389 14.3074C12.8708 14.1393 12.7764 13.9113 12.7764 13.6736C12.7764 13.436 12.8708 13.208 13.0389 13.0399C13.2069 12.8718 13.4349 12.7773 13.6726 12.7773H24.4086C24.6464 12.7773 24.8743 12.8718 25.0423 13.0399C25.2104 13.208 25.3048 13.436 25.3048 13.6736C25.3048 13.9113 25.2104 14.1393 25.0423 14.3074C24.8743 14.4755 24.6464 14.5698 24.4086 14.5698Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.5388 14.5703C8.3011 14.5703 8.07315 14.4759 7.90508 14.3079C7.737 14.1398 7.64258 13.9118 7.64258 13.6741C7.64318 13.5857 7.65573 13.4977 7.67991 13.4127C8.01502 12.3223 8.56183 11.3087 9.28908 10.4298C10.0163 9.55088 10.9097 8.82398 11.9181 8.29072C12.9266 7.75745 14.0303 7.42823 15.1661 7.32193C16.3018 7.21564 17.4475 7.33433 18.5373 7.67123C18.7648 7.74219 18.955 7.90038 19.066 8.11123C19.1772 8.32207 19.2002 8.56835 19.1301 8.79614C19.0956 8.90855 19.0391 9.01311 18.964 9.1036C18.8888 9.19409 18.7965 9.26876 18.6924 9.32343C18.5883 9.3781 18.4743 9.41167 18.3572 9.42212C18.24 9.43257 18.122 9.41966 18.0098 9.38428C17.3486 9.18302 16.6613 9.08082 15.97 9.08092C14.497 9.0737 13.0611 9.54297 11.8766 10.4187C10.6921 11.2944 9.82257 12.5297 9.39769 13.9401C9.34159 14.1241 9.2278 14.2852 9.07314 14.3995C8.91846 14.5138 8.73113 14.5753 8.5388 14.575'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.1871 10.2762C22.1871 10.4534 22.1346 10.6267 22.0361 10.7741C21.9376 10.9215 21.7976 11.0363 21.6339 11.1041C21.4701 11.172 21.2899 11.1898 21.116 11.1552C20.9421 11.1206 20.7825 11.0353 20.6572 10.9099C20.5318 10.7846 20.4464 10.6249 20.4119 10.451C20.3773 10.2772 20.3951 10.0969 20.4628 9.93316C20.5307 9.7694 20.6456 9.62947 20.793 9.531C20.9403 9.43252 21.1136 9.37988 21.2909 9.37988C21.4085 9.37988 21.5251 9.40311 21.6339 9.44815C21.7426 9.49319 21.8413 9.55923 21.9246 9.64245C22.0078 9.72567 22.0738 9.82443 22.1189 9.93316C22.1639 10.0419 22.1871 10.1585 22.1871 10.2762ZM11.5444 21.6656C11.5444 21.843 11.4918 22.0162 11.3934 22.1636C11.2949 22.311 11.1549 22.4259 10.9912 22.4937C10.8274 22.5616 10.6472 22.5792 10.4733 22.5446C10.2995 22.5101 10.1398 22.4248 10.0144 22.2994C9.88911 22.1741 9.80376 22.0144 9.76918 21.8405C9.73459 21.6666 9.75234 21.4866 9.82017 21.3227C9.888 21.159 10.0029 21.0189 10.1503 20.9205C10.2976 20.8221 10.4709 20.7695 10.6482 20.7695C10.7659 20.7695 10.8824 20.7927 10.9912 20.8378C11.0999 20.8828 11.1987 20.9487 11.2819 21.032C11.3651 21.1152 11.4311 21.2141 11.4762 21.3227C11.5212 21.4315 11.5444 21.548 11.5444 21.6656Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_47_35893'>
          <rect
            width='18.6667'
            height='18.6667'
            fill='white'
            transform='translate(6.66504 6.66699)'
          />
        </clipPath>
      </defs>
    </>
  ),
});
