import { useEffect } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import { idTokenAtom } from 'data/auth/atoms/idTokenAtom';
import { isLoaded } from 'utils/types';

import { fetchUserProfilesAtom } from '../atoms/userProfilesAtom';

export function useFetchUserProfiles() {
  const { idToken } = useAtomValue(idTokenAtom);
  const [{ fetchStatus, maintenance }, fetchUserProfiles] = useAtom(
    fetchUserProfilesAtom,
  );

  useEffect(() => {
    if (idToken && fetchStatus === 'INITIAL') {
      fetchUserProfiles({});
    }
  }, [idToken, fetchStatus, fetchUserProfiles]);

  return { userLoaded: idToken && isLoaded(fetchStatus), maintenance };
}
