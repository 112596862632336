export const SidebarMenuDesktop = {
  baseStyle: {
    w: '168px',
    h: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    background: 'grey.primaryText',
    _dark: {
      bg: 'grey.white',
    },
    pt: '8px',
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
  },
};
