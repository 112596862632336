import { useAtomValue } from 'jotai';

import { chartSettingsAtom } from 'data/settings/atoms/chartSettingsAtom';
import { ProductSettings } from 'data/settings/models/ProductSettings';

export type ProductSignals = {
  signal1: string | null | undefined;
  signal2: string | null | undefined;
  signal3: string | null | undefined;
  signal4: string | null | undefined;
  signal5: string | null | undefined;
};

export function extractSignalTypes(
  eventMappings: ProductSettings['eventMappings'],
) {
  const signal1 = eventMappings.find(
    ({ frameworkStep }) => frameworkStep === 'signal_1',
  )?.type;
  const signal2 = eventMappings.find(
    ({ frameworkStep }) => frameworkStep === 'signal_2',
  )?.type;
  const signal3 = eventMappings.find(
    ({ frameworkStep }) => frameworkStep === 'signal_3',
  )?.type;
  const signal4 = eventMappings.find(
    ({ frameworkStep }) => frameworkStep === 'signal_4',
  )?.type;
  const signal5 = eventMappings.find(
    ({ frameworkStep }) => frameworkStep === 'signal_5',
  )?.type;

  return {
    signal1,
    signal2,
    signal3,
    signal4,
    signal5,
  } as ProductSignals;
}

export function useProductSettings() {
  const { PRODUCT, fetchStatus } = useAtomValue(chartSettingsAtom);

  if (!PRODUCT)
    return {
      productSignals: {
        signal1: null,
        signal2: null,
        signal3: null,
        signal4: null,
        signal5: null,
      } as ProductSignals,
      productSettings: PRODUCT,
      showCompaniesSplit: false,
      fetchStatus,
    };

  return {
    productSignals: extractSignalTypes(PRODUCT?.eventMappings ?? []),
    productSettings: PRODUCT,
    showCompaniesSplit: PRODUCT.showCompaniesSplit,
    fetchStatus,
  };
}
