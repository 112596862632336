import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';

export function useCustomerActiveUsersBlocks() {
  const { blocks } = useCustomerBreakdownBlocks();
  const chartBlock = blocks.find(
    (block) =>
      block.uniqueName === 'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_CHART',
  );
  const weekSummaryBlock = blocks.find(
    (block) =>
      block.uniqueName === 'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_THIS_WEEK',
  );
  const monthSummaryBlock = blocks.find(
    (block) =>
      block.uniqueName === 'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_THIS_MONTH',
  );
  const blocksExist = !!chartBlock && !!weekSummaryBlock && !!monthSummaryBlock;
  return { chartBlock, weekSummaryBlock, monthSummaryBlock, blocksExist };
}
