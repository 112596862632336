import { fetchChartSettings } from 'api/rest/charts/fetchChartsSettings';
import { ChartSettings } from 'data/settings/models/ChartSettings';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const chartSettingsAtom = getDataAtom<ChartSettings | null>(null);
export const fetchChartSettingsAtom = getDataFetchingAtom<
  ChartSettings | null,
  undefined
>(chartSettingsAtom, fetchChartSettings);
