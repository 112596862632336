import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByLostDealsRow = z.object({
  dealName: z.string(),
  company: z.string(),
  ownerName: z.string(),
  closeDate: z.coerce.date(),
  timeToCloseInDays: z.coerce.number(),
  expectedDealValue: z.coerce.number(),
});

export type FullBreakdownByLostDealsRow = z.infer<
  typeof FullBreakdownByLostDealsRow
>;

export const breakdownByRecentLostDealsAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByLostDealsRow,
    [{ column: 'closeDate', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByLargestLostDealsAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByLostDealsRow,
    [{ column: 'expectedDealValue', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );
