import { createIcon } from '@chakra-ui/react';

export const LongRightArrowIcon = createIcon({
  displayName: 'LongRightArrowIcon',
  viewBox: '0 0 61 25',
  path: (
    <path
      d='M60.9278 13.4708C61.3183 13.0803 61.3183 12.4471 60.9278 12.0566L54.5639 5.6926C54.1733 5.30208 53.5402 5.30208 53.1496 5.6926C52.7591 6.08313 52.7591 6.71629 53.1496 7.10682L58.8065 12.7637L53.1496 18.4205C52.7591 18.8111 52.7591 19.4442 53.1496 19.8347C53.5402 20.2253 54.1733 20.2253 54.5639 19.8347L60.9278 13.4708ZM0.779297 13.7637H60.2207V11.7637H0.779297V13.7637Z'
      fill='currentColor'
    />
  ),
});
