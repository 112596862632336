import { CompanyTags } from 'components/CustomerDrawer/CompanyDrawer/CompanyTags';
import { CompanyBasicInfoAndCloseButton } from 'components/CustomerDrawer/CompanyDrawer/DrawerHeader/CompanyBasicInfoAndCloseButton';
import { VStack } from 'components/uikit';
import { CustomerTag } from 'data/customer/CustomerTag';

type DrawerHeaderProps = {
  customerCompanyId: string;
  onClick: () => void;
  autoCohorts?: string[];
  tags: CustomerTag[];
  isTagsOpen: boolean;
  onTagsOpen: () => void;
};

export function DrawerHeader({
  customerCompanyId,
  onClick,
  tags,
  isTagsOpen,
  onTagsOpen,
}: DrawerHeaderProps) {
  return (
    <VStack p={'24px'} spacing={'16px'} backgroundColor={'grey.white'}>
      <CompanyBasicInfoAndCloseButton
        customerCompanyId={customerCompanyId}
        onClick={onClick}
      />
      {/*<CompanyDescription />*/}
      {/*<CompanyAdditionalData />*/}
      {/*<CompanyAutoCohorts autoCohorts={autoCohorts} />*/}
      <CompanyTags
        customerCompanyId={customerCompanyId}
        companyTags={tags}
        isTagsOpen={isTagsOpen}
        onTagsOpen={onTagsOpen}
      />
    </VStack>
  );
}
