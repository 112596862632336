import { useTranslation } from 'react-i18next';

import { Text } from 'components/uikit';

export function SidebarMenuMobileHeaderTitle() {
  const { t } = useTranslation('sidebar');

  return (
    <Text fontWeight='semibold' fontSize='18px' color={'#F8FAFC'}>
      {t('menu')}
    </Text>
  );
}
