export const POSITIVE_SENTIMENT = 'primary.text';
export const NEGATIVE_SENTIMENT = 'error.text';
export const NEUTRAL_SENTIMENT = 'grey.tertiaryText';

export type SentimentColor =
  | typeof POSITIVE_SENTIMENT
  | typeof NEGATIVE_SENTIMENT
  | typeof NEUTRAL_SENTIMENT;

export function getSentimentColor(value: number): SentimentColor {
  if (value > 0) {
    return POSITIVE_SENTIMENT;
  } else if (value < 0) {
    return NEGATIVE_SENTIMENT;
  } else {
    return NEUTRAL_SENTIMENT;
  }
}
