import React from 'react';

import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SystemStyleObject,
} from '@chakra-ui/react';
import { TypographyProps } from '@chakra-ui/styled-system';

import { Skeleton } from 'components/uikit/Skeleton';

import { BoxProps, Text } from '../../reexport';
import { getInputIcon } from './getInputIcon';
import { getInputVariant } from './getInputVariant';
import { getMessageColorForStatus, InputStatus } from './InputStatus';

export interface InputProps extends BoxProps {
  name?: string;
  id?: string;
  label?: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  placeholder?: string;
  value?: string | number;
  reset?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputStatus?: InputStatus;
  message?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  type?: 'text' | 'number' | 'password';
  fontWeight?: TypographyProps['fontWeight'];
  labelProps?: FormLabelProps;
  hasStatusIcon?: boolean;
  errorMessageWidth?: string;
  _placeholder?: SystemStyleObject;
  autoFocus?: boolean;
  iconWidth?: BoxProps['w'];
  rightIconWidth?: BoxProps['w'];
  ref?: React.RefObject<HTMLInputElement>;
}

export function Input({
  name,
  id,
  label,
  icon,
  placeholder,
  value,
  onChange,
  message,
  inputStatus,
  onFocus,
  onBlur,
  isLoading = false,
  type = 'text',
  reset,
  labelProps,
  fontWeight = 'medium',
  maxH,
  hasStatusIcon = true,
  _placeholder,
  errorMessageWidth,
  autoFocus = false,
  iconWidth,
  rightIconWidth,
  ref,
  ...props
}: InputProps) {
  const rightIcon = props.rightIcon ?? getInputIcon(inputStatus);

  return (
    <FormControl
      w='100%'
      display='flex'
      flexDir='column'
      rowGap={label ? '6px' : undefined}
      position='relative'
      {...props}
    >
      <FormLabel
        color='grey.secondaryText'
        fontSize='sm'
        fontWeight='medium'
        htmlFor={id}
        lineHeight='20px'
        m='0 0 0 2px'
        {...labelProps}
      >
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading} variant='input'>
        <InputGroup variant={getInputVariant(inputStatus)}>
          {icon ? (
            <InputLeftElement h={props.height ?? props.h} w={iconWidth}>
              {icon}
            </InputLeftElement>
          ) : null}
          <ChakraInput
            id={id}
            name={name}
            type={type}
            maxH={maxH}
            placeholder={placeholder}
            disabled={inputStatus === 'DISABLED'}
            backgroundColor='grey.white'
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            fontWeight={fontWeight}
            _placeholder={_placeholder}
            autoFocus={autoFocus}
            ref={ref}
          />

          {rightIcon && hasStatusIcon ? (
            <InputRightElement h={props.height ?? props.h} w={rightIconWidth}>
              {rightIcon}
            </InputRightElement>
          ) : null}
        </InputGroup>
      </Skeleton>

      <Text
        fontSize='sm'
        color={getMessageColorForStatus(inputStatus)}
        fontWeight='medium'
        position='absolute'
        width={errorMessageWidth}
        left='2px'
        bottom='-4px'
        transform='translateY(100%)'
      >
        {message}
      </Text>
    </FormControl>
  );
}
