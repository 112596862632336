import { useTranslation } from 'react-i18next';

import { OpenUserDrawerLinkBox } from 'components/CustomerDrawer/renderers/OpenUserDrawerLinkBox';
import { UserSegmentIndicator } from 'components/CustomerDrawer/UserSegmentIndicator';
import { HStack } from 'components/uikit';
import { FullBreakdownByUsersRow } from 'data/charts/atoms/product/users/breakdowns';
import { TableConfig } from 'data/charts/models/TableConfig';
import { getSegmentFromBreakdownByUsersRow } from 'data/healthReports/utils/userSegmentIndicator';
import { formatDate } from 'utils/datetime';

export function useActiveUsersTableConfig(onDrawerClose: () => void) {
  const { t } = useTranslation('breakdowns');

  const config: TableConfig<FullBreakdownByUsersRow> = [
    {
      accessor: 'customerId',
      header: t('user'),
      sortable: true,
      style: {
        fontWeight: '500',
        textColor: 'grey.primaryText',
        width: '295px',
      },
      render: (row) => (
        <HStack>
          <UserSegmentIndicator
            segment={getSegmentFromBreakdownByUsersRow(row)}
          />
          <OpenUserDrawerLinkBox
            onDrawerClose={onDrawerClose}
            department={'PRODUCT'}
            departmentId={row.userId}
            userName={row.customerId}
          />
        </HStack>
      ),
    },
    {
      accessor: 'accountType',
      header: t('account_type'),
      sortable: true,
      style: {
        align: 'right',
      },
      hidden: true,
    },
    {
      accessor: 'joinDate',
      header: t('join_date'),
      render: (row) => formatDate(row.joinDate, 'DAY', true, false, true),
      sortable: true,
    },
    {
      accessor: 'activeSessionsL30',
      header: t('active_days_last_month'),
      sortable: true,
      style: {
        align: 'right',
      },
    },
  ];

  return config;
}
