import { Center, createIcon, HStack, Text } from '@chakra-ui/react';

interface SelectionModalHeaderProps {
  title: string;
  tealIcon?: boolean;
  IconComponent: ReturnType<typeof createIcon>;
}

function ModalHeader({
  title,
  tealIcon = false,
  IconComponent,
}: SelectionModalHeaderProps) {
  return (
    <HStack>
      <Center
        bgColor={tealIcon ? 'teal.background' : 'secondary.background'}
        width='32px'
        height='32px'
        borderRadius='4px'
      >
        <IconComponent
          color={tealIcon ? 'teal.text' : 'secondary.text'}
          stroke={tealIcon ? 'teal.text' : 'secondary.text'}
          boxSize='20px'
          fill='transparent'
        />
      </Center>
      <Text fontWeight='semibold' fontSize='lg' lineHeight='24px'>
        {title}
      </Text>
    </HStack>
  );
}

export default ModalHeader;
