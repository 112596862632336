export const Text = {
  baseStyle: {
    color: 'grey.primaryText',
  },
  variants: {
    numeric: {
      fontFamily: 'var(--font-numeric)',
    },
  },
};
