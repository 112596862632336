import { endOfHour, startOfHour } from 'date-fns';
import { atom, Atom } from 'jotai';

import { Department } from 'data/blocks/models/Block';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import {
  DatePickerAtomValue,
  FormattedDatePickerAtomValue,
  getFormattedDateRangeAtom,
} from 'utils/atoms/dateRangeAtom';

// TODO: remove and read granularity from atom response
export const getDefaultGranularityByDepartment = (
  department: Department | undefined,
): AggregationPeriod => {
  if (department === 'PRODUCT' || department === 'GROWTH') {
    return AggregationPeriod.enum.WEEK;
  }
  return AggregationPeriod.enum.MONTH;
};

export const datePickerInitialState: DatePickerAtomValue = {
  date: startOfHour(new Date()),
  granularity: AggregationPeriod.enum.MONTH,
};

export type FormattedDatePickerAtom = Atom<FormattedDatePickerAtomValue>;

export const departmentMissionControlDatePickerAtom = atom(
  datePickerInitialState,
);
export const formattedDatePickerAtom = getFormattedDateRangeAtom(
  departmentMissionControlDatePickerAtom,
);

export const fixedEndDateDateRangeAtom = atom({
  date: endOfHour(new Date()),
  granularity: AggregationPeriod.enum.SINCE_THE_BEGINNING_OF_TIME,
});

export const fixedEndDateFormattedDateRangeAtom = getFormattedDateRangeAtom(
  fixedEndDateDateRangeAtom,
);

export const fixedEndDateAYearAgoDateRangeAtom = atom({
  date: endOfHour(new Date()),
  granularity: AggregationPeriod.enum.YEAR,
});

export const fixedEndDateFormattedAYearAgoDateRangeAtom =
  getFormattedDateRangeAtom(fixedEndDateAYearAgoDateRangeAtom);
