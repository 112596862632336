import { createIcon } from '@chakra-ui/react';

export const GoalIcon = createIcon({
  displayName: 'GoalIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M7.39429 21.3907L10.8783 15.6438L8.89758 12.5711L3 21.3907H7.39429ZM7.39429 21.3907H21L14.1971 10.1692L7.39429 21.3907Z'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M14.2139 7.19892C14.2139 7.19892 14.5215 6.89126 15.4445 6.89126C16.3675 6.89126 16.9828 7.50658 17.9058 7.50658C18.8288 7.50658 19.1365 7.19892 19.1365 7.19892V3.81463C19.1365 3.81463 18.8288 4.1223 17.9058 4.1223C16.9828 4.1223 16.3675 3.50697 15.4445 3.50697C14.5215 3.50697 14.2139 3.81463 14.2139 3.81463M14.2139 7.19892V10.5301M14.2139 7.19892V3.81463M14.2139 3.81463V2.6095'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
