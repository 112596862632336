import { atom } from 'jotai';

import { CustomerUser } from 'data/customer/CustomerUser';

export const standaloneDrawerCustomerUser = atom<{
  user?: CustomerUser;
}>({});

export const standaloneDrawerCustomerUserActions = atom<{
  onOpen?: () => void;
}>({});
