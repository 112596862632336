import { useCallback, useEffect, useState } from 'react';

import { TFunction } from 'react-i18next';

import { InputStatus, ValidateFunction } from './InputStatus';

/**
 * hook that returns input status and error message for a given value and validation function.
 * - i.e. the error message only appears when the input is blurred, and when focused it disappears.
 * @param value: the input's current value
 * @param validateFunction: function that returns an input status and an error message
 * @returns onFocus, onBlur, inputStatus and message (corrected for the above conditions)
 */
export function useValidationOnBlur<T>({
  value,
  validate,
  t,
}: {
  value?: T;
  validate?: ValidateFunction<T>;
  t?: TFunction;
}) {
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [inputStatus, setInputStatus] = useState<InputStatus>(null);
  const [message, setMessage] = useState('');
  const { inputStatus: calculatedInputStatus, message: calculatedMessage } =
    validate ? validate(value) : { inputStatus: null, message: '' };

  useEffect(() => {
    if (!hasBeenTouched && isFocused) {
      setHasBeenTouched(true);
    }

    // if it's a success, don't wait for touched / blur - it's valid
    if (calculatedInputStatus === 'SUCCESS') {
      setInputStatus('SUCCESS');
      return;
    }

    // if it's invalid we don't want to show an error until the user's blurred the input
    if (isFocused || !hasBeenTouched) {
      // don't show any status
      setInputStatus(null);
      setMessage('');
      return;
    }

    // must be an error, and we've blurred - show it
    setInputStatus(calculatedInputStatus);
    if (calculatedMessage) {
      if (t) setMessage(t(calculatedMessage));
      else setMessage(calculatedMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, calculatedInputStatus, calculatedMessage, hasBeenTouched]);

  const reset = useCallback(() => {
    setHasBeenTouched(false);
    setInputStatus(null);
    setIsFocused(false);
    setMessage('');
  }, []);

  return {
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    inputStatus,
    message,
    reset,
  };
}
