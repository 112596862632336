import { z } from 'zod';

import { CustomersUniqueBlockName } from 'data/blocks/models/Block';
import {
  breakdownByAllCustomersAtoms,
  breakdownByChurnRisksAtoms,
  breakdownByTopNewTrialsAtoms,
  breakdownByUpsellOpportunitiesAtoms,
  FullBreakdownByAllCustomersRow,
} from 'data/charts/atoms/fullBreakdowns/customers/BreakdownByAllCustomers';
import { BreakdownConfig } from 'data/charts/configs/breakdowns/types';

export const CustomersBreakdownName = z.enum([
  'byAllCustomers',
  'topNewTrials',
  'upsellOpportunities',
  'churnRisks',
]);
export type CustomersBreakdownName = z.infer<typeof CustomersBreakdownName>;

export const CUSTOMERS_BREAKDOWNS_CONFIG: Record<
  CustomersBreakdownName,
  BreakdownConfig<CustomersUniqueBlockName>
> = {
  byAllCustomers: {
    blockName: 'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_ALL_CUSTOMERS',
    columnParser: FullBreakdownByAllCustomersRow.keyof(),
    atoms: breakdownByAllCustomersAtoms,
  },
  topNewTrials: {
    blockName: 'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_TOP_NEW_TRIALS',
    columnParser: FullBreakdownByAllCustomersRow.keyof(),
    atoms: breakdownByTopNewTrialsAtoms,
  },
  upsellOpportunities: {
    blockName: 'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_UPSELL_OPPORTUNITIES',
    columnParser: FullBreakdownByAllCustomersRow.keyof(),
    atoms: breakdownByUpsellOpportunitiesAtoms,
  },
  churnRisks: {
    blockName: 'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_CHURN_RISKS',
    columnParser: FullBreakdownByAllCustomersRow.keyof(),
    atoms: breakdownByChurnRisksAtoms,
  },
} as const;
