import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  sharingBlockContextAtom,
  useSetActiveSharingOptionId,
  useSharingBlockContext,
} from 'components/Sharing/sharingBlockContextAtom';
import { SharingBlockModalOptionButton } from 'components/Sharing/SharingBlockModalOptionButton';
import { ShareOptions } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import { HStack, Loader, Stack } from 'components/uikit';

const getActiveOption = (
  sharingOptions: ShareOptions,
  activeSharingOptionId?: string,
) => {
  const activeId = activeSharingOptionId ?? 'SHARE_WITH_TEAM';
  const newActiveOption = sharingOptions.find(
    (option) => option.id === activeId,
  );

  return newActiveOption ? newActiveOption : sharingOptions[0];
};

export function SharingBlockModalBody() {
  const { t } = useTranslation('common');
  const { activeSharingOptionId } = useSharingBlockContext();
  const setSharingBlockContext = useSetActiveSharingOptionId();
  const { sharingOptions } = useAtomValue(sharingBlockContextAtom);

  const modalHeight = '450px';

  if (!sharingOptions) {
    return <Loader fullScreen={false} width='100%' height={modalHeight} />;
  }

  const activeOption = getActiveOption(sharingOptions, activeSharingOptionId);

  const ActiveOptionBody = activeOption.body;

  return (
    <HStack height={modalHeight} spacing='0px' overflow='hidden'>
      <Stack
        paddingX='16px'
        paddingY='16px'
        spacing='4px'
        height='100%'
        borderRight='2px solid'
        borderRightColor={'grey.border'}
      >
        {sharingOptions.map((option) => (
          <SharingBlockModalOptionButton
            key={option.id}
            Icon={option.icon}
            caption={t(option.titleTk)}
            isActive={option.id === activeOption.id}
            onClick={() => {
              setSharingBlockContext(option.id);
            }}
            disabled={!option.enabled}
          />
        ))}
      </Stack>
      <ActiveOptionBody />
    </HStack>
  );
}
