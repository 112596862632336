import { ReactElement, SVGAttributes } from 'react';

import { AxisTickProps } from '@nivo/axes/dist/types/types';
import { format, getWeek } from 'date-fns';

import { ChartAxisLabel } from 'components/charts/ChartAxisLabel';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';

interface RowTspanProps extends SVGAttributes<SVGTSpanElement> {
  numeric?: boolean;
}

function TopRowTspan({ ...props }: RowTspanProps) {
  return <tspan textAnchor='middle' {...props} />;
}

function BottomRowTspan({ numeric = false, ...props }: RowTspanProps) {
  return (
    <tspan
      x='0'
      dy='20px'
      dx={-3}
      style={{
        fontFamily: numeric ? 'var(--font-numeric)' : 'var(--font-base)',
      }}
      {...props}
    />
  );
}

export type SpecificGranularity = 'DAY_IN_MONTH' | 'DAY_IN_WEEK';

export const formatTimeAxisValues =
  (
    aggPeriod: AggregationPeriod,
    specificGranularity?: SpecificGranularity,
    isMobile = false,
  ) =>
  // eslint-disable-next-line react/display-name
  ({ value, tickIndex, x, y }: AxisTickProps<string>) => {
    const date = new Date(value);

    if (
      isMobile &&
      aggPeriod === 'DAY' &&
      specificGranularity === 'DAY_IN_MONTH' &&
      tickIndex !== 0 &&
      (tickIndex + 1) % 7 !== 0
    ) {
      return null as unknown as ReactElement;
    }

    return (
      <ChartAxisLabel key={tickIndex} {...getTextProps(aggPeriod, x, y)}>
        {formatTimeContent(
          aggPeriod,
          date,
          tickIndex,
          specificGranularity,
          isMobile,
        )}
      </ChartAxisLabel>
    );
  };

const getTextProps = (aggPeriod: AggregationPeriod, x: number, y: number) => {
  switch (aggPeriod) {
    case 'DAY':
    case 'WEEK':
    case 'HOUR':
    case 'MONTH':
    default:
      return { x, y };
  }
};

const formatTimeContent = (
  aggPeriod: AggregationPeriod,
  date: Date,
  index: number,
  specificGranularity?: SpecificGranularity,
  isMobile = false,
) => {
  if (aggPeriod == 'YEAR') {
    return date.getFullYear();
  }
  if (aggPeriod == 'MONTH') {
    return format(date, isMobile ? 'MMM' : "MMM ''yy");
  }
  if (aggPeriod == 'WEEK') {
    const week = getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 7 });
    return (
      <>
        <TopRowTspan
          style={{
            fontFamily: 'var(--font-base)',
          }}
        >
          W{week}
        </TopRowTspan>
        <BottomRowTspan textAnchor='middle'>
          {isNaN(week) ? '' : format(date, 'd MMM')}
        </BottomRowTspan>
      </>
    );
  }
  if (aggPeriod == 'DAY') {
    if (specificGranularity === 'DAY_IN_MONTH') {
      return <TopRowTspan>{format(date, isMobile ? 'd' : 'dd')}</TopRowTspan>;
    } else if (specificGranularity === 'DAY_IN_WEEK') {
      return <TopRowTspan>{format(date, 'EEE')}</TopRowTspan>;
    } else {
      return <TopRowTspan>{format(date, isMobile ? 'd' : 'dd')}</TopRowTspan>;
    }
  }
  if (aggPeriod == 'HOUR') {
    const hour = date.getHours();
    return (
      <>
        <TopRowTspan>{format(date, 'ha')}</TopRowTspan>
        {index === 0 || hour === 0 ? (
          <BottomRowTspan>{format(date, 'd MMM')}</BottomRowTspan>
        ) : null}
      </>
    );
  }
  if (aggPeriod == 'MINUTE') {
    return format(date, 'HH:mm');
  }
};
