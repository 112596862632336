import { Avatar } from '@chakra-ui/react';

import { Box, IconButton, LogoutIcon, Text } from 'components/uikit';
import { useCurrentProfile } from 'data/user/hooks/useCurrentProfile';
import { useNavigate } from 'utils/navigation';
import paths from 'utils/paths';

function SidebarMenuMobileUser() {
  const { user } = useCurrentProfile();
  const avatarUrl = user?.avatar ?? undefined;
  const avatarName = `${user?.firstName} ${user?.lastName}` ?? undefined;

  return (
    <Box
      w='100%'
      h='100%'
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='flex-start'
    >
      <Box w='48px' h='48px' p='8px' display='grid' placeItems='center'>
        <Avatar name={avatarName} src={avatarUrl} size='sm' />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        h='100%'
      >
        <Text
          color='white'
          fontSize='16px'
          fontWeight='semibold'
          lineHeight='100%'
        >
          {`${user?.firstName} ${user?.lastName} `}
        </Text>
        <Text color='white' opacity='0.5' fontSize='12px'>
          {user?.email}
        </Text>
      </Box>
    </Box>
  );
}

function SidebarMenuMobileLogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(paths.logout);
  };

  return (
    <IconButton
      aria-label='Logout'
      backgroundColor='transparent'
      variant='grey'
      size='md'
      alignItems='center'
      onClick={handleLogout}
      icon={
        <LogoutIcon
          pt='2px'
          pl='4px'
          opacity='0.7'
          width='24px'
          height='24px'
          color='#697586'
          stroke='#697586'
        />
      }
    />
  );
}

export function SidebarMenuMobileUserButton() {
  return (
    <Box
      w='100%'
      h='56px'
      bg='grey.primaryText'
      borderRadius='8px'
      background={'#121926'}
      borderWidth='1px'
      borderColor={'grey.border.dark'}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      pr='8px'
      alignSelf='stretch'
    >
      <SidebarMenuMobileUser />
      <SidebarMenuMobileLogoutButton />
    </Box>
  );
}
