import { endOfDay, subMonths } from 'date-fns';
import { Atom, Getter } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { fetchTimeSeries } from 'api/rest/charts/fetchTimeSeries';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { ChartConfig, Filter } from 'data/charts/models/ChartsApiRequest';
import { TimeSeriesResponse } from 'data/charts/models/ChartsApiResponse';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { getFilterAtoms } from 'utils/atoms/filter';
import { mobileAtom } from 'utils/atoms/mobileAtom';
import { getSplitAtoms } from 'utils/atoms/split';
import { ChartSplitOption } from 'utils/types';

import { FormattedDatePickerAtomValue } from './dateRangeAtom';

export type TimeSeriesAtomValue = {
  data: TimeSeriesResponse;
  aggPeriod: AggregationPeriod;
};

type TimeSeriesAtomConfig<Split extends ChartSplitOption> = {
  initialFilters?: Filter[];
  defaultSplit?: Split;
  timeRangeAtom?: Atom<FormattedDatePickerAtomValue>;
  shortScale?: boolean;
};

function getDefaultDateValues() {
  return {
    start: endOfDay(subMonths(new Date(), 6)).toISOString(),
    end: endOfDay(new Date()).toISOString(),
    granularity: 'MONTH' as const,
  };
}

function getTimeRange(
  shortScale: boolean,
  isMobile: boolean,
  timeRangeValues?: FormattedDatePickerAtomValue,
) {
  if (!timeRangeValues) return getDefaultDateValues();

  const { end, granularity } = timeRangeValues;

  if (granularity === 'MONTH') {
    return {
      end,
      granularity,
      start: timeRangeValues.start6thPeriodBeforeInclusive,
    };
  }
  if (granularity === 'WEEK') {
    if (isMobile) {
      return {
        end,
        granularity,
        start: timeRangeValues.start4thPeriodBeforeInclusive,
      };
    }
    if (shortScale) {
      return {
        end,
        granularity,
        start: timeRangeValues.start6thPeriodBeforeInclusive,
      };
    }
    return {
      end,
      granularity,
      start: timeRangeValues.start8thPeriodBeforeInclusive,
    };
  }
  if (granularity === 'DAY') {
    if (isMobile) {
      return {
        end,
        granularity,
        start: timeRangeValues.start7thPeriodBeforeInclusive,
      };
    }
    return {
      end,
      granularity,
      start: timeRangeValues.start30thPeriodBeforeInclusive,
    };
  }
  return getDefaultDateValues();
}

export function getTimeSeriesAtoms<Split extends ChartSplitOption>({
  initialFilters = [],
  defaultSplit,
  timeRangeAtom,
  shortScale = false,
}: TimeSeriesAtomConfig<Split> = {}) {
  async function fetcher(
    blockId: string,
    get: Getter,
  ): Promise<TimeSeriesAtomValue> {
    const split = get(splitAtoms.splitValueAtom);
    const filters = get(filterAtoms.appliedFiltersAtom);

    const isMobile = get(mobileAtom);
    const timeRangeParams = timeRangeAtom ? get(timeRangeAtom) : undefined;
    const timeRange = getTimeRange(shortScale, isMobile, timeRangeParams);

    const chartConfig: ChartConfig<string> = {
      timeRange,
      // TODO: fix this type assertion
      filters: filters as Filter[],
    };

    if (split) {
      chartConfig.split = { top: 5, column: split.id };
    }

    const result = await fetchTimeSeries<string>({
      blockId,
      chartConfig,
    });

    return {
      data: result.data,
      aggPeriod: timeRange.granularity,
    };
  }

  const dataAtom = getDataAtom<TimeSeriesAtomValue>({
    data: {},
    aggPeriod: 'WEEK',
  });

  const fetchingAtom = getDataFetchingAtom<TimeSeriesAtomValue, string>(
    dataAtom,
    fetcher,
  );

  const splitAtoms = getSplitAtoms<Split | undefined>(
    defaultSplit,
    fetchingAtom,
  );
  const filterAtoms = getFilterAtoms(initialFilters, fetchingAtom);
  const chartLegendAtom = atomWithReset<string[]>([]);

  return {
    dataAtom,
    fetchingAtom,
    splitAtoms,
    filterAtoms,
    chartLegendAtom,
  };
}
