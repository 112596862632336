import {
  CheckDataSystemStatusMutationVariables,
  CheckDataSystemStatusMutation,
  CheckDataSystemStatusDocument,
} from 'api/graphql/backendAPI/queries/dataSystem/checkDataSystemStatus.generated';
import { client } from 'api/graphql/client';
import { DataSystemStatusPayload } from 'data/dataSystem/models';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const dataSystemStatusAtom = getDataAtom<DataSystemStatusPayload>({
  dataSystemStatus: null,
});

async function fetchDataSystemStatus(): Promise<DataSystemStatusPayload> {
  const clientQuery = client.mutation<
    CheckDataSystemStatusMutation,
    CheckDataSystemStatusMutationVariables
  >(CheckDataSystemStatusDocument, {});

  try {
    const response = await clientQuery.toPromise();
    const dataSystemStatus = response.data?.checkDataSystemStatus;
    if (dataSystemStatus) return { dataSystemStatus };
    throw new Error('Could not fetch Data System Status');
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Could not fetch Data System Status');
  }
}

export const fetchDataSystemStatusAtom = getDataFetchingAtom<
  DataSystemStatusPayload,
  CheckDataSystemStatusMutationVariables
>(dataSystemStatusAtom, fetchDataSystemStatus);
