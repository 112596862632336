import * as Sentry from '@sentry/browser';

import { getLocalStorageSessionId } from 'data/auth/amplify';
import { ADMIN_LOGIN_STORAGE_KEY } from 'utils/analytics/constants';
import { AnalyticsIdentifier } from 'utils/analytics/types/AnalyticsIdentifier';

async function track() {
  return;
}

async function identify({ user, company }: AnalyticsIdentifier) {
  Sentry.setTag('user_id', user.id);
  Sentry.setTag('user_name', `${user.firstName} ${user.lastName}`);
  Sentry.setTag('user_email', user.email);
  Sentry.setTag('company', company);
  Sentry.setTag('user_session', getLocalStorageSessionId().substring(0, 50));
  if (localStorage.getItem(ADMIN_LOGIN_STORAGE_KEY) === 'true') {
    Sentry.setTag('admin_session', 'true');
  }
}

async function reset() {
  return;
}

export function initSentryProvider() {
  return {
    track,
    identify,
    reset,
  };
}
