import { BadgeProps } from '@chakra-ui/react';

import { Badge, Box, BoxProps } from 'components/uikit/reexport';

interface BadgeWithMiddleDotParams extends BadgeProps {
  caption: string;
  middleDotColor?: BoxProps['color'];
}

export function BadgeWithMiddleDot({
  caption,
  middleDotColor = 'currentColor',
  ...props
}: BadgeWithMiddleDotParams) {
  return (
    <Badge {...props} userSelect='none'>
      <Box
        width='6px'
        height='6px'
        borderRadius='50%'
        backgroundColor={middleDotColor}
        mr='5px'
        as='span'
      />
      {caption}
    </Badge>
  );
}
