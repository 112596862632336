import gql from 'graphql-tag';
import * as Urql from 'urql';

import * as Types from '../../../generatedTypes';
import {
  BusinessModel,
  CompanySize,
  RoleInCompany,
} from '../../../generatedTypes';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrCreateUserProfilesMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOrCreateUserProfilesMutation = {
  __typename?: 'Mutation';
  getOrCreateUserProfiles: {
    __typename?: 'UserProfiles';
    userCreated: boolean;
    maintenance: boolean;
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      emailVerified: boolean;
      hasOnboarded: boolean;
      avatar?: string | null;
      tourStep: Types.TourStep;
    };
    profiles: Array<{
      __typename?: 'Profile';
      id: string;
      roleInCompany: RoleInCompany | null;
      company: {
        __typename?: 'ProfileCompany';
        id: string;
        name: string;
        namespace: string;
        companyCreated: boolean;
        size: CompanySize | null;
        businessModel: BusinessModel | null;
      };
    }>;
  };
};

export const GetOrCreateUserProfilesDocument = gql`
  mutation getOrCreateUserProfiles {
    getOrCreateUserProfiles {
      user {
        id
        firstName
        lastName
        email
        emailVerified
        hasOnboarded
        avatar
        tourStep
      }
      profiles {
        id
        roleInCompany
        company {
          id
          name
          namespace
          companyCreated
          size
          businessModel
        }
      }
      userCreated
      maintenance
    }
  }
`;

export function useGetOrCreateUserProfilesMutation() {
  return Urql.useMutation<
    GetOrCreateUserProfilesMutation,
    GetOrCreateUserProfilesMutationVariables
  >(GetOrCreateUserProfilesDocument);
}
