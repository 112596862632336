import { Getter } from 'jotai';

import { fetchBlocksBySection } from 'api/rest/charts/fetchBlocksBySection';
import { CustomerBlock } from 'data/blocks/models/Block';
import { BlockSection } from 'data/blocks/models/BlockSection';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';
import { parseItems } from 'utils/parser/parseResponse';

export async function getCustomerBlocks(
  namespace: string,
  get: Getter,
): Promise<{ blocks: CustomerBlock[] }> {
  const currentBlocks = get(customerBlocksAtom);
  if (currentBlocks.fetchStatus === 'SUCCESS') return currentBlocks;

  const { blocks } = await fetchBlocksBySection(
    namespace,
    BlockSection.enum.CUSTOMER,
  );

  const customerBlocks = parseItems(CustomerBlock, blocks);

  return { blocks: customerBlocks };
}

export const customerBlocksAtom = getDataAtom<{
  blocks: CustomerBlock[];
}>({
  blocks: [],
});

export const fetchCustomerBlocksAtom = getDataRefreshingAtom(
  customerBlocksAtom,
  getCustomerBlocks,
);
