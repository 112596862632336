import { atom, useAtomValue } from 'jotai';
import { ZodType, ZodTypeDef } from 'zod';

import { fetchCustomerCompanyById } from 'api/rest/customer/companies/fetchCustomerCompanyById';
import { createCustomerUserNote } from 'api/rest/customer/userNotes/createCustomerUserNote';
import { deleteCustomerUserNote } from 'api/rest/customer/userNotes/deleteCustomerUserNote';
import { fetchCustomerUserNotes } from 'api/rest/customer/userNotes/fetchCustomerUserNotes';
import { fetchCustomerUserByDepartmentId } from 'api/rest/customer/users/fetchCustomerUserByDepartmentId';
import { removeCustomerTagFromUser } from 'api/rest/customer/users/removeCustomerTagFromUser';
import { updateCustomerUserSentiment } from 'api/rest/customer/users/updateCustomerUserSentiment';
import { updateCustomerUserTags } from 'api/rest/customer/users/updateCustomerUserTags';
import { Department } from 'data/blocks/models/Block';
import {
  RevenueBreakdownRow,
  SignalsByEngagementRow,
} from 'data/charts/atoms/product/users/breakdowns';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { CustomerUser } from 'data/customer/CustomerUser';
import {
  CompanyData,
  getCustomersChartAtoms,
  RevenueStatsData,
  UserData,
} from 'data/healthReports/atoms/customerChartAtom';
import {
  customerDrawerFormattedDateRangeAtom,
  customerDrawerMonthlyFormattedDateRangeAtom,
} from 'data/healthReports/atoms/customerDrawerFormattedDateRangeAtom';
import { CustomerCompanyAtomType } from 'data/healthReports/models/user/CustomerCompanyAtomType';
import {
  CustomerUserAtomsType,
  CustomerUsersAtomsType,
} from 'data/healthReports/models/user/CustomerUserAtomsType';
import { CustomerUserAtomType } from 'data/healthReports/models/user/CustomerUserAtomType';
import { CustomerUserNotesAtomType } from 'data/healthReports/models/user/CustomerUserNotesAtomType';
import {
  DepartmentCustomerUserAtomsType,
  DepartmentCustomerUserAtomType,
  DepartmentCustomerUsersAtomsType,
} from 'data/healthReports/models/user/DepartmentCustomerUserAtomType';
import {
  QuartileActiveDaysDataBlock,
  QuartileCustomerLifespanDataBlock,
  QuartileProductEngagementDataBlock,
  QuartileRevenueDataBlock,
} from 'data/healthReports/models/user/QuartileDataBlocks';
import { buildClickableCardBreakdownAtom } from 'utils/atoms/buildClickableCardBreakdownAtom';
import { getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataFetchingAtom,
  getFormUpdatingAtom,
} from 'utils/atoms/dataFetchingAtom';
import { getRecordChartAtoms } from 'utils/atoms/recordBlockAtoms';
import { getSummaryAtom } from 'utils/atoms/summaryCardAtom';
import { getTimeSeriesAtoms } from 'utils/atoms/timeSeriesAtom';

const getProductFilterConfig = (departmentIds: string[]): Filter[] => {
  return [{ field: 'user_id', operator: 'in', value: departmentIds }];
};

const getFinanceFilterConfig = (departmentIds: string[]): Filter[] => {
  return [{ field: 'customer', operator: 'in', value: departmentIds }];
};

const departmentCustomerUserAtoms: DepartmentCustomerUsersAtomsType = {};

const customerUserAtoms = atom<CustomerUsersAtomsType>({});

export const createDepartmentCustomerUserAtoms = (
  department: Department,
  departmentId: string,
) => {
  if (departmentCustomerUserAtoms[departmentId]) {
    return departmentCustomerUserAtoms[
      departmentId
    ] as DepartmentCustomerUserAtomsType;
  }

  const dataAtom = getDataAtom<DepartmentCustomerUserAtomType>({});

  const fetchingAtom = getDataFetchingAtom(dataAtom, async (_, get, set) => {
    const userRemote = await fetchCustomerUserByDepartmentId({
      department,
      departmentId,
    });

    if (!get(customerUserAtoms)[userRemote.user.id]) {
      const atoms = createCustomerUserAtoms(userRemote.user);

      set(customerUserAtoms, (prev) => ({
        ...prev,
        [userRemote.user.id]: atoms,
      }));
    }

    return userRemote;
  });

  return (departmentCustomerUserAtoms[departmentId] = {
    dataAtom,
    fetchingAtom,
  });
};

export const createCustomerUserAtoms = (
  user: CustomerUser,
): CustomerUserAtomsType => {
  const dataAtom = getDataAtom<CustomerUserAtomType>({ user }, 'SUCCESS');

  const companyDataAtom = getDataAtom<CustomerCompanyAtomType>({
    company: undefined,
  });

  const notesDataAtom = getDataAtom<CustomerUserNotesAtomType>({
    userId: user.id,
    notes: [],
  });

  const fetchingAtom = getDataFetchingAtom(
    dataAtom,
    fetchCustomerUserByDepartmentId,
  );

  const companyFetchingAtom = getDataFetchingAtom(
    companyDataAtom,
    fetchCustomerCompanyById,
  );

  const userDataAtoms = getRecordChartAtoms({
    parser: UserData,
    config: { customerUserId: user.id },
  });

  const companyDataAtoms = getRecordChartAtoms({
    parser: CompanyData,
    config: { customerCompanyId: user.customerCompanyId ?? undefined },
  });

  const sentimentUpdatingAtom = getFormUpdatingAtom(
    updateCustomerUserSentiment,
    dataAtom,
  );

  const tagsUpdatingAtom = getFormUpdatingAtom(
    updateCustomerUserTags,
    dataAtom,
  );

  const tagRemovingAtom = getFormUpdatingAtom(
    removeCustomerTagFromUser,
    dataAtom,
  );

  const notesFetchingAtom = getDataFetchingAtom(
    notesDataAtom,
    fetchCustomerUserNotes,
  );

  const noteCreatingAtom = getFormUpdatingAtom(
    createCustomerUserNote,
    dataAtom,
  );

  const noteDeletingAtom = getFormUpdatingAtom(
    deleteCustomerUserNote,
    dataAtom,
  );

  const {
    dataAtom: quartileRevenueAtom,
    fetchingAtom: quartileRevenueFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileRevenueDataBlock,
    filters: getFinanceFilterConfig(user.financeIds),
  });

  const {
    dataAtom: quartileActiveDaysAtom,
    fetchingAtom: quartileActiveDaysFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileActiveDaysDataBlock,
    filters: getProductFilterConfig(user.productIds),
  });

  const {
    dataAtom: quartileProductEngagementAtom,
    fetchingAtom: quartileProductEngagementFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileProductEngagementDataBlock,
    filters: getProductFilterConfig(user.productIds),
  });

  const {
    dataAtom: quartileCustomerLifespanAtom,
    fetchingAtom: quartileCustomerLifespanFetchingAtom,
  } = getCustomersChartAtoms({
    blockResponseParser: QuartileCustomerLifespanDataBlock,
    filters: getProductFilterConfig(user.productIds),
  });

  const revenueChartAtoms = getTimeSeriesAtoms({
    timeRangeAtom: customerDrawerMonthlyFormattedDateRangeAtom,
    initialFilters: getFinanceFilterConfig(user.financeIds),
  });

  const revenueStatsAtoms = getRecordChartAtoms({
    parser: RevenueStatsData,
    config: { filters: getFinanceFilterConfig(user.financeIds) },
  });

  const revenueBreakdownAtoms = buildClickableCardBreakdownAtom<
    RevenueBreakdownRow,
    RevenueBreakdownRow,
    ZodType<RevenueBreakdownRow, ZodTypeDef, RevenueBreakdownRow>
  >(
    RevenueBreakdownRow,
    customerDrawerFormattedDateRangeAtom,
    getFinanceFilterConfig(user.financeIds),
  );

  const activeUsersChartAtoms = getTimeSeriesAtoms({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    initialFilters: getProductFilterConfig(user.productIds),
  });

  const thisWeekActiveUsersSummaryAtoms = getSummaryAtom({
    timeRangeAtom: customerDrawerFormattedDateRangeAtom,
    filters: getProductFilterConfig(user.productIds),
  });

  const thisMonthActiveUsersSummaryAtoms = getSummaryAtom({
    timeRangeAtom: customerDrawerMonthlyFormattedDateRangeAtom,
    filters: getProductFilterConfig(user.productIds),
  });

  const signalsByEngagementAtoms = buildClickableCardBreakdownAtom<
    SignalsByEngagementRow,
    SignalsByEngagementRow,
    ZodType<SignalsByEngagementRow, ZodTypeDef, SignalsByEngagementRow>
  >(
    SignalsByEngagementRow,
    customerDrawerFormattedDateRangeAtom,
    getProductFilterConfig(user.productIds),
  );

  return {
    dataAtom,
    companyDataAtom,
    notesDataAtom,
    fetchingAtom,
    companyFetchingAtom,
    sentimentUpdatingAtom,
    tagsUpdatingAtom,
    tagRemovingAtom,
    notesFetchingAtom,
    noteCreatingAtom,
    noteDeletingAtom,

    userDataAtoms,
    companyDataAtoms,

    quartileRevenueAtom,
    quartileRevenueFetchingAtom,
    quartileActiveDaysAtom,
    quartileActiveDaysFetchingAtom,
    quartileProductEngagementAtom,
    quartileProductEngagementFetchingAtom,
    quartileCustomerLifespanAtom,
    quartileCustomerLifespanFetchingAtom,

    revenueChartAtoms,
    revenueStatsAtoms,

    revenueBreakdownAtoms,

    activeUsersChartAtoms,
    thisWeekActiveUsersSummaryAtoms,
    thisMonthActiveUsersSummaryAtoms,

    signalsByEngagementAtoms,
  };
};

export const useCustomerUserAtoms = (userId: string) => {
  const atoms = useAtomValue(customerUserAtoms)[userId];

  if (!atoms) {
    throw new Error('Customer user atoms not found');
  }

  return atoms;
};
