import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';
import { CustomerBlock } from 'data/blocks/models/Block';
import { DEMO_COMPANY_NAME } from 'data/constants/demo';
import { useCurrentCompanyNamespace } from 'data/user/hooks/useCurrentCompany';

export const useCustomerBreakdownBlock = (
  blockName: CustomerBlock['uniqueName'],
) => {
  const { blocks } = useCustomerBreakdownBlocks();
  const companyNamespace = useCurrentCompanyNamespace();

  const block = blocks.find((block) => block.uniqueName === blockName) as
    | CustomerBlock
    | undefined;

  return {
    block: block,
    isDemo:
      block?.company == DEMO_COMPANY_NAME &&
      companyNamespace != DEMO_COMPANY_NAME,
  };
};
