import { useTranslation } from 'react-i18next';

import { Badge } from 'components/uikit';

export function ComingSoonBadge() {
  const { t } = useTranslation('common');
  return (
    <Badge
      fontSize='xxxs'
      fontWeight='medium'
      textColor='grey.primaryText'
      bgColor='grey.offWhite'
      ml='9px'
    >
      {t('sharing_coming_soon')}
    </Badge>
  );
}
