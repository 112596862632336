import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { useCustomerRevenueChartBlocks } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/useCustomerRevenueChartBlocks';
import { useCustomerCompanyAtoms } from 'data/healthReports/atoms/customerCompanyAtoms';
import { isInitial } from 'utils/types';

export function useRevenueChartData(customerId: string) {
  const { statsBlock, chartBlock } = useCustomerRevenueChartBlocks();
  const { revenueChartAtoms, revenueStatsAtoms } =
    useCustomerCompanyAtoms(customerId);

  const [
    {
      fetchStatus: chartFetchStatus,
      aggPeriod: chartAggPeriod,
      data: chartData,
    },
    fetchChart,
  ] = useAtom(revenueChartAtoms.fetchingAtom);
  const [{ fetchStatus: statsFetchStatus, data: statsData }, fetchStats] =
    useAtom(revenueStatsAtoms.fetchingAtom);

  useEffect(() => {
    if (isInitial(chartFetchStatus) && chartBlock) {
      fetchChart(chartBlock.id);
    }
    if (isInitial(statsFetchStatus) && statsBlock) {
      fetchStats(statsBlock.id);
    }
  }, [
    chartBlock,
    chartFetchStatus,
    fetchChart,
    fetchStats,
    statsBlock,
    statsFetchStatus,
  ]);

  return {
    chart: {
      data: chartData,
      aggPeriod: chartAggPeriod,
      fetchStatus: chartFetchStatus,
    },
    stats: {
      data: statsData,
      fetchStatus: statsFetchStatus,
    },
  };
}
