import { authorisedFetch } from 'api/rest/authorisedFetch';
import { USERS_API_URL } from 'data/constants';
import { UsedProvidersByDepartment } from 'data/user/models/onboarding';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function fetchUsedProviders(): Promise<UsedProvidersByDepartment> {
  const result = await authorisedFetch(`${USERS_API_URL}/providers`, {
    method: 'GET',
  });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch used providers: ${result.status}`);
  }

  return parseItemStrict(UsedProvidersByDepartment, data, false);
}
