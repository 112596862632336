import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
  Flex,
} from '@chakra-ui/react';

interface SpinnerProps extends ChakraSpinnerProps {
  fullScreen?: boolean;
  fillContainer?: boolean;
}

export function Spinner({
  fullScreen = false,
  fillContainer = false,
  ...props
}: SpinnerProps) {
  if (fullScreen) {
    return (
      <Flex w='100vw' h='100vh' justify='center' align='center' {...props}>
        <ChakraSpinner size='xl' />
      </Flex>
    );
  }
  if (fillContainer) {
    return (
      <Flex w='100%' h='100%' justify='center' align='center' {...props}>
        <ChakraSpinner size='lg' />
      </Flex>
    );
  }
  return <ChakraSpinner {...props} />;
}
