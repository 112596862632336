import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownByPowerUsersRow = z.object({
  userId: z.string(),
  customerId: z.string(),
  userName: z.string(),
  company: z.string(),
  accountType: z.string().nullable(),
  accountState: z.string().nullable(),
  companyName: z.string().nullable(),
  powerUserEventsCurrentPeriod: z.coerce.number(),
  powerUserEventsDelta: z.coerce.number(),
  joinDate: z.coerce.date(),
  joinMonth: z.string(),
  country: z.string(),
  tags: z.string().array(),
});

export type FullBreakdownByPowerUsersRow = z.infer<
  typeof FullBreakdownByPowerUsersRow
>;

export const breakdownByPowerUsersAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByPowerUsersRow,
  [
    {
      column: 'joinDate',
      direction: 'DESC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);

export const breakdownByToPowerUserAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByPowerUsersRow,
  [
    {
      column: 'powerUserEventsCurrentPeriod',
      direction: 'DESC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);
