import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { InsightAction } from 'data/ai/models';
import { parseItemStrict } from 'utils/parser/parseResponse';

import { PARROT_API_URL } from './resourcesUrls';

export async function getInsightActionCaches(insightIds: string[]) {
  const result = await authorisedFetch(`${PARROT_API_URL}/insight/actions`, {
    method: 'POST',
    body: JSON.stringify({ ids: insightIds }),
  });

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(
      `Failed to get parrot follow up questions: ${responseBody}`,
    );
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(
    z.record(z.string().uuid(), InsightAction.array()),
    data,
    false,
  );
}
