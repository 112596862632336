import { createIcon } from '@chakra-ui/react';

export const ShareIcon = createIcon({
  displayName: 'ShareIcon',
  viewBox: '0 0 14 16',
  path: (
    <path
      d='M1.6665 7.99992V13.3333C1.6665 13.6869 1.80698 14.026 2.05703 14.2761C2.30708 14.5261 2.64622 14.6666 2.99984 14.6666H10.9998C11.3535 14.6666 11.6926 14.5261 11.9426 14.2761C12.1927 14.026 12.3332 13.6869 12.3332 13.3333V7.99992M9.6665 3.99992L6.99984 1.33325M6.99984 1.33325L4.33317 3.99992M6.99984 1.33325V9.99992'
      fill='none'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
