import { RefObject, useEffect, useMemo } from 'react';

import {
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { CompanyTagsModal } from 'components/CustomerDrawer/CompanyDrawer/CompanyTagsModal';
import { DrawerHeader } from 'components/CustomerDrawer/CompanyDrawer/DrawerHeader';
import { DrawerTabs } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs';
import { useCustomerTags } from 'components/CustomerDrawer/hooks/useCustomerTags';
import {
  useCustomerCompany,
  useFetchCustomerCompanyData,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { hideScrollbar } from 'styles/hideScrollbar';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

type CustomerCompanyDrawerProps = {
  customerCompanyId: string;
  isOpen: boolean;
  onClose: () => void;
  finalFocusRef: RefObject<HTMLDivElement>;
};

export function CustomerCompanyDrawer({
  customerCompanyId,
  isOpen,
  onClose,
  finalFocusRef,
}: CustomerCompanyDrawerProps) {
  const trackEvent = useUserEvent();
  const { company } = useCustomerCompany(customerCompanyId);
  const {
    isOpen: isTagsOpen,
    onOpen: onTagsOpen,
    onClose: onTagsClose,
  } = useDisclosure();
  const { tags: allTags } = useCustomerTags();
  useFetchCustomerCompanyData(customerCompanyId);

  const tags = useMemo(
    () => allTags.filter((tag) => company.tags.includes(tag.id)),
    [allTags, company.tags],
  );

  useEffect(() => {
    if (isOpen) {
      trackEvent(CustomerDrawerAnalyticsEvents.COMPANY_OPENED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      size={'lg'}
    >
      <DrawerOverlay />
      <DrawerContent
        overflow={'scroll'}
        css={hideScrollbar}
        background={'grey.offWhite'}
      >
        <DrawerHeader
          customerCompanyId={company.id}
          onClick={onClose}
          tags={tags}
          isTagsOpen={isTagsOpen}
          onTagsOpen={() => {
            onTagsOpen();
            trackEvent(CustomerDrawerAnalyticsEvents.TAGS_MANAGE, {
              customerType: 'company',
            });
          }}
        />

        <Collapse in={isTagsOpen} endingHeight={'100%'}>
          <CompanyTagsModal
            customerCompanyId={company.id}
            onTagsClose={onTagsClose}
          />
        </Collapse>

        <Collapse in={!isTagsOpen} endingHeight={'100%'}>
          <DrawerTabs customerCompanyId={company.id} onDrawerClose={onClose} />
        </Collapse>
      </DrawerContent>
    </Drawer>
  );
}
