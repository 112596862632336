import { createIcon } from '@chakra-ui/react';

export const EnvelopeIcon = createIcon({
  displayName: 'EnvelopeIcon',
  viewBox: '0 0 20 16',
  path: (
    <>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.667'
        fill='transparent'
        d='M18.334 3c0-.917-.75-1.667-1.667-1.667H3.334c-.917 0-1.667.75-1.667 1.667m16.667 0v10c0 .917-.75 1.667-1.667 1.667H3.334c-.917 0-1.667-.75-1.667-1.667V3m16.667 0L10 8.833 1.667 3'
      />
    </>
  ),
});
