import { z } from 'zod';

import { formattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownsBySalesOwnersRow = z.object({
  ownerName: z.string(),
  totalPipeline: z.coerce.number(),
  totalSales: z.coerce.number(),
  winRate: z.coerce.number(),
  averageSalesCycle: z.coerce.number(),
});

export type FullBreakdownsBySalesOwnersRow = z.infer<
  typeof FullBreakdownsBySalesOwnersRow
>;

export const breakdownByOwnerSalesAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsBySalesOwnersRow,
  [
    {
      column: 'totalSales',
      direction: 'DESC',
    },
  ],
  formattedDatePickerAtom,
);

export const breakdownByWinRateAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsBySalesOwnersRow,
  [
    {
      column: 'winRate',
      direction: 'DESC',
    },
  ],
  formattedDatePickerAtom,
);

export const breakdownByTimeToCloseAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsBySalesOwnersRow,
  [
    {
      column: 'averageSalesCycle',
      direction: 'ASC',
    },
  ],
  formattedDatePickerAtom,
);
