import { some } from 'lodash';

export class BrowserInfo {
  private static get platform() {
    // navigator is not available when rendered from the server.
    return global.navigator?.userAgent.toLowerCase() || '';
  }
  static get isIOS() {
    return some(['iphone', 'ipad', 'ipod'], (os) =>
      BrowserInfo.platform.includes(os),
    );
  }
  static get isOSX() {
    return /mac/.test(BrowserInfo.platform);
  }
  static get isApple() {
    return BrowserInfo.isIOS || BrowserInfo.isOSX;
  }
}
