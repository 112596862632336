import { chakra, Box, Skeleton, Flex } from 'components/uikit';
import { LineChartSkeleton } from 'components/uikit/icons/LineChartSkeleton';
import { render } from 'utils/helpers';
import { useIsMobile } from 'utils/hooks/screenBreakpoints';

export enum ChartSkeletonType {
  LINE = 'line',
  BAR = 'bar',
}

interface ChartSkeletonProps {
  isLoading?: boolean;
  chartType?: ChartSkeletonType;
}

function BarsSkeleton({ isLoading }: { isLoading: boolean }) {
  const isMobile = useIsMobile();
  const nBars = isMobile ? 6 : 10;

  const calculateBarHeight = (index: number) => {
    if (isMobile) {
      return `${(index + 1) * (index + 1) * 2.5 + 1}%`;
    }
    return `${(index + 1) * (index + 1) * 0.9 + 1}%`;
  };

  return (
    <>
      <Box h='100%' w='100%' display='flex' justifyContent='space-around'>
        {render(Skeleton, nBars).times((index) => ({
          key: `bar-skeleton-${index}`,
          startColor: 'grey.offWhite',
          endColor: isLoading ? 'grey.background' : 'grey.offWhite',
          height: calculateBarHeight(index),
          width: '45px',
          marginTop: 'auto',
        }))}
      </Box>
      <LabelsSkeleton n={nBars} hasError={!isLoading} />
    </>
  );
}

function LinesSkeleton({ isLoading }: { isLoading: boolean }) {
  const isMobile = useIsMobile();

  return (
    <>
      <Box h='100%' w='100%' display='flex' justifyContent='space-around'>
        <LineChartSkeleton height='100%' width='100%' />
      </Box>
      <LabelsSkeleton n={isMobile ? 6 : 7} hasError={!isLoading} />
    </>
  );
}

function LabelsSkeleton({ n, hasError }: { n: number; hasError: boolean }) {
  return (
    <Flex
      direction='row'
      justifyContent='space-around'
      marginTop='8px'
      marginBottom='20px'
    >
      {[...Array(n).keys()].map((index) => {
        return (
          <Skeleton
            key={`label-skeleton-${index}`}
            height='12px'
            width='32px'
            hasError={hasError}
          />
        );
      })}
    </Flex>
  );
}

export function ChartSkeleton({
  isLoading = false,
  chartType = ChartSkeletonType.BAR,
}: ChartSkeletonProps) {
  const lines = Array.from(Array(6)).map((_, index) => {
    return <chakra.div key={index} bg={'grey.offWhite'} w='100%' h='1px' />;
  });

  return (
    <Box w='100%' h='100%' position='relative'>
      <Box
        position='absolute'
        top='0'
        left='0'
        h='100%'
        w='100%'
        display='flex'
        flexDir='column'
        justifyContent='space-between'
      >
        {lines}
      </Box>

      {chartType === ChartSkeletonType.BAR ? (
        <BarsSkeleton isLoading={isLoading} />
      ) : (
        <LinesSkeleton isLoading={isLoading} />
      )}
    </Box>
  );
}
