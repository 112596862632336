import { authorisedFetch } from 'api/rest/authorisedFetch';
import { SHARING_LINK_PUBLIC_URL } from 'api/rest/sharing/resourceUrls';
import { SharingLink } from 'data/sharingLink/models/sharingLink';
import { parseItem } from 'utils/parser/parseResponse';
import { RequestStatus } from 'utils/requests/types';

export async function createSharingLinkRequest(
  blockId: string,
): Promise<RequestStatus<SharingLink>> {
  let response: Response;

  try {
    response = await authorisedFetch(SHARING_LINK_PUBLIC_URL, {
      method: 'PUT',
      body: JSON.stringify({ blockId }),
    });
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : `Error creating sharing link: ${JSON.stringify(error)}`;
    return {
      fetchStatus: 'ERROR',
      errorMessage,
    };
  }

  if (!response.ok) {
    return {
      fetchStatus: 'ERROR',
      errorMessage: `Error creating sharing link: ${response.status} ${response.statusText}`,
    };
  }

  const sharingLink = parseItem(SharingLink, await response.json());

  if (sharingLink === null) {
    return {
      fetchStatus: 'ERROR',
      errorMessage: `Failed to parse sharing link: ${response.status} ${response.statusText}`,
    };
  }

  return {
    fetchStatus: 'SUCCESS',
    response: sharingLink,
  };
}
