import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Box, Heading, Text, Textarea, VStack } from 'components/uikit';
import { EditableTextInput } from 'components/uikit/form/inputs/EditableTextInput';
import { TagIcon } from 'components/uikit/icons/Tag';
import { draftNewTagAtom } from 'data/healthReports/atoms/draftNewTagAtom';

export function CreateTagForm({ tKeyPrefix }: { tKeyPrefix: string }) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: tKeyPrefix,
  });
  const [newTag, setNewTag] = useAtom(draftNewTagAtom);

  return (
    <VStack
      p={'16px'}
      alignItems={'left'}
      spacing={'16px'}
      height={'100%'}
      overflowY={'scroll'}
    >
      <Heading
        as={'h5'}
        fontWeight={'semibold'}
        fontSize={'18px'}
        lineHeight={1.4}
      >
        {t('create_tag_heading')}
      </Heading>

      <Box>
        <Text fontSize={'14px'} lineHeight={1.4} pb={'8px'}>
          {t('create_tag_title_input_label')}
        </Text>
        <EditableTextInput
          border={'1px solid'}
          borderColor={'grey.border'}
          borderRadius={'4px'}
          type='text'
          placeholder={t('create_tag_title_input_placeholder')}
          icon={<TagIcon boxSize='16px' stroke='grey.tertiaryText' />}
          value={newTag.title}
          onChange={(e) => {
            setNewTag({
              title: e.currentTarget.value,
              description: newTag.description,
            });
          }}
        />
      </Box>

      <Box>
        <Text fontSize={'14px'} lineHeight={1.4} pb={'8px'}>
          {t('create_tag_description_input_label')}
        </Text>
        <Textarea
          name={'tagDescription'}
          placeholder={t('create_tag_description_input_placeholder')}
          value={newTag.description}
          onChange={(e) => {
            setNewTag({
              title: newTag.title,
              description: e.currentTarget.value,
            });
          }}
        />
      </Box>
    </VStack>
  );
}
