import { ReactNode, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  useClipboard,
} from '@chakra-ui/react';

import { CheckCircleIcon2, CopyIcon } from 'components/uikit/icons';
import { Skeleton } from 'components/uikit/Skeleton';

import { BoxProps, Text } from '../../reexport';

const getIcon = (hasCopied: boolean) => {
  if (hasCopied) return <CheckCircleIcon2 />;

  return <CopyIcon />;
};

const getInputVariant = (error: boolean, hasCopied: boolean) => {
  if (error) return 'error';
  if (hasCopied) return 'success';
  return 'outline';
};

interface CopyFieldProps extends BoxProps {
  name?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  errorMessage?: ReactNode;
  isLoading?: boolean;
}

export function CopyField({
  name,
  id,
  label,
  placeholder,
  value,
  errorMessage,
  isLoading = false,
  ...props
}: CopyFieldProps) {
  const { onCopy, hasCopied, setValue } = useClipboard(
    String(value ?? ''),
    3000,
  );
  const rightIcon = getIcon(hasCopied);
  const hasError = !!errorMessage;

  useEffect(() => {
    setValue(String(value ?? ''));
  }, [setValue, value]);

  return (
    <FormControl
      w='100%'
      display='flex'
      flexDir='column'
      rowGap='6px'
      position='relative'
      fontWeight='medium'
      {...props}
    >
      <FormLabel
        color='grey.tertiaryText'
        fontSize='sm'
        fontWeight='medium'
        htmlFor={id}
        lineHeight='20px'
        m='0 0 0 2px'
      >
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading} variant='input'>
        <InputGroup
          onClick={onCopy}
          variant={getInputVariant(hasError, hasCopied)}
        >
          <ChakraInput
            id={id}
            name={name}
            value={value}
            type='text'
            placeholder={placeholder}
            backgroundColor='grey.white'
            isDisabled
            cursor={hasError ? 'default !important' : 'pointer !important'}
            opacity='1 !important'
            textColor='darkText !important'
            fontWeight='medium'
          />

          {errorMessage ? null : (
            <InputRightElement
              borderTopRightRadius='5px'
              borderBottomRightRadius='5px'
              backgroundColor={
                hasCopied ? 'primary.background' : 'secondary.button'
              }
              height='calc(100% - 2px)'
              mt='1px'
              mr='1px'
              stroke={hasCopied ? 'primary.button' : 'grey.white'}
              cursor='pointer'
            >
              {rightIcon}
            </InputRightElement>
          )}
        </InputGroup>
      </Skeleton>

      <Text
        fontSize='sm'
        color='error.button'
        fontWeight='400'
        position='absolute'
        left='2px'
        bottom='-4px'
        transform='translateY(100%)'
      >
        {errorMessage}
      </Text>
    </FormControl>
  );
}
