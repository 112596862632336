import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { fetchIdTokenAtom } from '../atoms/idTokenAtom';
import { useIsLoggingOut } from './useIsLoggingOut';

export function useFetchIdToken() {
  const [{ idToken }, fetchIdToken] = useAtom(fetchIdTokenAtom);
  const isLoggingOut = useIsLoggingOut();

  useEffect(() => {
    if (idToken || isLoggingOut) return;
    fetchIdToken();
  }, [idToken, fetchIdToken, isLoggingOut]);
}
