import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { Department } from 'data/blocks/models/Block';
import { createDepartmentCustomerUserAtoms } from 'data/healthReports/atoms/customerUserAtoms';

export const useFetchCustomerUser = (
  department: Department,
  departmentId: string,
) => {
  const { dataAtom, fetchingAtom } = createDepartmentCustomerUserAtoms(
    department,
    departmentId,
  );
  const fetchCustomerUser = useSetAtom(fetchingAtom);

  useEffect(() => {
    fetchCustomerUser({ department, departmentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useAtomValue(dataAtom);
};
