import { CloseButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import {
  Box,
  CustomerCompanyIcon,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Text,
  VStack,
} from 'components/uikit';
import {
  useCustomerCompany,
  useCustomerCompanyUsers,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { getCustomerName } from 'data/healthReports/utils/getCustomerAnyDepartmentId';
import { isLoading } from 'utils/types';

function CompanyBasicInfo({
  customerCompanyId,
}: {
  customerCompanyId: string;
}) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.header',
  });
  const { company, fetchStatus: companyFetchStatus } =
    useCustomerCompany(customerCompanyId);
  const { usersCount, fetchStatus: userCountFetchStatus } =
    useCustomerCompanyUsers(customerCompanyId);
  const { showProductBlocks } =
    useShowDepartmentBlocksForCompany(customerCompanyId);

  return (
    <HStack>
      <Box
        boxSize={'48px'}
        p={'8px'}
        background={'grey.offWhite'}
        border={'1px solid'}
        borderColor={'grey.border'}
        borderRadius={'4px'}
      >
        <CustomerCompanyIcon boxSize={'32px'} p={'3px'} />
      </Box>
      <VStack alignItems='left' spacing={0}>
        {isLoading(companyFetchStatus) ? (
          <Skeleton h='24px' w='200px' mb='2px' />
        ) : (
          <Heading
            as={'h5'}
            fontWeight={'semibold'}
            noOfLines={1}
            fontSize={'18px'}
            lineHeight={1.3}
          >
            {getCustomerName(company)}
          </Heading>
        )}
        <Box h='22.4px'>
          {showProductBlocks ? (
            isLoading(userCountFetchStatus) ? (
              <Skeleton h='22.4px' w='70px' />
            ) : (
              <Text
                noOfLines={1}
                fontSize={'14px'}
                lineHeight={1.6}
                color={'grey.tertiaryText'}
              >
                {t('user_count', { count: usersCount })}
              </Text>
            )
          ) : null}
        </Box>
      </VStack>
    </HStack>
  );
}

export function CompanyBasicInfoAndCloseButton({
  customerCompanyId,
  onClick,
}: {
  customerCompanyId: string;
  onClick: () => void;
}) {
  return (
    <Flex
      width={'100%'}
      flexDirection={'row'}
      justify={'space-between'}
      gap={'10px'}
    >
      <CompanyBasicInfo customerCompanyId={customerCompanyId} />
      <CloseButton
        height={'24px'}
        width={'24px'}
        background={'grey.background'}
        color={'grey.tertiaryText'}
        onClick={onClick}
      />
    </Flex>
  );
}
