import { AnalyticsEventGroup } from 'utils/analytics/types/AnalyticsEventGroup';
import { EventKey } from 'utils/analytics/types/EventKey';

// Class with static members is effectively an enum with object values
export class AnalyticsEventType implements EventKey {
  /**
   * USER
   */
  static SIGN_UP = new AnalyticsEventType(
    'user-sign-up',
    AnalyticsEventGroup.USER,
  );
  static SIGN_IN = new AnalyticsEventType(
    'user-sign-in',
    AnalyticsEventGroup.USER,
  );
  static SIGN_OUT = new AnalyticsEventType(
    'user-sign-out',
    AnalyticsEventGroup.USER,
  );

  /**
   * INSIGHTS
   */
  static INSIGHT_OPENED = new AnalyticsEventType(
    'insight-opened',
    AnalyticsEventGroup.INSIGHTS,
  );
  static INSIGHT_CHART_ERROR = new AnalyticsEventType(
    'chart-error',
    AnalyticsEventGroup.INSIGHTS,
  );

  /**
   * PAGE_VIEW
   */
  static PAGE_VISIT = new AnalyticsEventType(
    'page-visit',
    AnalyticsEventGroup.PAGE_VIEW,
  );

  /**
   * ONBOARDING
   */
  static USER_DETAILS_UPDATED = new AnalyticsEventType(
    'onboarding-user-details-added',
    AnalyticsEventGroup.ONBOARDING,
  );
  static COMPANY_NAME_UPDATED = new AnalyticsEventType(
    'onboarding-company-details-added',
    AnalyticsEventGroup.ONBOARDING,
  );
  static SLACK_STARTED = new AnalyticsEventType(
    'onboarding-slack-auth-started',
    AnalyticsEventGroup.ONBOARDING,
  );
  static SLACK_INSTALLED = new AnalyticsEventType(
    'onboarding-slack-auth-completed',
    AnalyticsEventGroup.ONBOARDING,
  );
  static SLACK_SKIPPED = new AnalyticsEventType(
    'onboarding-slack-skipped',
    AnalyticsEventGroup.ONBOARDING,
  );
  static THEME_SELECTED = new AnalyticsEventType(
    'onboarding-theme-selected',
    AnalyticsEventGroup.ONBOARDING,
  );
  static CHANNEL_SELECTED = new AnalyticsEventType(
    'onboarding-channel-selected',
    AnalyticsEventGroup.ONBOARDING,
  );
  static SIGNUP_COMPLETED = new AnalyticsEventType(
    'onboarding-signup-completed',
    AnalyticsEventGroup.ONBOARDING,
  );
  static PRODUCT_TOUR_START = new AnalyticsEventType(
    'onboarding-product-tour-started',
    AnalyticsEventGroup.ONBOARDING,
  );
  static WELCOME_COMPLETED = new AnalyticsEventType(
    'welcome-completed',
    AnalyticsEventGroup.ONBOARDING,
  );
  static USED_PROVIDER_SELECTED = new AnalyticsEventType(
    'used-provider-selected',
    AnalyticsEventGroup.ONBOARDING,
  );
  static USED_PROVIDER_FLOW_RESTARTED = new AnalyticsEventType(
    'used-provider-flow-restarted',
    AnalyticsEventGroup.ONBOARDING,
  );
  static USED_PROVIDER_FLOW_COMPLETED = new AnalyticsEventType(
    'used-provider-flow-completed',
    AnalyticsEventGroup.ONBOARDING,
  );

  /**
   * DATA_SOURCES
   */
  static INTEGRATION_PANEL_OPENED = new AnalyticsEventType(
    'data-sources-integration-panel-opened',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static NEW_INTEGRATION_REQUESTED = new AnalyticsEventType(
    'data-sources-new-integration-requested',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static INTEGRATION_SELECTED = new AnalyticsEventType(
    'data-sources-integration-selected',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static INTEGRATION_STARTED = new AnalyticsEventType(
    'data-sources-integration-started',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static INTEGRATION_COMPLETED = new AnalyticsEventType(
    'data-sources-integration-completed',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static INTEGRATION_GOAL_SELECTED = new AnalyticsEventType(
    'data-sources-integration-goal-selected',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static GA_ACCOUNT_SELECTED = new AnalyticsEventType(
    'data-sources-ga-account-selected',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static GA_CONVERSION_EVENT_SELECTED = new AnalyticsEventType(
    'data-sources-ga-conversion-event-selected',
    AnalyticsEventGroup.DATA_SOURCES,
  );
  static GA_PROPERTIES_SELECTED = new AnalyticsEventType(
    'data-sources-ga-properties-selected',
    AnalyticsEventGroup.DATA_SOURCES,
  );

  /**
   * CHART_LEGEND
   */
  static CHART_LEGEND_FOCUSED = new AnalyticsEventType(
    'chart-legend-focused',
    AnalyticsEventGroup.CHART_LEGEND,
  );
  static CHART_LEGEND_TOGGLED = new AnalyticsEventType(
    'chart-legend-toggled',
    AnalyticsEventGroup.CHART_LEGEND,
  );

  /**
   * CHART_SPLIT
   */
  static CHART_SPLIT_REMOVED = new AnalyticsEventType(
    'chart-split-removed',
    AnalyticsEventGroup.CHART_SPLIT,
  );
  static CHART_SPLIT_APPLIED = new AnalyticsEventType(
    'chart-split-applied',
    AnalyticsEventGroup.CHART_SPLIT,
  );
  static CHART_SPLIT_CATEGORY_SELECTED = new AnalyticsEventType(
    'chart-split-category-selected',
    AnalyticsEventGroup.CHART_SPLIT,
  );
  static CHART_SPLIT_COMMAND_PANEL_OPENED = new AnalyticsEventType(
    'chart-split-command-panel-opened',
    AnalyticsEventGroup.CHART_SPLIT,
  );

  /**
   * CHART_FILTER
   */
  static CHART_FILTER_REMOVED = new AnalyticsEventType(
    'chart-filter-removed',
    AnalyticsEventGroup.CHART_FILTER,
  );
  static CHART_FILTER_COMMAND_PANEL_FILTERS_APPLIED = new AnalyticsEventType(
    'chart-filter-command-panel-filters-applied',
    AnalyticsEventGroup.CHART_FILTER,
  );
  static CHART_FILTER_COMMAND_PANEL_SEGMENTS_CLEARED = new AnalyticsEventType(
    'chart-filter-command-panel-segments-cleared',
    AnalyticsEventGroup.CHART_FILTER,
  );
  static CHART_FILTER_COMMAND_PANEL_SEGMENT_SELECTED = new AnalyticsEventType(
    'chart-filter-command-panel-segment-selected',
    AnalyticsEventGroup.CHART_FILTER,
  );
  static CHART_FILTER_COMMAND_PANEL_CATEGORY_SELECTED = new AnalyticsEventType(
    'chart-filter-command-panel-category-selected',
    AnalyticsEventGroup.CHART_FILTER,
  );
  static CHART_FILTER_COMMAND_PANEL_OPENED = new AnalyticsEventType(
    'chart-filter-command-panel-opened',
    AnalyticsEventGroup.CHART_FILTER,
  );

  /**
   * CHART_SPLIT_OR_FILTER
   */
  static CHART_SPLIT_OR_FILTER_APPLIED = new AnalyticsEventType(
    'chart-split-or-filter-applied',
    AnalyticsEventGroup.CHART_SPLIT_OR_FILTER,
  );

  /**
   * MISSION_CONTROL
   */
  static MISSION_CONTROL_OVERVIEW_OPENED = new AnalyticsEventType(
    'mission-control-overview-opened',
    AnalyticsEventGroup.MISSION_CONTROL,
  );
  static MISSION_CONTROL_MARKETING_OPENED = new AnalyticsEventType(
    'mission-control-marketing-opened',
    AnalyticsEventGroup.MISSION_CONTROL,
  );
  static MISSION_CONTROL_FINANCE_OPENED = new AnalyticsEventType(
    'mission-control-finance-opened',
    AnalyticsEventGroup.MISSION_CONTROL,
  );
  static MISSION_CONTROL_PRODUCT_OPENED = new AnalyticsEventType(
    'mission-control-product-opened',
    AnalyticsEventGroup.MISSION_CONTROL,
  );
  static MISSION_CONTROL_SALES_OPENED = new AnalyticsEventType(
    'mission-control-sales-opened',
    AnalyticsEventGroup.MISSION_CONTROL,
  );

  /**
   * CLICKABLE_CARD
   */
  static CLICKABLE_CARD_SWITCHED = new AnalyticsEventType(
    'mission-control-clickable-card-switched',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );
  static MISSION_CONTROL_FINANCE_SWITCH_CLICKED = new AnalyticsEventType(
    'mission-control-finance-switch-clicked',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );
  static MISSION_CONTROL_PRODUCT_SWITCH_CLICKED = new AnalyticsEventType(
    'mission-control-product-switch-clicked',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );
  static MISSION_CONTROL_SALES_SWITCH_CLICKED = new AnalyticsEventType(
    'mission-control-sales-switch-clicked',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );
  static MISSION_CONTROL_MARKETING_SWITCH_CLICKED = new AnalyticsEventType(
    'mission-control-marketing-switch-clicked',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );
  static MISSION_CONTROL_SUPPORT_SWITCH_CLICKED = new AnalyticsEventType(
    'mission-control-support-switch-clicked',
    AnalyticsEventGroup.CLICKABLE_CARD,
  );

  /**
   * SHARING
   */
  static PAGE_SHARE_LINK_COPIED = new AnalyticsEventType(
    'page-share-link-copied',
    AnalyticsEventGroup.SHARING,
  );
  static PAGE_SHARE_LINK_OPENED = new AnalyticsEventType(
    'page-share-link-opened',
    AnalyticsEventGroup.SHARING,
  );

  /**
   * DATE_PICKER
   */
  static DATE_PICKER_COMMAND_PANEL_DATE_ERROR = new AnalyticsEventType(
    'date-picker-command-panel-date-error',
    AnalyticsEventGroup.DATE_PICKER,
  );
  static DATE_PICKER_COMMAND_PANEL_DATE_SELECTED = new AnalyticsEventType(
    'date-picker-command-panel-date-selected',
    AnalyticsEventGroup.DATE_PICKER,
  );
  static DATE_PICKER_COMMAND_PANEL_OPENED = new AnalyticsEventType(
    'date-picker-command-panel-opened',
    AnalyticsEventGroup.DATE_PICKER,
  );
  static DATE_PICKER_PERIOD_SWITCHED = new AnalyticsEventType(
    'date-picker-period-switched',
    AnalyticsEventGroup.DATE_PICKER,
  );
  static DATE_PICKER_AGGREGATION_CHANGED = new AnalyticsEventType(
    'date-picker-aggregation-changed',
    AnalyticsEventGroup.DATE_PICKER,
  );
  static DATE_PICKER_OPENED = new AnalyticsEventType(
    'date-picker-opened',
    AnalyticsEventGroup.DATE_PICKER,
  );

  /**
   * BREAKDOWNS
   */
  static BREAKDOWNS_OPENED_FROM_MISSION_CONTROL = new AnalyticsEventType(
    'fullBreakdowns-opened-from-mission-control',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_OPENED = new AnalyticsEventType(
    'fullBreakdowns-opened',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_DEPARTMENT_SWITCHED = new AnalyticsEventType(
    'fullBreakdowns-department-switched',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_SWITCHED = new AnalyticsEventType(
    'fullBreakdowns-switched',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_SORT_CHANGED = new AnalyticsEventType(
    'fullBreakdowns-sort-changed',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_PAGINATION_CHANGED = new AnalyticsEventType(
    'fullBreakdowns-page-changed',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_SPLIT_CHANGED = new AnalyticsEventType(
    'fullBreakdowns-split-changed',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_BACK_TO_MISSION_CONTROL_BUTTON_CLICKED =
    new AnalyticsEventType(
      'fullBreakdowns-back-to-mission-control-button-clicked',
      AnalyticsEventGroup.BREAKDOWNS,
    );
  static BREAKDOWNS_REPORT_DATA_ERROR = new AnalyticsEventType(
    'fullBreakdowns-report-data-error',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_FILTER_TAB_SELECTED = new AnalyticsEventType(
    'fullBreakdowns-filter-tab-selected',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_FILTERED = new AnalyticsEventType(
    'fullBreakdowns-filtered',
    AnalyticsEventGroup.BREAKDOWNS,
  );
  static BREAKDOWNS_FILTER_SEARCHED = new AnalyticsEventType(
    'fullBreakdowns-searched',
    AnalyticsEventGroup.BREAKDOWNS,
  );

  /**
   * RADAR
   */
  static RADAR_WIDGET_INSIGHT_SELECTED = new AnalyticsEventType(
    'radar-widget-insight-selected',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_WIDGET_BUTTON_CLICKED = new AnalyticsEventType(
    'radar-widget-button-clicked',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_OPENED = new AnalyticsEventType(
    'radar-opened',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHTS_FILTERED = new AnalyticsEventType(
    'radar-insights-filtered',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_TIMELINE_SWITCHED = new AnalyticsEventType(
    'radar-timeline-switched',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_BOOKMARKED = new AnalyticsEventType(
    'radar-insight-bookmarked',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_BOOKMARK_REMOVED = new AnalyticsEventType(
    'radar-insight-bookmark-removed',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_BOOKMARKED_TRIGGERED = new AnalyticsEventType(
    'radar-bookmarked-triggered',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_MANAGE_REVIEWS_CLICKED = new AnalyticsEventType(
    'radar-manage-reviews-clicked',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_CREATE_REVIEW_CLICKED = new AnalyticsEventType(
    'radar-create-review-clicked',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_SHARED = new AnalyticsEventType(
    'radar-insight-shared',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_RATED = new AnalyticsEventType(
    'radar-insight-rated',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_FEEDBACK_SUBMITTED = new AnalyticsEventType(
    'radar-insight-feedback-submitted',
    AnalyticsEventGroup.RADAR,
  );
  static RADAR_INSIGHT_TEXT_FEEDBACK_SUBMITTED = new AnalyticsEventType(
    'radar-insight-text-feedback-submitted',
    AnalyticsEventGroup.RADAR,
  );

  /**
   * ALERTS
   */
  static ALERT_OPENED = new AnalyticsEventType(
    'radar-alert-opened',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERT_CONFIGS_OPENED = new AnalyticsEventType(
    'radar-alert-configs-opened',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERTS_FEED_OPENED = new AnalyticsEventType(
    'radar-alerts-feed-opened',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERT_CONFIG_CREATED = new AnalyticsEventType(
    'radar-alert-config-created',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERT_CONFIG_UPDATED = new AnalyticsEventType(
    'radar-alert-config-edited',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERT_CONFIG_DELETED = new AnalyticsEventType(
    'radar-alert-config-deleted',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERTS_FILTERED = new AnalyticsEventType(
    'radar-alerts-feed-filtered',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERTS_TIMELINE_SWITCHED = new AnalyticsEventType(
    'radar-alert-timeline-switched',
    AnalyticsEventGroup.ALERTS,
  );
  static ALERT_SHARED = new AnalyticsEventType(
    'radar-alert-shared',
    AnalyticsEventGroup.ALERTS,
  );

  /**
   * Chart errors for MISSION_CONTROL, RADAR, BREAKDOWNS
   */
  static MISSION_CONTROL_CHART_ERROR = new AnalyticsEventType(
    'chart-error',
    AnalyticsEventGroup.MISSION_CONTROL,
  );
  static RADAR_CHART_ERROR = new AnalyticsEventType(
    'chart-error',
    AnalyticsEventGroup.RADAR,
  );
  static BREAKDOWNS_CHART_ERROR = new AnalyticsEventType(
    'chart-error',
    AnalyticsEventGroup.BREAKDOWNS,
  );

  /**
   * SIGNAL_SETTINGS
   */
  static FINANCE_SETTINGS_OPENED = new AnalyticsEventType(
    'signal-settings-finance-opened',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static FINANCE_SETTINGS_UPDATED = new AnalyticsEventType(
    'signal-settings-finance-updated',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static PRODUCT_SETTINGS_OPENED = new AnalyticsEventType(
    'signal-settings-product-opened',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static PRODUCT_SETTINGS_UPDATED = new AnalyticsEventType(
    'signal-settings-product-updated',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static SALES_SETTINGS_OPENED = new AnalyticsEventType(
    'signal-settings-sales-opened',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static SALES_SETTINGS_UPDATED = new AnalyticsEventType(
    'signal-settings-sales-updated',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static MARKETING_SETTINGS_OPENED = new AnalyticsEventType(
    'signal-settings-marketing-opened',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static MARKETING_SETTINGS_UPDATED = new AnalyticsEventType(
    'signal-settings-marketing-updated',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static SUPPORT_SETTINGS_OPENED = new AnalyticsEventType(
    'signal-settings-support-opened',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );
  static SUPPORT_SETTINGS_UPDATED = new AnalyticsEventType(
    'signal-settings-support-updated',
    AnalyticsEventGroup.SIGNAL_SETTINGS,
  );

  protected constructor(
    readonly action: string,
    readonly group: AnalyticsEventGroup,
  ) {}
}
