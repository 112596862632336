import { createIcon } from '@chakra-ui/react';

export const RadarIcon = createIcon({
  displayName: 'RadarIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12 3V10.875M12 6.9375C9.20406 6.9375 6.9375 9.20406 6.9375 12C6.9375 14.7959 9.20406 17.0625 12 17.0625C13.4102 17.0625 14.6857 16.4859 15.6037 15.5556M12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875Z'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </>
  ),
});
