import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Chat } from 'data/ai/models';
import { parseItemsStrict } from 'utils/parser/parseResponse';

import { PARROT_API_URL } from './resourcesUrls';

export async function getChats() {
  const result = await authorisedFetch(
    `${PARROT_API_URL}/chats?page=0&size=9999`,
    {
      method: 'GET',
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(
      `Failed to get parrot follow up questions: ${responseBody}`,
    );
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  const results = parseItemsStrict(Chat, data);

  return { results };
}
