import {
  DataSource,
  DataSourceProvider as APIDataSourceProvider,
  DataSourceStatus,
} from 'api/graphql/backendAPI/generatedTypes';
import { Department } from 'data/blocks/models/Block';
import {
  DEPARTMENT_NAMES_PER_TYPE,
  DepartmentNameType,
} from 'data/constants/departments';
import { State } from 'utils/atoms/dataFetchingAtom';

export type IndexedDataSources = { [k in APIDataSourceProvider]?: DataSource };

export type DataSourcesPayload = {
  dataSources: IndexedDataSources | null;
};

export type DataSourcesState = State<DataSourcesPayload>;

export const isErrorStatus = (status?: DataSourceStatus) =>
  status
    ? ['HISTORICAL_DATA_SYNC_ERROR', 'LIVE_SYNC_ERROR'].includes(status)
    : false;

export const syncStarted = (status: DataSourceStatus) =>
  !['INITIAL', 'NOT_CREATED', 'DISABLED'].includes(status);

export const hasBeenSyncedOnce = (status?: DataSourceStatus) =>
  status
    ? [
        'HISTORICAL_DATA_SYNC_SUCCESS',
        'LIVE_SYNC_ERROR',
        'LIVE_SYNC_IN_PROGRESS',
        'LIVE_SYNC_SUCCESS',
      ].includes(status)
    : false;

export const getProviderName = (provider: APIDataSourceProvider) => {
  switch (provider) {
    case 'GOOGLE_ANALYTICS_3':
    case 'GOOGLE_ANALYTICS_4':
      return 'Google Analytics';
    case 'STRIPE':
      return 'Stripe';
    case 'HUBSPOT':
      return 'HubSpot';
    case 'XERO':
      return 'Xero';
    case 'MIXPANEL':
      return 'Mixpanel';
    case 'SEGMENT':
      return 'Segment';
    case 'AMPLITUDE':
      return 'Amplitude';
    case 'CHARGEBEE':
      return 'Chargebee';
    case 'INTERCOM':
      return 'Intercom';
  }
};

export function getDepartment(provider: APIDataSourceProvider): Department {
  switch (provider) {
    case 'GOOGLE_ANALYTICS_3':
    case 'GOOGLE_ANALYTICS_4':
      return 'GROWTH';
    case 'STRIPE':
    case 'XERO':
    case 'CHARGEBEE':
      return 'FINANCE';
    case 'HUBSPOT':
      return 'SALES';
    case 'MIXPANEL':
    case 'AMPLITUDE':
    case 'SEGMENT':
      return 'PRODUCT';
    case 'INTERCOM':
      return 'SUPPORT';
  }
}

export function getDepartmentName(
  provider: APIDataSourceProvider,
): DepartmentNameType {
  return DEPARTMENT_NAMES_PER_TYPE[getDepartment(provider)];
}

export const statusPriority: Record<DataSourceStatus, number> = {
  HISTORICAL_DATA_SYNC_ERROR: 8,
  LIVE_SYNC_ERROR: 7,
  HISTORICAL_DATA_SYNC_IN_PROGRESS: 6,
  LIVE_SYNC_IN_PROGRESS: 5,
  HISTORICAL_DATA_SYNC_SUCCESS: 4,
  LIVE_SYNC_SUCCESS: 3,
  INITIAL: 2,
  NOT_CREATED: 1,
  DISABLED: 0,
};
