import { FC, ReactNode } from 'react';

import { CloseButton } from '@chakra-ui/react';

import { Card, Flex, Text } from 'components/uikit';

type SidebarCardProps = {
  title: string;
  description: string;
  cta: string;
  ctaIcon: ReactNode;
  onClose: () => void;
  onCtaClick: () => void;
};

export const SidebarCard: FC<SidebarCardProps> = ({
  title,
  description,
  cta,
  ctaIcon,
  onClose,
  onCtaClick,
}) => {
  return (
    <Card
      width='152px'
      p='8px'
      gap='4px'
      borderRadius='4px'
      borderTop='2px solid'
      borderBottomWidth='0'
      borderX='0'
      borderTopColor='primary.button'
      backgroundColor='grey.offWhite.dark'
    >
      <CloseButton
        onClick={onClose}
        color='gray.400'
        size='sm'
        p='2px'
        borderRadius='3px'
      />
      <Text
        color='grey.primaryText.dark'
        fontSize='14px'
        fontWeight='600'
        lineHeight='160%'
      >
        {title}
      </Text>
      <Text
        fontSize='12px'
        fontWeight='400'
        lineHeight='150%'
        color='grey.secondaryText.dark'
      >
        {description}
      </Text>
      <Flex
        color='primary.button.dark'
        alignItems='center'
        gap='4px'
        _hover={{
          cursor: 'pointer',
        }}
        onClick={onCtaClick}
      >
        <Text
          fontSize='12px'
          fontWeight='600'
          color='primary.button.dark'
          _hover={{
            textDecoration: 'underline',
          }}
        >
          {cta}
        </Text>
        {ctaIcon}
      </Flex>
    </Card>
  );
};
