import { z } from 'zod';

import { BlockData } from 'data/blocks/models/BlockDataGoal';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { DEPARTMENTS } from 'data/constants/departments';
import { SUB_DEPARTMENTS } from 'data/constants/subDepartments';
import { DataAtom } from 'utils/atoms/dataAtom';
import { WritableDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

import { BreakdownConfig } from './BreakdownConfig';
import { ChartConfig } from './ChartConfig';
import {
  FinanceDictionarySummaryUniqueBlockName,
  GrowthDictionarySummaryUniqueBlockName,
  ProductDictionarySummaryUniqueBlockName,
  SalesDictionarySummaryUniqueBlockName,
  SupportDictionarySummaryUniqueBlockName,
} from './MetricDictionarySummaryName';
import {
  FinanceCustomersSubpanels,
  FinanceSummaryUniqueBlockName,
  FinanceValueSubpanels,
  GrowthSummaryUniqueBlockName,
  MetricSummaryBlockName,
  ProductCompaniesSubpanels,
  ProductEngagementSubpanels,
  ProductSummaryUniqueBlockName,
  ProductUsersSubpanels,
  SalesCompaniesSubpanels,
  SalesSummaryUniqueBlockName,
  SalesValueSubpanels,
  SupportSummaryUniqueBlockName,
} from './MetricSummaryBlockName';

export const CustomersCommonUniqueBlockName = z.enum([
  'CUSTOMER_COMPANY_ROOT',
  'CUSTOMER_COMPANY_INFO',
  'CUSTOMER_USER_ROOT',
  'CUSTOMER_USER_INFO',
  'CUSTOMER_USER_COMPANY_INFO',

  'CUSTOMER_LIST_FULL_BREAKDOWNS',
  'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_ALL_CUSTOMERS',
  'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_TOP_NEW_TRIALS',
  'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_UPSELL_OPPORTUNITIES',
  'CUSTOMER_LIST_FULL_BREAKDOWNS_BY_CHURN_RISKS',
  'CUSTOMER_LIST_MATCHED_PRODUCT_COMPANIES_STATS',
  'CUSTOMER_LIST_MATCHED_FINANCE_COMPANIES_STATS',
]);

export const CustomersProductUniqueBlockName = z.enum([
  ...CustomersCommonUniqueBlockName.options,

  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_QUARTILE',
  'CUSTOMER_COMPANY_PRODUCT_ENGAGEMENT_QUARTILE',
  'CUSTOMER_COMPANY_PRODUCT_LIFESPAN_QUARTILE',
  'CUSTOMER_COMPANY_PRODUCT_USAGE',
  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS',
  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_SUMMARY',
  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_THIS_WEEK',
  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_THIS_MONTH',
  'CUSTOMER_COMPANY_PRODUCT_ACTIVE_USERS_CHART',
  'CUSTOMER_COMPANY_PRODUCT_ENGAGEMENT',
  'CUSTOMER_COMPANY_PRODUCT_FULL_BREAKDOWN_BY_USER_ENGAGEMENT',
  'CUSTOMER_COMPANY_PRODUCT_USERS',

  'CUSTOMER_USER_PRODUCT_ENGAGEMENT_QUARTILE',
  'CUSTOMER_USER_PRODUCT_ACTIVE_DAYS_QUARTILE',
  'CUSTOMER_USER_PRODUCT_LIFESPAN_QUARTILE',
  'CUSTOMER_USER_PRODUCT_USAGE',
  'CUSTOMER_USER_PRODUCT_ACTIVE_DAYS',
  'CUSTOMER_USER_PRODUCT_ACTIVE_DAYS_CHART',
  'CUSTOMER_USER_PRODUCT_ENGAGEMENT',
]);

export type CustomersProductUniqueBlockName = z.infer<
  typeof CustomersProductUniqueBlockName
>;

export const CustomersFinanceUniqueBlockName = z.enum([
  ...CustomersCommonUniqueBlockName.options,

  'CUSTOMER_COMPANY_FINANCE_REVENUE_QUARTILE',
  'CUSTOMER_COMPANY_FINANCE_REVENUE',
  'CUSTOMER_COMPANY_FINANCE_REVENUE_MRR',
  'CUSTOMER_COMPANY_FINANCE_REVENUE_STATS',
  'CUSTOMER_COMPANY_FINANCE_REVENUE_CHART',
  'CUSTOMER_COMPANY_FINANCE_REVENUE_STATS',
  'CUSTOMER_COMPANY_FINANCE_REVENUE_BREAKDOWN',

  'CUSTOMER_USER_FINANCE_REVENUE_QUARTILE',
  'CUSTOMER_USER_FINANCE_REVENUE',
  'CUSTOMER_USER_FINANCE_REVENUE_MRR',
  'CUSTOMER_USER_FINANCE_REVENUE_STATS',
  'CUSTOMER_USER_FINANCE_REVENUE_CHART',
  'CUSTOMER_USER_FINANCE_REVENUE_STATS',
  'CUSTOMER_USER_FINANCE_REVENUE_BREAKDOWN',
]);

export type CustomersFinanceSummaryUniqueBlockName = z.infer<
  typeof CustomersFinanceUniqueBlockName
>;

export const CustomersUniqueBlockName = z.enum([
  ...CustomersCommonUniqueBlockName.options,
  ...CustomersProductUniqueBlockName.options,
  ...CustomersFinanceUniqueBlockName.options,
]);

export type CustomersUniqueBlockName = z.infer<typeof CustomersUniqueBlockName>;

export const SupportDictionaryContentUniqueName = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_CONTENT',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_CONTENT',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_CONTENT',
]);

export const SupportDictionarySwitchNames = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME',
]);

export type SupportDictionarySwitchNames = z.infer<
  typeof SupportDictionarySwitchNames
>;

export const SupportDictionarySummaryContentUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_SUMMARY_CONTENT',
]);

export const SupportDictionaryChartUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_CHART',
  'METRIC_DICTIONARY_SUPPORT_INBOUND_CONVERSATIONS_CHART',
  'METRIC_DICTIONARY_SUPPORT_AVERAGE_RESPONSE_TIME_CHART',
]);

export const SupportDictionaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SUPPORT',
  ...SupportDictionaryContentUniqueName.options,
  ...SupportDictionarySwitchNames.options,
  ...SupportDictionarySummaryContentUniqueBlockName.options,
  ...SupportDictionarySummaryUniqueBlockName.options,
  ...SupportDictionaryChartUniqueBlockName.options,
]);

export const SupportUniqueBlockName = z.enum([
  ...SupportDictionaryUniqueBlockName.options,
]);

export type SupportUniqueBlockName = z.infer<typeof SupportUniqueBlockName>;

export const GrowthDictionaryContentUniqueName = z.enum([
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CONTENT',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_CONTENT',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_CONTENT',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_CONTENT',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_CONTENT',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_CONTENT',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_CONTENT',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_CONTENT',
  'METRIC_DICTIONARY_GROWTH_LEADS_CONTENT',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_CONTENT',
]);

export const GrowthDictionarySwitchNames = z.enum([
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS',
  'METRIC_DICTIONARY_GROWTH_SESSIONS',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME',
  'METRIC_DICTIONARY_GROWTH_LEADS',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE',
]);

export type GrowthDictionarySwitchNames = z.infer<
  typeof GrowthDictionarySwitchNames
>;

export const GrowthDictionarySummaryContentUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_LEADS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_SUMMARY_CONTENT',
]);

export const GrowthDictionaryChartUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_GROWTH_NEW_VISITORS_CHART',
  'METRIC_DICTIONARY_GROWTH_SESSIONS_CHART',
  'METRIC_DICTIONARY_GROWTH_ENGAGED_SESSIONS_CHART',
  'METRIC_DICTIONARY_GROWTH_ENGAGEMENT_RATE_CHART',
  'METRIC_DICTIONARY_GROWTH_PAGE_VIEWS_CHART',
  'METRIC_DICTIONARY_GROWTH_PAGES_PER_SESSION_CHART',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_SESSION_DURATION_CHART',
  'METRIC_DICTIONARY_GROWTH_AVERAGE_PAGE_VIEW_TIME_CHART',
  'METRIC_DICTIONARY_GROWTH_LEADS_CHART',
  'METRIC_DICTIONARY_GROWTH_LEAD_CONVERSION_RATE_CHART',
]);

export const GrowthDictionaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_GROWTH',
  ...GrowthDictionaryContentUniqueName.options,
  ...GrowthDictionarySwitchNames.options,
  ...GrowthDictionarySummaryContentUniqueBlockName.options,
  ...GrowthDictionarySummaryUniqueBlockName.options,
  ...GrowthDictionaryChartUniqueBlockName.options,
]);

export const GrowthPanelName = z.enum(['METRICS_GROWTH_EVENTS']);
export type GrowthPanelName = z.infer<typeof GrowthPanelName>;

export const GrowthFullBreakdownsBlockName = z.enum([
  'FULL_BREAKDOWNS_GROWTH',
  'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_CAMPAIGN_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_CHANNEL_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_COUNTRY_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_DEVICE_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_OS_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_PAGE_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_NEW_VISITORS',
  'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_SESSIONS',
  'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_ENGAGEMENT_RATE',
  'FULL_BREAKDOWNS_GROWTH_BY_SOURCE_BY_AVG_TIME_PER_SESSION',
  'FULL_BREAKDOWNS_GROWTH_BY_PAGE_VIEWS',
]);

export const GrowthUniqueBlockName = z.enum([
  'MISSION_CONTROL_GROWTH',
  'MISSION_CONTROL_NEW_VISITORS_SUMMARY',
  'MISSION_CONTROL_NEW_VISITORS_CHART',
  'MISSION_CONTROL_LEADS_SUMMARY',
  'MISSION_CONTROL_LEADS_CHART',
  'METRICS_GROWTH_LEADS',
  'METRICS_GROWTH',
  'METRICS_GROWTH_SUMMARY',
  'METRICS_GROWTH_VISITORS',
  'METRICS_GROWTH_CONTENT_VISITORS',
  'METRICS_GROWTH_CONTENT_TIME_ON_SITE',
  'METRICS_GROWTH_CONTENT_ENGAGEMENT',
  'METRICS_GROWTH_CONTENT_SESSIONS',
  'METRICS_GROWTH_CONTENT_LEADS',
  'METRICS_GROWTH_VISITORS_BREAKDOWN',
  'METRICS_GROWTH_VISITORS_BREAKDOWN_BY_MEDIUM',
  'METRICS_GROWTH_VISITORS_BREAKDOWN_BY_COUNTRY',
  'METRICS_GROWTH_SESSIONS',
  'METRICS_GROWTH_SESSIONS_BREAKDOWN',
  'METRICS_GROWTH_SESSIONS_BREAKDOWN_BY_LANDING',
  'METRICS_GROWTH_SESSIONS_BREAKDOWN_BY_OS',
  'METRICS_GROWTH_ENGAGEMENT',
  'METRICS_GROWTH_ENGAGEMENT_BREAKDOWN',
  'METRICS_GROWTH_ENGAGEMENT_BREAKDOWN_BY_MEDIUM',
  'METRICS_GROWTH_ENGAGEMENT_BREAKDOWN_BY_CAMPAIGN',
  'METRICS_GROWTH_AVERAGE_TIME_ON_SITE',
  'METRICS_GROWTH_TIME_BREAKDOWN',
  'METRICS_GROWTH_SUMMARY_LEADS',
  'METRICS_GROWTH_LEADS',
  'METRICS_GROWTH_LEADS_BREAKDOWN',
  'METRICS_GROWTH_LEADS_BREAKDOWN_BY_CAMPAIGN',
  'METRICS_GROWTH_LEADS_BREAKDOWN_BY_MEDIUM',
  'METRICS_GROWTH_TIME_BREAKDOWN_BY_PAGE',
  'METRICS_GROWTH_VISITORS_BREAKDOWN_BY_PAGE',
  ...GrowthPanelName.options,
  ...GrowthFullBreakdownsBlockName.options,
  ...GrowthDictionaryUniqueBlockName.options,
  ...GrowthSummaryUniqueBlockName.options,
]);
export type GrowthUniqueBlockName = z.infer<typeof GrowthUniqueBlockName>;

export const FinanceDictionaryContentUniqueName = z.enum([
  'METRIC_DICTIONARY_FINANCE_ARR_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_CONTENT',
  'METRIC_DICTIONARY_FINANCE_ARPC_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CHURN_CONTENT',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CONTENT',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_CONTENT',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_CONTENT',
  'METRIC_DICTIONARY_FINANCE_GRR_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NRR_CONTENT',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_CONTENT',
]);

export const FinanceDictionarySwitchNames = z.enum([
  'METRIC_DICTIONARY_FINANCE_ARR',
  'METRIC_DICTIONARY_FINANCE_MRR',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS',
  'METRIC_DICTIONARY_FINANCE_ARPC',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS',
  'METRIC_DICTIONARY_FINANCE_CHURN',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT',
  'METRIC_DICTIONARY_FINANCE_EXPANSION',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT',
  'METRIC_DICTIONARY_FINANCE_MRR_WON',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO',
  'METRIC_DICTIONARY_FINANCE_GRR',
  'METRIC_DICTIONARY_FINANCE_NRR',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE',
]);

export type FinanceDictionarySwitchNames = z.infer<
  typeof FinanceDictionarySwitchNames
>;

export const FinanceDictionarySummaryContentUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_FINANCE_ARR_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_ARPC_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CHURN_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_GRR_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NRR_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_SUMMARY_CONTENT',
]);

export const FinanceDictionaryChartUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_FINANCE_ARR_CHART',
  'METRIC_DICTIONARY_FINANCE_MRR_CHART',
  'METRIC_DICTIONARY_FINANCE_CUSTOMERS_CHART',
  'METRIC_DICTIONARY_FINANCE_ARPC_CHART',
  'METRIC_DICTIONARY_FINANCE_NET_MRR_CHANGE_CHART',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_MRR_CHART',
  'METRIC_DICTIONARY_FINANCE_NEW_CUSTOMERS_CHART',
  'METRIC_DICTIONARY_FINANCE_CHURN_CHART',
  'METRIC_DICTIONARY_FINANCE_CHURN_CUSTOMERS_COUNT_CHART',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CHART',
  'METRIC_DICTIONARY_FINANCE_EXPANSION_CUSTOMERS_COUNT_CHART',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CHART',
  'METRIC_DICTIONARY_FINANCE_CONTRACTION_CUSTOMERS_COUNT_CHART',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CHART',
  'METRIC_DICTIONARY_FINANCE_REACTIVATION_CUSTOMERS_COUNT_CHART',
  'METRIC_DICTIONARY_FINANCE_MRR_WON_CHART',
  'METRIC_DICTIONARY_FINANCE_MRR_LOST_CHART',
  'METRIC_DICTIONARY_FINANCE_QUICK_RATIO_CHART',
  'METRIC_DICTIONARY_FINANCE_GRR_CHART',
  'METRIC_DICTIONARY_FINANCE_NRR_CHART',
  'METRIC_DICTIONARY_FINANCE_AVERAGE_LTV_CHART',
  'METRIC_DICTIONARY_FINANCE_TOTAL_ACCOUNTS_CHART',
  'METRIC_DICTIONARY_FINANCE_NEW_TRIALS_CHART',
  'METRIC_DICTIONARY_FINANCE_NEW_ACCOUNTS_CHART',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERTED_CHART',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CANCELLED_CHART',
  'METRIC_DICTIONARY_FINANCE_TRIALS_CONVERSION_RATE_CHART',
]);

export const FinanceDictionaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_FINANCE',
  ...FinanceDictionaryContentUniqueName.options,
  ...FinanceDictionarySwitchNames.options,
  ...FinanceDictionarySummaryContentUniqueBlockName.options,
  ...FinanceDictionarySummaryUniqueBlockName.options,
  ...FinanceDictionaryChartUniqueBlockName.options,
]);

export type FinanceDictionaryUniqueBlockName = z.infer<
  typeof FinanceDictionaryUniqueBlockName
>;

export const FinancePanelName = z.enum([
  'METRICS_FINANCE_VALUE',
  'METRICS_FINANCE_CUSTOMERS',
]);
export type FinancePanelName = z.infer<typeof FinancePanelName>;

export const FinanceFullBreakdownsBlocks = z.enum([
  'FULL_BREAKDOWNS_FINANCE',
  'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_HIGHEST_LTV',
  'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_LARGEST_MRR',
  'FULL_BREAKDOWNS_FINANCE_BY_CUSTOMERS_BY_JOIN_DATE',
  'FULL_BREAKDOWNS_FINANCE_BY_PRODUCTS_BY_CUSTOMERS_COUNT',
  'FULL_BREAKDOWNS_FINANCE_BY_PRODUCTS_BY_MRR',
  'FULL_BREAKDOWNS_FINANCE_LATEST_TRANSACTIONS',
  'FULL_BREAKDOWNS_FINANCE_CHURN_TRANSACTIONS_BY_DATE',
  'FULL_BREAKDOWNS_FINANCE_CHURN_TRANSACTIONS_BY_MRR',
  'FULL_BREAKDOWNS_FINANCE_EXPANSION_TRANSACTIONS_BY_MRR',
]);

export type FinanceFullBreakdownsBlocks = z.infer<
  typeof FinanceFullBreakdownsBlocks
>;

export const FinanceUniqueBlockName = z.enum([
  'MISSION_CONTROL_FINANCE',
  'MISSION_CONTROL_MRR_SUMMARY',
  'MISSION_CONTROL_TOTAL_CUSTOMERS_SUMMARY',
  'MISSION_CONTROL_MRR_CHART',
  'MISSION_CONTROL_TOTAL_CUSTOMERS_CHART',
  'METRICS_FINANCE',
  'METRICS_FINANCE_SUMMARY',
  'METRICS_FINANCE_MRR_CHART',
  'METRICS_FINANCE_MRR_BREAKDOWN',
  'METRICS_FINANCE_MRR_BREAKDOWN_BY_PRODUCT',
  'METRICS_FINANCE_MRR_BREAKDOWN_BY_CLIENT',
  'METRICS_FINANCE_MRR_MOVEMENTS',
  'METRICS_FINANCE_MRR_CHANGE_BREAKDOWN',
  'METRICS_FINANCE_MRR_CHANGE_BREAKDOWN_LATEST_TRANSACTIONS',
  'METRICS_FINANCE_MRR_CHANGE_BREAKDOWN_MOVEMENTS',
  'METRICS_FINANCE_NEW_CUSTOMERS',
  'METRICS_FINANCE_NEW_CUSTOMERS_BREAKDOWN',
  'METRICS_FINANCE_NEW_CUSTOMERS_BREAKDOWN_NEWEST_CUSTOMERS',
  'METRICS_FINANCE_NEW_CUSTOMERS_BREAKDOWN_BY_PRODUCT',
  'METRICS_FINANCE_EXPANSION',
  'METRICS_FINANCE_EXPANSION_BREAKDOWN',
  'METRICS_FINANCE_EXPANSION_BREAKDOWN_LATEST_UPGRADES',
  'METRICS_FINANCE_EXPANSION_BREAKDOWN_LONGEST_CUSTOMERS',
  'METRICS_FINANCE_CHURN',
  'METRICS_FINANCE_CHURN_BREAKDOWN',
  'METRICS_FINANCE_CHURN_BREAKDOWN_LATEST_CHURNS',
  'METRICS_FINANCE_CHURN_BREAKDOWN_LARGEST_CHURNS',
  'METRICS_FINANCE_SUMMARY_CUSTOMERS',
  'METRICS_FINANCE_RECURRING_CUSTOMERS_CHART',
  'METRICS_FINANCE_RECURRING_CUSTOMERS_BREAKDOWN',
  'METRICS_FINANCE_RECURRING_CUSTOMERS_BREAKDOWN_BY_PRODUCT',
  'METRICS_FINANCE_RECURRING_CUSTOMERS_BREAKDOWN_BY_CLIENT',
  'METRICS_FINANCE_NEW_TRIALS',
  'METRICS_FINANCE_NEW_TRIALS_BREAKDOWN',
  'METRICS_FINANCE_NEW_TRIALS_BREAKDOWN_LATEST_TRIALS',
  'METRICS_FINANCE_NEW_TRIALS_BREAKDOWN_ENDING_SOON_TRIALS',
  'METRICS_FINANCE_EXPANSION_CUSTOMERS',
  'METRICS_FINANCE_EXPANSION_BREAKDOWN_CUSTOMERS',
  'METRICS_FINANCE_EXPANSION_CUSTOMERS_BREAKDOWN_LATEST_UPGRADES',
  'METRICS_FINANCE_EXPANSION_CUSTOMERS_BREAKDOWN_LONGEST_CUSTOMERS',
  'METRICS_FINANCE_CHURN_CUSTOMERS',
  'METRICS_FINANCE_CHURN_BREAKDOWN_CUSTOMERS',
  'METRICS_FINANCE_CHURN_BREAKDOWN_LATEST_CHURNS_CUSTOMERS',
  'METRICS_FINANCE_CHURN_BREAKDOWN_LARGEST_CHURNS_CUSTOMERS',
  'METRICS_FINANCE_NEW_CUSTOMERS_MRR',
  'METRICS_FINANCE_NEW_CUSTOMERS_MRR_BREAKDOWN',
  'METRICS_FINANCE_NEW_CUSTOMERS_MRR_BREAKDOWN_NEWEST_CUSTOMERS',
  'METRICS_FINANCE_NEW_CUSTOMERS_MRR_BREAKDOWN_BY_PRODUCT',

  'METRICS_FINANCE_CONTENT_MRR',
  'METRICS_FINANCE_CONTENT_MRR_MOVEMENTS',
  'METRICS_FINANCE_CONTENT_NEW_CUSTOMERS_MRR',
  'METRICS_FINANCE_CONTENT_EXPANSION',
  'METRICS_FINANCE_CONTENT_CHURN',
  'METRICS_FINANCE_CONTENT_RECURRING_CUSTOMERS',
  'METRICS_FINANCE_CONTENT_NEW_TRIALS',
  'METRICS_FINANCE_CONTENT_NEW_CUSTOMERS',
  'METRICS_FINANCE_CONTENT_EXPANSION_CUSTOMERS',
  'METRICS_FINANCE_CONTENT_CHURN_CUSTOMERS',
  'METRICS_FINANCE_SUMMARY_NEW_CUSTOMERS_MRR',
  ...FinancePanelName.options,
  ...FinanceDictionaryUniqueBlockName.options,
  ...FinanceFullBreakdownsBlocks.options,
  ...FinanceSummaryUniqueBlockName.options,
  ...CustomersFinanceUniqueBlockName.options,
]);
export type FinanceUniqueBlockName = z.infer<typeof FinanceUniqueBlockName>;

export const ProductPanelName = z.enum([
  'METRICS_PRODUCT_USERS',
  'METRICS_PRODUCT_COMPANIES',
  'METRICS_PRODUCT_ENGAGEMENT',
]);
export type ProductPanelName = z.infer<typeof ProductPanelName>;

export const ProductDictionaryContentUniqueName = z.enum([
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_CONTENT',
]);

export const ProductDictionarySwitchNames = z.enum([
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU',
]);

export type ProductDictionarySwitchNames = z.infer<
  typeof ProductDictionarySwitchNames
>;

export const ProductDictionarySummaryContentUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_SUMMARY_CONTENT',
]);

export const ProductDictionaryChartUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_PRODUCT_NEW_SIGN_UPS_CHART',
  'METRIC_DICTIONARY_PRODUCT_ACTIVE_USERS_CHART',
  'METRIC_DICTIONARY_PRODUCT_ENGAGED_USERS_CHART',
  'METRIC_DICTIONARY_PRODUCT_POWER_USERS_CHART',
  'METRIC_DICTIONARY_PRODUCT_WAU_MAU_CHART',
]);

export const ProductDictionaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_PRODUCT',
  ...ProductDictionaryContentUniqueName.options,
  ...ProductDictionarySwitchNames.options,
  ...ProductDictionarySummaryContentUniqueBlockName.options,
  ...ProductDictionarySummaryUniqueBlockName.options,
  ...ProductDictionaryChartUniqueBlockName.options,
]);

export const ProductFullBreakdownsBlockName = z.enum([
  'FULL_BREAKDOWNS_PRODUCT',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_SIGN_UP_DATE',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_ACTIVE_DAYS',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_INCREASE_IN_ACTIVITY',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_DECREASE_IN_ACTIVITY',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_ENGAGED_DAYS',
  'FULL_BREAKDOWNS_PRODUCT_BY_POWER_USERS_BY_JOIN_DATE',
  'FULL_BREAKDOWNS_PRODUCT_BY_POWER_USERS_BY_POWER_USER_EVENT_COUNT',
  'FULL_BREAKDOWNS_PRODUCT_COHORT_BY_NEW_SIGN_UPS',
  'FULL_BREAKDOWNS_PRODUCT_BY_USERS_ENGAGEMENT',
  'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_JOIN_DATE',
  'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_ACTIVE_DAYS',
  'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_INCREASE_IN_ACTIVITY',
  'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_DECREASE_IN_ACTIVITY',
  'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_ENGAGED_DAYS',
]);

export const ProductUniqueBlockName = z.enum([
  'MISSION_CONTROL_PRODUCT',
  'MISSION_CONTROL_SIGN_UPS_SUMMARY',
  'MISSION_CONTROL_SIGN_UPS_CHART',
  'MISSION_CONTROL_WAU_MAU_SUMMARY',
  'MISSION_CONTROL_WAU_MAU_CHART',

  'METRICS_PRODUCT',
  'METRICS_PRODUCT_SUMMARY',

  'METRICS_PRODUCT_SIGN_UPS',
  'METRICS_PRODUCT_SIGN_UPS_BREAKDOWN',

  'METRICS_PRODUCT_POWER_USERS',
  'METRICS_PRODUCT_POWER_USERS_BREAKDOWN',

  'METRICS_PRODUCT_ACTIVE_USERS',
  'METRICS_PRODUCT_ACTIVE_USERS_BREAKDOWN',

  'METRICS_PRODUCT_ENGAGED_USERS',
  'METRICS_PRODUCT_ENGAGED_USERS_BREAKDOWN',

  'METRICS_PRODUCT_WAU_MAU',
  'METRICS_PRODUCT_WAU_MAU_BREAKDOWN',

  'METRICS_PRODUCT_RECENTLY_SIGNED_UP',
  'METRICS_PRODUCT_BEST_RETENTION_COHORTS',
  'METRICS_PRODUCT_MOST_ACTIVE',
  'METRICS_PRODUCT_USERS_WITH_LARGEST_INCREASE_IN_ACTIVITY',
  'METRICS_PRODUCT_MOST_ENGAGED',
  'METRICS_PRODUCT_RECENTLY_ENGAGED',
  'METRICS_PRODUCT_NEW_POWER_USERS',
  'METRICS_PRODUCT_TOP_POWER_USERS',
  'METRICS_PRODUCT_RETENTION_COHORTS',
  'METRICS_PRODUCT_USERS_WITH_DECREASED_USAGE',

  'METRICS_PRODUCT_CONTENT_SIGN_UPS',
  'METRICS_PRODUCT_CONTENT_ACTIVE_USERS',
  'METRICS_PRODUCT_CONTENT_POWER_USERS',
  'METRICS_PRODUCT_CONTENT_ENGAGED_USERS',
  'METRICS_PRODUCT_CONTENT_WAU_MAU',
  'METRICS_PRODUCT_CONTENT_COMPANIES_SIGN_UPS',
  'METRICS_PRODUCT_CONTENT_ACTIVE_COMPANIES',
  'METRICS_PRODUCT_CONTENT_ENGAGED_COMPANIES',
  'METRICS_PRODUCT_CONTENT_POWER_COMPANIES',
  'METRICS_PRODUCT_CONTENT_WAC_MAC',
  'METRICS_PRODUCT_SUMMARY_COMPANIES',
  'METRICS_PRODUCT_COMPANIES_SIGN_UPS',
  'METRICS_PRODUCT_COMPANIES_SIGN_UPS_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_SIGNED_UP_COMPANIES',
  'METRICS_PRODUCT_COMPANIES_BEST_RETENTION_COHORTS',
  'METRICS_PRODUCT_ACTIVE_COMPANIES',
  'METRICS_PRODUCT_ACTIVE_COMPANIES_BREAKDOWN',
  'METRICS_PRODUCT_MOST_ACTIVE_COMPANIES',
  'METRICS_PRODUCT_COMPANIES_WITH_LARGEST_INCREASE_IN_ACTIVITY',
  'METRICS_PRODUCT_ENGAGED_COMPANIES',
  'METRICS_PRODUCT_ENGAGED_COMPANIES_BREAKDOWN',
  'METRICS_PRODUCT_MOST_ENGAGED_COMPANIES',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_COMPANIES',
  'METRICS_PRODUCT_COMPANIES_POWER_USERS',
  'METRICS_PRODUCT_COMPANIES_POWER_USERS_BREAKDOWN',
  'METRICS_PRODUCT_COMPANIES_TOP_POWER_USERS',
  'METRICS_PRODUCT_COMPANIES_NEW_POWER_USERS',
  'METRICS_PRODUCT_WAC_MAC',
  'METRICS_PRODUCT_WAC_MAC_BREAKDOWN',
  'METRICS_PRODUCT_COMPANY_RETENTION_COHORTS',
  'METRICS_PRODUCT_COMPANIES_WITH_DECREASED_USAGE',

  'METRICS_PRODUCT_CONTENT_SIGNAL_ONE',
  'METRICS_PRODUCT_SIGNAL_ONE',
  'METRICS_PRODUCT_SIGNAL_ONE_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_BY_SIGNAL_ONE',
  'METRICS_PRODUCT_MOST_ENGAGED_BY_SIGNAL_ONE',

  'METRICS_PRODUCT_CONTENT_SIGNAL_TWO',
  'METRICS_PRODUCT_SIGNAL_TWO',
  'METRICS_PRODUCT_SIGNAL_TWO_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_BY_SIGNAL_TWO',
  'METRICS_PRODUCT_MOST_ENGAGED_BY_SIGNAL_TWO',

  'METRICS_PRODUCT_CONTENT_SIGNAL_THREE',
  'METRICS_PRODUCT_SIGNAL_THREE',
  'METRICS_PRODUCT_SIGNAL_THREE_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_BY_SIGNAL_THREE',
  'METRICS_PRODUCT_MOST_ENGAGED_BY_SIGNAL_THREE',

  'METRICS_PRODUCT_CONTENT_SIGNAL_FOUR',
  'METRICS_PRODUCT_SIGNAL_FOUR',
  'METRICS_PRODUCT_SIGNAL_FOUR_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_BY_SIGNAL_FOUR',
  'METRICS_PRODUCT_MOST_ENGAGED_BY_SIGNAL_FOUR',

  'METRICS_PRODUCT_CONTENT_SIGNAL_FIVE',
  'METRICS_PRODUCT_SIGNAL_FIVE',
  'METRICS_PRODUCT_SIGNAL_FIVE_BREAKDOWN',
  'METRICS_PRODUCT_RECENTLY_ENGAGED_BY_SIGNAL_FIVE',
  'METRICS_PRODUCT_MOST_ENGAGED_BY_SIGNAL_FIVE',

  ...ProductPanelName.options,
  ...ProductDictionaryUniqueBlockName.options,
  ...ProductFullBreakdownsBlockName.options,
  ...ProductSummaryUniqueBlockName.options,
  ...CustomersProductUniqueBlockName.options,
]);
export type ProductUniqueBlockName = z.infer<typeof ProductUniqueBlockName>;

export const SalesPanelName = z.enum([
  'METRICS_SALES_VALUE',
  'METRICS_SALES_COMPANIES',
]);
export type SalesPanelName = z.infer<typeof SalesPanelName>;

const SalesMissionControlUniqueBlockName = z.enum([
  'MISSION_CONTROL_SALES',
  'MISSION_CONTROL_SALES_SUMMARY',
  'MISSION_CONTROL_SALES_SUMMARY_NEW_DEALS_ADDED',
  'MISSION_CONTROL_SALES_SUMMARY_TOTAL_SALES',
  'MISSION_CONTROL_SALES_PIPELINE_BREAKDOWN',
  'MISSION_CONTROL_CLOSED_DEALS_SUMMARY',
  'MISSION_CONTROL_CLOSED_DEALS_CHART',
  'MISSION_CONTROL_TOTAL_PIPELINE_SUMMARY',
  'MISSION_CONTROL_TOTAL_PIPELINE_CHART',
]);

export const SalesDictionaryContentUniqueName = z.enum([
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_CONTENT',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_CONTENT',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_CONTENT',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_CONTENT',
  'METRIC_DICTIONARY_SALES_WIN_RATE_CONTENT',
  'METRIC_DICTIONARY_SALES_VELOCITY_CONTENT',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CONTENT',
  'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS_CONTENT',
  'METRIC_DICTIONARY_SALES_STALLED_DEALS_CONTENT',
  'METRIC_DICTIONARY_SALES_NEW_DEALS_CONTENT',
]);

export const SalesDictionarySwitchNames = z.enum([
  'METRIC_DICTIONARY_SALES_TOTAL_SALES',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT',
  'METRIC_DICTIONARY_SALES_LOST_DEALS',
  'METRIC_DICTIONARY_SALES_WIN_RATE',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE',
  'METRIC_DICTIONARY_SALES_VELOCITY',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS',
  'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS',
  'METRIC_DICTIONARY_SALES_STALLED_DEALS',
  'METRIC_DICTIONARY_SALES_NEW_DEALS',
]);

export type SalesDictionarySwitchNames = z.infer<
  typeof SalesDictionarySwitchNames
>;

export const SalesDictionarySummaryContentUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_WIN_RATE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_VELOCITY_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_CLOSING_SOON_DEALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_STALLED_DEALS_SUMMARY_CONTENT',
  'METRIC_DICTIONARY_SALES_NEW_DEALS_SUMMARY_CONTENT',
]);

export const SalesDictionaryChartUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SALES_TOTAL_SALES_CHART',
  'METRIC_DICTIONARY_SALES_TOTAL_DEALS_CHART',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_AMOUNT_CHART',
  'METRIC_DICTIONARY_SALES_LOST_DEALS_CHART',
  'METRIC_DICTIONARY_SALES_WIN_RATE_CHART',
  'METRIC_DICTIONARY_SALES_AVERAGE_SALES_CYCLE_CHART',
  'METRIC_DICTIONARY_SALES_VELOCITY_CHART',
  'METRIC_DICTIONARY_SALES_PIPELINE_VALUE_CHART',
  'METRIC_DICTIONARY_SALES_PIPELINE_DEALS_CHART',
]);

const SalesDictionaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SALES',
  ...SalesDictionaryContentUniqueName.options,
  ...SalesDictionarySwitchNames.options,
  ...SalesDictionarySummaryContentUniqueBlockName.options,
  ...SalesDictionarySummaryUniqueBlockName.options,
  ...SalesDictionaryChartUniqueBlockName.options,
]);

const SalesFullBreakdownsUniqueBlockName = z.enum([
  'FULL_BREAKDOWNS_SALES',
  'FULL_BREAKDOWNS_SALES_BY_SALES_BY_LARGEST_VALUE',
  'FULL_BREAKDOWNS_SALES_BY_SALES_BY_MOST_RECENT',
  'FULL_BREAKDOWNS_SALES_BY_SALES_BY_TIME_TO_CLOSE',
  'FULL_BREAKDOWNS_SALES_BY_LOST_DEALS_BY_CLOSE_DATE',
  'FULL_BREAKDOWNS_SALES_BY_LOST_DEALS_BY_LARGEST_VALUE',
  'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_LARGEST_DEALS',
  'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_RECENT_DEALS',
  'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_STALLED_DEALS',
  'FULL_BREAKDOWNS_SALES_BY_PIPELINE_DEALS_BY_CLOSING_SOON',
  'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_VALUE',
  'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_WIN_RATE',
  'FULL_BREAKDOWNS_SALES_BY_SALES_OWNERS_BY_AVG_SALES_CYCLE',
  'FULL_BREAKDOWNS_SALES_BY_PIPELINE_STAGE',
]);

export type SalesFullBreakdownsUniqueBlockName = z.infer<
  typeof SalesFullBreakdownsUniqueBlockName
>;

export const SalesUniqueBlockName = z.enum([
  'METRICS_SALES',
  'METRICS_SALES_SUMMARY',
  'METRICS_SALES_SUMMARY_COMPANIES',
  'METRICS_SALES_CONTENT_WON_DEALS',
  'METRICS_SALES_WON_DEALS_COMPANIES',
  'METRICS_SALES_WON_DEALS_BREAKDOWN_COMPANIES',
  'METRICS_SALES_DEAL_BREAKDOWN_RECENTLY_CLOSED_COMPANIES',
  'METRICS_SALES_DEAL_BREAKDOWN_LARGEST_ACTIVE_ACCOUNTS_COMPANIES',
  'METRICS_SALES_CONTENT_AVG_DEAL_VALUE_COMPANIES',
  'METRICS_SALES_AVG_DEAL_VALUE_COMPANIES',
  'METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN_COMPANIES',
  'METRICS_SALES_AVG_DEAL_VALUE_CLOSING_SOON_BREAKDOWN_COMPANIES',
  'METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN_CLOSING_SOON',
  'METRICS_SALES_SUMMARY_AVG_DEAL_VALUE',
  'METRICS_SALES_AVG_DEAL_VALUE_STALLED_BREAKDOWN_COMPANIES',
  'METRICS_SALES_CONTENT_PIPELINE_DEALS_COUNT',
  'METRICS_SALES_PIPELINE_OVERVIEW_COMPANIES',
  'METRICS_SALES_PIPELINE_BREAKDOWN_COMPANIES',
  'METRICS_SALES_PIPELINE_BREAKDOWN_LATEST_ADDED_COMPANIES',
  'METRICS_SALES_PIPELINE_BREAKDOWN_SUMMARY_COMPANIES',
  'METRICS_SALES_CONTENT_WIN_RATE_COMPANIES',
  'METRICS_SALES_WIN_RATE_COMPANIES',
  'METRICS_SALES_WIN_RATE_BREAKDOWN_COMPANIES',
  'METRICS_SALES_WIN_RATE_BREAKDOWN_BY_DEAL_SIZE_COMPANIES',
  'METRICS_SALES_WIN_RATE_BREAKDOWN_RECENTLY_LOST_COMPANIES',
  'METRICS_SALES_CONTENT_AVG_SALES_CYCLE_COMPANIES',
  'METRICS_SALES_AVG_SALES_CYCLE_RATE_COMPANIES',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_COMPANIES',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_FASTEST_MOVING_COMPANIES',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_BY_OWNER_COMPANIES',
  'METRICS_SALES_AVG_DEAL_VALUE',
  'METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN_STALLED',
  'METRICS_SALES_AVG_DEAL_VALUE_BREAKDOWN',
  'METRICS_SALES_CONTENT_AVG_DEAL_VALUE',
  'METRICS_SALES_MONTHLY_SALES',
  'METRICS_SALES_DEAL_BREAKDOWN',
  'METRICS_SALES_DEAL_BREAKDOWN_RECENTLY_CLOSED',
  'METRICS_SALES_DEAL_BREAKDOWN_LARGEST_ACTIVE_ACCOUNTS',
  'METRICS_SALES_VELOCITY',
  'METRICS_SALES_VELOCITY_BREAKDOWN',
  'METRICS_SALES_VELOCITY_BREAKDOWN_CLOSING_SOON',
  'METRICS_SALES_VELOCITY_BREAKDOWN_STALLED',
  'METRICS_SALES_PIPELINE_OVERVIEW',
  'METRICS_SALES_PIPELINE_BREAKDOWN',
  'METRICS_SALES_PIPELINE_BREAKDOWN_LATEST_ADDED',
  'METRICS_SALES_PIPELINE_BREAKDOWN_SUMMARY',
  'METRICS_SALES_WIN_RATE',
  'METRICS_SALES_WIN_RATE_BREAKDOWN',
  'METRICS_SALES_WIN_RATE_BREAKDOWN_BY_DEAL_SIZE',
  'METRICS_SALES_WIN_RATE_BREAKDOWN_RECENTLY_LOST',
  'METRICS_SALES_AVG_SALES_CYCLE_RATE',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_FASTEST_MOVING',
  'METRICS_SALES_AVG_SALES_CYCLE_BREAKDOWN_BY_OWNER',

  'METRICS_SALES_CONTENT_SALES',
  'METRICS_SALES_CONTENT_VELOCITY',
  'METRICS_SALES_CONTENT_PIPELINE_VALUE',
  'METRICS_SALES_CONTENT_WIN_RATE',
  'METRICS_SALES_CONTENT_AVG_SALES_CYCLE',
  'METRICS_SALES_CONTENT_WON_DEALS',
  'METRICS_SALES_CONTENT_PIPELINE_DEALS_COUNT',
  'METRICS_SALES_CONTENT_AVG_DEAL_VALUE_COMPANIES',
  'METRICS_SALES_CONTENT_WIN_RATE_COMPANIES',
  'METRICS_SALES_CONTENT_AVG_SALES_CYCLE_COMPANIES',
  ...SalesPanelName.options,
  ...SalesSummaryUniqueBlockName.options,
  ...SalesMissionControlUniqueBlockName.options,
  ...SalesDictionaryUniqueBlockName.options,
  ...SalesFullBreakdownsUniqueBlockName.options,
]);
export type SalesUniqueBlockName = z.infer<typeof SalesUniqueBlockName>;

export const MetricDictionaryContentName = z.enum([
  ...GrowthDictionaryContentUniqueName.options,
  ...FinanceDictionaryContentUniqueName.options,
  ...ProductDictionaryContentUniqueName.options,
  ...SalesDictionaryContentUniqueName.options,
  ...SupportDictionaryContentUniqueName.options,
]);

export type MetricDictionaryContentName = z.infer<
  typeof MetricDictionaryContentName
>;

export const MetricDictionarySwitchName = z.enum([
  ...GrowthDictionarySwitchNames.options,
  ...FinanceDictionarySwitchNames.options,
  ...ProductDictionarySwitchNames.options,
  ...SalesDictionarySwitchNames.options,
  ...SupportDictionarySwitchNames.options,
]);

export type MetricDictionarySwitchName = z.infer<
  typeof MetricDictionarySwitchName
>;

export const MetricDictionaryChartName = z.enum([
  ...GrowthDictionaryChartUniqueBlockName.options,
  ...FinanceDictionaryChartUniqueBlockName.options,
  ...ProductDictionaryChartUniqueBlockName.options,
  ...SalesDictionaryChartUniqueBlockName.options,
]);

export type MetricDictionaryChartName = z.infer<
  typeof MetricDictionaryChartName
>;

export const MetricPanelName = z.enum([
  ...GrowthPanelName.options,
  ...FinancePanelName.options,
  ...SalesPanelName.options,
  ...ProductPanelName.options,
  ...SupportSummaryUniqueBlockName.options,
]);
export type MetricPanelName = z.infer<typeof MetricPanelName>;

export const MetricBlockName = z.union([
  GrowthUniqueBlockName,
  FinanceUniqueBlockName,
  SalesUniqueBlockName,
  ProductUniqueBlockName,
  SupportUniqueBlockName,
]);
export type MetricBlockName = z.infer<typeof MetricBlockName>;

export const BlockType = z.enum([
  'MISSION_CONTROL',
  'METRIC',
  'INSIGHT',
  'LAYOUT',
  'ANOMALY',
  'GOAL',
]);

export type BlockType = z.infer<typeof BlockType>;

export const Department = z.enum(DEPARTMENTS);
export type Department = z.infer<typeof Department>;
export const SubDepartment = z.enum(SUB_DEPARTMENTS);
export type SubDepartment = z.infer<typeof SubDepartment>;

export const BlockConfig = z.object({
  uniqueName: z.string().nullish(),
  company: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  charts: z.array(ChartConfig),
  // TODO: add tables as in blockRenderers service, maybe?
  breakdowns: z.array(BreakdownConfig),
  department: Department.nullable(),
  subDepartment: SubDepartment.nullish(),
  layoutTemplate: z.string().nullable(),
  layoutMetadata: z.record(z.unknown()).nullable(),
  type: BlockType,
  parent: z.string().nullable(),
  children: z.array(z.string()),
  staticContent: z.boolean(),
  data: BlockData,
});

export const BlockBase = BlockConfig.extend({
  id: z.string(),
  insertedAt: z.string(),
  updatedAt: z.string(),
});
export type BlockBase = z.infer<typeof BlockBase>;
export type BlockBaseInput = z.input<typeof BlockBase>;

const GrowthMetricBlock = BlockBase.extend({
  uniqueName: GrowthUniqueBlockName,
  type: z.union([
    z.literal('MISSION_CONTROL'),
    z.literal('METRIC'),
    z.literal('LAYOUT'),
  ]),
  department: z.literal('GROWTH'),
});
const FinanceMetricBlock = BlockBase.extend({
  uniqueName: FinanceUniqueBlockName,
  type: z.union([
    z.literal('MISSION_CONTROL'),
    z.literal('METRIC'),
    z.literal('LAYOUT'),
  ]),
  department: z.literal('FINANCE'),
});
const ProductMetricBlock = BlockBase.extend({
  uniqueName: ProductUniqueBlockName,
  type: z.union([
    z.literal('MISSION_CONTROL'),
    z.literal('METRIC'),
    z.literal('LAYOUT'),
  ]),
  department: z.literal('PRODUCT'),
});
const SalesMetricBlock = BlockBase.extend({
  uniqueName: SalesUniqueBlockName,
  type: z.union([
    z.literal('MISSION_CONTROL'),
    z.literal('METRIC'),
    z.literal('LAYOUT'),
  ]),
  department: z.literal('SALES'),
});
const SupportMetricBlock = BlockBase.extend({
  uniqueName: SupportUniqueBlockName,
  type: z.union([
    z.literal('MISSION_CONTROL'),
    z.literal('METRIC'),
    z.literal('LAYOUT'),
  ]),
  department: z.literal('SUPPORT'),
});
export const GoalBlock = BlockBase.extend({
  uniqueName: z.string(),
  type: z.literal(BlockType.enum.GOAL),
  department: Department,
});

export type GoalBlock = z.input<typeof GoalBlock>;

export const CustomerBlock = BlockBase.extend({
  uniqueName: CustomersUniqueBlockName,
  type: z.union([z.literal('LAYOUT'), z.literal('METRIC')]),
  department: Department.nullable(),
});

export type CustomerBlock = z.infer<typeof CustomerBlock>;

export const MetricBlock = z.union([
  GrowthMetricBlock,
  FinanceMetricBlock,
  ProductMetricBlock,
  SalesMetricBlock,
  SupportMetricBlock,
]);
export type MetricBlock = z.input<typeof MetricBlock>;
export const NonMetricBlock = BlockBase.extend({
  uniqueName: z.string().nullish(),
  type: z.union([z.literal('INSIGHT'), z.literal('ANOMALY')]),
});
export type NonMetricBlock = z.infer<typeof NonMetricBlock>;

export const MetricOrGoalBlock = z.union([MetricBlock, GoalBlock]);

export type MetricOrGoalBlock = z.infer<typeof MetricOrGoalBlock>;

export const Block = z.union([
  MetricBlock,
  NonMetricBlock,
  GoalBlock,
  CustomerBlock,
]);
export type Block = z.input<typeof Block>;

export type BlockWithChildren = Omit<Block, 'children'> & {
  children: BlockWithChildren[];
};

export const BlockWithChildren: z.ZodType<BlockWithChildren> = z.lazy(() =>
  Block.and(
    z.object({
      children: z.array(BlockWithChildren),
    }),
  ),
);

export type TBlockWithChildren<T extends Block> = Omit<T, 'children'> & {
  children: TBlockWithChildren<T>[];
};

export type MetricBlockWithChildren = Omit<MetricBlock, 'children'> & {
  children: MetricBlockWithChildren[];
};

export const MetricBlockWithChildren: z.ZodType<MetricBlockWithChildren> =
  z.lazy(() =>
    MetricBlock.and(
      z.object({
        children: z.array(MetricBlockWithChildren),
      }),
    ),
  );

export type BlocksByDepartment = Record<
  MetricOrGoalBlock['department'],
  MetricOrGoalBlock[]
> & {
  blocksExist: boolean;
};

export type BlocksById<T extends Block> = { [key: string]: T | undefined };

export type MetricBlocks = { root?: MetricBlockWithChildren };

export type Blocks<T extends Block> = { root?: TBlockWithChildren<T> };

export type BlockAtomMapping<T = unknown> = {
  fetchingAtom: WritableDataFetchingAtom<unknown, string>;
  dataAtom: DataAtom<{
    data: T;
    aggPeriod: AggregationPeriod;
  }>;
  name: MetricBlockName;
};

export const panelBySubpanel: {
  [key in MetricSummaryBlockName]: MetricPanelName;
} = Object.fromEntries([
  ...GrowthSummaryUniqueBlockName.options.map((subpanel) => [
    subpanel,
    'METRICS_GROWTH_EVENTS',
  ]),
  ...FinanceValueSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_FINANCE_VALUE',
  ]),
  ...FinanceCustomersSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_FINANCE_CUSTOMERS',
  ]),
  ...ProductUsersSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_PRODUCT_USERS',
  ]),
  ...ProductCompaniesSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_PRODUCT_COMPANIES',
  ]),
  ...ProductEngagementSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_PRODUCT_ENGAGEMENT',
  ]),
  ...SalesValueSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_SALES_VALUE',
  ]),
  ...SalesCompaniesSubpanels.options.map((subpanel) => [
    subpanel,
    'METRICS_SALES_COMPANIES',
  ]),
]);
