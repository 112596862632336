import { TagProps } from '@chakra-ui/tag';

import { Tag } from 'components/uikit';

export function CountTag(props: TagProps) {
  return (
    <Tag
      background={'grey.background'}
      color={'grey.secondaryText'}
      borderRadius='2px'
      height='18px'
      mx='4px'
      {...props}
    />
  );
}
