import { useAtom } from 'jotai';

import { CustomerUser } from 'data/customer/CustomerUser';
import { standaloneDrawerCustomerUser } from 'data/healthReports/atoms/standaloneDrawerCustomerUser';

export const useUserDrawerState = () => {
  const [data, setUser] = useAtom(standaloneDrawerCustomerUser);
  return {
    isOpen: !!data.user,
    onOpen: (user: CustomerUser) => setUser({ user }),
    onClose: () => setUser({}),
    user: data.user,
  };
};
