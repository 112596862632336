import { AiAnswerChart } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswerChart';
import { AiAnswerFooter } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswerFooter';
import { AiAnswerHeader } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswerHeader';
import { InsightActionResults } from 'components/pages/Radar/InsightActions';
import { Card, CardProps } from 'components/uikit';
import { Answer } from 'data/ai/models';

interface AiAnswerProps extends CardProps {
  answer: Answer;
}

export function AiAnswer({
  answer: { answer, description, actions, chartData },
  ...props
}: AiAnswerProps) {
  return (
    <Card boxShadow='none' {...props}>
      {answer ? (
        <AiAnswerHeader
          answer={answer}
          description={description}
          showSeparator={!!chartData || !!actions}
        />
      ) : null}
      {chartData ? <AiAnswerChart {...chartData} /> : null}
      {chartData ? <AiAnswerFooter config={chartData.config} /> : null}
      {actions ? <InsightActionResults actions={actions} /> : null}
    </Card>
  );
}
