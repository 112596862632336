import { useBreakdownDataReFetch } from 'data/blocks/hooks/useBreakdownDataReFetch';
import { useCustomerCompanyUserActivityBreakdownAtoms } from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';

export function useCustomerCompanyUserActivityBreakdownDataReFetch(
  companyId: string,
  blockId: string,
) {
  const atoms = useCustomerCompanyUserActivityBreakdownAtoms(companyId);

  useBreakdownDataReFetch(
    atoms.fetchingAtom,
    atoms.sortingAtom,
    atoms.paginationAtom,
    blockId,
  );
}
