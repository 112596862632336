import { z } from 'zod';

export const engagementSignalsArray = [
  'signal_1',
  'signal_2',
  'signal_3',
  'signal_4',
  'signal_5',
] as const;
export const onboardedSignal = ['onboarded'] as const;
const frameworkSteps = [
  'active',
  'sign_up',
  'onboarding_completed',
  'active_user',
  'power_user_event',
] as const;
export const SignalsSettingsParser = z.union([
  z.enum(engagementSignalsArray),
  z.enum(onboardedSignal),
]);

export const FrameworkStep = z.union([
  z.enum(frameworkSteps),
  z.enum(onboardedSignal),
  z.enum(engagementSignalsArray),
]);
export type FrameworkStep = z.infer<typeof FrameworkStep>;

export const ProductConfigFields = z.enum([
  'powerUserEventRange',
  'powerUserEventFrequency',
  'groupId',
  'eventPattern',
]);

export const ProductConfigRequestFields = z.enum([
  'power_user_event_range',
  'power_user_event_frequency',
]);

export type ProductConfigRequestFields = z.infer<
  typeof ProductConfigRequestFields
>;

export type ProductConfigFields = z.infer<typeof ProductConfigFields>;

export const ExclusionRule = z.enum([
  'user_id',
  'exact_email',
  'email_domain',
  'group_id',
  'country',
  'event_pattern',
]);
export type ExclusionRule = z.infer<typeof ExclusionRule>;

const ProductEventMapping = z.object({
  type: z.string(),
  description: z.string().nullable(),
  frameworkStep: FrameworkStep,
});
export type ProductEventMapping = z.infer<typeof ProductEventMapping>;

const UserExclusion = z.object({
  pattern: z.string(),
  type: ExclusionRule,
});
export type UserExclusion = z.infer<typeof UserExclusion>;

export const ExclusionUserCount = z.object({
  count: z.number(),
  type: ExclusionRule,
});
export type ExclusionUserCount = z.infer<typeof ExclusionUserCount>;

export const ProductSettings = z.object({
  eventMappings: ProductEventMapping.array(),
  showCompaniesSplit: z.boolean().default(false),
  config: z.record(ProductConfigFields, z.string()),
  excludedUsers: UserExclusion.array(),
  updating: z.boolean().default(false),
});
export type ProductSettings = z.infer<typeof ProductSettings>;
