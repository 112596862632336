import { WritableAtom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { SortArray } from 'data/charts/models/ChartsApiRequest';

export type BreakdownSortAtom = WritableAtom<
  SortArray,
  SortArray | typeof RESET,
  void
>;

export function getSortingAtom(initialValue: SortArray): BreakdownSortAtom {
  return atomWithReset<SortArray>(initialValue);
}
