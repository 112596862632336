import { z } from 'zod';

export const LinkType = z.enum([
  'PUBLIC',
  'EMAIL_PROTECTED',
  'PASSWORD_PROTECTED',
]);

export type LinkType = z.infer<typeof LinkType>;

export const SharingLink = z.object({
  id: z.string(),
  blockId: z.string(),
  createdBy: z.string(),
  type: LinkType,
  insertedAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type SharingLink = z.infer<typeof SharingLink>;
