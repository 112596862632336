import { createIcon } from '@chakra-ui/react';

export const CheckCircleIcon2 = createIcon({
  displayName: 'CheckCircleIcon2',
  viewBox: '-1 -1 22 22',
  path: (
    <>
      <rect width='20' height='20' fill='#D1FADF' rx='10' />
      <path
        fill='#12B76A'
        fillRule='evenodd'
        d='m14.247 6.158-5.966 5.759-1.584-1.692c-.291-.275-.75-.292-1.083-.058a.764.764 0 0 0-.217 1.008l1.875 3.05c.184.283.5.458.859.458.341 0 .666-.175.85-.458.3-.392 6.025-7.217 6.025-7.217.75-.766-.159-1.441-.759-.858v.008Z'
        clipRule='evenodd'
      />
    </>
  ),
});
