import { createIcon } from '@chakra-ui/react';

export const TagIcon = createIcon({
  displayName: 'TagIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M4.99984 5.0013H5.0065M14.0598 9.27463L9.27984 14.0546C9.15601 14.1786 9.00895 14.2769 8.84709 14.344C8.68523
         14.4111 8.51172 14.4457 8.3365 14.4457C8.16128 14.4457 7.98778 14.4111 7.82592 14.344C7.66405 14.2769 7.517
         14.1786 7.39317 14.0546L1.6665 8.33464V1.66797H8.33317L14.0598 7.39464C14.3082 7.64445 14.4476 7.98239 14.4476
         8.33464C14.4476 8.68688 14.3082 9.02482 14.0598 9.27463Z'
      fill='none'
      strokeWidth='1.33'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
