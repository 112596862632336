import { useTranslation } from 'react-i18next';

import { Skeleton, Text, VStack } from 'components/uikit';
import { FetchStatus } from 'utils/requests/types';
import { isLoading } from 'utils/types';

type Props = {
  value?: string;
  period: 'this_week' | 'this_month';
  fetchStatus: FetchStatus;
  align: 'left' | 'right';
};

export function ProductUsageStat({ period, value, fetchStatus, align }: Props) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs.drill_down_tab',
  });

  return (
    <VStack align={align === 'left' ? 'flex-start' : 'flex-end'} spacing={0}>
      {isLoading(fetchStatus) ? (
        <Skeleton height='20px' width='40px' />
      ) : (
        <Text
          fontSize='16px'
          fontWeight='semibold'
          color='grey.primaryText'
          lineHeight='150%'
        >
          {value ?? t('data_not_available')}
        </Text>
      )}
      <Text
        fontSize='12px'
        fontWeight='medium'
        lineHeight='150%'
        color='grey.tertiaryText'
      >
        {t(period)}
      </Text>
    </VStack>
  );
}
