import { useCallback, useRef } from 'react';

type Callback<T extends unknown[]> = (...args: T) => void;

type UseDoubleClickPayload<T extends unknown[], U extends unknown[]> = {
  onClick: Callback<T>;
  onDoubleClick: Callback<U>;
  delay?: number;
};

function useDoubleClick<T extends unknown[], U extends unknown[]>({
  onClick,
  onDoubleClick,
  delay = 200,
}: UseDoubleClickPayload<T, U>) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cancelPendingClick = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer]);

  const handleClick = useCallback(
    (...args: T) => {
      cancelPendingClick();
      timer.current = setTimeout(() => {
        timer.current = null;
        onClick(...args);
      }, delay);
    },
    [delay, timer, cancelPendingClick, onClick],
  );

  const handleDoubleClick = useCallback(
    (...args: U) => {
      cancelPendingClick();
      onDoubleClick(...args);
    },
    [cancelPendingClick, onDoubleClick],
  );

  return { handleClick, handleDoubleClick };
}

export default useDoubleClick;
