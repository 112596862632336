import { createIcon } from '@chakra-ui/react';

export const CheckboxIndeterminateIcon = createIcon({
  displayName: 'CheckboxIndeterminateIcon',
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1002 5.30005H1.9002C1.5002 5.30005 1.2002 5.60005 1.2002 6.00005C1.2002 6.40005 1.5002 6.70005 1.9002 6.70005H10.1002C10.5002 6.70005 10.8002 6.40005 10.8002 6.00005C10.8002 5.60005 10.5002 5.30005 10.1002 5.30005Z'
      fill='#1570EF'
    />
  ),
});
