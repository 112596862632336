import { createIcon } from '@chakra-ui/react';

export const HeartPulseIcon = createIcon({
  displayName: 'HeartPulseIcon',
  viewBox: '0 0 24 25',
  path: (
    <path
      d='M15.5 12H14.5L13 15L11 9L9.5 12H8.5M11.9932 5.63581C9.9938 3.2984 6.65975 2.66964 4.15469 4.81001C1.64964
         6.95038 1.29697 10.529 3.2642 13.0604C4.75009 14.9724 8.97129 18.811 10.948 20.5749C11.3114 20.8991 11.4931
         21.0613 11.7058 21.1251C11.8905 21.1805 12.0958 21.1805 12.2805 21.1251C12.4932 21.0613 12.6749 20.8991 13.0383
         20.5749C15.015 18.811 19.2362 14.9724 20.7221 13.0604C22.6893 10.529 22.3797 6.92787 19.8316 4.81001C17.2835
         2.69216 13.9925 3.2984 11.9932 5.63581Z'
      stroke='currentColor'
      fill='none'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
