import { useTranslation } from 'react-i18next';

import { PieChart } from 'components/charts/PieChart/PieChart';
import { CenteredMetric } from 'components/CustomerDrawer/CompanyDrawer/charts/CenteredMetric';
import { UserSegmentsTable } from 'components/CustomerDrawer/CompanyDrawer/charts/UserSegmentsTable';
import { Card, HStack } from 'components/uikit';
import {
  BreakdownBySegmentsRow,
  UserSegments,
  UserSegmentsBreakdownRow,
} from 'data/charts/atoms/product/users/breakdowns';
import { useCustomerCompanyUserSegmentsFetchedData } from 'data/healthReports/hooks/company/useCustomerCompanyUserSegmentsFetchedData';
import { getSegmentColorVar } from 'data/healthReports/utils/userSegmentIndicator';
import { isLoading } from 'utils/types';

const getSegmentData = (
  data: BreakdownBySegmentsRow,
  segment: UserSegments,
): UserSegmentsBreakdownRow => {
  switch (segment) {
    case 'NEW':
      return { segment, count: data.new, delta: data.newDelta };
    case 'ACTIVE':
      return { segment, count: data.active, delta: data.activeDelta };
    case 'ENGAGED':
      return { segment, count: data.engaged, delta: data.engagedDelta };
    case 'POWER':
      return { segment, count: data.power, delta: data.powerDelta };
    case 'INACTIVE':
      return { segment, count: data.inactive, delta: data.inactiveDelta };
  }
};

const getSegmentRows = (
  data: BreakdownBySegmentsRow,
): UserSegmentsBreakdownRow[] => {
  return UserSegments.options.map((segment) => getSegmentData(data, segment));
};

interface SegmentPieChartProps {
  customerCompanyId: string;
  blockId: string;
}

export function SegmentPieChartWithBreakdown({
  customerCompanyId,
  blockId,
}: SegmentPieChartProps) {
  const { t } = useTranslation('breakdowns', {
    keyPrefix: 'segments',
  });
  const { data, fetchStatus } =
    useCustomerCompanyUserSegmentsFetchedData(customerCompanyId);

  const segmentData = data.rows?.[0];

  if (!segmentData || isLoading(fetchStatus)) {
    return null;
  }

  const pieChartData = UserSegments.options.map((segment) => {
    const seg = getSegmentData(segmentData, segment);
    return {
      id: segment,
      label: t(segment),
      value: seg.count,
      chunkColor: getSegmentColorVar(segment),
      data: seg,
    };
  });
  return (
    <Card>
      <HStack>
        <PieChart data={pieChartData} additionalLayers={[CenteredMetric]} />
        <UserSegmentsTable
          blockId={blockId}
          data={{
            rows: getSegmentRows(segmentData),
            totalCount: 5,
          }}
        />
      </HStack>
    </Card>
  );
}
