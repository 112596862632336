import { Department } from 'data/blocks/models/Block';
import { DepartmentIdsArrays } from 'data/customer/DepartmentIdsArrays';

export function getDepartmentIdFromCustomer(customer?: DepartmentIdsArrays): {
  department?: Department;
  departmentId?: string;
} {
  if (!customer) {
    return { department: undefined, departmentId: undefined };
  }

  if (customer.productIds.length > 0) {
    return {
      department: 'PRODUCT',
      departmentId: customer.productIds[0],
    };
  }

  if (customer.financeIds.length > 0) {
    return {
      department: 'FINANCE',
      departmentId: customer.financeIds[0],
    };
  }

  return { department: undefined, departmentId: undefined };
}
