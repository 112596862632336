import { AnalyticsEventType, AnalyticsEventGroup } from 'utils/analytics';

export class GoalsAnalyticsEvents extends AnalyticsEventType {
  static ADD_GOAL_CLICKED = new GoalsAnalyticsEvents('goals-add-clicked');
  static GOALS_CREATED = new GoalsAnalyticsEvents('goals-created');
  static GOALS_UPDATED = new GoalsAnalyticsEvents('goals-updated');

  private constructor(readonly action: string) {
    super(action, AnalyticsEventGroup.GOALS);
  }
}
