import { z } from 'zod';

import { Department, MetricBlockName } from 'data/blocks/models/Block';
// TODO: redo models before adding blocks
export const CustomerCommandPanelOptionType = z.enum(['USER', 'COMPANY']);
export type CustomerCommandPanelOptionType = z.infer<
  typeof CustomerCommandPanelOptionType
>;
export const ActionCommandPanelOptionType = z.enum([
  'ASK_AI',
  'INVITE_TEAM_MEMBER',
  'ADD_DATA_SOURCE',
  'VIEW_INSIGHTS',
  'METRICS_DICTIONARY',
  'CREATE_REPORT',
  'CREATE_ALERT',
]);
export type ActionCommandPanelOptionType = z.infer<
  typeof ActionCommandPanelOptionType
>;

export const CommandPanelOptionType = z.union([
  CustomerCommandPanelOptionType,
  z.enum(['ACTION']),
  z.enum(['BETA']),
  z.enum(['METRIC', 'INSIGHT']),
]);

export type CommandPanelOptionType = z.infer<typeof CommandPanelOptionType>;

export const CustomerCommandPanelOption = z.object({
  id: z.string(),
  department: Department,
  departmentId: z.string(),
  type: CustomerCommandPanelOptionType,
  name: z.string(),
});

export const BlockCommandPanelOption = z.object({
  id: z.string(),
  department: Department,
  uniqueName: MetricBlockName,
  type: z.literal('METRIC'),
  title: z.string(),
});

export type BlockCommandPanelOption = z.infer<typeof BlockCommandPanelOption>;

export const InsightCommandPanelOption = z.object({
  id: z.string(),
  uniqueName: z.string().nullish(),
  type: z.literal('INSIGHT'),
  title: z.string(),
  description: z.string().nullish(),
  insertedAt: z.coerce.date(),
  insightId: z.string(),
});
export type InsightCommandPanelOption = z.infer<
  typeof InsightCommandPanelOption
>;

export type CustomerCommandPanelOption = z.infer<
  typeof CustomerCommandPanelOption
>;
export const BreakdownLinkCommandPanelOption = z.object({
  title: z.string(),
  link: z.string(),
  type: z.literal('LEADERBOARD'),
  department: Department,
});
export type BreakdownLinkCommandPanelOption = z.infer<
  typeof BreakdownLinkCommandPanelOption
>;

export const ActionCommandPanelOption = z.object({
  type: z.literal('ACTION'),
  action: ActionCommandPanelOptionType,
});
export type ActionCommandPanelOption = z.infer<typeof ActionCommandPanelOption>;
export const CommandPanelOption = z.union([
  CustomerCommandPanelOption,
  ActionCommandPanelOption,
  BlockCommandPanelOption,
  InsightCommandPanelOption,
  BreakdownLinkCommandPanelOption,
]);

export type CommandPanelOption = z.infer<typeof CommandPanelOption>;

export const CustomersAndBlocksSearchResult = z.object({
  USER: z.array(CustomerCommandPanelOption),
  COMPANY: z.array(CustomerCommandPanelOption),
  METRIC: z.array(BlockCommandPanelOption),
  INSIGHT: z.array(InsightCommandPanelOption),
  LEADERBOARD: z.array(BreakdownLinkCommandPanelOption),
});

export type CustomersAndBlocksSearchResult = z.infer<
  typeof CustomersAndBlocksSearchResult
>;

export const CommandPanelSearchResult = CustomersAndBlocksSearchResult.merge(
  z.object({
    BETA: z.array(ActionCommandPanelOption),
    ACTION: z.array(ActionCommandPanelOption),
  }),
);

export type CommandPanelSearchResult = z.infer<typeof CommandPanelSearchResult>;
