import { sum } from 'lodash';
import { useTranslation } from 'react-i18next';

import { TooltipContentDataEntry } from 'components/charts/common/TooltipContentDataEntry';
import { getSentimentColor } from 'components/uikit/sentimentColor';
import { BarData } from 'data/charts/models/BarData';
import { Currency, formatWholeNumber } from 'utils/formatting/number';

import { Box } from '../uikit';

type FinanceTooltipContentProps = {
  data: BarData;
  colors: string[];
  getKeyColor?: (key: string) => string;
  currency: Currency | null;
};

export const FinanceTooltipContent = ({
  data,
  colors,
  getKeyColor,
  currency,
}: FinanceTooltipContentProps) => {
  const { t } = useTranslation('mission-control');
  const { id, ...values } = data;

  const isSplitData = Object.keys(values).length > 1; // We assume single values do not come from split data
  const netValue = sum(Object.values(values).map(Number));

  return (
    <Box>
      {isSplitData &&
        Object.entries(values).map(([key, value], idx) => (
          <TooltipContentDataEntry
            key={key}
            boxColor={
              getKeyColor ? getKeyColor(key) : colors[idx % colors.length]
            }
            label={key}
            value={formatWholeNumber(Number(value), currency)}
            valueColor={getSentimentColor(Number(value))}
          />
        ))}
      <TooltipContentDataEntry
        label={
          isSplitData ? t('label_mrr_net_by_month') : Object.keys(values)[0]
        }
        value={formatWholeNumber(netValue, currency)}
        valueColor={getSentimentColor(netValue)}
      />
    </Box>
  );
};
