import { Skeleton as ChakraSkeleton, SkeletonProps } from '@chakra-ui/react';

type Props = {
  hasError?: boolean;
} & SkeletonProps;

export function Skeleton({ hasError, ...skeletonProps }: Props) {
  return (
    <ChakraSkeleton
      startColor={'grey.border'}
      endColor={hasError ? 'grey.border' : 'grey.offWhite'}
      {...skeletonProps}
    />
  );
}
