import { HStack, Key, Text } from 'components/uikit';

export function CommandPanelShortcut() {
  return (
    <HStack spacing='4px' p='0'>
      <Key
        isOutlined
        style='normal'
        size='16px'
        borderRadius='2px'
        enforceDark
        element={
          <Text fontSize='12px' color='grey.secondaryText.dark'>
            ⌘
          </Text>
        }
      />
      <Key
        isOutlined
        style='normal'
        size='16px'
        borderRadius='2px'
        enforceDark
        element={
          <Text fontSize='12px' color='grey.secondaryText.dark'>
            K
          </Text>
        }
      />
    </HStack>
  );
}
