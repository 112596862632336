import { PropsWithChildren, ReactNode } from 'react';

import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalFooter as ChakraModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  HStack,
  Flex,
} from '@chakra-ui/react';

import { BoxProps } from './reexport';

export interface ModalContentProps {
  header?: ReactNode;
  subheader?: ReactNode;
  body: ReactNode;
  width?: BoxProps['width'];
  footer?: ReactNode;
  footerStyle?: ModalFooterProps;
  noCloseButton?: boolean;
}

interface ModalWrapperProps extends ChakraModalProps {
  maxWidth: BoxProps['maxWidth'];
  h?: BoxProps['h'];
  w?: BoxProps['w'];
  transparentOverlay?: boolean;
  trapFocus?: boolean;
  border?: BoxProps['border'];
  borderLeft?: BoxProps['borderLeft'];
  borderLeftColor?: BoxProps['borderLeftColor'];
}

interface ModalProps
  extends Omit<ModalWrapperProps, 'children'>,
    ModalContentProps {}

export const ModalFooterWrapper = ({ children }: PropsWithChildren) => (
  <HStack
    bgColor='grey.offWhite'
    px={4}
    h='40px'
    flexGrow={1}
    gap='2px'
    align='center'
    borderTopWidth='2px'
    borderTopColor='grey.border'
    fontWeight='regular'
    fontSize='sm'
  >
    {children}
  </HStack>
);

export const ModalContent = ({
  header,
  subheader,
  body,
  footer,
  footerStyle,
  width = '100%',
  noCloseButton = false,
}: ModalContentProps) => {
  const defaultFooterStyle = {
    minH: '73px',
    borderBottomRadius: '8px',
    overflow: 'hidden',
  } as ModalFooterProps;
  const footerProps = { ...defaultFooterStyle, ...footerStyle };

  return (
    <>
      {header ? (
        <ModalHeader
          borderBottomWidth='2px'
          borderBottomColor='grey.border'
          minWidth={width}
          width={width}
          display='flex'
          flexDirection='column'
          padding='0'
        >
          <Flex padding={subheader ? '24px 24px 16px 24px' : '24px'}>
            {header}
            {noCloseButton ? null : (
              <ModalCloseButton
                width='24px'
                height='24px'
                borderRadius='4px'
                size='sm'
                right='24px'
              />
            )}
          </Flex>
          {subheader}
        </ModalHeader>
      ) : null}
      <ModalBody p='0' minWidth={width} width={width}>
        {body}
      </ModalBody>
      {footer ? (
        <ChakraModalFooter minWidth={width} width={width} {...footerProps}>
          {footer}
        </ChakraModalFooter>
      ) : null}
    </>
  );
};

export const ModalWrapper = ({
  isOpen,
  onClose,
  maxWidth,
  h,
  w,
  children,
  isCentered,
  transparentOverlay = false,
  trapFocus = true,
  border,
  borderLeft,
  borderLeftColor,
}: ModalWrapperProps) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      trapFocus={trapFocus}
    >
      <ModalOverlay
        bg={transparentOverlay ? 'rgba(0,0,0,0)' : 'rgba(36, 45, 60, 0.9)'}
      />

      <ChakraModalContent
        h={h}
        w={w}
        maxWidth={maxWidth}
        boxShadow='0px 4px 8px -2px #1018281A'
        borderColor='grey.border'
        border={border ?? '1px solid'}
        borderLeft={borderLeft}
        borderLeftColor={borderLeftColor}
        bgColor='grey.white'
      >
        {children}
      </ChakraModalContent>
    </ChakraModal>
  );
};

export const Modal = ({
  isOpen,
  onClose,
  maxWidth,
  h,
  transparentOverlay,
  isCentered,
  ...contentProps
}: ModalProps) => {
  return (
    <ModalWrapper
      transparentOverlay={transparentOverlay}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      isCentered={isCentered}
      h={h}
    >
      <ModalContent {...contentProps} />
    </ModalWrapper>
  );
};
