import { FinanceRevenueCard } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/FinanceRevenueCard';
import { ProductUsageCard } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/ProductUsageCard';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { TabPanel, VStack } from 'components/uikit';
import { hideScrollbar } from 'styles/hideScrollbar';

export function DrillDownTabPanel({
  customerCompanyId,
}: {
  customerCompanyId: string;
}) {
  const { showAnyDepartmentBlocks, showProductBlocks, showFinanceBlocks } =
    useShowDepartmentBlocksForCompany(customerCompanyId);

  if (!showAnyDepartmentBlocks) {
    return null;
  }

  return (
    <TabPanel height={'100%'}>
      <VStack
        p='16px'
        background={'grey.offWhite'}
        alignItems='stretch'
        height={'100%'}
        overflowY={'scroll'}
        css={hideScrollbar}
      >
        {showFinanceBlocks ? (
          <FinanceRevenueCard customerId={customerCompanyId} />
        ) : null}
        {showProductBlocks ? (
          <ProductUsageCard customerId={customerCompanyId} />
        ) : null}
      </VStack>
    </TabPanel>
  );
}
