// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from 'react';

import { CustomLayer, CustomLayerProps, Point } from '@nivo/line';
import { useTooltip } from '@nivo/tooltip';
import { TooltipAnchor } from '@nivo/tooltip/dist/types/types';

import { SeriesLine } from 'components/charts/Layers/LinesLayer/SeriesLine';
import { LineMaskLayer } from 'components/charts/Layers/MaskLayer';
import {
  LineChartTooltip,
  LineChartTooltipProps,
} from 'components/charts/LineChart/LineChartTooltip';
import { GetSeriesItemColorFn } from 'utils/lineChartUtils';

interface TooltipExternalProps
  extends Omit<LineChartTooltipProps, 'slice' | 'axis' | 'selectedSeriesIds'> {
  tooltipAnchor?: TooltipAnchor;
}

export const makeLinesLayer = (
  tooltipExternalProps: TooltipExternalProps,
  lastLineDotted: boolean,
  getSeriesColor: GetSeriesItemColorFn | undefined,
): CustomLayer => {
  function LinesLayer(props: CustomLayerProps) {
    const [selectedSeriesIds, setSelectedSeriesIds] = useState<Array<string>>(
      [],
    );
    const [hoveredSerieId, setHoveredSerieId] = useState<string | null>(null);
    const [selectedSliceId, setSelectedSliceId] = useState<number | null>(null);
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const showTooltip = (
      event: React.MouseEvent,
      tooltipProps?: LineChartTooltipProps = {},
    ) => {
      showTooltipFromEvent(
        <LineChartTooltip
          slice={props.currentSlice}
          axis={props.enableSlices}
          selectedSeriesIds={selectedSeriesIds}
          {...tooltipExternalProps}
          {...tooltipProps}
        />,
        event,
        tooltipExternalProps.tooltipAnchor ?? 'auto',
      );
    };

    const lines = props.series.map(({ id, data, color, isReference }) => {
      const isSelected = selectedSeriesIds.includes(String(id));
      const isHovered = hoveredSerieId === String(id);

      const points = data
        .filter(({ data }) => data.y !== undefined)
        .map(({ data }) => ({
          x: props.xScale(data.x ?? 0),
          y: props.yScale(data.y ?? 0),
        }));

      const seriesColor = data.map(
        (_, index) => getSeriesColor?.({ id, index }) ?? color ?? '',
      );

      const isHighlighted = (() => {
        if (isSelected || isHovered) return true;
        return hoveredSerieId === null && selectedSeriesIds.length === 0;
      })();

      return (
        <SeriesLine
          key={id}
          points={points}
          lineGenerator={props.lineGenerator}
          thickness={2}
          colors={seriesColor}
          onMouseOver={(event) => {
            setHoveredSerieId(String(id));
            if (!selectedSliceId) showTooltip(event);
          }}
          onMouseLeave={() => {
            setHoveredSerieId(null);
          }}
          onEvent={(event) => showTooltip(event)}
          onClick={(event) => {
            // toggle the line's select status
            const newSelectedSeriesIds = isSelected
              ? selectedSeriesIds?.filter((seriesId) => seriesId !== String(id))
              : [...selectedSeriesIds, String(id)];
            setSelectedSeriesIds(newSelectedSeriesIds);
            // immediately show the tooltip with the new selected line state
            showTooltip(event, { selectedSeriesIds: newSelectedSeriesIds });
          }}
          isDashed={isReference && lastLineDotted}
          lastLineDotted={lastLineDotted}
          opacity={isHighlighted ? 1 : 0.3}
        />
      );
    });

    const selectedPoints = props.currentSlice?.points?.map((point: Point) => {
      return (
        <circle
          key={point.id}
          cx={point.x}
          cy={point.y}
          r={5}
          fill={point.serieColor}
          style={{ cursor: 'pointer' }}
          onMouseOver={(event) => {
            const matchingSerieId = props.series.find(
              ({ id }) => id === point.serieId,
            )?.id;
            setHoveredSerieId(String(matchingSerieId));
            if (!selectedSliceId) showTooltip(event);
          }}
          onMouseLeave={() => {
            setHoveredSerieId(null);
          }}
          onClick={(event) => {
            setSelectedSliceId(props.currentSlice.id);
            showTooltip(event);
          }}
        />
      );
    });

    const slices = props.slices.map((slice, index) => (
      <rect
        key={index}
        x={slice.x0}
        y={slice.y0}
        width={slice.width}
        height={slice.height}
        stroke='red'
        strokeWidth={0}
        strokeOpacity={0.75}
        fill='red'
        fillOpacity={0}
        onMouseEnter={() => {
          if (selectedSliceId) return;
          props.setCurrentSlice(slice);
        }}
        onClick={() => {
          if (selectedSliceId) {
            setSelectedSliceId(null);
            props.setCurrentSlice(slice);
          } else {
            setSelectedSliceId(slice.id);
            props.setCurrentSlice(slice);
          }
        }}
        onMouseMove={(event) => {
          if (selectedSliceId) return;
          showTooltip(event);
        }}
        onMouseLeave={hideTooltip}
      />
    ));

    return (
      <>
        {slices}
        {lines}
        <LineMaskLayer {...props} />
        {selectedPoints}
      </>
    );
  }

  return LinesLayer;
};
