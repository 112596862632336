import { z } from 'zod';

export const FinanceGoalMetric = z.enum(['MRR', 'ARR', 'CUSTOMERS']);
export type FinanceGoalMetric = z.infer<typeof FinanceGoalMetric>;

export const GrowthGoalMetric = z.enum(['LEADS']);
export type GrowthGoalMetric = z.infer<typeof GrowthGoalMetric>;

export const ProductGoalMetric = z.enum(['TOTAL_USERS']);
export type ProductGoalMetric = z.infer<typeof ProductGoalMetric>;

export const SalesGoalMetric = z.enum(['TOTAL_SALES']);
export type SalesGoalMetric = z.infer<typeof SalesGoalMetric>;

export const GoalMetric = z.enum([
  ...FinanceGoalMetric.options,
  ...GrowthGoalMetric.options,
  ...ProductGoalMetric.options,
  ...SalesGoalMetric.options,
]);
export type GoalMetric = z.infer<typeof GoalMetric>;

export const GOAL_METRICS_BY_DEPARTMENT = {
  FINANCE: FinanceGoalMetric.options,
  GROWTH: GrowthGoalMetric.options,
  PRODUCT: ProductGoalMetric.options,
  SALES: SalesGoalMetric.options,
  SUPPORT: [],
};
