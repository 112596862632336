import { createRef, RefObject } from 'react';

import { atom } from 'jotai';

export const navRefAtom = atom<RefObject<HTMLElement>>(
  createRef<HTMLElement>(),
);

export const bodyRefAtom = atom<RefObject<HTMLElement>>(
  createRef<HTMLElement>(),
);

export const isSideMenuOpenAtom = atom<boolean>(false);
