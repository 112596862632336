import { createIcon } from '@chakra-ui/react';

export const ProhibitedCircleIcon = createIcon({
  displayName: 'ProhibitedCircleIcon',
  viewBox: '0 0 20 20',
  path: (
    <g fill='#FEF3F2'>
      <rect width='20' height='20' rx='10' fill='#FEF3F2' />
      <path
        d='M10 14.5C12.4853 14.5 14.5 12.4853 14.5 10C14.5 7.51472 12.4853 5.5 10 5.5C7.51472 5.5 5.5 7.51472 5.5 10C5.5 12.4853 7.51472 14.5 10 14.5Z'
        stroke='#F04438'
        strokeMiterlimit='10'
      />
      <path
        d='M6.81738 6.81714L13.183 13.1828'
        stroke='#F04438'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
});
