import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from 'components/uikit';
import { BarData } from 'data/charts/models/BarData';
import { formatWholeNumber } from 'utils/formatting/number';
import { mapValues } from 'utils/helpers';

// TODO: fix types
type BarChartTooltipProps = {
  data: BarData;
  colors: string[];
  calculateNet?: boolean;
  sortKeys: (keys: string[]) => string[];
};
export const BarChartTooltip = ({
  data,
  colors,
  sortKeys,
  calculateNet = true,
}: BarChartTooltipProps) => {
  const { t } = useTranslation('mission-control');
  const { id, ...values } = data;

  const formattedValues = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const net: { net: number } | {} = calculateNet
      ? {
          net: Object.values(values).reduce(
            (acc, value) => Number(acc) + Number(value),
            0,
          ),
        }
      : {};
    const valuesObject = {
      ...values,
      ...net,
    };
    return mapValues(
      (_, value) => formatWholeNumber(Number(value)),
      valuesObject,
    );
  }, [values, calculateNet]);
  return (
    <Box>
      {sortKeys(Object.keys(values)).map((key, idx) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = formattedValues[key];
        if (!value) return null;
        return (
          <Flex
            key={key}
            flexDirection='row'
            justify='space-between'
            gap='16px'
          >
            <Flex flexDirection='row' alignItems='center'>
              <Box
                display='inline-block'
                backgroundColor={colors[idx % colors.length]}
                width='8px'
                height='8px'
                mr='4px'
                borderRadius='2px'
              />
              <Text
                color='grey.primaryText'
                fontSize='14px'
                lineHeight='18px'
                maxW='210px'
                overflow='hidden'
                overflowX='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                {key}
              </Text>
            </Flex>

            <Text
              color='grey.primaryText'
              fontSize='14px'
              fontWeight='medium'
              variant='numeric'
              lineHeight='18px'
            >
              {value}
            </Text>
          </Flex>
        );
      })}
      {calculateNet ? (
        <Flex key='Net' flexDirection='row' justify='space-between'>
          <Flex flexDirection='row' alignItems='center'>
            <Text fontSize='12px' lineHeight='18px' fontWeight='medium'>
              {t('label_mrr_net_by_month')}
            </Text>
          </Flex>

          <Text
            fontSize='12px'
            fontWeight='medium'
            variant='numeric'
            lineHeight='18px'
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {formattedValues['net']}
          </Text>
        </Flex>
      ) : null}
    </Box>
  );
};

export const BarChartSingleLineTooltip = ({
  data,
  colors,
  sortKeys,
}: BarChartTooltipProps) => {
  return (
    <BarChartTooltip
      data={data}
      colors={colors}
      calculateNet={false}
      sortKeys={sortKeys}
    />
  );
};
