import { HStack, Text, Th } from 'components/uikit';
import { SortArrowDown } from 'components/uikit/icons/SortArrowDown';
import { SortArrowUp } from 'components/uikit/icons/SortArrowUp';
import { SortIcon } from 'components/uikit/icons/SortIcon';
import { Direction, SortArray } from 'data/charts/models/ChartsApiRequest';
import { ColumnConfig } from 'data/charts/models/TableConfig';

type HeaderCellProps<T> = {
  cell: ColumnConfig<T>;
  options: SortArray;
  handleSort: (accessor: keyof T, direction: Direction) => void;
  removeSort: (accessor: keyof T) => void;
};

export function HeaderCell<T>({
  cell,
  handleSort,
  removeSort,
  options,
}: HeaderCellProps<T>) {
  const selectedSort = options.find(
    (option) => option.column === cell.accessor,
  );

  function handleClick() {
    console.debug('click', selectedSort);
    if (!selectedSort) {
      console.debug('apply sort', cell.accessor);
      handleSort(cell.accessor, 'ASC');
    } else {
      switch (selectedSort.direction) {
        case 'ASC':
          handleSort(cell.accessor, 'DESC');
          break;
        case 'DESC':
          removeSort(cell.accessor);
          break;
      }
    }
  }

  return (
    <Th
      onClick={cell.sortable ? handleClick : undefined}
      cursor={cell.sortable ? 'pointer' : undefined}
      textTransform='none'
      maxWidth={cell.style?.width}
      minWidth={cell.style?.width}
      whiteSpace='break-spaces'
      _hover={cell.sortable ? { background: 'grey.background' } : {}}
      paddingX='16px'
    >
      <HStack
        alignItems='center'
        justifyContent={
          cell.style?.align === 'right' ? 'flex-end' : 'flex-start'
        }
      >
        <Text
          fontSize='xxs'
          fontWeight='600'
          color={selectedSort ? 'secondary.button' : 'grey.primaryText'}
        >
          {cell.header}
        </Text>
        {cell.sortable && <SortState direction={selectedSort?.direction} />}
      </HStack>
    </Th>
  );
}

function SortState({ direction }: { direction?: Direction }) {
  switch (direction) {
    case 'ASC':
      return <SortArrowUp stroke='secondary.button' />;
    case 'DESC':
      return <SortArrowDown stroke='secondary.button' />;
    default:
      return <SortIcon stroke='grey.primaryText' />;
  }
}
