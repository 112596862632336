import { createIcon } from '@chakra-ui/react';

export const PipedriveMenuIcon = createIcon({
  displayName: 'PipedriveMenuIcon',
  viewBox: '0 0 40 40',
  path: (
    <>
      <mask
        id='mask0_19114_17463'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='40'
        height='41'
      >
        <circle cx='20' cy='20.5' r='20' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0_19114_17463)'>
        <rect
          x='-3.26172'
          y='-5.58594'
          width='46.5217'
          height='52.1739'
          fill='url(#pattern0_pd)'
        />
      </g>
      <defs>
        <pattern
          id='pattern0_pd'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use
            xlinkHref='#image0_19114_17463'
            transform='scale(0.0046729 0.00416667)'
          />
        </pattern>
        <image
          id='image0_19114_17463'
          width='214'
          height='240'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANYAAADwCAMAAABLyGo3AAAAP1BMVEVHcEwlKCwgISMkKCslKComKiwmKSskKCgmKSwlKSwlKComKSz///92eHqWl5nLzM08PkHq6+tYWlyxs7SFhoiVaIIOAAAAC3RSTlMAwxBDZO+jIN+EMPn/ABUAAAUTSURBVHja7Z3bmqsgDIVBQLGhouj7P+u+2IfuVm2tckgyrNuZC//PtWKIVITIoEb3rTUAYGWvOsFCjZbwJKnpkzW9gZVMSxxsCwoAwPaEoZSFXVlNFKqT8FYkndj08FH0wLSBAyLmRCXhoKxiEyqSTmx6A9+pb7iEiljElIRTspid2LRwWmgj9n2onhtFnP3Uu06JbMRuEiIImROPdEr0wLSBaELjxOuhQthPdRKiq7gT44XqpZ/quIQKTcSUhISytzKhaiGxCkTsYqd0NGLEizqKiKUN1csMuCNe1MtGLEuocjsxV6iygnUSCimhE3OHKk8/pQ0UVRInKgnFFX3Jkr5TKhCxpjeARfFGwNoCIkWKGIZQRR8BNy0g1MWIYQpVvBGwsoBWpyN2k4Bap5xYtlNKBaYNENCXTlQSiOiLfqojA/WFE9EW9UtLFk0N6kjElASSettPdS2Q1X7EegOU1TOof0edqA2Ql1mVjh5YqGdJ9cKlgY3+82HHhwrM4yWmZYQFtuEWrKd4dbyowPx+fLXMsKDleLMATMOruP+fLskPS3L0IAB0HD0IoLk9tP6GS3LEsrwapwcWsFTFqlgVq2JVrIpVsX4S1hiW2U/OuWnyw32kg+WfND9de/CTe9I0jESw3ErDn7/cJ7eheSSK5TwAwOjdjlKBpcZyA8AyuV1NgSbWBIN7q4Eklpudy8+VHuuzBp5YbuGJ5QJPrGlkiRU9Xkiw3MgTy5PF8ksYYQz3OUPViIoVQgghbHcV/nHd45A8XQnWW/fPj6YwJS6GmbDur0vK6eO/EMBaOyyse0dyWFtXPKxbfWpYW7FZ2zAQw9q215Cy4c2BtV0MVrdrpoW1FxqfsNHIgLV3uUvCmpEBa69/WNV4Wlh7z9kxYRefAWu3cE8/AyuQwtq9Cb5iVaxaMlIUeKBd4Jk+jv3F/8Pawe+YayaOtRxbmAzEsPyVZRniRX84Ut6jzquzYG3NAIek42o8A7WFHtaKa2OsS3Gq+8K1sfsk7iuTXFjOP+7GOB8YZxPB+vciaPHb2zeoYr3VnSVW5JeRWLBGlljR99GgwJqBI5YfOWJNCbZKlsfyKTaA5ngR9L5aJNnWmmNEc98HmxYAqljbTWDSrdWZBmphoxFMuRE+25xwnJ+sOPkl5e8Wco4/x/sw//k5Q4C0KjjVrVgVq2JVrIpVsSpWxapYFatiVayKVbEqVsXCgIVBFatiVayKVbEqVsUqj8X0k6YsP0AruX4umOnHnZl+ipvph9M5hksLIRrD0YNcj5AQneF4s7gezyIaXofpPE5fNfwsyPegKq7HinE9BI7rkX1CdOTrodw+EZO2EfePF+d5eClhJ8pPpzlry6NUrG5YTy5UzbFDt0lFTB4//l1ZNqGiGDHz3YH2RJx4MFS0wORNnBNmJ1olzgvr8eLmlP+wO7G9BoWzn5JKxBCuiFktIqnp2YQKZ8TaTsQVhn4qUqhwRcxokURllyxRQ4UlYrITKVXGiVaJ1MoPtj9TouzEhKF6AZPEi3ppJ9qcULlGwCab/3JGrO1ECd0sm1DliliqTqlsP1UgVOkjJjtRXrGXLFYJHIoZsbKhSuXE4qFKAXZ6UIvZiWhCFXMEbJD5L44TW6xQV0bAUgncOhMxTEU92ggYb6iuRAxFpxS7n0IfqjMRoxCq751IJFTfgREK1XEnWiXoqrclB7UpnWjoPqnegumXGbDU9KF+k6leWgAwtu3zMP0Ci2OyXKcQL8AAAAAASUVORK5CYII='
        />
      </defs>
    </>
  ),
});
