import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { useAuthState } from 'data/auth/hooks/useAuthState';
import {
  customerProductsAtom,
  fetchCustomerProductsAtom,
} from 'data/charts/atoms/filters/products';
import { useCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';

export function useFetchCustomerProducts() {
  const fetchCustomerProducts = useSetAtom(fetchCustomerProductsAtom);
  const { isAuthenticated } = useAuthState();
  const { mainProductActive } = useCompanyOnboarding();

  useEffect(() => {
    if (isAuthenticated && mainProductActive) {
      fetchCustomerProducts();
    }
  }, [fetchCustomerProducts, isAuthenticated, mainProductActive]);
}

export function useCustomerProducts() {
  const { products, fetchStatus } = useAtomValue(customerProductsAtom);

  return { products, fetchStatus };
}
