import {
  endOfWeek,
  format,
  formatDistanceToNow,
  getWeek,
  isThisMonth,
  isThisWeek,
  isToday,
  isYesterday,
  startOfWeek,
} from 'date-fns';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { isPreviousMonth, isPreviousWeek } from 'utils/datetime/dateUtil';

export function formatDate(
  date: Date,
  granularity: AggregationPeriod,
  shortened = false,
  relative = false,
  withYear = false,
  suffix = true,
): string {
  if (relative) {
    return formatRelativeTime(date, suffix);
  }
  switch (granularity) {
    case 'SINCE_THE_BEGINNING_OF_TIME':
    case 'DAY':
      if (shortened) {
        if (withYear) {
          return format(date, 'd MMM ‘yy');
        } else {
          return format(date, 'd MMM');
        }
      } else {
        return format(date, 'EEE d MMM ‘yy');
      }
    case 'WEEK':
      if (shortened) {
        return `W${getWeek(date)}`;
      } else {
        const weekStartDate = format(startOfWeek(date), 'd');
        const weekEndDate = format(endOfWeek(date), 'd MMM ‘yy');
        return `W${getWeek(date)} (${weekStartDate} - ${weekEndDate})`;
      }
    case 'MONTH':
      if (shortened) {
        return format(date, 'MMM ‘yy');
      } else {
        return format(date, 'MMMM ‘yy');
      }
    default:
      throw new Error(`Unsupported granularity: ${granularity}`);
  }
}

function getMonthLabel(date: Date) {
  if (isThisMonth(date)) {
    return '(This month)';
  } else if (isPreviousMonth(date)) {
    return '(Last month)';
  }
  return '';
}

function getWeekLabel(date: Date) {
  if (isThisWeek(date)) {
    return '(This week)';
  } else if (isPreviousWeek(date)) {
    return '(Last week)';
  }
  return '';
}

function getDayLabel(date: Date) {
  if (isToday(date)) {
    return '(Today)';
  } else if (isYesterday(date)) {
    return '(Yesterday)';
  }
  return '';
}

export function getLabel(date: Date, granularity: AggregationPeriod): string {
  switch (granularity) {
    case 'DAY':
      return getDayLabel(date);
    case 'WEEK':
      return getWeekLabel(date);
    case 'MONTH':
      return getMonthLabel(date);
    default:
      return '';
  }
}

export function formatRelativeTime(date: Date, addSuffix = true): string {
  return formatDistanceToNow(date, { addSuffix: addSuffix });
}
