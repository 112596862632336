import { DepartmentIdsArrays } from 'data/customer/DepartmentIdsArrays';

export const useShowDepartmentBlocks = (customer: DepartmentIdsArrays) => {
  const showProductBlocks = customer.productIds.length > 0;
  const showFinanceBlocks = customer.financeIds.length > 0;
  const showAnyDepartmentBlocks = showProductBlocks || showFinanceBlocks;

  return {
    showProductBlocks,
    showFinanceBlocks,
    showAnyDepartmentBlocks,
  };
};
