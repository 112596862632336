import { FC } from 'react';

import { IconProps } from '@chakra-ui/icons';
import { useAtom } from 'jotai';

import {
  embedCurrentOptionAtom,
  EmbedOption,
} from 'components/Sharing/SharingOptions/embedCurrentOptionAtom';
import { Button } from 'components/uikit';

const getTextColor = (isActive: boolean) =>
  isActive ? 'secondary.text' : 'grey.tertiaryText';
const getBackgroundColor = (isActive: boolean) =>
  isActive ? 'secondary.background' : undefined;

export function EmbedOptionTopNavButton({
  caption,
  Icon,
  embedOption,
}: {
  caption: string;
  Icon: FC<IconProps>;
  embedOption: EmbedOption;
}) {
  const [embedCurrentOption, setEmbedCurrentOption] = useAtom(
    embedCurrentOptionAtom,
  );

  const isActive = embedOption === embedCurrentOption;

  return (
    <Button
      variant={'primaryGhost'}
      width='100%'
      onClick={() => setEmbedCurrentOption(embedOption)}
      isActive={isActive}
      color={getTextColor(isActive)}
      background={getBackgroundColor(isActive)}
    >
      <Icon mr='9px' />
      {caption}
    </Button>
  );
}
