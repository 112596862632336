import { useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';
import { CustomerBlock } from 'data/blocks/models/Block';
import { FullBreakdownByUsersRow } from 'data/charts/atoms/product/users/breakdowns';
import { useBreakdownPaginationAtomWithoutUrlNavigation } from 'data/charts/hooks/useBreakdownPaginationAtom';
import { useBreakdownSortingAtom } from 'data/charts/hooks/useBreakdownSortingAtom';
import { useCustomerCompanyAtoms } from 'data/healthReports/atoms/customerCompanyAtoms';
import { QuartileAtomKeys } from 'data/healthReports/models/company/CustomerCompanyAtomsType';

export const useCustomerCompany = (customerCompanyId: string) => {
  return useAtomValue(useCustomerCompanyAtoms(customerCompanyId).dataAtom);
};

export const useCustomerCompanyFetchingAtom = (customerCompanyId: string) => {
  return useCustomerCompanyAtoms(customerCompanyId).fetchingAtom;
};

export const useCustomerCompanyTagsUpdatingAtom = (
  customerCompanyId: string,
) => {
  return useAtom(useCustomerCompanyAtoms(customerCompanyId).tagsUpdatingAtom);
};

export const useCustomerCompanyTagRemovingAtom = (
  customerCompanyId: string,
) => {
  return useSetAtom(useCustomerCompanyAtoms(customerCompanyId).tagRemovingAtom);
};

export const useCustomerCompanyNoteCreatingAtom = (
  customerCompanyId: string,
) => {
  return useSetAtom(
    useCustomerCompanyAtoms(customerCompanyId).noteCreatingAtom,
  );
};

export const useCustomerCompanyNotes = (customerCompanyId: string) => {
  return useAtomValue(useCustomerCompanyAtoms(customerCompanyId).notesDataAtom);
};

export const useCustomerCompanyNote = (customerCompanyId: string) => {
  return useAtomValue(useCustomerCompanyAtoms(customerCompanyId).notesDataAtom);
};

export const useCustomerCompanyNotesFetchingAtom = (
  customerCompanyId: string,
) => {
  return useCustomerCompanyAtoms(customerCompanyId).notesFetchingAtom;
};

export const useCustomerCompanyNoteDeletingAtom = (
  customerCompanyId: string,
) => {
  return useCustomerCompanyAtoms(customerCompanyId).noteDeletingAtom;
};

export const useCustomerCompanyData = (customerCompanyId: string) => {
  const { companyDataAtoms } = useCustomerCompanyAtoms(customerCompanyId);

  return useAtomValue(companyDataAtoms.dataAtom);
};

export const useCustomerCompanyDataFetchingAtom = (
  customerCompanyId: string,
) => {
  const { companyDataAtoms } = useCustomerCompanyAtoms(customerCompanyId);

  return useSetAtom(companyDataAtoms.fetchingAtom);
};

export const useFetchCustomerCompanyData = (customerCompanyId: string) => {
  const { blocks } = useCustomerBreakdownBlocks();
  const companyDataBlock = blocks.find(
    (block) => block.uniqueName == 'CUSTOMER_COMPANY_INFO',
  );
  const fetchCompanyData =
    useCustomerCompanyDataFetchingAtom(customerCompanyId);

  useEffect(() => {
    if (companyDataBlock) {
      fetchCompanyData(companyDataBlock.id);
    }
  }, [fetchCompanyData, customerCompanyId, companyDataBlock]);
};

export const useCustomerCompanySentimentUpdatingAtom = (
  customerCompanyId: string,
) => {
  return useAtom(
    useCustomerCompanyAtoms(customerCompanyId).sentimentUpdatingAtom,
  );
};

const useFetchCustomersQuartileChart = (
  quartileFetchingAtomKey: QuartileAtomKeys,
  customerCompanyId: string,
  block?: CustomerBlock,
) => {
  const [{ data, fetchStatus, errorMessage }, fetchData] = useAtom(
    useCustomerCompanyAtoms(customerCompanyId)[
      `quartile${quartileFetchingAtomKey}FetchingAtom`
    ],
  );

  useEffect(() => {
    if (block) fetchData({ block });
  }, [fetchData, block]);

  return { data, fetchStatus, errorMessage };
};

export const useFetchCustomersQuartileRevenueChart = (
  customerCompanyId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart('Revenue', customerCompanyId, block);
};

export const useFetchCustomersQuartileActiveUsersChart = (
  customerCompanyId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart(
    'ActiveUsers',
    customerCompanyId,
    block,
  );
};

export const useFetchCustomersQuartileProductEngagementChart = (
  customerCompanyId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart(
    'ProductEngagement',
    customerCompanyId,
    block,
  );
};

export const useFetchCustomersQuartileCustomerLifespanChart = (
  customerCompanyId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart(
    'CustomerLifespan',
    customerCompanyId,
    block,
  );
};

export const useCustomerCompanyUserActivityBreakdownAtoms = (
  customerCompanyId: string,
) => {
  const customerCompanyAtoms = useCustomerCompanyAtoms(customerCompanyId);

  return {
    dataAtom: customerCompanyAtoms.userActivityDataAtom,
    fetchingAtom: customerCompanyAtoms.userActivityFetchingAtom,
    sortingAtom: customerCompanyAtoms.userActivitySortingAtom,
    paginationAtom: customerCompanyAtoms.userActivityPaginationAtom,
  };
};

export const useCustomerCompanyUserActivityBreakdownSortingAtom = (
  customerCompanyId: string,
) => {
  return useBreakdownSortingAtom({
    blockName: 'CUSTOMER_COMPANY_PRODUCT_USERS',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: useCustomerCompanyUserActivityBreakdownAtoms(customerCompanyId),
  });
};

export const useCustomerCompanyUserActivityBreakdownPaginationAtom = (
  customerCompanyId: string,
) => {
  return useBreakdownPaginationAtomWithoutUrlNavigation(
    useCustomerCompanyAtoms(customerCompanyId).userActivityPaginationAtom,
  );
};

export const useCustomerCompanyUserSegmentsBreakdownAtoms = (
  customerCompanyId: string,
) => {
  const customerCompanyAtoms = useCustomerCompanyAtoms(customerCompanyId);

  return {
    dataAtom: customerCompanyAtoms.userSegmentDataAtom,
    fetchingAtom: customerCompanyAtoms.userSegmentFetchingAtom,
  };
};

export const useCustomerCompanyUsers = (customerCompanyId: string) => {
  const usersAtom =
    useCustomerCompanyUserActivityBreakdownAtoms(customerCompanyId).dataAtom;
  const { data, fetchStatus } = useAtomValue(usersAtom);
  const users = data.rows;
  const usersCount = data.totalCount;

  return { users, usersCount, fetchStatus };
};
