import { createIcon } from '@chakra-ui/react';

export const DownloadIcon = createIcon({
  displayName: 'Download',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M17.5 13.001V16.3343C17.5 16.7763 17.3244 17.2003 17.0118 17.5128C16.6993 17.8254 16.2754 18.001 15.8333 18.001H4.16667C3.72464 18.001 3.30072 17.8254 2.98816 17.5128C2.67559 17.2003 2.5 16.7763 2.5 16.3343V13.001M5.83333 8.83431L10 13.001M10 13.001L14.1667 8.83431M10 13.001V3.00098'
      stroke='currentColor'
      fill='none'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
