import { useCallback, useEffect, useState } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Box, Button, HStack } from 'components/uikit';
import {
  createCustomerTagAtom,
  customerTagsAtom,
  newCustomerTagAtom,
} from 'data/healthReports/atoms/customerTagsAtoms';
import { draftNewTagAtom } from 'data/healthReports/atoms/draftNewTagAtom';
import { searchTagsBoxValueAtom } from 'data/healthReports/atoms/searchTagsBoxValueAtom';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';
import { isFetchError } from 'utils/types';

interface CreateTagModalFooterProps {
  tKeyPrefix: string;
  onClose: () => void;
  customerType: 'user' | 'company';
}
export function CreateTagModalFooter({
  tKeyPrefix,
  onClose,
  customerType,
}: CreateTagModalFooterProps) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: tKeyPrefix,
  });
  const setSearchBoxValue = useSetAtom(searchTagsBoxValueAtom);
  const draftNewTag = useAtomValue(draftNewTagAtom);
  const createCustomerTag = useSetAtom(createCustomerTagAtom);
  const [{ tag: newTag, fetchStatus }, setNewTag] = useAtom(newCustomerTagAtom);
  const [tagsAtomValue, setTags] = useAtom(customerTagsAtom);
  const [disabledButtons, setDisabledButtons] = useState(false);

  const closeHandler = () => {
    setDisabledButtons(true);
    setSearchBoxValue('');
    onClose();
    setDisabledButtons(false);
  };

  const saveHandler = useCallback(async () => {
    setDisabledButtons(true);
    setSearchBoxValue(''); // Clear search box value
    await createCustomerTag(draftNewTag);
    trackEvent(CustomerDrawerAnalyticsEvents.TAGS_CREATED, {
      customerType,
    });
  }, [
    createCustomerTag,
    customerType,
    draftNewTag,
    setSearchBoxValue,
    trackEvent,
  ]);

  useEffect(() => {
    if (newTag) {
      tagsAtomValue.tags.push(newTag);
      setTags({ ...tagsAtomValue });
      setSearchBoxValue(newTag.title);
      setNewTag({ tag: undefined, fetchStatus: 'INITIAL', errorMessage: '' });
    }
    onClose();
    setDisabledButtons(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTag]);

  useEffect(() => {
    if (isFetchError(fetchStatus)) {
      setDisabledButtons(false);
    }
  }, [fetchStatus]);

  return (
    <HStack
      p={'16px'}
      spacing={'16px'}
      justify={'space-between'}
      borderTop={'1px solid'}
      borderColor={'grey.border'}
      shadow={'0px -16px 16px rgba(0, 0, 0, 0.05)'}
    >
      <Box></Box>
      <HStack alignItems='right' spacing={'16px'}>
        <Button
          variant={'grayOutline'}
          onClick={closeHandler}
          disabled={disabledButtons}
        >
          {t('cancel_button_caption')}
        </Button>
        <Button
          variant={'primary'}
          onClick={saveHandler}
          disabled={
            disabledButtons || !draftNewTag.title || !draftNewTag.description
          }
        >
          {t('save_button_caption')}
        </Button>
      </HStack>
    </HStack>
  );
}
