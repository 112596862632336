import { createIcon } from '@chakra-ui/react';

export const EnterIcon = createIcon({
  displayName: 'EnterIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        d='M4.125 4.75L1 7.875M1 7.875L4.125 11M1 7.875H8.5C9.16304 7.875 9.79893 7.61161 10.2678 7.14277C10.7366 6.67393 11 6.03804 11 5.375V1'
        fill='none'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
