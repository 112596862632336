import { ReactElement, ReactNode, useCallback, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { DropdownMenuRow } from 'components/DropdownMenu/DropdownMenuRow';
import {
  Flex,
  List,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from 'components/uikit';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

interface DropdownMenuItem {
  label: ReactNode;
  onClick: () => void;
}

interface DropdownMenuProps {
  header?: ReactNode;
  items: DropdownMenuItem[];
  placement?: 'bottom-start' | 'bottom-end';
  children: ReactElement;
}

export function DropdownMenu({
  header,
  items,
  placement = 'bottom-start',
  children,
}: DropdownMenuProps) {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const { onClose, isOpen, onOpen } = useDisclosure();

  const handleSelection = useCallback(
    (index: number) => {
      onClose();
      items[index]?.onClick();
    },
    [items, onClose],
  );

  const selectHoveredItem = useCallback(() => {
    if (hoveredItem === null) return;
    handleSelection(hoveredItem);
  }, [hoveredItem, handleSelection]);

  useKeyAction('CLOSE', onClose, {
    preventDefault: true,
    enabled: isOpen,
  });
  useKeyAction('CONFIRM', selectHoveredItem, {
    preventDefault: true,
    enabled: isOpen,
  });

  return (
    <Popover
      placement={placement}
      onClose={() => {
        onClose();
      }}
      onOpen={() => {
        onOpen();
      }}
      isOpen={isOpen}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        borderRadius='4px'
        borderColor='grey.border'
        border='1px'
        mt='4px'
      >
        <PopoverBody p={0} borderRadius='4px'>
          {header ? (
            <Flex
              backgroundColor='grey.white'
              p='12px 16px'
              cursor='pointer'
              justifyContent='space-between'
              whiteSpace='break-spaces'
              borderBottom='1px solid'
              borderColor='grey.border'
            >
              {header}
            </Flex>
          ) : null}
          <List
            items={items}
            hotkeysActive={isOpen}
            onItemSelected={(item, index) => {
              if (index != null) setHoveredItem(index);
            }}
            renderItem={(
              { label, onClick },
              isHovered,
              hoverItem,
              removeHover,
              index,
            ) => (
              <DropdownMenuRow
                key={index}
                label={label}
                index={index}
                isActive={isOpen}
                isHovered={isHovered}
                onSelect={() => {
                  onClose();
                  onClick();
                }}
                onHover={hoverItem}
                onBlur={removeHover}
              />
            )}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
