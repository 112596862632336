import { useTranslation } from 'react-i18next';

import {
  Button,
  Card,
  CardProps,
  HStack,
  LifeBuoyIcon,
  Text,
  VStack,
} from 'components/uikit';
import { ReloadIcon } from 'components/uikit/icons/Reload';
import { RobotIcon } from 'components/uikit/icons/RobotIcon';
import { useOpenIntercom } from 'utils/intercom/useOpenIntercom';

interface AiErrorCardProps extends CardProps {
  onRetry: () => void;
}

export function AIErrorCard({ onRetry, ...props }: AiErrorCardProps) {
  const { t } = useTranslation('ai');
  const openIntercom = useOpenIntercom();

  return (
    <Card boxShadow='none' {...props}>
      <VStack
        align='center'
        bgColor='secondary.background'
        h='160px'
        p='24px 16px'
        gap='16px'
      >
        <RobotIcon height='79' width='64' />
        <Text fontSize='12px' fontWeight='400' lineHeight='150%'>
          {t('error')}
        </Text>
      </VStack>
      <HStack>
        <Button
          variant='ghost'
          w='100%'
          color='secondary.text'
          onClick={onRetry}
          leftIcon={<ReloadIcon stroke='secondary.text' />}
        >
          {t('retry')}
        </Button>
        <Button
          variant='ghost'
          w='100%'
          color='secondary.text'
          onClick={openIntercom}
          leftIcon={<LifeBuoyIcon stroke='secondary.text' />}
        >
          {t('contact_support')}
        </Button>
      </HStack>
    </Card>
  );
}
