import { createIcon } from '@chakra-ui/react';

export const SortArrowUp = createIcon({
  displayName: 'SortArrowUp',
  viewBox: '0 0 8 10',
  path: (
    <path
      d='M5 8.5V1.5M5 1.5L8.5 5M5 1.5L1.5 5'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
