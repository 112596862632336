import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CompanyOnboardingUrl } from 'api/rest/users/resourceUrls';
import { CompanyOnboarding } from 'data/user/models/onboarding';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function getCompanyOnboarding(): Promise<CompanyOnboarding> {
  const result = await authorisedFetch(CompanyOnboardingUrl, { method: 'GET' });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch company onboarding: ${result.status}`);
  }

  return parseItemStrict(CompanyOnboarding, data);
}
