import { atom, WritableAtom } from 'jotai';

import { PageRequest } from 'data/charts/models/ChartsApiRequest';

export const DEFAULT_PAGINATION: PageRequest = {
  size: 10,
  page: 1,
};

export type BreakdownPaginationAtom = WritableAtom<
  PageRequest,
  PageRequest,
  void
>;

export function getPaginationAtom(): BreakdownPaginationAtom {
  return atom<PageRequest>(DEFAULT_PAGINATION);
}
