import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Filter, TimeRangeParser } from 'data/charts/models/ChartsApiRequest';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { CurrencyType } from 'data/common/currencyType';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict } from 'utils/parser/parseResponse';

type SummaryCardConfig = {
  timeRange: TimeRangeParser;
  filters?: Filter[];
};

export const SummaryCard = z.object({
  value: z.coerce.number().nullable(),
  delta: z.coerce.number().nullable(),
  pctDelta: z.coerce.number().nullable(),
  currency: CurrencyType.nullish(),
});

export type SummaryCard = z.infer<typeof SummaryCard>;

export async function fetchSummaryCard(
  blockId: string,
  chartConfig: SummaryCardConfig,
): Promise<BlockResponse<SummaryCard>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      blocks: [{ blockId, chartConfig }],
    }),
  });

  const json = await result.json();
  const [data] = parseItemStrict(z.tuple([BlockResponse(SummaryCard)]), json);

  return data;
}
