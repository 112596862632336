import { Divider } from '@chakra-ui/react';

import {
  SharingBlockModalBodyDefaultContainer,
  SharingBlockModalBodyDefaultContentContainer,
} from 'components/Sharing/SharingBlockModalBodyDefaultContainers';
import { EmbedOptionNotionTabBody } from 'components/Sharing/SharingOptions/EmbedOptionNotionTabBody';
import { EmbedOptionSlackTabBody } from 'components/Sharing/SharingOptions/EmbedOptionSlackTabBody';
import { EmbedOptionTomeTabBody } from 'components/Sharing/SharingOptions/EmbedOptionTomeTabBody';
import { EmbedOptionTopNavBar } from 'components/Sharing/SharingOptions/EmbedOptionTopNavBar';
import { EmbedOptionWebsiteTabBody } from 'components/Sharing/SharingOptions/EmbedOptionWebsiteTabBody';

export function EmbedOptionBody() {
  return (
    <SharingBlockModalBodyDefaultContainer>
      <SharingBlockModalBodyDefaultContentContainer height='auto'>
        <EmbedOptionTopNavBar />
        <Divider />
      </SharingBlockModalBodyDefaultContentContainer>

      <EmbedOptionSlackTabBody />
      <EmbedOptionNotionTabBody />
      <EmbedOptionTomeTabBody />
      <EmbedOptionWebsiteTabBody />
    </SharingBlockModalBodyDefaultContainer>
  );
}
