import { useEffect } from 'react';

import { useBreakpoint } from '@chakra-ui/media-query';
import { useSetAtom } from 'jotai';

import { mobileAtom } from 'utils/atoms/mobileAtom';

// To skip a breakpoint, set its value to the same value as the nearest
// smaller breakpoint
export const breakpoints = {
  base: '0em',
  sm: '1px',
  md: '1px',
  lg: '768px',
  xl: '768px',
  '2xl': '768px',
};

export type BreakpointNames = keyof typeof breakpoints;

export function useCurrentScreenSize() {
  const breakpoint = useBreakpoint({ ssr: false });
  return (breakpoint ?? 'lg') as BreakpointNames;
}

export function useIsCurrentScreenSize(breakpoint: BreakpointNames) {
  return useCurrentScreenSize() === breakpoint;
}

export function useIsMobile() {
  return useIsCurrentScreenSize('sm');
}

export function useIsDesktop() {
  return useIsCurrentScreenSize('lg');
}

export function useComponentResponsiveVariants(
  controlName: string,
  responsiveControls: {
    sm: JSX.Element;
    lg: JSX.Element;
  },
) {
  const breakpoint = useCurrentScreenSize();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const defaultControl = responsiveControls.lg;

  if (isMobile) {
    return responsiveControls.sm;
  }

  if (isDesktop) {
    return responsiveControls.lg;
  }

  console.info(`${controlName} is not defined for screen size: ${breakpoint}`);

  return defaultControl;
}

export function useSetMobileAtom() {
  const isMobile = useIsMobile();
  const setMobile = useSetAtom(mobileAtom);

  useEffect(() => {
    setMobile(isMobile);
  }, [setMobile, isMobile]);
}
