import {
  addDays,
  addHours,
  format,
  startOfDay,
  startOfWeek,
  subMinutes,
} from 'date-fns';

export function formatDateToUtcIso(date: Date) {
  return convertLocalDateToUTC(date).toISOString();
}

// Converts date from local timezone to the same hours in UTC
// Required for various end of the day ranges etc to work predictably
// Since e.g. the start of the day in timezone +1 is 23:00 of previous date
export function convertLocalDateToUTC(date: Date) {
  const offsetinMinutes = date.getTimezoneOffset();
  return subMinutes(date, offsetinMinutes);
}

export function convertUTCStringDateToLocalDate(dateString: string) {
  return convertUTCDateToLocalDate(new Date(dateString));
}

export function convertUTCDateToLocalDate(date: Date) {
  // Parse the date from ISO 8601 format
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  const ms = date.getUTCMilliseconds();

  // Create a new Date object using the parsed date in the local timezone
  // Fixed 10th of month is required here to avoid issues with 31st of month
  const localDate = new Date(1000 * 60 * 60 * 24 * 10);
  localDate.setFullYear(year);
  localDate.setMonth(month);
  localDate.setDate(day);
  localDate.setHours(hour);
  localDate.setMinutes(minute);
  localDate.setSeconds(second);
  localDate.setMilliseconds(ms);

  return localDate;
}

export function formatWeekdayNameFromOrdinal(ordinal: number) {
  // Ensure the ordinal is between 1 and 7
  if (ordinal < 1 || ordinal > 7) {
    return 'Invalid ordinal. It should be between 1 and 7.';
  }

  // Create a base date, for example, the start of this week
  const baseDate = startOfWeek(new Date(), { weekStartsOn: 1 }); // week starts on Monday

  // Calculate the date of the desired weekday
  const weekdayDate = addDays(baseDate, ordinal - 1);

  // Format the date to get the weekday name
  return format(weekdayDate, 'EEEE'); // 'EEEE' gives the full name of the day
}

export function formatTimeFromHour(hour: number) {
  if (hour < 0 || hour > 23) {
    return 'Invalid ordinal. It should be between 1 and 7.';
  }

  const baseDate = startOfDay(new Date()); // week starts on Monday

  const hourDate = addHours(baseDate, hour);

  // Format the date to get the weekday name
  return format(hourDate, 'HH:mm'); // 'EEEE' gives the full name of the day
}
