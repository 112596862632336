import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CompanyOnboardingUrl } from 'api/rest/users/resourceUrls';
import { CompanyOnboarding } from 'data/user/models/onboarding';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function activateMainProduct(): Promise<CompanyOnboarding | null> {
  const result = await authorisedFetch(
    `${CompanyOnboardingUrl}?activateMainProduct=true`,
    { method: 'PUT' },
  );

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to activate main product: ${result.status}`);
  }

  return parseItemStrict(CompanyOnboarding, data);
}
