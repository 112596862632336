import { BoxProps, SkeletonProps } from '@chakra-ui/react';

import { Box, Skeleton, Td, Tr } from 'components/uikit';

interface BodySkeletonProps {
  cellCount?: number;
  colSpans?: number[];
  hasError?: boolean;
  cellWidth?: SkeletonProps['width'];
  cellHeight?: SkeletonProps['height'];
}

type Props = BodySkeletonProps & BoxProps;

export function BreakdownTableBodySkeleton({
  cellCount,
  colSpans,
  cellWidth,
  hasError,
  cellHeight = '16px',
  height = '240px',
  paddingTop = '16px',
  ...boxProps
}: Props) {
  const finalColSpans: number[] = colSpans ?? Array(cellCount || 1).fill(1);

  return (
    <Tr>
      {finalColSpans.map((colSpan, index) => {
        return (
          <Td
            key={`skeleton-table-cell-${index}`}
            colSpan={colSpan}
            height={height}
            verticalAlign='top'
          >
            <Box paddingTop={paddingTop} {...boxProps}>
              <Skeleton
                width={cellWidth}
                height={cellHeight}
                hasError={hasError}
              />
            </Box>
          </Td>
        );
      })}
    </Tr>
  );
}
