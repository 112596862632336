import { createIcon } from '@chakra-ui/react';

export const VerticalBarChartIcon = createIcon({
  displayName: 'VerticalBarChartIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <rect
        x='1.3335'
        y='15.9998'
        width='4.36885'
        height='14.5628'
        rx='0.5'
        fill='currentColor'
      />
      <rect
        x='9.65527'
        y='6.60449'
        width='4.36885'
        height='23.9582'
        rx='0.5'
        fill='currentColor'
      />
      <rect
        x='17.9766'
        y='12.4219'
        width='4.36885'
        height='18.1408'
        rx='0.5'
        fill='currentColor'
      />
      <rect
        x='26.2983'
        y='1.43701'
        width='4.36885'
        height='29.1257'
        rx='0.5'
        fill='currentColor'
      />
    </>
  ),
});
