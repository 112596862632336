import { captureException } from '@sentry/core';
import { atom } from 'jotai';

import { chatWithParrot } from 'api/rest/parrot/chatWithParrot';
import { createInsightChat } from 'api/rest/parrot/createInsightChat';
import { getChatActions } from 'api/rest/parrot/getChatActions';
import { getChatMessages } from 'api/rest/parrot/getChatMessages';
import { getChats } from 'api/rest/parrot/getChats';
import { getFollowUpQuestions } from 'api/rest/parrot/getFollowUpQuestions';
import { getInsightActionCaches } from 'api/rest/parrot/getInsightActionCaches';
import { getInsightActions } from 'api/rest/parrot/getInsightActions';
import { getInsightFollowUpQuestions } from 'api/rest/parrot/getInsightFollowUpQuestions';
import { DataAtom, getDataAtom } from 'utils/atoms/dataAtom';
import {
  getDataFetchingAtom,
  WritableDataFetchingAtom,
} from 'utils/atoms/dataFetchingAtom';

import {
  Chat,
  ChatContext,
  InsightAction,
  PublicChatMessage,
  QuestionWithAnswer,
} from './models';

export const aiDrawerAtom = atom(false);

export const aiArchiveDrawerAtom = atom(false);

export const aiResponseAtom = getDataAtom<{
  results: QuestionWithAnswer[];
  context: ChatContext;
}>({
  results: [],
  context: {
    type: 'CHAT',
  },
});

export const aiChatsAtom = getDataAtom<{
  results: Chat[];
}>({
  results: [],
});

export const aiFollowUpQuestionsAtom = getDataAtom<{
  questions: string[];
}>({
  questions: [],
});

export const insightActionsAtoms: Record<
  string,
  {
    dataAtom: DataAtom<{ actions: InsightAction[] }>;
    fetchingAtom: WritableDataFetchingAtom<
      { actions: InsightAction[] },
      string
    >;
  }
> = {};

const fetchFollowUpQuestionsAtom = getDataFetchingAtom(
  aiFollowUpQuestionsAtom,
  async (chatId: string) => {
    const { followUpQuestions } = await getFollowUpQuestions({ chatId });
    return { questions: followUpQuestions };
  },
);

export const loadCachedActionsAtom = atom(
  null,
  async (_, set, insightIds: string[]) => {
    try {
      const atoms = Object.fromEntries(
        insightIds.map((id) => [id, getInsightFetchingAtomPair(id).dataAtom]),
      );

      const actions = await getInsightActionCaches(insightIds);

      Object.entries(actions).forEach(([insightId, actions]) => {
        const atom = atoms[insightId];
        if (atom)
          set(atom, { fetchStatus: 'SUCCESS', actions, errorMessage: '' });
      });
    } catch (e) {
      captureException(e);
    }
  },
);

export const getInsightFetchingAtomPair = (insightId: string) => {
  const storedAtoms = insightActionsAtoms[insightId];

  if (storedAtoms) return storedAtoms;

  const dataAtom = getDataAtom<{ actions: InsightAction[] }>({ actions: [] });
  const fetchingAtom = getDataFetchingAtom(dataAtom, getInsightActions);

  insightActionsAtoms[insightId] = {
    dataAtom,
    fetchingAtom,
  };

  return {
    dataAtom,
    fetchingAtom,
  };
};

export const fetchInsightFollowUpQuestionsAtom = getDataFetchingAtom(
  aiFollowUpQuestionsAtom,
  async (insightId: string) => {
    const { followUpQuestions } = await getInsightFollowUpQuestions({
      insightId,
    });
    return { questions: followUpQuestions };
  },
);

export const fetchInsightActionsAtom = getDataFetchingAtom(
  aiFollowUpQuestionsAtom,
  async (insightId: string) => {
    const { followUpQuestions } = await getInsightFollowUpQuestions({
      insightId,
    });
    return { questions: followUpQuestions };
  },
);

export const fetchAiResponseAtom = getDataFetchingAtom(
  aiResponseAtom,
  async (
    {
      prompt,
      actionsRequested = false,
    }: { prompt: string; actionsRequested?: boolean },
    get,
    set,
  ) => {
    const prevResults = get(aiResponseAtom);
    const shouldStartInsightChat =
      !prevResults.context.id && prevResults.context.type === 'INSIGHT';

    set(aiResponseAtom, {
      ...prevResults,
      results: [
        ...prevResults.results.filter(({ error }) => error == null),
        { prompt },
      ],
    });

    set(aiFollowUpQuestionsAtom, ({ questions }) => ({
      questions,
      fetchStatus: 'INITIAL',
      errorMessage: '',
    }));

    try {
      const {
        content: { text, description, actions, chartData },
        chatId,
      } =
        shouldStartInsightChat && prevResults.context.insightId
          ? await createInsightChat({
              prompt,
              insightId: prevResults.context.insightId,
            })
          : actionsRequested && prevResults.context.id
          ? await getChatActions(prevResults.context.id)
          : await chatWithParrot({ prompt, chatId: prevResults.context.id });

      set(fetchFollowUpQuestionsAtom, chatId);

      set(aiChatsAtom, ({ results, ...rest }) => {
        const updatedChat = results.find(({ id }) => id === chatId);
        const newMessage = {
          id: '',
          chatId,
          role: 'user' as const,
          content: { text: prompt },
          insertedAt: new Date(),
        };
        const newChat = !updatedChat && {
          id: chatId,
          name: prevResults.context.name ?? prompt,
          description: prevResults.context.description,
          type: prevResults.context.type,
          insertedAt: new Date(),
          latestMessages: [newMessage],
          totalMessages: 1,
        };

        return {
          results: newChat
            ? [newChat, ...results]
            : results.map((result) => {
                if (updatedChat && updatedChat?.id === result.id) {
                  return {
                    ...updatedChat,
                    latestMessages: [...updatedChat.latestMessages, newMessage],
                  };
                }
                return result;
              }),
          ...rest,
        };
      });

      return {
        results: [
          ...prevResults.results.filter(({ error }) => error == null),
          { prompt, answer: { answer: text, description, actions, chartData } },
        ],
        chatId,
        context: {
          ...prevResults.context,
          id: chatId,
        },
      };
    } catch (e) {
      if (e instanceof Error) {
        set(aiResponseAtom, {
          ...prevResults,
          results: [
            ...prevResults.results.filter(({ error }) => error == null),
            { prompt, error: e.message },
          ],
        });
      }
      throw e;
    }
  },
);

export const fetchAiChatsAtom = getDataFetchingAtom(aiChatsAtom, getChats);

export const fetchAiChatMessagesAtom = getDataFetchingAtom(
  aiResponseAtom,
  async (chat: Chat, get, set) => {
    set(aiFollowUpQuestionsAtom, ({ questions }) => ({
      questions,
      fetchStatus: 'INITIAL',
      errorMessage: '',
    }));

    const { results } = await getChatMessages(chat.id);

    set(fetchFollowUpQuestionsAtom, chat.id);

    return {
      results: convertMessagesToQuestionsWithAnswers(results),
      context: {
        id: chat.id,
        name: chat.name,
        description: chat.description,
        type: chat.type,
        insightId: chat.insightId,
      },
    };
  },
);

const convertMessagesToQuestionsWithAnswers = (
  messages: PublicChatMessage[],
) => {
  return messages
    .map((message, index) => ({ message, originalIndex: index }))
    .filter(({ message: { role } }) => role === 'user')
    .map(({ message, originalIndex }) => {
      const answer = messages[originalIndex + 1];
      return {
        prompt: message.content?.text ?? '',
        answer: answer
          ? {
              answer: answer.content?.text ?? '',
              description: answer.content?.description,
              actions: answer.content?.actions,
              chartData: answer.content?.chartData,
            }
          : undefined,
      };
    });
};
