import { useEffect } from 'react';

import { Image, useClipboard, useDisclosure } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  CopyIcon,
  Flex,
  ModalContent,
  ModalWrapper,
  Text,
  VStack,
} from 'components/uikit';
import celebration from 'components/uikit/animations/celebration.json';
import { APP_URL } from 'data/constants';
import { useUpdateProductTourConfig } from 'data/user/hooks/useUpdateProductTourConfig';
import { useUserProductTourConfig } from 'data/user/hooks/useUserProductTourConfig';

export function ProductTourCompletedModal() {
  const { t } = useTranslation('onboarding');
  const { updateTourConfig } = useUpdateProductTourConfig();
  const { config } = useUserProductTourConfig();
  const {
    isOpen: isTourCompletedOpen,
    onOpen: onTourCompletedOpen,
    onClose: onTourCompletedClose,
  } = useDisclosure();

  useEffect(() => {
    if (
      config.viewedMissionControl &&
      config.viewedRadar &&
      config.viewedAlerts &&
      config.viewedBreakdowns &&
      config.viewedMetricDictionary &&
      !config.productTourCompleted
    ) {
      onTourCompletedOpen();
    }
  }, [
    config.productTourCompleted,
    config.viewedAlerts,
    config.viewedBreakdowns,
    config.viewedMetricDictionary,
    config.viewedMissionControl,
    config.viewedRadar,
    onTourCompletedOpen,
  ]);

  const onCloseModal = async () => {
    await updateTourConfig('productTourCompleted');
    onTourCompletedClose();
  };

  const { onCopy } = useClipboard(APP_URL || '', 3000);

  const onClick = async () => {
    onCopy();
    await onCloseModal();
  };

  return (
    <>
      <ModalWrapper
        border='none'
        isCentered
        maxWidth='480px'
        isOpen={isTourCompletedOpen}
        onClose={onCloseModal}
      >
        <ModalContent
          body={
            <Flex direction='column' alignItems='flex-start'>
              <Flex
                bgColor='green.50'
                w='100%'
                h='200px'
                alignItems='center'
                justifyContent='center'
                borderTopRadius='4px'
              >
                <Image src='/static/confetti.png' w='160px' h='160px' />
              </Flex>
              <VStack spacing='12px' px='40px' py='32px'>
                <Text fontWeight='600' fontSize='18px'>
                  {t(`product_tour_completed_title`)}
                </Text>
                <Text
                  color='grey.secondaryText'
                  fontWeight='400'
                  fontSize='16px'
                  whiteSpace='pre-line'
                  textAlign='center'
                >
                  {t(`product_tour_completed_description`)}
                </Text>
                <Text
                  color='grey.secondaryText'
                  fontWeight='400'
                  fontSize='16px'
                  whiteSpace='pre-line'
                  textAlign='center'
                >
                  {t(`product_tour_completed_caption`)}
                </Text>
              </VStack>
              <Box
                width='100%'
                height='100%'
                style={{
                  position: 'absolute',
                }}
              >
                <Lottie
                  animationData={celebration}
                  style={{
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                  }}
                />
              </Box>
            </Flex>
          }
          footerStyle={{
            justifyContent: 'center',
            border: 'none',
            pb: '24px',
          }}
          footer={
            <Button
              variant='onboarding'
              onClick={onClick}
              rightIcon={<CopyIcon boxSize='20px' stroke='grey.white' />}
              tabIndex={-1}
            >
              {t(`onboarding:copy_invite_link`)}
            </Button>
          }
        />
      </ModalWrapper>
    </>
  );
}
