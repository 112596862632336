import { createIcon } from '@chakra-ui/react';

export const ZapIcon = createIcon({
  displayName: 'ZapIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M13 2L3 14H12L11 22L21 10H12L13 2Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  ),
});
