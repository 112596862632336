import { useCallback, useState } from 'react';

import { ColorMode } from '@chakra-ui/color-mode';
import { useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useSharingBlockContext } from 'components/Sharing/sharingBlockContextAtom';
import {
  SharingBlockModalBodyDefaultActionsContainer,
  SharingBlockModalBodyDefaultContainer,
  SharingBlockModalBodyDefaultContentContainer,
} from 'components/Sharing/SharingBlockModalBodyDefaultContainers';
import { CopyAsImageThemeSelector } from 'components/Sharing/SharingOptions/CopyAsImageThemeSelector';
import {
  useCopyImage,
  useGetScreenshotFromPublicLink,
} from 'components/Sharing/SharingOptions/useCreateAndCopyPublicSharingLink';
import {
  base64toBlob,
  downloadImage,
} from 'components/Sharing/SharingOptions/utils';
import { useGetModalTitle } from 'components/Sharing/useGetModalTitle';
import { Box, Button, CopyIcon, DownloadIcon, Spinner } from 'components/uikit';
import { CopyAsImagePlaceholder } from 'components/uikit/icons/CopyAsImagePlaceholder';
import { CopyAsImagePlaceholderDark } from 'components/uikit/icons/CopyAsImagePlaceholderDark';
import { formatDateToUtcIso } from 'utils/datetime';

export function CopyAsImageOptionBody() {
  const { t } = useTranslation('common', {
    keyPrefix: 'sharing_block_modal_copy_as_image',
  });
  const { closeSharingModal, shareBlockConfig, publicSharingLinkId } =
    useSharingBlockContext();
  const copyImage = useCopyImage();
  const downloadFileTitle = useGetModalTitle(shareBlockConfig?.blockName);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean | string>(
    false,
  );
  const [screenshotColorMode, setScreenshotColorMode] = useState<ColorMode>(
    useColorMode().colorMode,
  );
  const isLight = screenshotColorMode === 'light';
  const CopyAsImagePlaceholderImage = isLight
    ? CopyAsImagePlaceholder
    : CopyAsImagePlaceholderDark;

  const getScreenshot = useGetScreenshotFromPublicLink(
    publicSharingLinkId,
    shareBlockConfig,
    screenshotColorMode,
  );

  const onImageCTA = useCallback(
    async (type: 'copied' | 'downloaded') => {
      setButtonsDisabled(type);
      const screenshotImage = await getScreenshot(type);
      if (screenshotImage) {
        if (type === 'copied') {
          await copyImage(base64toBlob(screenshotImage));
        } else {
          downloadImage(
            `data:image/png;base64,${screenshotImage}`,
            `${downloadFileTitle} at ${formatDateToUtcIso(new Date())}.png`,
          );
        }
      } else {
        console.error('screenshotImage is undefined');
      }
      setButtonsDisabled(false);
      closeSharingModal?.();
    },
    [closeSharingModal, copyImage, downloadFileTitle, getScreenshot],
  );

  return (
    <SharingBlockModalBodyDefaultContainer>
      <SharingBlockModalBodyDefaultContentContainer>
        <Box justifyItems='center' overflow='hidden'>
          <CopyAsImagePlaceholderImage
            height='346px'
            width='490px'
            display='block'
          />
        </Box>
      </SharingBlockModalBodyDefaultContentContainer>
      <SharingBlockModalBodyDefaultActionsContainer>
        <Box marginRight='50px'>
          <CopyAsImageThemeSelector
            setColorMode={setScreenshotColorMode}
            isLight={isLight}
            disabled={!!buttonsDisabled}
          />
        </Box>
        <Button
          variant={'primaryGhost'}
          height='40px'
          onClick={onImageCTA.bind(null, 'downloaded')}
          disabled={!!buttonsDisabled}
        >
          {buttonsDisabled === 'downloaded' ? (
            <Spinner size='sm' mr='9px' />
          ) : (
            <DownloadIcon boxSize='16px' stroke='secondary.button' mr='9px' />
          )}
          {t('download_image_button_caption')}
        </Button>
        <Button
          variant={'primary'}
          height='40px'
          onClick={onImageCTA.bind(null, 'copied')}
          disabled={!!buttonsDisabled}
        >
          {buttonsDisabled === 'copied' ? (
            <Spinner size='sm' mr='9px' />
          ) : (
            <CopyIcon boxSize='16px' stroke='grey.white' mr='9px' />
          )}
          {t('copy_image_button_caption')}
        </Button>
      </SharingBlockModalBodyDefaultActionsContainer>
    </SharingBlockModalBodyDefaultContainer>
  );
}
