import { useCallback } from 'react';

import { CustomLayerProps } from '@nivo/line';

import { SeriesArea } from 'components/charts/Layers/AreasLayer/SeriesArea';
import { GetSeriesItemColorFn } from 'utils/lineChartUtils';

export const useAreasLayer = (
  getSeriesColor: GetSeriesItemColorFn | undefined,
  hasGradient: boolean,
) =>
  useCallback(
    function LinesLayer(props: CustomLayerProps) {
      return (
        <>
          {props.series.map(({ id, data, color, isReference }, serieIndex) => {
            if (!hasGradient || isReference) return null;

            const points = data
              .filter(({ data }) => data.y !== undefined)
              .map(({ data }) => ({
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                x: (props.xScale as any)(data.x ?? 0),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                y: (props.yScale as any)(data.y ?? 0),
              }));

            const seriesColor = data.map(
              (_, index) => getSeriesColor?.({ id, index }) ?? color ?? '',
            );

            return (
              <SeriesArea
                key={`series-area-${serieIndex}`}
                points={points}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                areaGenerator={(props as any).areaGenerator}
                colors={seriesColor}
                areaId={`series-area-${serieIndex}`}
              />
            );
          })}
        </>
      );
    },
    [getSeriesColor, hasGradient],
  );
