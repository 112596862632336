import { SidebarMenuDesktop } from 'components/SidebarMenu/SidebarMenuDesktop';
import { SidebarMenuMobile } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobile';
import { useSidebarNavItemConfigs } from 'components/SidebarMenu/useSidebarNavItemConfigs';
import { useComponentResponsiveVariants } from 'utils/hooks/screenBreakpoints';

export function SidebarMenu() {
  const navItemConfigs = useSidebarNavItemConfigs();
  return useComponentResponsiveVariants('SidebarMenu', {
    sm: <SidebarMenuMobile navItemConfigs={navItemConfigs} />,
    lg: <SidebarMenuDesktop navItemConfigs={navItemConfigs} />,
  });
}
