import { Datum, Serie } from '@nivo/line';
import { format } from 'date-fns';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { TimeSeries } from 'data/responseModels/TimeSeries';
import {
  convertUTCStringDateToLocalDate,
  getEndOfPeriod,
} from 'utils/datetime';

export function formatPropelLineData(
  getKeyLabel: (name: string) => string,
  timeSeries: { [k: string]: TimeSeries },
  granularity: AggregationPeriod,
  colors: string[],
): Serie[] {
  const data: Serie[] = [];
  const ids = Object.keys(timeSeries);
  ids.forEach((id, idx) => {
    const serie: Serie = {
      id: getKeyLabel(id),
      data: mapData(timeSeries[id] as TimeSeries, granularity),
      isReference: timeSeries[id]?.isReference,
      serieId: timeSeries[id]?.serieId,
      color: colors[idx % colors.length || 0],
    };
    data.push(serie);
  });
  return data;
}

const mapData = (
  result: TimeSeries,
  granularity: AggregationPeriod,
): Datum[] => {
  return result.labels
    .map((label, index) => ({
      x: format(convertUTCStringDateToLocalDate(label), 'yyyy-MM-dd'),
      y: Number(result.values[index]),
    }))
    .filter(
      (datum) =>
        result.isReference ||
        new Date(datum.x) <= getEndOfPeriod(granularity)(new Date()),
    );
};
