import { Atom, atom, Getter, PrimitiveAtom, Setter } from 'jotai';
import { WritableAtom } from 'jotai/core/atom';

import { FetchStatus } from 'utils/requests/types';

export type State<Payload> = Payload & {
  fetchStatus: FetchStatus;
  errorMessage: string;
};

export type DataFetchingAtom<Payload> = Atom<State<Payload>>;
export type WritableDataFetchingAtom<Payload, Variables> = WritableAtom<
  State<Payload>,
  Variables,
  Promise<void>
>;

function getErrorMessage(error: unknown) {
  console.error(error);
  return error instanceof Error
    ? error.message
    : `Something went wrong: ${JSON.stringify(error)}`;
}

function setFetchingErrorStatus<Payload>(
  set: Setter,
  dataAtom: PrimitiveAtom<State<Payload>>,
  error: unknown,
) {
  set(dataAtom, (value) => ({
    ...value,
    fetchStatus: 'ERROR',
    errorMessage: getErrorMessage(error),
  }));
}

const fetchData = async <Payload, Variables>(
  dataAtom: PrimitiveAtom<State<Payload>>,
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
  get: Getter,
  set: Setter,
  variables: Variables,
): Promise<void> => {
  set(dataAtom, (value) => {
    return { ...value, fetchStatus: 'LOADING', errorMessage: '' };
  });

  const response = await fetcher(variables, get, set);
  set(dataAtom, (value) => ({ ...value, fetchStatus: 'SUCCESS', ...response }));
};

/**
 * Use this method in case you don't need to differentiate between initial loading and refreshing
 * data fetching states.
 *
 * Payload: the dataAtom's payload signature.
 * Variables: object of query variables required for the fetch function.
 * @param dataAtom - see getDataAtom above.
 * @param fetcher - async function that returns the payload.
 * @returns an atom that can be used with jotai useAtom, useAtomValue or useSetAtom,
 *          to return the atom's current state and a fetch / re-fetch function.
 */
export function getDataFetchingAtom<Payload, Variables>(
  dataAtom: PrimitiveAtom<State<Payload>>,
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
): WritableDataFetchingAtom<Payload, Variables> {
  return atom(
    (get) => get(dataAtom),
    async (get, set, variables: Variables) => {
      try {
        await fetchData(dataAtom, fetcher, get, set, variables);
      } catch (error) {
        setFetchingErrorStatus(set, dataAtom, error);
      }
    },
  );
}

const fetchOrRefreshData = async <Payload, Variables>(
  dataAtom: PrimitiveAtom<State<Payload>>,
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
  get: Getter,
  set: Setter,
  variables: Variables,
): Promise<void> => {
  set(dataAtom, (value) => {
    const fetchStatus =
      value.fetchStatus === 'SUCCESS' ? 'REFRESHING' : 'LOADING';

    return { ...value, fetchStatus, errorMessage: '' };
  });

  const response = await fetcher(variables, get, set);
  set(dataAtom, (value) => ({ ...value, fetchStatus: 'SUCCESS', ...response }));
};

/**
 * Use this method in case you want to differentiate between initial loading and refreshing
 * data fetching states.
 *
 * Payload: the dataAtom's payload signature.
 * Variables: object of query variables required for the fetch function.
 * @param dataAtom - see getDataAtom above.
 * @param fetcher - async function that returns the payload.
 * @returns an atom that can be used with jotai useAtom, useAtomValue or useSetAtom,
 *          to return the atom's current state and a fetch / re-fetch function.
 */
export function getDataRefreshingAtom<Payload, Variables>(
  dataAtom: PrimitiveAtom<State<Payload>>,
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
): WritableDataFetchingAtom<Payload, Variables> {
  return atom(
    (get) => get(dataAtom),
    async (get, set, variables: Variables) => {
      try {
        await fetchOrRefreshData(dataAtom, fetcher, get, set, variables);
      } catch (error) {
        setFetchingErrorStatus(set, dataAtom, error);
      }
    },
  );
}

/**
 * Same as data fetching status but does not set error/loading states
 */
export function getDataUpdatingAtom<Payload, Variables>(
  dataAtom: PrimitiveAtom<State<Payload>>,
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
) {
  return atom(
    (get) => get(dataAtom),
    async (get, set, variables: Variables) => {
      const response = await fetcher(variables, get, set);

      set(dataAtom, (value) => ({
        ...value,
        fetchStatus: 'SUCCESS',
        ...response,
        errorMessage: '',
      }));
    },
  );
}

type FormState = {
  status: 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';
  errorMessage?: string;
};

export type WritableFormUpdatingAtom<Variables> = WritableAtom<
  FormState,
  Variables,
  Promise<void>
>;

export const isFormInitialised = ({ status }: FormState) =>
  status !== 'INITIAL';

export const isFormLoading = ({ status }: FormState) => status === 'LOADING';

export const isFormReady = ({ status }: FormState) =>
  status === 'SUCCESS' || status === 'ERROR';

export const isFormFaulty = ({ status }: FormState) => status === 'ERROR';

/**
 *
 */
export function getFormUpdatingAtom<Payload, Variables>(
  fetcher: (variables: Variables, get: Getter, set: Setter) => Promise<Payload>,
  dataAtom?: PrimitiveAtom<State<Payload>>,
  formStateAtom: PrimitiveAtom<FormState> = atom<FormState>({
    status: 'INITIAL',
  }),
): WritableFormUpdatingAtom<Variables> {
  return atom(
    (get) => get(formStateAtom),
    async (get, set, variables: Variables) => {
      set(formStateAtom, { status: 'LOADING' });
      try {
        const response = await fetcher(variables, get, set);

        if (dataAtom) {
          set(dataAtom, (value) => ({
            ...value,
            fetchStatus: 'SUCCESS',
            ...response,
            errorMessage: '',
          }));
        }

        set(formStateAtom, { status: 'SUCCESS' });
      } catch (error) {
        set(formStateAtom, {
          status: 'ERROR',
          errorMessage: getErrorMessage(error),
        });
      }
    },
  );
}
