import Image from 'next/legacy/image';

import { useTranslation } from 'react-i18next';

import { Box, Flex, HStack, Text, VStack } from 'components/uikit';

function Info({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation(['common']);
  return (
    <Box
      flexBasis='600px'
      bg='grey.primaryText'
      color='grey.white'
      h='100%'
      pl='10'
      pt='8'
    >
      <HStack spacing='10px'>
        <Image
          src='/static/calliper_logo.svg'
          width={20}
          height={20}
          alt='Calliper logo'
        />
        <Text as='span' fontSize='xl' fontWeight='bold' color='grey.white'>
          {t('app_name')}
        </Text>
      </HStack>
      {children}
    </Box>
  );
}

function Action({ children }: { children?: React.ReactNode }) {
  return (
    <VStack h='100%' flex={1} alignItems='center' justifyContent='center'>
      {children}
    </VStack>
  );
}

export function ActionLayout({ children }: { children: React.ReactNode }) {
  return (
    <Flex w='100%' h='100%'>
      {children}
    </Flex>
  );
}

ActionLayout.Info = Info;
ActionLayout.Action = Action;
