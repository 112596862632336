import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface CreateCustomerCompanyNoteParams {
  customerCompanyId: string;
  content: string;
}

export async function createCustomerCompanyNote({
  customerCompanyId,
  content,
}: CreateCustomerCompanyNoteParams): Promise<CustomerCompanyResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/${customerCompanyId}/notes`,
    {
      method: 'POST',
      body: JSON.stringify({ content }),
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to create customer company note: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompanyResponse, data);
}
