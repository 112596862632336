import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CompanyOnboardingUrl } from 'api/rest/users/resourceUrls';
import {
  CompanyOnboarding,
  UpdateCompanyOnboardingParams,
} from 'data/user/models/onboarding';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function updateCompanyOnboarding({
  step,
  progress,
}: UpdateCompanyOnboardingParams): Promise<CompanyOnboarding | null> {
  const result = await authorisedFetch(CompanyOnboardingUrl, {
    method: 'PUT',
    body: JSON.stringify({ step, progress }),
  });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to update company onboarding: ${result.status}`);
  }

  return parseItemStrict(CompanyOnboarding, data);
}
