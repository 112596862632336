import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useAtomValue } from 'jotai';

import { SidebarMenuMobileHeader } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobileHeader';
import { SidebarMenuMobileThemeSwitch } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobileThemeSwitch';
import { SidebarMenuMobileUserButton } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobileUserButton';
import {
  SidebarNavItem,
  SidebarNavItemProps,
} from 'components/SidebarMenu/SidebarNavItem';
import { Box, VStack } from 'components/uikit';
import { bodyRefAtom, isSideMenuOpenAtom } from 'data/layout/atoms/layoutAtoms';
import { useCloseSideMenu } from 'data/layout/hooks/useNavHeight';

export function SidebarMenuMobile({
  navItemConfigs,
}: {
  navItemConfigs: SidebarNavItemProps[];
}) {
  const router = useRouter();
  const isSideMenuOpen = useAtomValue(isSideMenuOpenAtom);
  const closeSideMenu = useCloseSideMenu();
  const sidebarContainerRef = useRef<HTMLDivElement>(null);
  const bodyRed = useAtomValue(bodyRefAtom);

  useEffect(() => {
    if (!sidebarContainerRef.current || !bodyRed.current) {
      return;
    }

    if (!isSideMenuOpen) {
      bodyRed.current.style.transform = 'none';
      sidebarContainerRef.current.style.transform = 'none';
    } else {
      sidebarContainerRef.current.style.transform = 'translateX(100%)';
      bodyRed.current.style.transform = 'translateX(100%)';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideMenuOpen]);

  useEffect(() => {
    closeSideMenu();
  }, [closeSideMenu, router]);

  const navItems = navItemConfigs
    .filter((item) => !item.hideOnMobile)
    .map((props) => <SidebarNavItem key={props.path} {...props} />);

  return (
    <Box
      w='100vw'
      h='100%'
      position='fixed'
      top='0'
      left='-100%'
      bottom='0'
      bg={'#242D3C'}
      ref={sidebarContainerRef}
      transition='all .2s cubic-bezier(0.17, 0.59, 0.4, 0.77)'
    >
      <SidebarMenuMobileHeader />

      <VStack spacing='10px' w='100%' p='16px'>
        {navItems}
      </VStack>

      <VStack
        w='100%'
        bottom='68px'
        position='absolute'
        p='16px'
        spacing='16px'
      >
        <SidebarMenuMobileThemeSwitch />
        <SidebarMenuMobileUserButton />
      </VStack>
    </Box>
  );
}
