import gql from 'graphql-tag';
import * as Urql from 'urql';

import * as Types from '../../../generatedTypes';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDataSourcesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDataSourcesQuery = {
  __typename?: 'Query';
  dataSources: Array<{
    __typename?: 'DataSource';
    id?: string | null;
    status: Types.DataSourceStatus;
    provider: Types.DataSourceProvider;
    connectionMethod: Types.ConnectionMethod;
    lastSyncedAt?: string | null;
  }>;
};

export const GetDataSourcesDocument = gql`
  query getDataSources {
    dataSources {
      id
      status
      provider
      connectionMethod
      lastSyncedAt
    }
  }
`;

export function useGetDataSourcesQuery(
  options?: Omit<Urql.UseQueryArgs<GetDataSourcesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetDataSourcesQuery, GetDataSourcesQueryVariables>({
    query: GetDataSourcesDocument,
    ...options,
  });
}
