import { createIcon } from '@chakra-ui/react';

export const CustomerUserIcon = createIcon({
  displayName: 'CustomerUserIcon',
  viewBox: '0 0 25 26',
  path: (
    <path
      d='M23.263 25V22.3333C23.263 20.9188 22.7011 19.5623 21.7009
         18.5621C20.7007 17.5619 19.3442 17 17.9297 17H7.26302C5.84853 17
         4.49198 17.5619 3.49178 18.5621C2.49159 19.5623 1.92969 20.9188
         1.92969 22.3333V25M17.9297 6.33333C17.9297 9.27885 15.5419 11.6667
         12.5964 11.6667C9.65083 11.6667 7.26302 9.27885 7.26302 6.33333C7.26302
         3.38781 9.65083 1 12.5964 1C15.5419 1 17.9297 3.38781 17.9297 6.33333Z'
      fill='none'
      stroke='#667085'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
