import { createIcon } from '@chakra-ui/react';

export const BulbIcon = createIcon({
  displayName: 'BulbIcon',
  viewBox: '0 0 17 17',
  path: (
    <>
      <path
        d='M8.60355 11.6424H6.44927V9.35414C5.36815 8.65047 4.65332 7.43132 4.65332 6.04521C4.65332 3.86633 6.41965 2.10001 8.59853 2.10001C10.7774 2.10001 12.5437 3.86633 12.5437 6.04521C12.5437 7.43049 11.8379 8.64526 10.7578 9.34912V11.6424H8.60355ZM8.60355 11.6424V6.86104M8.60355 6.86104H6.70903H10.4981H8.60355Z'
        strokeLinejoin='round'
        fill='none'
      />
      <line
        x1='6.83057'
        y1='13'
        x2='10.3765'
        y2='13'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <line
        x1='7.68262'
        y1='14.3333'
        x2='9.52382'
        y2='14.3333'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </>
  ),
});
