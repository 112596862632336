import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { SHARING_LINK_PUBLIC_SCREENSHOT_URL } from 'api/rest/sharing/resourceUrls';
import { ScreenshotOneTimeZones } from 'data/sharingLink/models/screenshotOne';
import { parseItem } from 'utils/parser/parseResponse';
import { RequestStatus } from 'utils/requests/types';
import { buildUrlSearchParams } from 'utils/url/urlQueryUtils';

const GetScreenshotUrlResponse = z.string().optional();

type GetScreenshotUrlResponse = z.infer<typeof GetScreenshotUrlResponse>;

function getErrorMessage(error: unknown) {
  return error instanceof Error
    ? error.message
    : `Error taking a screenshot: ${JSON.stringify(error)}`;
}

export async function getScreenshotUrlFromPublicLink(
  linkId: string,
  urlParams: string,
  timeZone: ScreenshotOneTimeZones = 'Europe/London',
  selector = 'body',
  viewport?: {
    width: number;
    height: number;
  },
): Promise<RequestStatus<GetScreenshotUrlResponse>> {
  let response: Response;

  const viewportParams = viewport
    ? {
        'viewport[width]': viewport.width,
        'viewport[height]': viewport.height,
      }
    : undefined;
  const queryParams = buildUrlSearchParams({
    linkId,
    urlParams,
    timeZone,
    selector,
    ...viewportParams,
  });

  try {
    response = await authorisedFetch(
      `${SHARING_LINK_PUBLIC_SCREENSHOT_URL}?${queryParams}`,
    );
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      fetchStatus: 'ERROR',
      errorMessage,
    };
  }

  if (!response.ok) {
    return {
      fetchStatus: 'ERROR',
      errorMessage: getErrorMessage(response),
    };
  }

  const image = parseItem(
    z.object({ image: GetScreenshotUrlResponse }),
    await response.json(),
  );

  return {
    fetchStatus: 'SUCCESS',
    response: image?.image,
  };
}
