import { useCallback, useMemo, useState } from 'react';

import { LayoutProps, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChevronDownIcon } from 'components/uikit/icons';
import { List } from 'components/uikit/List';
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from 'components/uikit/reexport';
import { SimpleMultiSelectRow } from 'components/uikit/SimpleMultiSelect/SimpleMultiSelectRow';
import { Skeleton } from 'components/uikit/Skeleton';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

export interface SelectItem<T> {
  label: string;
  value: T;
}

interface MultiSelectProps<T> {
  items: SelectItem<T>[];
  renderItem?: (item: T) => React.ReactNode;
  onChange: (values: SelectItem<T>[]) => void;
  selectedItems?: SelectItem<T>[];
  multiselectTranslationKey?: string;
  labelKey: string;
  width: LayoutProps['width'];
  isLoading?: boolean;
}

export function SimpleMultiSelect<T>({
  items,
  onChange,
  width,
  labelKey,
  isLoading,
  selectedItems = [],
  renderItem,
  multiselectTranslationKey = 'multiselect',
}: MultiSelectProps<T>) {
  const [hoveredItem, setHoveredItem] = useState<T | null>(null);
  const [isActive, setIsActive] = useState(false);
  const { onClose } = useDisclosure();
  const { t } = useTranslation('common');
  const selected = selectedItems.filter(
    (selectedItem) => !!items.find((item) => item.value === selectedItem.value),
  );

  const getItemByValue = useCallback(
    (value: T) => {
      const item = items.find((item) => item.value === value);

      if (!item) {
        throw new Error('Item not found');
      }

      return item;
    },
    [items],
  );

  const isSelected = useCallback(
    (value: T) => {
      return selected.findIndex((item) => item.value === value) !== -1;
    },
    [selected],
  );

  const handleSelection = useCallback(
    (value: T) => {
      const newSelection = Array.from(selected);
      const selectedItem = getItemByValue(value);

      const selectedItemIndex = newSelection.findIndex(
        (item) => item.value === selectedItem.value,
      );

      if (selectedItemIndex === -1) {
        newSelection.push(selectedItem);
      } else {
        newSelection.splice(selectedItemIndex, 1);
      }

      onChange(newSelection);

      return newSelection;
    },
    [selected, getItemByValue, onChange],
  );

  const selectHoveredItem = useCallback(() => {
    if (hoveredItem === null) {
      return;
    }

    handleSelection(hoveredItem);
  }, [hoveredItem, handleSelection]);

  useKeyAction('CLOSE', onClose, {
    preventDefault: true,
    enabled: isActive,
  });

  useKeyAction('CONFIRM', selectHoveredItem, {
    preventDefault: true,
    enabled: isActive,
  });

  const renderedItems: SelectItem<T>[] = useMemo(() => items, [items]);

  return (
    <Flex direction='column' gap='5px'>
      <Text color='grey.secondaryText' fontWeight='medium' fontSize='sm'>
        {t(labelKey)}
      </Text>
      <Skeleton isLoaded={!isLoading} height='48px'>
        <Popover
          placement='bottom-end'
          onClose={() => {
            setIsActive(false);
            onClose();
          }}
          onOpen={() => {
            setIsActive(true);
          }}
        >
          <PopoverTrigger>
            <Button
              h='48px'
              p={0}
              variant='grayOutline'
              borderRadius='4px'
              width='100%'
              _hover={{
                'div:last-child': {
                  backgroundColor: 'grey.offWhite',
                },
              }}
            >
              <Flex
                backgroundColor='grey.white'
                p='0 10px'
                h='46px'
                alignItems='center'
                borderRadius='4px'
                fontWeight='500'
                justifyContent='space-between'
                minW='76px'
                width={width}
              >
                <Text opacity={selected.length === 0 ? 0.5 : 1}>
                  {t(multiselectTranslationKey, {
                    count: selected.length,
                  })}
                </Text>
                <ChevronDownIcon w='20px' h='20px' stroke='borderDark' />
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            borderRadius='4px'
            borderColor={'grey.border'}
            border='0.5px'
            width={width}
          >
            <PopoverBody
              p={0}
              borderRadius='4px'
              maxH='400px'
              overflow='auto'
              border='0.5px solid'
              borderColor={'grey.border'}
            >
              <List
                items={renderedItems}
                hotkeysActive={isActive}
                onItemSelected={(item) => {
                  if (item) {
                    setHoveredItem(item.value);
                  }
                }}
                renderItem={(
                  { label, value },
                  isHovered,
                  hoverItem,
                  removeHover,
                  index,
                ) => {
                  return (
                    <SimpleMultiSelectRow<T>
                      value={value}
                      label={renderItem?.(value) ?? label}
                      index={index}
                      isActive={isActive}
                      isSelected={isSelected(value)}
                      isIndeterminate={false}
                      isHovered={isHovered}
                      onSelect={() => handleSelection(value)}
                      onHover={hoverItem}
                      onBlur={removeHover}
                    />
                  );
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Skeleton>
    </Flex>
  );
}
