import { DatumValue } from '@nivo/line';

import { LegendKey } from 'components/charts/ChartLegend';
import { Breakdown } from 'data/blocks/models/BreakdownConfig';
import { StaticChartSeries } from 'data/blocks/models/ChartConfig';
import { TimeSeries } from 'data/responseModels/TimeSeries';
import { getDefaultCurrency } from 'data/settings/hooks/finance/useDefaultCurrency';
import { Currency, formatWholeNumber } from 'utils/formatting/number';
import { isEmpty } from 'utils/helpers';

const MinYAxisScale = 6;

export const getChartColors = (
  colorPalette: Record<string, string>,
  keys: string[],
): string[] => {
  const colors = Object.values(colorPalette);
  if (keys.find((key) => key === 'other')) {
    return [
      ...colors.slice(0, (keys.length - 1) % colors.length),
      colorPalette.gray ?? '',
    ];
  } else {
    return colors;
  }
};

export function getLegendKeys<
  T extends { id: string | number; isReference?: boolean },
>(data: T[], colors: string[]): LegendKey[] {
  return data.map(({ id, isReference = false }, index) => ({
    key: id?.toString(),
    isReference,
    color: colors[index % colors.length] ?? 'dataViz.1',
  }));
}

/*
 * This function tries to find a nice scale for a given value by sort of
 * scaling the number to a multiple of 10, and yielding a higher number
 * than the original value.
 */
export function getNiceScale(value: number): number {
  if (!isFinite(value)) {
    return value;
  }

  let result = value;
  let multiplier = 1;

  if (-MinYAxisScale <= result && result <= MinYAxisScale) {
    result = Math.sign(result) * MinYAxisScale;
  }

  if (result < 0) {
    result = -result;
    multiplier = -1;
  }

  while (result > 10) {
    if (result > 20) {
      result = Math.floor((result + 9) / 10);
      multiplier *= 10;
    } else if (result > 10) {
      result = Math.floor((result + 4) / 5);
      multiplier *= 5;
    }
  }

  return result * multiplier;
}

export function addOpacityToChartColor(
  hexCode: string,
  opacity: number,
): string {
  if (opacity >= 100) return `${hexCode}FF`;
  if (opacity <= 0) return `${hexCode}00`;
  return `${hexCode}${Math.round(opacity * 255).toString(16)}`;
}

/*
 * Adds all values of the list of series into a single series and trims all leading zeros
 * Returns null if the list is empty
 */
export function reduceStaticSeries(
  seriesList: StaticChartSeries[],
): StaticChartSeries | null {
  if (isEmpty(seriesList)) {
    return null;
  }

  const reducedSeries = seriesList.reduce((acc, series) => ({
    ...series,
    values: series.values
      .map((v) => Number(v || 0))
      .map((value, i) => String(value + Number(acc.values[i]))),
  }));

  return {
    ...reducedSeries,
    values: reducedSeries.values,
    labels: reducedSeries.labels,
  };
}

export function buildStaticSeriesName(
  staticSeries: StaticChartSeries,
  translateFn: (key: string) => string,
): string {
  const metricName = staticSeries.metricName
    ? translateFn(`metric_name.${staticSeries.metricName}`)
    : 'Unknown Series';
  const segmentName =
    staticSeries.labelMetadata.segmentName &&
    translateFn(staticSeries.labelMetadata.segmentName);
  const segmentValue = staticSeries.labelMetadata.segmentValue;

  if (!segmentName || !segmentValue) return metricName;

  return `${metricName} from ${segmentName} ${segmentValue}`;
}

export function getChartDefaultChartMargins(isMobile: boolean) {
  return {
    top: isMobile ? 10 : 20,
    bottom: isMobile ? 30 : 50,
    left: isMobile ? 40 : 60,
    right: 20,
  };
}

export function getTimeSeriesCurrency(data: Record<string, TimeSeries>) {
  return Object.values(data)
    .flatMap((series) => series.currencies ?? [])
    .reduce((prevCurrency: Currency | null, currentCurrency, idx) => {
      if (prevCurrency !== currentCurrency && idx !== 0) {
        console.warn('Multiple currencies found in time series data');
      }
      return currentCurrency ?? prevCurrency;
    }, null);
}

export function getBreakdownTableCurrency(breakdown: Breakdown) {
  const defaultCurrency = getDefaultCurrency();

  return (
    breakdown.rows
      .flatMap((row) => row.cells)
      .reduce(
        (
          prevCurrency: Currency | null,
          { currency: currentCurrency = null },
          idx,
        ) => {
          if (prevCurrency !== currentCurrency && idx !== 0) {
            console.warn(
              `Multiple currencies found in breakdown table data: ${prevCurrency} and ${currentCurrency}`,
            );
          }

          return currentCurrency || prevCurrency;
        },
        null,
      ) || defaultCurrency
  );
}

export const formatNumericDatum = (value: DatumValue) =>
  formatWholeNumber(Number(value));
