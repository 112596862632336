import { CloseButton } from '@chakra-ui/react';

import { UserOfCompany } from 'components/CustomerDrawer/UserDrawer/DrawerHeader/UserOfCompany';
import {
  Box,
  CustomerUserIcon,
  Flex,
  Heading,
  HStack,
  Skeleton,
  VStack,
} from 'components/uikit';
import {
  useCustomerCompany,
  useCustomerUser,
} from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { getCustomerName } from 'data/healthReports/utils/getCustomerAnyDepartmentId';
import { getDepartmentIdFromCustomer } from 'utils/customers/getDepartmentIdFromCustomer';
import { isLoading } from 'utils/types';

interface UserBasicInfoAndCloseButtonProps {
  customerUserId: string;
  onDrawerClose: () => void;
}

export function UserBasicInfoAndCloseButton({
  customerUserId,
  onDrawerClose,
}: UserBasicInfoAndCloseButtonProps) {
  const { user, fetchStatus: userFetchStatus } =
    useCustomerUser(customerUserId);
  const { company, fetchStatus: userCompanyFetchStatus } =
    useCustomerCompany(customerUserId);
  const { department, departmentId } = getDepartmentIdFromCustomer(company);

  const userCompanyExists =
    !!user.customerCompanyId && !!department && !!departmentId;

  return (
    <Flex
      width={'100%'}
      flexDirection={'row'}
      justify={'space-between'}
      gap={'10px'}
    >
      <HStack>
        <Box
          boxSize={'48px'}
          p={'8px'}
          background={'grey.offWhite'}
          border={'1px solid'}
          borderColor={'grey.border'}
          borderRadius={'4px'}
        >
          <CustomerUserIcon boxSize={'32px'} p={'3px'} />
        </Box>
        <VStack alignItems='left' spacing={0}>
          {isLoading(userFetchStatus) ? (
            <Skeleton h='24px' w='200px' mb='2px' />
          ) : (
            <Heading
              as={'h5'}
              fontWeight={'semibold'}
              noOfLines={1}
              fontSize={'18px'}
              lineHeight={1.3}
            >
              {getCustomerName(user)}
            </Heading>
          )}
          <Box h='22.4px'>
            {userCompanyExists ? (
              isLoading(userCompanyFetchStatus) ? (
                <Skeleton h='24px' w='200px' mb='2px' />
              ) : (
                <UserOfCompany
                  customerUserId={customerUserId}
                  department={department}
                  departmentId={departmentId}
                  onDrawerClose={onDrawerClose}
                />
              )
            ) : null}
          </Box>
        </VStack>
      </HStack>
      <CloseButton
        height={'24px'}
        width={'24px'}
        background={'grey.background'}
        color={'grey.tertiaryText'}
        onClick={onDrawerClose}
      />
    </Flex>
  );
}
