import { z } from 'zod';

import { Department } from 'data/blocks/models/Block';
import { BlockData } from 'data/blocks/models/BlockDataGoal';
import { BreakdownConfig } from 'data/blocks/models/BreakdownConfig';
import { ChartConfig } from 'data/blocks/models/ChartConfig';
import { convertUTCStringDateToLocalDate } from 'utils/datetime';

export const InsightStatus = z.enum([
  'BACKLOG',
  'REVIEWED',
  'SHORTLISTED',
  'SCHEDULED',
  'SHARED',
  'ARCHIVED',
]);

export const InsightType = z.enum(['HIGHLIGHT', 'INSIGHT', 'DIGEST']);
export type InsightType = z.infer<typeof InsightType>;

export const InsightReasonFeedback = z.object({
  notActionable: z.boolean(),
  dataIsWrong: z.boolean(),
  alreadyKnewThis: z.boolean(),

  actionable: z.boolean(),
  helpTrackPerformance: z.boolean(),
  saveTime: z.boolean(),
});
export type InsightReasonFeedback = z.infer<typeof InsightReasonFeedback>;

export const InsightTextFeedback = z.object({
  feedback: z.string(),
});
export type InsightTextFeedback = z.infer<typeof InsightTextFeedback>;

export const InsightFeedback = z.union([
  InsightReasonFeedback,
  InsightTextFeedback,
]);
export type InsightFeedback = z.infer<typeof InsightFeedback>;

export const InsightBlockChild = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
});
export type InsightBlockChild = z.infer<typeof InsightBlockChild>;

export const SlimInsight = z.object({
  blockId: z.string(),
  company: z.string(),
  insightId: z.string(),
  specialMention: z.string().nullish(),
  model: z.string().nullable(),
  type: InsightType,
  startDate: z
    .string()
    .nullish()
    .transform((value) =>
      value ? convertUTCStringDateToLocalDate(value) : null,
    ),
  endDate: z
    .string()
    .nullish()
    .transform((value) =>
      value ? convertUTCStringDateToLocalDate(value) : null,
    ),
  sharedAt: z.coerce.date().nullable(),
  insertedAt: z.coerce.date(),
});

export const BaseInsight = SlimInsight.extend({
  title: z.string().nullable(),
  description: z.string().nullable(),
  department: Department.nullable(),
  charts: z.array(ChartConfig),
  data: BlockData,
  breakdowns: z.array(BreakdownConfig),
  children: z.array(InsightBlockChild),
});
export type BaseInsight = z.infer<typeof BaseInsight>;

export const Insight = BaseInsight.extend({
  first: z.boolean().default(false),
  externalRating: z.number().nullable(),
  feedback: InsightFeedback.nullable(),
  bookmarked: z.boolean(),
});
export type Insight = z.infer<typeof Insight>;

export const AdminInsight = BaseInsight.extend({
  feedbacks: z.array(
    z.object({
      profileId: z.string(),
      rating: z.number(),
      feedback: InsightFeedback.nullable(),
    }),
  ),
});
export type AdminInsight = z.infer<typeof AdminInsight>;

export const CountInsightsResponse = z.object({
  month: z.coerce.date(),
  count: z.coerce.number(),
});
export type CountInsightsResponse = z.infer<typeof CountInsightsResponse>;

export const InsightsStartDateResponse = z.object({
  startDate: z.coerce.date().nullable(),
});
export type InsightsStartDateResponse = z.infer<
  typeof InsightsStartDateResponse
>;

export interface InsightFilters {
  type: InsightType[];
  department: Department[];
  companies?: string[];
  onlyBookmarked?: boolean;
}

export type PaginatedRequest<T> = T & {
  before?: Date;
  after?: Date;
  limit: number;
};

export interface UpdateInsightExternalRatingRequest {
  insightId: string;
  externalRating: number;
}

export interface UpdateInsightBookmarkRequest {
  insightId: string;
  bookmarked: boolean;
}

export interface UpdateInsightFeedbackRequest {
  insightId: string;
  feedback: InsightFeedback;
}
