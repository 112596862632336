import { createIcon } from '@chakra-ui/react';

export const SearchPulseIcon = createIcon({
  displayName: 'SearchPulseIcon',
  viewBox: '0 0 16 15',
  path: (
    <path
      d='M10.8278 6.78906H9.47363L8.45801 9.83594L6.42676 3.74219L5.41113 6.78906H4.05697M14.6533 14L11.5117 10.8583M13.2089 6.77778C13.2089 9.96876 10.6221 12.5556 7.4311 12.5556C4.24012 12.5556 1.65332 9.96876 1.65332 6.77778C1.65332 3.5868 4.24012 1 7.4311 1C10.6221 1 13.2089 3.5868 13.2089 6.77778Z'
      fill='none'
      strokeWidth='1.33'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
