import { useAnimatedPath } from '@nivo/core';
import { CustomLayerProps } from '@nivo/line';
import { animated } from '@react-spring/web';

export type LineGenerator = CustomLayerProps['lineGenerator'];
type GeneratedLine = ReturnType<LineGenerator>;

export function LineSegment(props: {
  generatedLine: GeneratedLine;
  color: string;
  dashed: boolean;
  thickness: number;
  opacity: number;
}) {
  const { generatedLine, color, dashed, thickness, opacity } = props;
  const animatedLine = useAnimatedPath(generatedLine);

  return (
    <animated.path
      d={animatedLine}
      fill='none'
      strokeWidth={thickness}
      opacity={opacity}
      strokeDasharray={dashed ? '5 3' : undefined}
      stroke={color}
    />
  );
}
