import { atom, WritableAtom } from 'jotai';

import { ChartSplitOption } from 'utils/types';

export function getSplitAtoms<T extends ChartSplitOption | undefined>(
  initialValue: T,
  fetchData: WritableAtom<unknown, string>,
) {
  const splitValueAtom = atom<T>(initialValue);
  const applySplitAtom = atom<T, { split: T } & { blockId: string }, void>(
    (get) => get(splitValueAtom),
    (get, set, { split, blockId }) => {
      set(splitValueAtom, split);
      set(fetchData, blockId);
    },
  );

  return {
    splitValueAtom,
    applySplitAtom,
  };
}
