import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict, Parser } from 'utils/parser/parseResponse';

export interface RecordsChartConfig {
  customerUserId?: string;
  customerCompanyId?: string;
  filters?: Filter[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchRecordChart<T = any>(
  blockId: string,
  chartConfig: RecordsChartConfig,
  parser: Parser<T>,
): Promise<BlockResponse<T>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      blocks: [{ blockId, chartConfig }],
    }),
  });

  const json = await result.json();
  const [data] = parseItemStrict(z.tuple([BlockResponse(parser)]), json);

  return data;
}
