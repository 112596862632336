import { useTranslation } from 'react-i18next';

import { RadarInsightDesktopDescription } from 'components/pages/Radar/RadarInsight/RadarInsightDesktopDescription';
import {
  Button,
  Card,
  Flex,
  Skeleton,
  Tag,
  Text,
  VStack,
} from 'components/uikit';
import { useInsightActions } from 'data/ai/hooks';
import { InsightAction } from 'data/ai/models';
import { responsive } from 'utils/responsiveUtils';
import { resolveState } from 'utils/types';

export interface InsightActionsProps {
  insightId: string;
}

export function InsightActions({ insightId }: InsightActionsProps) {
  const { actionsRemote, fetchActions } = useInsightActions(insightId);
  const { t } = useTranslation('radar');
  return (
    <Card maxWidth='306px' width='306px' overflow='hidden'>
      <Flex borderBottom='1px' borderColor='grey.border' p='16px'>
        <Text
          fontSize={responsive('sm', 'md')}
          fontWeight={responsive('medium', 'semibold')}
        >
          {t('insight_action_title')}
        </Text>
      </Flex>
      <VStack
        backgroundColor='grey.offWhite'
        spacing='16px'
        p='16px'
        align='start'
      >
        {resolveState(actionsRemote, {
          INITIAL: () => (
            <Button variant='tertiary' onClick={() => fetchActions(insightId)}>
              {t('insight_action_button')}
            </Button>
          ),
          LOADING: () => (
            <>
              <Skeleton w='100%' h='176px' />
              <Skeleton w='100%' h='176px' />
            </>
          ),
          SUCCESS: ({ actions }) => <InsightActionResults actions={actions} />,
          ERROR: () => (
            <>
              <Text color='error.text'>{t('insight_action_error')}</Text>
              <Button
                variant='tertiary'
                onClick={() => fetchActions(insightId)}
              >
                {t('insight_action_retry_button')}
              </Button>
            </>
          ),
        })}
      </VStack>
    </Card>
  );
}

interface InsightActionResultsProps {
  actions: InsightAction[];
}
export function InsightActionResults({ actions }: InsightActionResultsProps) {
  const { t } = useTranslation('radar');
  return (
    <>
      {actions.map(({ actionType, description }) => (
        <Card key={actionType} boxShadow='none' p='16px' gap='8px'>
          <Tag
            colorScheme={actionType === 'QUICK_WIN' ? 'purple' : 'blue'}
            height='24px'
            borderRadius='2px'
            display='flex'
            justifyContent='center'
            alignSelf='start'
          >
            {t(`insight_actions_tag_${actionType.toLowerCase()}`)}
          </Tag>
          <RadarInsightDesktopDescription
            description={description}
            type='INSIGHT'
          />
        </Card>
      ))}
    </>
  );
}
