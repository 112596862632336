import { z } from 'zod';

import { DepartmentIdsArrays } from 'data/customer/DepartmentIdsArrays';

export const CustomerUser = DepartmentIdsArrays.extend({
  id: z.string().uuid(),
  name: z.string(),
  companyId: z.string(),
  customerCompanyId: z.string().nullable(),
  productIds: z.string().array().min(1),
  insertedAt: z.coerce.date(),
  tags: z.string().uuid().array(),
  notes: z.string().uuid().array(),
  sentiment: z.string().nullable(),
  sentimentUpdatedAt: z.coerce.date().nullable(),
  updatedAt: z.coerce.date(),
});

export type CustomerUser = z.infer<typeof CustomerUser>;
