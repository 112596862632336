import { createIcon } from '@chakra-ui/react';

export const LineChartSkeleton = createIcon({
  displayName: 'LineChartSkeleton',
  viewBox: '0 0 587 281',
  path: (
    <>
      <path
        d='M94.6283 123.409L0.150146 199.543V280.48C285.986 279.024 586.347 280.001 586.347 280.001L585.378 0.992676L494.879 111.671L405.16 123.409L315.44 53.7459L224.161 156.948L94.6283 123.409Z'
        fill='url(#skeleton_gradient)'
      />
      <defs>
        <linearGradient
          id='skeleton_gradient'
          x1='293.249'
          y1='0.992676'
          x2='293.249'
          y2='280.48'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#98A2B3' stopOpacity='0.25' />
          <stop offset='1' stopColor='#98A2B3' stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  ),
});
