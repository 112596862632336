import { CloseButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { HStack, Text, VStack } from 'components/uikit';
import { ArchiveIcon } from 'components/uikit/icons/Archive';

type AiDrawerHeaderProps = {
  onClose: () => void;
};

export function AiChatArchiveHeader({ onClose }: AiDrawerHeaderProps) {
  const { t } = useTranslation('ai');

  return (
    <VStack
      p='24px'
      spacing='16px'
      backgroundColor='grey.white'
      borderBottom='1px solid'
      borderColor='grey.border'
      align='stretch'
    >
      <HStack justify='space-between' align='center'>
        <HStack justify='start' align='center' spacing='8px'>
          <ArchiveIcon boxSize='24px' />
          <Text as='h3' fontSize='2xl' fontWeight='semibold'>
            {t('question_archive')}
          </Text>
        </HStack>
        <CloseButton
          height='24px'
          width='24px'
          background='grey.background'
          color='grey.tertiaryText'
          onClick={onClose}
        />
      </HStack>
    </VStack>
  );
}
