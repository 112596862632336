import { useRouter } from 'next/router';

import { Parser } from 'utils/parser/parseResponse';
import { castQueryParamToBoolean } from 'utils/url/urlQueryUtils';

export function useQueryParam(key: string): string {
  const router = useRouter();
  const param = router.query[key];
  return typeof param === 'string' ? param : '';
}

export function useBooleanQueryParam(key: string): boolean {
  const param = useQueryParam(key);
  return castQueryParamToBoolean(param);
}

export function useParsedQueryParam<Output, Input = Output>(
  parser: Parser<Output, Input>,
  key: string,
): Output | undefined {
  const param = useQueryParam(key);

  return param === '' ? undefined : parser.parse(param);
}
