import { z } from 'zod';

export const GrowthSummaryUniqueBlockName = z.enum([
  'METRICS_GROWTH_SUMMARY_NEW_VISITORS',
  'METRICS_GROWTH_SUMMARY_SESSIONS',
  'METRICS_GROWTH_SUMMARY_ENGAGEMENT_RATE',
  'METRICS_GROWTH_SUMMARY_TIME_ON_SITE',
  'METRICS_GROWTH_SUMMARY_LEADS',
]);
export type GrowthSummaryUniqueBlockName = z.infer<
  typeof GrowthSummaryUniqueBlockName
>;

export const FinanceValueSubpanels = z.enum([
  'METRICS_FINANCE_SUMMARY_MRR',
  'METRICS_FINANCE_SUMMARY_NET_MRR_CHANGE',
  'METRICS_FINANCE_SUMMARY_NEW_CUSTOMERS_MRR',
  'METRICS_FINANCE_SUMMARY_EXPANSION',
  'METRICS_FINANCE_SUMMARY_CHURN',
]);
export const FinanceCustomersSubpanels = z.enum([
  'METRICS_FINANCE_SUMMARY_RECURRING_CUSTOMERS',
  'METRICS_FINANCE_SUMMARY_NEW_TRIALS',
  'METRICS_FINANCE_SUMMARY_NEW_CUSTOMERS',
  'METRICS_FINANCE_SUMMARY_EXPANSION_CUSTOMERS',
  'METRICS_FINANCE_SUMMARY_CHURN_CUSTOMERS',
]);
export const FinanceSummaryUniqueBlockName = z.enum([
  ...FinanceValueSubpanels.options,
  ...FinanceCustomersSubpanels.options,
]);
export type FinanceSummaryUniqueBlockName = z.infer<
  typeof FinanceSummaryUniqueBlockName
>;

export const ProductUsersSubpanels = z.enum([
  'METRICS_PRODUCT_SUMMARY_NEW_SIGN_UPS',
  'METRICS_PRODUCT_SUMMARY_ACTIVE_USERS',
  'METRICS_PRODUCT_SUMMARY_ENGAGED_USERS',
  'METRICS_PRODUCT_SUMMARY_POWER_USERS',
  'METRICS_PRODUCT_SUMMARY_WAU_MAU',
]);
export const ProductCompaniesSubpanels = z.enum([
  'METRICS_PRODUCT_SUMMARY_COMPANIES_NEW_SIGN_UPS',
  'METRICS_PRODUCT_SUMMARY_ACTIVE_COMPANIES',
  'METRICS_PRODUCT_SUMMARY_ENGAGED_COMPANIES',
  'METRICS_PRODUCT_SUMMARY_POWER_COMPANIES',
  'METRICS_PRODUCT_SUMMARY_WAC_MAC',
]);
export const ProductEngagementSubpanels = z.enum([
  'METRICS_PRODUCT_SUMMARY_SIGNAL_ONE',
  'METRICS_PRODUCT_SUMMARY_SIGNAL_TWO',
  'METRICS_PRODUCT_SUMMARY_SIGNAL_THREE',
  'METRICS_PRODUCT_SUMMARY_SIGNAL_FOUR',
  'METRICS_PRODUCT_SUMMARY_SIGNAL_FIVE',
]);
export const ProductSummaryUniqueBlockName = z.enum([
  ...ProductUsersSubpanels.options,
  ...ProductCompaniesSubpanels.options,
  ...ProductEngagementSubpanels.options,
]);
export type ProductSummaryUniqueBlockName = z.infer<
  typeof ProductSummaryUniqueBlockName
>;

export const SalesValueSubpanels = z.enum([
  'METRICS_SALES_SUMMARY_SALES',
  'METRICS_SALES_SUMMARY_PIPELINE_VALUE',
  'METRICS_SALES_SUMMARY_AVG_DEAL_VALUE',
  'METRICS_SALES_SUMMARY_WIN_RATE',
  'METRICS_SALES_SUMMARY_AVG_SALES_CYCLE',
]);
export const SalesCompaniesSubpanels = z.enum([
  'METRICS_SALES_SUMMARY_WON_DEALS',
  'METRICS_SALES_SUMMARY_PIPELINE_DEALS_COUNT',
  'METRICS_SALES_SUMMARY_AVG_DEAL_VALUE_COMPANIES',
  'METRICS_SALES_SUMMARY_WIN_RATE_COMPANIES',
  'METRICS_SALES_SUMMARY_AVG_SALES_CYCLE_COMPANIES',
]);
export const SalesSummaryUniqueBlockName = z.enum([
  ...SalesValueSubpanels.options,
  ...SalesCompaniesSubpanels.options,
]);
export type SalesSummaryUniqueBlockName = z.infer<
  typeof SalesSummaryUniqueBlockName
>;
export const SupportSummaryUniqueBlockName = z.enum([
  'METRIC_DICTIONARY_SUPPORT_OUTBOUND_CONVERSATIONS_SUMMARY',
]);
export const MetricSummaryBlockName = z.enum([
  ...GrowthSummaryUniqueBlockName.options,
  ...FinanceSummaryUniqueBlockName.options,
  ...SalesSummaryUniqueBlockName.options,
  ...ProductSummaryUniqueBlockName.options,
  ...SupportSummaryUniqueBlockName.options,
]);
export type MetricSummaryBlockName = z.infer<typeof MetricSummaryBlockName>;
