import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

import { Box, BoxProps, chakra, Text } from 'components/uikit';
import { DELTA_UNAVAILABLE } from 'data/charts/models/SummaryCard';
import { NumberFormatter } from 'utils/formatting/number';
import { getSentiment, Sentiment } from 'utils/getSentiment';
import { responsive } from 'utils/responsiveUtils';

export type DeltaValue = number | typeof DELTA_UNAVAILABLE;

const getColor = (sentiment: Sentiment) => {
  switch (sentiment) {
    case 'GOOD':
      return 'primary.text';
    case 'BAD':
      return 'error.text';
    default:
      return 'grey.text';
  }
};

const getBackground = (sentiment: Sentiment) => {
  switch (sentiment) {
    case 'GOOD':
      return 'primary.background';
    case 'BAD':
      return 'error.background';
    default:
      return 'grey.background';
  }
};

// arrow should reflect up / down whether or not it's an inverse metric
const getIcon = (value: DeltaValue, color: string) => {
  if (value === DELTA_UNAVAILABLE) {
    return null;
  }
  if (value === 0)
    return (
      <Text
        variant='numeric'
        fontSize='xs'
        fontWeight='600'
        h='20px'
        color={color}
      >
        =
      </Text>
    );
  if (value > 0) {
    return (
      <chakra.span
        fontSize='xs'
        mr='0.5'
        height='18px'
        display='flex'
        pt='5px'
        pb='4px'
      >
        <TriangleUpIcon
          alignSelf='start'
          data-testid='triangle-up-icon'
          width='8px'
          height='8px'
        />
      </chakra.span>
    );
  }
  if (value < 0) {
    return (
      <chakra.span
        fontSize='xs'
        mr='0.5'
        height='18px'
        display='flex'
        pt='5px'
        pb='4px'
      >
        <TriangleDownIcon
          data-testid='triangle-down-icon'
          alignSelf='end'
          width='8px'
          height='8px'
        />
      </chakra.span>
    );
  }
};

export interface DeltaProps extends BoxProps {
  value: DeltaValue | undefined;
  valueFormatter: NumberFormatter;
  isInverseMetric?: boolean;
}

function getFormattedValue(value: DeltaValue, valueFormatter: NumberFormatter) {
  if (value === DELTA_UNAVAILABLE) {
    return '-';
  }

  return valueFormatter(Math.abs(value));
}

function getValueSentiment(value: DeltaValue, isInverseMetric: boolean) {
  return getSentiment(value === DELTA_UNAVAILABLE ? 0 : value, isInverseMetric);
}

/**
 * Delta - illustrates change %
 * @param value - change %
 * @param isInverseMetric - when negatives are good e.g. cancellations
 * @param valueFormatter - number formatter
 * @param rest - chakra props
 */
export function Delta({
  value: incomingDeltaValue,
  isInverseMetric = false,
  valueFormatter,
  ...rest
}: DeltaProps) {
  const value = incomingDeltaValue ?? DELTA_UNAVAILABLE;
  const sentiment = getValueSentiment(value, isInverseMetric);
  const color = getColor(sentiment);
  const background = getBackground(sentiment);
  const icon = getIcon(value, color);
  const formattedValue = getFormattedValue(value, valueFormatter);

  return (
    <Box
      borderRadius='2'
      px='1'
      bg={background}
      color={color}
      display='inline-flex'
      alignItems='center'
      data-testid='colored-box'
      {...rest}
    >
      {icon}
      <Text
        variant='numeric'
        fontSize={responsive('12px', 'xs')}
        fontWeight='semibold'
        color={color}
      >
        {formattedValue}
      </Text>
    </Box>
  );
}
