import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { FullBreakdownByPowerUsersRow } from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByPowerUsers';
import { getFullBreakdownWithSummaryAtom } from 'utils/atoms/fullBreakdownWithSummaryAtom';

export const Best1MonthRetentionSummaryRow = z.object({
  cohortsNum: z.coerce.number(),
});

export const Best1MonthRetentionRow = z.object({
  cohort: z.coerce.date(),
  initial: z.coerce.number(),
  m1Active: z.coerce.number(),
  m1Retention: z.coerce.number(),
});

export type Best1MonthRetentionRow = z.infer<typeof Best1MonthRetentionRow>;

export const {
  fetchingAtom: bestRetentionFetchingAtom,
  dataAtom: bestRetentionDataAtom,
} = getFullBreakdownWithSummaryAtom(
  Best1MonthRetentionRow,
  [{ column: 'm1Retention', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  Best1MonthRetentionSummaryRow,
);

export const NewPowerUsersSummaryRow = z.object({
  totalPowerUsers: z.coerce.number(),
});

export const {
  fetchingAtom: breakdownByTopPowerUsersFetchingAtom,
  dataAtom: breakdownByTopPowerUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByPowerUsersRow,
  [{ column: 'powerUserEventsCurrentPeriod', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  NewPowerUsersSummaryRow,
);
export const {
  fetchingAtom: breakdownByNewPowerUsersFetchingAtom,
  dataAtom: breakdownByNewPowerUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByPowerUsersRow,
  [{ column: 'joinDate', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  NewPowerUsersSummaryRow,
);

export const FullBreakdownByUsersSummaryRow = z.object({
  count: z.coerce.number(),
});

export const FullBreakdownByUsersRow = z.object({
  userId: z.string(),
  customerId: z.string(),
  userName: z.string(),
  company: z.string(),
  accountType: z.string().nullable(),
  accountState: z.string().nullable(),
  companyName: z.string().nullable(),
  isPowerUser: z.coerce.boolean(),
  activeSessionsL30: z.coerce.number(),
  activeSessionsDelta: z.coerce.number(),
  engagedSessionsL30: z.coerce.number(),
  engagedRateL30: z.coerce.number().nullable(),
  joinDate: z.coerce.date(),
  lastActive: z.coerce.date(),
  lastEngaged: z.coerce.date(),
  tags: z.string().array(),
  joinMonth: z.string(),
  country: z.string(),
});

export type FullBreakdownByUsersRow = z.infer<typeof FullBreakdownByUsersRow>;

export const UserSegments = z.enum([
  'POWER',
  'ENGAGED',
  'NEW',
  'ACTIVE',
  'INACTIVE',
]);

export type UserSegments = z.infer<typeof UserSegments>;

export const BreakdownBySegmentsRow = z.object({
  active: z.number(),
  activeDelta: z.number(),
  engaged: z.number(),
  engagedDelta: z.number(),
  inactive: z.number(),
  inactiveDelta: z.number(),
  new: z.number(),
  newDelta: z.number(),
  power: z.number(),
  powerDelta: z.number(),
});

export type BreakdownBySegmentsRow = z.infer<typeof BreakdownBySegmentsRow>;

export interface UserSegmentsBreakdownRow {
  segment: UserSegments;
  count: number;
  delta: number;
}

export const {
  fetchingAtom: usersWithDecreasedUsageFetchingAtom,
  dataAtom: usersWithDecreasedUsageDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'activeSessionsDelta', direction: 'ASC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const {
  fetchingAtom: mostEngagedUsersFetchingAtom,
  dataAtom: mostEngagedUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'engagedSessionsL30', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const {
  fetchingAtom: recentlyEngagedUsersFetchingAtom,
  dataAtom: recentlyEngagedUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'lastEngaged', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const {
  fetchingAtom: recentlySignedUpUsersFetchingAtom,
  dataAtom: recentlySignedUpUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'joinDate', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const {
  fetchingAtom: usersLargestIncreaseInActivityFetchingAtom,
  dataAtom: usersLargestIncreaseInActivityDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'activeSessionsDelta', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const {
  fetchingAtom: mostActiveUsersFetchingAtom,
  dataAtom: mostActiveUsersDataAtom,
} = getFullBreakdownWithSummaryAtom(
  FullBreakdownByUsersRow,
  [{ column: 'activeSessionsL30', direction: 'DESC' }],
  fixedEndDateFormattedDateRangeAtom,
  FullBreakdownByUsersSummaryRow,
);

export const SignalsByEngagementRow = z.object({
  signal1: z.coerce.number(),
  signal1Delta: z.coerce.number(),
  signal1Total: z.coerce.number(),
  signal2: z.coerce.number(),
  signal2Delta: z.coerce.number(),
  signal2Total: z.coerce.number(),
  signal3: z.coerce.number(),
  signal3Delta: z.coerce.number(),
  signal3Total: z.coerce.number(),
  signal4: z.coerce.number(),
  signal4Delta: z.coerce.number(),
  signal4Total: z.coerce.number(),
  signal5: z.coerce.number(),
  signal5Delta: z.coerce.number(),
  signal5Total: z.coerce.number(),
});

export type SignalsByEngagementRow = z.infer<typeof SignalsByEngagementRow>;

export const RevenueBreakdownRow = z.object({
  movementType: z.string(),
  date: z.coerce.date(),
  customerMrr: z.number(),
  amount: z.number(),
});

export type RevenueBreakdownRow = z.infer<typeof RevenueBreakdownRow>;
