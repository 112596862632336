import { Tag } from '@chakra-ui/tag';
import { useTranslation } from 'react-i18next';

import { AlertTriangleIcon } from 'components/uikit';

export function DemoDataTag({ size = 'sm' }: { size?: 'sm' | 'md' }) {
  const { t } = useTranslation('mission-control');
  return (
    <Tag
      variant='square'
      bgColor='warning.text'
      color='warning.background'
      fontSize='16px'
      borderRadius='4px'
      h={size === 'sm' ? '32px' : '42px'}
      py='4px'
      gap='8px'
      px='12px'
    >
      <AlertTriangleIcon
        height='20px'
        width='20px'
        stroke='warning.background'
      />
      {t('demo_data_tag')}
    </Tag>
  );
}
