import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';

import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import { iconsByType } from 'components/CommandPanel/Modal/ListItems/UserMenuListItem';
import { ActionCommandPanelOptionType } from 'data/commandPanel/models';
import { useCopyInviteLink } from 'data/common/useCopyInviteLink';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';
import paths from 'utils/paths';

type ActionItemProps = {
  action: ActionCommandPanelOptionType;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
};
const actionsToPaths = {
  ADD_DATA_SOURCE: paths.dataSources.connect,
  VIEW_INSIGHTS: paths.radar.index,
  METRICS_DICTIONARY: paths.dictionary.index,
  CREATE_REPORT: paths.reviews.create,
  CREATE_ALERT: paths.alerts.create.index,
};

export function ActionMenuListItem({
  action,
  index,
  isSelected,
  onSelect,
  onClose,
}: ActionItemProps) {
  const { t } = useTranslation('command-panel');
  const onCopy = useCopyInviteLink();
  const router = useRouter();
  const onClick = useCallback(() => {
    switch (action) {
      case 'ASK_AI':
        return onSelect();
      case 'INVITE_TEAM_MEMBER':
        onCopy();
        return onClose();
      default:
        router.push(actionsToPaths[action]);
        return onClose();
    }
  }, [action, onCopy, router, onClose, onSelect]);
  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.ACTION_SELECTED}
      icon={iconsByType[action]}
      id={action}
      name={t(`actions.${action}`)}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={onClick}
    />
  );
}
