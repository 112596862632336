import { authorisedFetch } from 'api/rest/authorisedFetch';
import { PublicChatMessage } from 'data/ai/models';
import { parseItemsStrict } from 'utils/parser/parseResponse';

import { PARROT_API_URL } from './resourcesUrls';

export async function getChatMessages(chatId: string) {
  const result = await authorisedFetch(`${PARROT_API_URL}/chats/${chatId}`, {
    method: 'GET',
  });

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to get parrot chat messages: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  const results = parseItemsStrict(PublicChatMessage, data);

  return { results };
}
