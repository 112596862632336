import {
  DepartmentDictionarySwitch,
  getDepartmentFromBlockName,
  getSwitchQueryValue,
} from 'components/pages/MetricsDictionary/useMenu';

export function getPageTitleKeySlug(blockUniqueName?: string) {
  if (!blockUniqueName) {
    return undefined;
  }

  const lowercaseBlockName = blockUniqueName.toLowerCase();

  if (
    blockUniqueName.startsWith('METRICS_') ||
    blockUniqueName.startsWith('MISSION_CONTROL_')
  ) {
    return `mission-control:${lowercaseBlockName}_header` ?? undefined;
  } else if (blockUniqueName.startsWith('METRIC_DICTIONARY_')) {
    const department = getDepartmentFromBlockName(blockUniqueName);
    if (!department) return undefined;
    const tTitleKey = getSwitchQueryValue(
      department,
      blockUniqueName as DepartmentDictionarySwitch<typeof department>,
    );
    return tTitleKey ? `dictionary:${tTitleKey}.name` : undefined;
  }

  return undefined;
}
