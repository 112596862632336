import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { useAuthState } from 'data/auth/hooks/useAuthState';
import { fetchGoalsAtom } from 'data/goals/atoms/goals';
import { useCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';

export const useFetchGoals = () => {
  const [remote, fetchGoals] = useAtom(fetchGoalsAtom);
  const { isAuthenticated } = useAuthState();
  const { mainProductActive } = useCompanyOnboarding();

  const { fetchStatus } = remote;
  useEffect(() => {
    if (fetchStatus === 'SUCCESS') return;
    if (!isAuthenticated || !mainProductActive) return;
    fetchGoals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGoals, isAuthenticated, mainProductActive]);

  return { remote, fetchGoals };
};
