import { CloseButton } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { HStack, MagicWandIcon, Text, VStack } from 'components/uikit';
import { Tag } from 'components/uikit/reexport';
import { aiResponseAtom } from 'data/ai/atoms';

type AiDrawerHeaderProps = {
  onClose?: () => void;
};

export function AiChatHeader({ onClose }: AiDrawerHeaderProps) {
  const resultRemote = useAtomValue(aiResponseAtom);
  const { t } = useTranslation('ai');

  return (
    <VStack
      p='24px'
      spacing='16px'
      backgroundColor='grey.white'
      borderBottom='1px solid'
      borderColor='grey.border'
      align='stretch'
    >
      <HStack justify='space-between'>
        <Tag
          variant='square'
          bgColor='tertiary.background'
          color='tertiary.text'
          borderRadius='4px'
          py='6px'
          gap='8px'
          px='12px'
        >
          <MagicWandIcon height='20px' width='20px' />
          {t(
            resultRemote.context.type === 'INSIGHT'
              ? 'insight_investigation'
              : 'ask_question',
          )}
        </Tag>
        {onClose ? (
          <CloseButton
            height='24px'
            width='24px'
            background='grey.background'
            color='grey.tertiaryText'
            onClick={onClose}
          />
        ) : null}
      </HStack>
      <Text as='h3' fontSize='xl' fontWeight='semibold'>
        {resultRemote.context.type === 'INSIGHT'
          ? resultRemote.context.name ?? t('drawer_title')
          : t('drawer_title')}
      </Text>
    </VStack>
  );
}
