import { createElement, Fragment, FunctionComponent } from 'react';

import Big from 'big.js';

import { FetchStatus } from 'utils/requests/types';
import { isFetchError } from 'utils/types';

export function isNotNull<T>(element: T | null): element is T {
  return element != null;
}

export function isNotNullish<T>(element?: T | null | undefined): element is T {
  return element != null && typeof element !== 'undefined';
}

export function hasNullId<T extends { id: string | null }>(element: T) {
  return element.id === null;
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function uniqueBy<T>(array: T[], getKey: (item: T) => string): T[] {
  const seen = new Set<string>();
  return array.filter((item) => {
    const key = getKey(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

export function groupBy<T, K extends string>(
  getKey: (item: T) => K,
  items: T[],
): Record<K, T[]> {
  return items.reduce((result, item) => {
    const groupKey = getKey(item);
    return {
      ...result,
      [groupKey]: (result[groupKey] ?? []).concat(item),
    };
  }, <Record<K, T[]>>{});
}

export function indexBy<T, K extends string>(
  getKey: (item: T) => K,
  items: T[],
): Record<K, T> {
  return items.reduce((result, item) => {
    const groupKey = getKey(item);
    return {
      ...result,
      [groupKey]: item,
    };
  }, <Record<K, T>>{});
}

export function mapValues<V, R, K extends string = string>(
  mapper: (key: K, value: V) => R,
  object: Record<K, V>,
): Record<K, R> {
  return Object.fromEntries(
    Object.entries(object).map(([k, v]) => [k, mapper(k as K, v as V)]),
  ) as Record<K, R>;
}

export function mapEntries<V, R>(
  mapper: (key: string, value: V) => [string, R],
  object: Record<string, V>,
) {
  return Object.fromEntries(
    Object.entries(object).map(([k, v]) => mapper(k, v)),
  );
}

export function isEmpty(enumerable: { length: number }): boolean {
  return enumerable.length === 0;
}

export function isNotEmpty<T>(enumerable: T[]): enumerable is [T, ...T[]] {
  return enumerable.length !== 0;
}

export function isBlank(str: string | null | undefined): boolean {
  return !str || /^\s*$/.test(str);
}

export function isSingleItem<T>(enumerable: T[]): enumerable is [T] {
  return enumerable.length === 1;
}

export function sortByField<T, K extends keyof T>(field: K, items: T[]) {
  return items.sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
}

export function filteringSplit<T>(
  predicate: (item: T) => boolean,
  array: T[],
): [T[], T[]] {
  const trueGroup: T[] = [];
  const falseGroup: T[] = [];

  array.forEach((item) => {
    if (predicate(item)) {
      trueGroup.push(item);
    } else {
      falseGroup.push(item);
    }
  });

  return [trueGroup, falseGroup];
}

interface WithChildren<T> {
  children: T[];
}

export function findInTree<T extends WithChildren<T>>(
  predicate: (item: T) => boolean,
  items: T[],
): T[] {
  const stack: T[] = [...items];
  const result: T[] = [];

  while (stack.length > 0) {
    const current = stack.pop();

    if (current && predicate(current)) {
      result.push(current);
    }

    if (current) {
      current.children.forEach((child) => stack.push(child));
    }
  }

  return result;
}

export function hasLoaded(
  fetchStatus: FetchStatus,
): fetchStatus is 'SUCCESS' | 'ERROR' {
  return fetchStatus === 'SUCCESS' || isFetchError(fetchStatus);
}

export function range(size: number, startIndex = 0) {
  return Array.from({ length: size }, (_, index) => startIndex + index);
}

export function safeDiv(
  divisible: Big,
  divider: Big | string | number | null,
): Big | undefined {
  if (divider == null) return undefined;
  const formattedDivider = Big(divider);
  return formattedDivider.eq(0) ? undefined : divisible.div(formattedDivider);
}

export function noop() {
  return;
}

export function sum(a: number, b: number) {
  return a + b;
}

export function createLruCache<T>(maxSize = 100) {
  const values: Map<string, T> = new Map<string, T>();

  return {
    get(key: string): T | null {
      const hasKey = values.has(key);
      if (hasKey) {
        // peek the entry, re-insert for LRU strategy
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const entry = values.get(key)!;
        values.delete(key);
        values.set(key, entry);

        return entry;
      }

      return null;
    },
    set(key: string, value: T) {
      if (values.size >= maxSize) {
        // least-recently used cache eviction strategy
        const keyToDelete = values.keys().next().value;

        values.delete(keyToDelete);
      }

      values.set(key, value);
    },
  };
}

export function splitArrayIntoBatches<T>(array: T[], batchSize: number): T[][] {
  const batches: T[][] = [];

  const length = array.length;
  let i = 0;
  while (i < length) {
    batches.push(array.slice(i, i + batchSize));
    i += batchSize;
  }

  return batches;
}

export async function promiseWaterfall<T, R>(
  items: T[],
  callback: (item: T) => R,
): Promise<R[]> {
  const result: R[] = [];

  for (const item of items) {
    const callbackResult = await callback(item);
    result.push(callbackResult);
  }

  return result;
}

export function deleteKey<T extends string>(
  key: T,
  obj: { [key in T]?: unknown },
) {
  const { [key]: _, ...rest } = obj;
  return rest;
}

export function repeat(n: number) {
  return {
    times: <T>(callback: (index: number) => T): T[] =>
      [...Array(n).keys()].map(callback),
  };
}

export function render<T extends object>(
  component: FunctionComponent<T>,
  n: number,
) {
  return {
    times: (propsBuilder: (index: number) => { key: string } & T) => {
      return createElement(
        Fragment,
        {},
        repeat(n).times((index) =>
          createElement(component, propsBuilder(index)),
        ),
      );
    },
  };
}

export function maxBy<T>(
  transformer: (value: T) => number,
  ...args: [T, ...T[]]
) {
  const [, result] = args
    .map((arg) => [transformer(arg), arg] as const)
    .sort((a, b) => b[0] - a[0])[0] as [number, T];

  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(obj1: any, obj2: any): boolean {
  // Check if the objects are strictly equal
  if (obj1 === obj2) {
    return true;
  }

  // Check if both objects are objects or arrays and not null
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function last<T>(collection: T[]) {
  return collection[collection.length - 1];
}

export function lastChar(str: string) {
  return str[str.length - 1];
}

type JsonPrimitive = string | number | boolean | null;
type Json = JsonPrimitive | JsonObject | JsonArray;

interface JsonObject {
  [key: string]: Json;
}

type JsonArray = Json[];

function findCursor<T>(item: T, path: (string | number)[]): Json | undefined {
  let cursor = item as Json;

  for (const part of path) {
    if (typeof part === 'string') {
      const value = (cursor as JsonObject)[part];
      if (typeof value === 'undefined') return undefined;
      cursor = value;
    } else if (typeof part === 'number') {
      const value = (cursor as JsonArray)[part];
      if (typeof value === 'undefined') return undefined;
      cursor = value;
    } else {
      return undefined;
    }
  }

  return cursor;
}

export function getStringByPath<T>(item: T, path: (string | number)[]) {
  return String(findCursor(item, path) ?? '');
}

export function deepCopy<T>(item: T): T {
  if (typeof item !== 'object' || item === null) {
    return item;
  }

  if (item instanceof Date) {
    return new Date(item.getTime()) as T;
  }

  if (Array.isArray(item)) {
    return item.map(deepCopy) as T;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [key, deepCopy(value)]),
  ) as T;
}

export function setByPath<T, V>(
  item: T,
  path: (string | number)[],
  value: V,
): T {
  const copy = deepCopy(item);
  const [lastPart, ...reversedParts] = [...path].reverse();
  const cursor = findCursor(copy, reversedParts.reverse());

  if (
    typeof cursor === 'object' &&
    cursor !== null &&
    typeof lastPart !== 'undefined'
  ) {
    (cursor as JsonObject)[lastPart] = value as Json;
  } else {
    throw new Error(`Path does not exist: ${path}`);
  }

  return copy as T;
}

export function updateByPath<T, V>(
  item: T,
  path: (string | number)[],
  mapper: (value: V) => V,
): T {
  const copy = deepCopy(item as Json);
  const [lastPart, ...reversedParts] = [...path].reverse();
  const cursor = findCursor(copy, reversedParts.reverse());

  if (
    typeof cursor === 'object' &&
    cursor !== null &&
    typeof lastPart !== 'undefined'
  ) {
    (cursor as JsonObject)[lastPart] = mapper(
      (cursor as JsonObject)[lastPart] as unknown as V,
    ) as Json;
  } else {
    throw new Error(`Path does not exist: ${path}`);
  }

  return copy as T;
}

function hasChildren<R, T extends { children: (T | R)[] }>(
  item: T | R,
): item is T {
  return Array.isArray((item as T).children);
}

export function traverseBreadthFirst<R, T extends { children: (T | R)[] }>(
  root: T | R,
  predicate: (item: T | R) => void,
): void {
  const queue: (T | R)[] = [root];

  while (queue.length > 0) {
    const currentNode = queue.shift(); // Dequeue

    if (!currentNode) continue;

    // Process current node
    predicate(currentNode);

    if (hasChildren(currentNode)) {
      // Enqueue children
      currentNode.children.forEach((child) => {
        queue.push(child);
      });
    }
  }
}

export function isObjectEmpty(object: object) {
  return Object.keys(object).length === 0;
}
