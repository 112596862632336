import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { DateRangeControlOptions } from 'components/charts/BreakdownTable/BreakdownTableStub';
import { FullBreakdownTable } from 'components/charts/FullBreakdownTable/FullBreakdownTable';
import { useFormatEngagementBreakdownBySignals } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useFormatEngagementBreakdownBySignals';
import { useShowDepartmentBlocksForCompany } from 'components/CustomerDrawer/CompanyDrawer/hooks/useShowDepartmentBlocksForCompany';
import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';
import { useCustomerEngagementBreakdownBySignalsConfig } from 'data/blocks/hooks/tableConfigs/breakdowns/product/useCustomerEngagementBreakdownBySignalsConfig';
import { useCustomerCompanyAtoms } from 'data/healthReports/atoms/customerCompanyAtoms';
import { getNumberGroupFormatter } from 'utils/formatting/number';
import { isInitial } from 'utils/types';

export function EngagementBySignalsBreakdown({
  customerId,
}: {
  customerId: string;
}) {
  const { showProductBlocks } = useShowDepartmentBlocksForCompany(customerId);
  const { blocks } = useCustomerBreakdownBlocks();
  const breakdownBlock = blocks.find(
    (block) => block.uniqueName == 'CUSTOMER_COMPANY_PRODUCT_ENGAGEMENT',
  );

  if (!showProductBlocks || !breakdownBlock) return null;

  return (
    <EngagementBreakdownWithBlockId
      customerId={customerId}
      blockId={breakdownBlock.id}
    />
  );
}

function EngagementBreakdownWithBlockId({
  customerId,
  blockId,
}: {
  customerId: string;
  blockId: string;
}) {
  const { signalsByEngagementAtoms } = useCustomerCompanyAtoms(customerId);
  const { fetchingAtom } = signalsByEngagementAtoms;
  const [{ data, fetchStatus }, fetchData] = useAtom(fetchingAtom);
  useEffect(() => {
    if (isInitial(fetchStatus)) {
      fetchData(blockId);
    }
  }, [blockId, fetchData, fetchStatus]);
  const firstRow = data.rows[0];
  const formatter = getNumberGroupFormatter(
    [
      firstRow?.signal1Total ?? 0,
      firstRow?.signal1Total ?? 0,
      firstRow?.signal3Total ?? 0,
      firstRow?.signal4Total ?? 0,
      firstRow?.signal5Total ?? 0,
    ],
    {
      style: 'integer',
    },
  );
  const config = useCustomerEngagementBreakdownBySignalsConfig(formatter);
  const dataRows = useFormatEngagementBreakdownBySignals(firstRow);
  return (
    <FullBreakdownTable
      blockId={blockId}
      width='100%'
      trackingLabel='newPowerUsersBreakdown'
      fetchStatus={fetchStatus}
      config={config.tableConfig}
      borderRadius={'0px'}
      borderWidth={'0px'}
      numRows={5}
      data={{
        rows: dataRows,
        totalCount: 0,
      }}
      dateRangeControl={DateRangeControlOptions.DATE_PICKER_FIXED_END_DATE}
    />
  );
}
