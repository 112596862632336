import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { Department } from 'data/blocks/models/Block';
import { parseItemStrict } from 'utils/parser/parseResponse';

export type FetchCustomerUserByDepartmentIdParams = {
  department: Department;
  departmentId: string;
};

export async function fetchCustomerUserByDepartmentId({
  department,
  departmentId,
}: FetchCustomerUserByDepartmentIdParams): Promise<CustomerUserResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/departments/${department}/${departmentId}`,
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer user: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserResponse, data);
}
