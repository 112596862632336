import { useCallback } from 'react';

import { ColorMode } from '@chakra-ui/color-mode';

import { getScreenshotUrlFromPublicLink } from 'api/rest/sharing/getScreenshotUrlFromPublicLink';
import { useShareLinkCopiedToast } from 'components/Sharing/ShareLinkCopiedToast';
import { APP_URL } from 'data/constants';
import { ShareBlockConfig } from 'data/sharingLink/atoms/globalShareBlock';
import { useCreateSharingLink } from 'data/sharingLink/hooks/useCreateSharingLink';
import { buildUrlSearchParams } from 'utils/url/urlQueryUtils';

export function useCreatePublicSharingLink() {
  const { renderErrorToast } = useShareLinkCopiedToast();
  const { createSharingLink } = useCreateSharingLink();

  return useCallback(
    async (shareBlockConfig: ShareBlockConfig) => {
      const { blockId, params } = shareBlockConfig;
      const sharingLink = await createSharingLink(blockId);
      const urlParams = params
        ? `?${Object.entries(params)
            .map(([name, value]) => `${name}=${value}`)
            .join('&')}`
        : '';

      if (!sharingLink) {
        renderErrorToast();
        return;
      }

      return {
        linkId: sharingLink.id,
        linkUrl: `${APP_URL}/shared/${sharingLink.id}${urlParams}`,
      };
    },
    [createSharingLink, renderErrorToast],
  );
}

export const useCopyToClipboard = () => {
  const { renderSuccessToast, renderErrorToast } = useShareLinkCopiedToast();

  return useCallback(
    async (text?: string) => {
      if (!text) {
        renderErrorToast();
        return;
      }

      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
        console.error('SharingModal error:', error);
        renderErrorToast();
      }

      renderSuccessToast(false, 'public');
    },
    [renderErrorToast, renderSuccessToast],
  );
};

export function useCopyHtmlEmbedCode() {
  const copyToClipboard = useCopyToClipboard();

  return useCallback(
    async (sharingLink?: string) => {
      const getCode = (sharingLink?: string) => {
        return `<iframe src="${sharingLink}" width="100%" height="100%" frameborder="0"></iframe>`;
      };

      await copyToClipboard(getCode(sharingLink));
    },
    [copyToClipboard],
  );
}

export function useGetScreenshotFromPublicLink(
  publicSharingLinkId?: string,
  shareBlockConfig?: ShareBlockConfig,
  colorMode?: ColorMode,
) {
  const { renderSuccessScreenshotToast, renderErrorToast } =
    useShareLinkCopiedToast();

  return useCallback(
    async (type: 'copied' | 'downloaded') => {
      if (!publicSharingLinkId) {
        renderErrorToast();
        return undefined;
      }

      const screenshotResponse = await getScreenshotUrlFromPublicLink(
        publicSharingLinkId,
        buildUrlSearchParams({
          ...shareBlockConfig?.params,
          colorMode,
        }).toString(),
        'Europe/London',
        '#public-shared-page-body > :first-child',
      );

      if (
        screenshotResponse.fetchStatus !== 'SUCCESS' ||
        !screenshotResponse.response
      ) {
        renderErrorToast();
        return undefined;
      }

      renderSuccessScreenshotToast(type);

      return screenshotResponse.response;
    },
    [
      colorMode,
      publicSharingLinkId,
      renderErrorToast,
      renderSuccessScreenshotToast,
      shareBlockConfig?.params,
    ],
  );
}

export function useCopyImage() {
  const { renderErrorToast } = useShareLinkCopiedToast();

  return useCallback(
    async (imageBlob: Blob) => {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': imageBlob,
          }),
        ]);
      } catch (error) {
        renderErrorToast();
        console.error(error);
      }
    },
    [renderErrorToast],
  );
}
