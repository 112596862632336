import { useMemo } from 'react';

import { useAnimatedPath } from '@nivo/core';
import { animated } from '@react-spring/web';

import {
  LineGenerator,
  LineSegment,
} from 'components/charts/Layers/LinesLayer/LineSegment';
import { splitColorSegments, splitSegmentLastItem } from 'utils/lineChartUtils';

type Props = {
  lineGenerator: LineGenerator;
  points: Array<{ x: number; y: number }>;
  colors: string[];
  thickness: number;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  opacity: number;
  isDashed?: boolean;
  lastLineDotted?: boolean;
  onMouseOver: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onMouseLeave: () => void;
};

export const SeriesLine = ({
  lineGenerator,
  points,
  colors,
  thickness,
  onClick,
  opacity,
  isDashed = false,
  lastLineDotted,
  onMouseOver,
  onMouseLeave,
}: Props) => {
  const originalColorSegments = splitColorSegments(colors);

  const effectiveColorSegments = lastLineDotted
    ? [
        ...originalColorSegments.slice(0, originalColorSegments.length - 1),
        ...splitSegmentLastItem(
          originalColorSegments[originalColorSegments.length - 1],
        ),
      ]
    : originalColorSegments;

  const allPath = useMemo(() => lineGenerator(points), [lineGenerator, points]);
  const allAnimatedPath = useAnimatedPath(allPath);

  return (
    <>
      {effectiveColorSegments.map(
        ({ start, end, color: segmentColor }, idx) => {
          const isLastSegment = idx === effectiveColorSegments.length - 1;
          return (
            <LineSegment
              key={`segment-${idx}-${segmentColor}`}
              generatedLine={lineGenerator(
                points.slice(Math.max(0, start - 1), end + 1),
              )}
              color={segmentColor}
              dashed={isDashed || (!!lastLineDotted && isLastSegment)}
              thickness={thickness}
              opacity={opacity}
            />
          );
        },
      )}

      <animated.path
        d={allAnimatedPath}
        fill='none'
        strokeWidth={10}
        stroke='transparent'
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        style={{ cursor: 'pointer' }}
      />
    </>
  );
};
