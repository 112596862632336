import React, { useEffect } from 'react';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import { SearchIcon } from 'components/uikit';

export interface CommandPanelInputProps {
  value: string;
  setValue: (input: string) => void;
}

export function CommandPanelSearchInput({ setValue }: CommandPanelInputProps) {
  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      ref.current?.focus();
    });
  }, []);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents='none'>
        <SearchIcon stroke='grey.secondaryText' />
      </InputLeftElement>
      <Input
        ref={ref}
        py={0}
        variant='flushed'
        onChange={(e) => setValue(e.target.value)}
      />
    </InputGroup>
  );
}
