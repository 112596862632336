import { RecentlyActiveBreakdownChart } from 'components/CustomerDrawer/CompanyDrawer/charts/RecentlyActiveBreakdownChart';
import { SegmentPieChartWithBreakdown } from 'components/CustomerDrawer/CompanyDrawer/charts/SegmentPieChartWithBreakdown';
import { TabPanel, VStack } from 'components/uikit';
import { useCustomerBreakdownBlock } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlock';
import {
  useCustomerCompanyUserActivityBreakdownAtoms,
  useCustomerCompanyUserSegmentsBreakdownAtoms,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { useFetchCustomerCompanyBreakdownBlock } from 'data/healthReports/hooks/company/useFetchCustomerCompanyBreakdownBlock';
import { hideScrollbar } from 'styles/hideScrollbar';

interface UsersTabPanelProps {
  customerCompanyId: string;
  onDrawerClose: () => void;
}

export function UsersTabPanel({
  customerCompanyId,
  onDrawerClose,
}: UsersTabPanelProps) {
  const { block: userSegmentsFullBreakdownBlock } = useCustomerBreakdownBlock(
    'CUSTOMER_COMPANY_PRODUCT_FULL_BREAKDOWN_BY_USER_ENGAGEMENT',
  );
  const { block: userListFullBreakdownBlock } = useCustomerBreakdownBlock(
    'CUSTOMER_COMPANY_PRODUCT_USERS',
  );
  useFetchCustomerCompanyBreakdownBlock(
    useCustomerCompanyUserSegmentsBreakdownAtoms(customerCompanyId)
      .fetchingAtom,
    userSegmentsFullBreakdownBlock?.id,
  );
  useFetchCustomerCompanyBreakdownBlock(
    useCustomerCompanyUserActivityBreakdownAtoms(customerCompanyId)
      .fetchingAtom,
    userListFullBreakdownBlock?.id,
  );

  return (
    <TabPanel height={'100%'}>
      <VStack
        background={'grey.offWhite'}
        alignItems='stretch'
        height={'100%'}
        overflowY={'hidden'}
      >
        <VStack
          p='16px'
          overflowY={'scroll'}
          width={'100%'}
          css={hideScrollbar}
        >
          {userSegmentsFullBreakdownBlock && (
            <SegmentPieChartWithBreakdown
              customerCompanyId={customerCompanyId}
              blockId={userSegmentsFullBreakdownBlock.id}
            />
          )}
          {userListFullBreakdownBlock && (
            <RecentlyActiveBreakdownChart
              customerCompanyId={customerCompanyId}
              blockId={userListFullBreakdownBlock.id}
              onDrawerClose={onDrawerClose}
            />
          )}
        </VStack>
      </VStack>
    </TabPanel>
  );
}
