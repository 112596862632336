import {
  fetchCompanyUsers,
  FetchCompanyUsersParams,
} from 'api/rest/users/fetchCompanyUsers';
import { CompanyUser } from 'data/user/models';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const companyUsersAtom = getDataAtom<{ users: CompanyUser[] }>({
  users: [],
});

export const fetchCompanyUsersAtom = getDataFetchingAtom<
  { users: CompanyUser[] },
  FetchCompanyUsersParams
>(companyUsersAtom, fetchCompanyUsers);
