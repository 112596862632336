export const INTERCOM_ID = process.env.NEXT_PUBLIC_INTERCOM_ID;
export const INTERCOM_REDIRECT_URI =
  process.env.NEXT_PUBLIC_INTERCOM_REDIRECT_URI ?? '';
export const INTERCOM_AUTH_URL = `${
  process.env.NEXT_PUBLIC_INTERCOM_AUTH_URL ?? ''
}`;
export const INTERCOM_CLIENT_ID =
  process.env.NEXT_PUBLIC_INTERCOM_CLIENT_ID ?? '';

export const INTERCOM_STATE_STORAGE_KEY = '@calliper/intercom-state';

export const INTERCOM_AVAILABLE =
  process.env.NEXT_PUBLIC_INTERCOM_AVAILABLE === 'true';
