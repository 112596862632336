import { NotAllowedIcon } from '@chakra-ui/icons';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useSharingBlockContext } from 'components/Sharing/sharingBlockContextAtom';
import {
  SharingBlockModalBodyDefaultActionsContainer,
  SharingBlockModalBodyDefaultContentContainer,
} from 'components/Sharing/SharingBlockModalBodyDefaultContainers';
import { embedCurrentOptionAtom } from 'components/Sharing/SharingOptions/embedCurrentOptionAtom';
import { useCopyToClipboard } from 'components/Sharing/SharingOptions/useCreateAndCopyPublicSharingLink';
import { Box, Button, CopyIcon, Heading } from 'components/uikit';
import { Markdown } from 'components/uikit/Markdown';
import { AnalyticsEventType, trackEvent } from 'utils/analytics';

export function EmbedOptionSlackTabBody() {
  const { t } = useTranslation('common', {
    keyPrefix: 'sharing_block_modal_embed',
  });
  const embedCurrentOption = useAtomValue(embedCurrentOptionAtom);
  const { closeSharingModal, publicSharingLink, shareBlockConfig } =
    useSharingBlockContext();
  const copyToClipboard = useCopyToClipboard();
  const copyButtonDisabled = !publicSharingLink;

  if ('slack' !== embedCurrentOption) {
    return <></>;
  }

  const onCopyLinkClick = async () => {
    await copyToClipboard(publicSharingLink);
    trackEvent(AnalyticsEventType.PAGE_SHARE_LINK_COPIED, {
      sharingOption: 'EMBED',
      blockUniqueName: shareBlockConfig?.blockName,
    });
    closeSharingModal?.();
  };

  return (
    <>
      <SharingBlockModalBodyDefaultContentContainer>
        <Heading as='h1' fontSize='xl'>
          {t('slack_body_title')}
        </Heading>
        <Box pl='20px' color='grey.secondaryText' lineHeight='200%'>
          <Markdown>{t('slack_body_instructions')}</Markdown>
        </Box>
      </SharingBlockModalBodyDefaultContentContainer>
      <SharingBlockModalBodyDefaultActionsContainer>
        <Button
          variant='primary'
          height='40px'
          onClick={onCopyLinkClick}
          disabled={copyButtonDisabled}
        >
          {copyButtonDisabled ? (
            <NotAllowedIcon mr='9px' />
          ) : (
            <CopyIcon boxSize='16px' stroke='grey.white' mr='9px' />
          )}
          {t('copy_link_button_caption')}
        </Button>
      </SharingBlockModalBodyDefaultActionsContainer>
    </>
  );
}
