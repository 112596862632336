import { ReactElement, useCallback, useMemo, useState } from 'react';

import { AxisTickProps } from '@nivo/axes/dist/types/types';
import { Bar, ComputedDatum } from '@nivo/bar';
import { Margin } from '@nivo/core';
import { atom, useAtomValue, WritableAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';

import {
  ChartLegend,
  ChartLegendProps,
  JustifyLegend,
} from 'components/charts/ChartLegend';
import { DATA_VIZ_NEUTRAL } from 'components/charts/constants';
import { BarMaskLayer } from 'components/charts/Layers/MaskLayer';
import { useEffectiveChartHeight } from 'components/charts/useEffectiveChartHeight';
import { Box } from 'components/uikit';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { formatBarData } from 'data/charts/formatters/finance/formatBarData';
import {
  getAxisLeftFormatter,
  renderLeftTick,
} from 'data/charts/formatters/formatLeftTick';
import { formatTimeAxisValues } from 'data/charts/formatters/formatTimeAxisValues';
import { useFormatTooltipTitle } from 'data/charts/hooks/useFormatTooltipTitle';
import { BarData } from 'data/charts/models/BarData';
import { TimeSeries } from 'data/responseModels/TimeSeries';
import {
  BarChartAlignment,
  calculateNiceScaleRange,
  getBarDataDefsAndFills,
} from 'utils/barChartUtils';
import {
  getChartDefaultChartMargins,
  getTimeSeriesCurrency,
} from 'utils/chartsUtils';
import { getEndOfPeriodOrToday } from 'utils/datetime';
import { Currency } from 'utils/formatting/number';
import { last, mapValues } from 'utils/helpers';
import { useIsMobile } from 'utils/hooks/screenBreakpoints';
import { FetchStatus } from 'utils/requests/types';
import { isFetchError, isLoading } from 'utils/types';

import { ChartSkeleton } from '../ChartSkeleton';
import {
  BarChartTooltip,
  TooltipFormat,
  TooltipTitleFormat,
} from './BarChartTooltip';

export type GetBarColorFn = (item: ComputedDatum<BarData>) => string;
type TimeTickFormat = (tick: AxisTickProps<string>) => ReactElement;

type TimeTickFrequency = 'every day' | 'every month';

interface ChartProps {
  data: Array<BarData>;
  keys: string[];
  highlightedKey: string;
  width: number;
  height: number;
  timeTickFormat: TimeTickFormat;
  tooltipTitleFormat: TooltipTitleFormat;
  tooltipContentFormat?: TooltipFormat;
  customTooltip?: (data: BarData) => ReactElement;
  timeTickFrequency: TimeTickFrequency;
  colors: string[];
  getBarColor?: GetBarColorFn;
  financialData?: boolean;
  currency: Currency | null;
  lastBarDotted?: boolean;
  align?: BarChartAlignment;
  leftTickFormat?: (value: number) => string;
  getDataKeyColor?: (key: string) => string;
  onHighlight: (key: string) => void;
  id: string;
  legendKeysSorting: (keys: string[]) => string[];
  horizontalLines: number | undefined;
  margin?: Partial<Margin>;
}

function Chart({
  id,
  data,
  keys,
  highlightedKey,
  width,
  height,
  timeTickFormat,
  tooltipTitleFormat,
  tooltipContentFormat,
  timeTickFrequency,
  colors,
  getBarColor,
  getDataKeyColor,
  onHighlight,
  legendKeysSorting,
  financialData = false,
  currency,
  lastBarDotted = false,
  leftTickFormat,
  align = 'default',
  horizontalLines = 5,
  margin,
  customTooltip,
}: ChartProps) {
  const { defs, fills } = getBarDataDefsAndFills(
    data,
    colors,
    lastBarDotted,
    highlightedKey,
    getBarColor,
    id,
  );

  const { allZeroValues, scaleMin, scaleMax } = calculateNiceScaleRange(
    data,
    align,
  );

  const onMouseEnter = useCallback(
    (datum: ComputedDatum<BarData>) => {
      datum.id && onHighlight(String(datum.id));
    },
    [onHighlight],
  );

  const onMouseLeave = useCallback(() => {
    onHighlight('');
  }, [onHighlight]);

  const isMobile = useIsMobile();

  return (
    <Bar
      data={data}
      keys={keys}
      width={width}
      height={height}
      defs={defs}
      fill={fills}
      margin={{
        ...getChartDefaultChartMargins(isMobile),
        ...margin,
      }}
      colors={getBarColor || colors}
      layout='vertical'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      theme={{
        crosshair: {
          line: {
            strokeOpacity: 1,
            stroke: 'var(--chakra-colors-gray-400)',
            strokeWidth: 1,
            strokeDasharray: '10px',
          },
        },
        grid: {
          line: {
            stroke: 'var(--chakra-colors-grey-border)',
          },
        },
        fontFamily: 'var(--font-numeric)',
      }}
      enableGridX={false}
      axisLeft={{
        format:
          leftTickFormat ||
          getAxisLeftFormatter(
            financialData ? 'FINANCIAL_TRANSACTION_SET' : 'TIME_SERIES',
            currency,
          ),
        renderTick: renderLeftTick,
        tickPadding: 12,
        tickSize: 0,
        tickValues: allZeroValues ? [0] : horizontalLines, // Only show the tick value at the 0 line
      }}
      valueScale={{
        type: 'linear',
        nice: true,
        max: scaleMax,
        min: scaleMin,
      }}
      enableLabel={false}
      gridYValues={horizontalLines}
      padding={isMobile ? 0.3 : 0.4}
      axisBottom={{
        renderTick: timeTickFormat,
        tickPadding: 8,
        tickValues: timeTickFrequency,
        tickSize: 0,
        legendOffset: 12,
      }}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: {
            stroke: 'var(--chakra-colors-gray-400)',
            strokeWidth: 1,
          },
          legendOrientation: 'vertical',
        },
      ]}
      tooltip={({ data }) =>
        customTooltip ? (
          customTooltip(data)
        ) : tooltipContentFormat ? (
          <BarChartTooltip
            data={data}
            titleFormat={tooltipTitleFormat}
            contentFormat={tooltipContentFormat}
            colors={colors}
            getKeyColor={getDataKeyColor}
            sortKeys={legendKeysSorting}
            currency={currency}
          />
        ) : null
      }
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        BarMaskLayer,
      ]}
    />
  );
}

export interface BarChartProps {
  id: string;
  data: { [k: string]: TimeSeries };
  margin?: Partial<Margin>;
  aggPeriod: AggregationPeriod;
  fetchStatus: FetchStatus;
  tooltipContentFormat?: TooltipFormat;
  colors: string[];
  getBarColor?: GetBarColorFn;
  testId?: string;
  financialData?: boolean;
  hasLegend?: boolean;
  lastBarDotted?: boolean;
  leftTickFormat?: (value: number) => string;
  align?: BarChartAlignment;
  height?: number;
  legendAtom?: WritableAtom<string[], string[]>;
  legendKeyFilter?: (key: string) => boolean;
  getDataKeyColor?: (key: string) => string;
  sortKeys?: (keys: string[]) => string[];
  preventKeyHighlighting?: boolean;
  preventLegendToggle?: boolean;
  reverseLegendKeys?: boolean;
  justifyLegend?: JustifyLegend;
  horizontalLines?: number;
  legendMarginTop?: ChartLegendProps['marginTop'];
  customTooltip?: (data: BarData) => ReactElement;
}

const emptyHiddenKeysAtom = atom(() => []);

export function BarChart({
  id,
  data,
  aggPeriod,
  fetchStatus,
  testId,
  colors,
  height = 500,
  hasLegend = true,
  legendAtom,
  getDataKeyColor,
  sortKeys = (keys) => keys,
  legendKeyFilter = () => true,
  lastBarDotted,
  preventKeyHighlighting = false,
  preventLegendToggle = false,
  reverseLegendKeys = false,
  justifyLegend,
  horizontalLines,
  legendMarginTop,
  ...rest
}: BarChartProps) {
  const isMobile = useIsMobile();
  const effectiveHeight = useEffectiveChartHeight(height, hasLegend);
  const [highlightedKey, setHighlightedKey] = useState<string>('');
  const setHighlightedKeyCallback = useCallback(
    (key: string) => {
      if (preventKeyHighlighting) return;

      setHighlightedKey(key);
    },
    [preventKeyHighlighting],
  );
  const hiddenKeys = useAtomValue(legendAtom ?? emptyHiddenKeysAtom);
  const { t } = useTranslation('mission-control');
  const dataIsLoading = isLoading(fetchStatus);
  const hasError = isFetchError(fetchStatus);

  const chartCurrency = getTimeSeriesCurrency(data);

  const formattedBarData = useMemo(() => {
    return formatBarData(t, data || {});
  }, [data, t]);
  const unsortedKeys = Object.keys(formattedBarData[0] || []).filter(
    (key) => key !== 'id',
  );
  const keys = sortKeys ? sortKeys(unsortedKeys) : unsortedKeys;
  const legendKeysSorting = useCallback(
    (keys: string[]) => {
      if (reverseLegendKeys) {
        return sortKeys(keys).reverse();
      }
      return sortKeys(keys);
    },
    [sortKeys, reverseLegendKeys],
  );

  const legendKeys = useMemo(
    () =>
      legendKeysSorting(keys)
        .map((key, index) => ({
          key,
          color: colors[index] ?? DATA_VIZ_NEUTRAL,
        }))
        .filter(({ key }) => legendKeyFilter(key)),
    [keys, colors, legendKeysSorting, legendKeyFilter],
  );

  const formatTitle = useFormatTooltipTitle(aggPeriod);

  // We interpret all 'null' values in BarData as '0'.
  const normalizedBarData = useMemo(
    () =>
      formattedBarData.map((barData) =>
        mapValues((key, value) => {
          if (hiddenKeys.includes(key)) return '0';

          return key !== 'id' && value === null ? '0' : value;
        }, barData),
      ) as BarData[],
    [formattedBarData, hiddenKeys],
  );

  if (dataIsLoading || hasError) {
    return (
      <Box h={`${effectiveHeight - 50}px`} mb='70px'>
        <ChartSkeleton isLoading={dataIsLoading} />
      </Box>
    );
  }
  const lastPeriod = last(normalizedBarData)?.id;
  const lastPeriodIsCurrent = lastPeriod
    ? getEndOfPeriodOrToday(new Date(lastPeriod), aggPeriod).dateIsInFuture
    : false;

  return (
    <Box w='100%' data-testid={testId}>
      <ReactVirtualizedAutoSizer disableHeight>
        {({ width }) => {
          return (
            <Box width={width}>
              <Chart
                id={id}
                data={normalizedBarData}
                keys={keys}
                highlightedKey={highlightedKey}
                width={width}
                height={effectiveHeight}
                colors={colors}
                tooltipTitleFormat={(data: BarData) =>
                  formatTitle(new Date(data.id))
                }
                timeTickFormat={formatTimeAxisValues(
                  aggPeriod,
                  undefined,
                  isMobile,
                )}
                timeTickFrequency={
                  `every ${aggPeriod.toLowerCase()}` as TimeTickFrequency
                }
                lastBarDotted={lastPeriodIsCurrent && lastBarDotted}
                getDataKeyColor={getDataKeyColor}
                onHighlight={setHighlightedKeyCallback}
                legendKeysSorting={legendKeysSorting}
                currency={chartCurrency}
                horizontalLines={horizontalLines}
                {...rest}
              />
              {hasLegend && (legendAtom || preventLegendToggle) ? (
                <ChartLegend
                  keys={legendKeys}
                  legendAtom={legendAtom}
                  getKeyColor={getDataKeyColor}
                  onHighlight={setHighlightedKeyCallback}
                  justifyLegend={justifyLegend}
                  marginTop={legendMarginTop}
                />
              ) : (
                !isMobile && <Box height='20px' />
              )}
            </Box>
          );
        }}
      </ReactVirtualizedAutoSizer>
    </Box>
  );
}
