import { ChangeEvent, useState } from 'react';

import { Input } from '@chakra-ui/react';

import { HStack, Text } from 'components/uikit';

type GoToPageProps = {
  onPaginationChange?: (page: number) => void;
  total: number;
};

export function GoToPageElement({ onPaginationChange, total }: GoToPageProps) {
  const [page, setPage] = useState<number | null>(null);
  return (
    <HStack>
      <Text fontSize='14px' fontWeight='medium' color='grey.secondaryText'>
        {' '}
        Go to
      </Text>
      <Input
        p={0}
        value={page ?? ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPage(e.target.valueAsNumber);
        }}
        fontSize='14px'
        textAlign='center'
        background='grey.white'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (page && page > 0 && page <= total) {
              onPaginationChange?.(page);
            }
          }
        }}
        borderRadius='2px'
        width='32px'
        height='24px'
        type='number'
      />
      <Text fontSize='14px' fontWeight='medium' color='grey.secondaryText'>
        page
      </Text>
    </HStack>
  );
}
