import { AiAnswerBreakdown } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswerBreakdown';
import { AiAnswerLineChart } from 'components/AiDrawer/AiChat/AiAnswer/AiAnswerLineChart';
import { AiChartData, isBreakdown, isTimeSeries } from 'data/ai/models';

type AiAnswerChartProps = AiChartData;

export function AiAnswerChart(props: AiAnswerChartProps) {
  if (isTimeSeries(props)) return <AiAnswerLineChart {...props} />;
  if (isBreakdown(props)) return <AiAnswerBreakdown {...props} />;
  return null;
}
