import React, { ChangeEvent, useCallback, useState } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  SystemStyleObject,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';

import { BoxProps } from '../../reexport';
import { getInputIcon } from './getInputIcon';
import { getInputVariant } from './getInputVariant';
import { getMessageColorForStatus, InputStatus } from './InputStatus';

interface TextareaProps
  extends Omit<BoxProps, 'onChange' | 'onFocus' | 'onBlur'> {
  name?: string;
  rows?: number;
  id?: string;
  label?: string;
  placeholder?: string;
  _placeholder?: SystemStyleObject;
  _focus?: SystemStyleObject;
  value?: string;
  reset?: () => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  inputStatus?: InputStatus;
  message?: string;
  resize?: ChakraTextareaProps['resize'];
  ref?: React.RefObject<HTMLTextAreaElement>;
  autoFocus?: boolean;
  autoSize?: boolean;
  submitOnEnter?: boolean;
}

export function Textarea({
  name,
  id,
  label,
  rows,
  placeholder,
  _placeholder,
  _focus,
  value,
  message,
  inputStatus,
  onFocus,
  onBlur,
  onChange,
  reset,
  resize,
  ref,
  autoFocus = false,
  autoSize = false,
  submitOnEnter = false,
  ...props
}: TextareaProps) {
  const rightIcon = getInputIcon(inputStatus);
  const [autoHeight, setAutoHeight] = useState(48);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e);
      if (autoSize) {
        setAutoHeight(e.target.scrollHeight + 2);
      }
    },
    [onChange, setAutoHeight, autoSize],
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (
        submitOnEnter &&
        e.key === 'Enter' &&
        !e.shiftKey &&
        'form' in e.target
      ) {
        e.preventDefault();
        (e.target.form as HTMLFormElement).requestSubmit();
      }
    },
    [submitOnEnter],
  );

  return (
    <FormControl
      w='100%'
      h='100%'
      display='flex'
      flexDir='column'
      rowGap='6px'
      {...props}
    >
      <FormLabel
        color='grey.tertiaryText'
        fontSize='sm'
        fontWeight='medium'
        lineHeight='20px'
        m='0 0 0 2px'
        htmlFor={id}
      >
        {label}
      </FormLabel>
      <InputGroup variant={getInputVariant(inputStatus)}>
        <ChakraTextarea
          id={id}
          name={name}
          placeholder={placeholder}
          _placeholder={_placeholder}
          color='grey.primaryText'
          backgroundColor='grey.white'
          disabled={inputStatus === 'DISABLED'}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          resize={resize}
          minH='48px'
          rows={autoSize ? undefined : rows}
          height={autoSize ? `${autoHeight}px` : undefined}
          autoFocus={autoFocus}
          _focus={_focus}
          ref={ref}
          onKeyDown={onKeyDown}
        />

        {rightIcon ? (
          <InputRightElement bottom='0px' top='inherit'>
            {rightIcon}
          </InputRightElement>
        ) : null}
      </InputGroup>

      <FormErrorMessage
        fontSize='sm'
        color={getMessageColorForStatus(inputStatus)}
        fontWeight='400'
        position='absolute'
        left='2px'
        bottom='-4px'
        transform='translateY(100%)'
      >
        {message}
      </FormErrorMessage>
    </FormControl>
  );
}
