import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import {
  CustomerItemProps,
  iconsByType,
} from 'components/CommandPanel/Modal/ListItems/UserMenuListItem';
import { useOnCustomerCompanyDrawerOpen } from 'components/CustomerDrawer/hooks/useOnCustomerCompanyDrawerOpen';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';
import { noop } from 'utils/helpers';

export function CompanyMenuListItem({
  item,
  index,
  isSelected,
  onSelect,
  onClose,
}: CustomerItemProps) {
  const onOpen = useOnCustomerCompanyDrawerOpen({
    department: item.department,
    departmentId: item.departmentId,
    onDataLoaded: noop,
  });
  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.COMPANY_SELECTED}
      icon={iconsByType.COMPANY}
      id={item.id}
      name={item.name}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={() => {
        onOpen();
        onClose();
      }}
    />
  );
}
