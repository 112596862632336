import { useTranslation } from 'react-i18next';

import { Delta } from 'components/Delta/Delta';
import { RevenueBreakdownRow } from 'data/charts/atoms/product/users/breakdowns';
import { TableConfig } from 'data/charts/models/TableConfig';
import { useDefaultCurrency } from 'data/settings/hooks/finance/useDefaultCurrency';
import { formatDate } from 'utils/datetime';
import { formatCurrency, getCurrencyFormatter } from 'utils/formatting/number';

export function useCustomerFinanceRevenueBreakdownConfig() {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs.drill_down_tab.finance_revenue',
  });
  const currency = useDefaultCurrency();

  const tableConfig: TableConfig<RevenueBreakdownRow> = [
    {
      accessor: 'movementType',
      header: t('breakdown.movement_type'),
      style: {
        fontWeight: '500',
        textColor: 'grey.primaryText',
        width: '250px',
        align: 'left',
      },
    },
    {
      accessor: 'date',
      header: t('breakdown.date'),
      style: {
        align: 'right',
        textColor: 'grey.tertiaryText',
      },
      render: ({ date }) => formatDate(date, 'DAY', true, false, true),
    },
    {
      accessor: 'amount',
      header: t('breakdown.change'),
      render: ({ amount }) => (
        <Delta
          value={amount}
          valueFormatter={getCurrencyFormatter(currency)}
          py={0}
          my={0}
          maxH='15px'
        />
      ),
      style: {
        align: 'right',
      },
    },
    {
      accessor: 'customerMrr',
      header: t('breakdown.mrr'),
      style: {
        align: 'right',
      },
      render: ({ customerMrr }) => formatCurrency(customerMrr, currency),
    },
  ];

  return { tableConfig };
}
