import { createIcon } from '@chakra-ui/react';

export const WarningCircleIcon = createIcon({
  displayName: 'WarningCircleIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <rect width='100%' height='100%' fill='#FEE4E2' rx='10' />
      <path
        stroke='#D92D20'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='#FEE4E2'
        d='M10 8v2m0 2h.004m-.86-6.57L4.91 12.5a1 1 0 0 0 .855 1.5h8.47a.999.999 0 0 0 .855-1.5l-4.235-7.07a1 1 0 0 0-1.71 0Z'
      />
    </>
  ),
});
