import { Input, InputProps } from './Input';

type EditableTextInputProps = Omit<InputProps, 'hasStatusIcon'>;

export function EditableTextInput({
  fontSize,
  ...props
}: EditableTextInputProps) {
  return (
    <Input
      hasStatusIcon={false}
      sx={{
        input: {
          border: 'none',
          boxShadow: 'none !important',
          fontSize,
          p: 0,
          pl: props.icon ? '40px' : 0,
          caretColor: '#175CD3',
        },
        p: {
          left: 0,
          bottom: '6px',
        },
      }}
      _dark={{
        input: {
          caretColor: '#E5F3FF',
        },
      }}
      {...props}
    />
  );
}
