import { GoogleIcon } from './icons/Google';
import { Button, ButtonProps } from './reexport';

function Google(props: ButtonProps) {
  return (
    <Button
      variant='outline'
      leftIcon={<GoogleIcon />}
      colorScheme='gray'
      size='lg'
      {...props}
    />
  );
}

export const oauthButtons = {
  Google,
};
