import { useCallback, useState } from 'react';

import {
  BreakdownTr,
  BreakdownTrProps,
} from 'components/charts/FullBreakdownTable/FullBreakdownTableRow';
import { useOnCustomerUserDrawerOpen } from 'components/CustomerDrawer/hooks/useOnCustomerUserDrawerOpen';
import { RowConfig } from 'data/charts/models/TableConfig';
import { DrawerEnabledDepartments } from 'data/customer/DrawerEnabledDepartments';
import { rowHoverEffect } from 'styles/rowHoverEffect';

interface ProductCustomerRowProps {
  userId?: string;
}

interface FinanceCustomerRowProps {
  customerId?: string;
}

type RowComponentProps<T extends DrawerEnabledDepartments> = T extends 'PRODUCT'
  ? ProductCustomerRowProps
  : FinanceCustomerRowProps;

function getDrawerOnOpen<T extends DrawerEnabledDepartments>(
  department: T,
  row: RowComponentProps<T>,
) {
  if (department === 'PRODUCT') {
    const r = row as ProductCustomerRowProps;
    return r.userId ? r.userId : undefined;
  } else if (department === 'FINANCE') {
    const r = row as FinanceCustomerRowProps;
    return r.customerId ? r.customerId : undefined;
  } else {
    return undefined;
  }
}

function CustomerUserDrawerEnabledRow<T extends DrawerEnabledDepartments>({
  department,
  departmentId,
  row,
  ...rest
}: BreakdownTrProps<RowComponentProps<T>> & {
  department: T;
  departmentId: string;
}) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const onDataLoaded = useCallback(() => {
    setDataLoaded(true);
  }, []);

  const onOpen = useOnCustomerUserDrawerOpen({
    department,
    departmentId,
    onDataLoaded,
  });

  return (
    <BreakdownTr
      row={row}
      onClick={onOpen}
      _hover={dataLoaded ? rowHoverEffect : undefined}
      {...rest}
    />
  );
}

export function getUserCustomerDrawerRow<T extends DrawerEnabledDepartments>(
  department: T,
) {
  const RowComponent = ({
    row,
    ...rest
  }: BreakdownTrProps<RowComponentProps<T>>) => {
    const departmentId = getDrawerOnOpen(department, row) ?? '';

    if (!departmentId) {
      return <BreakdownTr row={row} {...rest} />;
    }

    return (
      <CustomerUserDrawerEnabledRow
        department={department}
        departmentId={departmentId}
        row={row}
        {...rest}
      />
    );
  };

  RowComponent.displayName = 'CustomerUserNameRow';

  return RowComponent;
}

export const useCustomerUserNameRowConfig = <
  T extends DrawerEnabledDepartments,
>(
  department: T,
): RowConfig<RowComponentProps<T>> => {
  return { RowComponent: getUserCustomerDrawerRow(department) };
};
