import { z } from 'zod';

import { ProductUniqueBlockName } from 'data/blocks/models/Block';
import {
  breakdownByCompaniesLargestDecreaseInActivityAtoms,
  breakdownByCompaniesLargestIncreaseInActivityAtoms,
  breakdownByMostActiveCompaniesAtoms,
  breakdownByMostEngagedCompaniesAtoms,
  breakdownByNewCompaniesAtoms,
  FullBreakdownByCompaniesRow,
} from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByCompanies';
import { breakdownByNewSignUpCohortsAtoms } from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByNewSignUpsCohorts';
import {
  breakdownByPowerUsersAtoms,
  breakdownByToPowerUserAtoms,
  FullBreakdownByPowerUsersRow,
} from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByPowerUsers';
import {
  breakdownByMostActiveUsersAtoms,
  breakdownByMostEngagedUsersAtoms,
  breakdownByNewUsersAtoms,
  breakdownByUsersLargestDecreaseInActivityAtoms,
  breakdownByUsersLargestIncreaseInActivityAtoms,
} from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByUsers';
import {
  breakdownByUsersEngagementAtoms,
  FullBreakdownByUsersEngagementRow,
} from 'data/charts/atoms/fullBreakdowns/Product/FullBreakdownByUsersEngagement';
import { FullBreakdownByUsersRow } from 'data/charts/atoms/product/users/breakdowns';
import {
  BreakdownConfig,
  BreakdownConfigBase,
} from 'data/charts/configs/breakdowns/types';

export const ProductBreakdownName = z.enum([
  'byNewUsers',
  'byMostActive',
  'byLargestIncreaseInActivity',
  'byLargestDecreaseInActivity',
  'byMostEngaged',
  'byNewestPowerUsers',
  'byTopPowerUsers',
  'byUserEngagement',
  'byNewSignUpCohorts',
  'byNewCompanies',
  'byMostActiveCompanies',
  'byCompaniesLargestIncreaseInActivity',
  'byCompaniesLargestDecreaseInActivity',
  'byMostEngagedCompanies',
]);
export type ProductBreakdownName = z.infer<typeof ProductBreakdownName>;
export type SortableProductBreakdownNames = Exclude<
  ProductBreakdownName,
  'byNewSignUpCohorts'
>;
export type FilterableProductBreakdownNames = Extract<
  ProductBreakdownName,
  'byNewUsers'
>;
export type ProductBreakdownWithPaginationName = SortableProductBreakdownNames;

export const PRODUCT_BREAKDOWN_CONFIG: Record<
  SortableProductBreakdownNames,
  BreakdownConfig<ProductUniqueBlockName>
> & { byNewSignUpCohorts: BreakdownConfigBase<ProductUniqueBlockName> } = {
  byNewUsers: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_SIGN_UP_DATE',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: breakdownByNewUsersAtoms,
  },
  byMostEngaged: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_ENGAGED_DAYS',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: breakdownByMostEngagedUsersAtoms,
  },
  byMostActive: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_ACTIVE_DAYS',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: breakdownByMostActiveUsersAtoms,
  },
  byLargestIncreaseInActivity: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_INCREASE_IN_ACTIVITY',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: breakdownByUsersLargestIncreaseInActivityAtoms,
  },
  byLargestDecreaseInActivity: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_BY_DECREASE_IN_ACTIVITY',
    columnParser: FullBreakdownByUsersRow.keyof(),
    atoms: breakdownByUsersLargestDecreaseInActivityAtoms,
  },
  byNewestPowerUsers: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_POWER_USERS_BY_JOIN_DATE',
    columnParser: FullBreakdownByPowerUsersRow.keyof(),
    atoms: breakdownByPowerUsersAtoms,
  },
  byTopPowerUsers: {
    blockName:
      'FULL_BREAKDOWNS_PRODUCT_BY_POWER_USERS_BY_POWER_USER_EVENT_COUNT',
    columnParser: FullBreakdownByPowerUsersRow.keyof(),
    atoms: breakdownByToPowerUserAtoms,
  },
  byNewSignUpCohorts: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_COHORT_BY_NEW_SIGN_UPS',
    atoms: breakdownByNewSignUpCohortsAtoms,
  },
  byUserEngagement: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_USERS_ENGAGEMENT',
    columnParser: FullBreakdownByUsersEngagementRow.keyof(),
    atoms: breakdownByUsersEngagementAtoms,
  },
  byCompaniesLargestIncreaseInActivity: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_INCREASE_IN_ACTIVITY',
    columnParser: FullBreakdownByCompaniesRow.keyof(),
    atoms: breakdownByCompaniesLargestIncreaseInActivityAtoms,
  },
  byNewCompanies: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_JOIN_DATE',
    columnParser: FullBreakdownByCompaniesRow.keyof(),
    atoms: breakdownByNewCompaniesAtoms,
  },
  byMostActiveCompanies: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_ACTIVE_DAYS',
    columnParser: FullBreakdownByCompaniesRow.keyof(),
    atoms: breakdownByMostActiveCompaniesAtoms,
  },
  byMostEngagedCompanies: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_ENGAGED_DAYS',
    columnParser: FullBreakdownByCompaniesRow.keyof(),
    atoms: breakdownByMostEngagedCompaniesAtoms,
  },
  byCompaniesLargestDecreaseInActivity: {
    blockName: 'FULL_BREAKDOWNS_PRODUCT_BY_COMPANIES_BY_DECREASE_IN_ACTIVITY',
    columnParser: FullBreakdownByCompaniesRow.keyof(),
    atoms: breakdownByCompaniesLargestDecreaseInActivityAtoms,
  },
};
