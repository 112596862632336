import { Flex } from 'components/uikit';

export interface HeaderProps {
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
}

/**
 * Header component for main layout. Can be 2 or 3 column
 */
export function Header({ left, middle, right }: HeaderProps) {
  return (
    <Flex
      borderBottom='1px solid'
      borderColor='grey.border'
      height='52px'
      bg='grey.white'
      align='center'
      justifyContent='center'
      padding='0 32px'
      width='100%'
    >
      {left ? (
        <Flex
          height='100%'
          px='16px'
          align='center'
          justifyContent='center'
          minWidth='290px'
        >
          {left}
        </Flex>
      ) : null}
      <Flex
        height='100%'
        width='1320px'
        align='center'
        justifyContent='space-between'
      >
        {middle}
        {right}
      </Flex>
    </Flex>
  );
}
