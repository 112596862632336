import { useAtomValue } from 'jotai';

import { DataSource } from 'api/graphql/backendAPI/generatedTypes';
import { Department } from 'data/blocks/models/Block';
import { isDataSourceActive } from 'data/dataSources/hooks/useActiveGAProvider';
import { getDepartment, hasBeenSyncedOnce } from 'data/dataSources/models';
import { groupBy, mapValues } from 'utils/helpers';
import { FetchStatus } from 'utils/requests/types';

import { dataSourcesAtom } from '../atoms/dataSourcesAtom';

export const useDataSources = () => {
  return useAtomValue(dataSourcesAtom);
};

type DataSourceByProviderResponse = {
  dataSource: DataSource | null;
  isActive: boolean;
  lastSyncedAt: Date | null;
  fetchStatus: FetchStatus;
};

const NOT_SYNCED_DATA_SOURCE: DataSourceByProviderResponse = {
  dataSource: null,
  isActive: false,
  lastSyncedAt: null,
  fetchStatus: 'ERROR',
};

export const useDataSourceByDepartment = (
  department: Department,
): DataSourceByProviderResponse => {
  const { dataSources, fetchStatus } = useDataSources();
  if (dataSources == null) return NOT_SYNCED_DATA_SOURCE;
  const providerDataSources = Object.values(dataSources).filter(
    (dataSource) =>
      getDepartment(dataSource?.provider) === department &&
      dataSource?.provider != 'XERO',
  );

  const dataSource = providerDataSources.find(
    (dataSource) => dataSource != null && isDataSourceActive(dataSource.status),
  );

  if (providerDataSources.length === 0 || dataSource == null) {
    return NOT_SYNCED_DATA_SOURCE;
  }

  const lastSyncedAt =
    dataSource.lastSyncedAt != null ? new Date(dataSource.lastSyncedAt) : null;
  return {
    dataSource: dataSource ?? null,
    isActive: isDataSourceActive(dataSource.status),
    lastSyncedAt,
    fetchStatus,
  };
};

export const useConnectedDepartments = () => {
  const { dataSources, ...remote } = useDataSources();
  const departments = mapValues(
    (_, dataSources) =>
      dataSources.some(({ status }) => hasBeenSyncedOnce(status)),
    groupBy<DataSource, Department>(
      ({ provider }) => getDepartment(provider),
      Object.values(
        dataSources ??
          Department.options.reduce((acc, key) => {
            acc[key] = [];
            return acc;
          }, {} as Record<Department, DataSource[]>),
      ),
    ),
  );

  return { departments, ...remote };
};
