export const DEPARTMENTS = <const>[
  'GROWTH',
  'SALES',
  'SUPPORT',
  'PRODUCT',
  'FINANCE',
];

export type DepartmentType = typeof DEPARTMENTS[number];

export const DEPARTMENT_NAMES = <const>[
  'marketing',
  'finance',
  'sales',
  'product',
  'support',
];

export type DepartmentNameType = typeof DEPARTMENT_NAMES[number];

export const DEPARTMENT_NAMES_PER_TYPE: {
  [key in DepartmentType]: DepartmentNameType;
} = <const>{
  GROWTH: 'marketing',
  FINANCE: 'finance',
  SALES: 'sales',
  PRODUCT: 'product',
  SUPPORT: 'support',
};

export const DEPARTMENT_TYPES_PER_NAME: {
  [key in DepartmentNameType]: DepartmentType;
} = <const>{
  marketing: 'GROWTH',
  finance: 'FINANCE',
  sales: 'SALES',
  product: 'PRODUCT',
  support: 'SUPPORT',
};
