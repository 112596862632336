import { FC } from 'react';

import { z } from 'zod';

import {
  AmplitudeMenuIcon,
  BrainTreeMenuIcon,
  ChargebeeMenuIcon,
  CloseMenuIcon,
  FreshDeskMenuIcon,
  FrontMenuIcon,
  GoogleAdsMenuIcon,
  GoogleAnalyticsMenuIcon,
  HeapMenuIcon,
  HelpScoutMenuIcon,
  HubspotMenuIcon,
  IconProps,
  IntercomMenuIcon,
  LinkedinMenuIcon,
  MetaMenuIcon,
  MixpanelMenuIcon,
  PaddleMenuIcon,
  PipedriveMenuIcon,
  PosthogMenuIcon,
  RecurlyMenuIcon,
  SalesForceMenuIcon,
  SegmentMenuIcon,
  StripeMenuIcon,
  SugarMenuIcon,
  TiktokMenuIcon,
  XMenuIcon,
  ZendeskMenuIcon,
  ZohoCrmMenuIcon,
  ZuoraMenuIcon,
} from 'components/uikit';

const onboardingProviderSteps = [
  'GA_ACCOUNT_SELECT',
  'HUBSPOT_SYNC',
  'STRIPE_API_KEY',
  'XERO_SYNC',
  'SEGMENT_API_SECRET',
  'MIXPANEL_API_KEY',
  'AMPLITUDE_API_KEY',
  'CHARGEBEE_API_KEY',
  'INTERCOM_SYNC',
] as const;

const onboardingUsedProviderSteps = [
  'SELECT_PROVIDER_MARKETING',
  'SELECT_PROVIDER_SALES',
  'SELECT_PROVIDER_SUPPORT',
  'SELECT_PROVIDER_PRODUCT',
  'SELECT_PROVIDER_FINANCE',
] as const;

export const OnboardingStep = z.enum([
  'WELCOME',
  'USER_DETAILS',
  'COMPANY_NAME',
  ...onboardingUsedProviderSteps,
  'AVAILABLE_INTEGRATIONS',
  'SELECT_INTEGRATION',
  ...onboardingProviderSteps,
  'CONNECT_ANOTHER_PROVIDER',
  'SLACK',
  'COLOR_THEME',
  'CHANNEL',
  'SETUP_COMPLETED',
  'INSIGHTS_READY',
]);
export type OnboardingStep = z.infer<typeof OnboardingStep>;

export const Channel = z.enum([
  'ANOTHER_FOUNDER',
  'INVESTOR',
  'SOCIAL_MEDIA',
  'GOOGLE',
  'BLOG',
  'BETA_LIST',
  'INVESTOR_MATCH',
  'STRIPE_MARKETPLACE',
  'SALES_EMAIL',
  'OTHER',
]);
export type Channel = z.infer<typeof Channel>;

export const TourStep = z.enum([
  'RADAR',
  'MISSION_CONTROL',
  'DEPARTMENTS',
  'METRICS_DICTIONARY',
  'DATA_SOURCES',
  'PRE_COMPLETED',
  'COMPLETED',
]);
export type TourStep = z.infer<typeof TourStep>;
export const OnboardingProviderStep = z.enum(onboardingProviderSteps);
export type OnboardingProviderStep = z.infer<typeof OnboardingProviderStep>;
export const CompanyOnboarding = z.object({
  company: z.string(),
  currentStep: OnboardingStep,
  progress: z.number(),
  mainProductActive: z.boolean(),
  tourStep: TourStep,
  updatedAt: z.string(),
});
export type CompanyOnboarding = z.infer<typeof CompanyOnboarding>;

export interface UpdateCompanyOnboardingParams {
  companyNamespace: string;
  step: OnboardingStep;
  progress: number;
}

export interface UpdateTourStep {
  companyNamespace: string;
  tourStep: TourStep;
}

const MarketingProvider = z.enum([
  'GOOGLE_ANALYTICS',
  'GOOGLE_ADS',
  'X',
  'LINKEDIN',
  'TIKTOK',
  'META',
  'MARKETING_OTHER',
]);
const SalesProvider = z.enum([
  'HUBSPOT',
  'CLOSE',
  'ZOHO_CRM',
  'SUGAR',
  'PIPEDRIVE',
  'SALESFORCE',
  'SALES_OTHER',
]);
const SupportProvider = z.enum([
  'INTERCOM',
  'HELP_SCOUT',
  'FRESH_DESK',
  'FRONT',
  'ZENDESK',
  'SUPPORT_OTHER',
]);
const ProductProvider = z.enum([
  'SEGMENT',
  'MIXPANEL',
  'AMPLITUDE',
  'POSTHOG',
  'HEAP',
  'PRODUCT_OTHER',
]);
const AcquiringProvider = z.enum([
  'STRIPE',
  'BRAIN_TREE',
  'PADDLE',
  'RECURLY',
  'CHARGEBEE',
  'ZUORA',
  'ACQUIRING_OTHER',
]);

export const UsedProvider = z.union([
  MarketingProvider,
  SalesProvider,
  SupportProvider,
  ProductProvider,
  AcquiringProvider,
]);
export type UsedProvider = z.infer<typeof UsedProvider>;

export const UsedProvidersByDepartment = z.object({
  GROWTH: MarketingProvider.array(),
  SALES: SalesProvider.array(),
  SUPPORT: SupportProvider.array(),
  PRODUCT: ProductProvider.array(),
  FINANCE: AcquiringProvider.array(),
});
export type UsedProvidersByDepartment = z.infer<
  typeof UsedProvidersByDepartment
>;

export const USED_PROVIDERS_BY_DEPARTMENT: UsedProvidersByDepartment = {
  GROWTH: Object.values(MarketingProvider.enum),
  SALES: Object.values(SalesProvider.enum),
  SUPPORT: Object.values(SupportProvider.enum),
  PRODUCT: Object.values(ProductProvider.enum),
  FINANCE: Object.values(AcquiringProvider.enum),
};

export const USED_PROVIDER_ICONS: Record<UsedProvider, FC<IconProps> | null> = {
  GOOGLE_ANALYTICS: GoogleAnalyticsMenuIcon,
  GOOGLE_ADS: GoogleAdsMenuIcon,
  X: XMenuIcon,
  LINKEDIN: LinkedinMenuIcon,
  TIKTOK: TiktokMenuIcon,
  META: MetaMenuIcon,
  HUBSPOT: HubspotMenuIcon,
  CLOSE: CloseMenuIcon,
  ZOHO_CRM: ZohoCrmMenuIcon,
  SUGAR: SugarMenuIcon,
  PIPEDRIVE: PipedriveMenuIcon,
  SALESFORCE: SalesForceMenuIcon,
  INTERCOM: IntercomMenuIcon,
  HELP_SCOUT: HelpScoutMenuIcon,
  FRESH_DESK: FreshDeskMenuIcon,
  FRONT: FrontMenuIcon,
  ZENDESK: ZendeskMenuIcon,
  SEGMENT: SegmentMenuIcon,
  MIXPANEL: MixpanelMenuIcon,
  AMPLITUDE: AmplitudeMenuIcon,
  POSTHOG: PosthogMenuIcon,
  HEAP: HeapMenuIcon,
  STRIPE: StripeMenuIcon,
  BRAIN_TREE: BrainTreeMenuIcon,
  PADDLE: PaddleMenuIcon,
  RECURLY: RecurlyMenuIcon,
  CHARGEBEE: ChargebeeMenuIcon,
  ZUORA: ZuoraMenuIcon,
  MARKETING_OTHER: null,
  SALES_OTHER: null,
  SUPPORT_OTHER: null,
  PRODUCT_OTHER: null,
  ACQUIRING_OTHER: null,
};
