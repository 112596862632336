import { createIcon } from '@chakra-ui/react';

export const PlusSquareIcon = createIcon({
  displayName: 'PlusCircleIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M20.3112 1.5H3.97786C2.6892 1.5 1.64453 2.54467 1.64453 3.83333V20.1667C1.64453 21.4553 2.6892 22.5 3.97786 22.5H20.3112C21.5999 22.5 22.6445 21.4553 22.6445 20.1667V3.83333C22.6445 2.54467 21.5999 1.5 20.3112 1.5Z'
        fill='none'
      />
      <path
        d='M12.1445 7.33333V16.6667M7.47786 12H16.8112M3.97786 1.5H20.3112C21.5999 1.5 22.6445 2.54467 22.6445 3.83333V20.1667C22.6445 21.4553 21.5999 22.5 20.3112 22.5H3.97786C2.6892 22.5 1.64453 21.4553 1.64453 20.1667V3.83333C1.64453 2.54467 2.6892 1.5 3.97786 1.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </>
  ),
});
