import { useAtomValue } from 'jotai';

import { sharingBlockContextAtom } from 'components/Sharing/sharingBlockContextAtom';
import { SharingBlockModalBody } from 'components/Sharing/SharingBlockModalBody';
import { useGetModalTitle } from 'components/Sharing/useGetModalTitle';
import { Modal, ShareIcon } from 'components/uikit';
import ModalHeader from 'components/uikit/ModalHeader';

export function SharingBlockModalHeader() {
  const { shareBlockConfig } = useAtomValue(sharingBlockContextAtom);
  const title = useGetModalTitle(shareBlockConfig?.blockName);

  return <ModalHeader title={title} IconComponent={ShareIcon} />;
}

interface SharingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SharingBlockModal({ isOpen, onClose }: SharingModalProps) {
  return (
    <Modal
      isCentered
      header={<SharingBlockModalHeader />}
      body={<SharingBlockModalBody />}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth='3xl'
    />
  );
}
