import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useCustomerTags } from 'components/CustomerDrawer/hooks/useCustomerTags';
import { TagCheckboxLabel } from 'components/CustomerDrawer/Tags/TagCheckboxLabel';
import { Box, Checkbox, SearchIcon, Spinner, VStack } from 'components/uikit';
import { EditableTextInput } from 'components/uikit/form/inputs/EditableTextInput';
import { CustomerTag } from 'data/customer/CustomerTag';
import { searchTagsBoxValueAtom } from 'data/healthReports/atoms/searchTagsBoxValueAtom';
import { tagsToUpdateAtom } from 'data/healthReports/atoms/tagsToUpdateAtom';
import { useCustomerUser } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { stringContainsAnother } from 'data/healthReports/utils/stringContainsAnother';
import { hideScrollbar } from 'styles/hideScrollbar';
import { combineStatuses, isLoading } from 'utils/types';

function TagsListLoader() {
  return (
    <VStack height={'100%'} justifyContent={'center'}>
      <Spinner thickness={'4px'} color={'grey.tertiaryText'} boxSize={'24px'} />
    </VStack>
  );
}

interface UserTagsListProps {
  userId: string;
}

export function UserTagsList({ userId }: UserTagsListProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.user_tags_modal.body',
  });
  const { user, fetchStatus: companyFetchStatus } = useCustomerUser(userId);
  const { tags: allTags, fetchStatus: tagsFetchStatus } = useCustomerTags();
  const [tags, setTagsToUpdate] = useAtom(tagsToUpdateAtom);
  const [filteredTags, setFilteredTags] = useState(allTags);
  const [searchBoxValue, setSearchBoxValue] = useAtom(searchTagsBoxValueAtom);
  const fetchStatus = combineStatuses(companyFetchStatus, tagsFetchStatus);

  const onTagSelected = useCallback(
    (tag: CustomerTag) => {
      if (tags.includes(tag.id)) {
        setTagsToUpdate(
          tags.filter((selectedTagId) => selectedTagId !== tag.id),
        );
      } else {
        setTagsToUpdate([...tags, tag.id]);
      }
    },
    [tags, setTagsToUpdate],
  );

  useEffect(() => {
    if (user) {
      setTagsToUpdate(user.tags);
    }
  }, [user, setTagsToUpdate]);

  useEffect(() => {
    setFilteredTags(
      allTags.filter((tag) =>
        stringContainsAnother(
          `${tag.title} ${tag.description}`,
          searchBoxValue,
        ),
      ),
    );
  }, [allTags, searchBoxValue]);

  if (!user) {
    return <></>;
  }

  if (isLoading(fetchStatus)) {
    return <TagsListLoader />;
  }

  const onSearchInputChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    setSearchBoxValue(currentTarget.value);
  };

  return (
    <VStack
      p={'16px 16px 0 16px'}
      spacing={'8px'}
      height={'100%'}
      overflow={'hidden'}
    >
      <EditableTextInput
        border={'1px solid'}
        borderColor={'grey.border'}
        borderRadius={'4px'}
        type='text'
        placeholder={t('search_tags_input_placeholder')}
        icon={<SearchIcon boxSize='16px' color='grey.secondaryText' />}
        value={searchBoxValue}
        onChange={onSearchInputChange}
      />

      <VStack
        spacing={'8px'}
        height={'100%'}
        width={'100%'}
        overflowY={'scroll'}
        css={hideScrollbar}
      >
        {filteredTags.length === 0 && (
          <VStack height={'100%'} width={'70%'} justify={'center'}>
            <Box textAlign={'center'}>{t('no_tags_found')}</Box>
          </VStack>
        )}
        {filteredTags.map((tag, index) => {
          return (
            <Checkbox
              p={'4px'}
              borderRadius={'8px'}
              key={`${tag}-${index}`}
              isChecked={tags.includes(tag.id)}
              onChange={() => onTagSelected(tag)}
              value={tag.id}
              label={<TagCheckboxLabel tag={tag} />}
              width={'100%'}
              _hover={{ background: 'grey.offWhite' }}
            />
          );
        })}
      </VStack>
    </VStack>
  );
}
