import {
  Currency,
  formatCompactNumber,
  FormattedObject,
  formatWholeNumber,
  getGroupCurrencyFormatter,
  getNumberGroupFormatter,
  NumberFormatter,
  NumberFormattingOptions,
} from 'utils/formatting/number/core';

export function formatPercentage(value: number) {
  return formatCompactNumber(value, { style: 'percent' });
}

export function formatPercentagePoint(value: number) {
  return formatCompactNumber(value, { style: 'percentagePoint' });
}

export function formatIntegerPercentage(value: number) {
  return formatCompactNumber(value, { style: 'percent', isInteger: true });
}

export function formatIntegerNumber(value: number) {
  return formatCompactNumber(value, { style: 'integer' });
}

export function formatCurrency(value: number, currency: Currency) {
  return formatCompactNumber(value, { style: 'currency', currency });
}

export function getCurrencyFormatter(currency: Currency): NumberFormatter {
  return (value) => formatCurrency(value, currency);
}

function getMultipleNumbersInObjectCollectionFormatter<
  K extends string,
  T extends { [key in K]: number },
>(
  collection: T[],
  keys: K[],
  options?: {
    [key in K]: NumberFormattingOptions;
  },
): {
  [key in K]: NumberFormatter;
} {
  return Object.fromEntries(
    keys.map((key) => [
      key,
      getNumberGroupFormatter(
        collection.map((item) => item[key]),
        options?.[key],
      ),
    ]),
  ) as { [key in K]: NumberFormatter };
}

export function formatWholeNumbersInCollection<
  K extends string,
  T extends { [key in K]: number },
>(collection: T[], key: K, currency?: Currency): FormattedObject<K, T>[] {
  return collection.map((item) => ({
    ...item,
    [key]: formatWholeNumber(item[key], currency),
  }));
}

export function formatNumbersInObjectCollection<
  K extends string,
  T extends { [key in K]: number },
>(
  collection: T[],
  key: K,
  options?: NumberFormattingOptions,
): FormattedObject<K, T>[] {
  const formattingOptions =
    options == null
      ? undefined
      : ({ [key]: options } as { [key in K]: NumberFormattingOptions });
  return formatMultipleNumbersInCollection(
    collection,
    [key],
    formattingOptions,
  );
}

export function formatCurrencyInObjectCollection<
  K extends string,
  T extends { [k in K]: number } & { currency: Currency },
>(collection: T[], key: K): FormattedObject<K, T>[] {
  const currencyFormatter = getGroupCurrencyFormatter(
    collection.map((item) => item[key]),
  );

  return collection.map((item) => ({
    ...item,
    [key]: currencyFormatter(item[key], item.currency),
  }));
}

export function formatWholeCurrencyInCollection<
  K extends string,
  T extends { [k in K]: number } & { currency: Currency },
>(collection: T[], key: K): FormattedObject<K, T>[] {
  return collection.map((item) => ({
    ...item,
    [key]: formatWholeNumber(item[key], item.currency),
  }));
}

export function formatMultipleNumbersInCollection<
  K extends string,
  T extends { [key in K]: number },
>(
  collection: T[],
  keys: K[],
  options?: {
    [key in K]: NumberFormattingOptions;
  },
): FormattedObject<K, T>[] {
  const formatters = getMultipleNumbersInObjectCollectionFormatter(
    collection,
    keys,
    options,
  );

  return collection.map((item) => ({
    ...item,
    ...Object.fromEntries(
      keys.map((key) => {
        const formatter = formatters[key];
        return [key, formatter(item[key])];
      }),
    ),
  }));
}

export function unique<T>(items: T[]): T[] {
  return Array.from(new Set(items));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): T {
  let timeout: NodeJS.Timeout;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: never, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  } as T;
}
