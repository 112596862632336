import { createIcon } from '@chakra-ui/react';

export const CalliperLogo = createIcon({
  displayName: 'CalliperLogo',
  viewBox: '0 0 120 120',
  path: (
    <>
      <path
        opacity='0.8'
        d='M63.3487 1.80342C61.8123 -0.584054 58.1977 -0.584049 56.6612 1.80342L20.6353 57.7832C20.2229 58.424 20.0377 59.1123 20.0418 59.7823C20.0377 60.4572 20.2229 61.1505 20.6353 61.7961L56.6612 118.187C58.1977 120.592 61.8123 120.592 63.3487 118.187L99.3746 61.7961C99.4394 61.6947 99.4986 61.5921 99.5524 61.4885C99.8407 60.9328 99.9717 60.3506 99.9682 59.7815C99.9721 59.1118 99.7869 58.4238 99.3746 57.7832L63.3487 1.80342Z'
        fill='#12B76A'
      />
      <path
        opacity='0.32'
        d='M63.3487 1.80342C61.8123 -0.584054 58.1977 -0.584049 56.6612 1.80342L20.6353 57.7832C20.2229 58.424 20.0377 59.1123 20.0418 59.7823C20.0377 60.4572 20.2229 61.1505 20.6353 61.7961L56.6612 118.187C58.1977 120.592 61.8123 120.592 63.3487 118.187L99.3746 61.7961C99.4394 61.6947 99.4986 61.5921 99.5524 61.4885C99.8407 60.9328 99.9717 60.3506 99.9682 59.7815C99.9721 59.1118 99.7869 58.4238 99.3746 57.7832L63.3487 1.80342Z'
        fill='#12B76A'
      />
      <path
        opacity='0.7'
        d='M106.76 11.7666C106.254 8.97265 102.914 7.58898 100.581 9.20655L45.882 47.1342C45.2557 47.5684 44.8214 48.1334 44.5688 48.7539C44.3069 49.3759 44.2127 50.0874 44.3468 50.8417L56.0574 116.731C56.5569 119.541 59.8963 120.924 62.2359 119.291L117.092 80.9835C117.191 80.9146 117.285 80.8424 117.374 80.7673C117.853 80.3643 118.197 79.8765 118.412 79.3494C118.671 78.7321 118.763 78.0257 118.628 77.276L106.76 11.7666Z'
        fill='#F04438'
      />
      <path
        opacity='0.32'
        d='M106.76 11.7666C106.254 8.97265 102.914 7.58898 100.581 9.20655L45.882 47.1342C45.2557 47.5684 44.8214 48.1334 44.5688 48.7539C44.3069 49.3759 44.2127 50.0874 44.3468 50.8417L56.0574 116.731C56.5569 119.541 59.8963 120.924 62.2359 119.291L117.092 80.9835C117.191 80.9146 117.285 80.8424 117.374 80.7673C117.853 80.3643 118.197 79.8765 118.412 79.3494C118.671 78.7321 118.763 78.0257 118.628 77.276L106.76 11.7666Z'
        fill='#F04438'
      />
      <path
        opacity='0.75'
        d='M19.4078 9.21619C17.075 7.59862 13.7355 8.9823 13.2294 11.7762L1.36151 77.2856C1.22565 78.0356 1.31793 78.7424 1.57794 79.3598C1.83241 79.9848 2.2687 80.5545 2.89672 80.9931L57.7532 119.3C60.0928 120.934 63.4322 119.55 63.9317 116.74L75.6424 50.8513C75.6634 50.7328 75.6789 50.6154 75.6889 50.4991C75.7427 49.8753 75.641 49.2873 75.42 48.7628C75.1674 48.1426 74.7331 47.5779 74.1072 47.1439L19.4078 9.21619Z'
        fill='#2E90FA'
      />
      <path
        opacity='0.32'
        d='M19.4078 9.21619C17.075 7.59862 13.7355 8.9823 13.2294 11.7762L1.36151 77.2856C1.22565 78.0356 1.31793 78.7424 1.57794 79.3598C1.83241 79.9848 2.2687 80.5545 2.89672 80.9931L57.7532 119.3C60.0928 120.934 63.4322 119.55 63.9317 116.74L75.6424 50.8513C75.6634 50.7328 75.6789 50.6154 75.6889 50.4991C75.7427 49.8753 75.641 49.2873 75.42 48.7628C75.1674 48.1426 74.7331 47.5779 74.1072 47.1439L19.4078 9.21619Z'
        fill='#2E90FA'
      />
    </>
  ),
});
