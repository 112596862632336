import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { getPageTitleKeySlug } from 'components/pages/Shared/SharingLink/getPageTitleKey';

export const useGetModalTitle = (blockName?: string) => {
  const { t } = useTranslation();
  const pageTitleKeySlug = getPageTitleKeySlug(blockName);

  return useMemo(
    () =>
      t('common:share_page_dialog_title', {
        pageTitle: pageTitleKeySlug
          ? t(pageTitleKeySlug)
          : t('common:share_page_dialog_title_unavailable_block_name'),
      }),
    [pageTitleKeySlug, t],
  );
};
