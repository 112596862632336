import { authorisedFetch } from 'api/rest/authorisedFetch';
import { UpdateGoalRequest } from 'data/goals/models/Goal';

import { GOALS_API_URL } from './goalsUrls';

export async function updateGoal(data: UpdateGoalRequest) {
  const result = await authorisedFetch(`${GOALS_API_URL}/${data.id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });

  if (result.status > 399) {
    throw new Error(`Failed to fetch review configs: ${result.status}`);
  }
}
