import { ReactNode } from 'react';

import {
  fixedWidthStyle,
  stickyColumnStyle,
} from 'components/uikit/grids/gridCommons';
import { Box, BoxProps } from 'components/uikit/reexport';

import { Flex } from '../reexport';

interface WideMiddleColGridProps {
  children: [ReactNode, ReactNode, ReactNode];
  stickyCols?: [boolean, boolean, boolean];
  middleColWidth?: BoxProps['width'];
}

export function WideMiddleColGrid({
  children: [col1, col2, col3],
  stickyCols: [stickyCol1, stickyCol2, stickyCol3] = [false, false, false],
  middleColWidth = '760px',
}: WideMiddleColGridProps) {
  const leftColSize = 204;
  const rightColSize = 306;
  const middleColSize = parseInt(middleColWidth.toString(), 10);
  const xPadding = 16;
  const gridSize = 2 * xPadding + leftColSize + middleColSize + rightColSize;

  return (
    <Flex
      dir='row'
      width='100%'
      // This one is important for the scroll bar to appear on the page
      // instead of on this container
      height='auto'
      gap='8'
      justifyContent='center'
      //
      // the following is necessary for the user-friendly
      // horizontal scroll when the screen is too small
      {...fixedWidthStyle(gridSize)}
      margin='auto'
      //
    >
      <Box {...fixedWidthStyle(leftColSize)} {...stickyColumnStyle(stickyCol1)}>
        {col1}
      </Box>
      <Box
        {...fixedWidthStyle(middleColSize)}
        {...stickyColumnStyle(stickyCol2)}
      >
        {col2}
      </Box>
      <Box
        {...fixedWidthStyle(rightColSize)}
        {...stickyColumnStyle(stickyCol3)}
      >
        {col3}
      </Box>
    </Flex>
  );
}
