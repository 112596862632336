import { FinanceRevenueCard } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/FinanceRevenue/FinanceRevenueCard';
import { ProductUsageCard } from 'components/CustomerDrawer/UserDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/ProductUsageCard';
import { useShowDepartmentBlocksForUser } from 'components/CustomerDrawer/UserDrawer/hooks/useShowDepartmentBlocksForUser';
import { TabPanel, VStack } from 'components/uikit';
import { hideScrollbar } from 'styles/hideScrollbar';

export function DrillDownTabPanel({ userId }: { userId: string }) {
  const { showAnyDepartmentBlocks, showProductBlocks, showFinanceBlocks } =
    useShowDepartmentBlocksForUser(userId);

  if (!showAnyDepartmentBlocks) {
    return null;
  }

  return (
    <TabPanel height={'100%'}>
      <VStack
        p='16px'
        background={'grey.offWhite'}
        alignItems='stretch'
        height={'100%'}
        overflowY={'scroll'}
        css={hideScrollbar}
      >
        {showFinanceBlocks ? <FinanceRevenueCard customerId={userId} /> : null}
        {showProductBlocks ? <ProductUsageCard customerId={userId} /> : null}
      </VStack>
    </TabPanel>
  );
}
