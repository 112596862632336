import { endOfDay, subMonths } from 'date-fns';
import { Atom, Getter } from 'jotai';

import {
  fetchSummaryCard,
  SummaryCard,
} from 'api/rest/charts/fetchSummaryCard';
import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { Filter } from 'data/charts/models/ChartsApiRequest';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';

import { FormattedDatePickerAtomValue } from './dateRangeAtom';

export type SummaryCardResult = {
  data: SummaryCard;
  aggPeriod: AggregationPeriod;
};

export type SummaryCardAtomConfig = {
  timeRangeAtom?: Atom<FormattedDatePickerAtomValue>;
  filters?: Filter[];
};

function getDefaultDateValues() {
  return {
    startOf1PeriodBefore: subMonths(new Date(), 1).toISOString(),
    start4thPeriodBeforeInclusive: subMonths(new Date(), 4).toISOString(),
    end: endOfDay(new Date()).toISOString(),
    granularity: 'MONTH' as const,
  };
}

export function getSummaryAtom({
  timeRangeAtom,
  filters = [],
}: SummaryCardAtomConfig = {}) {
  async function fetcher(
    blockId: string,
    get: Getter,
  ): Promise<SummaryCardResult> {
    const { start4thPeriodBeforeInclusive, end, granularity } = timeRangeAtom
      ? get(timeRangeAtom)
      : getDefaultDateValues();

    const result = await fetchSummaryCard(blockId, {
      filters,
      timeRange: {
        start: start4thPeriodBeforeInclusive,
        end: end,
        granularity: granularity,
      },
    });

    return {
      data: result.data,
      aggPeriod: granularity,
    };
  }

  const dataAtom = getDataAtom<SummaryCardResult>({
    data: {
      value: 0,
      delta: 0,
      pctDelta: 0,
      currency: null,
    },
    aggPeriod: 'MONTH',
  });

  const fetchingAtom = getDataRefreshingAtom<SummaryCardResult, string>(
    dataAtom,
    fetcher,
  );

  return {
    dataAtom,
    fetchingAtom,
  };
}
