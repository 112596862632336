import { ReactNode } from 'react';

import { MenuListItem, Text } from 'components/uikit';
import { useUserEvent } from 'utils/analytics';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';

type CustomerMenuListItemProps = {
  id: string;
  name: string;
  index: number;
  subTitle?: string;
  isSelected: boolean;
  onSelect: () => void;
  onClick: () => void;
  icon: ReactNode;
  event: CommandPanelAnalyticsEvents;
};

export function CommandBarMenuListItemBase({
  id,
  icon,
  name,
  index,
  subTitle,
  isSelected,
  onSelect,
  onClick,
  event,
}: CustomerMenuListItemProps) {
  const trackEvent = useUserEvent();
  const onConfirm = () => {
    trackEvent(event);
    onClick();
  };
  useKeyAction('CONFIRM', onConfirm, {
    enabled: isSelected,
    preventDefault: true,
    enableOnFormTags: true,
  });
  // TODO: fix temporary hack
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  useKeyAction(index <= 9 ? `SELECT_${index}` : 'CONFIRM', onConfirm, {
    enabled: index <= 9,
    preventDefault: true,
    enableOnFormTags: true,
  });
  return (
    <MenuListItem
      image={icon}
      key={id}
      title={name}
      subTitle={
        subTitle ? (
          <Text
            pl='3px'
            fontSize='12px'
            fontWeight='400'
            color='grey.tertiaryText'
          >{` / ${subTitle}`}</Text>
        ) : null
      }
      subTitleDirection='row'
      fontSize='14px'
      color='grey.secondaryText'
      fontWeight='500'
      height='48px'
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      action={index >= 9 ? null : `SELECT_${index}`}
      onClick={onConfirm}
      onSelect={onSelect}
      selected={isSelected}
      hotkeysActive
    />
  );
}
