import { useMemo } from 'react';

import { DatumValue, Point, SliceTooltipProps } from '@nivo/line';

import { TooltipContentDataEntry } from 'components/charts/common/TooltipContentDataEntry';
import { Box } from 'components/uikit';

import { Tooltip } from '../common/Tooltip';

export type TooltipDataFormatter = (data: DatumValue) => string | number;
export type TooltipTitleFormatter = (date: Date) => string;
export type TooltipLabelFormatter = (point: Point) => string | number;

export interface LineChartTooltipProps extends SliceTooltipProps {
  selectedSeriesIds?: string[];
  tooltipDataFormatter: TooltipDataFormatter;
  tooltipTitleFormatter: TooltipTitleFormatter;
  tooltipLabelFormatter?: TooltipLabelFormatter;
  alwaysShowSeriesColor: boolean;
}

const createLineOpacityCalculator =
  (selectedSeriesIds: string[]) => (point: Point) =>
    selectedSeriesIds &&
    selectedSeriesIds.length > 0 &&
    !selectedSeriesIds.includes(String(point.serieId))
      ? 0.2
      : 1;

export function LineChartTooltip({
  slice,
  selectedSeriesIds,
  tooltipTitleFormatter,
  tooltipDataFormatter,
  tooltipLabelFormatter = (point) => point.serieId,
  alwaysShowSeriesColor,
}: LineChartTooltipProps) {
  const points: Point[] = slice?.points ?? [];

  const firstPoint = points[0]?.data?.x;
  const calculateLineOpacity = useMemo(
    () => createLineOpacityCalculator(selectedSeriesIds ?? []),
    [selectedSeriesIds],
  );
  if (typeof firstPoint == 'undefined') return null;
  const formattedDate = tooltipTitleFormatter(new Date(firstPoint));

  const showColorBox = alwaysShowSeriesColor || points.length > 1;

  return (
    <Tooltip title={formattedDate}>
      <Box>
        {[...points].reverse().map((point) => (
          <TooltipContentDataEntry
            key={point.id}
            label={tooltipLabelFormatter(point)}
            value={tooltipDataFormatter(point.data.y.toString())}
            opacity={calculateLineOpacity(point)}
            boxColor={showColorBox ? point.serieColor : null}
          />
        ))}
      </Box>
    </Tooltip>
  );
}
