import { PropsWithChildren, ReactNode } from 'react';

import { Box, Text } from 'components/uikit';

type PropsSimpleTitle = {
  title: ReactNode;
  titleComponent?: undefined;
};

type PropsWithTitleComponent = {
  title?: undefined;
  titleComponent: ReactNode;
};

export type TooltipProps = PropsSimpleTitle | PropsWithTitleComponent;

export function Tooltip({
  title,
  titleComponent,
  children,
}: PropsWithChildren<TooltipProps>) {
  return (
    <Box
      bg='grey.white'
      minWidth='140px'
      borderRadius='4'
      boxShadow='2'
      borderWidth='1px'
      borderColor='grey.offWhite'
    >
      <Box
        w='100%'
        h='28px'
        bg='grey.offWhite'
        display='flex'
        alignItems='center'
        borderTopRadius='inherit'
        px='2'
      >
        {titleComponent ?? (
          <Text color='grey.secondaryText' fontSize='sm' fontWeight='600'>
            {title}
          </Text>
        )}
      </Box>

      <Box w='100%' p='2'>
        {children}
      </Box>
    </Box>
  );
}
