import { useAtom } from 'jotai';

import { CustomerCompany } from 'data/customer/CustomerCompany';
import { standaloneDrawerCustomerCompany } from 'data/healthReports/atoms/standaloneDrawerCustomerCompany';

export const useCompanyDrawerState = () => {
  const [data, setCompany] = useAtom(standaloneDrawerCustomerCompany);
  return {
    isOpen: !!data.company,
    onOpen: (company: CustomerCompany) => setCompany({ company }),
    onClose: () => setCompany({}),
    company: data.company,
  };
};
