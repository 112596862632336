import { useTranslation } from 'react-i18next';

import { UserSegmentIndicator } from 'components/CustomerDrawer/UserSegmentIndicator';
import { Delta } from 'components/Delta/Delta';
import { HStack, Text } from 'components/uikit';
import { UserSegmentsBreakdownRow } from 'data/charts/atoms/product/users/breakdowns';
import { TableConfig } from 'data/charts/models/TableConfig';
import { formatWholeNumber } from 'utils/formatting/number';

export function useUserSegmentsTableConfig() {
  const { t } = useTranslation('breakdowns');
  const config: TableConfig<UserSegmentsBreakdownRow> = [
    {
      accessor: 'segment',
      header: t('segment'),
      sortable: false,
      style: {
        fontWeight: '500',
        textColor: 'grey.primaryText',
        width: '195px',
      },
      render: ({ segment }) => (
        <HStack>
          <UserSegmentIndicator segment={segment} />
          <Text>{t(`segments.${segment}`)}</Text>
        </HStack>
      ),
    },
    {
      accessor: 'count',
      header: t('count'),
      sortable: false,
    },
    {
      accessor: 'delta',
      header: t('delta'),
      sortable: false,
      render: ({ delta }) => (
        <Delta
          value={Number(delta)}
          valueFormatter={formatWholeNumber}
          py={0}
          my={0}
          maxH='15px'
        />
      ),
    },
  ];

  return config;
}
