import { createIcon } from '@chakra-ui/react';

export const CloudSetupIcon = createIcon({
  displayName: 'CloudSetupIcon',
  viewBox: '0 0 138 108',
  path: (
    <>
      <circle cx='73.3362' cy='48.8145' r='48.012' fill='#EAECF0' />
      <ellipse
        cx='23.8234'
        cy='15.8063'
        rx='4.50113'
        ry='4.50113'
        fill='#EAECF0'
      />
      <circle cx='21.5728' cy='84.8235' r='6.0015' fill='#EAECF0' />
      <circle cx='128.849' cy='27.8093' r='6.0015' fill='#EAECF0' />
      <circle cx='120.598' cy='7.5542' r='3.75094' fill='#EAECF0' />
      <g filter='url(#filter0_dd_16484_176283)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M74.8358 12.8055C64.7338 12.8055 55.7977 17.7973 50.3596 25.4489C48.5839 25.0301 46.7319 24.8085 44.8283 24.8085C31.5701 24.8085 20.8223 35.5564 20.8223 48.8145C20.8223 62.0727 31.5701 72.8205 44.8283 72.8205H104.843C116.444 72.8205 125.849 63.4162 125.849 51.8153C125.849 40.2144 116.444 30.81 104.843 30.81C104.019 30.81 103.206 30.8575 102.407 30.9498C97.8087 20.2771 87.1948 12.8055 74.8358 12.8055Z'
          fill='#F8FAFC'
        />
        <ellipse
          cx='44.8283'
          cy='48.8145'
          rx='24.006'
          ry='24.006'
          fill='url(#paint0_linear_16484_176283)'
        />
        <ellipse
          cx='74.8356'
          cy='42.813'
          rx='30.0075'
          ry='30.0075'
          fill='url(#paint1_linear_16484_176283)'
        />
        <circle
          cx='104.843'
          cy='51.8153'
          r='21.0053'
          fill='url(#paint2_linear_16484_176283)'
        />
      </g>
      <g filter='url(#filter1_b_16484_176283)'>
        <rect
          x='55'
          y='64.8235'
          width='40'
          height='40'
          rx='20'
          fill='#175CD3'
          fillOpacity='0.8'
        />
        <path
          d='M84 77.8235C84 79.4804 79.9706 80.8235 75 80.8235C70.0294 80.8235 66 79.4804 66 77.8235M84 77.8235C84 76.1667 79.9706 74.8235 75 74.8235C70.0294 74.8235 66 76.1667 66 77.8235M84 77.8235V91.8235C84 93.4835 80 94.8235 75 94.8235C70 94.8235 66 93.4835 66 91.8235V77.8235M84 84.8235C84 86.4835 80 87.8235 75 87.8235C70 87.8235 66 86.4835 66 84.8235'
          stroke='#F8FAFC'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_16484_176283'
          x='0.822266'
          y='12.8055'
          width='145.026'
          height='100.015'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_16484_176283'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='4' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_16484_176283'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect2_dropShadow_16484_176283'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_16484_176283'
            result='effect2_dropShadow_16484_176283'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_16484_176283'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_16484_176283'
          x='47'
          y='56.8235'
          width='56'
          height='56'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='4' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_16484_176283'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_16484_176283'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_16484_176283'
          x1='26.3951'
          y1='32.9534'
          x2='68.8343'
          y2='72.8205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#697586' stopOpacity='0.75' />
          <stop offset='0.350715' stopColor={'grey.white'} stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_16484_176283'
          x1='51.7942'
          y1='22.9866'
          x2='104.843'
          y2='72.8205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#697586' stopOpacity='0.75' />
          <stop offset='0.350715' stopColor={'grey.white'} stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_16484_176283'
          x1='88.7141'
          y1='37.9368'
          x2='125.848'
          y2='72.8205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#697586' stopOpacity='0.75' />
          <stop offset='0.350715' stopColor={'grey.white'} stopOpacity='0' />
        </linearGradient>
      </defs>
    </>
  ),
});
