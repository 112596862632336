import { createIcon } from '@chakra-ui/react';

export const DataSourcesIcon = createIcon({
  displayName: 'DataSourcesIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      d='M29.3332 5.73325C29.3332 8.1633 23.3636 10.1333 15.9998 10.1333C8.63604 10.1333 2.6665 8.1633 2.6665
           5.73325M29.3332 5.73325C29.3332 3.3032 23.3636 1.33325 15.9998 1.33325C8.63604 1.33325 2.6665 3.3032 2.6665
           5.73325M29.3332 5.73325V26.2666C29.3332 28.7013 23.4072 30.6666 15.9998 30.6666C8.59243 30.6666 2.6665
           28.7013 2.6665 26.2666V5.73325M29.3332 15.9999C29.3332 18.4346 23.4072 20.3999 15.9998 20.3999C8.59243
           20.3999 2.6665 18.4346 2.6665 15.9999'
      stroke='currentColor'
      fill='none'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
