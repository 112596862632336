import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserNotesResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function fetchCustomerUserNotes(
  userId: string,
): Promise<CustomerUserNotesResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/${userId}/notes`,
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer company notes: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserNotesResponse, data);
}
