import { useTranslation } from 'react-i18next';

import { useSharingBlockContext } from 'components/Sharing/sharingBlockContextAtom';
import {
  SharingBlockModalBodyDefaultActionsContainer,
  SharingBlockModalBodyDefaultContainer,
  SharingBlockModalBodyDefaultContentContainer,
} from 'components/Sharing/SharingBlockModalBodyDefaultContainers';
import { useCopyToClipboard } from 'components/Sharing/SharingOptions/useCreateAndCopyPublicSharingLink';
import { Button, CopyIcon, Heading, Spinner, Text } from 'components/uikit';
import { AnalyticsEventType, trackEvent } from 'utils/analytics';

export function ShareWithAnyoneOptionBody() {
  const { t } = useTranslation('common', {
    keyPrefix: 'sharing_block_modal_share_with_anyone',
  });
  const { closeSharingModal, publicSharingLink, shareBlockConfig } =
    useSharingBlockContext();
  const copyToClipboard = useCopyToClipboard();
  const copyButtonDisabled = !publicSharingLink;

  const onCopyLinkClick = async () => {
    await copyToClipboard(publicSharingLink);
    trackEvent(AnalyticsEventType.PAGE_SHARE_LINK_COPIED, {
      sharingOption: 'SHARE_WITH_ANYONE',
      blockUniqueName: shareBlockConfig?.blockName,
    });
    closeSharingModal?.();
  };

  return (
    <SharingBlockModalBodyDefaultContainer>
      <SharingBlockModalBodyDefaultContentContainer>
        <Heading as='h1' fontSize='xl'>
          {t('body_title')}
        </Heading>
        <Text color='grey.secondaryText'>{t('body_paragraph_1')}</Text>
        <Text color='grey.secondaryText'>{t('body_paragraph_2')}</Text>
      </SharingBlockModalBodyDefaultContentContainer>
      <SharingBlockModalBodyDefaultActionsContainer>
        <Button
          variant='primary'
          height='40px'
          onClick={onCopyLinkClick}
          disabled={copyButtonDisabled}
        >
          {copyButtonDisabled ? (
            <Spinner size='sm' mr='9px' />
          ) : (
            <CopyIcon boxSize='16px' stroke='grey.white' mr='9px' />
          )}
          {t('copy_link_button_caption')}
        </Button>
      </SharingBlockModalBodyDefaultActionsContainer>
    </SharingBlockModalBodyDefaultContainer>
  );
}
