import { useEffect, useState } from 'react';

import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import { Card, Flex, VStack, Text, CardProps } from 'components/uikit';
import loader from 'components/uikit/animations/loader.json';

export function AiLoadingCard(props: CardProps) {
  const { t } = useTranslation('ai');
  const [loaderTextIndex, setLoaderTextIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoaderTextIndex((index) => {
        if (index >= 5) return index;
        return index + 1;
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [setLoaderTextIndex]);

  return (
    <Card boxShadow='none' px='16px' borderRadius='4px' py='36px' {...props}>
      <VStack w='100%' justify='center'>
        <Flex
          boxSize='120px'
          borderRadius='120px'
          border='1px solid'
          borderColor='grey.border'
          align='center'
          justify='center'
        >
          <Flex boxSize='84px'>
            <Lottie animationData={loader} loop />
          </Flex>
        </Flex>
        <Text fontWeight='medium'>{t(`loader_${loaderTextIndex}`)}</Text>
      </VStack>
    </Card>
  );
}
