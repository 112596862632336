import { useCallback } from 'react';

import { useRouter } from 'next/router';

const INITIAL_ROUTE_STORAGE_KEY = '@calliper/initialRoute';

export function removeInitialRoute() {
  return localStorage.removeItem(INITIAL_ROUTE_STORAGE_KEY);
}

export function getInitialRoute() {
  return localStorage.getItem(INITIAL_ROUTE_STORAGE_KEY);
}

/**
 * Redirects to the route you originally opened before the login
 */
export function useStoreInitialRoute() {
  const { asPath } = useRouter();

  return useCallback(() => {
    localStorage.setItem(INITIAL_ROUTE_STORAGE_KEY, asPath);
  }, [asPath]);
}
