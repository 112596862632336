import { createIcon } from '@chakra-ui/react';

export const BookIcon = createIcon({
  displayName: 'BookIcon',
  viewBox: '0 0 32 32',
  path: (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9998 9.33333C15.9998 7.91885 15.4379 6.56229 14.4377 5.5621C13.4375 4.5619 12.081 4 10.6665
           4H2.6665V24H11.9998C13.0607 24 14.0781 24.4214 14.8283 25.1716C15.5784 25.9217 15.9998 26.9391 15.9998
           28M15.9998 9.33333V28M15.9998 9.33333C15.9998 7.91885 16.5617 6.56229 17.5619 5.5621C18.5621 4.5619 19.9187
           4 21.3332 4H29.3332V24H19.9998C18.939 24 17.9216 24.4214 17.1714 25.1716C16.4213 25.9217 15.9998 26.9391
           15.9998 28'
        stroke='currentColor'
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
});
