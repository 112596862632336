import {
  CheckCircleIcon2,
  LockIcon,
  WarningCircleIcon,
} from 'components/uikit/icons';

import { InputStatus } from './InputStatus';

export const getInputIcon = (status?: InputStatus) => {
  switch (status) {
    case 'SUCCESS':
      return <CheckCircleIcon2 />;
    case 'ERROR':
      return <WarningCircleIcon />;
    case 'DISABLED':
      return <LockIcon stroke='grey.tertiaryText' />;
    default:
      return null;
  }
};
