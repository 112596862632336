import { ReactNode, useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';

import { sharingBlockContextAtom } from 'components/Sharing/sharingBlockContextAtom';
import { SharingBlockModal } from 'components/Sharing/SharingBlockModal';
import { ShareOptions } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import { useCreatePublicSharingLink } from 'components/Sharing/SharingOptions/useCreateAndCopyPublicSharingLink';
import {
  QueryParams,
  ShareBlockConfig,
} from 'data/sharingLink/atoms/globalShareBlock';

interface Props {
  blockId: string;
  sharingUrl?: string;
  sharingOptions: ShareOptions;
  children: (openShareDialog: () => void) => ReactNode;
  blockName?: string;
  params?: QueryParams;
}

export function WithSharingBlockModal({
  blockId,
  sharingUrl,
  sharingOptions,
  children,
  blockName,
  params,
}: Props) {
  const {
    isOpen: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
  } = useDisclosure();
  const createPublicSharingLink = useCreatePublicSharingLink();
  const [sharingBlockContext, setSharingBlockContext] = useAtom(
    sharingBlockContextAtom,
  );

  const openSharingDialog = useCallback(async () => {
    const shareBlockConfig: ShareBlockConfig = {
      blockId,
      blockName,
      params,
    };

    const activeSharingOptionId =
      sharingBlockContext.activeSharingOptionId ?? sharingOptions[0].id;

    setSharingBlockContext({
      sharingUrl,
      shareBlockConfig,
      activeSharingOptionId,
      closeSharingModal: closeDialog,
      sharingOptions,
    });

    openDialog();

    const publicSharingLink = await createPublicSharingLink(shareBlockConfig);

    setSharingBlockContext({
      sharingUrl,
      shareBlockConfig,
      activeSharingOptionId,
      closeSharingModal: closeDialog,
      publicSharingLink: publicSharingLink?.linkUrl,
      publicSharingLinkId: publicSharingLink?.linkId,
      sharingOptions,
    });
  }, [
    blockId,
    blockName,
    closeDialog,
    createPublicSharingLink,
    openDialog,
    params,
    setSharingBlockContext,
    sharingBlockContext.activeSharingOptionId,
    sharingOptions,
    sharingUrl,
  ]);

  return (
    <>
      <SharingBlockModal isOpen={isDialogOpen} onClose={closeDialog} />
      {children(openSharingDialog)}
    </>
  );
}
