import { createIcon } from '@chakra-ui/react';

export const RemoteRight = createIcon({
  displayName: 'RemoteRight',
  viewBox: '0 0 16 12',
  path: (
    <>
      <path
        d='M8.66732 10.3247L14.6673 5.658L8.66732 0.991333V10.3247Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.33398 10.3247L7.33398 5.658L1.33398 0.991333V10.3247Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
