import { createIcon } from '@chakra-ui/react';

export const FramedPictureIcon = createIcon({
  displayName: 'FramedPictureIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d='M2.5 5.2C2.5 4.0799 2.5 3.51984 2.71799 3.09202C2.90973 2.71569 3.21569 2.40973 3.59202 2.21799C4.01984 2 4.5799 2 5.7 2H11.3C12.4201 2 12.9802 2 13.408 2.21799C13.7843 2.40973 14.0903 2.71569 14.282 3.09202C14.5 3.51984 14.5 4.0799 14.5 5.2V10.8C14.5 11.9201 14.5 12.4802 14.282 12.908C14.0903 13.2843 13.7843 13.5903 13.408 13.782C12.9802 14 12.4201 14 11.3 14H5.7C4.5799 14 4.01984 14 3.59202 13.782C3.21569 13.5903 2.90973 13.2843 2.71799 12.908C2.5 12.4802 2.5 11.9201 2.5 10.8V5.2Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
        fill='none'
      />
      <path
        d='M6.5 6.33333L4.5 10H12.5L9.5 5L7.83333 7.33333L6.5 6.33333Z'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
        fill='none'
      />
    </>
  ),
});
