import { createIcon } from '@chakra-ui/react';

export const CalliperLogoWideWhite = createIcon({
  displayName: 'CalliperLogo',
  viewBox: '0 0 139 32',
  path: (
    <>
      <path
        d='M56.9258 13.8029H52.7889C52.7276 13.3019 52.5745 12.8164 52.3372 12.3708C52.1097 11.9624 51.8057 11.6016 51.4418 11.308C51.0651 11.0078 50.6377 10.7775 50.1798 10.6278C49.6803 10.4669 49.1582 10.3871 48.6334 10.3914C47.706 10.3697 46.7937 10.6291 46.0163 11.1353C45.2428 11.6686 44.6424 12.4168 44.2893 13.2875C43.8476 14.3703 43.6333 15.5325 43.6596 16.7017C43.6245 17.8912 43.8352 19.0752 44.2787 20.1796C44.6159 21.0413 45.2119 21.7773 45.9846 22.2864C46.7573 22.7955 47.6687 23.0527 48.5936 23.0226C49.1098 23.0269 49.6238 22.9544 50.1187 22.8074C50.5703 22.6706 50.994 22.4546 51.3701 22.1697C51.7358 21.8882 52.045 21.5403 52.2814 21.1441C52.5329 20.719 52.7048 20.2518 52.7889 19.7651L56.9258 19.7837C56.8171 20.6795 56.547 21.5482 56.1287 22.3477C55.6965 23.1795 55.1167 23.9258 54.4176 24.5503C53.6706 25.2155 52.8054 25.7346 51.867 26.0807C50.7971 26.4694 49.6653 26.6594 48.5272 26.6413C46.9085 26.6689 45.3117 26.2645 43.9014 25.4696C42.5265 24.6724 41.4111 23.4952 40.6891 22.0793C39.9062 20.6003 39.5139 18.8077 39.5121 16.7017C39.5103 14.5956 39.9071 12.8003 40.7024 11.316C41.4338 9.90196 42.5562 8.72792 43.9359 7.93368C45.3359 7.1445 46.9203 6.74108 48.5272 6.76462C49.5863 6.7559 50.6401 6.91554 51.6491 7.23756C52.5752 7.53608 53.4387 8.00164 54.1971 8.6112C54.945 9.21739 55.5638 9.96723 56.0171 10.8165C56.506 11.7456 56.8143 12.759 56.9258 13.8029Z'
        fill='white'
      />
      <path
        d='M63.9269 26.652C63.0764 26.6669 62.2324 26.5012 61.4507 26.1657C60.7513 25.8604 60.1558 25.3582 59.7369 24.7204C59.2971 24.0024 59.079 23.1705 59.1099 22.3291C59.0858 21.626 59.2383 20.9281 59.5536 20.2992C59.8371 19.7608 60.2532 19.3036 60.7625 18.9707C61.2983 18.6249 61.8871 18.3691 62.5055 18.2135C63.1794 18.0395 63.8662 17.9195 64.5593 17.8548C65.4042 17.7662 66.0844 17.683 66.5998 17.605C66.9957 17.5661 67.3792 17.4458 67.7264 17.2516C67.8395 17.1748 67.9309 17.0702 67.9918 16.9478C68.0528 16.8254 68.0812 16.6894 68.0745 16.5529V16.4944C68.0893 16.2362 68.0467 15.9779 67.9497 15.7381C67.8528 15.4982 67.7039 15.2829 67.5138 15.1075C67.0601 14.755 66.493 14.5811 65.9197 14.6186C65.3077 14.5857 64.7015 14.7526 64.1926 15.0942C63.7826 15.3855 63.483 15.8069 63.3424 16.2898L59.6227 15.9869C59.7941 15.1416 60.1771 14.3536 60.7359 13.6966C61.3203 13.0314 62.0581 12.5189 62.8854 12.2034C63.8618 11.8335 64.8997 11.6532 65.9436 11.672C66.7255 11.6687 67.5047 11.7634 68.2631 11.9537C68.9679 12.1278 69.6363 12.4256 70.2372 12.8331C70.8084 13.2243 71.2769 13.7475 71.6029 14.3582C71.9512 15.0476 72.1229 15.8127 72.1024 16.5847V26.373H68.279V24.359H68.1648C67.9316 24.8132 67.6142 25.2189 67.2295 25.5547C66.8152 25.9076 66.3372 26.1782 65.8214 26.3517C65.2111 26.557 64.5708 26.6585 63.9269 26.652ZM65.0907 23.8755C65.6342 23.8872 66.1724 23.7659 66.6583 23.5221C67.0915 23.3025 67.4584 22.9714 67.7211 22.5629C67.9808 22.1543 68.1157 21.6787 68.109 21.1946V19.6535C67.9485 19.7511 67.775 19.8254 67.5936 19.8741C67.3757 19.9405 67.1312 20.0016 66.8629 20.0547C66.5945 20.1079 66.3315 20.1557 66.0499 20.1956L65.3112 20.3045C64.8859 20.3615 64.4698 20.4732 64.0731 20.6366C63.7517 20.7656 63.469 20.9751 63.2521 21.2451C63.051 21.5205 62.9481 21.8554 62.9598 22.1963C62.9466 22.4385 62.9947 22.6802 63.0997 22.8989C63.2046 23.1177 63.363 23.3064 63.5603 23.4477C64.0118 23.7498 64.548 23.8996 65.0907 23.8755Z'
        fill='white'
      />
      <path d='M79.2523 7.02765V26.3783H75.227V7.02765H79.2523Z' fill='white' />
      <path
        d='M86.4898 7.02765V26.3783H82.4645V7.02765H86.4898Z'
        fill='white'
      />
      <path
        d='M91.732 9.99549C91.1612 10.0055 90.609 9.79194 90.1936 9.40033C89.9914 9.21916 89.8296 8.99739 89.7188 8.74949C89.6081 8.50158 89.5508 8.23311 89.5508 7.96159C89.5508 7.69007 89.6081 7.4216 89.7188 7.17369C89.8296 6.92579 89.9914 6.70402 90.1936 6.52285C90.6047 6.1288 91.1547 5.91299 91.724 5.92237C92.2967 5.91093 92.8509 6.12463 93.2677 6.51753C93.4706 6.69698 93.6326 6.91797 93.7427 7.16553C93.8527 7.41308 93.9082 7.6814 93.9054 7.95229C93.9076 8.22426 93.8519 8.49357 93.7419 8.74233C93.632 8.99109 93.4703 9.21359 93.2677 9.39502C92.855 9.78988 92.303 10.0057 91.732 9.99549ZM89.7021 26.3783V11.866H93.7274V26.3783H89.7021Z'
        fill='white'
      />
      <path
        d='M96.9396 31.8198V11.866H100.906V14.3104H101.087C101.289 13.879 101.548 13.4769 101.858 13.1148C102.224 12.6851 102.677 12.3374 103.186 12.0945C103.82 11.8008 104.513 11.658 105.211 11.6774C106.244 11.6708 107.257 11.9574 108.133 12.5037C109.074 13.1059 109.819 13.9691 110.278 14.9879C110.809 16.0932 111.075 17.4775 111.075 19.1408C111.075 20.758 110.814 22.1228 110.294 23.2351C109.843 24.264 109.105 25.1406 108.168 25.7593C107.285 26.3226 106.258 26.6196 105.211 26.6148C104.528 26.6341 103.849 26.5016 103.223 26.2269C102.719 25.9943 102.267 25.6616 101.895 25.2491C101.571 24.8928 101.302 24.4897 101.098 24.0535H100.96V31.8198H96.9396ZM100.877 19.1222C100.861 19.8906 100.984 20.6556 101.239 21.3806C101.439 21.9663 101.798 22.4845 102.277 22.8765C102.76 23.226 103.34 23.4143 103.935 23.4143C104.531 23.4143 105.111 23.226 105.593 22.8765C106.071 22.4781 106.429 21.9547 106.627 21.3646C106.876 20.6464 106.996 19.8901 106.983 19.1301C106.997 18.3784 106.878 17.63 106.632 16.9195C106.435 16.3356 106.079 15.8184 105.604 15.4263C105.119 15.0787 104.538 14.8903 103.941 14.8869C103.344 14.8836 102.761 15.0656 102.272 15.4077C101.796 15.7912 101.438 16.3014 101.239 16.8797C100.984 17.5993 100.862 18.359 100.877 19.1222Z'
        fill='white'
      />
      <path
        d='M120.379 26.6626C119.038 26.6991 117.711 26.384 116.529 25.7486C115.465 25.1513 114.602 24.2504 114.051 23.1607C113.441 21.9227 113.142 20.5548 113.179 19.1753C113.149 17.8135 113.448 16.4646 114.051 15.243C114.599 14.1487 115.449 13.2341 116.5 12.6073C117.63 11.9584 118.917 11.6321 120.22 11.6641C121.133 11.6561 122.04 11.8109 122.898 12.1211C123.714 12.4241 124.455 12.8989 125.072 13.5133C125.717 14.1657 126.212 14.9509 126.522 15.8142C126.891 16.8526 127.071 17.9486 127.054 19.0504V20.1557H114.776V17.6582H123.252C123.263 17.1203 123.133 16.5889 122.874 16.1171C122.63 15.674 122.269 15.3064 121.83 15.0543C121.359 14.7923 120.826 14.6603 120.286 14.6717C119.718 14.6604 119.157 14.8066 118.666 15.0942C118.205 15.3641 117.824 15.752 117.563 16.2181C117.297 16.6958 117.158 17.2337 117.159 17.7804V20.1557C117.142 20.7989 117.28 21.4367 117.56 22.0156C117.811 22.5229 118.207 22.9445 118.698 23.2272C119.233 23.5208 119.836 23.6675 120.446 23.6523C120.863 23.6565 121.277 23.5928 121.673 23.4636C122.03 23.3459 122.356 23.1518 122.63 22.895C122.898 22.636 123.104 22.32 123.233 21.9704L126.953 22.2149C126.778 23.0817 126.38 23.8877 125.797 24.553C125.18 25.243 124.408 25.7758 123.544 26.1073C122.534 26.4936 121.46 26.6821 120.379 26.6626Z'
        fill='white'
      />
      <path
        d='M129.668 26.3783V11.866H133.571V14.3981H133.72C133.924 13.5878 134.391 12.8683 135.048 12.3522C135.672 11.8875 136.431 11.6418 137.209 11.6534C137.426 11.6545 137.644 11.6678 137.86 11.6933C138.066 11.7126 138.272 11.7472 138.473 11.7969V15.3732C138.212 15.3031 137.945 15.2524 137.676 15.2217C137.372 15.1805 137.066 15.1584 136.76 15.1553C136.204 15.1438 135.655 15.2756 135.165 15.5379C134.711 15.7866 134.333 16.1539 134.071 16.6007C133.797 17.078 133.658 17.6208 133.67 18.171V26.381L129.668 26.3783Z'
        fill='white'
      />
      <g clipPath='url(#clip0_10901_79200)'>
        <path
          opacity='0.8'
          d='M15.9619 0.454411C15.5748 -0.147158 14.664 -0.147157 14.2769 0.454411L5.1995 14.5595C5.09558 14.721 5.04893 14.8945 5.04994 15.0633C5.04893 15.2333 5.09558 15.408 5.1995 15.5707L14.2769 29.7793C14.664 30.3853 15.5748 30.3853 15.9619 29.7793L25.0393 15.5707C25.0557 15.5451 25.0706 15.5193 25.0841 15.4932C25.1568 15.3532 25.1898 15.2064 25.1889 15.0631C25.1899 14.8943 25.1432 14.721 25.0393 14.5595L15.9619 0.454411Z'
          fill='#12B76A'
        />
        <path
          opacity='0.32'
          d='M15.9619 0.454411C15.5748 -0.147158 14.664 -0.147157 14.2769 0.454411L5.1995 14.5595C5.09558 14.721 5.04893 14.8945 5.04994 15.0633C5.04893 15.2333 5.09558 15.408 5.1995 15.5707L14.2769 29.7793C14.664 30.3853 15.5748 30.3853 15.9619 29.7793L25.0393 15.5707C25.0557 15.5451 25.0706 15.5193 25.0841 15.4932C25.1568 15.3532 25.1898 15.2064 25.1889 15.0631C25.1899 14.8943 25.1432 14.721 25.0393 14.5595L15.9619 0.454411Z'
          fill='#12B76A'
        />
        <path
          opacity='0.7'
          d='M26.9002 2.9648C26.7726 2.26083 25.9312 1.91219 25.3434 2.31976L11.5609 11.8763C11.4031 11.9857 11.2936 12.1281 11.23 12.2845C11.164 12.4412 11.1403 12.6204 11.174 12.8105L14.1248 29.4125C14.2506 30.1205 15.092 30.4692 15.6815 30.0575L29.5037 20.4053C29.5285 20.3879 29.5522 20.3697 29.5747 20.3508C29.6954 20.2493 29.782 20.1264 29.836 19.9936C29.9015 19.838 29.9247 19.66 29.8905 19.4711L26.9002 2.9648Z'
          fill='#F04438'
        />
        <path
          opacity='0.32'
          d='M26.9002 2.9648C26.7726 2.26083 25.9312 1.91219 25.3434 2.31976L11.5609 11.8763C11.4031 11.9857 11.2936 12.1281 11.23 12.2845C11.164 12.4412 11.1403 12.6204 11.174 12.8105L14.1248 29.4125C14.2506 30.1205 15.092 30.4692 15.6815 30.0575L29.5037 20.4053C29.5285 20.3879 29.5522 20.3697 29.5747 20.3508C29.6954 20.2493 29.782 20.1264 29.836 19.9936C29.9015 19.838 29.9247 19.66 29.8905 19.4711L26.9002 2.9648Z'
          fill='#F04438'
        />
        <path
          opacity='0.75'
          d='M4.89021 2.3222C4.3024 1.91463 3.46097 2.26327 3.33344 2.96725L0.34311 19.4736C0.308877 19.6625 0.332128 19.8406 0.397642 19.9962C0.461761 20.1537 0.571693 20.2972 0.729935 20.4077L14.5521 30.0599C15.1416 30.4716 15.983 30.123 16.1088 29.4149L19.0595 12.8129C19.0649 12.7831 19.0687 12.7535 19.0713 12.7242C19.0848 12.567 19.0592 12.4188 19.0035 12.2867C18.9399 12.1304 18.8305 11.9881 18.6727 11.8788L4.89021 2.3222Z'
          fill='#2E90FA'
        />
        <path
          opacity='0.32'
          d='M4.89021 2.3222C4.3024 1.91463 3.46097 2.26327 3.33344 2.96725L0.34311 19.4736C0.308877 19.6625 0.332128 19.8406 0.397642 19.9962C0.461761 20.1537 0.571693 20.2972 0.729935 20.4077L14.5521 30.0599C15.1416 30.4716 15.983 30.123 16.1088 29.4149L19.0595 12.8129C19.0649 12.7831 19.0687 12.7535 19.0713 12.7242C19.0848 12.567 19.0592 12.4188 19.0035 12.2867C18.9399 12.1304 18.8305 11.9881 18.6727 11.8788L4.89021 2.3222Z'
          fill='#2E90FA'
        />
      </g>
      <defs>
        <clipPath id='clip0_10901_79200'>
          <rect width='30.2362' height='30.2362' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
});
