import { createIcon } from '@chakra-ui/react';

export const NotionLogo = createIcon({
  displayName: 'NotionLogo',
  viewBox: '2 2 17 17',
  path: (
    <>
      <g clipRule='evenodd' fillRule='evenodd'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.17015 19.1L2.02055 16.4221C1.50336 15.7778 1.22266 14.9855 1.22266 14.17V4.47323C1.22266 3.25177 2.1999 2.23822 3.4606 2.15215L13.4302 1.47148C14.1545 1.42203 14.8744 1.61455 15.4694 2.01681L18.9722 4.38506C19.5992 4.80899 19.9727 5.50198 19.9727 6.24153V17.2658C19.9727 18.4612 19.0118 19.4509 17.7774 19.5269L6.71224 20.208C5.72724 20.2687 4.77515 19.8537 4.17015 19.1Z'
          fill='white'
        />
        <path
          d='M7.62625 9.3255V9.19855C7.62625 8.87674 7.88398 8.60985 8.21616 8.58767L10.6358 8.42613L13.9818 13.3535V9.0289L13.1206 8.91409V8.85379C13.1206 8.5282 13.3842 8.25954 13.7206 8.24231L15.9222 8.12953V8.44635C15.9222 8.59508 15.8116 8.7223 15.66 8.74794L15.1302 8.83754V15.8411L14.4653 16.0698C13.9098 16.2608 13.2914 16.0563 12.9734 15.5765L9.72498 10.6745V15.3532L10.7248 15.5445L10.7109 15.6373C10.6673 15.9278 10.4165 16.1475 10.1131 16.161L7.62625 16.2717C7.59338 15.9593 7.82751 15.6798 8.1505 15.6459L8.47762 15.6115V9.37334L7.62625 9.3255Z'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.518 2.69305L3.54836 3.37371C2.95118 3.41448 2.48828 3.89459 2.48828 4.47317V14.17C2.48828 14.7136 2.67542 15.2418 3.02021 15.6713L5.16981 18.3492C5.51736 18.7822 6.06431 19.0206 6.63016 18.9858L17.6953 18.3047C18.2625 18.2697 18.704 17.815 18.704 17.2658V6.24147C18.704 5.90168 18.5324 5.58327 18.2443 5.3885L14.7415 3.02024C14.3845 2.77889 13.9526 2.66337 13.518 2.69305ZM4.04273 4.62443C3.90407 4.52177 3.97006 4.3079 4.14426 4.2954L13.5851 3.61825C13.8859 3.59667 14.1848 3.67951 14.428 3.85186L16.3222 5.19389C16.3942 5.24484 16.3604 5.35483 16.2714 5.35967L6.27361 5.90341C5.97105 5.91986 5.6724 5.83098 5.43183 5.65287L4.04273 4.62443ZM5.80468 7.608C5.80468 7.2832 6.06707 7.01491 6.40261 6.99664L16.9731 6.42096C17.3001 6.40315 17.5754 6.65464 17.5754 6.97119V16.5171C17.5754 16.8413 17.3139 17.1093 16.9791 17.1284L6.47549 17.726C6.11156 17.7467 5.80468 17.4671 5.80468 17.1147V7.608Z'
          fill='black'
        />
      </g>
    </>
  ),
});
