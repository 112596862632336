import { useEffect } from 'react';

import { useAtom } from 'jotai';

import { useCustomerActiveUsersBlocks } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/DrillDownTabPanel/ProductUsage/useCustomerActiveUsersBlocks';
import { useCustomerCompanyAtoms } from 'data/healthReports/atoms/customerCompanyAtoms';
import { isInitial } from 'utils/types';

export function useActiveUsersData(customerId: string) {
  const { monthSummaryBlock, weekSummaryBlock, chartBlock } =
    useCustomerActiveUsersBlocks();
  const {
    activeUsersChartAtoms,
    thisWeekActiveUsersSummaryAtoms,
    thisMonthActiveUsersSummaryAtoms,
  } = useCustomerCompanyAtoms(customerId);

  const [
    {
      fetchStatus: chartFetchStatus,
      aggPeriod: chartAggPeriod,
      data: chartData,
    },
    fetchChart,
  ] = useAtom(activeUsersChartAtoms.fetchingAtom);
  const [
    {
      fetchStatus: thisWeekSummaryFetchStatus,
      data: thisWeekSummaryData,
      aggPeriod: thisWeekSummaryAggPeriod,
    },
    fetchThisWeekSummary,
  ] = useAtom(thisWeekActiveUsersSummaryAtoms.fetchingAtom);
  const [
    {
      fetchStatus: thisMonthSummaryFetchStatus,
      data: thisMonthSummaryData,
      aggPeriod: thisMonthSummaryAggPeriod,
    },
    fetchThisMonthSummary,
  ] = useAtom(thisMonthActiveUsersSummaryAtoms.fetchingAtom);

  useEffect(() => {
    if (isInitial(chartFetchStatus) && chartBlock) {
      fetchChart(chartBlock.id);
    }
    if (isInitial(thisWeekSummaryFetchStatus) && weekSummaryBlock) {
      fetchThisWeekSummary(weekSummaryBlock.id);
    }
    if (isInitial(thisMonthSummaryFetchStatus) && monthSummaryBlock) {
      fetchThisMonthSummary(monthSummaryBlock.id);
    }
  }, [
    chartBlock,
    chartFetchStatus,
    fetchChart,
    fetchThisMonthSummary,
    fetchThisWeekSummary,
    monthSummaryBlock,
    thisMonthSummaryFetchStatus,
    thisWeekSummaryFetchStatus,
    weekSummaryBlock,
  ]);

  return {
    chart: {
      data: chartData,
      aggPeriod: chartAggPeriod,
      fetchStatus: chartFetchStatus,
    },
    thisWeekSummary: {
      data: thisWeekSummaryData,
      aggPeriod: thisWeekSummaryAggPeriod,
      fetchStatus: thisWeekSummaryFetchStatus,
    },
    thisMonthSummary: {
      data: thisMonthSummaryData,
      aggPeriod: thisMonthSummaryAggPeriod,
      fetchStatus: thisMonthSummaryFetchStatus,
    },
  };
}
