import { z } from 'zod';

import { PaginationPageSize } from 'data/charts/models/ChartsApiRequest';

export const Category = z.object({
  name: z.string(),
  type: z.enum(['string', 'number', 'date', 'boolean']),
});
export type Category = z.infer<typeof Category>;
export const CategoryValue = z.object({
  name: z.string(),
  value: z.union([z.string(), z.number(), z.boolean(), z.date()]),
});
export type CategoryValue = z.infer<typeof CategoryValue>;

export const CategoryValues = z.object({
  blockId: z.string(),
  category: z.string(),
  values: z.array(CategoryValue),
  page: z.number(),
  pageSize: PaginationPageSize,
  totalPages: z.number(),
});
export type CategoryValues = z.infer<typeof CategoryValues>;

export const CustomerProducts = z.object({
  products: z.string().array(),
});

export type CustomerProducts = z.infer<typeof CustomerProducts>;
