/*
 * The formatters implemented in this file should be an implementation of the specification in
 * https://www.notion.so/calliper/Number-formatting-63b7fc3268e64a4cb944d56419687332
 */

import { z } from 'zod';

import { isSingleItem } from 'utils/helpers';

export enum Currency {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  CAD = 'CAD',
  AUD = 'AUD',
  JPY = 'JPY',
  CNY = 'CNY',
  CHF = 'CHF',
  INR = 'INR',
  DKK = 'DKK',
  SEK = 'SEK',
  NOK = 'NOK',
  NZD = 'NZD',
  BRL = 'BRL',
  BGN = 'BGN',
  MYR = 'MYR',
  THB = 'THB',
  AED = 'AED',
  SGD = 'SGD',
  KRW = 'KRW',
  ZAR = 'ZAR',
  PEN = 'PEN',
  ARS = 'ARS',
  SAR = 'SAR',
  PHP = 'PHP',
  TRY = 'TRY',
  CLP = 'CLP',
  IDR = 'IDR',
  HUF = 'HUF',
  MXN = 'MXN',
  CZK = 'CZK',
  HKD = 'HKD',
  PLN = 'PLN',
  ILS = 'ILS',
  RON = 'RON',
  RUB = 'RUB',
  TWD = 'TWD',
}

export const CurrencyParser = z.nativeEnum(Currency);

export const CURRENCY_SYMBOL = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CAD: 'CA$',
  AUD: 'A$',
  JPY: '¥',
  CNY: '¥',
  CHF: 'CHF',
  INR: '₹',
  DKK: 'kr',
  SEK: 'kr',
  NOK: 'kr',
  NZD: 'NZ$',
  BRL: 'R$',
  BGN: 'лв',
  MYR: 'RM',
  THB: '฿',
  AED: 'AED',
  SGD: 'SG$',
  KRW: '₩',
  ZAR: 'R',
  PEN: 'S/.',
  ARS: 'AR$',
  SAR: '﷼',
  PHP: '₱',
  TRY: '₺',
  CLP: 'CL$',
  IDR: 'Rp',
  HUF: 'Ft',
  MXN: 'MX$',
  CZK: 'Kč',
  HKD: 'HK$',
  PLN: 'zł',
  ILS: '₪',
  RON: 'RON',
  RUB: '₽',
  TWD: 'NT$',
} as const;

export type FormattingOptionsBase = {
  signDisplay: 'auto' | 'never' | 'always' | 'exceptZero';
};

type PercentagePointOptions = {
  style: 'percentagePoint';
} & Partial<FormattingOptionsBase>;

type PercentageOptions = {
  style: 'percent';
  isInteger?: boolean;
} & Partial<FormattingOptionsBase>;

type CurrencyOptions = {
  style: 'currency';
  currency: Currency;
} & Partial<FormattingOptionsBase>;

type IntegerOptions = {
  style: 'integer';
} & Partial<FormattingOptionsBase>;

type OrderOfMagnitude = 0 | 1 | 2 | 3;
type FractionalDigits = 0 | 1 | 2;
type SmallNumberThreshold = 0.01 | 0.1;

export type NumberFormattingOptions =
  | PercentagePointOptions
  | PercentageOptions
  | CurrencyOptions
  | IntegerOptions;

type CalculatedFormattingOptions = {
  magnitude: OrderOfMagnitude;
  fractionalDigits: FractionalDigits;
  smallNumberThreshold: SmallNumberThreshold;
};

export type NumberFormatter = (value: number) => string;
export type NumberGroupFormatter<T extends number[] = number[]> = (
  values: T,
) => StrTuple<T>;

export type CurrencyFormatter = (value: number, currency: Currency) => string;

export type FormattedObject<
  K extends string,
  T extends { [key in K]: number },
> = Omit<T, K> & { [key in K]: string };

type StrTuple<T> = T extends [number, ...infer U]
  ? [string, ...StrTuple<U>]
  : [];

const MAGNITUDE_SYMBOLS = ['', 'K', 'M', 'B'] as const;

function isInfinite(value: number) {
  return !Number.isFinite(value);
}

function isPercentageStyle(
  formattingOptions: NumberFormattingOptions | undefined,
): formattingOptions is PercentageOptions | PercentagePointOptions {
  return (
    formattingOptions?.style === 'percent' ||
    formattingOptions?.style === 'percentagePoint'
  );
}

function shouldFormatIntegerNumber(
  formattingOptions: NumberFormattingOptions | undefined,
) {
  return (
    formattingOptions?.style === 'integer' ||
    (formattingOptions?.style === 'percent' && formattingOptions.isInteger)
  );
}

function buildLocaleOptions(
  formattingOptions: NumberFormattingOptions | undefined,
) {
  const baseOptions: FormattingOptionsBase = {
    signDisplay: formattingOptions?.signDisplay ?? 'auto',
  };

  if (!formattingOptions) return baseOptions;

  if (isPercentageStyle(formattingOptions)) {
    return { ...baseOptions, style: 'percent' };
  }
  if (formattingOptions.style === 'currency') {
    return {
      ...baseOptions,
      style: 'currency',
      currency: formattingOptions.currency,
    };
  }
  if (formattingOptions.style === 'integer') {
    return baseOptions;
  }
}

function getOrderOfMagnitude(value: number): OrderOfMagnitude {
  const absoluteValue = Math.abs(value);
  if (absoluteValue < 1000) return 0;
  if (absoluteValue < 1000000) return 1;
  if (absoluteValue < 1000000000) return 2;
  return 3;
}

function scaleToMagnitude(
  value: number,
  orderOfMagnitude: OrderOfMagnitude,
): number {
  return value / Math.pow(1000, orderOfMagnitude);
}

function getFractionalDigits(
  value: number,
  isInteger = false,
): FractionalDigits {
  if (Number.isNaN(value) || isInfinite(value) || value === 0) return 0;

  const absoluteValue = Math.abs(value);
  if (absoluteValue < 1) return isInteger ? 0 : 2;
  if (absoluteValue < 10) return isInteger ? 0 : 1;
  if (absoluteValue < 1000) return 0;
  if (absoluteValue < 10000)
    return getFractionalDigits(Math.round(absoluteValue / 100) / 10, false);
  return getFractionalDigits(absoluteValue / 1000, false);
}

function formatNumberWithMagnitude(
  value: number,
  {
    magnitude,
    fractionalDigits,
    smallNumberThreshold,
  }: CalculatedFormattingOptions,
) {
  if (isInfinite(value)) return '∞';

  const magnitudeSymbol = MAGNITUDE_SYMBOLS[magnitude];
  const scaledValue = scaleToMagnitude(value, magnitude);

  const isSmallNumber = scaledValue !== 0 && scaledValue < smallNumberThreshold;
  const numberToFormat = isSmallNumber ? smallNumberThreshold : scaledValue;

  const formattedNumber = numberToFormat.toLocaleString(undefined, {
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  });

  return `${isSmallNumber ? '<' : ''}${formattedNumber}${magnitudeSymbol}`;
}

export function formatWholeNumber(
  value: number,
  currency: Currency | null = null,
): string {
  const options = currency
    ? {
        style: 'currency',
        currency,
      }
    : undefined;
  const roundedValue = Math.round(value);
  const formattedNumber = roundedValue.toLocaleString(undefined, {
    ...options,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: roundedValue === 0 ? 'never' : 'auto',
  });

  if (currency) {
    return replaceCurrencySymbol(formattedNumber, currency);
  }
  return formattedNumber;
}

export function getWholeNumberFormatter(currency: Currency | null = null) {
  return (value: number) => formatWholeNumber(value, currency);
}

function getPlaceholderValue(
  scaledValue: number,
  smallNumberThreshold: SmallNumberThreshold,
  formattingOptions: NumberFormattingOptions | undefined,
) {
  // We use a placeholder value to take advantage of locale string formatting.
  const isPercent = isPercentageStyle(formattingOptions);
  const isPositive = scaledValue >= 0 && !Object.is(scaledValue, -0);

  if (Math.abs(scaledValue) < smallNumberThreshold) {
    return isPositive || formattingOptions?.signDisplay !== 'always' ? +0 : -0;
  }

  if (scaledValue > 0) {
    return isPercent ? 0.01 : 1;
  }

  if (scaledValue < 0) {
    return isPercent ? -0.01 : -1;
  }

  return 0;
}

function replaceCurrencySymbol(placeholder: string, currency: Currency) {
  if (currency in CURRENCY_SYMBOL) {
    const systemCurrencySymbol = (0)
      .toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      })
      .replace(/0/, '')
      .trim();

    return placeholder.replace(systemCurrencySymbol, CURRENCY_SYMBOL[currency]);
  }
  return placeholder;
}

function getFormattedPlaceholder(
  scaledValue: number,
  formattingOptions: NumberFormattingOptions | undefined,
  calculatedFormattingOptions: CalculatedFormattingOptions,
) {
  const placeholderValue = getPlaceholderValue(
    scaledValue,
    calculatedFormattingOptions.smallNumberThreshold,
    formattingOptions,
  );

  const formattedPlaceholder = placeholderValue.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...buildLocaleOptions(formattingOptions),
  });

  switch (formattingOptions?.style) {
    case 'percentagePoint':
      return formattedPlaceholder.replace('%', '%pt');
    case 'currency':
      return replaceCurrencySymbol(
        formattedPlaceholder,
        formattingOptions.currency,
      );
    default:
      return formattedPlaceholder;
  }
}

function getPlaceholderRegExp(
  scaledValue: number,
  smallNumberThreshold: number,
) {
  const regExpValue = Math.abs(scaledValue) < smallNumberThreshold ? 0 : 1;
  return RegExp(
    regExpValue.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
  );
}

function formatNumber(
  value: number,
  formattingOptions: NumberFormattingOptions | undefined,
  calculatedFormattingOptions: CalculatedFormattingOptions,
): string {
  if (isNaN(value)) {
    return '-';
  }

  const scaledValue = scaleToMagnitude(
    value,
    calculatedFormattingOptions.magnitude,
  );

  // Force 0 values to have 0 fractional digits and no magnitude, regardless of previous configuration
  const forcedFormattingOptions: Partial<CalculatedFormattingOptions> =
    value === 0 ? { fractionalDigits: 0, magnitude: 0 } : {};

  // Format number according to our rules of magnitude and fractional digits
  const formattedNumber = formatNumberWithMagnitude(Math.abs(value), {
    ...calculatedFormattingOptions,
    ...forcedFormattingOptions,
  });

  // We get a formatted string with a placeholder value,
  // this is to take advantage of locale string formatting (e.g. it places the currency symbol in the right place)
  const formattedPlaceholder = getFormattedPlaceholder(
    scaledValue,
    formattingOptions,
    calculatedFormattingOptions,
  );

  // We then get a placeholder RegExp to match the used placeholder value
  // and replace it with the formatted number
  const placeholderRegExp = getPlaceholderRegExp(
    scaledValue,
    calculatedFormattingOptions.smallNumberThreshold,
  );

  return formattedPlaceholder.replace(placeholderRegExp, formattedNumber);
}

export function getCompactNumberFormatter(
  formattingOptions?: NumberFormattingOptions,
): NumberFormatter {
  return (value) => formatCompactNumber(value, formattingOptions);
}

export function formatCompactNumber(
  value: number,
  formattingOptions?: NumberFormattingOptions,
): string {
  return formatNumber(value, formattingOptions, {
    magnitude: getOrderOfMagnitude(value),
    fractionalDigits: getFractionalDigits(
      value,
      shouldFormatIntegerNumber(formattingOptions),
    ),
    smallNumberThreshold: 0.01,
  });
}

/*
 * Calculates the number formatting options of a group of numbers formatted together.
 * - If different magnitudes, they all are scaled to the bigger one.
 * - If the values have different number of fractional digits, force them all to 1.
 */
function calculateGroupFormattingOptions(
  values: number[],
  formattingOptions?: NumberFormattingOptions,
) {
  const safeValues = values.filter((value) => !isNaN(value));
  const maxValue = Math.max(...safeValues.map(Math.abs));
  const orderOfMagnitude = getOrderOfMagnitude(maxValue);
  const formatIntegerNumber =
    orderOfMagnitude === 0 && shouldFormatIntegerNumber(formattingOptions);

  const getDigits = (value: number) =>
    getFractionalDigits(value, formatIntegerNumber);

  const fractionalDigits = Array.from(
    new Set(
      safeValues
        .map((value) => scaleToMagnitude(value, orderOfMagnitude))
        .map(getDigits),
    ),
  );

  return {
    magnitude: orderOfMagnitude,
    fractionalDigits: isSingleItem(fractionalDigits) ? fractionalDigits[0] : 1,
    smallNumberThreshold: 0.1,
  } as const;
}

export function getNumberGroupFormatter(
  values: number[],
  formattingOptions?: NumberFormattingOptions,
): NumberFormatter {
  const calculatedFormattingOptions = calculateGroupFormattingOptions(
    values,
    formattingOptions,
  );

  return (value) =>
    formatNumber(value, formattingOptions, calculatedFormattingOptions);
}

export function getGroupCurrencyFormatter(values: number[]): CurrencyFormatter {
  const calculatedOptions = calculateGroupFormattingOptions(values);

  return (value: number, currency: Currency) =>
    formatNumber(
      value,
      {
        style: 'currency',
        currency,
      },
      calculatedOptions,
    );
}

export function formatNumberGroup<T extends number[]>(
  values: [...T],
  formattingOptions?: NumberFormattingOptions,
): StrTuple<T> {
  return values.map(
    getNumberGroupFormatter(values, formattingOptions),
  ) as StrTuple<T>;
}
