import { BaseQuartileDataBlock } from 'data/healthReports/models/BaseQuartileDataBlock';

export const QuartileRevenueDataBlock = BaseQuartileDataBlock.extend({});

export type QuartileRevenueDataBlock = typeof QuartileRevenueDataBlock;

export const QuartileActiveDaysDataBlock = BaseQuartileDataBlock.extend({});

export type QuartileActiveDaysDataBlock = typeof QuartileActiveDaysDataBlock;

export const QuartileProductEngagementDataBlock = BaseQuartileDataBlock.extend(
  {},
);

export type QuartileProductEngagementDataBlock =
  typeof QuartileProductEngagementDataBlock;

export const QuartileCustomerLifespanDataBlock = BaseQuartileDataBlock.extend(
  {},
);

export type QuartileCustomerLifespanDataBlock =
  typeof QuartileCustomerLifespanDataBlock;
