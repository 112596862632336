import { createIcon } from '@chakra-ui/react';

export const CrunchbaseIcon = createIcon({
  displayName: 'CrunchbaseIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d='M14.4 0H1.6C1.17619 0.00175491 0.770247 0.170889 0.470568
         0.470568C0.170889 0.770247 0.00175491 1.17619 0 1.6L0
         14.4C0.00175491 14.8238 0.170889 15.2298 0.470568 15.5294C0.770247
         15.8291 1.17619 15.9982 1.6 16H14.4C14.8238 15.9982 15.2298 15.8291
         15.5294 15.5294C15.8291 15.2298 15.9982 14.8238 16 14.4V1.6C15.9982
         1.17619 15.8291 0.770247 15.5294 0.470568C15.2298 0.170889 14.8238
         0.00175491 14.4 0ZM4.69667 9.64333C5.03614 9.79804 5.42317 9.81155
         5.77262 9.6809C6.12206 9.55025 6.40529 9.28614 6.56
         8.94667H7.66667C7.52402 9.53107 7.17218 10.0429 6.67769
         10.3855C6.18319 10.7281 5.58032 10.8776 4.98306 10.8057C4.38581
         10.7339 3.83557 10.4457 3.43641 9.99565C3.03725 9.5456 2.81683
         8.96489 2.81683 8.36333C2.81683 7.76177 3.03725 7.18106 3.43641
         6.73102C3.83557 6.28097 4.38581 5.99277 4.98306 5.92094C5.58032
         5.8491 6.18319 5.9986 6.67769 6.34116C7.17218 6.68372 7.52402
         7.1956 7.66667 7.78H6.56C6.4658 7.57328 6.32292 7.39244 6.1436
         7.25297C5.96428 7.11349 5.75385 7.01952 5.5303 6.97909C5.30675
         6.93867 5.07673 6.95299 4.85992 7.02083C4.64311 7.08867 4.44595
         7.20801 4.28531 7.36865C4.12467 7.52928 4.00533 7.72644 3.93749
         7.94325C3.86965 8.16006 3.85533 8.39008 3.89576 8.61363C3.93619
         8.83718 4.03016 9.04762 4.16963 9.22694C4.30911 9.40626 4.48994
         9.54913 4.69667 9.64333ZM12.26 10.2067C12.0507 10.3868 11.8116
         10.529 11.5533 10.6267C11.1842 10.77 10.7858 10.8218 10.3922
         10.7777C9.99865 10.7336 9.62162 10.5948 9.29333
         10.3733V10.6267H8.28667V3.58H9.28667V6.32C9.64365 6.07866 10.0571
         5.93395 10.4867 5.9H10.6667C11.1672 5.89922 11.656 6.05198 12.067
         6.33768C12.478 6.62338 12.7915 7.02826 12.9651 7.49771C13.1388
         7.96716 13.1643 8.47859 13.0382 8.96299C12.9122 9.4474 12.6405
         9.88148 12.26 10.2067ZM12.1113 8.36267C12.1154 8.5494 12.0821
         8.73506 12.0135 8.90876C11.9449 9.08246 11.8422 9.24071 11.7116
         9.37421C11.581 9.50771 11.425 9.61378 11.2528 9.6862C11.0807
         9.75861 10.8958 9.79591 10.709 9.79591C10.5222 9.79591 10.3373
         9.75861 10.1652 9.6862C9.993 9.61378 9.83703 9.50771 9.70641
         9.37421C9.57579 9.24071 9.47315 9.08246 9.4045 8.90876C9.33586
         8.73506 9.3026 8.5494 9.30667 8.36267C9.31466 7.99604 9.46592
         7.64712 9.72804 7.39066C9.99015 7.13419 10.3423 6.99058 10.709
         6.99058C11.0757 6.99058 11.4278 7.13419 11.69 7.39066C11.9521
         7.64712 12.1033 7.99604 12.1113 8.36267Z'
      fill='currentColor'
      stroke='none'
    />
  ),
});
