import { CustomerTagsResponse } from 'api/rest/customer/models';
import { createCustomerTag } from 'api/rest/customer/tags/createCustomerTag';
import { fetchCustomerTags } from 'api/rest/customer/tags/fetchCustomerTags';
import { CustomerTagAtomType } from 'data/healthReports/models/company/CustomerTagAtomType';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const customerTagsAtom = getDataAtom<CustomerTagsResponse>({
  tags: [],
});

export const fetchCustomerTagsAtom = getDataFetchingAtom(
  customerTagsAtom,
  fetchCustomerTags,
);

export const newCustomerTagAtom = getDataAtom<CustomerTagAtomType>({
  tag: undefined,
});

export const createCustomerTagAtom = getDataFetchingAtom(
  newCustomerTagAtom,
  createCustomerTag,
);
