import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface UpdateCustomerUserSentimentParams {
  userId: string;
  sentiment: string | null;
}

export async function updateCustomerUserSentiment({
  userId,
  sentiment,
}: UpdateCustomerUserSentimentParams): Promise<CustomerUserResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/${userId}/sentiment/${sentiment}`,
    { method: 'PUT' },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(
      `Failed to update customer user sentiment: ${responseBody}`,
    );
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserResponse, data);
}
