import { z } from 'zod';

import { CurrencyType } from 'data/common/currencyType';
import { FinanceSettings } from 'data/settings/models/FinanceSettings';
import { GrowthSettings } from 'data/settings/models/GrowthSettings';
import { ProductSettings } from 'data/settings/models/ProductSettings';
import { SalesSettings } from 'data/settings/models/SalesSettings';

export const ChartSettings = z.object({
  SALES: SalesSettings,
  GROWTH: GrowthSettings,
  PRODUCT: ProductSettings,
  FINANCE: FinanceSettings,
  SUPPORT: z.object({ updating: z.boolean() }).default({ updating: false }),
  defaultCurrency: CurrencyType,
});
export type ChartSettings = z.infer<typeof ChartSettings>;
