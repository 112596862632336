import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { CommandBarMenuListItemBase } from 'components/CommandPanel/Modal/ListItems/CommandBarMenuListItemBase';
import { iconsByType } from 'components/CommandPanel/Modal/ListItems/UserMenuListItem';
import { BreakdownLinkCommandPanelOption } from 'data/commandPanel/models';
import { CommandPanelAnalyticsEvents } from 'utils/analytics/events/CommandPanelAnalyticsEvents';

type LeaderboardtemProps = {
  item: BreakdownLinkCommandPanelOption;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
};

export function LeaderboardMenuListItem({
  item,
  index,
  isSelected,
  onSelect,
  onClose,
}: LeaderboardtemProps) {
  const router = useRouter();
  const onClick = useCallback(() => {
    router.push(item.link);
  }, [router]);
  return (
    <CommandBarMenuListItemBase
      event={CommandPanelAnalyticsEvents.LEADERBOARD_SELECTED}
      icon={iconsByType.LEADERBOARD}
      id={item.title}
      name={item.title}
      index={index}
      isSelected={isSelected}
      onSelect={onSelect}
      onClick={() => {
        onClick();
        onClose();
      }}
    />
  );
}
