import { PROTECTED_API_URL } from 'data/constants/url';

export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '';
export const AMPLITUDE_API_KEY =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '';
export const HOTJAR_ID = Number(process.env.NEXT_PUBLIC_HOTJAR_ID) ?? 0;
export const HOTJAR_VERSION =
  Number(process.env.NEXT_PUBLIC_HOTJAR_VERSION) ?? 0;

export const SEGMENT_WRITE_KEY =
  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '';

export const SEGMENT_PROXY_URL = PROTECTED_API_URL
  ? `${PROTECTED_API_URL.replace(/(^\w+:|^)\/\//, '')}/a/s`
  : 'api.segment.io/v1';

export const SEGMENT_PROXY_PROTOCOL =
  process.env.NEXT_PUBLIC_SEGMENT_PROXY_PROTOCOL ?? 'https';
