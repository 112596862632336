import { createIcon } from '@chakra-ui/react';

export const CustomerCompanyIcon = createIcon({
  displayName: 'CustomerCompanyIcon',
  viewBox: '0 0 29 26',
  path: (
    <path
      d='M15.9284 11.6667H22.3284C23.8219 11.6667 24.5686 11.6667 25.139
         11.9573C25.6408 12.213 26.0487 12.6209 26.3044 13.1227C26.5951 13.6931
         26.5951 14.4399 26.5951 15.9333V25M15.9284 25V5.26667C15.9284 3.77319
         15.9284 3.02646 15.6377 2.45603C15.3821 1.95426 14.9741 1.54631 14.4724
         1.29065C13.9019 1 13.1552 1 11.6617 1H6.86172C5.36825 1 4.62151 1
         4.05108 1.29065C3.54931 1.54631 3.14136 1.95426 2.8857 2.45603C2.59505
         3.02646 2.59505 3.77319 2.59505 5.26667V25M27.9284 25H1.26172M7.26172
         6.33333H11.2617M7.26172 11.6667H11.2617M7.26172 17H11.2617'
      fill='none'
      stroke='#667085'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
