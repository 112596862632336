import { IconProps } from '@chakra-ui/icons';
import { IconButtonProps } from '@chakra-ui/react';
import { Property } from 'csstype';

import { ShareOptions } from 'components/Sharing/SharingOptions/sharingOptionsConfig';
import { WithSharingBlockModal } from 'components/Sharing/WithSharingBlockModal';
import { IconButton, ShareIcon } from 'components/uikit';
import { QueryParams } from 'data/sharingLink/atoms/globalShareBlock';
import { useIsSharedContent } from 'utils/hooks/useIsSharedContent';

type ShareBlockIconButtonProps = {
  sharingOptions: ShareOptions;
  blockId: string;
  blockName?: string;
  params?: QueryParams;
  sharingUrl?: string;
  iconSize?: IconProps['boxSize'];
  backgroundColor?: Property.BackgroundColor;
} & Pick<IconButtonProps, 'height'>;

export function ShareBlockIconButton({
  blockId,
  blockName,
  params,
  sharingUrl,
  sharingOptions,
  iconSize = '20px',
  backgroundColor = 'transparent',
  ...iconButtonProps
}: ShareBlockIconButtonProps) {
  const isSharedContent = useIsSharedContent();

  if (isSharedContent) {
    return null;
  }

  return (
    <WithSharingBlockModal
      blockId={blockId}
      sharingUrl={sharingUrl}
      sharingOptions={sharingOptions}
      blockName={blockName}
      params={params}
    >
      {(openShareDialog) => (
        <IconButton
          aria-label='Share'
          variant='grayGhost'
          size='sm'
          width='32px'
          icon={<ShareIcon stroke='grey.tertiaryText' boxSize={iconSize} />}
          onClick={openShareDialog}
          background={backgroundColor}
          {...iconButtonProps}
        />
      )}
    </WithSharingBlockModal>
  );
}
