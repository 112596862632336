import { useCallback } from 'react';

import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Card, chakra, HStack, Text, VStack } from 'components/uikit';
import { Chat } from 'data/ai/models';

interface AiChatItemProps extends Chat {
  onSelectChat: (chat: Chat) => void;
  isSelected: boolean;
  fullScreen?: boolean;
}

export function AiChatArchiveItem({
  onSelectChat,
  isSelected,
  fullScreen = false,
  ...chat
}: AiChatItemProps) {
  const { name, latestMessages, totalMessages } = chat;
  const { t } = useTranslation('ai');
  const onClick = useCallback(() => {
    onSelectChat(chat);
  }, [chat, onSelectChat]);

  const latestMessage = latestMessages[0];
  const otherMessagesCount = totalMessages - latestMessages.length;

  return (
    <Card
      boxShadow='none'
      cursor={isSelected ? undefined : 'pointer'}
      onClick={isSelected ? undefined : onClick}
      border={fullScreen && !isSelected ? 'none' : undefined}
      background={fullScreen && !isSelected ? 'none' : undefined}
      borderColor={isSelected ? 'secondary.button' : 'grey.border'}
      py={fullScreen ? '8px' : '12px'}
      px={fullScreen ? '8px' : '16px'}
      sx={
        isSelected
          ? {
              'p:first-of-type': {
                color: 'secondary.button',
              },
            }
          : {}
      }
      _hover={
        isSelected
          ? {}
          : {
              borderColor: 'secondary.button',
              'p:first-of-type': {
                color: 'secondary.button',
              },
            }
      }
      flex={0}
    >
      <VStack spacing='8px' align='stretch'>
        <HStack justify='space-between' spacing='8px' align='start'>
          <Text color='grey.secondaryText' fontWeight='medium'>
            {name}
          </Text>
          {!fullScreen && latestMessage ? (
            <Text
              pt='2px'
              whiteSpace='nowrap'
              fontSize='sm'
              color='grey.tertiaryText'
            >
              {formatDistance(latestMessage.insertedAt, new Date())}
            </Text>
          ) : null}
        </HStack>
        {fullScreen && latestMessage ? (
          <Text
            pt='2px'
            whiteSpace='nowrap'
            fontSize='sm'
            color='grey.tertiaryText'
          >
            {formatDistance(latestMessage.insertedAt, new Date())}
          </Text>
        ) : (
          <chakra.ul pl='6' color='grey.tertiaryText'>
            {latestMessages.map((message) =>
              message.content?.text ? (
                <li key={message.id}>{message.content?.text}</li>
              ) : null,
            )}
            {otherMessagesCount > 0 ? (
              <li>{t('more_questions', { count: otherMessagesCount })}</li>
            ) : null}
          </chakra.ul>
        )}
      </VStack>
    </Card>
  );
}
