import { Box, Center } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import { Heading } from 'components/uikit';
import connecting from 'components/uikit/animations/connecting.json';

export function Maintenance() {
  return (
    <Center
      width='100vw'
      height='100vh'
      backgroundColor='grey.background'
      flexDir='column'
      gap='24px'
    >
      <Heading
        as='h1'
        fontSize={['2xl', '3xl', '4xl']}
        fontWeight='700'
        minWidth='226px'
        textAlign='center'
      >
        Calliper is currently under maintenance
      </Heading>

      <Heading
        as='h2'
        fontSize={['lg', 'xl', '2xl']}
        fontWeight='500'
        minWidth='226px'
        textAlign='center'
      >
        We will be back online shortly
      </Heading>
      <Box width='240px' height='240px' data-testid='loader'>
        <Lottie animationData={connecting} loop />
      </Box>
    </Center>
  );
}
