import { Getter } from 'jotai';
import { ZodType, ZodTypeDef } from 'zod';

import { fetchBreakdownWithSummaryRow } from 'api/rest/charts/fetchBreakdownWithSummary';
import { FormattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import {
  BreakdownsSortArray,
  Filter,
} from 'data/charts/models/ChartsApiRequest';
import { BreakdownWithSummaryRowResponse } from 'data/charts/models/ChartsApiResponse';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';
import { getTimeRangeFromDatePickerAtom } from 'utils/atoms/getTimeRangeFromDatePickerAtom';
import { getSortingAtom } from 'utils/atoms/sortingAtom';

export type BreakdownWithSummaryRowPayload<Row, Summary> = {
  data: BreakdownWithSummaryRowResponse<Row, unknown, Summary, unknown>;
};

export function getFullBreakdownWithSummaryAtom<RowType, Summary = RowType>(
  rowParser: ZodType<RowType, ZodTypeDef, unknown>,
  initialSorts: BreakdownsSortArray<Extract<keyof RowType, string>> = [],
  datePickerAtom: FormattedDatePickerAtom,
  totalRowParser?: ZodType<Summary, ZodTypeDef, unknown>,
  filters?: Filter[],
) {
  async function fetcher(
    blockId: string,
    get: Getter,
  ): Promise<BreakdownWithSummaryRowPayload<RowType, Summary>> {
    const timeRange = getTimeRangeFromDatePickerAtom(get, datePickerAtom);

    const sorts = get(sortingAtom);

    const result = await fetchBreakdownWithSummaryRow(
      blockId,
      {
        filters: filters ?? [],
        timeRange,
        sorts,
      },
      BreakdownWithSummaryRowResponse(rowParser, totalRowParser),
    );

    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data: result.data!,
    };
  }

  const dataAtom = getDataAtom<
    BreakdownWithSummaryRowPayload<RowType, Summary>
  >({
    data: {
      rows: [],
      summaryRow: null,
      totalCount: 0,
    },
  });

  const fetchingAtom = getDataRefreshingAtom<
    BreakdownWithSummaryRowPayload<RowType, Summary>,
    string
  >(dataAtom, fetcher);

  const sortingAtom = getSortingAtom(initialSorts);

  return {
    dataAtom,
    fetchingAtom,
    sortingAtom,
  };
}
