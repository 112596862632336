import { ReactNode } from 'react';

import { IBMPlexSans } from 'components/fontLoader/IBMPlexSans';
import { Inter } from 'components/fontLoader/Inter';
import { useSetMobileAtom } from 'utils/hooks/screenBreakpoints';

import { Flex } from '../uikit';

interface AppLayoutProps {
  children?: ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  useSetMobileAtom();
  return (
    <>
      <style jsx global>{`
        :root {
          --font-base: ${Inter.style.fontFamily};
          --font-numeric: ${IBMPlexSans.style.fontFamily};
        }
      `}</style>
      <Flex as='main' flex={1} minH='100vh' h='100vh'>
        {children}
      </Flex>
    </>
  );
}
