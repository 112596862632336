import { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import { useAuthState } from 'data/auth/hooks/useAuthState';
import { fetchChartSettingsAtom } from 'data/settings/atoms/chartSettingsAtom';
import { useCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';

export function useFetchSettings() {
  const fetchSettings = useSetAtom(fetchChartSettingsAtom);
  const { isAuthenticated } = useAuthState();
  const { mainProductActive } = useCompanyOnboarding();

  useEffect(() => {
    if (isAuthenticated && mainProductActive) {
      fetchSettings();
    }
  }, [fetchSettings, isAuthenticated, mainProductActive]);
}
