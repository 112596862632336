import { Avatar } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { titleCase } from 'title-case';

import { NoteContextMenu } from 'components/CustomerDrawer/CompanyDrawer/DrawerTabs/NotesTabPanel/NoteContextMenu';
import {
  Box,
  Card,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from 'components/uikit';
import { Markdown } from 'components/uikit/Markdown';
import { CustomerNote } from 'data/customer/CustomerNote';
import { companyUsersAtom } from 'data/user/atoms/companyUsersAtom';
import { formatRelativeTime } from 'utils/datetime';

export function CompanyNoteCard({
  companyId,
  note,
}: {
  companyId: string;
  note: CustomerNote;
}) {
  const { users } = useAtomValue(companyUsersAtom);
  const user = users.find((user) => user.id === note.creatorId);
  const userFullName = user
    ? `${user?.firstName} ${user?.lastName}`
    : 'Unknown User';
  const title = user?.roleInCompany
    ? titleCase(user.roleInCompany)
    : 'Unknown Title';

  return (
    <Card p='16px' shadow={'none'}>
      <Flex
        width={'100%'}
        flexDirection={'row'}
        justify={'space-between'}
        gap={'10px'}
      >
        <VStack alignItems='left'>
          <HStack>
            <Avatar
              src={user?.avatar ?? undefined}
              name={userFullName}
              boxSize={'32px'}
            />
            <VStack alignItems='left' spacing={0}>
              <Heading
                as={'h5'}
                fontWeight={'semibold'}
                noOfLines={1}
                fontSize={'14px'}
                lineHeight={1.3}
              >
                {userFullName}
              </Heading>
              <Text
                noOfLines={1}
                fontSize={'12px'}
                lineHeight={1.6}
                color={'grey.tertiaryText'}
              >
                {title} &middot; {formatRelativeTime(note.updatedAt)}
              </Text>
            </VStack>
          </HStack>
          <Box pl={'40px'}>
            <Markdown>{note.content}</Markdown>
          </Box>
        </VStack>
        <NoteContextMenu companyId={companyId} noteId={note.id} />
      </Flex>
    </Card>
  );
}
