import { Box, BoxProps } from '@chakra-ui/react';

export interface IframeProps extends BoxProps {
  allow?: string;
  allowFullScreen?: boolean;
  allowTransparency?: boolean;
  frameBorder?: number | string;
  height?: number | string;
  marginHeight?: number;
  marginWidth?: number;
  name?: string;
  sandbox?: string;
  scrolling?: string;
  seamless?: boolean;
  src?: string;
  srcDoc?: string;
  zoom?: string;
}

export const Iframe = ({
  allow,
  allowFullScreen,
  allowTransparency,
  sandbox,
  scrolling,
  seamless,
  src,
  srcDoc,
  ...props
}: IframeProps) => {
  return (
    <Box {...props}>
      <iframe
        height='100%'
        width='100%'
        frameBorder='0'
        allow={allow}
        allowFullScreen={allowFullScreen}
        // allowTransparency={allowTransparency}
        sandbox={sandbox}
        scrolling={scrolling}
        seamless={seamless}
        src={src}
        srcDoc={srcDoc}
        style={{ zoom: props.zoom }}
      />
    </Box>
  );
};
