import { createIcon } from '@chakra-ui/react';

export const UserIcon = createIcon({
  displayName: 'UserIcon',
  viewBox: '0 0 18 24',
  path: (
    <>
      <path
        fill='none'
        stroke='currentcolor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M17 19v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M13 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z'
      />
    </>
  ),
});
