import { useHotkeys } from 'react-hotkeys-hook';
import {
  HotkeyCallback,
  OptionsOrDependencyArray,
} from 'react-hotkeys-hook/src/types';

import { BrowserInfo } from 'utils/browserInfo';
import { isNotNull } from 'utils/helpers';
import { DEFAULT_MAPPINGS } from 'utils/hotkeys/mappings';
import { Action } from 'utils/hotkeys/types';

function resolveAction(action: Action) {
  const actionMapping = DEFAULT_MAPPINGS[action];

  if (!actionMapping) return null;

  if (typeof actionMapping === 'string') {
    return actionMapping;
  }

  if (BrowserInfo.isApple) {
    return actionMapping.onMac;
  }
  return actionMapping.default;
}

/**
 * Action is an abstraction for key remapping
 * similar to how computer games key mapping works
 */
export const useKeyAction = (
  actions: Action | Action[],
  callback: HotkeyCallback,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray,
) => {
  const keys = Array.isArray(actions)
    ? actions.map(resolveAction).filter(isNotNull)
    : resolveAction(actions) ?? '';

  return useHotkeys(keys, callback, options, dependencies);
};

export function useUserReadableKeys(action: Action) {
  const resolved = resolveAction(action);
  if (!resolved) return [];
  return resolved.split('+').map((key) => (key === 'Meta' ? '⌘' : key));
}
