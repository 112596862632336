import {
  BarCustomLayerProps,
  BarDatum,
  ComputedBarDatum,
  ComputedDatum,
} from '@nivo/bar';

import { BarData } from 'data/charts/models/BarData';
import { addOpacityToChartColor, getNiceScale } from 'utils/chartsUtils';
import { isNotNullish, repeat } from 'utils/helpers';

export type BarChartAlignment =
  | 'top'
  | 'center'
  | 'bottom'
  | 'right'
  | 'left'
  | 'default'
  | 'dynamic';

function mapDefsToData(
  data: Array<BarData>,
  colors: string[],
  lastBarDotted: boolean,
  highlightedKey: string,
  getBarColor?: (item: ComputedDatum<BarData>) => string,
  chartId = '',
): Defs {
  return data
    .flatMap((barData, dataEntryIdx) => {
      const isLastBarData = dataEntryIdx === data.length - 1;
      return Object.entries(barData).map(([key, value], keyIdx) => {
        if (key === 'id') return null;

        const plainColor = getBarColor
          ? getBarColor({
              value: Number(value) ?? 0,
              formattedValue: String(value),
              data: barData,
              id: key,
              index: dataEntryIdx,
              hidden: false,
              indexValue: value,
            })
          : getBarChartColor(colors, key, keyIdx, !!barData.id);

        const finalColor = maskHighlightedColor(
          highlightedKey,
          key,
          plainColor,
        );

        if (lastBarDotted && isLastBarData) {
          return {
            id: `squares-${chartId}${dataEntryIdx}-${keyIdx}`,
            type: 'patternSquares',
            color: finalColor,
            size: 2,
            padding: 1,
            background: 'transparent',
          };
        } else {
          return {
            id: `solid-${chartId}${dataEntryIdx}-${keyIdx}`,
            type: 'patternLines',
            lineWidth: 2,
            spacing: 1,
            color: finalColor,
            background: 'transparent',
          };
        }
      });
    })
    .filter(isNotNullish);
}

function maskHighlightedColor(
  highlightedKey: string,
  key: string,
  hexColor: string,
) {
  return !!highlightedKey && key !== highlightedKey
    ? addOpacityToChartColor('#98A2B3', 0.32)
    : hexColor;
}

function getBarChartColor(
  colors: string[],
  key: string,
  keyIdx: number,
  hasId: boolean,
) {
  const idKeyShift = hasId ? 1 : 0; // sometimes there is a hidden id key that shifts the colours
  return colors[(keyIdx - idKeyShift) % colors.length] ?? '';
}

function mapFillsToData(
  data: Array<BarData>,
  lastBarDotted: boolean,
  chartId?: string,
) {
  return data.flatMap((barData, dataEntryIdx) => {
    const isLastBarData = dataEntryIdx === data.length - 1;
    return Object.keys(barData).map((key, keyIdx) => ({
      match: (datum: ComputedBarDatum<BarDatum>) => {
        return datum.key == `${key}.${barData.id}`;
      },
      id:
        lastBarDotted && isLastBarData
          ? `squares-${chartId}${dataEntryIdx}-${keyIdx}`
          : `solid-${chartId}${dataEntryIdx}-${keyIdx}`,
    }));
  });
}

type Defs = {
  id: string;
  [key: string]: unknown;
}[];

export function getBarDataDefsAndFills(
  data: Array<BarData>,
  colors: string[],
  lastBarDotted: boolean,
  highlightedKey: string,
  getBarColor?: (item: ComputedDatum<BarData>) => string,
  chartId = '',
) {
  return {
    defs: mapDefsToData(
      data,
      colors,
      lastBarDotted,
      highlightedKey,
      getBarColor,
      chartId,
    ),
    fills: mapFillsToData(data, lastBarDotted, chartId),
  };
}

const isPositive = (value: number) => value >= 0;
const isNegative = (value: number) => value <= 0;

const sumPositive = (values: (string | number)[]) => {
  return values
    .map(Number)
    .filter(isPositive)
    .reduce((a, b) => a + b, 0);
};

const sumNegative = (values: (string | number)[]) => {
  return values
    .map(Number)
    .filter(isNegative)
    .reduce((a, b) => a + b, 0);
};

const isSplit = (data: Array<BarData>) => {
  return data.some((datum) => Object.keys(datum).length > 2);
};

const getValues = (
  data: Array<BarData>,
  mapper: (values: (string | number)[]) => number,
) => {
  if (isSplit(data)) {
    return data
      .map(({ id, ...series }) => mapper(Object.values(series)))
      .flat();
  } else {
    return data
      .map(({ id, ...series }) => Object.values(series).map(Number))
      .flat();
  }
};

export function getBarDataMaxValue(data: Array<BarData>) {
  if (data.length === 0) {
    return 0;
  }
  const values = getValues(data, sumPositive);
  return Math.max(...values);
}

export function getBarDataMinValue(data: Array<BarData>) {
  if (data.length === 0) {
    return 0;
  }
  const values = getValues(data, sumNegative);
  return Math.min(...values);
}

function calculateDynamicAlignment(
  max: number,
  min: number,
): BarChartAlignment {
  if (max <= 0) {
    return 'top';
  } else if (min >= 0) {
    return 'bottom';
  } else {
    return 'center';
  }
}

function filterBarDataKeys(obj: BarData, keys: Exclude<string, 'id'>[]) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key)),
  ) as BarData;
}

function getBarValueScaleMaxMin(
  data: Array<BarData>,
  alignment: BarChartAlignment,
  valueKeys?: Exclude<string, 'id'>[],
): { max: number; min: number } {
  const filteredData = valueKeys
    ? data.map((datum) => filterBarDataKeys(datum, valueKeys))
    : data;

  const maxValue = getBarDataMaxValue(filteredData);
  const minValue = getBarDataMinValue(filteredData);

  switch (alignment) {
    case 'dynamic':
      return getBarValueScaleMaxMin(
        data,
        calculateDynamicAlignment(maxValue, minValue),
      );
    case 'default':
      return {
        max: maxValue,
        min: minValue,
      };
    case 'top':
      return {
        max: 0,
        min: minValue,
      };
    case 'bottom':
    case 'left':
      return {
        max: maxValue,
        min: 0,
      };
    case 'right':
      return {
        max: 0,
        min: minValue,
      };
    case 'center':
      return {
        max: Math.max(Math.abs(maxValue), Math.abs(minValue)),
        min: -Math.max(Math.abs(maxValue), Math.abs(minValue)),
      };
  }
}

export function calculateNiceScaleRange(
  data: Array<BarData>,
  align: BarChartAlignment,
  valueKeys?: Exclude<string, 'id'>[],
) {
  const scaleValues = getBarValueScaleMaxMin(data, align, valueKeys);
  const allZeroValues =
    scaleValues.min === scaleValues.max && scaleValues.min === 0;

  return {
    scaleMin: getNiceScale(allZeroValues ? 0 : scaleValues.min),
    scaleMax: getNiceScale(allZeroValues ? 1 : scaleValues.max),
    allZeroValues,
  };
}

/*
 * We assume null values at the beginning of the bar chart are missing data.
 * This function returns the number of bars that have a value of 0 at the beginning of the chart.
 */
export function calculateLeadingMissingDataRange(
  props: BarCustomLayerProps<BarData>,
) {
  const maxBarIndex = Math.max(...props.bars.map((bar) => bar.data.index));
  const maxIndex = maxBarIndex == -Infinity ? 0 : maxBarIndex;
  return repeat(maxIndex + 1)
    .times((index) =>
      props.bars
        .filter((barData) => barData.data.index === index)
        .map((barData) => barData.data.value),
    )
    .map((barValues) =>
      barValues.every((value) => value === null) ? 'hasNoData' : 'hasData',
    )
    .findIndex((value) => value === 'hasData');
}
