import { isEmpty } from 'utils/helpers';

export function getSearchParams() {
  return new URLSearchParams(window.location.search);
}

export function appendSearchParam<T extends string | boolean | number | Date>(
  search: URLSearchParams,
  key: string,
  value: T | T[] | Set<T> | undefined | null,
) {
  if (typeof value == 'undefined' || value == null) {
    search.delete(key);
  }
  if (Array.isArray(value) || value instanceof Set) {
    search.delete(`${key}[]`);
    for (const item of value) {
      search.append(`${key}[]`, String(item));
    }
  } else if (value instanceof Date) {
    search.delete(key);
    search.append(key, String(value.valueOf()));
  } else {
    search.delete(key);
    search.append(key, String(value));
  }
}

export function setSearchParam<T extends string | boolean | number | Date>(
  key: string,
  value: T | T[] | Set<T> | undefined | null,
) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  appendSearchParam(searchParams, key, value);
  currentUrl.search = searchParams.toString();

  window.history.replaceState({}, '', currentUrl.toString());
}

export const getQueryParam = (key: string): string | string[] | null => {
  if (typeof window === 'undefined' || !window.location) {
    return null;
  }

  const singleValue = getSearchParams().get(key);
  if (singleValue != null) return singleValue;

  const arrayValue = getSearchParams().getAll(`${key}[]`);

  if (!isEmpty(arrayValue)) return arrayValue;

  return null;
};

export function buildUrlSearchParams(
  queryParams: Record<
    string,
    string | string[] | boolean | number | Date | undefined
  >,
): URLSearchParams {
  const params = new URLSearchParams();

  for (const key in queryParams) {
    const value = queryParams[key];
    if (typeof value === 'undefined' || value == null) continue;
    appendSearchParam(params, key, value);
  }

  return params;
}

export function getSetValue<T extends string>(
  key: string,
  defaultValue: Set<T>,
) {
  const value = getQueryParam(key);
  if (value == null) return defaultValue;
  if (Array.isArray(value)) return new Set(value as T[]);
  return new Set([value as T]);
}

export function castQueryParamToBoolean(
  value: string | string[] | null,
  defaultValue = false,
) {
  if (value == null) return defaultValue;
  if (Array.isArray(value)) {
    if (isEmpty(value)) return defaultValue;
    return value[0] === 'true';
  }
  return value === 'true';
}

export function getBooleanValue(key: string, defaultValue: boolean) {
  const value = getQueryParam(key);
  return castQueryParamToBoolean(value, defaultValue);
}

export function getDateValue(key: string, defaultValue: Date | null) {
  const value = getQueryParam(key);
  if (value == null) return defaultValue;
  if (Array.isArray(value)) {
    if (!value[0]) return defaultValue;
    return new Date(Number(value[0]));
  }
  return new Date(Number(value));
}

export function getStringValue(key: string, defaultValue: string) {
  return getOptionalStringValue(key) ?? defaultValue;
}

export function getOptionalStringValue(key: string) {
  const value = getQueryParam(key);
  if (value === null) return null;
  if (Array.isArray(value)) {
    return value[0] ?? null;
  }
  return value;
}

export function getOptionalNumberValue(key: string) {
  const value = getOptionalStringValue(key);

  if (value === null) return null;
  const numericValue = Number(value);

  return isNaN(numericValue) ? null : numericValue;
}

export function getNumberValue(key: string, defaultValue: number) {
  const value = getOptionalNumberValue(key);
  return value ?? defaultValue;
}

export function encodeUrlParam(value: Date | string | number) {
  if (value instanceof Date) {
    return encodeURIComponent(value.toISOString());
  }
  if (typeof value === 'number') {
    return String(value);
  }
  return value;
}
