import { createIcon } from '@chakra-ui/react';

export const ArchiveIcon = createIcon({
  displayName: 'ArchiveIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d='M21 8V21H3V8M10 12H14M1 3H23V8H1V3Z'
        stroke='currentColor'
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
