import { createIcon } from '@chakra-ui/react';

export const HorizontalBarChartIcon = createIcon({
  displayName: 'HorizontalBarChartIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <mask id='path-1-inside-1_12741_2776' fill='white'>
        <rect x='1' y='1.18237' width='2.55777' height='30.6932' rx='0.5' />
      </mask>
      <rect
        x='1'
        y='1.18237'
        width='2.55777'
        height='30.6932'
        rx='0.5'
        stroke='currentColor'
        strokeWidth='2.55777'
        mask='url(#path-1-inside-1_12741_2776)'
        fill='none'
      />
      <path
        d='M2.71729 13.9911H22.5078M2.71729 13.9911V20.8119M2.71729 13.9911V7.53908C2.71729 7.26198 2.94264 7.03773
           3.21974 7.03909L29.5026 7.16804C29.7778 7.16939 30.0001 7.39285 30.0001 7.66804V13.4911C30.0001 13.7673
           29.7763 13.9911 29.5001 13.9911H22.5078M22.5078 13.9911L22.5078 20.3119C22.5078 20.588 22.2839 20.8119
           22.0078 20.8119H16.2408M2.71729 20.8119H16.2408M2.71729 20.8119V27.1326C2.71729 27.4087 2.94114 27.6326
           3.21729 27.6326H15.7408C16.017 27.6326 16.2408 27.4087 16.2408 27.1326V20.8119'
        stroke='currentColor'
        strokeWidth='2'
        fill='none'
      />
    </>
  ),
});
