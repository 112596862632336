import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompaniesResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { Department } from 'data/blocks/models/Block';
import { parseItemStrict } from 'utils/parser/parseResponse';

export type FetchCustomerCompaniesByDepartmentIdsParams = {
  department: Department;
  departmentIds: string[];
};

export async function fetchCustomerCompanyByDepartmentIds({
  department,
  departmentIds,
}: FetchCustomerCompaniesByDepartmentIdsParams): Promise<CustomerCompaniesResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/departments/${department}`,
    { method: 'POST', body: JSON.stringify({ departmentIds }) },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to fetch customer companies: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompaniesResponse, data);
}
