import { useTranslation } from 'react-i18next';

import { FullBreakdownTablePaginated } from 'components/charts/FullBreakdownTable/paginated/FullBreakdownTablePaginated';
import { useActiveUsersTableConfig } from 'components/CustomerDrawer/CompanyDrawer/hooks/useActiveUsersTableConfig';
import { useCustomerUserNameRowConfig } from 'components/CustomerDrawer/UserDrawer/hooks/useCustomerUserNameRowConfig';
import {
  useCustomerCompanyUserActivityBreakdownPaginationAtom,
  useCustomerCompanyUserActivityBreakdownSortingAtom,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { useCustomerCompanyUserActivityBreakdownDataReFetch } from 'data/healthReports/hooks/company/useCustomerCompanyUserActivityBreakdownDataReFetch';
import { useCustomerCompanyUserActivityFetchedData } from 'data/healthReports/hooks/company/useCustomerCompanyUserActivityFetchedData';

interface RecentlyActiveBreakdownChartProps {
  customerCompanyId: string;
  blockId: string;
  onDrawerClose: () => void;
}

export function RecentlyActiveBreakdownChart({
  customerCompanyId,
  blockId,
  onDrawerClose,
}: RecentlyActiveBreakdownChartProps) {
  useCustomerCompanyUserActivityBreakdownDataReFetch(
    customerCompanyId,
    blockId,
  );
  const { t } = useTranslation('breakdowns');
  const { data, fetchStatus } =
    useCustomerCompanyUserActivityFetchedData(customerCompanyId);

  const rowConfig = useCustomerUserNameRowConfig('PRODUCT');
  const config = useActiveUsersTableConfig(onDrawerClose);
  const [pagination, setPagination] =
    useCustomerCompanyUserActivityBreakdownPaginationAtom(customerCompanyId);
  const [sorts, setSorts] =
    useCustomerCompanyUserActivityBreakdownSortingAtom(customerCompanyId);

  return (
    <FullBreakdownTablePaginated
      isDemo={false}
      trackingLabel='usersListFullBreakdown'
      sorts={sorts}
      onSortChange={setSorts}
      pagination={pagination}
      onPaginationChange={setPagination}
      fetchStatus={fetchStatus}
      title={t('users')}
      config={config}
      rowConfig={rowConfig}
      data={data}
      onResetTable={undefined}
      width={'100%'}
    />
  );
}
