import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useStyledToast } from 'components/Toast';
import { Text, Key, CopyIcon } from 'components/uikit';
import { useUserReadableKeys } from 'utils/hotkeys/useHotkeyAction';

const TOAST_DURATION_IN_MS = 5000;

function HotKeyCombinationItem({
  isLast,
  combinationKey,
}: {
  isLast: boolean;
  combinationKey: string;
}) {
  const combinationSymbol = isLast ? null : (
    <Text fontWeight='regular' fontSize='sm' textColor='grey.tertiaryText'>
      +
    </Text>
  );

  return (
    <>
      <Key
        size='24px'
        element={combinationKey}
        allowGrowWidth={combinationKey.length > 1}
      />
      {combinationSymbol}
    </>
  );
}

function HotKeysHint() {
  const { t } = useTranslation('common');

  const keyCombination = useUserReadableKeys('COPY_SHARE_LINK');

  return (
    <>
      <Text fontWeight='regular' fontSize='sm' textColor='grey.tertiaryText'>
        {t('sharing_link_copied_toast_hint_description')}
      </Text>
      <Flex gap='10px' alignItems='center'>
        {keyCombination.map((key, index) => (
          <HotKeyCombinationItem
            isLast={index === keyCombination.length - 1}
            key={index}
            combinationKey={key}
          />
        ))}
      </Flex>
    </>
  );
}

function ShareItMessage({
  sharingType,
}: {
  sharingType: 'organization' | 'public';
}) {
  const { t } = useTranslation('common');

  return (
    <Text fontWeight='regular' fontSize='sm' textColor='grey.tertiaryText'>
      {t(`sharing_link_copied_toast_from_hotkeys_description.${sharingType}`)}
    </Text>
  );
}

function ShareScreenshotMessage({ type }: { type: 'copied' | 'downloaded' }) {
  const { t } = useTranslation('common');

  return (
    <Text fontWeight='regular' fontSize='sm' textColor='grey.tertiaryText'>
      {t(`sharing_screenshot_toast_description.${type}`)}
    </Text>
  );
}

function ShareLinkCopiedToastDescription({
  withHotKeysHint,
  sharingType,
}: {
  withHotKeysHint: boolean;
  sharingType: 'organization' | 'public';
}) {
  return (
    <Flex flexDir='column' gap='14px'>
      {withHotKeysHint ? (
        <HotKeysHint />
      ) : (
        <ShareItMessage sharingType={sharingType} />
      )}
    </Flex>
  );
}

export function useShareLinkCopiedToast() {
  const { t } = useTranslation('common');
  const toast = useStyledToast({
    containerStyle: {
      margin: '32px',
    },
  });

  const renderSuccessToast = (
    useHotKeysHint: boolean,
    sharingType: 'organization' | 'public',
  ) => {
    toast({
      variant: 'SUCCESS',
      title: t('sharing_link_copied_toast_title'),
      titleColor: 'grey.secondaryText',
      description: (
        <ShareLinkCopiedToastDescription
          withHotKeysHint={useHotKeysHint}
          sharingType={sharingType}
        />
      ),
      duration: TOAST_DURATION_IN_MS,
      position: 'bottom-right',
      Icon: CopyIcon,
      iconColor: 'grey.secondaryText',
      iconBackgroundColor: 'grey.white',
    });
  };

  const renderSuccessScreenshotToast = (type: 'copied' | 'downloaded') => {
    toast({
      variant: 'SUCCESS',
      title: t(`sharing_screenshot_toast_title.${type}`),
      titleColor: 'grey.secondaryText',
      description: <ShareScreenshotMessage type={type} />,
      duration: TOAST_DURATION_IN_MS,
      position: 'bottom-right',
      Icon: CopyIcon,
      iconColor: 'grey.secondaryText',
      iconBackgroundColor: 'grey.white',
    });
  };

  const renderErrorToast = () =>
    toast({
      variant: 'ERROR',
      title: t('sharing_link_copied_toast_error_title'),
      titleColor: 'grey.secondaryText',
      description: (
        <Text fontWeight='regular' fontSize='sm' textColor='grey.tertiaryText'>
          {t('something_went_wrong')}
        </Text>
      ),
      duration: TOAST_DURATION_IN_MS,
      position: 'bottom-right',
    });

  return {
    renderSuccessToast,
    renderSuccessScreenshotToast,
    renderErrorToast,
  };
}
