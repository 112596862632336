import { useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { CustomerTagsList } from 'components/CustomerDrawer/CompanyDrawer/CompanyTags/CustomerTagsList';
import { CompanyTagsModalFooter } from 'components/CustomerDrawer/CompanyDrawer/CompanyTagsModal/CompanyTagsModalFooter';
import { CompanyTagsModalHeader } from 'components/CustomerDrawer/CompanyDrawer/CompanyTagsModal/CompanyTagsModalHeader';
import { CreateTagForm } from 'components/CustomerDrawer/Tags/CreateTagForm';
import { CreateTagModalFooter } from 'components/CustomerDrawer/Tags/CreateTagModalFooter';
import { Card, VStack } from 'components/uikit';
import { draftNewTagAtom } from 'data/healthReports/atoms/draftNewTagAtom';
import { searchTagsBoxValueAtom } from 'data/healthReports/atoms/searchTagsBoxValueAtom';

interface CompanyTagsModalProps {
  customerCompanyId: string;
  onTagsClose: () => void;
}

export function CompanyTagsModal({
  customerCompanyId,
  onTagsClose,
}: CompanyTagsModalProps) {
  const [variant, setVariant] = useState<'list' | 'create'>('list');
  const searchBoxValue = useAtomValue(searchTagsBoxValueAtom);
  const setNewTag = useSetAtom(draftNewTagAtom);

  const onCreateTagOpen = () => {
    setVariant('create');
    setNewTag({ title: searchBoxValue, description: '' });
  };

  const onCreateTagClose = () => {
    setVariant('list');
  };

  return (
    <VStack
      background={'grey.background'}
      width={'100%'}
      p={'24px'}
      height={'100%'}
      spacing={'0'}
    >
      <Card
        overflow={'hidden'}
        border={'0px solid'}
        height={'100%'}
        hidden={variant !== 'list'}
      >
        <CompanyTagsModalHeader />
        <CustomerTagsList companyId={customerCompanyId} />
        <CompanyTagsModalFooter
          companyId={customerCompanyId}
          onTagsClose={onTagsClose}
          onCreateTag={onCreateTagOpen}
        />
      </Card>

      <Card
        overflow={'hidden'}
        border={'0px solid'}
        height={'100%'}
        hidden={variant !== 'create'}
      >
        <CompanyTagsModalHeader />
        <CreateTagForm tKeyPrefix={'company_drawer.company_tags_modal.body'} />
        <CreateTagModalFooter
          tKeyPrefix={'company_drawer.company_tags_modal.footer'}
          onClose={onCreateTagClose}
          customerType={'company'}
        />
      </Card>
    </VStack>
  );
}
