import gql from 'graphql-tag';
import * as Urql from 'urql';

import * as Types from '../../generatedTypes';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckDataSystemStatusMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CheckDataSystemStatusMutation = {
  __typename?: 'Mutation';
  checkDataSystemStatus: Types.DataSystemStatus;
};

export const CheckDataSystemStatusDocument = gql`
  mutation checkDataSystemStatus {
    checkDataSystemStatus
  }
`;

export function useCheckDataSystemStatusMutation() {
  return Urql.useMutation<
    CheckDataSystemStatusMutation,
    CheckDataSystemStatusMutationVariables
  >(CheckDataSystemStatusDocument);
}
