import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { PageRequest } from 'data/charts/models/ChartsApiRequest';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { Category, CategoryValues } from 'data/charts/models/filters';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict } from 'utils/parser/parseResponse';

const CategoryValuesRequest = z.object({
  category: z.string(),
  pageRequest: PageRequest,
});
export type CategoryValuesRequest = z.infer<typeof CategoryValuesRequest>;

const CategoryValuesResponse = CategoryValues.omit({
  blockId: true,
  category: true,
});
type CategoryValuesResponse = z.infer<typeof CategoryValuesResponse>;

export async function fetchFilters({
  blockId,
}: {
  blockId: string;
}): Promise<{ categories: Category[] }> {
  const result = await authorisedFetch(`${CHARTS_API_URL}/filters`, {
    method: 'POST',
    body: JSON.stringify({ blocks: [{ blockId }] }),
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch new filters: ${result.status}`);
  }

  const data = await result.json();
  const categories = parseItemStrict(
    BlockResponse(z.array(Category)),
    data[0],
  ).data;

  return { categories };
}

export async function fetchFilterValues({
  blockId,
  chartConfig,
}: {
  blockId: string;
  chartConfig: CategoryValuesRequest;
}): Promise<CategoryValuesResponse> {
  const result = await authorisedFetch(`${CHARTS_API_URL}/filters/values`, {
    method: 'POST',
    body: JSON.stringify({ blocks: [{ blockId, chartConfig }] }),
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch new filters: ${result.status}`);
  }

  const data = await result.json();
  return parseItemStrict(BlockResponse(CategoryValuesResponse), data[0]).data;
}
