import { Box } from 'components/uikit';
import { Markdown } from 'components/uikit/Markdown';
import { InsightType } from 'data/radar/models/insight';
import { isBlank } from 'utils/helpers';

interface RadarInsightDesktopDescriptionProps {
  description: string | null;
  type: InsightType;
}

export function RadarInsightDesktopDescription({
  description,
  type,
}: RadarInsightDesktopDescriptionProps) {
  if (type !== InsightType.enum.INSIGHT || isBlank(description)) {
    return null;
  }

  return (
    <Box
      fontWeight='500'
      fontSize='16px'
      color='grey.secondaryText'
      lineHeight='150%'
      css={{
        li: { paddingBottom: '8px' },
        'li:last-child': { paddingBottom: 0 },
      }}
    >
      <Markdown>{description}</Markdown>
    </Box>
  );
}
