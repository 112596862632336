import { z, ZodType } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { ClickableCardBreakdownConfig } from 'data/charts/models/ChartsApiRequest';
import {
  BlockResponse,
  ClickableCardBreakdownResponse,
} from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function fetchClickableCardBreakdown<Input, Output>(
  blockId: string,
  chartConfig: ClickableCardBreakdownConfig,
  rowParser: ZodType<Output, z.ZodTypeDef, Input>,
): Promise<BlockResponse<ClickableCardBreakdownResponse<Output, Input>>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      blocks: [
        {
          blockId,
          chartConfig,
        },
      ],
    }),
  });

  if (!result.ok) {
    throw new Error(
      `Failed to fetch clickable card breakdown: ${result.status}`,
    );
  }

  const json = await result.json();
  const [breakdown] = parseItemStrict(
    z.tuple([BlockResponse(ClickableCardBreakdownResponse(rowParser))]),
    json,
  );

  return breakdown;
}
