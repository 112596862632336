import { TFuncKey } from 'react-i18next';

export type InputStatus = 'SUCCESS' | 'ERROR' | 'DISABLED' | null;

export type ValidateFunction<T> = (value?: T) => {
  inputStatus: InputStatus;
  message?: TFuncKey;
};

export function getMessageColorForStatus(status?: InputStatus) {
  if (!status) return 'grey.tertiaryText';
  return {
    SUCCESS: 'green.500',
    ERROR: 'error.button',
    DISABLED: 'grey.tertiaryText',
  }[status];
}
