import { useTranslation } from 'react-i18next';

import { Text, Key, ModalFooterWrapper } from 'components/uikit';
import { ArrowsUpDownIcon } from 'components/uikit/icons/ArrowsUpDown';
import { EnterIcon } from 'components/uikit/icons/Enter';

export function CommandPanelSearchFooter() {
  const { t } = useTranslation('control-panel');

  return (
    <ModalFooterWrapper>
      <Key
        isOutlined
        element={<ArrowsUpDownIcon stroke='grey.tertiaryText' boxSize='10px' />}
      />
      <Text color='grey.tertiaryText'>{t('arrows_to_select')}</Text>
      <Key
        isOutlined
        element={<EnterIcon stroke='grey.tertiaryText' boxSize='10px' />}
      />
      <Text color='grey.tertiaryText'>{t('enter_to_open')}</Text>
    </ModalFooterWrapper>
  );
}
