import { createIcon } from '@chakra-ui/react';

export const StarryStarryNightIcon = createIcon({
  displayName: 'StarryStarryNightIcon',
  viewBox: '0 0 32 32',
  path: (
    <>
      <path
        d='M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332
           4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723
           13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226
           18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218
           14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018
           20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214
           18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723
           20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z'
        stroke='currentColor'
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
