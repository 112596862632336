import {
  AreaGenerator,
  AreaSegment,
} from 'components/charts/Layers/AreasLayer/AreaSegment';
import { splitColorSegments } from 'utils/lineChartUtils';

type SeriesAreaProps = {
  areaGenerator: AreaGenerator;
  points: Array<{ x: number; y: number }>;
  colors: Array<string>;
  areaId: string;
};

export function SeriesArea({
  areaGenerator,
  points,
  colors,
  areaId,
}: SeriesAreaProps) {
  const colorSegments = splitColorSegments(colors);
  return (
    <>
      {colorSegments.map(({ start, end, color }, index) => (
        <AreaSegment
          key={`${areaId}-segment-${index}`}
          generatedArea={areaGenerator(
            points.slice(Math.max(0, start - 1), end + 1),
          )}
          color={color}
          areaSegmentId={`${areaId}-segment-${index}`}
        />
      ))}
    </>
  );
}
