import { createIcon } from '@chakra-ui/react';

export const SunIcon = createIcon({
  displayName: 'SunIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath='url(#clip0_563_34687)'>
        <path
          d='M12 1V3M12 21V23M4.22 4.22L5.64 5.64M18.36 18.36L19.78 19.78M1 12H3M21 12H23M4.22 19.78L5.64 18.36M18.36 5.64L19.78 4.22M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='transparent'
        />
      </g>
      <defs>
        <clipPath id='clip0_563_34687'>
          <rect width='24' height='24' fill='transparent' />
        </clipPath>
      </defs>
    </>
  ),
});
