import { createIcon } from '@chakra-ui/react';

export const StripeLogo = createIcon({
  displayName: 'StripeLogo',
  viewBox: '0 0 77 32',
  path: (
    <>
      <path
        fill='#635BFF'
        fillRule='evenodd'
        d='M77 16.534c0-5.469-2.652-9.784-7.721-9.784-5.091 0-8.17 4.315-8.17 9.741 0 6.43 3.635 9.677 8.854 9.677 2.545 0 4.47-.576 5.925-1.388v-4.273c-1.455.727-3.123 1.175-5.24 1.175-2.075 0-3.915-.726-4.15-3.247h10.46c0-.277.042-1.388.042-1.901Zm-10.566-2.03c0-2.413 1.476-3.417 2.823-3.417 1.305 0 2.695 1.004 2.695 3.418h-5.518ZM52.852 6.75c-2.096 0-3.444.983-4.192 1.667l-.278-1.325h-4.706V32l5.347-1.132.022-6.046c.77.556 1.903 1.346 3.786 1.346 3.828 0 7.315-3.076 7.315-9.848-.022-6.194-3.551-9.57-7.294-9.57ZM51.569 21.47c-1.262 0-2.01-.449-2.524-1.004l-.022-7.926c.556-.62 1.326-1.046 2.546-1.046 1.946 0 3.293 2.179 3.293 4.977 0 2.862-1.326 4.999-3.293 4.999ZM36.318 5.49l5.369-1.154V0l-5.369 1.132V5.49Z'
        clipRule='evenodd'
      />
      <path fill='#635BFF' d='M41.687 7.113h-5.369v18.692h5.369V7.113Z' />
      <path
        fill='#635BFF'
        fillRule='evenodd'
        d='m30.565 8.694-.343-1.58h-4.62v18.691h5.348V13.137c1.262-1.644 3.4-1.345 4.064-1.11V7.113c-.685-.256-3.187-.726-4.45 1.581ZM19.87 2.478l-5.219 1.11L14.63 20.7c0 3.161 2.374 5.49 5.54 5.49 1.754 0 3.037-.32 3.743-.705v-4.337c-.685.278-4.064 1.26-4.064-1.901v-7.583h4.064v-4.55h-4.064l.021-4.636ZM5.411 12.54c0-.834.685-1.154 1.818-1.154 1.626 0 3.68.491 5.305 1.367v-5.02c-1.775-.705-3.53-.983-5.305-.983C2.888 6.75 0 9.015 0 12.796c0 5.896 8.128 4.956 8.128 7.498 0 .982-.856 1.303-2.054 1.303-1.775 0-4.042-.727-5.839-1.71v5.085c1.99.854 4 1.218 5.84 1.218 4.448 0 7.507-2.2 7.507-6.024-.021-6.366-8.17-5.234-8.17-7.627Z'
        clipRule='evenodd'
      />
    </>
  ),
});
