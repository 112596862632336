import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { BreakdownConfig } from 'data/charts/models/ChartsApiRequest';
import { BlockResponse } from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict, Parser } from 'utils/parser/parseResponse';

export async function fetchBreakdownWithSummaryRow<Output, Input>(
  blockId: string,
  chartConfig: BreakdownConfig,
  parser: Parser<Output, Input>,
): Promise<BlockResponse<Output, Input>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({ blocks: [{ blockId, chartConfig }] }),
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch new breakdown: ${result.status}`);
  }

  const [breakdown] = parseItemStrict(
    z.tuple([BlockResponse(parser)]),
    await result.json(),
  );

  return breakdown;
}
