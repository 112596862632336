import { endOfDay, startOfDay, startOfHour, subDays } from 'date-fns';
import { Atom, atom } from 'jotai';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { missionControlGranularityAtom } from 'data/charts/atoms/missionControlGranularityAtom';
import {
  getDateBeforePeriods,
  getStartOfDayBeforePeriods,
  getStartOfLastDayOfPeriod,
} from 'utils/datetime/dateUtil';
import { formatDateToUtcIso } from 'utils/datetime/timeFormat';
import { noop } from 'utils/helpers';

export type DatePickerAtomValue = {
  date: Date;
  granularity: AggregationPeriod;
};

export type FormattedDatePickerAtomValue = {
  end: string;
  startOfEndDay: string;
  startOfLastDayOfPreviousPeriod: string;
  startOfCurrentPeriod: string;
  startOfToday1PeriodBefore: string;
  endOfToday1PeriodBefore: string;
  startOf1PeriodBefore: string;
  endOf1PeriodBefore: string;
  // These next 3 dates should include the current period
  start4thPeriodBeforeInclusive: string;
  start6thPeriodBeforeInclusive: string;
  start7thPeriodBeforeInclusive: string;
  start8thPeriodBeforeInclusive: string;
  start10thPeriodBeforeInclusive: string;
  start30thPeriodBeforeInclusive: string;
  granularity: AggregationPeriod;
};

export type DateRangeAtom = Atom<DatePickerAtomValue>;

export const missionControlDateRangeAtom = getFormattedDateRangeAtom(
  atom((get) => {
    const granularity = get(missionControlGranularityAtom);

    return {
      date: new Date(),
      granularity,
    };
  }, noop),
);

export function getFormattedDateRangeAtom(timePickerAtom: DateRangeAtom) {
  return atom((get) => {
    const { date, granularity } = get(timePickerAtom);

    const startOfCurrentPeriod = getDateBeforePeriods(date, 1, granularity);
    const startOfToday1PeriodBefore = getStartOfDayBeforePeriods(
      date,
      1,
      granularity,
    );
    const startOfLastDayOfPreviousPeriod = getStartOfLastDayOfPeriod(
      startOfToday1PeriodBefore,
      granularity,
    );

    const formattedValues: FormattedDatePickerAtomValue = {
      // The only datetime that should not be pushed by UTC is the end date
      // because, for consistency when looking the data form different time
      // zones, we want to show the data from start date T00:00:00.000Z to
      // the current datetime in the current time zone (which is then converted
      // to the UTC properly).
      end: startOfHour(date).toISOString(),
      startOfEndDay: formatDateToUtcIso(startOfDay(date)),
      startOfLastDayOfPreviousPeriod: formatDateToUtcIso(
        startOfLastDayOfPreviousPeriod,
      ),
      startOfCurrentPeriod: formatDateToUtcIso(startOfCurrentPeriod),
      endOf1PeriodBefore: formatDateToUtcIso(
        subDays(endOfDay(startOfCurrentPeriod), 1),
      ),
      startOfToday1PeriodBefore: formatDateToUtcIso(startOfToday1PeriodBefore),
      endOfToday1PeriodBefore: formatDateToUtcIso(
        endOfDay(startOfToday1PeriodBefore),
      ),
      startOf1PeriodBefore: formatDateToUtcIso(
        getDateBeforePeriods(date, 2, granularity),
      ),
      start4thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 4, granularity),
      ),
      start6thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 6, granularity),
      ),
      start7thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 7, granularity),
      ),
      start8thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 8, granularity),
      ),
      start10thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 10, granularity),
      ),
      start30thPeriodBeforeInclusive: formatDateToUtcIso(
        getDateBeforePeriods(date, 30, granularity),
      ),
      granularity,
    };
    return formattedValues;
  });
}
