import { Getter } from 'jotai';

import {
  GetDataSourcesDocument,
  GetDataSourcesQuery,
  GetDataSourcesQueryVariables,
} from 'api/graphql/backendAPI/queries/datasources/getDataSources/getDataSources.generated';
import { getClient } from 'api/graphql/client';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataRefreshingAtom } from 'utils/atoms/dataFetchingAtom';
import { deleteKey, indexBy } from 'utils/helpers';

import { DataSourcesPayload } from '../models';

const getDataSourcesIfNotCreated = async (_ = {}, get: Getter) => {
  const dataSources = get(dataSourcesAtom);

  const dataSourcesPendingCreation = Object.values(
    dataSources.dataSources ?? {},
  ).some(({ status }) => status === 'NOT_CREATED');

  if (dataSources.fetchStatus === 'SUCCESS' && !dataSourcesPendingCreation)
    return dataSources;

  return await getDataSources();
};

export const getDataSources = async () => {
  const clientQuery = getClient().query<
    GetDataSourcesQuery,
    GetDataSourcesQueryVariables
  >(GetDataSourcesDocument, {}, { requestPolicy: 'network-only' });

  try {
    const { data, error } = await clientQuery.toPromise();

    if (error) {
      throw new Error(JSON.stringify(error));
    }
    if (!data) {
      throw new Error('could not fetch dataSources');
    }
    const dataSources = data.dataSources;
    const indexedDataSources = indexBy(({ provider }) => provider, dataSources);

    return {
      dataSources: deleteKey('GOOGLE_ANALYTICS_3', indexedDataSources),
    };
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    return { dataSources: null };
  }
};

export const dataSourcesAtom = getDataAtom<DataSourcesPayload>({
  dataSources: null,
});

export const fetchDataSourcesAtom = getDataRefreshingAtom<
  DataSourcesPayload,
  { force?: boolean } | undefined
>(dataSourcesAtom, getDataSourcesIfNotCreated);
