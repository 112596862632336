import { ReactNode } from 'react';

import { Flex, HStack, Spacer, Text, VStack } from '../reexport';

interface CardHeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
  summary: ReactNode;
  controls?: ReactNode;
}

export function CardHeader({
  title,
  subtitle,
  summary,
  controls = null,
}: CardHeaderProps) {
  return (
    <>
      <HStack px='24px' py='16px' height='78px'>
        <VStack align='flex-start' spacing='4px'>
          <Text fontSize='18px' color='grey.primaryText' fontWeight='600'>
            {title}
          </Text>
          {subtitle ? (
            <Text fontSize='14px' color='grey.tertiaryText' fontWeight='400'>
              {subtitle}
            </Text>
          ) : null}
        </VStack>
        <Spacer />
        {controls}
      </HStack>

      <Flex w='100%'>{summary}</Flex>
    </>
  );
}
