import { z } from 'zod';

export const CustomerTag = z.object({
  id: z.string().uuid(),
  creatorId: z.string(),
  title: z.string(),
  description: z.string(),
  insertedAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type CustomerTag = z.infer<typeof CustomerTag>;
