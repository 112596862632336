import { CalliperLogoLinkBox } from 'components/SidebarMenu/SidebarMenuMobile/CalliperLogoLinkBox';
import { SidebarMenuMobileCloseButton } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobileCloseButton';
import { SidebarMenuMobileHeaderTitle } from 'components/SidebarMenu/SidebarMenuMobile/SidebarMenuMobileHeaderTitle';
import { Stack } from 'components/uikit';

export function SidebarMenuMobileHeader() {
  return (
    <Stack
      height='48px'
      alignSelf='stretch'
      align='center'
      spacing='0px'
      background={'#090D13'}
      borderColor={'grey.border.dark'}
      borderBottomWidth='1px'
      paddingX='16px'
      paddingY='8px'
      direction='row'
      justify='space-between'
    >
      <Stack direction='row' justify='flex-start' align='center' spacing='12px'>
        <CalliperLogoLinkBox />
        <SidebarMenuMobileHeaderTitle />
      </Stack>
      <SidebarMenuMobileCloseButton />
    </Stack>
  );
}
