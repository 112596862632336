import { FC, MouseEventHandler } from 'react';

import { IconProps } from '@chakra-ui/icons';

import { ComingSoonBadge } from 'components/Sharing/ComingSoonBadge';
import { Button } from 'components/uikit';

interface SharingBlockModalOptionButtonProps {
  caption: string;
  Icon: FC<IconProps>;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export function SharingBlockModalOptionButton({
  caption,
  Icon,
  isActive = false,
  onClick,
  disabled = false,
}: SharingBlockModalOptionButtonProps) {
  const textColor = isActive ? 'secondary.text' : 'grey.tertiaryText';
  const backgroundColor = isActive ? 'secondary.background' : undefined;

  return (
    <Button
      variant={'primaryGhost'}
      isActive={isActive}
      onClick={onClick}
      fontWeight='medium'
      color={textColor}
      height='40px'
      width='100%'
      justifyContent='flex-start'
      borderRadius='4px'
      background={backgroundColor}
      disabled={disabled}
    >
      <Icon boxSize='16px' stroke={textColor} mr='9px' />
      {caption}
      {disabled && <ComingSoonBadge />}
    </Button>
  );
}
