import { ColorProps } from '@chakra-ui/react';
import { subMonths } from 'date-fns';

import {
  FullBreakdownByUsersRow,
  UserSegments,
} from 'data/charts/atoms/product/users/breakdowns';

export const getSegmentFromBreakdownByUsersRow = ({
  isPowerUser,
  activeSessionsL30,
  engagedSessionsL30,
  joinDate,
}: FullBreakdownByUsersRow) => {
  if (isPowerUser) {
    return UserSegments.enum.POWER;
  } else if (engagedSessionsL30 > 0) {
    return UserSegments.enum.ENGAGED;
  } else if (joinDate > subMonths(new Date(), 1)) {
    return UserSegments.enum.NEW;
  } else if (activeSessionsL30 > 0) {
    return UserSegments.enum.ACTIVE;
  } else {
    return UserSegments.enum.INACTIVE;
  }
};

export const getSegmentColor = (segment: string): ColorProps['color'] => {
  switch (segment) {
    case UserSegments.enum.NEW:
      return 'dataViz.5';
    case UserSegments.enum.ACTIVE:
      return 'dataViz.1';
    case UserSegments.enum.ENGAGED:
      return 'dataViz.2';
    case UserSegments.enum.POWER:
      return 'dataViz.3';
    default:
      return 'dataViz.6';
  }
};

export const getSegmentColorVar = (segment: string): ColorProps['color'] => {
  switch (segment) {
    case UserSegments.enum.NEW:
      return 'var(--chakra-colors-dataViz-5)';
    case UserSegments.enum.ACTIVE:
      return 'var(--chakra-colors-dataViz-1)';
    case UserSegments.enum.ENGAGED:
      return 'var(--chakra-colors-dataViz-2)';
    case UserSegments.enum.POWER:
      return 'var(--chakra-colors-dataViz-3)';
    default:
      return 'var(--chakra-colors-dataViz-6)';
  }
};
