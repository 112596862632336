import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MinusIcon } from '@chakra-ui/icons';
import { BoxProps, Center } from '@chakra-ui/react';
import { Property } from 'csstype';
import { HotkeyCallback } from 'react-hotkeys-hook/src/types';

import {
  Box,
  CheckboxIcon,
  HStack,
  Key,
  KeyStyle,
  LockIcon,
  ScrollsIntoView,
  Text,
} from 'components/uikit';
import { lastChar } from 'utils/helpers';
import { Action, hasAction, WithNullableAction } from 'utils/hotkeys/types';
import { useKeyAction } from 'utils/hotkeys/useHotkeyAction';
import { genericMemo } from 'utils/reactUtils';

type RowType = 'locked' | 'checkbox' | 'normal' | 'removable';
type RowStyle = 'normal' | 'hover' | 'disabled' | 'selected';

interface RowStyleProps {
  textColor: Property.Color;
  background: Property.Color;
  keyStyle: KeyStyle;
  opacity?: string;
  focus?: BoxProps['_focus'];
}

const ROW_STYLE: Readonly<{ [key in RowStyle]: RowStyleProps }> = {
  normal: {
    textColor: 'grey.secondaryText',
    background: 'transparent',
    keyStyle: 'normal',
  },
  hover: {
    background: 'grey.offWhite',
    textColor: 'grey.primaryText',
    keyStyle: 'hover',
    focus: {
      boxShadow: 'inset 4px 0 0 0 secondary.button',
    },
  },
  disabled: {
    textColor: 'grey.tertiaryText',
    background: 'grey.background',
    keyStyle: 'disabled',
    opacity: '50%',
  },
  selected: {
    textColor: 'secondary.text',
    background: 'secondary.background',
    keyStyle: 'selected',
    focus: {
      boxShadow: 'inset 4px 0 0 0 secondary.button',
    },
  },
} as const;

type OnSelectCallback<T> = (value: T) => void;

function GetRowStyle(
  isSelected: boolean,
  isDisabled: boolean,
  isFocused: boolean,
  isHovered: boolean,
): RowStyleProps {
  if (isDisabled) {
    return ROW_STYLE.disabled;
  }
  if (isSelected) {
    return ROW_STYLE.selected;
  }
  if (isFocused || isHovered) {
    return ROW_STYLE.hover;
  }
  return ROW_STYLE.normal;
}

type Props<T> = {
  value: WithNullableAction<T>;
  onSelect: OnSelectCallback<T> | null;
  focusOnMount: boolean;
  hotkeysEnabled?: boolean;
  isSelected?: boolean;
  isFocused: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  rowType?: RowType;
  optionLabel: string;
};

interface KeyBindingProps {
  onSelect: HotkeyCallback;
  action: Action;
  enabled: boolean;
}

export function KeyBinding({ onSelect, action, enabled }: KeyBindingProps) {
  useKeyAction(action, onSelect, { enabled }, [onSelect]);
  return null;
}

function IconBorders({
  color,
  children,
}: PropsWithChildren<{ color: Property.Color }>) {
  return (
    <Center
      backgroundColor='grey.white'
      boxSize='16px'
      borderRadius='4px'
      borderWidth='1px'
      borderColor={color}
      flexShrink={0}
    >
      {children}
    </Center>
  );
}

function OptionIcon({
  type,
  isSelected,
}: {
  type: RowType;
  isSelected: boolean;
}) {
  if (type === 'normal') {
    return null;
  }
  if (type === 'locked') {
    return <LockIcon stroke={ROW_STYLE.disabled.textColor} />;
  }
  if (type === 'removable') {
    return (
      <IconBorders color={ROW_STYLE.selected.textColor}>
        <MinusIcon color={ROW_STYLE.selected.textColor} boxSize='12px' />
      </IconBorders>
    );
  }

  if (isSelected) {
    return (
      <IconBorders color={ROW_STYLE.selected.textColor}>
        <CheckboxIcon color={ROW_STYLE.selected.textColor} boxSize='12px' />
      </IconBorders>
    );
  }

  return <IconBorders color='gray.400' />;
}

export const SelectionModalOptionRow = genericMemo(
  function SelectionModalOptionRow<T>({
    value,
    onSelect,
    focusOnMount,
    isFocused,
    onFocus,
    onBlur,
    optionLabel,
    hotkeysEnabled = true,
    isSelected = false,
    rowType = 'normal',
  }: Props<T>) {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseOverCallback = useCallback(() => {
      setIsHovered(true);
    }, []);

    const onMouseLeaveCallback = useCallback(() => {
      setIsHovered(false);
    }, []);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isFocused) {
        ref.current?.focus();
      }
    }, [isFocused]);

    useEffect(() => {
      if (focusOnMount) {
        ref.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const style = GetRowStyle(
      isSelected,
      rowType === 'locked',
      isFocused,
      isHovered,
    );

    return (
      <ScrollsIntoView selected={isFocused}>
        <Box
          ref={ref}
          onClick={() => onSelect?.(value)}
          onMouseOver={onMouseOverCallback}
          onMouseLeave={onMouseLeaveCallback}
          bgColor={style.background}
          cursor={onSelect !== null ? 'pointer' : 'default'}
          tabIndex={rowType === 'locked' ? -1 : 0}
          onFocus={onFocus}
          onBlur={onBlur}
          _focus={{
            outline: 'none',
            ...style.focus,
          }}
        >
          <HStack
            justify='space-between'
            align='center'
            height='48px'
            paddingLeft='24px'
            paddingRight='16px'
            opacity={style.opacity}
          >
            <Center width='100%' gap='8px'>
              <OptionIcon type={rowType} isSelected={isSelected} />
              <Text
                fontSize='sm'
                fontWeight='medium'
                color={style.textColor}
                width='100px'
                flexGrow={1}
                noOfLines={1}
              >
                {optionLabel}
              </Text>
            </Center>

            {hasAction(value) && onSelect !== null ? (
              <>
                <KeyBinding
                  onSelect={() => onSelect(value)}
                  action={value.action}
                  enabled={hotkeysEnabled}
                />
                <Key
                  isOutlined
                  style={style.keyStyle}
                  size='24px'
                  element={lastChar(value.action) as string}
                />
              </>
            ) : null}
          </HStack>
        </Box>
      </ScrollsIntoView>
    );
  },
);
