import { useCallback, useEffect } from 'react';

import { useUserDrawerState } from 'components/CustomerDrawer/hooks/useUserDrawerState';
import { Department } from 'data/blocks/models/Block';
import { useFetchCustomerUser } from 'data/healthReports/hooks/users/useFetchCustomerUser';
import { isSuccess } from 'utils/types';

interface CustomerUserDrawerRowProps {
  department: Department;
  departmentId: string;
  onDataLoaded: () => void;
}

export function useOnCustomerUserDrawerOpen({
  department,
  departmentId,
  onDataLoaded,
}: CustomerUserDrawerRowProps) {
  const { user, fetchStatus } = useFetchCustomerUser(department, departmentId);
  const { onOpen: openDrawer } = useUserDrawerState();

  useEffect(() => {
    if (isSuccess(fetchStatus)) {
      onDataLoaded();
    }
  }, [fetchStatus, onDataLoaded]);

  return useCallback(() => {
    if (!isSuccess(fetchStatus) || !user) {
      return;
    }

    openDrawer(user);
  }, [user, fetchStatus, openDrawer]);
}
