import { useMemo } from 'react';

import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { IconButton, MoreVerticalIcon } from 'components/uikit';
import {
  useCustomerUserNoteDeletingAtom,
  useCustomerUserNotesFetchingAtom,
} from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

export function NoteContextMenu({
  userId,
  noteId,
}: {
  userId: string;
  noteId: string;
}) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.tabs.notes_tab',
  });
  const fetchNotes = useSetAtom(useCustomerUserNotesFetchingAtom(userId));
  const deleteNote = useSetAtom(useCustomerUserNoteDeletingAtom(userId));

  const menuItems = useMemo(
    () => [
      {
        label: t('delete_note'),
        onClick: async () => {
          await deleteNote({ userId, noteId });
          trackEvent(CustomerDrawerAnalyticsEvents.NOTES_DELETED, {
            customerType: 'user',
          });
          await fetchNotes(userId);
        },
      },
    ],
    [t, deleteNote, userId, noteId, trackEvent, fetchNotes],
  );

  return (
    <DropdownMenu items={menuItems}>
      <IconButton
        aria-label={t('more_options_dropdown_aria_label')}
        variant='grayGhost'
        height='34px'
        width='32px'
        icon={
          <MoreVerticalIcon
            stroke='grey.tertiaryText'
            width='16px'
            height='16px'
          />
        }
        size='xs'
      />
    </DropdownMenu>
  );
}
