import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerCompanyResponse } from 'api/rest/customer/models';
import { CUSTOMERS_COMPANY_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface RemoveCustomerTagFromCompanyParams {
  companyId: string;
  tagId: string;
}

export async function removeCustomerTagFromCompany({
  companyId,
  tagId,
}: RemoveCustomerTagFromCompanyParams): Promise<CustomerCompanyResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_COMPANY_API_URL}/${companyId}/tags/${tagId}`,
    { method: 'DELETE' },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to remove customer company tag: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerCompanyResponse, data);
}
