import { FunctionComponent, ReactNode } from 'react';

import { BarData } from 'data/charts/models/BarData';
import { Currency } from 'utils/formatting/number';

import { Tooltip } from '../common/Tooltip';

interface TooltipFormatProps {
  data: BarData;
  colors: string[];
  getKeyColor?: (key: string) => string;
  sortKeys: (keys: string[]) => string[];
  currency: Currency | null;
}

export type TooltipFormat = FunctionComponent<TooltipFormatProps>;
export type TooltipTitleFormat = (data: BarData) => ReactNode;

type BarChartTooltipProps = {
  titleFormat: TooltipTitleFormat;
  contentFormat: TooltipFormat;
} & TooltipFormatProps;

export function BarChartTooltip({
  data,
  titleFormat,
  contentFormat,
  colors,
  getKeyColor,
  sortKeys,
  currency,
}: BarChartTooltipProps) {
  return (
    <Tooltip title={titleFormat(data)}>
      {contentFormat({ data, colors, getKeyColor, sortKeys, currency })}
    </Tooltip>
  );
}
