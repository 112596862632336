import { useFetchedData } from 'data/charts/hooks/useFetchedData';
import { useCustomerCompanyUserActivityBreakdownAtoms } from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';

export const useCustomerCompanyUserActivityFetchedData = (
  companyId: string,
) => {
  return useFetchedData(
    useCustomerCompanyUserActivityBreakdownAtoms(companyId).dataAtom,
  );
};
