import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CHARTS_API_URL } from 'data/constants';
import { SalesStageMappings } from 'data/settings/models/SalesStages';
import { parseItemStrict } from 'utils/parser/parseResponse';

export async function fetchSalesStageMappings(): Promise<SalesStageMappings> {
  const result = await authorisedFetch(`${CHARTS_API_URL}/settings/sales`, {
    method: 'GET',
  });

  const data = await result.json();

  if (result.status > 399) {
    throw new Error(`Failed to fetch chart settings: ${result.status}`);
  }

  return parseItemStrict(SalesStageMappings, data, false);
}
