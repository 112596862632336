import {
  BookIcon,
  DataSourcesIcon,
  MagicWandIcon,
  StarryStarryNightIcon,
  VerticalBarChartIcon,
} from 'components/uikit';
import { TrophyIcon } from 'components/uikit/icons/TrophyIcon';
import paths from 'utils/paths';
import { useIsEnterprise } from 'utils/useIsEnterprise';

import { SidebarNavItemProps } from './SidebarNavItem';

export const useSidebarNavItemConfigs = (): SidebarNavItemProps[] => {
  const isEnterprise = useIsEnterprise();

  if (isEnterprise) {
    return [
      {
        path: paths.radar.index,
        Icon: StarryStarryNightIcon,
        title: 'radar',
        hideOnMobile: false,
      },

      {
        path: paths.aiChat.index,
        Icon: MagicWandIcon,
        title: 'ai_chat',
        hideOnMobile: false,
      },
    ];
  }
  return [
    {
      path: paths.missionControl.index,
      Icon: VerticalBarChartIcon,
      title: 'mission_control',
      hideOnMobile: false,
    },
    {
      path: paths.radar.index,
      Icon: StarryStarryNightIcon,
      title: 'radar',
      hideOnMobile: false,
    },
    {
      path: paths.breakdowns.index,
      Icon: TrophyIcon,
      title: 'breakdowns',
      hideOnMobile: true,
    },
    {
      path: paths.dictionary.index,
      Icon: BookIcon,
      title: 'metrics_dictionary',
      hideOnMobile: false,
    },
    {
      path: paths.dataSources.index,
      Icon: DataSourcesIcon,
      title: 'data_sources',
      hideOnMobile: true,
    },
  ];
};
