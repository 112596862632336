import { useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { ModalWrapper } from 'components/uikit';

import { useControlPanelContent } from './useControlPanel';

export const ControlPanel = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const content = useControlPanelContent();

  useEffect(() => {
    if (!isOpen && content !== null) {
      onOpen();
    }
    if (isOpen && content === null) {
      onClose();
    }
  }, [isOpen, content, onOpen, onClose]);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} maxWidth='680px'>
      {content}
    </ModalWrapper>
  );
};
