import { AnalyticsBrowser } from '@segment/analytics-next';

import { SEGMENT_PROXY_PROTOCOL, SEGMENT_PROXY_URL } from 'data/constants';
import { SEGMENT_WRITE_KEY } from 'data/constants/analytics';
import {
  AnalyticsEventType,
  AnalyticsIdentifier,
  AnalyticsUserEvent,
} from 'utils/analytics';

const analytics = new AnalyticsBrowser();

async function track(event: AnalyticsUserEvent) {
  const { type, payload } = event;
  await analytics.ready(async () => {
    if (event.type === AnalyticsEventType.PAGE_VISIT) {
      if (event.payload.page) await analytics.page(event.payload.page);
      return;
    }

    await analytics.track(
      type.action,
      { group: type.group, ...payload },
      {
        groupId: event.company,
      },
    );
  });
}

async function identify({ user, company }: AnalyticsIdentifier) {
  await analytics.ready(async () => {
    if ((await analytics.user()).id()) return;

    await analytics.identify(user.id, {
      email: user.email || '',
      name: [user.firstName, user.lastName].filter(Boolean).join(' '),
    });

    await analytics.group(company);
  });
}

async function reset() {
  await analytics.reset();
}

export function initSegmentProvider() {
  if (!SEGMENT_WRITE_KEY || typeof window === 'undefined') return null;

  analytics
    .load(
      {
        writeKey: SEGMENT_WRITE_KEY,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: SEGMENT_PROXY_URL,
            protocol: SEGMENT_PROXY_PROTOCOL,
          },
        },
      },
    )
    .catch((error) => {
      console.error('Failed to load Segment', error);
    });

  return {
    track,
    identify,
    reset,
  };
}
