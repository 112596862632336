import { hotjar } from 'react-hotjar';

import { HOTJAR_ID, HOTJAR_VERSION } from 'data/constants/analytics';
import {
  AnalyticsEventType,
  AnalyticsUserEvent,
  AnalyticsIdentifier,
} from 'utils/analytics';

const HOTJAR_USER_KEY = '@calliper/hotjarUser';

async function track(event: AnalyticsUserEvent) {
  if (event.type === AnalyticsEventType.PAGE_VISIT) {
    hotjar.stateChange((event.payload as { page: string }).page);
  }
}

async function identify({ user, company }: AnalyticsIdentifier) {
  if (localStorage.getItem(HOTJAR_USER_KEY) === user.id) return;
  localStorage.setItem(HOTJAR_USER_KEY, user.id);

  hotjar.identify(user.id, { ...user, company });
}

async function reset() {
  localStorage.removeItem(HOTJAR_USER_KEY);

  hotjar.identify(null, {});
}

export function initHotjarProvider() {
  if (!HOTJAR_ID || !HOTJAR_VERSION || typeof window == 'undefined')
    return null;

  hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);

  return { track, identify, reset };
}
