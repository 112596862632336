import { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import { WritableDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';

export const useFetchCustomerCompanyBreakdownBlock = (
  fetchingAtom: WritableDataFetchingAtom<unknown, string>,
  blockId?: string,
) => {
  const fetchChart = useSetAtom(fetchingAtom);

  useEffect(() => {
    if (!blockId) {
      return;
    }

    fetchChart(blockId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
