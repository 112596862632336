import { z } from 'zod';

import { Currency } from 'utils/formatting/number';

export const CurrencyType = z.string().transform((value, ctx) => {
  const probableCurrency = value.toUpperCase();

  if (!Object.values(Currency).includes(probableCurrency as Currency)) {
    console.warn(
      `Currency ${probableCurrency} might not be supported, found in ${ctx.path}. Please check Currency enum.`,
    );
  }

  return probableCurrency as Currency;
});
