import { useCallback } from 'react';

import { useNavigate } from 'utils/navigation';

export function useNextPathRedirect() {
  const navigate = useNavigate();

  return useCallback(
    (data: unknown) => {
      const parsedData = typeof data === 'string' ? JSON.parse(data) : null;
      if (
        parsedData &&
        'nextPath' in parsedData &&
        parsedData.nextPath != '/'
      ) {
        navigate(parsedData.nextPath);
      }
    },
    [navigate],
  );
}
