import { Circle } from '@chakra-ui/react';

import { UserSegments } from 'data/charts/atoms/product/users/breakdowns';
import { getSegmentColor } from 'data/healthReports/utils/userSegmentIndicator';

type Props = {
  segment: UserSegments;
};
export const UserSegmentIndicator = ({ segment }: Props) => {
  return <Circle background={getSegmentColor(segment)} size='12px' />;
};
