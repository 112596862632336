import { z } from 'zod';

import { fixedEndDateFormattedAYearAgoDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { CurrencyType } from 'data/common/currencyType';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

const TransactionsTypes = z.enum([
  'mrr_new',
  'mrr_expansion',
  'mrr_contraction',
  'mrr_churn',
  'mrr_de-activation',
  'mrr_re-activation',
]);

export const FullBreakdownByTransactionsRow = z.object({
  customerId: z.string(),
  customerName: z.string(),
  type: TransactionsTypes,
  product: z.string(),
  date: z.coerce.date(),
  amount: z.number(),
  joinDate: z.coerce.date().nullable(),
  currency: CurrencyType,
  customer: z.string(),
  joinMonth: z.string(),
});

export type FullBreakdownByTransactionsRow = z.infer<
  typeof FullBreakdownByTransactionsRow
>;

export const breakdownByLatestTransactionsAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByTransactionsRow,
    [{ column: 'date', direction: 'DESC' }],
    fixedEndDateFormattedAYearAgoDateRangeAtom,
  );

export const breakdownByLargestChurnAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByTransactionsRow,
  [{ column: 'amount', direction: 'ASC' }],
  fixedEndDateFormattedAYearAgoDateRangeAtom,
);

export const breakdownByLatestChurnAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownByTransactionsRow,
  [{ column: 'date', direction: 'DESC' }],
  fixedEndDateFormattedAYearAgoDateRangeAtom,
);

export const breakdownByExpansionTransactionsAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownByTransactionsRow,
    [{ column: 'amount', direction: 'DESC' }],
    fixedEndDateFormattedAYearAgoDateRangeAtom,
  );
