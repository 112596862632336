import { z } from 'zod';

export const DisplayType = z.enum([
  'LINE',
  'FUNNEL',
  'TABLE',
  'BAR',
  'STAT_CARD',
  'HORIZONTAL_BAR',
]);

export type DisplayType = z.infer<typeof DisplayType>;
