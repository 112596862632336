import { createIcon } from '@chakra-ui/react';

export const SyncingLine = createIcon({
  displayName: 'SyncingLine',
  viewBox: '0 0 117 6',
  path: (
    <>
      <circle
        cx='3'
        cy='3'
        r='2'
        fill='white'
        stroke='#697586'
        strokeWidth='1.5'
      />
      <rect
        x='5.5'
        y='2.5'
        width='106'
        height='1'
        fill='#697586'
        stroke='#697586'
      />
      <circle cx='40' cy='3' r='3' fill='#12B76A' />
      <circle
        cx='114'
        cy='3'
        r='2'
        fill='white'
        stroke='#697586'
        strokeWidth='1.5'
      />
      <circle cx='78' cy='3' r='3' fill='#12B76A' />
    </>
  ),
});
