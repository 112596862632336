import { Getter } from 'jotai';
import { z, ZodType, ZodTypeDef } from 'zod';

import { fetchPaginatedBreakdown } from 'api/rest/charts/fetchPaginatedBreakdown';
import { FormattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import {
  BreakdownsSortArray,
  Filter,
} from 'data/charts/models/ChartsApiRequest';
import { PaginatedBreakdownResponse } from 'data/charts/models/ChartsApiResponse';
import { BreakdownResult } from 'utils/atoms/BreakdownResult';
import { getDataAtom } from 'utils/atoms/dataAtom';
import { getDataFetchingAtom } from 'utils/atoms/dataFetchingAtom';
import { getFiltersAtom } from 'utils/atoms/getFiltersAtom';
import { getTimeRangeFromDatePickerAtom } from 'utils/atoms/getTimeRangeFromDatePickerAtom';
import { getPaginationAtom } from 'utils/atoms/paginationAtom';
import { getSortingAtom } from 'utils/atoms/sortingAtom';

export function getFullBreakdownWithPaginationAtom<
  Output extends Record<string, unknown>,
  Input,
>(
  rowParser: ZodType<Output, ZodTypeDef, Input>,
  initialSorts: BreakdownsSortArray<Extract<keyof Output, string>> = [],
  datePickerAtom: FormattedDatePickerAtom,
  filters: Filter[] = [],
) {
  async function fetcher(
    blockId: string,
    get: Getter,
  ): Promise<BreakdownResult<Output>> {
    const timeRange = getTimeRangeFromDatePickerAtom(get, datePickerAtom);

    const pagination = get(paginationAtom);
    const sorts = get(sortingAtom);
    const { filters } = get(filtersAtom);

    const result = await fetchPaginatedBreakdown<z.infer<typeof rowParser>>({
      blockId,
      parser: PaginatedBreakdownResponse(rowParser),
      chartConfig: {
        filters,
        timeRange,
        sorts,
        pagination: {
          ...pagination,
          page: pagination.page,
        },
      },
    });

    return {
      data: result.data,
    };
  }

  const dataAtom = getDataAtom<BreakdownResult<Output>>({
    data: {
      rows: [],
      totalCount: 0,
      pageSize: 10,
      page: 0,
      totalPages: 0,
    },
  });

  const fetchingAtom = getDataFetchingAtom<BreakdownResult<Output>, string>(
    dataAtom,
    fetcher,
  );

  const paginationAtom = getPaginationAtom();
  const sortingAtom = getSortingAtom(initialSorts);
  const filtersAtom = getFiltersAtom(filters);

  return {
    dataAtom,
    fetchingAtom,
    paginationAtom,
    sortingAtom,
    filtersAtom,
  };
}

export type FullBreakdownWithPaginationAtoms<
  Output extends Record<string, unknown>,
  Input,
> = ReturnType<typeof getFullBreakdownWithPaginationAtom<Output, Input>>;
