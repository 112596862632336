import { createIcon } from '@chakra-ui/react';

export const GoogleAdsMenuIcon = createIcon({
  displayName: 'GoogleAdsMenuIcon',
  viewBox: '0 0 40 40',
  path: (
    <>
      <circle cx='20' cy='20.25' r='20' fill='#347EF9' />
      <rect
        width='13.1198'
        height='6.60797'
        transform='matrix(0.515669 -0.856788 -0.856788 -0.515669 16.2344 27.6719)'
        fill='white'
      />
      <circle cx='13.659' cy='25.5213' r='3.30357' fill='#B4B4B4' />
      <circle cx='26.5145' cy='25.5213' r='3.30357' fill='#CACACA' />
      <circle cx='20.0848' cy='14.8036' r='3.30357' fill='#CACACA' />
      <rect
        x='22.8359'
        y='13.1162'
        width='13.1198'
        height='6.52324'
        transform='rotate(58.9578 22.8359 13.1162)'
        fill='#CACACA'
      />
    </>
  ),
});
