import { useCallback, useEffect } from 'react';

import { useCompanyOnboarding } from 'data/user/hooks/useCompanyOnboarding';
import { useNavigate } from 'utils/navigation';
import paths from 'utils/paths';
import { isLoading } from 'utils/types';
import { useIsEnterprise } from 'utils/useIsEnterprise';

export const useNavigateToLandingPage = () => {
  const navigate = useNavigate();
  const getLandingPageRoute = useGetLandingPageRoute();

  useEffect(() => {
    const { route } = getLandingPageRoute();
    if (route) navigate(route);
  }, [getLandingPageRoute, navigate]);
};

export const useGetLandingPageRoute = () => {
  const { mainProductActive, fetchStatus } = useCompanyOnboarding();
  const isEnterprise = useIsEnterprise();

  return useCallback(() => {
    if (isLoading(fetchStatus)) {
      return { route: undefined, fetchStatus };
    }

    return {
      route: mainProductActive
        ? isEnterprise
          ? paths.radar.index
          : paths.missionControl.index
        : paths.onboarding.index,
      fetchStatus,
    };
  }, [fetchStatus, mainProductActive, isEnterprise]);
};
