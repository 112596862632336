import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { ChartConfig, Filter } from 'data/charts/models/ChartsApiRequest';
import {
  BlockResponse,
  TimeSeriesResponse,
} from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict } from 'utils/parser/parseResponse';

interface Params<T, R extends z.ZodType<Filter>> {
  blockId: string;
  chartConfig: ChartConfig<T, R>;
}

export async function fetchTimeSeries<
  T,
  R extends z.ZodType<Filter> = z.ZodType<Filter>,
>({
  blockId,
  chartConfig,
}: Params<T, R>): Promise<BlockResponse<TimeSeriesResponse>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({ blocks: [{ blockId, chartConfig }] }),
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch new breakdown: ${result.status}`);
  }

  const json = await result.json();
  const [timeSeries] = parseItemStrict(
    z.array(BlockResponse(TimeSeriesResponse)).length(1),
    json,
    false,
  );

  return timeSeries as BlockResponse<TimeSeriesResponse>;
}
