const paths = {
  aiChat: {
    index: '/ai_chat',
  },
  radar: {
    index: '/radar',
  },
  onboarding: {
    index: '/onboarding',
    selectGaAccounts: '/onboarding/select-ga-accounts',
    selectGaConversionEvent: '/onboarding/select-ga-conversion-event',
    selectGaProperties: '/onboarding/select-ga-properties',
    dataSources: {
      index: '/onboarding/data-sources',
      connect: '/onboarding/data-sources/connect',
    },
  },
  profile: {
    index: '/profile',
  },
  missionControl: {
    index: '/mission-control',
    marketing: '/mission-control/marketing',
    sales: '/mission-control/sales',
    product: '/mission-control/product',
    finance: '/mission-control/finance',
    support: '/mission-control/support',
  },
  breakdowns: {
    index: '/breakdowns',
    customers: '/breakdowns/customers',
    marketing: '/breakdowns/marketing',
    sales: '/breakdowns/sales',
    product: '/breakdowns/product',
    finance: '/breakdowns/finance',
  },
  dictionary: {
    index: '/dictionary',
    marketing: '/dictionary/marketing',
    sales: '/dictionary/sales',
    product: '/dictionary/product',
    finance: '/dictionary/finance',
    support: '/dictionary/support',
  },
  dataSources: {
    index: '/data-sources',
    connect: '/data-sources/connect',
  },
  alerts: {
    index: '/radar/alerts',
    create: {
      index: '/radar/alerts/create',
      marketing: '/radar/alerts/create/marketing',
      sales: '/radar/alerts/create/sales',
      product: '/radar/alerts/create/product',
      finance: '/radar/alerts/create/finance',
    },
    blocks: '/radar/alerts/blocks',
  },
  reviews: {
    manage: '/radar/reviews/manage',
    create: '/radar/reviews/create',
    update: '/radar/reviews/update',
  },
  blocks: '/blocks',
  // settings: '/settings',
  verifyEmail: '/verify-email',
  logout: '/logout',
  login: {
    index: '/login',
    email: '/login/email',
  },
  signup: {
    index: '/sign-up',
    email: '/sign-up/email',
  },
} as const;

export default paths;
