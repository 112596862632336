import { useMemo } from 'react';

import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { IconButton, MoreVerticalIcon } from 'components/uikit';
import {
  useCustomerCompanyNoteDeletingAtom,
  useCustomerCompanyNotesFetchingAtom,
} from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

export function NoteContextMenu({
  companyId,
  noteId,
}: {
  companyId: string;
  noteId: string;
}) {
  const trackEvent = useUserEvent();
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'company_drawer.tabs.notes_tab',
  });
  const fetchNotes = useSetAtom(useCustomerCompanyNotesFetchingAtom(companyId));
  const deleteNote = useSetAtom(useCustomerCompanyNoteDeletingAtom(companyId));

  const menuItems = useMemo(
    () => [
      {
        label: t('delete_note'),
        onClick: async () => {
          await deleteNote({ companyId, noteId });
          trackEvent(CustomerDrawerAnalyticsEvents.NOTES_DELETED, {
            customerType: 'company',
          });
          await fetchNotes(companyId);
        },
      },
    ],
    [companyId, deleteNote, fetchNotes, noteId, t, trackEvent],
  );

  return (
    <DropdownMenu items={menuItems}>
      <IconButton
        aria-label={t('more_options_dropdown_aria_label')}
        variant='grayGhost'
        height='34px'
        width='32px'
        icon={
          <MoreVerticalIcon
            stroke='grey.tertiaryText'
            width='16px'
            height='16px'
          />
        }
        size='xs'
      />
    </DropdownMenu>
  );
}
