import { AnalyticsEventType, AnalyticsEventGroup } from 'utils/analytics';

export class ReportsAnalyticsEvents extends AnalyticsEventType {
  static REPORTS_OPENED = new ReportsAnalyticsEvents('reports-opened');
  static REPORTS_CREATE_CLICKED = new ReportsAnalyticsEvents(
    'reports-create-clicked',
  );
  static REPORTS_CREATED = new ReportsAnalyticsEvents('reports-created');
  static REPORTS_EDIT_CLICKED = new ReportsAnalyticsEvents(
    'reports-edit-clicked',
  );
  static REPORTS_EDITED = new ReportsAnalyticsEvents('reports-edited');
  static REPORTS_STATUS_UPDATED = new ReportsAnalyticsEvents(
    'reports-status-updated',
  );

  private constructor(readonly action: string) {
    super(action, AnalyticsEventGroup.REPORTS);
  }
}
