import { DEMO_COMPANY_NAME } from 'data/constants/demo';

import { useCurrentProfile } from './useCurrentProfile';

export function useCurrentCompanyNamespace() {
  return useCompanyNamespaceWithFetchStatus().companyNamespace;
}

export function useCompanyNamespaceWithFetchStatus() {
  const { currentProfile, fetchStatus } = useCurrentProfile();
  const companyNamespace = currentProfile?.company?.namespace ?? null;

  return {
    companyNamespace,
    fetchStatus,
  };
}

export function useIsCurrentCompanyDemoCompany() {
  return useCurrentCompanyNamespace() === DEMO_COMPANY_NAME;
}
