import { ReactNode, useCallback } from 'react';

import { IconProps } from '@chakra-ui/icons';
import {
  createIcon,
  FlexProps,
  TextProps,
  ToastPosition,
} from '@chakra-ui/react';
import { UseToastOptions } from '@chakra-ui/toast';
import { useTranslation } from 'react-i18next';

import {
  ArrowForwardIcon,
  Flex,
  Button,
  CloseIcon,
  Text,
  useToast,
} from 'components/uikit';

import { AlertCircleIcon, CheckCircleIcon3, InfoCircleIcon } from './uikit';

const colorForVariant = {
  SUCCESS: 'primary.button',
  ONBOARDING: 'teal.600',
  INFO: 'grey.tertiaryText',
  ERROR: 'error.text',
} as const;

const backgroundColorForVariant = {
  SUCCESS: 'grey.offWhite',
  ONBOARDING: 'grey.white',
  INFO: 'grey.background',
  ERROR: 'error.backgrond',
} as const;

type ToastVariant = 'SUCCESS' | 'ERROR' | 'INFO' | 'ONBOARDING';

interface ToastProps {
  variant: ToastVariant;
  title: ReactNode;
  titleColor: TextProps['color'] | undefined;
  description?: ReactNode;
  customContent?: (onClose: () => void) => ReactNode;
  onClickClose: () => void;
  onClickRetry?: () => void;
  Icon: ReturnType<typeof createIcon> | undefined;
  iconColor: IconProps['stroke'] | undefined;
  iconBackgroundColor?: FlexProps['backgroundColor'] | undefined;
  iconSize?: TextProps['fontSize'];
}

function getToastIcon(variant: ToastVariant) {
  if (variant === 'SUCCESS') {
    return CheckCircleIcon3;
  } else if (variant === 'ERROR') {
    return AlertCircleIcon;
  } else if (variant === 'ONBOARDING') {
    return null;
  }
  return InfoCircleIcon;
}

export function Toast({
  variant,
  title,
  titleColor,
  description,
  customContent,
  onClickClose,
  onClickRetry,
  Icon,
  iconColor,
  iconBackgroundColor,
  iconSize = '20px',
}: ToastProps) {
  const { t } = useTranslation('mission-control');

  const ToastIcon = Icon || getToastIcon(variant);

  const toastIconColor = iconColor || colorForVariant[variant];
  const backgroundIconColor =
    iconBackgroundColor || backgroundColorForVariant[variant];

  return (
    <Flex
      bg='grey.white'
      borderRadius='4px'
      border='1px solid'
      borderColor='grey.border'
      boxShadow='lg'
      borderLeftWidth='4px'
      borderLeftStyle='solid'
      borderLeftColor={colorForVariant[variant]}
      p='4'
      columnGap='3'
      position='relative'
      justifyContent='space-between'
      w='auto'
      maxW='360px'
      mb='16px'
      mr='32px'
    >
      <Flex flexDir='row' alignItems='flex-start' gap='12px'>
        {ToastIcon ? (
          <Flex
            borderRadius='16px'
            w='32px'
            h='32px'
            p='6px'
            backgroundColor={backgroundIconColor}
            justifyContent='center'
            alignItems='center'
          >
            <ToastIcon h={iconSize} w={iconSize} stroke={toastIconColor} />
          </Flex>
        ) : null}
        <Flex flexDir='column' alignItems='flex-start'>
          {typeof title === 'string' ? (
            <Text
              fontSize='md'
              fontWeight='600'
              color={titleColor || 'grey.primaryText'}
            >
              {title}
            </Text>
          ) : (
            title
          )}

          {typeof description === 'string' ? (
            <Text fontSize='sm' fontWeight='400' color='grey.tertiaryText'>
              {description}
            </Text>
          ) : (
            description
          )}
          {customContent && customContent(onClickClose)}
          {onClickRetry ? (
            <Button
              variant='unstyled'
              color='secondary.button'
              fontWeight='600'
              fontSize='sm'
              onClick={() => {
                onClickRetry();
                onClickClose();
              }}
            >
              {t('try_again')}
              <ArrowForwardIcon ml='1' />
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <CloseIcon
        onClick={onClickClose}
        h='20px'
        w='20px'
        cursor='pointer'
        stroke='grey.tertiaryText'
      />
    </Flex>
  );
}

type SelectivePartial<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

type ToastParams = SelectivePartial<
  Omit<ToastProps, 'onClickClose'>,
  'titleColor' | 'iconColor' | 'Icon'
> & {
  position?: ToastPosition;
  duration: number;
  id?: string;
};

export function useStyledToast(defaultOptions?: UseToastOptions) {
  const toast = useToast(defaultOptions);

  return useCallback(
    ({
      id = 'default-toast',
      title,
      titleColor,
      description,
      customContent,
      variant,
      duration,
      onClickRetry,
      position,
      Icon,
      iconColor,
      iconSize,
      iconBackgroundColor,
    }: ToastParams) => {
      if (toast.isActive(id)) return;
      return toast({
        id,
        duration,
        position: position || 'bottom-right',
        containerStyle: {
          margin: '0',
        },
        render({ onClose }) {
          return (
            <Toast
              variant={variant}
              title={title}
              titleColor={titleColor}
              description={description}
              customContent={customContent}
              onClickClose={onClose}
              onClickRetry={onClickRetry}
              Icon={Icon}
              iconColor={iconColor}
              iconSize={iconSize}
              iconBackgroundColor={iconBackgroundColor}
            />
          );
        },
      });
    },
    [toast],
  );
}
