import { AiBreakdownData } from 'data/ai/models';

type AiBreakdownChartProps = AiBreakdownData;
export function AiAnswerBreakdown(props: AiBreakdownChartProps) {
  console.log(props);
  return null;
  // const { t } = useTranslation('mission-control');
  // const columnConfigs = useGetColumnConfigs();
  // const getColumnConfig = useCallback(
  //   (column: string) => {
  //     const config = columnConfigs[column];
  //
  //     if (!config)
  //       return {
  //         header: column,
  //         accessor: column,
  //       };
  //     return {
  //       ...config,
  //       header: t(config.header),
  //     };
  //   },
  //   [columnConfigs],
  // );
  //
  // const firstRow = data.rows[0];
  // if (!firstRow) return null;
  // const tableConfig = Object.keys(firstRow).map(getColumnConfig);
  //
  // return (
  //   <FullBreakdownTable
  //     width='100%'
  //     trackingLabel='unknown'
  //     fetchStatus='SUCCESS'
  //     config={tableConfig}
  //     numRows={data.rows.length}
  //     borderRadius='0px'
  //     borderWidth='0px'
  //     data={data}
  //     dateRangeControl={DateRangeControlOptions.DATE_PICKER_FULL}
  //   />
  // );
}
