import { z } from 'zod';

import { formattedDatePickerAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { CurrencyType } from 'data/common/currencyType';
import { getFullBreakdownWithSummaryAtom } from 'utils/atoms/fullBreakdownWithSummaryAtom';

export const FullBreakdownByProductRow = z.object({
  product: z.string(),
  customers: z.coerce.number(),
  customersPpt: z.coerce.number(),
  revenue: z.coerce.number(),
  revenuePpt: z.coerce.number(),
  currency: CurrencyType,
});

export type FullBreakdownByProductRow = z.infer<
  typeof FullBreakdownByProductRow
>;

export const breakdownByProductCustomersAtoms = getFullBreakdownWithSummaryAtom(
  FullBreakdownByProductRow,
  [{ column: 'customers', direction: 'DESC' }],
  formattedDatePickerAtom,
);

export const breakdownByProductRevenueAtoms = getFullBreakdownWithSummaryAtom(
  FullBreakdownByProductRow,
  [{ column: 'revenue', direction: 'DESC' }],
  formattedDatePickerAtom,
);
