import { createContext, useContext } from 'react';

import { TextFieldConnector } from './useTextField';

const FieldsContext = createContext<Record<string, TextFieldConnector>>({});

export function useFieldsContext<
  K extends string,
  T extends Record<K, TextFieldConnector>,
>() {
  return useContext(FieldsContext) as unknown as T;
}

export const FieldsProvider = FieldsContext.Provider;
