// eslint-enable import/no-cycle

import { z } from 'zod';

import { DisplayType } from 'data/blocks/models/DisplayType';
import { CurrencyType } from 'data/common/currencyType';

import { MetricDictionarySummaryName } from './MetricDictionarySummaryName';

const Sentiment = z.enum(['POSITIVE', 'NEGATIVE', 'NEUTRAL']);

const BreakdownCell = z.object({
  column: z.string(),
  value: z.string(),
  valueType: z.string().nullable(),
  delta: z.string().nullable(),
  sentiment: Sentiment.nullable(),
  deltaSentiment: Sentiment.nullable(),
  currency: CurrencyType.nullish(),
});

const BreakdownRow = z.object({
  disabled: z.boolean().default(false),
  cells: z.array(BreakdownCell),
});

const Breakdown = z.object({
  splitTitle: z.string(),
  splitCount: z.number(),
  displayType: DisplayType,
  rows: z.array(BreakdownRow),
});

const BreakdownWithHorizontalBar = Breakdown.omit({ displayType: true }).extend(
  {
    displayType: z.literal(DisplayType.enum.HORIZONTAL_BAR),
  },
);

const BreakdownTable = Breakdown.omit({ displayType: true }).extend({
  displayType: z.literal(DisplayType.enum.TABLE),
});

export type Breakdown = z.infer<typeof Breakdown>;
export type BreakdownWithHorizontalBar = z.infer<
  typeof BreakdownWithHorizontalBar
>;
export type BreakdownTable = z.infer<typeof BreakdownTable>;

const GrowthSummaryCell = z.enum([
  'NEW_VISITORS',
  'SESSIONS',
  'CONVERSIONS',
  'CONVERSION_RATE',
]);
const SalesSummaryCell = z.enum([
  'WON_DEALS',
  'CLOSING_SOON',
  'STALLED',
  'NEW',
]);
export const FinanceSummaryCell = z.enum([
  'NET_MRR_CHANGE',
  'MRR_WON',
  'MRR_LOST',
]);
const ProductSummaryCell = z.enum([
  'NEW_SIGN_UPS',
  'ACTIVE_USERS',
  'POWER_USERS',
  'RETENTION_RATE',
]);

const DigestSummary = z.object({
  cell: z.union([
    GrowthSummaryCell,
    SalesSummaryCell,
    FinanceSummaryCell,
    ProductSummaryCell,
    MetricDictionarySummaryName,
  ]),
  value: z.string(),
  deltaSentiment: Sentiment,
  delta: z.string(),
  currency: CurrencyType.nullish(),
});

export const BreakdownChart = z.object({
  breakdown: z.array(Breakdown),
  summary: z.array(DigestSummary).nullable(),
});
export type BreakdownChart = z.infer<typeof BreakdownChart>;

export const BreakdownConfig = z.object({
  name: z.string(),
  chart: BreakdownChart,
});
export type BreakdownConfig = z.infer<typeof BreakdownConfig>;
