import { useCallback, useEffect } from 'react';

import { Getter, Setter } from 'jotai';
import { useAtomCallback } from 'jotai/utils';

export let readAtom: Getter | undefined = undefined;
export let writeAtom: Setter | undefined = undefined;

export const AtomReader = () => {
  const init = useAtomCallback(
    useCallback((get, set) => {
      readAtom = get;
      writeAtom = set;
    }, []),
  );

  useEffect(() => {
    init();
  }, [init]);

  return null;
};
