import { useRef } from 'react';

import { LinkBox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UserTag } from 'components/CustomerDrawer/UserDrawer/DrawerHeader/UserTag';
import {
  Box,
  Flex,
  HStack,
  InfoCircleIcon,
  Text,
  Tooltip,
  VStack,
} from 'components/uikit';
import { TagIcon } from 'components/uikit/icons/Tag';
import { CustomerTag } from 'data/customer/CustomerTag';

interface UserTagsProps {
  userId: string;
  userTags: CustomerTag[];
  isTagsOpen: boolean;
  onTagsOpen: () => void;
}

export function UserTags({
  userId,
  userTags,
  isTagsOpen,
  onTagsOpen,
}: UserTagsProps) {
  const { t } = useTranslation('customer-health', {
    keyPrefix: 'user_drawer.header',
  });
  const infoBoxRef = useRef(null);

  return (
    <VStack alignItems='left' spacing={'8px'} width={'100%'}>
      <Flex gap='4px' justifyContent='space-between'>
        <HStack>
          <TagIcon boxSize='16px' stroke={'grey.secondaryText'} />
          <Text
            fontWeight={'medium'}
            color={'grey.secondaryText'}
            lineHeight={'150%'}
            fontSize={'12px'}
            verticalAlign={'middle'}
          >
            {t('tags')}
          </Text>
          <Tooltip
            closeOnClick={false}
            label={t('tags_info_tooltip')}
            placement={'top-end'}
            portalProps={{ containerRef: infoBoxRef }}
          >
            <Box ref={infoBoxRef} display={'inherit'}>
              <InfoCircleIcon boxSize='12px' stroke={'grey.tertiaryText'} />
            </Box>
          </Tooltip>
        </HStack>
        <LinkBox
          as={'button'}
          fontWeight={'medium'}
          color={'secondary.button'}
          lineHeight={'150%'}
          fontSize={'12px'}
          verticalAlign={'middle'}
          hidden={isTagsOpen}
          onClick={onTagsOpen}
        >
          {t('add_tag')}
        </LinkBox>
      </Flex>
      <Flex flexWrap={'wrap'} gap='8px'>
        {userTags.length === 0 && (
          <Text
            fontStyle={'italic'}
            color={'grey.tertiaryText'}
            fontSize={'12px'}
          >
            {t('no_tags')}
          </Text>
        )}
        {userTags.map((tag) => (
          <UserTag key={`${userId}-${tag.id}`} userId={userId} tag={tag} />
        ))}
      </Flex>
    </VStack>
  );
}
