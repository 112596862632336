import { z } from 'zod';

import { fixedEndDateFormattedDateRangeAtom } from 'data/charts/atoms/departmentMissionControlDatePickerAtom';
import { getFullBreakdownWithPaginationAtom } from 'utils/atoms/fullBreakdownWithPaginationAtom';

export const FullBreakdownsByPipelineDealsRow = z.object({
  dealName: z.string(),
  company: z.string(),
  ownerName: z.string(),
  dealCreationDate: z.coerce.date(),
  stage: z.string(),
  expectedCloseDate: z.coerce.date().nullable(),
  timeInStage: z.coerce.number(),
  expectedDealValue: z.coerce.number(),
});

export type FullBreakdownsByPipelineDealsRow = z.infer<
  typeof FullBreakdownsByPipelineDealsRow
>;

export const breakdownByLargestDealsInPipelineAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownsByPipelineDealsRow,
    [{ column: 'expectedDealValue', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByRecentlyAddedPipelineAtoms =
  getFullBreakdownWithPaginationAtom(
    FullBreakdownsByPipelineDealsRow,
    [{ column: 'dealCreationDate', direction: 'DESC' }],
    fixedEndDateFormattedDateRangeAtom,
  );

export const breakdownByStalledDealsAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsByPipelineDealsRow,
  [
    {
      column: 'timeInStage',
      direction: 'DESC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);

export const breakdownByClosingSoonAtoms = getFullBreakdownWithPaginationAtom(
  FullBreakdownsByPipelineDealsRow,
  [
    {
      column: 'expectedCloseDate',
      direction: 'ASC',
    },
  ],
  fixedEndDateFormattedDateRangeAtom,
);
