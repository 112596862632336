import { useTranslation } from 'react-i18next';

import { EmbedOptionTopNavButton } from 'components/Sharing/SharingOptions/EmbedOptionTopNavButton';
import { CodeIcon, HStack, SlackLogo } from 'components/uikit';
import { NotionLogo } from 'components/uikit/icons/Notion';
import { TomeLogo } from 'components/uikit/icons/Tome';

export function EmbedOptionTopNavBar() {
  const { t } = useTranslation('common', {
    keyPrefix: 'sharing_block_modal_embed',
  });

  return (
    <HStack
      padding='4px'
      borderRadius='4px'
      direction='row'
      justify='flex-start'
      align='flex-start'
      spacing='4px'
      borderColor={'grey.border'}
      borderStartWidth='1px'
      borderEndWidth='1px'
      borderTopWidth='1px'
      borderBottomWidth='1px'
      alignSelf='stretch'
      background={'grey.white'}
    >
      <EmbedOptionTopNavButton
        embedOption={'slack'}
        caption={t('option_button_slack')}
        Icon={SlackLogo}
      />
      <EmbedOptionTopNavButton
        embedOption={'notion'}
        caption={t('option_button_notion')}
        Icon={NotionLogo}
      />
      <EmbedOptionTopNavButton
        embedOption={'tome'}
        caption={t('option_button_tome')}
        Icon={TomeLogo}
      />
      <EmbedOptionTopNavButton
        embedOption={'website'}
        caption={t('option_button_website')}
        Icon={CodeIcon}
      />
    </HStack>
  );
}
