import { useState } from 'react';

import { TagProps } from '@chakra-ui/tag';

import { CloseIcon, HStack, Spinner, Tag, Tooltip } from 'components/uikit';
import { CustomerTag } from 'data/customer/CustomerTag';
import { useCustomerCompanyTagRemovingAtom } from 'data/healthReports/hooks/company/useCustomerCompanyAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

interface CompanyTagProps extends Omit<TagProps, 'children'> {
  customerCompanyId: string;
  tag: CustomerTag;
}

export function CompanyTag({
  customerCompanyId,
  tag,
  ...props
}: CompanyTagProps) {
  const trackEvent = useUserEvent();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const removeTagFromCompany =
    useCustomerCompanyTagRemovingAtom(customerCompanyId);
  const handleRemoveTag = async () => {
    setShowSpinner(true);
    await removeTagFromCompany({ companyId: customerCompanyId, tagId: tag.id });
    setShowSpinner(false);
    trackEvent(CustomerDrawerAnalyticsEvents.TAGS_REMOVED, {
      customerType: 'company',
    });
  };

  return (
    <Tag
      variant={'square'}
      background={'secondary.background'}
      color={'secondary.text'}
      fontWeight={'medium'}
      fontSize={'12px'}
      lineHeight={'150%'}
      {...props}
    >
      <HStack spacing={'4px'}>
        <Tooltip label={tag.description}>{tag.title}</Tooltip>
        {showSpinner ? (
          <Spinner boxSize={'14px'} />
        ) : (
          <CloseIcon
            boxSize='14px'
            stroke={'grey.tertiaryText'}
            onClick={handleRemoveTag}
            _hover={{ cursor: 'pointer', background: 'grey.white' }}
          />
        )}
      </HStack>
    </Tag>
  );
}
