import { useCallback } from 'react';

import { format } from 'date-fns';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import { MissionControlGranularity } from 'data/charts/atoms/missionControlGranularityAtom';
import {
  formatOrdinal,
  getEndOfPeriodOrToday,
  TOOLTIP_DATE_FORMAT,
  TOOLTIP_SHORT_MONTHLY_DATE_FORMAT,
  TOOLTIP_SHORT_WEEKLY_DATE_FORMAT,
} from 'utils/datetime/dateUtil';

export const useFormatTooltipTitle = (aggPeriod: AggregationPeriod) =>
  useCallback(
    (tooltipDate: Date) => {
      const { date } = getEndOfPeriodOrToday(tooltipDate, aggPeriod);
      return format(date, TOOLTIP_DATE_FORMAT);
    },
    [aggPeriod],
  );

export const useFormatMissionControlTooltipTitle = (
  aggPeriod: AggregationPeriod,
  granularity: MissionControlGranularity,
) =>
  useCallback(
    (tooltipDate: Date) => {
      if (granularity === 'WEEK') {
        return format(tooltipDate, TOOLTIP_SHORT_WEEKLY_DATE_FORMAT);
      }
      const date = format(tooltipDate, TOOLTIP_SHORT_MONTHLY_DATE_FORMAT);
      return formatOrdinal(Number(date));
    },
    [granularity],
  );
