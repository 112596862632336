import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { Department } from 'data/blocks/models/Block';
import { createDepartmentCustomerCompanyAtoms } from 'data/healthReports/atoms/customerCompanyAtoms';

export const useFetchCustomerCompany = (
  department: Department,
  departmentId: string,
) => {
  const { dataAtom, fetchingAtom } = createDepartmentCustomerCompanyAtoms(
    department,
    departmentId,
  );
  const fetchCustomerCompany = useSetAtom(fetchingAtom);

  useEffect(() => {
    fetchCustomerCompany({ department, departmentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useAtomValue(dataAtom);
};
