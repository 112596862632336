import { useStyleConfig } from '@chakra-ui/react';

import { SidebarCommandPanelButton } from 'components/CommandPanel/SidebarCommandPanelButton';
import { SidebarCards } from 'components/SidebarMenu/SidebarCards/SidebarCards';
import { SidebarMenuUserAndSettingsSection } from 'components/SidebarMenu/SidebarMenuUserAndSettingsSection';
import {
  SidebarNavItem,
  SidebarNavItemProps,
} from 'components/SidebarMenu/SidebarNavItem';
import ThemeSwitch from 'components/theme/ThemeSwitch';
import { Box, Link, VStack } from 'components/uikit';
import { CalliperLogoWideWhite } from 'components/uikit/icons/CalliperLogoWideWhite';
import { PaddleLogoWide } from 'components/uikit/icons/PaddleLogoWide';
import { hideScrollbar } from 'styles/hideScrollbar';
import paths from 'utils/paths';
import { useIsEnterprise } from 'utils/useIsEnterprise';

export function SidebarMenuDesktop({
  navItemConfigs,
}: {
  navItemConfigs: SidebarNavItemProps[];
}) {
  const navItems = navItemConfigs.map((props) => (
    <SidebarNavItem key={props.path} {...props} />
  ));

  const styles = useStyleConfig('SidebarMenuDesktop');
  const isEnterprise = useIsEnterprise();

  return (
    <Box
      __css={styles}
      id='sidebarContainer'
      borderRight='1px solid'
      borderRightColor='grey.border.dark'
    >
      <Box
        mx='8px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        w='130px'
        h='40px'
        mb='32px'
      >
        <Link href={paths.missionControl.index} passHref>
          {isEnterprise ? (
            <PaddleLogoWide w='120px' h='28px' />
          ) : (
            <CalliperLogoWideWhite w='120px' h='28px' />
          )}
        </Link>
      </Box>

      <SidebarCommandPanelButton />

      <VStack
        spacing='4px'
        mt='16px'
        mb='10px'
        overflowY='auto'
        css={hideScrollbar}
      >
        {navItems}
      </VStack>

      <SidebarCards />

      <Box
        marginTop='10px'
        bgColor='grey.offWhite.dark'
        p='8px'
        borderTopWidth='1px'
        borderTopColor='grey.border.dark'
        borderBottomWidth='1px'
        borderBottomColor='grey.border.dark'
      >
        <ThemeSwitch />
      </Box>

      <SidebarMenuUserAndSettingsSection />
    </Box>
  );
}
