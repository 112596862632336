import { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import { useSetCreatedAccount } from 'data/auth/hooks/useCreatedAccount';

import { Hub, signOut } from '../amplify';
import { fetchIdTokenAtom, idTokenAtom } from '../atoms/idTokenAtom';

export function useHandleAuthEvents() {
  const fetchIdToken = useSetAtom(fetchIdTokenAtom);
  const setIdToken = useSetAtom(idTokenAtom);
  const setCreatedAccount = useSetCreatedAccount();

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;

      switch (event) {
        case 'signIn':
        case 'autoSignIn':
          await fetchIdToken();
          setCreatedAccount(true);
          break;
        case 'signOut':
        case 'oAuthSignOut':
          setIdToken({
            idToken: null,
            expiry: null,
            fetchStatus: 'INITIAL',
            errorMessage: '',
          });
          break;
        case 'tokenRefresh_failure':
        case 'autoSignIn_failure':
          signOut();
          break;
      }
    });
  }, [fetchIdToken, setIdToken, setCreatedAccount]);
}
