import { z } from 'zod';

import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CohortBreakdownConfig } from 'data/charts/models/ChartsApiRequest';
import {
  BlockResponse,
  CohortTableResponse,
} from 'data/charts/models/ChartsApiResponse';
import { CHARTS_API_URL } from 'data/constants';
import { parseItemStrict, Parser } from 'utils/parser/parseResponse';

export async function fetchCohortsBreakdown<
  CohortRow extends { cohort: Date },
  Input = CohortRow,
>(
  blockId: string,
  chartConfig: CohortBreakdownConfig,
  parser: Parser<CohortRow, Input>,
): Promise<CohortTableResponse<CohortRow>> {
  const result = await authorisedFetch(`${CHARTS_API_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      blocks: [
        {
          blockId,
          chartConfig,
        },
      ],
    }),
  });
  const [cohortTableResponse] = parseItemStrict(
    z.tuple([BlockResponse(CohortTableResponse(parser))]),
    await result.json(),
  );

  return cohortTableResponse.data;
}
