import { Card, CardProps } from 'components/uikit';

interface AiQuestionProps extends CardProps {
  question: string;
}

export function AiQuestion({ question, ...props }: AiQuestionProps) {
  return (
    <Card
      variant='notice'
      highlightColor='tertiary.text'
      color='tertiary.text'
      boxShadow='none'
      px='16px'
      py='8px'
      fontWeight='medium'
      {...props}
    >
      {question}
    </Card>
  );
}
