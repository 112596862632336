import { useAtomValue } from 'jotai';

import { readAtom } from 'components/AtomReader';
import { chartSettingsAtom } from 'data/settings/atoms/chartSettingsAtom';
import { Currency } from 'utils/formatting/number';

export function getDefaultCurrency() {
  return readAtom?.(chartSettingsAtom).defaultCurrency ?? Currency.GBP;
}

export function useDefaultCurrency(): Currency {
  const chartSettings = useAtomValue(chartSettingsAtom);
  return chartSettings.defaultCurrency ?? Currency.GBP;
}
