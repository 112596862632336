import { ReactNode } from 'react';

import { responsive } from 'utils/responsiveUtils';

import { Box, BoxProps, Tag, TagProps, Text } from '../reexport';

export interface HighlightedProps extends BoxProps {
  highlightColor?: BoxProps['color'];
}

export interface CardProps extends HighlightedProps {
  variant?: 'single-section' | 'multi-section' | 'notice' | 'centered';
}

export interface FeatureCardProps extends Omit<BoxProps, 'title'> {
  colorScheme: TagProps['colorScheme'];
  title?: ReactNode;
  labelText?: ReactNode;
  labelIcon?: ReactNode;
}

export function BaseCard(props: BoxProps) {
  return (
    <Box
      bg='grey.white'
      borderRadius={responsive('12px', '8px')}
      w='100%'
      borderWidth='1px'
      borderStyle='solid'
      boxShadow='1'
      borderColor='grey.border'
      {...props}
    />
  );
}

function MultiSectionCard(props: BoxProps) {
  return <BaseCard {...props} />;
}

function SingleSectionCard(props: BoxProps) {
  return <BaseCard display='flex' flex={1} flexDir='column' {...props} />;
}

function CenteredCard(props: BoxProps) {
  return (
    <BaseCard
      display='flex'
      flex={1}
      flexDir='column'
      gap='4px'
      alignItems='center'
      textAlign='center'
      p='32px 28px'
      {...props}
    />
  );
}

function NoticeCard({
  highlightColor = 'grey.background',
  ...props
}: HighlightedProps) {
  return (
    <BaseCard
      display='flex'
      flex={1}
      flexDir='column'
      borderRadius='4px'
      borderLeftWidth='4px'
      borderLeftStyle='solid'
      borderLeftColor={highlightColor}
      p='16px'
      {...props}
    />
  );
}

export function Card({ variant = 'single-section', ...props }: CardProps) {
  switch (variant) {
    case 'multi-section':
      return <MultiSectionCard {...props} />;
    case 'notice':
      return <NoticeCard {...props} />;
    case 'single-section':
      return <SingleSectionCard {...props} />;
    case 'centered':
      return <CenteredCard {...props} />;
  }
}

export function FeatureCard({
  colorScheme,
  title,
  labelText,
  labelIcon,
  children,
  ...props
}: FeatureCardProps) {
  return (
    <BaseCard
      display='flex'
      flexDir='column'
      p='0'
      justifyContent='space-between'
      {...props}
    >
      <Box
        display='flex'
        flexDir='column'
        alignItems='flex-start'
        p='16px'
        gap='8px'
      >
        {labelText ? (
          <Tag colorScheme={colorScheme} gap='4px' p='2px 8px' variant='square'>
            {labelIcon}
            {labelText}
          </Tag>
        ) : null}
        <Text lineHeight='24px' color='grey.tertiaryText'>
          {children}
        </Text>
      </Box>
      {title ? (
        <Box
          display='flex'
          flexDir='row'
          alignItems='center'
          justifyContent='center'
          w='100%'
          h='32px'
          backgroundColor={`${colorScheme}.100`}
        >
          <Text fontSize='xxs' color={`${colorScheme}.700`} fontWeight='medium'>
            {title}
          </Text>
        </Box>
      ) : undefined}
    </BaseCard>
  );
}
