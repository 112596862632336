import { useState } from 'react';

import { TagProps } from '@chakra-ui/tag';

import { CloseIcon, HStack, Spinner, Tag, Tooltip } from 'components/uikit';
import { CustomerTag } from 'data/customer/CustomerTag';
import { useCustomerUserTagRemovingAtom } from 'data/healthReports/hooks/users/useCustomerUserAtoms';
import { CustomerDrawerAnalyticsEvents, useUserEvent } from 'utils/analytics';

interface CompanyTagProps extends Omit<TagProps, 'children'> {
  userId: string;
  tag: CustomerTag;
}

export function UserTag({ userId, tag, ...props }: CompanyTagProps) {
  const trackEvent = useUserEvent();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const removeTagFromUser = useCustomerUserTagRemovingAtom(userId);

  const handleRemoveTag = async () => {
    setShowSpinner(true);
    await removeTagFromUser({ userId: userId, tagId: tag.id });
    trackEvent(CustomerDrawerAnalyticsEvents.TAGS_REMOVED, {
      customerType: 'user',
    });
    setShowSpinner(false);
  };

  return (
    <Tag
      variant={'square'}
      background={'secondary.background'}
      color={'secondary.text'}
      fontWeight={'medium'}
      fontSize={'12px'}
      lineHeight={'150%'}
      {...props}
    >
      <HStack spacing={'4px'}>
        <Tooltip label={tag.description}>{tag.title}</Tooltip>
        {showSpinner ? (
          <Spinner boxSize={'14px'} />
        ) : (
          <CloseIcon
            boxSize='14px'
            stroke={'grey.tertiaryText'}
            onClick={handleRemoveTag}
            _hover={{ cursor: 'pointer', background: 'grey.white' }}
          />
        )}
      </HStack>
    </Tag>
  );
}
