import { useCallback, useEffect } from 'react';

import { useAtom } from 'jotai';

import { fetchCommandPanelOptionsAtom } from 'data/commandPanel/atoms';
import {
  ActionCommandPanelOption,
  CommandPanelSearchResult,
} from 'data/commandPanel/models';
import { useDebouncedState } from 'utils/hooks/useDebounce';
import { isInitial } from 'utils/types';

import { CommandPanelOptions } from './CommandPanelOptions';
import { CommandPanelSearchInput } from './CommandPanelSearchInput';

interface SearchCommandPanelProps {
  onClose: () => void;
  onSelectAiMode: () => void;
}

const AI_ACTIONS: ActionCommandPanelOption[] = [
  {
    action: 'ASK_AI',
    type: 'ACTION',
  },
];

const DEFAULT_ACTIONS: CommandPanelSearchResult = {
  COMPANY: [],
  USER: [],
  BETA: [...AI_ACTIONS],
  ACTION: [
    { action: 'INVITE_TEAM_MEMBER', type: 'ACTION' },
    { action: 'ADD_DATA_SOURCE', type: 'ACTION' },
    { action: 'VIEW_INSIGHTS', type: 'ACTION' },
    { action: 'METRICS_DICTIONARY', type: 'ACTION' },
    { action: 'CREATE_REPORT', type: 'ACTION' },
    { action: 'CREATE_ALERT', type: 'ACTION' },
  ],
  INSIGHT: [],
  METRIC: [],
  LEADERBOARD: [],
};

export function CommandPanelSearchBody({
  onClose,
  onSelectAiMode,
}: SearchCommandPanelProps) {
  const [searchInput, setSearchInput] = useDebouncedState('');
  const [{ fetchStatus, ...result }, fetchCustomers] = useAtom(
    fetchCommandPanelOptionsAtom,
  );

  const searchIsEmpty = searchInput === '';
  const showDefaultActions = searchIsEmpty || isInitial(fetchStatus);

  useEffect(() => {
    if (searchIsEmpty) return;
    fetchCustomers({ query: searchInput });
  }, [fetchCustomers, searchInput, searchIsEmpty]);

  const onCloseCallback = useCallback(() => {
    setSearchInput('');
    onClose();
  }, [onClose, setSearchInput]);

  const options = showDefaultActions
    ? DEFAULT_ACTIONS
    : {
        BETA: AI_ACTIONS,
        ACTION: [],
        USER: result.USER || [],
        COMPANY: result.COMPANY || [],
        METRIC: result.METRIC || [],
        LEADERBOARD: result.LEADERBOARD || [],
        INSIGHT: result.INSIGHT || [],
      };
  return (
    <>
      <CommandPanelSearchInput value={searchInput} setValue={setSearchInput} />
      <CommandPanelOptions
        options={options}
        onClose={onCloseCallback}
        fetchStatus={fetchStatus}
        onSelectAiMode={onSelectAiMode}
      />
    </>
  );
}
