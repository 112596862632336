import { useEffect, useRef, useState } from 'react';

import { Text, TextProps, Tooltip } from '@chakra-ui/react';

export function TruncatedText({
  children: content,
  ...textProps
}: Omit<TextProps, 'textOverflow' | 'overflow' | 'children'> & {
  children: string | number | undefined | null;
}) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTextOverflowed, setIsTextOverflowed] = useState(false);
  useEffect(() => {
    const tdElement = textRef.current;
    if (tdElement) {
      setIsTextOverflowed(tdElement.scrollWidth > tdElement.clientWidth);
    }
  }, []);

  return (
    <Tooltip isDisabled={!isTextOverflowed} label={content}>
      <Text
        ref={textRef}
        textOverflow='ellipsis'
        overflow='hidden'
        {...textProps}
      >
        {content}
      </Text>
    </Tooltip>
  );
}
