import { useToken } from '@chakra-ui/react';

export type ColorPalette = {
  blue: string;
  teal: string;
  violet: string;
  red: string;
  yellow: string;
  gray: string;
  positive: string;
  negative: string;
};

export function useColorPalette(): ColorPalette {
  const [blue, teal, violet, red, yellow, gray, positive, negative] = useToken(
    'colors',
    [
      'dataViz.1',
      'dataViz.2',
      'dataViz.3',
      'dataViz.4',
      'dataViz.5',
      'dataViz.6',
      'dataViz.Positive',
      'dataViz.Negative',
    ],
  );

  return {
    blue,
    teal,
    violet,
    red,
    yellow,
    gray,
    positive,
    negative,
  };
}
