import { ColorMode } from '@chakra-ui/color-mode';
import { LinkBox, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useColorModeChecker from 'components/theme/isLightTheme';
import { MoonIcon, Stack, SunIcon, Text } from 'components/uikit';
import { useCloseSideMenu } from 'data/layout/hooks/useNavHeight';

function SidebarMenuMobileThemeSwitchButton({ mode }: { mode: ColorMode }) {
  const { t } = useTranslation('sidebar');
  const closeSideMenu = useCloseSideMenu();
  const { setColorMode } = useColorMode();
  const isLight = useColorModeChecker();
  const isMode = mode === 'light' ? isLight : !isLight;
  const Icon = mode === 'light' ? SunIcon : MoonIcon;
  const captionTk =
    mode === 'light' ? 'theme_toggle_light_mode' : 'theme_toggle_dark_mode';

  const handleClick = () => {
    setColorMode(mode);
    setTimeout(() => {
      closeSideMenu();
    }, 600);
  };

  return (
    <LinkBox as='button' width='100%' onClick={handleClick}>
      <Stack
        height='100%'
        padding='8px'
        borderRadius='8px'
        direction='row'
        justify='center'
        align='center'
        flex='1'
        background={isMode ? '#121926' : 'transparent'}
      >
        <Icon
          stroke={isMode ? '#F8FAFC' : 'grey.tertiaryText'}
          height='24px'
          width='24px'
          mr='10px'
        />
        <Text
          fontSize='lg'
          fontWeight='medium'
          color={isMode ? '#F8FAFC' : 'grey.tertiaryText'}
          opacity='0.8'
        >
          {t(captionTk)}
        </Text>
      </Stack>
    </LinkBox>
  );
}

export function SidebarMenuMobileThemeSwitch() {
  return (
    <Stack
      padding='8px'
      height='72px'
      borderRadius='8px'
      direction='row'
      overflow='hidden'
      borderColor='grey.border.dark'
      borderStartWidth='1px'
      borderEndWidth='1px'
      borderTopWidth='1px'
      borderBottomWidth='1px'
      alignSelf='stretch'
      background={'#242D3C'}
    >
      <SidebarMenuMobileThemeSwitchButton mode={'light'} />
      <SidebarMenuMobileThemeSwitchButton mode={'dark'} />
    </Stack>
  );
}
