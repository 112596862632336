import { useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useCustomerBreakdownBlocks } from 'data/blocks/hooks/healthReports/useCustomerBreakdownBlocks';
import { CustomerBlock } from 'data/blocks/models/Block';
import { useCustomerUserAtoms } from 'data/healthReports/atoms/customerUserAtoms';
import { QuartileAtomKeys } from 'data/healthReports/models/user/CustomerUserAtomsType';
import { isNotNull } from 'utils/helpers';

export const useCustomerUser = (customerUserId: string) => {
  return useAtomValue(useCustomerUserAtoms(customerUserId).dataAtom);
};

export const useCustomerCompany = (customerUserId: string) => {
  return useAtomValue(useCustomerUserAtoms(customerUserId).companyDataAtom);
};

export const useCustomerUserFetchingAtom = (customerUserId: string) => {
  return useCustomerUserAtoms(customerUserId).fetchingAtom;
};

export const useCustomerCompanyFetchingAtom = (customerUserId: string) => {
  return useSetAtom(useCustomerUserAtoms(customerUserId).companyFetchingAtom);
};

export const useCustomerUserTagsUpdatingAtom = (customerUserId: string) => {
  return useAtom(useCustomerUserAtoms(customerUserId).tagsUpdatingAtom);
};

export const useCustomerUserTagRemovingAtom = (customerUserId: string) => {
  return useSetAtom(useCustomerUserAtoms(customerUserId).tagRemovingAtom);
};

export const useCustomerUserNoteCreatingAtom = (customerUserId: string) => {
  return useSetAtom(useCustomerUserAtoms(customerUserId).noteCreatingAtom);
};

export const useCustomerUserNotes = (customerUserId: string) => {
  return useAtomValue(useCustomerUserAtoms(customerUserId).notesDataAtom);
};

export const useCustomerUserNote = (customerUserId: string) => {
  return useAtomValue(useCustomerUserAtoms(customerUserId).notesDataAtom);
};

export const useCustomerUserNotesFetchingAtom = (customerUserId: string) => {
  return useCustomerUserAtoms(customerUserId).notesFetchingAtom;
};

export const useCustomerUserNoteDeletingAtom = (customerUserId: string) => {
  return useCustomerUserAtoms(customerUserId).noteDeletingAtom;
};

export const useCustomerUserData = (customerUserId: string) => {
  const { userDataAtoms } = useCustomerUserAtoms(customerUserId);

  return useAtomValue(userDataAtoms.dataAtom);
};

export const useCustomerUserDataFetchingAtom = (customerUserId: string) => {
  const { userDataAtoms } = useCustomerUserAtoms(customerUserId);

  return useSetAtom(userDataAtoms.fetchingAtom);
};

export const useFetchCustomerUserData = (customerUserId: string) => {
  const { blocks } = useCustomerBreakdownBlocks();
  const userDataBlock = blocks.find(
    (block) => block.uniqueName == 'CUSTOMER_USER_INFO',
  );
  const fetchCompanyData = useCustomerUserDataFetchingAtom(customerUserId);

  useEffect(() => {
    if (userDataBlock) {
      fetchCompanyData(userDataBlock.id);
    }
  }, [customerUserId, fetchCompanyData, userDataBlock]);
};

export const useCustomerUserCompanyData = (customerUserId: string) => {
  const { companyDataAtoms } = useCustomerUserAtoms(customerUserId);

  return useAtomValue(companyDataAtoms.dataAtom);
};

export const useCustomerUserCompanyDataFetchingAtom = (
  customerUserId: string,
) => {
  const { companyDataAtoms } = useCustomerUserAtoms(customerUserId);

  return useSetAtom(companyDataAtoms.fetchingAtom);
};

export const useFetchCustomerUserCompany = (
  customerUserId: string,
  customerCompanyId: string | null,
) => {
  const fetchUserCompany = useCustomerCompanyFetchingAtom(customerUserId);

  useEffect(() => {
    if (customerCompanyId) {
      fetchUserCompany({ companyId: customerCompanyId });
    }
  }, [customerUserId, customerCompanyId, fetchUserCompany]);
};

export const useFetchCustomerUserCompanyData = (
  customerUserId: string,
  customerCompanyId: string | null,
) => {
  const { blocks } = useCustomerBreakdownBlocks();
  const companyDataBlock = blocks.find(
    (block) => block.uniqueName == 'CUSTOMER_USER_COMPANY_INFO',
  );
  const fetchCompanyData =
    useCustomerUserCompanyDataFetchingAtom(customerUserId);

  useEffect(() => {
    if (companyDataBlock && isNotNull(customerCompanyId)) {
      fetchCompanyData(companyDataBlock.id);
    }
  }, [customerUserId, customerCompanyId, companyDataBlock, fetchCompanyData]);
};

export const useCustomerUserSentimentUpdatingAtom = (
  customerUserId: string,
) => {
  return useAtom(useCustomerUserAtoms(customerUserId).sentimentUpdatingAtom);
};

const useFetchCustomersQuartileChart = (
  quartileFetchingAtomKey: QuartileAtomKeys,
  customerUserId: string,
  block?: CustomerBlock,
) => {
  const [{ data, fetchStatus, errorMessage }, fetchData] = useAtom(
    useCustomerUserAtoms(customerUserId)[
      `quartile${quartileFetchingAtomKey}FetchingAtom`
    ],
  );

  useEffect(() => {
    if (block) fetchData({ block });
  }, [fetchData, block]);

  return { data, fetchStatus, errorMessage };
};

export const useFetchCustomersQuartileRevenueChart = (
  customerUserId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart('Revenue', customerUserId, block);
};

export const useFetchCustomersQuartileActiveDaysChart = (
  customerUserId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart('ActiveDays', customerUserId, block);
};

export const useFetchCustomersQuartileProductEngagementChart = (
  customerUserId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart(
    'ProductEngagement',
    customerUserId,
    block,
  );
};

export const useFetchCustomersQuartileCustomerLifespanChart = (
  customerUserId: string,
  block?: CustomerBlock,
) => {
  return useFetchCustomersQuartileChart(
    'CustomerLifespan',
    customerUserId,
    block,
  );
};
