import { authorisedFetch } from 'api/rest/authorisedFetch';
import { CustomerUserResponse } from 'api/rest/customer/models';
import { CUSTOMERS_USER_API_URL } from 'api/rest/customer/resourcesUrls';
import { parseItemStrict } from 'utils/parser/parseResponse';

export interface CreateCustomerUserNoteParams {
  userId: string;
  content: string;
}

export async function createCustomerUserNote({
  userId,
  content,
}: CreateCustomerUserNoteParams): Promise<CustomerUserResponse> {
  const result = await authorisedFetch(
    `${CUSTOMERS_USER_API_URL}/${userId}/notes`,
    {
      method: 'POST',
      body: JSON.stringify({ content }),
    },
  );

  if (result.status > 399) {
    const responseBody = await result.text();
    throw new Error(`Failed to create customer company note: ${responseBody}`);
  }

  // Must go below previous response reading (in case of error)
  const data = await result.json();

  return parseItemStrict(CustomerUserResponse, data);
}
