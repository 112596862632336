import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  isBefore,
  isSameMonth,
  isSameWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import { AggregationPeriod } from 'data/blocks/models/ChartConfig';
import {
  MappedTimeSeriesQueryResult,
  TimeSeries,
} from 'data/responseModels/TimeSeries';
import { convertUTCStringDateToLocalDate } from 'utils/datetime/timeFormat';

export const TOOLTIP_DATE_FORMAT = 'dd MMM yyyy';
export const TOOLTIP_SHORT_WEEKLY_DATE_FORMAT = 'EEEE';
export const TOOLTIP_SHORT_MONTHLY_DATE_FORMAT = 'd';

export function getEndOfPeriod(aggPeriod: AggregationPeriod) {
  switch (aggPeriod) {
    case 'MONTH':
      return (date: Date) => endOfMonth(date);
    case 'WEEK':
      return (date: Date) => endOfWeek(date);
    case 'DAY':
      return (date: Date) => endOfDay(date);
    default:
      throw new Error(`Unsupported period type: ${String(aggPeriod)}`);
  }
}

export function formatOrdinal(number: number) {
  const remainderOf10 = number % 10;
  const remainderOf100 = number % 100;

  if (remainderOf10 == 1 && remainderOf100 != 11) {
    return `${number}st`;
  }
  if (remainderOf10 == 2 && remainderOf100 != 12) {
    return `${number}nd`;
  }
  if (remainderOf10 == 3 && remainderOf100 != 13) {
    return `${number}rd`;
  }
  return `${number}th`;
}

export function formatAggPeriodLabels<T extends Record<string, TimeSeries>>(
  response: MappedTimeSeriesQueryResult<T>,
  aggPeriod: AggregationPeriod = 'MONTH',
) {
  return Object.keys(response).reduce((acc, key) => {
    const objectKey = key as keyof typeof response;
    const timeseries = response[objectKey] as TimeSeries;
    return {
      ...acc,
      [key]: {
        ...timeseries,
        labels: timeseries.labels.map((label) =>
          getEndOfPeriod(aggPeriod)(convertUTCStringDateToLocalDate(label)),
        ),
      },
    };
  }, {} as typeof response);
}

export function getEndOfPeriodOrToday(
  date: Date,
  aggPeriod: AggregationPeriod,
) {
  const dateToday = endOfToday();
  const endOfPeriod = getEndOfPeriod(aggPeriod)(date);
  const dateIsInFuture = dateToday <= endOfPeriod;
  const result = dateIsInFuture ? dateToday : endOfPeriod;
  return { dateIsInFuture, date: result };
}

export function isPreviousWeek(date: Date): boolean {
  const previousWeek = subWeeks(new Date(), 1); // get the date of the previous week
  return isSameWeek(date, previousWeek);
}

export function isPreviousMonth(date: Date): boolean {
  const previousMonth = subMonths(new Date(), 1); // get the date of the previous month
  return isSameMonth(date, previousMonth);
}

export function getStartOfDayBeforePeriods(
  date: Date,
  numberOfPeriods: number,
  periodType: AggregationPeriod,
): Date {
  switch (periodType) {
    case 'DAY':
      return subDays(startOfDay(date), numberOfPeriods);
    case 'WEEK':
      return subWeeks(startOfDay(date), numberOfPeriods);
    case 'MONTH':
      return subMonths(startOfDay(date), numberOfPeriods);
    case 'YEAR':
      return subYears(startOfDay(date), numberOfPeriods);
    case 'SINCE_THE_BEGINNING_OF_TIME':
      return subYears(startOfYear(date), 5);
    default:
      throw new Error(`Unsupported period type: ${periodType}`);
  }
}

export function getStartOfLastDayOfPeriod(
  date: Date,
  periodType: AggregationPeriod,
): Date {
  switch (periodType) {
    case 'DAY':
      return startOfDay(endOfDay(date));
    case 'WEEK':
      return startOfDay(endOfWeek(date));
    case 'MONTH':
      return startOfDay(endOfMonth(date));
    case 'YEAR':
      return startOfDay(endOfYear(date));
    case 'SINCE_THE_BEGINNING_OF_TIME':
      return endOfHour(new Date());
    default:
      throw new Error(`Unsupported period type: ${periodType}`);
  }
}

export function getDateBeforePeriods(
  endDate: Date,
  numberOfPeriods: number,
  periodType: AggregationPeriod,
): Date {
  switch (periodType) {
    case 'DAY':
      return subDays(addDays(startOfDay(endDate), 1), numberOfPeriods);
    case 'WEEK':
      return subWeeks(addWeeks(startOfWeek(endDate), 1), numberOfPeriods);
    case 'MONTH':
      return subMonths(addMonths(startOfMonth(endDate), 1), numberOfPeriods);
    case 'YEAR':
      return subYears(addYears(startOfYear(endDate), 1), numberOfPeriods);
    case 'SINCE_THE_BEGINNING_OF_TIME':
      return subYears(startOfYear(endDate), 5);
    default:
      throw new Error(`Unsupported period type: ${periodType}`);
  }
}

export function getDateBeforePeriodsLocal(
  endDate: Date,
  numberOfPeriods: number,
  periodType: AggregationPeriod,
): Date {
  switch (periodType) {
    case 'DAY':
      return subDays(startOfDay(endDate), numberOfPeriods);
    case 'WEEK':
      return subWeeks(startOfWeek(endDate), numberOfPeriods);
    case 'MONTH':
      return subMonths(startOfMonth(endDate), numberOfPeriods);
    case 'YEAR':
      return subYears(startOfYear(endDate), numberOfPeriods);
    case 'SINCE_THE_BEGINNING_OF_TIME':
      return endOfHour(new Date());
    default:
      throw new Error(`Unsupported period type: ${periodType}`);
  }
}

export function getChartLabel(
  metricName: string,
  aggPeriod: AggregationPeriod,
) {
  switch (aggPeriod) {
    case 'DAY':
      return `Daily ${metricName}`;
    case 'WEEK':
      return `Weekly ${metricName}`;
    case 'MONTH':
      return `Monthly ${metricName}`;
    default:
      throw new Error(`Unsupported granularity: ${aggPeriod}`);
  }
}

export function getAllMonthsInRange(startDate: Date, endDate: Date) {
  const months: Date[] = [];

  let currentMonth = startOfMonth(startDate);
  while (isBefore(currentMonth, endDate)) {
    months.push(currentMonth);
    currentMonth = addMonths(currentMonth, 1);
  }

  return months;
}

export function getTimeAgo(timestamp: Date): string {
  const now = new Date();
  const timeDifference = now.getTime() - timestamp.getTime();

  const units = [
    { value: 365 * 24 * 60 * 60 * 1000, label: 'year' },
    { value: 30 * 24 * 60 * 60 * 1000, label: 'month' },
    { value: 7 * 24 * 60 * 60 * 1000, label: 'week' },
    { value: 24 * 60 * 60 * 1000, label: 'day' },
    { value: 60 * 60 * 1000, label: 'hour' },
    { value: 60 * 1000, label: 'minute' },
  ];

  for (const unit of units) {
    const count = Math.floor(timeDifference / unit.value);
    if (count >= 1) {
      return count === 1
        ? `1 ${unit.label} ago`
        : `${count} ${unit.label}s ago`;
    }
  }

  return 'Just now';
}

export function isCurrentTimePeriod(timestamp: string): boolean {
  const inputDate = new Date(timestamp);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return inputDate >= today;
}

export function startOfDayUTC(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCHours(0, 0, 0, 0);
  return copy;
}

export function endOfDayUTC(date: Date): Date {
  const copy = new Date(date);
  copy.setUTCHours(23, 59, 59, 999);
  return copy;
}

export function utcToLocalHour(hour: number) {
  const date = new Date();
  date.setUTCHours(hour);
  return date.getHours();
}

export function localToUTCHour(hour: number) {
  const date = new Date();
  date.setHours(hour);
  return date.getUTCHours();
}

export function getPreviousPeriodEnd(
  date: Date,
  granularity: AggregationPeriod,
): Date {
  switch (granularity) {
    case 'DAY':
      return endOfDay(subDays(date, 1));
    case 'WEEK':
      return endOfWeek(subWeeks(date, 1));
    case 'MONTH':
      return endOfMonth(subMonths(date, 1));
    case 'YEAR':
      return endOfYear(subYears(date, 1));
    default:
      throw new Error(`Unknown granularity: ${granularity}`);
  }
}

export function getEndOfNPeriodsAgo(
  date: Date,
  granularity: AggregationPeriod,
  n: number,
): Date {
  switch (granularity) {
    case 'DAY':
      return endOfDay(subDays(date, n));
    case 'WEEK':
      return endOfWeek(subWeeks(date, n));
    case 'MONTH':
      return endOfMonth(subMonths(date, n));
    case 'YEAR':
      return endOfYear(subYears(date, n));
    default:
      throw new Error(`Unknown granularity: ${granularity}`);
  }
}

export function getNextPeriodEnd(
  date: Date,
  granularity: AggregationPeriod,
): Date {
  switch (granularity) {
    case 'DAY':
      return endOfDay(addDays(date, 1));
    case 'WEEK':
      return endOfWeek(addWeeks(date, 1));
    case 'MONTH':
      return endOfMonth(addMonths(date, 1));
    case 'YEAR':
      return endOfYear(addYears(date, 1));
    default:
      throw new Error(`Unknown granularity: ${granularity}`);
  }
}
