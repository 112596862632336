import { createIcon } from '@chakra-ui/react';

export const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath='url(#clip0_21957_25496)'>
        <path
          d='M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 11.993
             2.92547 15.3027 6.75 15.9028V10.3125H4.71875V8H6.75V6.2375C6.75
             4.2325 7.94438 3.125 9.77172 3.125C10.6467 3.125 11.5625 3.28125
             11.5625 3.28125V5.25H10.5538C9.56 5.25 9.25 5.86672 9.25
             6.5V8H11.4688L11.1141 10.3125H9.25V15.9028C13.0745 15.3027 16
             11.993 16 8Z'
          fill='currentColor'
          stroke='none'
        />
      </g>
      <defs>
        <clipPath id='clip0_21957_25496'>
          <rect width='16' height='16' fill='none' />
        </clipPath>
      </defs>
    </>
  ),
});
